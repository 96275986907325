import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { dashArrowActive, dashArrowInactive } from "../assets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dash_nav_container: {
      backgroundColor: "#f7f7f7",
      width: "100%",
      borderRadius: "1vw",
    },
    dash_nav_c: {
      paddingTop: "2vw",
      paddingBottom: "1.3vw",
      paddingLeft: "1.3vw",
      paddingRight: "1.3vw",
    },
    dash_nav_item_c: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      // backgroundColor: 'red',
      marginBottom: "1vw",
      cursor: "pointer",
    },
    dash_nav_item_img: {
      width: "0.5vw",
      marginRight: "0.5vw",
    },
    dash_nav_item_txt: {
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#333333",
      fontSize: "0.85vw",
      margin: "0px",
    },
  })
);
{/*@ts-ignore */}
interface Props {
  active: "Dashboard" | "MyClasses" | "JobListings" | "AppliedJobs" | "Messages" | "Profile" | "Settings" | "Assessments"; 
  styles?: any;
  navigation: any;
}
const DashSidebar1 = ({ active, styles, navigation }: Props) => {
  const classes = useStyles();
  const handleNav = (screen: string) => {
    navigation.navigate(screen);
  };
  return (
    <div className={classes.dash_nav_container} style={styles}>
      <div className={classes.dash_nav_c}>
      <div  data-test-id = {"Sidebar1Click"} onClick={() => {handleNav("TeacherDashboard");}} className={classes.dash_nav_item_c}>
          {active == "Dashboard" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}
          <p className={classes.dash_nav_item_txt} style={active == "Dashboard" ? { color: "#ff0000" } : {}}>
            Dashboard
          </p>
      </div>
     
        <div onClick={() => {handleNav("TeachersClasses");}}className={classes.dash_nav_item_c}>

          {active == "MyClasses" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}

          <p className={classes.dash_nav_item_txt} style={active == "MyClasses" ? { color: "#ff0000" } : {}}>
            My Classes
          </p>

        </div>

        <div onClick={() => {handleNav("TeachersAssessments");}}className={classes.dash_nav_item_c}>

          {active == "Assessments" ? (
              <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
            ) : (
          <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
            )}

          <p className={classes.dash_nav_item_txt} style={active == "Assessments" ? { color: "#ff0000" } : {}}>
          Assessments
          </p>

        </div>



        <div onClick={() => {handleNav("JobListing");}}
          className={classes.dash_nav_item_c}
        >

          {active == "JobListings" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}

          <p
            className={classes.dash_nav_item_txt}
            style={active == "JobListings" ? { color: "#ff0000" } : {}}
          >
            Job Listings
          </p>
          
        </div>
        <div
          onClick={() => {
            handleNav("AppliedJobs");
          }}
          className={classes.dash_nav_item_c}
        >
          {active == "AppliedJobs" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}
          <p
            className={classes.dash_nav_item_txt}
            style={active == "AppliedJobs" ? { color: "#ff0000" } : {}}
          >
            Applied Jobs
          </p>
        </div>
        <div
         onClick={() => {
          handleNav("TeacherMessages");
        }}
          className={classes.dash_nav_item_c}
        >
          {active == "Messages" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}
          <p className={classes.dash_nav_item_txt} style={active == "Messages" ? { color: "#ff0000" } : {}}>
            Messages
          </p>
        </div>
        <div
         onClick={() => {
          handleNav("TeachersProfile");
        }}
          className={classes.dash_nav_item_c}
        >
          {active == "Profile" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}
          <p className={classes.dash_nav_item_txt} style={active == "Profile" ? { color: "#ff0000" } : {}}>
            Profile
          </p>
        </div>
        <div
         onClick={() => {
          handleNav("TeachersSettings");
        }}
          className={classes.dash_nav_item_c}
        >
          {active == "Settings" ? (
            <img src={dashArrowActive} alt="" className={classes.dash_nav_item_img} />
          ) : (
            <img src={dashArrowInactive} alt="" className={classes.dash_nav_item_img} />
          )}
          <p className={classes.dash_nav_item_txt} style={active == "Settings" ? { color: "#ff0000" } : {}}>
            Settings
          </p>
        </div>
      </div>
    </div>
  );
};
export default DashSidebar1;
