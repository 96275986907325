import exp from "constants";

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const laptop = require("../assets/laptop.png");
export const boygirl = require("../assets/boygirl.png");
export const youngboy = require("../assets/youngboy.png");
export const freetag = require("../assets/freetag.png");
export const calender = require("../assets/calender.png");
export const twomembers = require("../assets/twomembers.png");
export const smallgirl = require("../assets/smallgirl.png");
export const book = require("../assets/book.png");
export const girllaptop = require("../assets/girllaptop.png");
export const boylaptop = require("../assets/boylaptop.png");
export const boyphone = require("../assets/boyphone.png");
export const tickmark = require("../assets/tickmark.png");
export const studentPicture = require("../assets/studentPicture.png");
export const ArrowButton = require("../assets/ArrowButton.png");
export const ArrowButtonYellow = require("../assets/ArrowButtonYellow.png");
export const progressiveBar = require("../assets/progressiveBar.png");
export const progressiveBar1 = require("../assets/progressiveBar1.png");
export const Women = require("../assets/Women.png");
export const GirlWithDesktop = require("../assets/GirlWithDesktop.png");
export const Bitmap = require("../assets/Bitmap.png");
export const groupvideo = require("../assets/groupvideo.png");
export const download = require("../assets/download.png");
export const thirdcard = require("../assets/thirdcard.png");
export const sidearrow = require("../assets/sidearrow.png");
export const redsidearrow = require("../assets/redsidearrow.png");
export const professor = require("../assets/professor.png");
export const notes = require("../assets/notes.png");
export const hours = require("../assets/hours.png");
export const Exercises = require("../assets/Exercises.png");
export const Reading = require("../assets/Reading.png");
export const videos = require("../assets/videos.png");
export const fourpoint = require("../assets/fourpoint.png");
export const fortypercentageImage = require("../assets/fortypercentageImage.png");
export const sixtyPercentageImage = require("../assets/sixtyPercentageImage.png");
export const boardwithBoy = require("../assets/boardwithBoy.png");
export const TableWithBook = require("../assets/TableWithBook.png");
export const openBook = require("../assets/openBook.png");
export const youngboy1 = require("../assets/youngboy.png");
export const smallgirl1 = require("../assets/smallgirl.png");
export const dropdown = require("../assets/dropdown.png");
export const hireteacher = require("../assets/hireteacher.png");
export const Avtar1 = require("../assets/Avtar1.png");
export const Avtar2 = require("../assets/Avtar2.png");
export const Avtar3 = require("../assets/Avtar3.png");
export const cardBoys = require("../assets/cardBoys.png");
export const boardWithMan = require("../assets/boardWithMan.png");
export const fullscreen = require("../assets/fullscreen.png");
export const playCenterBtn = require("../assets/playCenterBtn.png");
export const vidSetting = require("../assets/vidSetting.png");
export const volume = require("../assets/volume.png");
export const mute = require("../assets/mute.png");
export const pause = require("../assets/pause.png");
export const play = require("../assets/play.png");
export const volumeIcon = require("../assets/volume.png");
export const record = require("../assets/record.png");
export const back = require("../assets/back.png");
export const fastback = require("../assets/fastback.png");
export const fastforward = require("../assets/fastforward.png");
export const next = require("../assets/next.png");
export const carousal_inactive = require("../assets/carousal_inactive.png");
export const carousal_active = require("../assets/carousal_active.png");
export const carousal_leftArrow = require("../assets/carousal_leftArrow.png");
export const carousal_rightArrow = require("../assets/carousal_rightArrow.png");
export const loginbg = require("../assets/login-pana.png");
export const emailIcon = require("../assets/profile.png");
export const passwordIcon = require("../assets/key.png");

export const desktop = require("../assets/desktop.png");
export const screenView = require("../assets/screenView.png");
export const timeIcon = require("../assets/timeIcon.png");
export const defaultProfilePic = require("../assets/defaultProfilePic.png");
export const orangeViewIcon = require("../assets/orange_view_icon.png");
export const backArrow = require("../assets/backArrow.png");
export const ViewSubmissionListImg = require("../assets/ViewSubmissionListImg.png");


export const profileImg = require("../assets/ProfileImg.png");