import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
// Customizable Area Start

// Customizable Area End

let initialUserValues = {
  firstName: "",
  lastName: "",

  email: "",
  countryCode: "",
  mobileNumber: "",


  qualification: "",
  specialization: "",

  totalExperience: "",
  avalibility: "",


  languagesKnown: {
    english: true,
    hindi: false,
    others: true
  },
  otherLanguages: "",
  coursesOfInterests: "",
  cvFileName: ""
};
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  location?: any;
  jobDetails?: any
  // Customizable Area End
}

interface S {
  submitmodel: boolean;
  modalopen: boolean;
  teacherInputValues: any
  cvUploaded: any
  active_tab: any;
  errorStatusFirstName: any
  errorStatusLastName: any
  errorForEmail: any,
  errorForMaxPhone: boolean
  errorStatusspecialization: any
  errorForFirstName: any
  errorInLastName: any
  errorForPhone: any;
  errorFrspecialization: any


  teacherQualifactionListData: any
  teachersCourseInterestListData: any
  teachersExperienceListData: any
  teachersAvalibilityListData: any

  errorFrAvalibility: any
  errorFrQualification: any
  erorFrExperience: any
  errorFrCoursesInterest: any
  errorFrlanguagesKnown: any
  errorFrOtherlanguagesKnown: any
  errorcvUploaded: any




  teacherProfileData: any
  openAlreadyAppliedJob: any

}
interface SS {
  id: any;
}
// Customizable Area Start
export default class JobDetailContentController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  sampleAPIId: any;
  errStyle: any
  getProfileDetailsId: any;
  teachersQualificationId: any
  teachersCoursesInterestId: any
  teachersExperienceId: any
  teachersAvalibilityId: any
  applyJobCallId: any

  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      modalopen: false,
      submitmodel: false,
      teacherInputValues: initialUserValues,
      cvUploaded: "",
      errorStatusFirstName: false,
      errorStatusLastName: false,
      errorStatusspecialization: false,
      errorForEmail: false,
      errorForMaxPhone: false,
      errorFrspecialization: false,
      errorForPhone: false,
      errorForFirstName: false,
      errorInLastName: false,
      active_tab: false,
      errorFrAvalibility: false,
      errorFrQualification: false,
      erorFrExperience: false,
      errorFrCoursesInterest: false,
      errorFrlanguagesKnown: false,
      errorFrOtherlanguagesKnown: false,
      errorcvUploaded: false,

      teacherQualifactionListData: [],
      teachersCourseInterestListData: [],
      teachersExperienceListData: [],
      teachersAvalibilityListData: [],

      teacherProfileData: {},
      openAlreadyAppliedJob: false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };


    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    this.getTeacherProfileApplicationDetails()
    this.getTeacherQualificationDataList()
    this.getTeacherCoursesOfInterestListData()
    this.getTeacherExperienceListData()
    this.getTeacherAvalibilityListData()
  }
  // Customizable Area End



  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.handlegGetProfileDetailsId(from, message)

    this.handleteachersQualificationId(from, message)
    this.handleteachersCoursesInterestId(from, message)



    if (this.teachersExperienceId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        alert(apiResponse?.errors[0].token)
      } else {

        this.setState({
          teachersExperienceListData: apiResponse
        })
      }
    }
    if (this.teachersAvalibilityId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        alert(apiResponse?.errors[0].token)
      } else {

        this.setState({
          teachersAvalibilityListData: apiResponse
        })
      }
    }
    if (this.applyJobCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        alert(apiResponse?.errors[0].token)
      } else if (apiResponse?.message) {
        this.setState({ openAlreadyAppliedJob: true });
      } else {
        this.setState({ modalopen: false });
        this.setState({ submitmodel: true });
      }
    }



    // Customizable Area End
  }

  handlegGetProfileDetailsId = async (from: string, message: Message) => {
    if (this.getProfileDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          teacherProfileData: apiResponse.data,
        })

        const languagesArray = await apiResponse?.data?.attributes?.academic?.data?.attributes?.known_languages
        const otherlanguages = await apiResponse?.data?.attributes?.academic?.data?.attributes?.other_languages
        let isEnglishChecked = false
        let isHindiChecked = false
        if (languagesArray?.length) {
          isEnglishChecked = languagesArray[0].includes("english")
          isHindiChecked = languagesArray[0].includes("hindi")
        }
        else {
          isEnglishChecked = false
          isHindiChecked = false
        }
        const isOthersChecked = otherlanguages === "" ? false : true
        const { first_name, last_name, phone_number, country_code, qualification, email, } = await apiResponse?.data?.attributes
        const { specialization, interest, total_experience, cv_filename } = await apiResponse?.data?.attributes?.academic?.data?.attributes


        this.setState(prevState => ({
          ...prevState,
          teacherInputValues: {
            ...prevState.teacherInputValues,
            languagesKnown: {
              ...prevState.teacherInputValues.languagesKnown,
              english: isEnglishChecked,
              hindi: isHindiChecked,
              others: isOthersChecked
            },
            countryCode: country_code,
            firstName: first_name,
            lastName: last_name,
            mobileNumber: phone_number,
            qualification: qualification,
            specialization: specialization,
            coursesOfInterests: interest?.name,
            email: email,
            otherLanguages: otherlanguages,
            avalibility: "",
            totalExperience: total_experience,
            cvFileName: cv_filename
          }
        }))
      }
    }
  }

  handleteachersQualificationId = async (from: string, message: Message) => {
    if (this.teachersQualificationId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.error) {

        alert(apiResponse?.error)
      } else {
        this.setState({
          teacherQualifactionListData: apiResponse
        })
      }
    }
  }
  handleteachersCoursesInterestId = async (from: string, message: Message) => {
    if (this.teachersCoursesInterestId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        alert(apiResponse?.errors[0].token)
      } else {

        this.setState({
          teachersCourseInterestListData: apiResponse
        })
      }
    }
  }




  //profile Data
  getTeacherProfileApplicationDetails() {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };


    const profileDetailsFrApplicationMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileDetailsId = profileDetailsFrApplicationMsg.messageId;


    profileDetailsFrApplicationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailsEndpoint
    );

    profileDetailsFrApplicationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    profileDetailsFrApplicationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
    runEngine.sendMessage(profileDetailsFrApplicationMsg.id, profileDetailsFrApplicationMsg);

  }


  //qualification list
  getTeacherQualificationDataList() {

    const qualificationListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teachersQualificationId = qualificationListMsg.messageId;


    qualificationListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qualificationsEndpoint
    );


    qualificationListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );


    runEngine.sendMessage(qualificationListMsg.id, qualificationListMsg);


  }


  //courses list
  getTeacherCoursesOfInterestListData() {
    const getCoursesOfInterestListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teachersCoursesInterestId = getCoursesOfInterestListMsg.messageId;

    getCoursesOfInterestListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interestsEndpoint
    );

    getCoursesOfInterestListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getCoursesOfInterestListMsg.id, getCoursesOfInterestListMsg);

  }


  //teacher experienceList
  getTeacherExperienceListData() {
    const teachersExperienceMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teachersExperienceId = teachersExperienceMsg.messageId;

    teachersExperienceMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.experienceEndpoint
    );

    teachersExperienceMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(teachersExperienceMsg.id, teachersExperienceMsg);

  }

  //teacher Avalibility
  getTeacherAvalibilityListData() {
    const teachersAvalibilityMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teachersAvalibilityId = teachersAvalibilityMsg.messageId;

    teachersAvalibilityMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.avalibilityEndPoint
    );

    teachersAvalibilityMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(teachersAvalibilityMsg.id, teachersAvalibilityMsg);

  }




  checkValidationsInputs = () => {
    if (this.state.teacherInputValues.firstName?.length <= 0) {
      this.setState({ errorForFirstName: true });
    } else {
      this.setState({ errorForFirstName: false });
    }
    if (this.state.teacherInputValues.firstName?.length > 15) {
      this.setState({ errorStatusFirstName: true });
    } else {
      this.setState({ errorStatusFirstName: false });
    }
    //lastname validations
    if (this.state.teacherInputValues.lastName?.length <= 0) {
      this.setState({ errorInLastName: true });
    } else {
      this.setState({ errorInLastName: false });
    }
    if (this.state.teacherInputValues.lastName?.length > 15) {
      this.setState({ errorStatusLastName: true });
    } else {
      this.setState({ errorStatusLastName: false });
    }
  }
  checkValidationsInputs2 = () => {
    //Mobile Validation
    if (this.state.teacherInputValues.mobileNumber?.length <= 0) {
      this.setState({ errorForPhone: true });
    } else {
      this.setState({ errorForPhone: false });
    }
    if (this.state.teacherInputValues.mobileNumber?.length > 15) {
      this.setState({ errorForMaxPhone: true });
    } else {
      this.setState({ errorForMaxPhone: false });
    }
    //specilazation
    if (this.state.teacherInputValues.specialization?.length <= 0) {
      this.setState({ errorFrspecialization: true });
    } else {
      this.setState({ errorFrspecialization: false });
    }

    //avalibility
    if (this.state.teacherInputValues.avalibility?.length <= 0) {
      this.setState({ errorFrAvalibility: true });
    } else {
      this.setState({ errorFrAvalibility: false });
    }

    //errorFrQualification
    if (this.state.teacherInputValues.qualification?.length <= 0) {
      this.setState({ errorFrQualification: true });
    } else {
      this.setState({ errorFrQualification: false });
    }

    //erorFrExperience
    if (this.state.teacherInputValues.totalExperience?.length <= 0) {
      this.setState({ erorFrExperience: true });
    } else {
      this.setState({ erorFrExperience: false });
    }
  }
  checkValidationsInputs3 = () => {
    //errorFrCoursesInterest
    if (this.state.teacherInputValues.coursesOfInterests?.length <= 0) {
      this.setState({ errorFrCoursesInterest: true });
    } else {
      this.setState({ errorFrCoursesInterest: false });
    }
    //errorforlanguages
    if (!this.state.teacherInputValues.languagesKnown.english && !this.state.teacherInputValues.languagesKnown.hindi && !this.state.teacherInputValues.languagesKnown.others) {
      this.setState({ errorFrlanguagesKnown: true });
    } else {
      this.setState({ errorFrlanguagesKnown: false });
    }
    if (this.state.teacherInputValues.languagesKnown.others && this.state.teacherInputValues.otherLanguages?.length <= 0) {
      this.setState({ errorFrOtherlanguagesKnown: true });
    } else {
      this.setState({ errorFrOtherlanguagesKnown: false });
    }

    if (this.state.cvUploaded?.length <= 0 && this.state.teacherInputValues.cvFileName?.length <= 0) {
      this.setState({ errorcvUploaded: true });
    } else {
      this.setState({ errorcvUploaded: false });
    }

  }
  //apply for the job 
  handleApplyApplicationBtn = () => {

    this.checkValidationsInputs()
    this.checkValidationsInputs2()
    this.checkValidationsInputs3()

    //email validations
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const VEmail = this.state.teacherInputValues.email.match(regexEmail)
    if (this.state.teacherInputValues.email?.length <= 0 || VEmail == null) {
      this.setState({ errorForEmail: true });
    } else {
      this.setState({ errorForEmail: false });
    }

    if (this.state.teacherInputValues.firstName?.length <= 0 || this.state.teacherInputValues.lastName?.length <= 0 ||
      this.state.teacherInputValues.firstName?.length >= 15 || this.state.teacherInputValues.lastName?.length >= 15 ||
      (this.state.teacherInputValues.email?.length <= 0 || VEmail == null)
      || this.state.teacherInputValues.mobileNumber?.length <= 0 || this.state.teacherInputValues.mobileNumber?.length >= 15 ||
      this.state.teacherInputValues.specialization?.length <= 0 || this.state.teacherInputValues.specialization?.length > 20 ||
      this.state.teacherInputValues.avalibility?.length <= 0 || this.state.teacherInputValues.qualification?.length <= 0 ||
      this.state.teacherInputValues.totalExperience?.length <= 0 || this.state.teacherInputValues.coursesOfInterests?.length <= 0 ||
      (!this.state.teacherInputValues.languagesKnown.english && !this.state.teacherInputValues.languagesKnown.hindi && !this.state.teacherInputValues.languagesKnown.others) ||
      (this.state.teacherInputValues.languagesKnown.others && this.state.teacherInputValues.otherLanguages?.length <= 0) ||
      (this.state.cvUploaded?.length <= 0 && this.state.teacherInputValues.cvFileName?.length <= 0)

    ) {
      return
    }

    //api call from here
    const hireTeacherRequestId = localStorage.getItem("jobdetialId");
    const formData = new FormData();
    formData.append("hire_teacher_request_id", String(hireTeacherRequestId));

    formData.append("first_name", this.state.teacherInputValues.firstName)
    formData.append("last_name", this.state.teacherInputValues.lastName);
    formData.append("email", this.state.teacherInputValues.email);
    formData.append("full_phone_number", `${this.state.teacherInputValues.countryCode + this.state.teacherInputValues.mobileNumber}`);

    formData.append("qualification", this.state.teacherInputValues.qualification);
    formData.append("specialization", this.state.teacherInputValues.specialization);
    formData.append("total_experience", this.state.teacherInputValues.totalExperience);
    formData.append("availability", this.state.teacherInputValues.avalibility);


    let knownlanguages = ""
    if (this.state.teacherInputValues.languagesKnown.english && this.state.teacherInputValues.languagesKnown.hindi) {
      knownlanguages = "english,hindi"
    }
    else if (this.state.teacherInputValues.languagesKnown.english) {
      knownlanguages = "english"
    }
    else if (this.state.teacherInputValues.languagesKnown.hindi) {
      knownlanguages = "english"
    }
    formData.append("known_languages[]", knownlanguages);
    formData.append("other_languages", this.state.teacherInputValues.otherLanguages);
    formData.append("course_of_interests", this.state.teacherInputValues.coursesOfInterests);

    if (this.state.cvUploaded) {
      formData.append("cv", this.state.cvUploaded);
    }


    //api call from here
    const usertkn = localStorage.getItem("loginT");
    const headers = {
      token: usertkn,
    };

    const applyJobRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.applyJobCallId = applyJobRequestMsg.messageId;


    applyJobRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyJobEndpoint
    );

    applyJobRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    applyJobRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    applyJobRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(applyJobRequestMsg.id, applyJobRequestMsg);





  }

  //handle resetButton
  handleResetBtn = () => {
    this.setState(prevState => ({
      ...prevState,
      teacherInputValues: {
        ...prevState.teacherInputValues,
        languagesKnown: {
          ...prevState.teacherInputValues.languagesKnown,
          english: true,
          hindi: false,
          others: false
        },
        countryCode: 91,
        firstName: "",
        lastName: "",
        mobileNumber: "",
        qualification: "",
        specialization: "",
        coursesOfInterests: "",
        email: "",
        otherLanguages: "",
        totalExperience: "",
        cvFileName: "",
        avalibility: ""
      },
      cvUploaded: ""
    }))
  }




  updateInputChange = (e: any) => {
    const { value, name } = e.target
    if (name == "firstName") {
      const re = /^[A-Za-z]+$/;
      if (value === "" || re.test(value)) {
        this.setState({
          teacherInputValues: {
            ...this.state.teacherInputValues,
            [name]: value,
          }
        });
      }
      if (value?.length > 15) {
        this.setState({ errorStatusFirstName: true });
      } else {
        this.setState({ errorStatusFirstName: false });
      }
    }
    else if (name == "lastName") {
      const re = /^[A-Za-z]+$/;
      if (value === "" || re.test(value)) {
        this.setState({
          teacherInputValues: {
            ...this.state.teacherInputValues,
            [name]: value,
          }
        });
      }

      if (value?.length > 15) {
        this.setState({ errorStatusLastName: true });
      } else {
        this.setState({ errorStatusLastName: false });
      }

    }

  }

  updateInputChange2 = (e: any) => {
    const { value, name } = e.target
    if (name == "email") {
      this.setState({
        teacherInputValues: {
          ...this.state.teacherInputValues,
          [name]: value,
        }
      });

      const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      const VEmail = this.state.teacherInputValues.email.match(regexEmail)

      if (this.state.teacherInputValues.email?.length <= 0 || VEmail == null) {
        this.setState({ errorForEmail: true });

      } else {
        this.setState({ errorForEmail: false });
      }
    }
  }

  updateInputChange3 = (e: any) => {
    const { value, name } = e.target
    if (name == "mobileNumber") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        this.setState({
          teacherInputValues: {
            ...this.state.teacherInputValues,
            [name]: value,
          }
        });
      }

      if (this.state.teacherInputValues.mobileNumber?.length > 15) {
        this.setState({ errorForMaxPhone: true });
      } else {
        this.setState({ errorForMaxPhone: false });
      }
    }

    else if (name == "specialization") {
      const re = /^[A-Za-z]+$/;
      if (value === "" || re.test(value)) {
        this.setState({
          teacherInputValues: {
            ...this.state.teacherInputValues,
            [name]: value,
          }
        });
      }

      if (value?.length > 20) {
        this.setState({ errorStatusspecialization: true });
      } else {
        this.setState({ errorStatusspecialization: false });
      }

    }

    else {
      this.setState({
        teacherInputValues: {
          ...this.state.teacherInputValues,
          [name]: value,
        }
      });
    }

  }






  //input changes
  handleteacherInputChange = async (e: any) => {
    this.updateInputChange(e)
    this.updateInputChange2(e)
    this.updateInputChange3(e)
  }

  handleCheckBoxChanges = (event: any) => {
    const { name, checked } = event.target
    console.log(name)
    console.log(checked, "+++++++++++++++++++++++++++++++++++++")

    this.setState(prevState => ({
      ...prevState,
      teacherInputValues: {
        ...prevState.teacherInputValues,
        languagesKnown: {
          ...prevState.teacherInputValues.languagesKnown,
          [name]: checked,
        }
      }
    }))
    if (name == "others" && checked === false) {
      this.setState(prevState => ({
        ...prevState,
        teacherInputValues: {
          ...prevState.teacherInputValues,
          otherLanguages: "",
        }
      }))
    }

  }


  handleUploadedCvFile = (e: any) => {
    const files = e.target.files[0];
    this.setState({ cvUploaded: files })

  }


  handleApplyJobModal = () => {
    this.setState({ modalopen: true });
  };

  handleClose = () => {
    this.setState({ modalopen: false });
  }

  // after submited model Start

  submithandleOpen = () => {
    this.setState({ submitmodel: true });
  };

  submithandleClose = () => {
    this.setState({ submitmodel: false });
  };

  getFileNAME = () => {
    if (this.state.cvUploaded) {
      return <label>{this.state.cvUploaded.name}</label>
    }
    else if (this.state.teacherInputValues.cvFileName?.length <= 0) {
      return <label>Upload Cv</label>
    } else {
      return <label>{this.state.teacherInputValues.cvFileName}</label>
    }

  }

  checkForTheStyle = (status: any) => {
    if (status) {
      return (this.errStyle)
    }
    else {
      return {}
    }

  }

  checkForTheValue = (status: any) => {
    if (status) {
      return (<p className="prl-input-err-txt">Please Select a value.</p>)
    }
    else {
      return (<></>)
    }
  }
  checkForTheEnterValues = (status: any) => {
    if (status) {
      return (<p className="prl-input-err-txt">Please enter a value. </p>)
    }
    else {
      return (<></>)
    }
  }


}
 // Customizable Area End