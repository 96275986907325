import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { SubjectSubmissionList } from "../../../components/src/Utils/APIResponseResults";
export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative?: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  submissionsDetails: any;
  subjectDetails: any;
  subjectDetailsLoading: boolean;
  subjectSubmissionListLoading: boolean;
  subjectSubmissionList: any;
  submissionSubjectId: any;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ViewSubmissionListController extends BlockComponent<Props, S, SS> {
  ViewSubmissionSubjectCallId: any;
  ViewSubmissionListCallId: any;
  removeFromTheListCallId: any;
  AcceptSubmissionCallId: any;
  RejectSubmissionCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      submissionsDetails: [],
      subjectDetails: {},
      subjectDetailsLoading: true,
      subjectSubmissionListLoading: true,
      subjectSubmissionList: [],
      submissionSubjectId: 0,
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.ViewSubmissionSubjectCallId) {
          this.handleViewSubSubjectResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.ViewSubmissionListCallId) {
          this.handleViewSubListResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.AcceptSubmissionCallId) {
          this.handleAcceptSubmissionResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.RejectSubmissionCallId) {
          this.handleRejectSubmissionResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.removeFromTheListCallId) {
          this.handleRemoveFromListResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  handleViewSubSubjectResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({ subjectDetailsLoading: false });
      console.log("ViewSubmissionSubjectCallId_Response", responseJson.data.attributes);
      this.setState({
        subjectDetails: responseJson.data.attributes,
      });
      console.log("subjectDetailsState", this.state.subjectDetails);
    } else {
      if (responseJson?.errors[0]?.token == "Token has Expired") {
        this.props.navigation.navigate("EmailLogin");
      }
      this.setState({ subjectDetailsLoading: false });
      console.log("ERR_ViewSubmissionSubjectCallId_Response", responseJson);
    }
  };
  handleViewSubListResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.setState({ subjectSubmissionListLoading: false });
      this.setState({
        subjectSubmissionList: responseJson.data,
      });
      console.log("subjectDetailsState", this.state.subjectSubmissionList);
      console.log("ViewSubmissionListCallId_Response", responseJson);
      if (responseJson?.message == "No Submissions.") {
        this.setState({
          subjectSubmissionList: responseJson,
        });
      }
    } else {
      this.setState({ subjectSubmissionListLoading: false });
      console.log("ERR_ViewSubmissionListCallId_Response", responseJson);
      alert(JSON.stringify(responseJson.errors[0][0]));
    }
  };
  handleAcceptSubmissionResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.getSubmissionList();
      console.log("acceptSubmission_Response", responseJson);
    } else {
      console.log("ERR_acceptSubmission_Response", responseJson);
      alert(JSON.stringify(responseJson.errors[0][0]));
    }
  };
  handleRejectSubmissionResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.getSubmissionList();
      console.log("rejectSubmission_Response", responseJson);
    } else {
      console.log("ERR_RejectSubmission_Response", responseJson);
      alert(JSON.stringify(responseJson.errors[0][0]));
    }
  };
  handleRemoveFromListResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      this.getSubmissionList();
      console.log("RemoveFromList_Response", responseJson);
    } else {
      console.log("ERR_RemoveFromList_Response", responseJson);
      alert(JSON.stringify(responseJson.errors[0][0]));
    }
  };

  getViewSubmissionSubject = async () => {
    this.setState({ subjectDetailsLoading: true });
    console.log("getViewSubmissionSubject");
    const token = localStorage.getItem("loginT");
    const endPoint = `${configJSON.ViewSubmissionSubjectEndpoint}?id=${this.state.submissionSubjectId}`;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ViewSubmissionSubjectCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpMethodType);
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getSubmissionList = async () => {
    this.setState({ subjectSubmissionListLoading: true });
    console.log("getSubmissionList");
    const endPoint = `${configJSON.ViewSubmissionListEndpoint}?hiring_request_id=${this.state.submissionSubjectId}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getSubmissionListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ViewSubmissionListCallId = getSubmissionListMsg.messageId;

    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getSubmissionListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
    runEngine.sendMessage(getSubmissionListMsg.id, getSubmissionListMsg);
  };

  putAcceptSubmission = async (id: any) => {
    console.log("getSubmissionList");
    const endPoint = `${configJSON.SubmissionAcceptEndpoint}?application_id=${id}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getSubmissionListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.AcceptSubmissionCallId = getSubmissionListMsg.messageId;

    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);
    runEngine.sendMessage(getSubmissionListMsg.id, getSubmissionListMsg);
  };

  putRejectSubmission = async (id: any) => {
    console.log("getSubmissionList");
    const endPoint = `${configJSON.SubmissionRejectEndpoint}?application_id=${id}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getSubmissionListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.RejectSubmissionCallId = getSubmissionListMsg.messageId;

    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getSubmissionListMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);
    runEngine.sendMessage(getSubmissionListMsg.id, getSubmissionListMsg);
  };

  removeFromTheList = async (id: any) => {
    console.log("getSubmissionList");
    const endPoint = `${configJSON.removeFromTheListEndPoint}?application_id=${id}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const removeFromTheListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.removeFromTheListCallId = removeFromTheListMsg.messageId;

    removeFromTheListMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    removeFromTheListMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    removeFromTheListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
    runEngine.sendMessage(removeFromTheListMsg.id, removeFromTheListMsg);
  };

  componentDidMount = async () => {
    console.log("componentDidMount ViewSubmissionListController");
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    const ViewSubmittionId = this.props.navigation.getParam("hiringPostId");
    await this.setState({
      submissionSubjectId: ViewSubmittionId,
    });
    this.getViewSubmissionSubject();
    this.getSubmissionList();
  };

  handleAddContract = (id: any) => {
    this.props.navigation.navigate("AddContractInfo", { jobApplication: id });
  };
  handleDownload = (data: any) => {
    console.log(data);
    fetch(data?.cv).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${data?.cv_filename}`;
        a.click();
      });
    });
  };
}
