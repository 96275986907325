import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  history: any;
  location: any;
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
}

export interface S {
  // Customizable Area Start
  qualification: string;
  currentlyWorking: string;
  careerGoal: string;
  AreaOfInt: string;
  errQualification: boolean;
  errCurrentlyworking: boolean;
  errCareerGoal: boolean;
  errAreaOfInt: boolean;
  lQualification: any[];
  lInterest: any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LearnerSignUpStep2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiQualificationListCallId_LSS2: any;
  apiInterestListCallId_LSS2: any;
  apiAddAcademicDeatailsCallId_LSS2: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      qualification: "",
      currentlyWorking: "",
      careerGoal: "",
      AreaOfInt: "",
      errQualification: false,
      errCurrentlyworking: false,
      errCareerGoal: false,
      errAreaOfInt: false,
      lQualification: [],
      lInterest: [],
    };

    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId_LSS2 = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson_LSS2 = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId_LSS2 && responseJson_LSS2) {
        if (apiRequestCallId_LSS2 === this.apiQualificationListCallId_LSS2) {
          if (!responseJson_LSS2.errors && !responseJson_LSS2.error) {
            console.log("Qualification_Response", responseJson_LSS2);
            await this.setState({ lQualification: responseJson_LSS2 });
          } else {
            console.log("ERR_Qualification_Response", responseJson_LSS2);
            alert(JSON.stringify(responseJson_LSS2.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId_LSS2 === this.apiInterestListCallId_LSS2) {
          if (!responseJson_LSS2.errors && !responseJson_LSS2.error) {
            console.log("Interest_Response", responseJson_LSS2);
            this.setState({ lInterest: responseJson_LSS2 });
          } else {
            console.log("ERR_Interest_Response", responseJson_LSS2);
            alert(JSON.stringify(responseJson_LSS2.errors[0][0]));
          }
        } else if (apiRequestCallId_LSS2 === this.apiAddAcademicDeatailsCallId_LSS2) {
          if (!responseJson_LSS2.errors && !responseJson_LSS2.error) {
            console.log("Academic_Response", responseJson_LSS2);
            this.props.navigation.navigate("RegSuccess");
          } else {
            console.log("ERR_Academic_Response", responseJson_LSS2);
            alert(JSON.stringify(responseJson_LSS2.errors[0][0]));
          }
        }
      }
    }
    // Customizable Area End
  }

  getQualification = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiQualificationListCallId_LSS2 = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qualificationAPiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getInterest = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiInterestListCallId_LSS2 = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interestAPiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  componentWillMount = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    await this.getQualification();
    await this.getInterest();
  };

  errStyle = {
    backgroundColor: "#fcecec",
    borderColor: "#fb7b7c",
  };

  async handleQualificationChange(e: any) {
    await this.setState({ qualification: e.target.value });
    if (this.state.qualification.length <= 0 || this.state.qualification == "Select") {
      await this.setState({ errQualification: true });
    } else {
      await this.setState({ errQualification: false });
    }
  }

  async handleCurrentWorkingChange(e: any) {
    await this.setState({ currentlyWorking: e.target.value });
    if (this.state.currentlyWorking.length <= 0 || this.state.currentlyWorking == "Select") {
      await this.setState({ errCurrentlyworking: true });
    } else {
      await this.setState({ errCurrentlyworking: false });
    }
  }

  async handleCareerGChange(e: any) {
    await this.setState({ careerGoal: e.target.value });
    if (this.state.careerGoal.length <= 0) {
      await this.setState({ errCareerGoal: true });
    } else {
      await this.setState({ errCareerGoal: false });
    }
  }

  async handleAreaOfIntChange(e: any) {
    await this.setState({ AreaOfInt: e.target.value });
    if (this.state.AreaOfInt.length <= 0 || this.state.AreaOfInt == "Select") {
      await this.setState({ errAreaOfInt: true });
    } else {
      await this.setState({ errAreaOfInt: false });
    }
  }

  async handleSubmit() {
    if (this.state.qualification.length <= 0 || this.state.qualification == "Select") {
      await this.setState({ errQualification: true });
    } else {
      await this.setState({ errQualification: false });
    }
    if (this.state.currentlyWorking.length <= 0 || this.state.currentlyWorking == "Select") {
      await this.setState({ errCurrentlyworking: true });
    } else {
      await this.setState({ errCurrentlyworking: false });
    }
    if (this.state.careerGoal.length <= 0) {
      await this.setState({ errCareerGoal: true });
    } else {
      await this.setState({ errCareerGoal: false });
    }
    if (this.state.AreaOfInt.length <= 0 || this.state.AreaOfInt == "Select") {
      await this.setState({ errAreaOfInt: true });
    } else {
      await this.setState({ errAreaOfInt: false });
    }

    if (
      this.state.errQualification === false &&
      this.state.errCurrentlyworking === false &&
      this.state.errCareerGoal === false &&
      this.state.errAreaOfInt === false
    ) {
      const UDetails: any = JSON.parse(localStorage.getItem("SignUpToken") || "{}");
      const token = UDetails;

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: token,
      };

      const httpBody = {
        qualification_id: this.state.qualification,
        career_goals: this.state.careerGoal,
        currently_working: this.state.currentlyWorking === "Yes" ? true : false,
        interest_id: this.state.AreaOfInt,
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiAddAcademicDeatailsCallId_LSS2 = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.academicAPiEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.signupAPiMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }
  handleLoginNav = () => {
    this.props.navigation.navigate("EmailLogin");
  };
}
