import React from 'react'
import { Button, Typography, Checkbox } from "@material-ui/core";
import { createStyles, withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { unCheckedTeach, checkedTeach} from "./assets";



 const useStyles = makeStyles(theme => ({
    root: {},

    redbutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      padding: "1.2em 4.5em 1.2em",
      color: "#fff",
      textTransform: "none",
      height: "80%",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    add_contract_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
      width: "85%",
      marginBottom:"20px"
    },
    buttonSection: {
      display: "flex",
      marginTop: "1.5em",
      justifyContent: "space-between",
      width: "85%",
      marginBottom: "10em",
    },
    CA_checkbox_container: {
    //   display: "flex",
      alignItems: "center",
      marginBottom: "1em",
    },
    CA_checkbox: {
      marginRight: "0.5em",
      "&.MuiIconButton-root": {
        padding: 0,
      },
    },
    CA_checkbox_Img: {
      width: "0.65em",
    },
    CA_Checkbox_label: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "1em",
    },
    questionContainer: {
      display: "flex",
      width: "85%",
      backgroundColor: "#F7F7F7",
      flexDirection: "column",
      marignTop: "2em",
      padding: "3em 2em 1em",
      marginBottom: "12em",
    },
    question: {
      color: "#14292D",
      fontSize: 20,
      fontWeight: 550,
      marginBottom: "1em",
    },
    sectionName: {
      color: "#14292D",
      fontSize: 22,
      fontWeight: 550,
      marginTop: "2em",
      marginBottom: "2em",
    },
    CA_Dropdown_C: {
      position: "relative",
      width: "60%",
      marginTop: "1em",
      marginBottom: "2em",
    },
    CA_Dropdown_select: {
      width: "100%",
      height: "3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
    },
    CA_Dropdown_select_arrow: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "2em",
      height: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      marginTop: "0.2em",
      marginRight: "0.2em",
      pointerEvents: "none",
    },
    MC_Question_input: {
      width: "100%",
      boxSizing: "border-box",
      color: "#000000",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.3em",
      paddingTop: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.4em",
      "&:focus::placeholder": {
        color: "transparent",
      },
    },
    textArea: {
      position: "relative",
      width: "80%",
      marginBottom: "1em",
    },
}));


function QuestionShow(props:{questionData:any[],activeQuestiionIndex : number}) {
    const classes = useStyles();
    let { questionData, activeQuestiionIndex} = props
    console.log("questionData[activeQuestiionIndex]",questionData)
    if(!questionData.length){
            return null     
    }

    console.log("questionData",questionData)

    if(questionData[activeQuestiionIndex].attributes.question_type === "mcq"){
        return <div className={classes.CA_checkbox_container}>
        <Typography className={classes.question}>

            {questionData[activeQuestiionIndex]?.attributes?.description}
        </Typography>
            {/* <div style={{display:"flex"}}>
            <Checkbox
                // checked={questionData[activeQuestiionIndex]}
                icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
            //   onClick={() => {checkedHandler(index);}}
                className={classes.CA_checkbox}
             // onChange={this.handleChange(index)}
                />
              <Typography className={classes.CA_Checkbox_label}>
                    {questionData[activeQuestiionIndex]?.attributes?.answer_options?.map((option: any, index: any) => {
                     return (
              <Typography style={{padding:"10px"}}>
                  {option?.description}
              </Typography>
           
          );
        })}
      </Typography>
            </div> */}
            <div >
            {questionData[activeQuestiionIndex]?.attributes?.answer_options?.map((option: any, index: any) => {
                     return (
                        <div style={{display:"flex"}}>
                          <Checkbox
                            checked={option?.is_correct}
                            icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                            checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
                        //   onClick={() => {checkedHandler(index);}}
                            className={classes.CA_checkbox}
                        // onChange={this.handleChange(index)}
                           />

                            <Typography style={{padding:"10px"}}>
                                {option?.description}
                            </Typography>
                        </div>
                        
           
          );
        })}
            </div>
        
    </div>
    }
    if(questionData[activeQuestiionIndex].attributes.question_type === 'drop_down'){
        return <div className={classes.CA_checkbox_container}>
        <Typography className={classes.question}>

            {questionData[activeQuestiionIndex]?.attributes?.description}
        </Typography>

        <Typography className={classes.CA_Checkbox_label}>
          {questionData[activeQuestiionIndex]?.attributes?.answer_options?.map((option: any, index: any) => {
            return (
                <Typography style={{padding:"10px"}}>
                    {option?.description}
                </Typography>
             
            );
          })}
        </Typography>
    </div>
        // return (
       
        //   <>
        //   <Typography className={classes.question}>
        //       {questionData[activeQuestiionIndex].attributes?.description}
        //       </Typography>
        //       <div className={classes.CA_Dropdown_C}>
        //         <select
        //           className={classes.CA_Dropdown_select}
        //         //   value={this.state.dropDwnanswer}
        //         //   onChange={(e: any) => {
        //         //     this.handleDropDownChange(e)
        //         //   }}
        //         >
        //           <option value={"select_below"}>Select below</option>
        //           {/* {this.state.assessmentViewQuestion?.questions?.data.map((option: any, index: any) => {
        //             return (
        //               <option key={index} value={option.id}>{option.description}</option>
        //             );
        //           })} */}

        //         </select>
        //         <span className={classes.CA_Dropdown_select_arrow}>
        //           {/* <img src={dropdown} alt="arrow" /> */}
        //         </span>
        //       </div>
        //   </>
        // )
    }
    if(questionData[activeQuestiionIndex].attributes.question_type === 'descriptive'){
        return <div className={classes.CA_checkbox_container}>
        <Typography className={classes.question}>

            {questionData[activeQuestiionIndex]?.attributes?.description}
        </Typography>

        {/* <Typography className={classes.CA_Checkbox_label}>
          {questionData[activeQuestiionIndex]?.attributes?.answer_options?.map((option: any, index: any) => {
            return (
                <Typography style={{padding:"10px"}}>
                    {option?.description}
                </Typography>
             
            );
          })}
        </Typography> */}
        <div className={classes.textArea}>
             <textarea
                 className={classes.MC_Question_input}
                   value={questionData[activeQuestiionIndex]?.attributes?.answer_options?.description}
                  // required
                    //  onChange={(e: any) => {
                    //   this.handletextAreaChange(e)
                    // }}
                    // placeholder="Type Question"
                     rows={5}
                   />
                </div>
    </div>
    }



  return (
    <></>
  )
}

export default QuestionShow