import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
otp: string;
otpMessage: string;
otpAuthToken: string;
userAccountID: string;
toMessage: string;
isFromForgotPassword: boolean;
resendOTP: string;
token: any;
mobileNo: any;
registerWise: any;
firstName: any;
lastName: any;
email: any;
role: any;
dateOfBirth: any;
country: any;
city: any;
state: any;
address: any;
password: any;
passwordConfirmation: any;
forgotOtp: any;
forgotOtpMessage: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitOtpCallId: any;
  resendOtpCallId: any;
  placeHolderOtp: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpMessage: "",
      otpAuthToken: "",
      userAccountID: "",
      resendOTP: configJSON.resendOTP,
      toMessage: "",
      isFromForgotPassword: false,
      token: "",
      mobileNo: "",
      registerWise: "",
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      dateOfBirth: "",
      country: "",
      city: "",
      state: "",
      address: "",
      password: "",
      passwordConfirmation: "",
      forgotOtp: "",
      forgotOtpMessage: ""
    };

    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const uDetails: any = JSON.parse(await getStorageData("SignUpUserDetails") || "{}");
    this.setState({
      address: uDetails.address,
      city: uDetails.city,
      country: uDetails.country,
      dateOfBirth: uDetails.date_of_birth,
      email: uDetails.email,
      firstName: uDetails.first_name,
      mobileNo: uDetails.full_phone_number,
      lastName: uDetails.last_name,
      password: uDetails.password,
      passwordConfirmation: uDetails.password_confirmation,
      role: uDetails.role,
      state: uDetails.state,
    })
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    console.log('@@@ API MESSAGE CREATE ACCOUNT =================', message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      console.log('responseJson', responseJson)
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.submitOtpCallId) {
          this.submitOtpSuccessCallBack(responseJson)
        }
        else if (apiRequestCallId === this.resendOtpCallId) {
          this.resendOTPSuccessCallBack(responseJson)
        }

      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.submitOtpCallId) {
          this.submitOtpFailureCallBack(responseJson)
        }
        else if (apiRequestCallId === this.resendOtpCallId) {
          this.resendOTPFailureCallBack(responseJson)
        }

      } else if (errorReponse) {
        console.log('errorReponse', errorReponse)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  submitOtpSuccessCallBack = async (responseJson: any) => {
    console.log('@@@ Submit OTP Success CallBack =================', responseJson)
    await setStorageData("Role", JSON.stringify(responseJson.data.attributes.user_role));
    await setStorageData("SignUpToken", JSON.stringify(responseJson.meta.token));
    this.props.navigation.navigate("OtpSuccess");
  };

  submitOtpFailureCallBack = async (responseJson: any) => {
    console.log('@@@ Submit OTP failure callBack =================', responseJson)
    if (responseJson.errors[0].pin == "Invalid Pin for Phone Number") {
      this.setState({ otpMessage: "Please Enter Valid Otp" })
    }
  };
  resendOTPSuccessCallBack = async (responseJson: any) => {
    console.log('@@@ Resend OTP Success CallBack =================', responseJson)
    await setStorageData("SignUpToken", JSON.stringify(responseJson.meta.token));
  };

  resendOTPFailureCallBack = async (responseJson: any) => {
    console.log('@@@ Resend OTP failure callBack =================', responseJson)
    if (responseJson.errors[0].account) {
      if (
        responseJson.errors[0].account ==
        "Account already exists, Verification code has been sent on your phone number, Please verify your phone number number to activate your account"
      ) {
        await setStorageData("SignUpToken", JSON.stringify(responseJson.data.meta.token));
        alert("OTP has been sent your phone.");
      } else if (
        responseJson.errors[0].account == "Account already registerd with entered mobile number"
      ) {
        alert(responseJson.errors[0].account);
      }
    }
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data
    const header = {
      'Content-Type': contentType,
      token: this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log('@@@ requestMessage ========', requestMessage)
    return requestMessage.messageId;
  }

  handleSubmit = async () => {
    if (this.state.otp.length < 4) {
      this.setState({ otpMessage: "Please Enter Your OTP" })
    } else if (this.state.otp.length === 4) {
      const UDetails: any = JSON.parse(await getStorageData("SignUpToken") || "{}");
      const token = UDetails;
      console.log("Token", token);
      const header = {
        "Content-Type": "multipart/form-data",
        token: token,
      };
      let formdata = new FormData();
      formdata.append("pin", this.state.otp)
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.submitOtpCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.submitOTP);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiVerifyOtpMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      console.log("httpBody", formdata);
      return true;
    }
  };
  resendOtp = () => {
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      full_phone_number: this.state.mobileNo,
      role: this.state.role,
      date_of_birth: this.state.dateOfBirth,
      country: this.state.country,
      city: this.state.city,
      state: this.state.state,
      address: this.state.address,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
    };
    console.log("attribute", attrs);

    const data = {
      type: "sms_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resendOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.resendOTP);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiVerifyOtpMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("data", data);
    return true;
  };
  goBack = () => {
    this.props.navigation.goBack()
  };
  goBackCfDedication = () => {
    this.props.navigation.navigate('CfDedicatedRegistrationForm2')
  };

  // Customizable Area End
}
