import { Grid } from "@material-ui/core";
import React from "react";
import CourseCard from "./CourseCard.web";

interface Props {
  posts: any[];
  navigateToCoursesDetails: any;
}

const CoursesListPageItem = ({ posts, navigateToCoursesDetails }: Props) => {
  console.log("posts", posts);
  return (
    <div style={{ width: "100%" }}>
      {posts?.length > 0 ? (
        <Grid
          style={{
            marginTop: "3em",
            width: "100%",
            columnGap: "1.5vw",
            rowGap: "1.5vw",
            marginBottom: "1.5em",
          }}
          justifyContent="flex-start"
          container
        >
          {posts.map((post: any) => (
            <div key={post.id}>
              <CourseCard
                start_date={post?.start_date}
                handleBuyCourse={navigateToCoursesDetails}
                image_url={post?.image_url}
                course_cost={post?.course_cost}
                handleViewCourse={navigateToCoursesDetails}
                is_paid={post?.is_paid}
                course_description={post?.course_description}
                selected_count={post?.selected_count}
                name={post?.name}
                id={post?.id}
                course_type={post?.course_type}
              />
            </div>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            textAlign: "center",
            margin: "2em",
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1.5em",
          }}
        >
          No Course Found
        </div>
      )}
    </div>
  );
};

export default CoursesListPageItem;
