export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const laptop = require("../assets/laptop.png");
export const boygirl = require("../assets/boygirl.png");
export const freetag = require("../assets/freetag.png");
export const calender = require("../assets/calender.png");
export const twomembers = require("../assets/twomembers.png");
export const smallgirl = require("../assets/smallgirl.png");
export const book = require("../assets/book.png");
export const girllaptop = require("../assets/girllaptop.png");
export const boylaptop = require("../assets/boylaptop.png");
export const boyphone = require("../assets/boyphone.png");
export const tickmark = require("../assets/tickmark.png");
export const BoyBook = require("../assets/BoyBook.png");
export const womenLaptop = require("../assets/womenLaptop.png");
export const tableBook = require("../assets/tableBook.png");
export const BoywithLaptop = require("../assets/BoywithLaptop.png");
export const girlWithPhone = require("../assets/girlWithPhone.png");
export const ManWomen = require("../assets/ManWomen.png");
export const Girlgirl = require("../assets/Girl&girl.png");
export const student = require("../assets/student.png");
export const InstructorPic = require("../assets/InstructorPic.png");
export const syllabusIcon = require("../assets/syllabusIcon.png");
export const back = require("../assets/back.png");
export const next = require("../assets/next.png");
export const fastback = require("../assets/fastback.png");
export const fastforward = require("../assets/fastforward.png");
export const dropdown = require("../assets/dropdown.png");
export const contactUsSec2Img = require("../assets/contactUsSec2Img.png");
export const contactusPhone = require("../assets/contactusPhone.png");
export const contactUsLoc = require("../assets/contactUsLoc.png");
export const contactUsEmail = require("../assets/contactUsEmail.png");
export const contactUsExpand = require("../assets/contactUsExpand.png");
export const contactUsCollapse = require("../assets/contactUsCollapse.png");
export const dashArrowInactive = require("../assets/dashArrowInactive.png");
export const dashArrowActive = require("../assets/dashArrowActive.png");
export const DashAdImg = require("../assets/DashAdImg.png");
export const contractTeacherDp = require("../assets/contractTeacherDp.png");
export const techfinimg = require("../assets/techfinimg.png");
export const time = require("../assets/time.png");
export const tvimg = require("../assets/tvimg.png");
export const blackboard = require("../assets/blackboard.png");
export const authorname = require("../assets/authorname.png");
export const contractSuccessIcon = require("../assets/contractSuccessIcon.png");
export const play = require("../assets/play.png");
export const fullscreen = require("../assets/fullscreen.png");
export const playCenterBtn = require("../assets/playCenterBtn.png");
export const vidSetting = require("../assets/vidSetting.png");
export const volume = require("../assets/volume.png");
export const mute = require("../assets/mute.png");
export const pause = require("../assets/pause.png");
export const camera = require("../assets/camera.png");
export const microphone = require("../assets/microphone.png");
export const attach = require("../assets/attach.png");
export const chatDp = require("../assets/chatDp.png");
export const share = require("../assets/share.png");
export const record = require("../assets/record.png");
export const partVidDis = require("../assets/partVidDis.png");
export const partAudDis = require("../assets/partAudDis.png");
export const liveIcon = require("../assets/liveIcon.png");
export const volumeIcon = require("../assets/volume.png");
export const closeDate = require("../assets/closeDate.png");
export const search = require("../assets/search.png");
export const notification = require("../assets/notification.png");
export const logo = require("../assets/Logo.png");
export const youngboy = require("../assets/youngboy.png");
export const diamonds = require("../assets/diamonds.png");
export const usercircleadd = require("../assets/usercircleadd.png");
export const messages = require("../assets/messages.png");

export const calendar = require("../assets/calendar-2.png");

export const screenShareIcon = require("../assets/screenShare.png");
export const StopScreenShareIcon = require("../assets/StopScreenShare.png");
export const end_call = require("../assets/end_call.png");
