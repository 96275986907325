//@ts-nocheck
import React from "react";
import {
  Button,
  Typography,
} from "@material-ui/core";
import {withStyles, Theme } from "@material-ui/core/styles";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import SubjectDetailsController, { Props } from "./SubjectDetailsController.web";
import { studentDp } from "./assets";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import { Divider } from "react-native-elements";
import CircularProgress from '@material-ui/core/CircularProgress';

export const styles:any = (theme: Theme) =>
  ({
    subjetDetailsTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },
    subjetDetailsMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    subjetDetailsjobsleftsec: {
      flex: 2,
    },
    subjetDetailsRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    SD_Container: {},
    SD_Container_CC: {
      display: "flex",
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3em",
    },
    SD_LeftDashNav: {
      flex: 2,
    },
    SD_RightDashNav: {
      flex: 7.5,
      width: "100%",
      marginLeft: "2vw",
    },
    SD_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
      marginBottom: "2vw",
    },
    SD_Title: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginBottom: "1vw",
    },
    CD_C: {
      //   paddingLeft: "17%",
      //   paddingRight: "17%",
      width: "100%",
    },
    CD_title: {
      fontSize: "1.8vw",
      fontWeight: 600,
      paddingTop: "1.5em",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
    },
    CD_subtitle: {
      marginTop: "20px",
      fontSize: "0.8vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      lineHeight: 2,
    },
    CD_Instruc_c: {
      paddingTop: "2em",

      display: "flex",
      justifyContent: "space-between",
      //   paddingLeft: "17%",
      //   paddingRight: "17%",
    },
    CD_Instruc_D_A: {
      width: "100%",
      // height: "8vw",
      borderRadius: "1em",
      border: "2px solid #ff0000",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      marginRight: "1.5vw",
      padding: "1.2vw",
    },
    CD_Instruc_D_B: {
      width: "100%",
      // height: "8vw",
      borderRadius: "1em",
      border: "2px solid #ff0000",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      marginRight: "1.5vw",
      padding: "1.2vw",
    },
    CD_Instruc_D_C: {
      width: "100%",
      // height: "8vw",
      borderRadius: "1em",
      border: "2px solid #ff0000",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      padding: "1.2vw",
    },
    CD_Instruct_title1: {
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1.5vw",
    },
    CD_Instruct_pics: {
      width: "5vw",
    },
    CD_Instruct_txt_cc: {
      marginTop: "0.5vw",
      display: "flex",
      overflow: "visible",
    },
    CD_Instruct_txt_cc_c: {
      marginLeft: "3.5em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    CD_Instruct_text_name: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1vw",
    },
    CD_Instruct_text_Quali: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.8vw",
      margin:"0.3m 0em"
    },
    CD_Instruct_text_btn: {
      marginTop:"0.4em",
       padding:"1em 2em",
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    CD_Instruct_2_text_c: {
      display: "flex",
      marginTop: "0.5vw",
    },
    CD_Instruct_2_text_1: {
      lineHeight: "1.5vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "0.85vw",
    },
    CD_Instruct_2_text_2_c: {
      marginLeft: "4em",
    },
    CD_Instruct_2_text_2: {
      lineHeight: "1.5vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85vw",
    },
    CD_Instruct_3_cc: {
      width: "85%",
      marginTop: "0.5vw",
    },
    CD_Instruct_3: {
      lineHeight: "1.5vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.8vw",
    },
    BtnC: {
      marginTop: "2em",
    },
    backToClassBtn1: {
      background: "#f0d17c",
      borderRadius: "10em",
      fontFamily: "Inter, sans-serif",
      fontWeight: 800,
      fontSize: "1.05em",
      textAlign: "center",
      textTransform: "none",
      color: "#181617",
      marginRight: "1.5em",
      padding:"1em 1.5em"

    },
    backToClassBtn2: {
      background: "#ff0000",
      borderRadius: "10em",
      fontFamily: "Inter, sans-serif",
      fontWeight: 800,
      fontSize: "1.05em",
      textAlign: "center",
      textTransform: "none",
      padding:"1em 1.5em",
      color: "#ffffff",
      marginRight: "1.5em",
    },
    backToClassBtn3: {
      borderRadius: "10em",
      fontFamily: "Inter, sans-serif",
      fontWeight: 800,
      fontSize: "1.05em",
      textAlign: "center",
      textTransform: "none",
      padding:"1em 1.5em",
      borderColor: "#ff4452",
      marginRight: "1.5em",
      color: "#ff0000",
    },
    
    teacherMyclassesLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },
  });

export class SubjectDetails extends SubjectDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.subjetDetailsMainSection}>
        <div className={classes.subjetDetailsjobsleftsec}>
          <DashSidebar1 active="MyClasses" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.subjetDetailsRightSec}>
        <div>
            <Typography className={classes.subjetDetailsTitle}>{this.state.subjectDetails?.data?.attributes.subject}</Typography>
            <div className={classes.CD_C}>
                <div>
                  <Typography className={classes.CD_subtitle}>
                  {this.state.subjectDetails?.data?.attributes.description}
                  </Typography>
                </div>
              </div>
            </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>

          {  this.state.isSubjectDetailsLoading ?
                (<div className={classes.teacherMyclassesLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div>):
            <>
             <div className={classes.CD_Instruc_c}>
                <div className={classes.CD_Instruc_D_A}>
                  <Typography className={classes.CD_Instruct_title1}>Hired by</Typography>
                  <div className={classes.CD_Instruct_txt_cc}>
                    <img className={classes.CD_Instruct_pics} src={studentDp} alt="InstructorPic" />
                    <div className={classes.CD_Instruct_txt_cc_c}>
                      <Typography className={classes.CD_Instruct_text_name}>
                      {this.state.subjectDetails?.data?.attributes.account.data?.attributes.first_name}&nbsp;{this.state.subjectDetails?.data?.attributes.account.data?.attributes.last_name}
                      </Typography>
                      <Typography className={classes.CD_Instruct_text_Quali}>{this.state.subjectDetails?.data?.attributes.account.data?.attributes.qualification}</Typography>
                      <Button variant="contained" className={classes.CD_Instruct_text_btn}>
                        Send Message
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={classes.CD_Instruc_D_B}>
                  <Typography className={classes.CD_Instruct_title1}>Timings</Typography>
                  <div className={classes.CD_Instruct_2_text_c}>
                    <div className={classes.CD_Instruct_2_txt_1_c}>
                      <Typography className={classes.CD_Instruct_2_text_1}>Starts on:</Typography>
                      <Typography className={classes.CD_Instruct_2_text_1}>Schedule:</Typography>
                      <Typography className={classes.CD_Instruct_2_text_1}>Duration:</Typography>
                    </div>
                    <div className={classes.CD_Instruct_2_text_2_c}>
                      <Typography className={classes.CD_Instruct_2_text_2}>
                        {this.state.subjectDetails?.data?.attributes.start_date}
                      </Typography>
                      <Typography className={classes.CD_Instruct_2_text_2}>{this.state.subjectDetails?.data?.attributes.schedule}</Typography>
                      <Typography className={classes.CD_Instruct_2_text_2}>
                      {this.state.subjectDetails?.data?.attributes.duration}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.BtnC}>
                <Button variant="contained" className={classes.backToClassBtn1} onClick={()=>{this.props.navigation.navigate("TeachersClasses")}}>                  Back to My Classes
                </Button>
                <Button variant="contained" className={classes.backToClassBtn2}>
                  Join Class Now
                </Button>
                <Button variant="outlined" className={classes.backToClassBtn3} onClick={()=>{this.props.navigation.navigate("CreateAssessment")}}>
                  Create Assessment
                </Button>
              </div>
              </>

          }

        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
</>
    );
  }
}
export default withStyles(styles, { withTheme: true })(SubjectDetails);
