import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const VideoStyles = (theme: Theme) =>
  createStyles({
    MSlider: {
      position: "absolute",
      bottom: 0,
      "&.MuiSlider-root": {
        padding: 0,
        color: "#ff0000",
      },
      "& .MuiSlider-rail": {
        backgroundColor: "transparent",
      },
      "& .MuiSlider-track": {
        height: "0.2em",
      },
    },
    MSliderLoaded: {
      position: "absolute",
      bottom: 0,
      "&.MuiSlider-root": {
        padding: 0,
        color: "#a8a8a8",
      },
      "& .MuiSlider-rail": {
        backgroundColor: "#525252",
      },
      "& .MuiSlider-thumb": {
        width: "0px",
      },
      "& .MuiSlider-track": {
        height: "0.2em",
      },
    },
    sliderInput: {
      position: "absolute",
      bottom: 0,
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    playbackRateBtn: {
      backgroundColor: "transparent",
      border: "0px",
      // borderBottom: "1px solid #000000",
      paddingTop: "0.5em",
      paddingBottom: "0.5em",
      color: "#fff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8em",
      lineHeight: "1em",
    },
  });

export default VideoStyles;
