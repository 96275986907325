 // Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
  id?:any;
  contractDetails?:any;
  subject?:any

}

export interface S {
  // Customizable Area Start
 
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class ContractInfoController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start 
 acceptCallId:any
 rejectCallId:any  

  //Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
    
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
     // Customizable Area Start

   
    // Customizable Area End 
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.acceptCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else if(apiResponse?.errors){
        alert(apiResponse?.errors[0].token)
      }else{
        this.props.navigation.navigate("ViewAppliedJobs")
      }
    }
      if (this.rejectCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse?.error) {
          alert(apiResponse?.error)
        } else if(apiResponse?.errors){
          alert(apiResponse?.errors[0].token)
        }else{
          this.props.navigation.navigate("ViewAppliedJobs")
        }

    }


  }
   // Customizable Area End 




   handleAcceptContract=(id:any)=>{
    const endPoint = `${"contract_accept"}?contract_id=${id}`;

      const token = localStorage.getItem("loginT");

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      const acceptContractRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.acceptCallId = acceptContractRequestMsg.messageId;
  
  
      acceptContractRequestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      );
  
      acceptContractRequestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      acceptContractRequestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPUTMethod
      );
      runEngine.sendMessage(acceptContractRequestMsg.id, acceptContractRequestMsg);

   }
   handleRejectContract=(id:any)=>{
    const endPoint = `${"contract_reject"}?contract_id=${id}`;

    const token = localStorage.getItem("loginT");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const rejectContractRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.rejectCallId = rejectContractRequestMsg.messageId;


    rejectContractRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );

    rejectContractRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    rejectContractRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPUTMethod
    );
    runEngine.sendMessage(rejectContractRequestMsg.id, rejectContractRequestMsg);
   }


  }
  
// Customizable Area End 