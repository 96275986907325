import React from "react";
// Customizable Area Start
import {Grid, Card, Typography, TextField, Button,} from "@material-ui/core";
import DatePicker from "react-datepicker"
import {setMinutes, setHours} from "date-fns";

// Customizable Area End

import DetailWebinarController, {
  Props, configJSON,
} from "./DetailWebinarController";

class DetailWebinar extends DetailWebinarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {       
    const currentDate = new Date()     
    const isFormInvalid = !this.state.webinarInputValues.name.isValid || !this.state.webinarInputValues.date.isValid || !this.state.webinarInputValues.startTime.isValid || !this.state.webinarInputValues.endTime.isValid || !this.state.webinarInputValues.description.isValid  
    return (
      // Customizable Area Start            
          <Card style={{padding:8, margin:16,}} elevation={2}>       
            <Grid container direction="column" style={{margin:8}}>
             
              <Grid item  style={{margin: 8}}>
              <TextField          
                onChange={(event)=>{this.webinarInputsChangeHandler('name', event.target.value)}}  
                value={this.state.webinarInputValues.name.value}              
                placeholder={configJSON.webinarName}
                style={{width:218, height:34}}
                label={configJSON.nameLabel}
                variant="outlined"
                id='webinarName'
                color="primary"
                size="small"
                required                           
              />
              </Grid>

              <Grid item style={{margin: 8}} >
                <DatePicker
                  onChange={(date:Date)=>{this.webinarInputsChangeHandler('date', date)}}  
                  selected={this.state.webinarInputValues.date.value}                 
                  placeholderText={configJSON.dateLabel}     
                  dateFormat={'dd/MM/yyyy'}
                  minDate={currentDate}
                  isClearable={true}                 
                  id="scheduledDate"
                />
              </Grid>

              <Grid item style={{margin: 8}}>
                <DatePicker
                  onChange={(time:Date)=>{this.webinarInputsChangeHandler('startTime', time)}}                
                  selected={this.state.webinarInputValues.startTime.value}                      
                  placeholderText={configJSON.startTimeLabel}
                  timeCaption={configJSON.startTimeLabel}
                  id="scheduledStartTime"   
                  dateFormat="h:mm aa"   
                  showTimeSelectOnly              
                  isClearable={true}                  
                  timeIntervals={5}     
                  showTimeSelect
                />
              </Grid>

              <Grid item style={{margin: 8}}>
                <DatePicker
                  minTime={setHours(setMinutes(currentDate, this.state.webinarInputValues.startTime.value?.getMinutes() || currentDate.getMinutes() + 5),this.state.webinarInputValues.startTime.value?.getHours() || currentDate.getHours())}                                    
                  onChange={(time:Date)=>{this.webinarInputsChangeHandler('endTime', time)}}  
                  selected={this.state.webinarInputValues.endTime.value}                      
                  maxTime={setHours(setMinutes(currentDate, 59),23)}   
                  placeholderText={configJSON.endTImeLabel}   
                  timeCaption={configJSON.endTImeLabel}
                  id="scheduledEndTime"  
                  dateFormat="h:mm aa"        
                  showTimeSelectOnly    
                  isClearable={true}                    
                  timeIntervals={5}          
                  showTimeSelect
                />
              </Grid>          

              <Grid item style={{margin: 8}}>
                <TextField
                  onChange={(event)=>{this.webinarInputsChangeHandler('description', event.target.value)}}  
                  value={this.state.webinarInputValues.description.value}
                  placeholder={configJSON.descriptionLabel}
                  label={configJSON.descriptionLabel}   
                  id='webinarDescription'
                  style={{width:218}}
                  variant="outlined"
                  multiline={true}
                  color="primary"
                  minRows={3}
                  maxRows={4}
                  required                                              
              />
              </Grid>

              <Grid item>
                {isFormInvalid && <Typography style={{color:'red', fontSize:16, textAlign:'center'}}>{configJSON.InvalidInputFiledsLabel}</Typography>}
              </Grid>

              <Grid item style={{margin: '0px auto', justifyContent:'center'}} >
                <Button color="primary" variant="contained" style={{textTransform:'none'}}
                    onClick={this.webinarSubmitHandler} size="small"
                    id="webinarFromSubmit">{configJSON.saveTxtBtn}</Button>
              </Grid>
            </Grid>
          </Card>            
      // Customizable Area End
    );
  }
}
export default DetailWebinar
// Customizable Area Start
// Customizable Area End
