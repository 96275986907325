import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Visibility from "@material-ui/icons/Visibility";
import CircularProgress from '@material-ui/core/CircularProgress';

import {viewIconPng} from "./assets"


import { withStyles, Theme } from "@material-ui/core/styles";

export const styles:any = (theme: Theme) =>
  ({
    root: {
      // marginTop:"3%",
      // marginBottom:"3%"
    },
    appliedJobsMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    appliedJobsleftsec: {
      flex: 2,
    },
    appliedJobsRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    }, 
    appliedJobsTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },

    appliedJobstableContainerStyle: {
      maxHeight: "20em",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "auto",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
        marginTop: "3vw",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    appliedJobstableHeadStyle: {
      "&.MuiTableCell-stickyHeader": {
        backgroundColor: "#14292d",
        backgroundBlendMode: "normal",
      },
      color: "white",
      fontSize: 16,
    },
    sendAMessage:{
      color: "#fff",
      fontFamily: "Inter, sans-serif",
      backgroundColor: " #ff0000",
      borderRadius: "50px",
      padding:" 0.5em 2em",
      marginLeft: "0.2em",
      marginRight: "0.2em",
      fontSize: "0.9vw",
      textTransform: "none"

    },

    underReview:{
      fontWeight:700,
      color:"#5e5858"
    },
    accepted:{
      fontWeight:700,
    color: "#6f924a" 
     
    },
    rejected:{
      fontWeight:700,
      color:"#ff0000",
    },
    viewContractBtn:{
      color: "#33333",
      fontFamily: "Inter, sans-serif",
      backgroundColor: "#f0d17c",
      borderRadius: "50px",
      fontSize: "0.9vw",
      textTransform: "none",
      padding:" 0.5em 2em",
    marginLeft: "0.2em",
    marginRight: "0.2em",
    },
    noAppliedJobs:{
      width:"100%",
      height:"20vh",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      textAlign:"center",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },

    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
  });

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End

import AppliedJobsController, { Props } from "./AppliedJobsController.web";
import { Divider } from "react-native-elements";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";


export class AppliedJobs extends AppliedJobsController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
   const  renderTableBody=()=>{
    if(this.state?.appliedJobsLoader){
      return    (<div className={classes.noAppliedJobs} style={{ marginLeft: "2em" }}><CircularProgress /></div> )
    }
    else if(this.state?.appliedJobs?.length <= 0){
    return  ( <div  className={classes.noAppliedJobs}><span>No Applied Jobs Found</span></div>)
    }
    else{
      return (<TableBody>
        {this.state.appliedJobs?.map((eachJob:any, key:any) => {
          return (
            <TableRow key={key}>
              <TableCell scope="row" align="center">
                 {eachJob?.attributes?.hire_teacher_request?.data?.attributes?.subject}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
              {eachJob?.attributes?.hire_teacher_request?.data?.attributes?.experience}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
              {eachJob?.attributes?.hire_teacher_request?.data?.attributes?.duration}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="right">

              {(eachJob?.attributes?.status==="accepted")&&
             <><span  className={classes.accepted} >Accepted</span>
             {  <Button
                 disabled={eachJob?.attributes?.contract_status==="Under Review"}
                  className={classes.viewContractBtn}
                  onClick={()=>this.handleClickOnAddContract(eachJob)}
                  variant="contained"
                  
                >
                    View Contract 
                </Button>}
                <Button
                  className={classes.sendAMessage}
                  // onClick={() => {
                  //     this.handleSubmit();
                  // }}
                  disableElevation
                  variant="contained"

                >
                  Send a Message
                </Button>
             </>}
             
            { (eachJob?.attributes?.status==="Under Review")&&
             <><span  className={classes.underReview}>Under Review</span></>}

             { (eachJob?.attributes?.status==="rejected") &&
              <><span className={classes.rejected}>Rejected</span></>}
              
              <span style={{marginLeft:"0.5em"}} onClick={()=>this.handleClickOnVisibilty(eachJob)}>
               <img src={viewIconPng} alt="viewIconPng"/>
              </span>
              </TableCell>            
            </TableRow>
          )
        })}


    </TableBody>)  
    }

  }
  
    return (
      // Customizable Area Start
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.appliedJobsMainSection}>
        <div className={classes.appliedJobsleftsec}>
          <DashSidebar1 active="AppliedJobs" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            Buttontitle="View your Classes"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.appliedJobsRightSec}>
        <div>
              <Typography className={classes.appliedJobsTitle}>Applied Jobs</Typography>
        </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>
            <div>
            <TableContainer className={classes.appliedJobstableContainerStyle} component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ color: "white" }}>
                      <TableCell className={classes.appliedJobstableHeadStyle} align="center">
                        Job Name
                      </TableCell>
                      <TableCell className={classes.appliedJobstableHeadStyle} align="center">
                        Experience
                      </TableCell>
                      <TableCell className={classes.appliedJobstableHeadStyle} align="center">
                        Duration
                      </TableCell>
                      <TableCell className={classes.appliedJobstableHeadStyle} align="center">
                      Application Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                {renderTableBody()}          
                </Table>
              </TableContainer>
            </div>
      
                    <Button
                      className="submit"
                      onClick={() => this.props.navigation.navigate("JobListing")}
                      disableElevation
                      variant="contained"
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Inter, sans-serif",
                        backgroundColor: "#FF0000",
                        borderRadius: "50px",
                       padding:"0em 2em",
                        fontSize: "0.9vw",
                        textTransform: "none",
                        fontWeight: 700,
                        marginTop: "20px",
                      }}
                    >
                        Back to job Listings
                    </Button>
      
        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(AppliedJobs);

// Customizable Area End
