import React from 'react'
import useRazorpay from "react-razorpay";
import { useEffect } from 'react';

 const RazorpayCmp =(props:any)=> {
    const Razorpay:any = useRazorpay();
    const {handleRazorPay} = props
    useEffect(() => {
        handleRazorPay(Razorpay)
    }, [Razorpay])

    return(<></>)
}

export default RazorpayCmp