import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { twomembers, calender, freetag } from "../assets";

import Grid from "@material-ui/core/Grid";
import moment from "moment";
import CourseCard from "../../../dashboard/src/components/CourseCard.web";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        PPCComp_popCourseName: {
            color: "#3f3f3f",
            fontSize: 38,
            fontWeight: 700,
            padding: 50,
            [theme.breakpoints.down("xs")]: {
                fontSize: 24,
                margin: 25,
            },
        },

        PPCComp_popCourseContainer: {
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "17vw",
            paddingRight: "17vw",
            gap: "2em",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "center",
            },
        },

        PPCComp_popCourseCard: {
            width: "100%",
            height: "100%",
            [theme.breakpoints.down("md")]: {
                width: 400,
            },

            [theme.breakpoints.down("sm")]: {
                width: 500,
            },
            [theme.breakpoints.down("xs")]: {
                width: 350,
            },
        },

        PPCComp_List_subtitle: {
            maxLines: 3,
            lineHeight: "2em",
            fontSize: "0.8vw",
            WebkitBoxOrient: "vertical",
            fontWeight: 400,
            color: "#666666",
            fontFamily: "Inter, sans-serif",
            minHeight: "6em",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 3,
        },
        PPCComp_cardButton: {
            marginBottom: 25,
            borderRadius: 100,
            textTransform: "none",
            fontWeight: 700,
            fontSize: 16,
            width: 180,
            height: 50,
            [theme.breakpoints.down("xs")]: {
                fontSize: 12,
                width: 130,
                height: 30,
            },
        },
    })
);

interface ScreenProps {
    bgColor: any;
    PCTopPopularData: any;
    PClistPopularData: any;
    PCnavigateToCoursesDetails: (id: any) => void;
    PChandleNavToCoursesList: () => void;
}

const PopularCoursesScreen = ({
    bgColor,
    PCTopPopularData,
    PClistPopularData,
    PCnavigateToCoursesDetails,
    PChandleNavToCoursesList,
}: ScreenProps) => {
    const classes = useStyles();
    console.log('PCTopPopularData', PCTopPopularData)

    return (
        <div style={bgColor ? { background: "#f7f7f7" } : {}}>
            {/* Popular course Title */}
            <div style={{ textAlign: "center" }}>
                <Typography className={classes.PPCComp_popCourseName}>Popular Courses</Typography>
            </div>

            {/* Popular Courses Cards */}

            {PCTopPopularData.length > 0 ? (
                <div className={classes.PPCComp_popCourseContainer}>
                    {PCTopPopularData.map((val: any, key: any) => {
                        return (
                            <Card className={classes.PPCComp_popCourseCard} key={val.id}>
                                <CardMedia>
                                    <img style={{ height: "18em", width: "100%" }} src={val.image_url} alt="" />
                                </CardMedia>
                                <CardContent style={{ margin: "0.8rem" }}>
                                    <Typography style={{ fontSize: 28, fontWeight: 700, color: "#3f3f3f" }}>
                                        {" "}
                                        {val.name}
                                    </Typography>
                                    <Typography variant="body2" component="p" className={classes.PPCComp_List_subtitle}>
                                        {val.course_description}
                                    </Typography>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "13px",
                                            columnGap: 10,
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img style={{ width: "22px" }} src={twomembers} alt="" />
                                            <Typography style={{ fontWeight: 600 }}>&nbsp;{val.selected_count}&nbsp;</Typography>
                                            <Typography>|</Typography>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img style={{ height: "20px", width: "20px" }} src={freetag} alt="" />
                                            <Typography style={{ fontWeight: 600 }}>
                                                &nbsp;{val.course_type == "free" ? "Free" : val.course_cost}&nbsp;
                                            </Typography>
                                            <Typography>|</Typography>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img style={{ height: "20px", width: "20px" }} src={calender} alt="" />
                                            <Typography style={{ fontWeight: 600 }}>
                                                &nbsp;{moment(`${val.start_date}`).format("DD/MM/YYYY")}
                                            </Typography>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardActions style={{ margin: "0.8rem" }}>
                                    <div style={{ display: "flex", columnGap: 20 }}>
                                        <Button
                                            variant="contained"
                                            style={{ background: "#f00", color: "#fff" }}
                                            className={classes.PPCComp_cardButton}
                                            onClick={() => {
                                                PCnavigateToCoursesDetails(val.id);
                                            }}
                                            data-testId="PPC_TopCoursesViewC"
                                        >
                                            View Course
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{ background: "#f0d17c" }}
                                            className={classes.PPCComp_cardButton}
                                            onClick={() => {
                                                PCnavigateToCoursesDetails(val.id);
                                            }}
                                            data-testId="PPC_TopCoursesBuyC"
                                        >
                                            Buy Now
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        );
                    })}
                </div>
            ) : null}

            {PClistPopularData.length > 0 ? (
                <Grid
                    container
                    justifyContent="flex-start"
                    // spacing={2}
                    wrap="nowrap"
                    style={{
                        marginTop: "3em",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: "1.5em",
                        paddingLeft: "17vw",
                        paddingRight: "17vw",
                        gap: "2em",
                    }}
                >
                    {PClistPopularData.map((val: any) => (
                        <CourseCard
                            id={val?.id}
                            image_url={val?.image_url}
                            name={val?.name}
                            course_description={val?.course_description}
                            selected_count={val?.selected_count}
                            course_type={val?.course_type}
                            course_cost={val?.course_cost}
                            start_date={val?.start_date}
                            is_paid={val?.is_paid}
                            handleViewCourse={PCnavigateToCoursesDetails}
                            handleBuyCourse={PCnavigateToCoursesDetails}
                        />
                    ))}
                </Grid>
            ) : null}

            <div style={{ textAlign: "center", margin: 50, height: 150 }}>
                <Button
                    variant="contained"
                    style={{
                        background: "#f0d17c",
                        borderRadius: 100,
                        textTransform: "none",
                        fontWeight: 600,
                        width: 250,
                        height: 50,
                        fontSize: 16,
                    }}
                    onClick={PChandleNavToCoursesList}
                >
                    View All Courses
                </Button>
            </div>
        </div>
    );
};
export default PopularCoursesScreen;
