import React from "react";
import ViewScheduleController, { Props } from "./ViewScheduleController.web";
import {
  techfinimg,
  time,
  tvimg,
  authorname,
} from "./assets";
import DashAdvartiseCard from "./components/DashAdvartiseCard.web";
import DashNav from "./components/DashNav.web";
import NextClassNotification from "./components/NextClassNotification.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import { defaultProfilePic } from "../../email-account-login/src/assets";
import UpdateContractInfo from "./components/UpdateContractInfo";
import Modal from "@material-ui/core/Modal";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    add_contract_container: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      display: "flex",
    },
    dash_left_c: {
      flex: 2,
    },
    add_contract_cc: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    add_contract_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
    },
    add_contract_title: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginBottom: "1vw",
    },
    add_contract_card_c: {
      boxShadow: "0px 5px 30px rgba(0,0,0,0.05)",
      borderRadius: "1vw",
      padding: "2vw",
      marginTop: "2vw",
    },
    add_contract_card_row_1: {
      display: "flex",
      justifyContent: "space-between",
    },
    add_contract_card_row_1_sec_1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    add_contract_card_row_1_sec_1_img: {
      width: "4vw",
      height: "4vw",
      objectFit: "cover",
      borderRadius: "50%",
    },
    add_contract_card_row_1_sec_1_name: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.2vw",
      marginLeft: "1vw",
    },
    add_contract_card_row_1_sec_1_stat_c: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.85vw",
    },
    add_contract_card_row_1_sec_1_stat: {
      color: "#6f924a",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.85vw",
      margin: 0,
    },
    add_contract_card_row_2: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1.5vw",
    },
    add_contract_card_row_2_tit: {
      margin: "0px",
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1vw",
    },
    add_contract_card_row_2_tit_tit: {
      margin: "0px",
      marginTop: "0.7vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85vw",
    },
    add_contract_card_row_3: {
      marginTop: "1.5vw",
      marginBottom: "1.5vw",
    },
    add_contract_card_row_3_btn: {
      borderColor: "#ff4452",
      borderWidth: "2px",
      borderRadius: "10em",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.7vw",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    add_contract_card_row_4: {
      marginTop: "1.5vw",
    },
    add_contract_card_row_4_btn1: {
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      marginRight: "1vw",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.7vw",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    add_contract_card_row_4_btn2_inactive: {
      backgroundColor: "#f0d17c",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      color: "#333333",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.7vw",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    add_contract_details_cc: {
      marginTop: "4em",
    },
    add_contract_details_title: {
      fontSize: "1.2vw",
      color: "#333333",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      marginBottom: "1em",
    },

    RelatedCourseCard_ViewSchedule: {
      // height: "420px",
      width: "100%",
      maxWidth: "21em",
      // border: "1px solid red"
    },
    add_contract_card_cd_ViewSchedule: {
      // boxShadow: '0px 5px 30px rgba(0,0,0,0.05)',
      marginBottom: "2vw",
      marginTop: "2vw",
      padding: "2vw",
      borderRadius: "1vw",
      backgroundColor: "#f7f7f7",
    },
    add_contract_card_row_2_title_ViewSchedule: {
      fontWeight: 600,
      color: "#181617",
      margin: "0px",
      fontFamily: "Inter, sans-serif",
      fontSize: "1vw",
    },
    add_contract_card_row_4_btn2_editcontract_ViewSchedule: {
      backgroundColor: "#ff0000",
      marginTop: "1.5em",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      color: "#ffffff",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    ViewSchedule_btn_c_ViewSchedule: {
      paddingTop: "0.5em",
      paddingBottom: "1em",
      paddingLeft: "1em",
    },
    status_modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    status_modal_content: {
      minWidth: "30em",
      border: "1px solid #979797",
      background: "#ffffff",
      borderRadius: "1em",
      padding: "3em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column'
    },
    Done_btn: {
      backgroundColor: "#ff0000",
      marginTop: "1.5em",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      color: "#ffffff",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    loadingProfile: {
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }

    },
  });
// Customizable Area End
export class ViewSchedule extends ViewScheduleController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.add_contract_container}>
          <div className={classes.dash_left_c}>
            <DashNav
              active="Classes&Teachers"
              styles={{ marginBottom: "1.5vw" }}
              navigation={this.props.navigation}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              onClick={() => { }}
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
              onClick={() => { }}
            />
          </div>
          <div className={classes.add_contract_cc}>
            <p className={classes.add_contract_title}>Teacher Details</p>
            <div className={classes.add_contract_Hr} />
            {this.state.getDetailsLoading ? <div className={classes.loadingProfile} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
              <div>
                <div className={classes.add_contract_card_c}>
                  <div className={classes.add_contract_card_row_1}>
                    <div className={classes.add_contract_card_row_1_sec_1}>
                      {this.state.contractDetails?.attributes?.account?.data
                        ?.attributes?.profile_image ? (
                        <img
                          src={
                            this.state.contractDetails?.attributes?.account?.data
                              ?.attributes?.profile_image
                          }
                          alt=""
                          className={classes.add_contract_card_row_1_sec_1_img}
                        />
                      ) : (
                        <img
                          src={defaultProfilePic}
                          alt=""
                          className={classes.add_contract_card_row_1_sec_1_img}
                        />
                      )}
                      <p className={classes.add_contract_card_row_1_sec_1_name}>
                        {
                          this.state.contractDetails?.attributes?.account?.data
                            ?.attributes?.first_name
                        }{" "}
                        {
                          this.state.contractDetails?.attributes?.account?.data
                            ?.attributes?.first_name
                        }
                      </p>
                    </div>
                    <div className={classes.add_contract_card_row_1_sec_1}>
                      <p className={classes.add_contract_card_row_1_sec_1_stat_c}>
                        Status:&nbsp;&nbsp;
                      </p>
                      <p className={classes.add_contract_card_row_1_sec_1_stat}>
                        {this.state.contract_status}
                      </p>
                    </div>
                  </div>
                  <div className={classes.add_contract_card_row_2}>
                    <div>
                      <p className={classes.add_contract_card_row_2_tit}>
                        Experience
                      </p>
                      <p className={classes.add_contract_card_row_2_tit_tit}>
                        {
                          this.state.contractDetails?.attributes?.account?.data
                            ?.attributes?.academic?.data?.attributes
                            ?.total_experience
                        }
                      </p>
                    </div>
                    <div>
                      <p className={classes.add_contract_card_row_2_tit}>
                        Qualification
                      </p>
                      <p className={classes.add_contract_card_row_2_tit_tit}>
                        {
                          this.state.contractDetails?.attributes?.account?.data
                            ?.attributes?.academic?.data?.attributes?.qualification
                            ?.name
                        }
                      </p>
                    </div>
                    <div>
                      <p className={classes.add_contract_card_row_2_tit}>
                        Specialization
                      </p>
                      <p className={classes.add_contract_card_row_2_tit_tit}>
                        {
                          this.state.contractDetails?.attributes?.account?.data
                            ?.attributes?.academic?.data?.attributes?.specialization
                        }
                      </p>
                    </div>
                    <div>
                      <p className={classes.add_contract_card_row_2_tit}>
                        Languages
                      </p>
                      <p className={classes.add_contract_card_row_2_tit_tit}>
                        {
                          this.state.contractDetails?.attributes?.account?.data
                            ?.attributes?.academic?.data?.attributes
                            ?.known_languages[0]
                        }
                      </p>
                    </div>
                  </div>
                  <div className={classes.add_contract_card_row_3}>
                    {this.state.contractDetails?.attributes?.account?.data
                      ?.attributes?.academic?.data?.attributes?.cv && (
                        <Button
                          variant="outlined"
                          className={classes.add_contract_card_row_3_btn}
                          onClick={() => {
                            this.handleDownloadResume(
                              this.state.contractDetails?.attributes?.account?.data
                                ?.attributes?.academic?.data?.attributes
                            );
                          }}
                        >
                          Download Resume
                        </Button>
                      )}
                  </div>
                  <div className={classes.add_contract_Hr} />
                  <div className={classes.add_contract_card_row_4}>
                    <Button
                      variant="contained"
                      className={classes.add_contract_card_row_4_btn2_inactive}
                    >
                      Send Message
                    </Button>
                  </div>
                </div>
                <div className={classes.add_contract_details_cc}>
                  <p className={classes.add_contract_details_title}>Schedules</p>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {this.state.tempCardData.map((val: any) => {
                      return (
                        <Grid item key={val}>
                          <Card className={classes.RelatedCourseCard_ViewSchedule}>
                            <CardMedia>
                              <img
                                style={{ height: "auto", width: "100%" }}
                                src={techfinimg}
                                alt=""
                              />
                            </CardMedia>
                            <CardContent>
                              <Typography style={{ fontSize: 21, fontWeight: 700 }}>
                                Subject
                              </Typography>
                              <Typography
                                variant="body2"
                                component="p"
                                style={{ lineHeight: 1.8, color: "#666666" }}
                              >
                                is simply dummy text of printing of printing
                              </Typography>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "13px",
                                  columnGap: 4,
                                }}
                              >
                                <div
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <img
                                    style={{ height: "18px", width: "18px" }}
                                    src={tvimg}
                                    alt=""
                                  />
                                  <Typography
                                    style={{ fontWeight: 550, fontSize: 12 }}
                                  >
                                    &nbsp;One on One&nbsp;
                                  </Typography>
                                </div>
                                <div
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <img
                                    style={{ height: "16px", width: "16px" }}
                                    src={time}
                                    alt=""
                                  />
                                  <Typography
                                    style={{ fontWeight: 550, fontSize: 12 }}
                                  >
                                    &nbsp;10:00 AM - 11:00 AM
                                  </Typography>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <img
                                  style={{ height: "16px", width: "16px" }}
                                  src={authorname}
                                  alt=""
                                />
                                <Typography
                                  style={{ fontWeight: 550, fontSize: 12 }}
                                >
                                  &nbsp;10 August 2022
                                </Typography>
                              </div>
                            </CardContent>

                            <CardActions
                              className={classes.ViewSchedule_btn_c_ViewSchedule}
                            >
                              <div style={{ display: "flex", columnGap: 20 }}>
                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#f00",
                                    color: "#fff",
                                    textTransform: "none",
                                    borderRadius: 100,
                                    width: 180,
                                  }}
                                  size="small"
                                >
                                  View Subject Details
                                </Button>
                              </div>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </div>
                </div>
                {this.state?.contractDetails?.attributes?.contract_status != "Under Review" &&
                  <div>
                    <Typography
                      style={{ fontSize: 20, fontWeight: 550, marginTop: 30 }}
                    >
                      Contact Info
                    </Typography>

                    {this.state.EditMode ? (
                      <div className={classes.add_contract_card_cd_ViewSchedule}>
                        <div className={classes.add_contract_card_row_1}></div>
                        <div className={classes.add_contract_card_row_2}>
                          <div>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Class Timings
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {this.state.timeSlot[0]} - {this.state.timeSlot[1]}
                            </p>
                          </div>
                          <div>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Contract Type
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.contract_status?.data[0]?.attributes
                                  ?.contract_type
                              }
                            </p>
                          </div>
                          <div>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Subject
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.hire_teacher_request?.data?.attributes.subject
                              }
                            </p>
                          </div>
                          <div>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Hired On
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {this.state?.contractDetails?.attributes?.hired_no}
                            </p>
                          </div>
                        </div>
                        <div className={classes.add_contract_card_row_3}>
                          <Typography
                            style={{
                              color: " #333333",
                              fontSize: 18,
                              fontWeight: 550,
                            }}
                          >
                            Payment Info
                          </Typography>
                        </div>
                        <div style={{ display: "flex", marginBottom: 20 }}>
                          <div>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Payment Condition
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.contract_status?.data[0]?.attributes
                                  ?.payment_condition
                              }
                            </p>
                          </div>
                          <div style={{ marginLeft: "130px" }}>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Distribution Method
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.contract_status?.data[0]?.attributes
                                  ?.distribution_method
                              }
                            </p>
                          </div>
                        </div>
                        <div className={classes.add_contract_Hr} />
                        <div className={classes.add_contract_card_row_3}>
                          <Typography
                            style={{
                              color: " #333333",
                              fontSize: 18,
                              fontWeight: 550,
                            }}
                          >
                            Payment Structure
                          </Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Total Classes
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.contract_status?.data[0]?.attributes
                                  ?.total_classes
                              }
                            </p>
                          </div>
                          <div style={{ marginLeft: "177px" }}>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              No of Payments
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.contract_status?.data[0]?.attributes
                                  ?.no_of_payments
                              }
                            </p>
                          </div>
                          <div style={{ marginLeft: "142px" }}>
                            <p
                              className={
                                classes.add_contract_card_row_2_title_ViewSchedule
                              }
                            >
                              Currency
                            </p>
                            <p className={classes.add_contract_card_row_2_tit_tit}>
                              {
                                this.state?.contractDetails?.attributes
                                  ?.contract_status?.data[0]?.attributes?.currency
                              }
                            </p>
                          </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "black" }}>
                                <TableRow style={{ color: "white" }}>
                                  <TableCell style={{ color: "white", fontSize: 18 }}>
                                    Payment No:
                                  </TableCell>
                                  <TableCell style={{ color: "white", fontSize: 18 }}>
                                    Payment
                                  </TableCell>
                                  <TableCell
                                    style={{ color: "white", fontSize: 18 }}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state?.contractDetails?.attributes?.contract_status?.data[0]?.attributes?.contract_payments.map(
                                  (data: any, key: any) => (
                                    <TableRow key={data?.id}>
                                      <TableCell scope="row">
                                        {data?.id}
                                      </TableCell>
                                      <TableCell>
                                        {data?.amount} {"\u20B9"}
                                      </TableCell>
                                      <TableCell>
                                        {data?.milestone_status &&
                                          `Milestone ${key + 1} Completed`}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <div className={classes.add_contract_card_row_4}>
                          <Button
                            onClick={() => {
                              this.handleEditMode();
                            }}
                            variant="contained"
                            className={
                              classes.add_contract_card_row_4_btn2_editcontract_ViewSchedule
                            }
                            data-testid="handleEditModeBtn"
                          >
                            Edit Contract
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <UpdateContractInfo
                        startTime={this.startTime}
                        endTime={this.endTime}
                        updateContract={this.handleUpdateContractInfo}
                        from={this.state.timeFrom}
                        to={this.state.timeTo}
                        contractTypeData={this.state.contractTypeData}
                        paymentConditionData={this.state.paymentConditionData}
                        distributionMethodData={this.state.distributionMethodData}
                        currencyTypeData={this.state.currencyTypeData}
                        proposed_budget={this.state.proposed_budget}
                        numberOfPaymentsUpdate={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.no_of_payments
                        }
                        totClassesUpdate={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.total_classes
                        }
                        currTypeUpdate={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.currency
                        }
                        contrTypeUpdate={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.contract_type
                        }
                        paymConditionUpdate={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.payment_condition
                        }
                        distrMethodUpdate={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.distribution_method
                        }
                        contractPayments={
                          this.state?.contractDetails?.attributes?.contract_status
                            ?.data[0]?.attributes?.contract_payments
                        }
                      />
                    )}

                  </div>
                }
              </div>}
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Modal
          open={this.state.status_modal}
          onClose={() => {
            this.setState({ status_modal: false });
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.status_modal}
        >
          <div className={classes.status_modal_content}>
            <p>{this.state.update_contract_response?.message}</p>
            <Button
              variant="contained"
              className={classes.Done_btn}
              onClick={async () => {
                this.setState({ status_modal: false });
              }}
              data-testid="updateContractBtn"
            >
              Done
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ViewSchedule);
