import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { dashArrowActive, dashArrowInactive } from "../assets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    STUD_dash_nav_container: {
      backgroundColor: "#f7f7f7",
      width: "100%",
      borderRadius: "1vw",
    },
    STUD_dash_nav_c: {
      paddingTop: "2vw",
      paddingBottom: "1.3vw",
      paddingLeft: "1.3vw",
      paddingRight: "1.3vw",
    },
    STUD_dash_nav_item_c: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      // backgroundColor: 'red',
      marginBottom: "1vw",
      cursor: "pointer",
    },
    STUD_dash_nav_item_img: {
      width: "0.5vw",
      marginRight: "0.5vw",
    },
    STUD_dash_nav_item_txt: {
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#333333",
      fontSize: "0.85vw",
      margin: "0px",
    },
  })
);

interface Props {
  active: "Dashboard" | "Courses" | "Classes&Teachers" | "HireATeacher" | "Messages" | "Profile" | "Settings";
  styles?: any;
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
}
const DashNav = ({ active, styles, navigation }: Props) => {
  const classes = useStyles();
  const STUD_handleNav = (screen: string) => {
    navigation.navigate(screen);
  };
  return (
    <div className={classes.STUD_dash_nav_container} style={styles}>
      <div className={classes.STUD_dash_nav_c}>
        <div
          onClick={() => {
            STUD_handleNav("StudentDashboard");
          }}
          className={classes.STUD_dash_nav_item_c}
          data-testId="StudentDashboardBtn"
        >
          {active == "Dashboard" ? (
            <img
              src={dashArrowActive}
              alt="Dashboard_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="Dashboard_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "Dashboard" ? { color: "#ff0000" } : {}}
          >
            Dashboard
          </p>
        </div>
        <div
          onClick={() => {
            navigation.navigate("CourseList", { page: 1 });
          }}
          className={classes.STUD_dash_nav_item_c}
          data-testId="CourseListBtn"
        >
          {active == "Courses" ? (
            <img
              src={dashArrowActive}
              alt="Courses_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="Courses_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "Courses" ? { color: "#ff0000" } : {}}
          >
            Courses
          </p>
        </div>
        <div
          onClick={() => {
            STUD_handleNav("TeacherClasses");
          }}
          className={classes.STUD_dash_nav_item_c}
          data-testId="TeacherClassesBtn"
        >
          {active == "Classes&Teachers" ? (
            <img
              src={dashArrowActive}
              alt="Classes&Teachers_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="Classes&Teachers_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "Classes&Teachers" ? { color: "#ff0000" } : {}}
          >
            Classes & Teachers
          </p>
        </div>
        <div
          onClick={() => {
            STUD_handleNav("HireTeacher");
          }}
          className={classes.STUD_dash_nav_item_c}
          data-testId="HireTeacherBtn"
        >
          {active == "HireATeacher" ? (
            <img
              src={dashArrowActive}
              alt="HireATeacher_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="HireATeacher_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "HireATeacher" ? { color: "#ff0000" } : {}}
          >
            Hire a Teacher
          </p>
        </div>
        <div
          onClick={() => {
            STUD_handleNav("Messages");
          }}
          className={classes.STUD_dash_nav_item_c}
          data-testId="MessagesBtn"
        >
          {active == "Messages" ? (
            <img
              src={dashArrowActive}
              alt="Messages_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="Messages_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "Messages" ? { color: "#ff0000" } : {}}
          >
            Messages
          </p>
        </div>
        <div
          onClick={() => {
            STUD_handleNav("Profile");
          }}
          className={classes.STUD_dash_nav_item_c}
        >
          {active == "Profile" ? (
            <img
              src={dashArrowActive}
              alt="Profile_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="Profile_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "Profile" ? { color: "#ff0000" } : {}}
          >
            Profile
          </p>
        </div>
        <div
          onClick={() => {
            STUD_handleNav("Settings");
          }}
          className={classes.STUD_dash_nav_item_c}
        >
          {active == "Settings" ? (
            <img
              src={dashArrowActive}
              alt="Settings_arrow_active"
              className={classes.STUD_dash_nav_item_img}
            />
          ) : (
            <img
              src={dashArrowInactive}
              alt="Settings_arrow_inactive"
              className={classes.STUD_dash_nav_item_img}
            />
          )}
          <p
            className={classes.STUD_dash_nav_item_txt}
            style={active == "Settings" ? { color: "#ff0000" } : {}}
          >
            Settings
          </p>
        </div>
      </div>
    </div>
  );
};
export default DashNav;
