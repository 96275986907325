import React, { useState, useEffect } from "react";
import CoursesListPageItem from "./CoursesListPageItem.web";
import CourseListPaginate from "./CourseListPaginate.web";
import CoursesListFilters from "./CoursesListFilters.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    CL_CC_C: {
      display: "flex",
      backgroundColor: "white",
      /* overflow: hidden; */
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // paddingLeft: '1vw',
      // paddingRight: '12vw',
    },
    RelatedCourseCard: {
      width: "100%",
      borderRadius: "0px",
      // height: "100%",
    },
    formControl: {
      width: "18vw",
      paddingRight: "1.5vw",
    },
    CL_page_cc: {
      width: "100%",
      marginTop: "1em",
      marginBottom: "3em",
    },
    CL_dateRangePicker: {
      padding: "0px",
      width: "100%",
      height: "3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
    },
    loadingProfile_CLPN: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      '& .MuiCircularProgress-colorPrimary': {
        color: '#ff0000',
      }
    }
  })
);

interface Props {
  navigateToCoursesDetails: any;
  coursesList: any;
  handleFilterByChange?: any;
  currPage: any;
  totPage: any;
  navigation: any;
  handleFilterByPriceChange?: any;
  handleFilterByStartEndDateChange?: any;
  enableFilters?: boolean;

  CSLselectFilterBy?: any;
  CSLselectFilterByPrice?: any;
  CSLstartDate?: any;
  CSLendDate?: any;
  pageFrom: 'CoursesList' | 'CourseList' | 'ElasticSearch';
  fetchCourseListLoading: boolean;
}
interface States {
  posts: any;
  loading: boolean;
  currentPage: any;
  postsPerPage: any;
}

const CourseListPagination = ({
  navigateToCoursesDetails,
  coursesList,
  handleFilterByChange,
  currPage,
  totPage,
  navigation,
  handleFilterByPriceChange,
  handleFilterByStartEndDateChange,
  enableFilters,

  CSLselectFilterBy,
  CSLselectFilterByPrice,
  CSLstartDate,
  CSLendDate,
  pageFrom,
  fetchCourseListLoading,
}: Props) => {
  const classes = useStyles();

  // const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState<any>([]);
  const [postsPerPage] = useState(8);
  const [currentCourses, setCurrentCourses] = useState([]);
  const [totalCourses, setTotalCourses] = useState([]);



  // Get current posts
  const indexOfLastPost = currPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts;

  return (
    <div className={classes.CL_CC_C}>
      {enableFilters && (
        <CoursesListFilters
          handleFilterByChange={handleFilterByChange}
          handleFilterByPriceChange={handleFilterByPriceChange}
          handleFilterByStartEndDateChange={handleFilterByStartEndDateChange}
          CSLselectFilterBy={CSLselectFilterBy}
          CSLselectFilterByPrice={CSLselectFilterByPrice}
          CSLstartDate={CSLstartDate}
          CSLendDate={CSLendDate}
        />
      )}
      {fetchCourseListLoading ? (
        <div className={classes.loadingProfile_CLPN} style={{ marginLeft: "2em" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <CoursesListPageItem
            posts={coursesList}
            navigateToCoursesDetails={navigateToCoursesDetails}
          />
          <CourseListPaginate
            coursesList={coursesList}
            totPage={totPage}
            currPage={currPage}
            navigation={navigation}
            CSLselectFilterBy={CSLselectFilterBy}
            CSLselectFilterByPrice={CSLselectFilterByPrice}
            CSLstartDate={CSLstartDate}
            CSLendDate={CSLendDate}
            pageFrom={pageFrom}
          />
        </>
      )}
    </div>
  );
};

export default CourseListPagination;
