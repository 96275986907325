import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
}

export interface S {
  // Customizable Area Start
  is_loggedIn: boolean;
  userDetails: any;
  anchorEl: any;
  SearchBy: string;
  SearchQuery: string;
  userDetailsApiRes: boolean;
  locationDetails: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TopNavigationController extends BlockComponent<Props, S, SS> {
  apiUserDetailsCallId: any;
  locationDetailsCallId_BNav: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      is_loggedIn: false,
      userDetails: [],
      anchorEl: null,
      SearchBy: '',
      SearchQuery: '',
      userDetailsApiRes: false,
      locationDetails: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiUserDetailsCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("UserDetails_Response", responseJson);
            await this.setState({ is_loggedIn: true });
            await this.setState({ userDetails: responseJson.data.attributes });
            await this.setState({ userDetailsApiRes: true })
            setStorageData("userName", JSON.stringify(responseJson.data.attributes?.first_name));
          } else {
            console.log("ERR_UserDetails_Response", responseJson);
            await this.setState({ is_loggedIn: false });
            await this.setState({ userDetailsApiRes: true })

          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.locationDetailsCallId_BNav) {
          if (!responseJson.errors && !responseJson.error) {
            await this.setState({ locationDetails: responseJson.data });
          } else {
            console.log("ERR_UserDetails_Response", responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  getUserDetails_TN = async () => {
    this.setState({ userDetailsApiRes: false })
    const token = localStorage.getItem("loginT");
    const headers_TN = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserDetailsCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailsApiEndpoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers_TN));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  locationDetails_BNav = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.locationDetailsCallId_BNav = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationDetailsApiEndpoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleNavToLandingPage = () => {
    this.props.navigation?.navigate("Dashboard");
  };

  handleNavHome = () => {
    if (this.state.is_loggedIn) {
      this.props.navigation?.navigate("StudentDashboard");
    } else {
      this.props.navigation?.navigate("Dashboard");
    }
  };

  handleNavCourses = async () => {
    this.props.navigation?.navigate("CoursesList", { page: 1 });
  };

  handleNavLWU = () => {
    this.props.navigation?.navigate("EmailLogin");
  };

  handleNavBAT = () => {
    this.props.navigation?.navigate("TeachersLandingPage");
  };

  handleNavCU = () => {
    this.props.navigation?.navigate("ContactUs");
  };

  handleNavSU = () => {
    this.props.navigation?.navigate("SignUp");
  };

  handleNavLI = () => {
    this.props.navigation?.navigate("EmailLogin");
  };

  handleNavCart = () => {
  };

  handleProfile = async () => {
    this.props.navigation?.navigate("Profile");
  };

  handleSettings = async () => {
    this.props.navigation?.navigate("Settings");
  };

  handleLogout = async () => {
    await localStorage.clear();
    this.props.navigation?.navigate("EmailLogin");
  };

  searchNav = async (SearchBy: string, Query: string) => {
    await this.setState({
      SearchBy: SearchBy,
      SearchQuery: Query
    })
    if (Query?.length > 0) {
      this.props.navigation?.navigate('Search', { searchBy: SearchBy, query: Query })
      window.location.reload();
    }
  }
  
  handleNavHome_BNav = () => {
    this.props.navigation?.navigate("Dashboard");
  };

  handleNavCourses_BNav = () => {
    this.props.navigation?.navigate("CoursesList", { page: 1, startDate: 'aa', endDate: 'bb' });
  };


  handleNavBAT_BNav = () => {
    this.props.navigation?.navigate("TeachersLandingPage");
  };

  handleNavCU_BNav = () => {
    this.props.navigation?.navigate("ContactUs");
  };

  handleNavTwitter_BNav = () => {
    console.log("twitter");
  };

  handleNavLinkedin_BNav = () => {
    console.log("linkedin");
  };

  handleNavFb_BNav = () => {
    console.log("fb");
  };

  handleNavGoogle_BNav = () => {
    console.log("google");
  };

  async componentDidMount() {
    this.setState({ userDetailsApiRes: false })
    this.getUserDetails_TN();
    this.locationDetails_BNav();
  }
}

