import React from "react";
import Button from "@material-ui/core/Button";
import { boygirl } from "../assets";

interface Props {
    navigation:
    | {
        navigate: (to: any, params?: any) => void;
        getParam: (param: any, alternative: any) => any;
        goBack: () => void;
    }
    | undefined;
    history: any;
    location: any;
}

const Banner = ({ navigation, history, location }: Props) => {
    return (
        <div className="bnr-container">
            <div className="bnr-container-c">
                <img src={boygirl} alt="banner" className="bnr-img" />
                <div className="bnr-title-c">
                    <p>
                        Wide Range of Courses
                        <br />
                        Available
                    </p>
                    <Button
                        className="bnr-btn"
                        variant="contained"
                        style={{
                            width: "12vw",
                            height: "3vw",
                            backgroundColor: "#f0d27c",
                            borderRadius: 50,
                            color: "black",
                            fontSize: "0.85vw",
                            fontWeight: "bold",
                            textTransform: "none",
                            marginRight: "0.8vw",
                        }}
                    >
                        Learn More
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Banner;
