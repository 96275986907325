// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  onGoingClassesList:any
  addedJobsList:any
  recentlyAddedJobs:any
  isAssessmentPageLoading:any

  teacherProfileData: any;
  scheduleClassesList:any
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class TeachersAssessmentsController extends BlockComponent<Props, S, SS> {
  onGoingAssesmnetsCallId: any;
  teacherProfileCallId:any
  scheduleClassesCallId:any
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      isAssessmentPageLoading:false,
      onGoingClassesList:[],
      addedJobsList:[],
      recentlyAddedJobs:{},
      scheduleClassesList:[],
     
      teacherProfileData: {}

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getScheduleClasses()
    this.getOnGoingAssesmentClasses()
    this.getTeacherProfileDetails()
    
    // Customizable Area End
  }




  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForScheduleClasses(from,message)
    this.handleOnGoingAssesmentClassesResponse(from,message)
    this.handleTeacherProfileDataResponse(from,message)
    // Customizable Area End
  }

  handleTeacherProfileDataResponse =  (from: string, message: Message) => {
    if (this.teacherProfileCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
            teacherProfileData:apiResponse.data.attributes,
            
        })
      }
  }
  };
  handleOnGoingAssesmentClassesResponse=  (from: string, message: Message) => {
    if (this.onGoingAssesmnetsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          onGoingClassesList:apiResponse.data,
          isAssessmentPageLoading:false
        })
      }
  }
  };

  handleResponseForScheduleClasses=(from: string, message: Message)=>{
    if (this.scheduleClassesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
           scheduleClassesList:apiResponse.data,
       
        })
      }
  }
}

 
  getTeacherProfileDetails() {
    this.setState({
      isAssessmentPageLoading: true,
    })
    const token = localStorage.getItem("loginT");

    const headers = {
      token: token,
    };

    const teacherProfileDetailsRqstMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teacherProfileCallId = teacherProfileDetailsRqstMsg.messageId;

    teacherProfileDetailsRqstMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailsEndPoint
    );

    teacherProfileDetailsRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    teacherProfileDetailsRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(teacherProfileDetailsRqstMsg.id, teacherProfileDetailsRqstMsg);
  }


  getOnGoingAssesmentClasses() {
    this.setState({
      isAssessmentPageLoading:true
    })
    const token = localStorage.getItem("loginT");
    const endPoint = `/assessments/ongoing_assessments_classes`;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const onGoingClassesMsgId = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.onGoingAssesmnetsCallId = onGoingClassesMsgId.messageId;


    onGoingClassesMsgId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    onGoingClassesMsgId.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    onGoingClassesMsgId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(onGoingClassesMsgId.id, onGoingClassesMsgId);

  }


  getScheduleClasses=()=>{
    const token = localStorage.getItem("loginT");
    const endPoint = `my_classes`;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getscheduleClassesRqstMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.scheduleClassesCallId = getscheduleClassesRqstMessage.messageId;


    getscheduleClassesRqstMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getscheduleClassesRqstMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getscheduleClassesRqstMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getscheduleClassesRqstMessage.id, getscheduleClassesRqstMessage);

  }

 






}

// Customizable Area End
