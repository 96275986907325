import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {} from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform, Share } from "react-native";
import moment from "moment";
import Clipboard from '@react-native-clipboard/clipboard';
export interface Webinar {
  id: string;
  type: string;
  attributes: {
    description: string;
    id: number;
    name: string;
    scheduled_date: string;
    scheduled_time: string;
    scheduled_start_time: string;
    scheduled_end_time: string;
    agora_record: {
      id: number;
      live_streaming_token: string;
      recording_token: string;
      recording_uid: number;
      resource_id: string;
      webinar_id: number;
      sid: string;
      recording_url: string;
      created_at: string;
      updated_at: string;
      rtm_token: string;
      creator_id: number;
    };
    uid: number;
  };
}

interface IToken {
  uid: number;
  webinar_name: string;
  live_streaming_token: string;
  rtm_token: string;
  teacher_id: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props{
  navigation: any;
  id: string;
  // Customizable Area Start
  onJoinCall: (props: {
    channelName: string;
    agoraRtcToken: string;
    agoraRtmToken: string;
    hostId: number;
    userId: number;
    webinarId: string;
  }) => void;
  isHost: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  userId: number;
  listWebinar: Webinar[];
  link: string;
  isFormOpen:boolean;
  editWebinar:{
    name:string;
    description:string;
    date:string;
    startTime:string;
    endTime:string;
    webinarId:string
  }
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class WebinarsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiToken = "";
  webinarId = "";
  getListWebinarMessageId = "";
  startWebinarMessageId: string = "";
  joinWebinarMessageId: string = "";
  deleteWebinarMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      listWebinar: [],
      userId: 0,
      link: "",
      isFormOpen: false,
      editWebinar:{
        name:'',
        description:'',
        date:'',
        startTime:'',
        endTime:'',
        webinarId:''
      }
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (
      message.properties.RestAPIResponceDataMessage ===
      this.startWebinarMessageId
    ) {
      this.handleStartCallSuccessResponse(
        message.properties.RestAPIResponceSuccessMessage,
        true
      );
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.joinWebinarMessageId
    ) {
      this.handleStartCallSuccessResponse(
        message.properties.RestAPIResponceSuccessMessage,
        false
      );
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.getListWebinarMessageId &&
      !!message.properties.RestAPIResponceSuccessMessage    
    ) {
      this.handleGetListWebinarSuccessResponse(
        message.properties.RestAPIResponceSuccessMessage
      );
    }

    if (
      message.properties.RestAPIResponceDataMessage ===
      this.deleteWebinarMessageId
    ) {
      this.getListWebinar();
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let apiToken = await getStorageData("SignUpToken");
    let loginToken =  await getStorageData('loginT');  
      
    if(Platform.OS === 'web'){
      this.apiToken = loginToken 
      this.getListWebinar();
    }else {
      this.apiToken = apiToken;
      this.props.navigation.addListener("willFocus", () => {
        this.getListWebinar();
      });
    }

    return Promise.resolve();
  }

  onChangeUserId = (userId: string) => {
    this.setState({
      userId: Number(userId),
    });
  };

  onChangeWebinarLink = (link: string) => {
    this.setState({
      link,
    });
  };

  getListWebinar = () => {
    const endPoint = configJSON.webinarGetListAPILink as string;
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.apiToken,
    };

    const getListWebinarMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListWebinarMessageId = getListWebinarMsg.messageId;

    getListWebinarMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    getListWebinarMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getListWebinarMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(getListWebinarMsg.id, getListWebinarMsg);
  };

  handleStartCallSuccessResponse = (
    data: { data: Webinar } | IToken,
    isStartCall: boolean
  ) => {
    const webinar = data as { data: Webinar };
    const token = data as IToken;
    let agoraUserId = -1
    if(Platform.OS === 'web' && isStartCall) {
      agoraUserId = webinar.data.attributes.uid
    } else if(Platform.OS === 'web' && !isStartCall) {
      agoraUserId = token.uid
    }
    else {
      agoraUserId = this.state.userId
    }
    this.props.onJoinCall({
      channelName: isStartCall
        ? webinar.data.attributes.name
        : token.webinar_name,
      agoraRtcToken: isStartCall
        ? webinar.data.attributes.agora_record.live_streaming_token
        : token.live_streaming_token,
      agoraRtmToken: isStartCall
        ? webinar.data.attributes.agora_record.rtm_token
        : token.rtm_token,
      hostId: isStartCall
        ? webinar.data.attributes.agora_record.creator_id
        : token.teacher_id,
      userId: agoraUserId,
      webinarId: this.webinarId,
    });
  };

  handleGetListWebinarSuccessResponse = ({ data }: { data: Webinar[] }) => {
    if(!!data){
    this.setState({
      listWebinar: data,
    });   
    } else {
      this.setState({
      listWebinar: [],
      });
    }
  };

  shareLink = (webinar: Webinar) => {
    Share.share({
      title: configJSON.webinarSharedLinkTitle,
      url:
        configJSON.webinarSharedLink +
        webinar.id +
        "/" +
        webinar.attributes.name,
      message:
        configJSON.webinarSharedLink +
        webinar.id +
        "/" +
        webinar.attributes.name,
    });
  };

  deleteWebinar = (webinar: Webinar) => {
    Alert.alert("Do you want to delete this Webinar?", "", [
      { text: configJSON.stopRecordCancelBtn },
      {
        text: configJSON.confirmTitleButton,
        style: "destructive",
        onPress: async () => {
          this.deleteAPIFunctionHandler(webinar);
        },
      },
    ]);
  };

  deleteAPIFunctionHandler = (webinar: Webinar) => {
    const startWebinarMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.apiToken,
    };
    this.deleteWebinarMessageId = startWebinarMsg.messageId;
    const endPoint = configJSON.webinarGetStreamAPILink as string;
    startWebinarMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint + "/" + webinar.id
    );
    startWebinarMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    startWebinarMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    runEngine.sendMessage(startWebinarMsg.id, startWebinarMsg);
  };

  openCreateNew = () => {
    if(Platform.OS === 'web') {
      this.setState({isFormOpen: true})
    } else {
      this.props.navigation.navigate("DetailWebinar");
    }
  };

  openEditWebinar = (webinar: Webinar) => () => {    
    if(Platform.OS === 'web') { 
      this.setState({isFormOpen: true,editWebinar: {...this.state.editWebinar, name: webinar.attributes.name,
      description: webinar.attributes.description,
      date: webinar.attributes.scheduled_date,
      startTime: webinar.attributes.scheduled_start_time,
      endTime: webinar.attributes.scheduled_end_time,
      webinarId: webinar.id
      }})
    } else {
    this.props.navigation.navigate("DetailWebinar", {
      webinar,
    });
  }
  };

  checkTimeisPassed = (time: string) => {
    const chosenTime = moment(time, "YYYY-MM-DD hh:mm a").toDate().getTime();
    const curentTime = new Date().getTime();
    return curentTime < chosenTime;
  };

  hasEditButton = (webinar: Webinar) => {
    const isValid = this.checkTimeisPassed(
      webinar.attributes.scheduled_date +
        " " +
        webinar.attributes.scheduled_start_time.toLocaleLowerCase()
    );

    return isValid;
  };
  
  startCallAPIHandler = ({
    endPoint,
    webinar,
  }: {
    webinar?: Webinar;
    endPoint: string;
  }) => {
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.apiToken,
    };
    const startWebinarMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (!!webinar) {
      this.startWebinarMessageId = startWebinarMsg.messageId;
    } else {
      this.joinWebinarMessageId = startWebinarMsg.messageId;
    }
    startWebinarMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    startWebinarMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    startWebinarMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );
    runEngine.sendMessage(startWebinarMsg.id, startWebinarMsg);
  } 

  copyToClipboard = (copiedLink:string) => {
    Clipboard.setString(copiedLink);
  };

  closeFormHandler = () => {    
    this.setState({isFormOpen: false,editWebinar: {...this.state.editWebinar, name: '',
      description: '',date: '',startTime: '',endTime: '',webinarId: ''}})
    this.getListWebinar()
  }
  
  startCall = (webinar?: Webinar) => {
    try {
      if (
        !!webinar &&
        (this.checkTimeisPassed(
          webinar.attributes.scheduled_date +
            " " +
            webinar.attributes.scheduled_start_time.toLocaleLowerCase()
        ) ||
          !this.checkTimeisPassed(
            webinar.attributes.scheduled_date +
              " " +
              webinar.attributes.scheduled_end_time.toLocaleLowerCase()
          ))
      ) {
        Alert.alert(configJSON.webinarStartWarning);
        Platform.OS === 'web' && alert(configJSON.webinarStartWarning)
        return;
      }
      let endPoint = "";
      if (!!webinar) {
        this.webinarId = webinar.id;
        endPoint = (configJSON.webinarStartStreamAPILink as string).replace(
          "${id}",
          webinar.id
        );
      } else {
        let webinarId = "";
        try {
          const splitString = this.state.link.split("/webinars/");
          const includeIdString = splitString[1];
          const splitIdString = includeIdString.split("/");
          webinarId = splitIdString[0];          
        } catch (error) {}
        if(Platform.OS !== 'web' && (!webinarId || !this.state.userId)) {
                 
            Alert.alert(configJSON.WrongWebinarLink, "");                   
            return;
                    
        } else if(!webinarId) {
          alert(configJSON.WrongWebinarLink)
          return;
        }
        endPoint = (configJSON.webinarJoinStreamAPILink as string).replace(
          "${id}",
          webinarId
        );
      }
      this.startCallAPIHandler({ webinar, endPoint });
    } catch (error) {}
  };

  // Customizable Area End
}
