import React, { Component } from "react";
import Banner from "./Components/Banner.web";
import PopularCourses from "./PopularCourses.web";
import SignUpController, { Props } from "./SignUpController.web";
import { dropdown } from "./assets";
import Button from "@material-ui/core/Button";
import moment from "moment";
import TopNavigation from "./TopNavigation.web";
import BottomNavigation from "./BottomNavigation.web";

export default class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="su-c-container">
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />

        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />

        <div className="container-c">
          <div className="sign-up-card">
            <div className="SU_S_su-title-container">
              <p className="su-title">Sign Up</p>
              <p className="su-sub-title">Step 1 of 2</p>
            </div>
            <div className="su-input-container">
              <div className="su-as-container">
                <label className="su-label-1" data-testId="labels">
                  I am registering as a<sup className="required">*</sup>
                </label>
                <div className="su-select-L-T-c">
                  <select
                    data-testId="txtInputCType"
                    className="su-select-L-T-c-dd-s"
                    value={this.state.signUpAs}
                    onChange={(e) => {
                      this.handleCTypeChange(e);
                    }}
                  >
                    <option value="learner">Learner</option>
                    <option value="teacher">Teacher</option>
                  </select>
                  <span className="su-select-L-T-c-input-arrow">
                    <img src={dropdown} alt="arrow" />
                  </span>
                </div>
              </div>
              <div className="su-steps-container">
                <div className="su-steps-title-container">
                  <p className="su-steps-title-1">Profile</p>
                  <p className="su-steps-title-2">Academics</p>
                </div>
                <div className="su-steps-c">
                  <div className="su-steps">
                    <div className="su-steps-c-1">
                      <p>1</p>
                    </div>
                  </div>
                  <hr className="su-steps-hl" />
                  <div className="su-steps">
                    <div className="su-steps-c-2">
                      <p>2</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Signup forms **************************************************/}

            <div>
              <div>
                <div className="SU_S_pr-title">
                  <p>Profile Registration</p>
                </div>
                <form className="SU_S_prl-inputs-container">
                  <div className="prl-input-cc" data-testId="inputsContainers">
                    <div className="prl-input-left" data-testId="leftInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        First Name<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputFirstName"
                        className="SU_S_prl-input"
                        type="text"
                        value={this.state.fName}
                        required
                        onChange={(e) => {
                          this.handleFNameChange(e);
                        }}
                        placeholder="Enter First Name"
                        style={this.state.errFName ? this.errStyle : {}}
                      />
                      {this.state.errFName ? (
                        <p className="prl-input-err-txt" data-testId="errorTag">
                          Please enter a value.
                        </p>
                      ) : (
                        <></>
                      )}
                      {this.state.errMaxFName ? (
                        <p className="prl-input-err-txt" data-testId="maxErrorTag">
                          Maximum 15 characters
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="prl-input-right" data-testId="rightInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Last Name<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputLastName"
                        className="SU_S_prl-input"
                        type="text"
                        value={this.state.lName}
                        required
                        onChange={(e) => {
                          this.handleLNameChange(e);
                        }}
                        placeholder="Enter Last Name"
                        style={this.state.errLName ? this.errStyle : {}}
                      />
                      {this.state.errLName ? (
                        <p className="prl-input-err-txt">Please enter a value. </p>
                      ) : (
                        <></>
                      )}
                      {this.state.errMaxLName ? (
                        <p className="prl-input-err-txt">Maximum 15 characters</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="prl-input-cc" data-testId="inputsContainers">
                    <div className="prl-input-left" data-testId="leftInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Gender
                      </label>
                      <div className="prl-input-dd">
                        <select
                          data-testId="txtInputGender"
                          className="SU_S_prl-input-dd-s"
                          value={this.state.gender}
                          onChange={(e) => {
                            this.handleGenderChange(e);
                          }}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        <span className="prl-input-arrow">
                          <img src={dropdown} alt="arrow" />
                        </span>
                      </div>
                    </div>
                    <div className="prl-input-right" data-testId="rightInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Date of Birth<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputDateInput"
                        className="SU_S_prl-input"
                        type="date"
                        value={this.state.date}
                        required
                        onChange={(e) => {
                          this.handleDateChange(e);
                        }}
                        max={moment().format("YYYY-MM-DD")}
                        placeholder="05/03/1995"
                        style={this.state.errDate ? this.errStyle : {}}
                      />
                      {this.state.errDate ? (
                        <p className="prl-input-err-txt">Please enter a valid date.</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="prl-input-cc" data-testId="inputsContainers">
                    <div className="prl-input-left" data-testId="leftInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Email<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputEmail"
                        className="SU_S_prl-input"
                        type="email"
                        name="email"
                        value={this.state.cemail}
                        required
                        onChange={(e) => {
                          this.handleEmailChange(e);
                        }}
                        placeholder="email@gmail.com"
                        style={this.state.errEmail ? this.errStyle : {}}
                      />
                      {this.state.errEmail ? (
                        <p className="prl-input-err-txt">Please enter a valid email address.</p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="prl-input-right" data-testId="rightInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Mobile no<sup className="required">*</sup>
                      </label>
                      <div className="prl-input-phone">
                        <div className="prl-input-dd-phone" data-testId="inpContainer">
                          <select
                            data-testId="txtInputMobileCode"
                            className="SU_S_prl-input-dd-s-phone"
                            value={this.state.mobileCode}
                            onChange={(e) => {
                              this.handleMobileCodeChange(e);
                            }}
                          >
                            <option value="91">+91</option>
                          </select>
                          <span className="prl-input-arrow">
                            <img src={dropdown} alt="arrow" />
                          </span>
                        </div>
                        <input
                          data-testId="txtInputMobile"
                          className="prl-input-i-phone"
                          type="text"
                          pattern="[0-9]*"
                          value={this.state.mobile}
                          required
                          onChange={(e) => {
                            this.handleMobileChange(e);
                          }}
                          placeholder="0000000000"
                          style={this.state.errPhone ? this.errStyle : {}}
                        />
                      </div>
                      {this.state.errPhone ? (
                        <p className="prl-input-err-txt">Please enter a value.</p>
                      ) : (
                        <></>
                      )}
                      {this.state.errMaxPhone ? (
                        <p className="prl-input-err-txt">Maximum 15 characters</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="prl-input-cc" data-testId="inputsContainers">
                    <div className="prl-input-left" data-testId="leftInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Country<sup className="required">*</sup>
                      </label>
                      <div className="prl-input-dd">
                        <select
                          data-testId="txtInputCountry"
                          className="SU_S_prl-input-dd-s"
                          onChange={(e) => {
                            this.handleCountryChange(e);
                          }}
                          style={this.state.errCountry ? this.errStyle : {}}
                        >
                          <option value="Select Country" data-key="Empty">
                            Select Country
                          </option>
                          {this.state.listCountry.map((val, key) => {
                            return (
                              <option
                                value={key}
                                key={key}
                                data-key={val.isoCode}
                                a-key={val.isoCode}
                                className="CountryOption"
                              >
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                        <span
                          className="prl-input-arrow"
                          style={this.state.errCountry ? { backgroundColor: "#fcecec" } : {}}
                        >
                          <img src={dropdown} alt="arrow" />
                        </span>
                        {this.state.errCountry ? (
                          <p className="prl-input-err-txt" data-testId="errorTag">
                            Please select a value.
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="prl-input-right" data-testId="rightInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        State<sup className="required">*</sup>
                      </label>
                      <div className="prl-input-dd">
                        <select
                          data-testId="txtInputState"
                          className="SU_S_prl-input-dd-s"
                          defaultValue="Select State"
                          onChange={(e) => {
                            this.handleStateChange(e);
                          }}
                          style={this.state.errState ? this.errStyle : {}}
                        >
                          <option value="Select State">Select State</option>
                          {this.state.listState.map((val, key) => {
                            return (
                              <option value={key} key={key}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                        <span
                          className="prl-input-arrow"
                          style={this.state.errState ? { backgroundColor: "#fcecec" } : {}}
                        >
                          <img src={dropdown} alt="arrow" />
                        </span>
                        {this.state.errState ? (
                          <p className="prl-input-err-txt">Please select a value.</p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="prl-input-cc" data-testId="inputsContainers">
                    <div className="prl-input-left" data-testId="leftInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        City<sup className="required">*</sup>
                      </label>
                      <div className="prl-input-dd">
                        <select
                          data-testId="txtInputCity"
                          className="SU_S_prl-input-dd-s"
                          defaultValue="Select City"
                          onChange={(e) => {
                            this.handleCityChange(e);
                          }}
                          style={this.state.errCity ? this.errStyle : {}}
                        >
                          <option value="Select City">Select City</option>
                          {this.state.listCity.map((val, key) => {
                            return (
                              <option value={val.name} key={key}>
                                {val.name}
                              </option>
                            );
                          })}
                        </select>
                        <span
                          className="prl-input-arrow"
                          style={this.state.errCity ? { backgroundColor: "#fcecec" } : {}}
                        >
                          <img src={dropdown} alt="arrow" />
                        </span>
                        {this.state.errCity ? (
                          <p className="prl-input-err-txt">Please select a value.</p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="prl-input-right" data-testId="rightInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Address<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputAddress"
                        className="SU_S_prl-input"
                        type="text"
                        value={this.state.address}
                        required
                        onChange={(e) => {
                          this.handleAddressChange(e);
                        }}
                        placeholder="Enter Details"
                        style={this.state.errAddress ? this.errStyle : {}}
                        autoComplete="new-password"
                      />
                      {this.state.errAddress ? (
                        <p className="prl-input-err-txt">Please enter a value.</p>
                      ) : (
                        <></>
                      )}
                      {this.state.errMaxAddress ? (
                        <p className="prl-input-err-txt">Maximum 30 characters</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="prl-input-cc" data-testId="inputsContainers">
                    <div className="prl-input-left" data-testId="leftInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Password<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputPass"
                        className="SU_S_prl-input"
                        type="password"
                        value={this.state.pass}
                        required
                        onChange={(e) => {
                          this.handlePassChange(e);
                        }}
                        placeholder="********************"
                        style={this.state.errPass ? this.errStyle : {}}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="prl-input-right" data-testId="rightInputContainer">
                      <label className="prl-input-lable" data-testId="labels">
                        Confirm Password<sup className="required">*</sup>
                      </label>
                      <input
                        data-testId="txtInputCPass"
                        className="SU_S_prl-input"
                        type="password"
                        value={this.state.cPass}
                        required
                        onChange={(e) => {
                          this.handleCPassChange(e);
                        }}
                        placeholder="********************"
                        style={this.state.errCPass ? this.errStyle : {}}
                      />
                    </div>
                  </div>
                </form>
                <div className="submit-container">
                  <div>
                    <Button
                      data-testId="txtInputNextBtn"
                      className="submit"
                      onClick={() => {
                        this.handleSubmit();
                      }}
                      disableElevation
                      variant="contained"
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Inter, sans-serif",
                        backgroundColor: "#FF0000",
                        borderRadius: "50px",
                        width: "15vw",
                        height: "2.8vw",
                        fontSize: "0.9vw",
                        textTransform: "none",
                        fontWeight: 700,
                      }}
                    >
                      Next
                    </Button>
                  </div>
                  <div className="already" style={{ marginTop: "1.5em" }}>
                    <p>Already on Techfin? </p>
                    <button
                      data-testId="txtInputRegisterBtn"
                      className="su-login-btn"
                      onClick={() => {
                        this.handleLoginNavigate();
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopularCourses
            history={this.props.history}
            location={this.props.location}
            navigation={this.props.navigation}
            classes={undefined}
          />
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
