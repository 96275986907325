import moment from "moment";
import React, { Component } from "react";
import ReactPlayer from "react-player";

const Style = {
  SECTION: "border p-4",
  BUTTON_GROUP: "flex gap-2",
  BUTTON:
    "inline-block px-4 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out disabled:opacity-75",
};

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

export interface ReactPlayerTestProps {
  urll: string;
  classes?: any;
  styles?: any;
  EndReached?: any;
  handleEnd: boolean;
  playBackFrom?: string;
}

export interface ReactPlayerTestState {
  url?: string;
  pip: boolean;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  seeking: boolean;
  settingActive: boolean;
  buffering: boolean;
  fullScreenn: boolean;
  initIsReady: boolean;

  initStartTimeInSeconds: number;
  isReady: boolean;
  isCompleated: boolean;
  playedSeconds: number;
}

const DEFAULT_STATE: ReactPlayerTestState = {
  url: undefined,
  pip: false,
  playing: false,
  controls: false,
  light: false,
  volume: 1,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  seeking: false,
  settingActive: false,
  buffering: false,
  fullScreenn: false,
  initIsReady: false,

  initStartTimeInSeconds: 0,
  isReady: false,
  isCompleated: false,
  playedSeconds: 0,
};

export default class ReactPlayerController extends Component<ReactPlayerTestProps, ReactPlayerTestState> {
  urlInput: HTMLInputElement | null = null;
  player: ReactPlayer | null = null;
  rrf: any;
  state = DEFAULT_STATE;

  toHHMMSS = (secs: any) => {
    return moment()
      .startOf("day")
      .seconds(secs)
      .format("H:mm:ss");
  };
  load = (url?: string) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handlePlayPause = async () => {
    console.log("playPause", this.state.playing);
    console.log("playPause played", this.state.played);
    this.setState({ playing: !this.state.playing });
    console.log("EndReached playPause triggered");
    this.props.EndReached(
      this.state.duration,
      this.toHHMMSS(this.state.playedSeconds),
      this.state.isCompleated
    );
  };

  handleStop = async () => {
    this.setState({ ...DEFAULT_STATE });
  };

  handleToggleControls = async () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: undefined,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = async () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = async () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = async (e: any) => {
    this.setState({ volume: parseFloat(e.currentTarget.value) });
  };

  handleToggleMuted = async () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = async (e: any) => {
    this.setState({ playbackRate: parseFloat(e.currentTarget.value) });
  };

  handleOnPlaybackRateChange = async (speed: string) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handleTogglePIP = async () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = async () => {
    console.log("onPlay");
    this.setState({ playing: true });
    console.log("onPlay");
    this.setState({ buffering: false });
    console.log(this.state.buffering);
  };

  handleEnablePIP = async () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = async () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = async () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = async (e: any) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = async (e: any) => {
    this.setState({ played: parseFloat(e.currentTarget.value) });
  };

  handleSeekMouseUp = async (e: any) => {
    this.setState({ seeking: false });
    if (this.player) {
      this.player.seekTo(parseFloat(e.currentTarget.value));
    }
  };

  handleProgress = async (progress: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    console.log("onProgress", progress);

    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      const { played, loaded = 0, playedSeconds } = progress;
      this.setState({ played, playedSeconds, loaded });
    }
    if (this.props.handleEnd) {
      if (progress.played === 1) {
        await this.setState({ isCompleated: true });
        console.log("EndReached handleProgress triggered");
        this.props.EndReached(
          this.state.duration,
          this.toHHMMSS(this.state.playedSeconds),
          this.state.isCompleated
        );
      }
    }
  };

  handleEnded = async () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
    if (this.props.handleEnd) {
      console.log(this.state.played);
      this.props.EndReached(
        this.state.duration,
        this.toHHMMSS(this.state.playedSeconds),
        this.state.isCompleated
      );
    }
  };

  handleDuration = async (duration: number) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  handleClickFullscreen = async () => {
    // screenfull.request(findDOMNode(this.player) as Element);

    await this.setState({ fullScreenn: !this.state.fullScreenn });
    console.log("onFullScreen", this.state.fullScreenn);
    var elem = this.rrf;
    if (this.state.fullScreenn) {
      console.log("onFullScreen");
      if (elem.requestFullscreen) {
        console.log("elem.enterFullscreen onExitFullScreen");
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      console.log("onExitFullScreen");
      if (document.exitFullscreen) {
        console.log("elem.exitFullscreen onExitFullScreen");
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  renderLoadButton = async (url: string, label: string) => {
    return (
      <button onClick={() => this.load(url)} type="button" className={Style.BUTTON}>
        {label}
      </button>
    );
  };

  ref = async (player: ReactPlayer) => {
    this.player = player;
  };

  hmsToSecondsOnly = (str: any) => {
    let a = str.split(":"); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];

    return seconds;
  };

  componentDidMount = async () => {
    console.log("componentDidMount ReactPlayerController");
    this.load(this.props.urll);
    if (this.props.playBackFrom) {
      const startTime =  await this.hmsToSecondsOnly(this.props.playBackFrom);
      console.log("initTime", startTime, typeof startTime);
      await this.setState({ initStartTimeInSeconds: startTime });
    }
  };

  settingsMouseLeave = async () => {
    await this.setState({ settingActive: false });
    console.log(this.state.settingActive);
  };
}
