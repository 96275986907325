import React , { RefObject } from "react";
import TeachersProfileController, { Props } from "./TeachersProfileController.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import DashSidebar1 from './Components/DashSidebar1.web';
import DashSidebar3 from './Components/DashSidebar3.web';
import DashSidebar2 from './Components/DashSidebar2.web';
import Typography from "@material-ui/core/Typography";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { updatepicture, dropdown, fileUploadIcon, takePhotoRed, UploadPhotoRed, crossImage } from "./assets";
import Grid from '@material-ui/core/Grid';
import moment from "moment";
import Modal from '@material-ui/core/Modal';
import { WebcamCapture } from "./WebCamComponent.web"
import CircularProgress from '@material-ui/core/CircularProgress';
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import { Divider } from "react-native-elements";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    teachersMainContainer: {
      paddingTop: "3vw",
      display: "flex",
      paddingLeft: "13vw",
      paddingRight: "13vw",
    },
    leftContainer: {
      flex: 2,
    },
    rightContainer: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    profileUpdateBackground: {
      position: "absolute",
      width: "50em",
      height: "120px",
      // left: "584px",
      top: "220px",
      background: " #F0D17C",
      // mix-blend-mode: "normal",
      opacity: "0.11",
      borderRadius: "20px",
    },
    teacherMainContainer: {
      marginTop: "100px",
      position: "relative",
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center'
    },

    teacherprofileUpdateBackground: {
      position: "relative",
      height: "120px",
      background: " #F0D17C",
      opacity: "0.11",
      borderRadius: "20px",
      top: 0,
      left: 0,
      width: "100%"
    },
    teacherProfileImageSec: {
      position: "absolute",
      /* top: -41px; */
      // left: "32em",
      bottom: "31px",
    },
    ProfileTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },


    teacherUpdateButton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      color: "#fff",
      marginTop: "13px",
      textTransform: "none",
      width:"12em",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },

    },
    teacherUpdatePreviewButton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      color: "#fff",
      marginTop: "13px",
      textTransform: "none",
      width: "8em",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },

    },
    teacherUpdateCancelButton: {
      marginRight: "1em",
      border: "1px solid  #ff0000",
      borderRadius: "25px",
      background: "#fff",
      width: "8em",
      marginTop: "13px",
      textTransform: "none",
      transparent: "",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },

    },
    teacherFormUpdateButton: {
      borderRadius: "100px",
      width: "15em",
      height: "3.5em",
      backgroundColor: "#ff0000",
      color: "#fff",
      marginTop: "13px",
      textTransform: "none",
      cursor: "pointer",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    teacherInputFormSectionInner: {
      background: "#F7F7F7",
      top: "410px",
      height: "auto",
      padding: 30,
      borderRadius: "20px",
      marginBottom: "5em"
    },

    teacherupdatepopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "31vw",
      height: "25vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      },
    },

    teacherUpdatePopUpCamera: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "58vw",
      height: "35vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      },
    },
    TeacherProfileUploadText: {
      fontFamily: "Inter, sans-serif",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      letterSpacing: "0.1px",
      color: "#ff0000",
    },
    teacherUpdatePreviewpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "31vw",
      height: "25vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      },
    },


    TeacherProfileUploadclose: {
      width: "0.8em",
      height: "0.7em",
      color: "#ff0000",
      marginTop: "1em"
    },
    buttonUpload: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "24px",
      background: "#f7f7f7",
      width: "50%",
      height: "198px",
      marginRight: "2em",
      position: "relative"
    },
    buttonTakePhoto: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "24px",
      background: "#f7f7f7",
      width: "50%",
      height: "198px",
    },
    teacherPreviewImage: {
      width: "100%",
      height: "60%",
    },

    teacherSuccessPopUp: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "29vw",
      height: "16vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px"
      },
    },
    teacherSuccessPopUpInner: {
      display: "flex", 
      flexDirection: "column", 
      justifyContent: "center",
        alignItems: "center", 
        height: "100%"
    },

    teacherLoadingProfile: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },
  okayButton:{
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "14vw",
      height: "3vw",
      border: "1px solid red",
      textTransform: "none",
  },
  teachermobileNumberCode:{
    width:" 100%",
    height:"3.5rem",
    background: "white",
    color: "#a8a8a8",
    cursor: "pointer",
    border:" 1px solid #c7c7c7",
    paddingLeft: "0.5em",
    fontSize: "0.8em",
    fontFamily:" Inter, sans-serif",
    borderRadius: "0.5em",
    appearance: "none",
    WebkitAppearance: "none",
    MozAppearance: "none",
    backgroundImage: `url(${dropdown})`,
    backgroundSize: "2em",
    backgroundPosition: "right 10px top 50%",
    backgroundRepeat: "no-repeat",
  },
  updatedMessage:{
    color: "#14292d",
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    marginBottom: "1em",
  }

  });
// Customizable Area End
class TeachersProfile extends TeachersProfileController {
  inputRef: RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
  }
  handleImageClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  };
  render() {
    const { classes } = this.props;
    const { attributes } = this.state?.profileData
    return (
      <div>
        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
        <div className={classes.teachersMainContainer}>
          <div className={classes.leftContainer}>
            <DashSidebar1 active="Profile" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
            <DashSidebar2
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              // subtitle=" Lorem Ipsum is simply dummy text of the printing"
              // onClick={() => { console.log("Next Class") }}
              styles={{ marginBottom: '1.5vw' }}
            />
            <DashSidebar3
              title="One on One classes with Students"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              Buttontitle="View your Classes"
              styles={{ marginBottom: '1.5vw' }}
              onClick={() => { }}
            />
          </div>
          <div className={classes.rightContainer}>

          <div>
            <Typography className={classes.ProfileTitle}>Profile</Typography>
            </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>


            {this.state.isLoading ?
              <div className={classes.teacherLoadingProfile} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
              <>
                <div className={classes.teacherMainContainer} >
                  <div className={classes.teacherprofileUpdateBackground} style={{ background: " #F0D17C", height: "120px" }}></div>
                  <div className={classes.teacherProfileImageSec} >
                    {this.state?.profileData?.attributes?.profile_image === null || undefined ?
                      <img src={updatepicture} alt="" style={{ width: "80", height: "auto", padding: 0 }} /> :
                      <img src={this.state?.profileData?.attributes?.profile_image} alt="" style={{ width: "8.5em", height: "8.5em", padding: 0, borderRadius: "50%",marginLeft:"9px" }} />
                    }
                    <div style={{ marginLeft: "-0.9em" }}>
                      <Button className={classes.teacherUpdateButton} variant="contained" onClick={() => this.handleTeacherUploadProfilePicture(true)}>Update Picture</Button>
                    </div>
                  </div>
                </div>


                <Typography style={{ marginTop: 65, fontSize: 22, fontWeight: 550, marginBottom: 20 }}>Update Profile Info</Typography>

                <div className={classes.inputFormSection}>
                  <div className={classes.teacherInputFormSectionInner}>

                    <form onSubmit={(e) => this.handleFormValue(e)}>
                      <Grid container>
                        <Grid item md={6}>
                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                First Name <sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                value={this.state.userInputValues.firstName}
                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="Enter First Name"
                                name="firstName"
                                style={this.state.errFirstName ? this.errStyle : {}}
                              />
                              {this.state.errFirstName ? (
                                <p className="prl-input-err-txt">Please enter a value. </p>
                              ) : (
                                <></>
                              )}
                              {this.state.errorStatusFirstName ? (
                                <p className="prl-input-err-txt">Maximum 15 characters</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Date of Birth<sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="date"
                                value={this.state.userInputValues.dateOfBirth}
                                max={moment().format("YYYY-MM-DD")}
                                placeholder="05-03-1995"
                                onChange={(e: any) => {
                                  this.handleDateChange(e)
                                }}
                                name="dateOfBirth"
                                style={this.state.errDate ? this.errStyle : {}}
                              />
                              {this.state.errDate ? (
                                <p className="prl-input-err-txt">Please enter a valid date.</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Email<sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"

                                value={this.state.userInputValues.email}

                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="email@gmail.com"
                                name="email"
                                style={this.state.errEmail ? this.errStyle : {}}
                              />
                              {this.state.errEmail ? (
                                <p className="prl-input-err-txt">Please enter a valid email address.</p>
                              ) : (
                                <></>
                              )}
                            </div>

                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">Qualification<sup className="required">*</sup></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"
                                  value={this.state.userInputValues.qualification}
                                  onChange={(e: any) => {
                                    this.handleInputChange(e)
                                  }}
                                  placeholder="Post Graduate"
                                  name="qualification"
                                >
                                  <option value={this.state.userInputValues.qualification}>{this.state.userInputValues.qualification}</option>
                                  {this.state.qualifactionListData.map((eachQualification: any, index_value: any) => {
                                    if (eachQualification.name === attributes?.qualification) {
                                      return
                                    }
                                    return (
                                      <option value={eachQualification.name} key={index_value}>
                                        {eachQualification.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Specialization<sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                value={this.state.userInputValues.specialization}
                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="Post Graduate"
                                name="specialization"
                              />
                            </div>
                          </div>



                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Languages Known<sup className="required">*</sup>
                              </label>
                              <FormGroup row style={{ marginTop: "1.5em" }}>

                                <FormControlLabel control={<Checkbox checked={this.state.userInputValues.languagesKnown.english} onChange={(e: any) => this.handleCheckBoxChanges(e)} name="english" />} label="English" />
                                <FormControlLabel control={<Checkbox checked={this.state.userInputValues.languagesKnown.hindi} onChange={(e: any) => this.handleCheckBoxChanges(e)} name="hindi" />} label="Hindi" />
                                <FormControlLabel control={<Checkbox checked={this.state.userInputValues.languagesKnown.others} onChange={(e: any) => this.handleCheckBoxChanges(e)} name="others" />} label="Others" />
                              </FormGroup>
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Address<sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                // defaultValue={attributes?.academic?.data?.attributes?.account?.address}
                                value={this.state.userInputValues.address}
                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="Address Details"
                                name="address"
                                style={this.state.errAddress ? this.errStyle : {}}
                              />
                              {this.state.errAddress ? (
                                <p className="prl-input-err-txt">Please enter a value.</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">State<sup className="required">*</sup></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"

                                  value={this.state.userInputValues.state}
                                  onChange={(e: any) => {
                                    this.handleStateChange(e);
                                  }}
                                  placeholder="Post Graduate"
                                  name="state"
                                  style={this.state.errState ? this.errStyle : {}}
                                >
                                  <option value={this.state.userInputValues.state}>
                                    {this.state.userInputValues.state} </option>
                                  {this.state.listOftheStates.map((val: any, key: any) => {
                                    return (
                                      <option value={key} key={key}>
                                        {val.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                                {this.state.errState ? (
                                  <p className="prl-input-err-txt">Please select a value.</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>


                          <div className="TSU-input-file-c">
                            <input
                              type="file"
                              id="cvUpload"
                              accept=".doc,.docx,.pdf"
                              className="TSU-input-file"
                              value=""
                              // required
                              onChange={(e: any) => {
                                this.handleUploadedCvFile(e);
                              }}
                            // placeholder="Enter Languages"
                            />
                            <label
                              htmlFor="cvUpload"
                              className="TSU-input-file-img-c"
                              style={{
                                marginRight: " 1.2em",
                                marginBottom: "1.5em",
                                marginTop: " 1em",
                              }}
                            >
                              <img src={fileUploadIcon} alt="file" />
                              &nbsp;{this.state.cvUploaded ? <label>{this.state.cvUploaded.name}</label> : <label>{attributes?.academic?.data?.attributes?.cv_filename}</label>}
                            </label>
                          </div>


                          <button type="submit" onClick={this.handleTeacherUpdateData} className={classes.teacherFormUpdateButton}>Update</button>
                        </Grid>

                        <Grid item md={6}>
                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Last Name<sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                value={this.state.userInputValues.lastName}
                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="Enter Last Name"
                                name="lastName"
                                style={this.state.errLastName ? this.errStyle : {}}
                              />
                              {this.state.errLastName ? (
                                <p className="prl-input-err-txt">Please enter a value. </p>
                              ) : (
                                <></>
                              )}
                              {this.state.errorStatusLastName ? (
                                <p className="prl-input-err-txt">Maximum 15 characters</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Mobile no<sup className="required">*</sup>
                              </label>
                              <div className="prl-input-phone">
                                <div className="prl-input-dd-phone">
                                  <select
                                    className={classes.teachermobileNumberCode}
                                    value={this.state.userInputValues.countryCode}
                                    onChange={(e: any) => {
                                      this.handleInputChange(e)
                                    }}
                                    name="countryCode"
                                    style={{ width: "100%" }}
                                  >
                                    <option value="91">+91</option>
                                    <option value="81">+81</option>
                                    <option value="71">+71</option>
                                  </select>
                                </div>
                                <input
                                  className="prl-input-i-phone"
                                  type="text"
                                  // pattern="[0-9]*"
                                  required
                                  value={this.state.userInputValues.mobileNo}
                                  onChange={(e: any) => {
                                    this.handleInputChange(e)
                                  }}
                                  placeholder="0000000"
                                  name="mobileNo"
                                  style={this.state.errPhone ? this.errStyle : {}}
                                />
                              </div>
                              {this.state.errPhone ? (
                                <p className="prl-input-err-txt">Please enter a value.</p>
                              ) : (
                                <></>
                              )}
                              {this.state.errMaxPhone ? (
                                <p className="prl-input-err-txt">Maximum 15 characters</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">Currently Working<sup className="required">*</sup></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"
                                  value={this.state.userInputValues.currentlyWorking}
                                  onChange={(e: any) => {
                                    this.handleInputChange(e)
                                  }}
                                  name="currentlyWorking"
                                >
                                  <option value={"Yes"}>Yes</option>
                                  <option value={"No"}>No</option>
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                College Name <sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                // defaultValue={attributes?.academic?.data?.attributes.college_name}
                                value={this.state.userInputValues.collegeName}
                                required
                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="College Name"
                                name="collegeName"
                              />
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable"> Course of Interests<sup className="required">*</sup></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"
                                  value={this.state.userInputValues.coursesOfInterests}
                                  onChange={(e: any) => {
                                    this.handleInputChange(e)
                                  }}
                                  placeholder="Statistics, Economics, Maths"
                                  name="coursesOfInterests"
                                >
                                  <option value={this.state.userInputValues.coursesOfInterests}>{this.state.userInputValues.coursesOfInterests}</option>
                                  {this.state.cousesInterestListData.map((eachCourseInterest: any, index_val: any) => {
                                    if (eachCourseInterest.name == attributes?.academic?.data?.attributes?.interest?.name) {
                                      return
                                    }
                                    return (
                                      <option value={eachCourseInterest.name} key={index_val}>
                                        {eachCourseInterest.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                              </div>
                            </div>
                          </div>



                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Enter Other Languages<sup className="required">*</sup>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                // defaultValue={attributes?.academic?.data?.attributes?.other_languages}
                                style={this.state.userInputValues.languagesKnown.others ? {} : { backgroundColor: "#EEEEEE" }}
                                value={this.state.userInputValues.otherLanguages}
                                required
                                onChange={(e: any) => {
                                  this.handleInputChange(e)
                                }}
                                placeholder="French, Spanish"
                                name="otherLanguages"
                                disabled={this.state.userInputValues.languagesKnown.others ? false : true}
                              />
                            </div>
                          </div>

                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">Country<sup className="required">*</sup></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"
                                  value={this.state.userInputValues.country}
                                  onChange={(e: any) => {
                                    this.handleCountryChange(e)
                                  }}
                                  style={this.state.errCountry ? this.errStyle : {}}
                                  placeholder="Select Country"
                                  name="country"
                                >
                                  <option value={this.state.userInputValues.country}>
                                    {this.state.userInputValues.country}
                                  </option>
                                  {this.state.countriesList.map((val: any, key: any) => {
                                    return (
                                      <option value={key} key={key} data-key={val.isoCode} a-key={val.isoCode}>
                                        {val.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                                {this.state.errCountry ? (
                                  <p className="prl-input-err-txt">Please select a value.</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">City<sup className="required">*</sup></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"
                                  defaultValue={this.state.userInputValues.city}
                                  onChange={(e) => {
                                    this.handleCityChange(e);
                                  }}
                                  style={this.state.errCity ? this.errStyle : {}}
                                  placeholder="Select City"
                                  name="city"
                                >
                                  <option value={this.state.userInputValues.city}>
                                    {this.state.userInputValues.city}
                                  </option>
                                  {this.state.citiesList.map((val: any, key: any) => {
                                    return (
                                      <option value={val.name} key={key}>
                                        {val.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                                {this.state.errCity ? (
                                  <p className="prl-input-err-txt">Please select a value.</p>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="TSU-input-file-c" style={{ marginTop: "" }}>
                            <input
                              type="file"
                              id="govtIdUpload"
                              accept=".doc,.docx,.pdf"
                              className="TSU-input-file"
                              value=""
                              // required
                              onChange={(e: any) => {
                                this.handleUploadedGovtFile(e);
                              }}

                            // placeholder="Enter Languages"
                            />
                            <label
                              htmlFor="govtIdUpload"
                              className="TSU-input-file-img-c"
                              style={{
                                marginLeft: " 1.3em",
                                marginBottom: "1em",
                                marginTop: " 1em",
                              }}
                            >
                              <img src={fileUploadIcon} alt="file" />
                              &nbsp;{this.state.govtIdUploaded ? <label>{this.state.govtIdUploaded.name}</label> : <label>{attributes?.academic?.data?.attributes?.government_id_filename}</label>}
                            </label>
                          </div>

                        </Grid>
                      </Grid>
                    </form>


                  </div>

                </div>
              </>}

          </div>



        </div>

        {/* modal fro update */}
        <Modal
          open={this.state.updateProfilePicModalStatus}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.teacherupdatepopup}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={classes.TeacherProfileUploadText}>Profile Upload</div>
              <div className={classes.TeacherProfileUploadclose} onClick={() => this.handleTeacherUploadProfilePicture(false)}><img src={crossImage} alt="crossImage" style={{ height: "0.7em" }} /></div>
            </div>
            <hr />
            <div style={{ marginTop: "3em", marginBottom: "3em" }}>
              <div style={{ display: 'flex', width: "100%" }}>
                <div className={classes.buttonUpload} >
                  <label
                    htmlFor="fileupload"
                    style={{ width: "100%", height: "198px" }}
                  >
                    <input
                      type="file"
                      id="fileupload"
                      accept=".jpeg,.png"
                      className="TSU-input-file"
                      value=""
                      required
                      ref={this.inputRef}
                      onChange={(e) => {
                        this.handleTeacherUploadProfilePictureUpdate(e);
                      }}
                    /></label>
                  <img src={UploadPhotoRed} alt="upload" style={{ width: 50, height: 43, position: "absolute" }}   onClick={this.handleImageClick}/>
                </div>

                <div className={classes.buttonTakePhoto} onClick={() => this.handleteacherTakePhotoModal(true)}><img src={takePhotoRed} alt="upload2" style={{ width: 50, height: 43 }} /></div>
              </div>

            </div>
          </div>
        </Modal>

        {/* modal for take photo */}
        <Modal
          open={this.state.takePhotoModalStatus}
          onClose={() => this.handleteacherTakePhotoModal(false)}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.teacherUpdatePopUpCamera}>
            <div>
              <WebcamCapture handletakePhotoCapture={this.handleteacherTakePhotoCapture} />
            </div>
          </div>
        </Modal>

        {/* Modal for priview */}
        <Modal
          open={this.state.previewConfirmModalStatus}
          // open={true}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.teacherUpdatePreviewpopup}>
            <div>
              <div className={classes.TeacherProfileUploadText}>Profile Upload</div>
            </div>
            <hr />
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              {this.state.uploadPicFile ? <img className={classes.teacherPreviewImage} alt="teacherImagepreview" src={this.state.previewUploadFile} />
                : <img id="my-image" className={classes.teacherPreviewImage} alt="teacherImagePreview" src={this.state.teacherImage} />}
            </div>

            <div style={{ display: 'flex', justifyContent: "center" }}>
              <Button className={classes.teacherUpdateCancelButton} variant="contained" onClick={() => this.handleTeacherUploadProfileConfirmModal(false, true)}>Cancel</Button>
              <Button className={classes.teacherUpdatePreviewButton} variant="contained" onClick={() => this.handleTeacherUploadProfilePictureConfirmUpdate()}>Upload</Button>
            </div>


          </div>
        </Modal>

        {/* sucess modal? */}
        <Modal
          // open={this.state.open}
          open={this.state.opensucessMessageModal}
          style={{
            position: "absolute",
            height: 545,
            width: 700,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.teacherSuccessPopUp}>
            <div className={classes.teacherSuccessPopUpInner}>
              <div>
              <p className={classes.updatedMessage}>Profile Updated Sucessfully</p>
              </div>
              <div>
                <Button
                  onClick={() => this.handleteacherSucessMessageModal(false)}
                  className={classes.okayButton}
                  variant="outlined"
                  style={{  borderRadius: "100px",
                  backgroundColor: "#ffffff",
                   padding:"0.5em 5em",
                  border: "1px solid red",
                  textTransform: "none",
                  marginTop:"1em"
                }}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>

        </Modal>



        <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(TeachersProfile);