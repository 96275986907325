Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ElasticSearch";
exports.labelBodyText = "ElasticSearch Body";

exports.btnExampleTitle = "CLICK ME";
exports.userDetailsApiEndpoint = "profile_details";
exports.SearchApiEndpoint = "search_course";

// Customizable Area End