export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const hireteacher = require("../assets/hireteacher.png");
export const calender = require("../assets/calender.png");
export const hours = require("../assets/hours.png");
export const twomembers = require("../assets/twomembers.png");
export const dataScience = require("../assets/dataScience.png");
export const backArrow = require("../assets/backArrow.png");
export const profile = require("../assets/profile.png");
export const profile2 = require("../assets/profile2.png");
export const profile3 = require("../assets/profile3.png")
export const document = require("../assets/document.png")
export const downArrow = require("../assets/downArrow.png")
export const event = require("../assets/event.png")
export const phone = require("../assets/phone.png")
export const menuImg = require("../assets/menuImg.png")
export const addIcon = require("../assets/addIcon.png")
export const sendIcon = require("../assets/sendIcon.png")
export const closeIcon = require("../assets/close_round_light.png");
export const infoIcon = require("../assets/info_alt_light.png");
export const micOff = require("../assets/mic_off.png");
export const micOn = require("../assets/mic_on.png");
export const mutedIcon = require("../assets/muted.png");
export const voiceIcon = require("../assets/voice_open.png");
export const removeUserIcon = require("../assets/remove_user.png");
export const copyIcon = require("../assets/copyIcon.svg");
export const deleteIcon = require("../assets/delete.png");
export const editIcon = require("../assets/edit.png");
export const attachmentIcon = require("../assets/attachment.png");
