import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  searchBy: string;
  query: string;
  coursesList: any;
  is_loggedIn: boolean;
  is_search_loaded: boolean;
  ESfetchCourseListLoading: boolean;
  totalPage: number;
  currentPage: number;
  userDetails: any;
  totalCourses: number;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class SearchController extends BlockComponent<Props, S, SS> {
  apiUserDetailsCallId: any;
  apiSearchCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      searchBy: "",
      query: "",
      coursesList: [],
      is_loggedIn: false,
      is_search_loaded: false,
      ESfetchCourseListLoading: true,
      totalPage: 1,
      currentPage: 1,
      userDetails: [],
      totalCourses: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Receive Fun() //////////////////////////////////////////////////////////
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiSearchCallId) {
          if (!responseJson.errors && !responseJson.error && !responseJson.message) {
            this.setState({ is_search_loaded: true })
            if (responseJson.data) {
              this.setState({ ESfetchCourseListLoading: false })
              console.log("CoursesList_Response", responseJson);
              localStorage.setItem("courses", JSON.stringify(responseJson.data));
              await this.setState({ coursesList: responseJson.data });
              await this.setState({ currentPage: responseJson.page });
              await this.setState({ totalPage: responseJson.page_count });
              await this.setState({ totalCourses: responseJson.total_course_count });
              // await this.setState({ lQualification: responseJson });
              console.log("coursesList State", this.state.coursesList);
            } else if (responseJson.message) {
              this.setState({ ESfetchCourseListLoading: false })
              console.log("CoursesList_no_Course_available_Response", responseJson);
              localStorage.setItem("courses", JSON.stringify([]));
              await this.setState({ coursesList: [] });
              await this.setState({ currentPage: 1 });
              await this.setState({ totalPage: 1 });
            } else {
              this.parseApiErrorResponse(responseJson);
              console.log("No_Response", responseJson);
            }
          } else {
            this.setState({ is_search_loaded: true })
            if (responseJson.message) {
              console.log("CoursesList_no_Course_available_Response", responseJson);
              localStorage.setItem("courses", JSON.stringify([]));
              await this.setState({ coursesList: [] });
              await this.setState({ currentPage: 1 });
              await this.setState({ totalPage: 1 });
            }
            console.log("ERR_CoursesList_Response", responseJson);
            // alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiUserDetailsCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("UserDetails_Response", responseJson);
            await this.setState({ is_loggedIn: true });
            await this.setState({ userDetails: responseJson?.data?.attributes });
            console.log("is_loggedIn", this.state.is_loggedIn);
          } else {
            console.log("ERR_UserDetails_Response", responseJson);
            // alert(JSON.stringify(responseJson.errors[0][0]));
            await this.setState({ is_loggedIn: false });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////

  navigateToCoursesDetails = (id: any) => {
    localStorage.setItem("currentCourseId", id);
    if (this.state.is_loggedIn) {
      this.props.navigation.navigate("CourseDetails", { courseId: id });
    } else {
      this.props.navigation.navigate("CoursesDetails", { courseId: id });
    }
  };

  getUserDetails = async () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserDetailsCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailsApiEndpoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  //////////////////////////////////////////// Search Api call //////////////////////////////////////////////

  getSearchedCourses = async (searchBy: string, query: string, page: number) => {
    this.setState({ ESfetchCourseListLoading: false })
    await this.setState({ is_search_loaded: false })
    let search_by = "";
    let token = localStorage.getItem("loginT");
    if (token === null) {
      token = "";
    }
    switch (searchBy) {
      case "Course":
        search_by = "search_course";
        break;
      case "Instructor":
        search_by = "search_instructor";
        break;
      case "Category":
        search_by = "search_category";
        break;
      case "Subcategory":
        search_by = "search_subcategory";
        break;
      default:
        search_by = "search_course";
        break;
    }

    const endPoint = `${configJSON.SearchApiEndpoint}?${search_by}=${query}&per_page=${8}&page=${page}`;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiSearchCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  async componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.getUserDetails();
    const parmsSearchBy = this.props.navigation.getParam("searchBy");
    const parmsQuery = this.props.navigation.getParam("query");
    // const searchBy = this.props.match?.params['searchBy']
    await this.setState({
      searchBy: parmsSearchBy,
      query: parmsQuery,
    });

    console.log("Search Load");
    console.log("searchBy", parmsSearchBy);
    console.log("query", parmsQuery);
    console.log("state params", this.state.searchBy, this.state.query);

    if (
      this.state.searchBy != null &&
      this.state.query != null &&
      this.state.searchBy.length > 0 &&
      this.state.query.length > 0
    ) {
      this.getSearchedCourses(this.state.searchBy, this.state.query, 1);
    }
  }
}
