import React from "react";

// Customizable Area Start
import moment from "moment";
import Button from "@material-ui/core/Button";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    sDashboard_container: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    dash_left_c: {
      flex: 2,
    },
    sDashboard_cc: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },

    Update: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "10em",
      color: "#fff",
      marginTop: "2em",
      textTransform: "none",

      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    backgroundupdate: {
      width: "100%",
      top: "210px",
      backgroundColor: "#F7F7F7",
      mixblendmode: "normal",
      borderRadius: "20px",
      marginTop: "1.5em",
      padding: "2em 2em 2em 2em",
    },
    UpdateInfo: {
      position: "absolute",
      width: "800px",
      height: "auto",
      left: "434px",
      top: "493px",
      background: "#F7F7F7",
      borderRadius: "20px",
      padding: 30,
    },
    updateprofilebutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      color: "#fff",
      marginTop: "20px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    tableContainerStyle: {
      maxHeight: 310,
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "auto",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
        marginTop: "4vw",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    tableHeadStyle: {
      "&.MuiTableCell-stickyHeader": {
        backgroundColor: "#14292d",
        backgroundBlendMode: "normal",
      },
    },
    noHistory: {
      width: "200%",
      textAlign: "center",
      padding: "30px",
    },
    PayNowpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "29vw",
      height: "16vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px",
      },
    },
    settingsUpdatedMessage: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      marginBottom: "1em",
    },
    toggleSwitch: {
      "&.MuiSwitch-colorSecondary.Mui-checked": {
        color: "#ff0000",
      },
    },
  });
// Customizable Area End

import SettingsController, { Props } from "./SettingsController.web";

import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { paymentDetails } = this.state;
    return (
      // Customizable Area Start'
      <>
        <div className={classes.root}>
          <TopNavigation
            navigation={this.props.navigation}
            history={this.props.history}
            location={this.props.location}
          />
          {/* Banner */}
          <div className={classes.sDashboard_container}>
            <div className={classes.dash_left_c}>
              <DashNav
                active="Settings"
                styles={{ marginBottom: "1.5vw" }}
                navigation={this.props.navigation}
              />
              <NextClassNotification
                timings="10:00 AM - 11:00 PM"
                title="Introduction to foundation of digital marketing and e-commerce"
                subtitle=" Lorem Ipsum is simply dummy text of the printing"
                onClick={() => {
                  console.log("Next Class");
                }}
                styles={{ marginBottom: "1.5vw" }}
              />
              <DashAdvartiseCard
                title="One on One classes with Teachers"
                subtitle="Lorem Ipsum is simply dummy text of the printing"
                styles={{ marginBottom: "1.5vw" }}
                onClick={() => {}}
              />
            </div>
            <div className={classes.sDashboard_cc}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "25px", marginLeft: "20px" }}>
                  {/* border: "1px solid black", */}
                  <div>
                    <Typography style={{ fontSize: 26, fontWeight: 600 }}>
                      Settings
                    </Typography>
                  </div>
                  <hr
                    style={{ color: "#ECECF1", border: "1px solid #ECECF1" }}
                  ></hr>
                  <Typography
                    style={{ fontSize: 24, fontWeight: 600, marginTop: 25 }}
                  >
                    Update Settings
                  </Typography>

                  <div className={classes.backgroundupdate}>
                    <Typography
                      style={{
                        color: "#333333",
                        fontSize: 16,
                        fontWeight: 550,
                      }}
                    >
                      Notifications
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <Switch
                        checked={this.state.emailCheckBox}
                        onChange={() => this.handleEmailCheckBox()}
                        name="emailCheckBox"
                        // inputProps={{ 'aria-label': 'secondary checkbox' }}
                        className={classes.toggleSwitch}
                        // variant="contained"
                      />
                      <Typography style={{ margin: 6 }}>Email</Typography>
                      <Switch
                        checked={this.state.phoneCheckBox}
                        onChange={() => this.handlePhoneCheckBox()}
                        name="phoneCheckBox"
                        className={classes.toggleSwitch}
                        // variant="contained"
                      />
                      <Typography style={{ margin: 6 }}>Phone</Typography>
                    </div>
                    <div>
                      <Button
                        className={classes.Update}
                        variant="contained"
                        onClick={() => this.handleUpdate()}
                        disabled={!this.state.notificationChange}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>

                {/* <div> */}

                <div style={{ marginLeft: "20px" }}>
                  <div>
                    <Typography
                      style={{
                        marginTop: 25,
                        fontSize: 22,
                        fontWeight: 550,
                        marginBottom: 20,
                      }}
                    >
                      Billing History
                    </Typography>
                  </div>
                  <div>
                    <TableContainer
                      className={classes.tableContainerStyle}
                      component={Paper}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow style={{ color: "white" }}>
                            <TableCell
                              className={classes.tableHeadStyle}
                              align="center"
                              style={{ color: "white", fontSize: 18 }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadStyle}
                              align="center"
                              style={{ color: "white", fontSize: 18 }}
                            >
                              Details
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadStyle}
                              align="center"
                              style={{ color: "white", fontSize: 18 }}
                            >
                              Amount
                            </TableCell>
                            <TableCell
                              className={classes.tableHeadStyle}
                              align="center"
                              style={{ color: "white", fontSize: 18 }}
                            >
                              Order ID
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {this.state.paymentStatusLoader && (
                          <div
                            className={classes.noHistory}
                            style={{ marginLeft: "2em" }}
                          >
                            <CircularProgress />
                          </div>
                        )}
                        {!this.state.paymentStatusLoader && paymentDetails.length <= 0 ? (
                          <div className={classes.noHistory}>
                            <span>No Payment History</span>
                          </div>
                        ) : (
                          <TableBody>
                            {paymentDetails.map((eachObject: any) => {
                              return (
                                <TableRow key={eachObject?.id}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    {moment(
                                      `${eachObject?.attributes.updated_at}`
                                    ).format("DD-MM-YYYY")}
                                  </TableCell>
                                  <TableCell align="center">
                                    {
                                      eachObject?.attributes?.course?.data
                                        ?.attributes?.name
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    Rs.
                                    {
                                      eachObject?.attributes?.course?.data
                                        ?.attributes?.course_cost
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    {eachObject?.attributes?.razorpay_order_id}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>

              <Modal
                // open={this.state.open}
                open={this.state.opensucessMessageModal}
                style={{
                  position: "absolute",
                  height: 545,
                  width: 700,
                  margin: "auto",
                  borderRadius: "20px",
                }}
              >
                <div className={classes.PayNowpopup}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      marginTop: "1em",
                    }}
                  >
                    <div>
                      <p className={classes.settingsUpdatedMessage}>
                        {this.state.sucessMessage}
                      </p>
                    </div>
                    <div>
                      <Button
                        onClick={() => this.handlesucessMessageModal(false)}
                        style={{
                          borderRadius: "100px",
                          backgroundColor: "#ffffff",
                          width: "14vw",
                          height: "3vw",
                          border: "1px solid red",
                          textTransform: "none",
                          // marginTop: "10px",
                        }}
                        variant="outlined"
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <BottomNavigation
            navigation={this.props.navigation}
            history={this.props.history}
            location={this.props.location}
          />
        </div>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
export default withStyles(styles, { withTheme: true })(Settings);
