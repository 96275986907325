import React, { useState } from "react";
// import { defaultProfilePic } from "../assets";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { search, searchRed } from "../assets";
import { Link } from "react-router-dom";
import { checkedTeach, unCheckedTeach } from "../../../AssessmentTest/src/assets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ButtonContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "0.7em",
    },
    WelcomeTxt: {
      color: "white",
      fontSize: "0.85vw",
      marginRight: "0.8vw",
      fontWeight: "normal",
      fontFamily: "Inter, sans-serif",
      textTransform: "none",
    },
    profileImg: {
      width: "3em",
      borderRadius: "10em",
    },
    profileArrowIcon: {
      width: 0,
      height: 0,
      borderLeft: "7px solid transparent",
      borderRight: "7px solid transparent",
      borderTop: "7px solid #f0d27c",
      marginLeft: "0.5em",
    },
    DropDownBtns: {
      width: "100%",
    },
    popoverStyles: {},
    popoverC: {
      padding: "1em",
      width: "25em",
    },
    searchByLabel: {
      padding: "0.5em 0em 0em 0em",
    },
    Checkbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#ff0000",
      },
    },
    CA_checkbox_Img: {
      width: "0.65em",
    },
  })
);
export default function TopNavigationSearchComp({ searchNav, navigation }: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [searchBy, setSearchBy] = useState("Course");
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = React.useState({
    Course: true,
    Instructor: false,
    Category: false,
    Subcategory: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBy(event.target.name);
    setState({
      Course: false,
      Instructor: false,
      Category: false,
      Subcategory: false,
      [event.target.name]: true,
    });
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        aria-describedby={id}
        variant="text"
        className={classes.ButtonContainer}
        aria-controls="simple-menu"
        aria-haspopup="true"
        //   className="dropdown"
        data-testid="TN_Search_PopoverBtn"
      >
        <img src={search} alt="Logo" style={{ width: "1.4vw" }} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // container={anchorEl.parentNode}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popoverStyles}
      // disableScrollLock
      >
        <div className={classes.popoverC}>
          <div style={{ display: "flex" }}>
            <TextField
              className={classes.DropDownBtns}
              id="outlined-basic"
              variant="outlined"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value);
              }}
              data-testid="TN_Search_Popover_inputTxt"

            />
            <Button
              onClick={() => {
                if (searchQuery.length > 0) {
                  searchNav(searchBy, searchQuery);
                }
              }}
              data-testid="TN_Search_Popover_searchBtn"

            >
              <img src={searchRed} alt="Logo" style={{ width: "1.4vw" }} />
            </Button>
            {/* {searchQuery.length > 0 &&
                            <Link to={`/Search?searchBy=${searchBy}&query=${searchQuery}`} >link</Link>
                        } */}
          </div>

          <Typography className={classes.searchByLabel}>Search By:</Typography>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.Checkbox}
                checked={state.Course}
                onChange={handleChange}
                name="Course"
                icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
                data-testid="TN_Search_popover_check1"
              />
            }
            label="Course"
          />
          <FormControlLabel
            control={
              <Checkbox
                className={classes.Checkbox}
                checked={state.Instructor}
                onChange={handleChange}
                name="Instructor"
                icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
                data-testid="TN_Search_popover_check2"
              />
            }
            label="Instructor"
          />
          <FormControlLabel
            control={
              <Checkbox
                className={classes.Checkbox}
                checked={state.Category}
                onChange={handleChange}
                name="Category"
                icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
                data-testid="TN_Search_popover_check3"
              />
            }
            label="Category"
          />
          <FormControlLabel
            control={
              <Checkbox
                className={classes.Checkbox}
                checked={state.Subcategory}
                onChange={handleChange}
                name="Subcategory"
                icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
                data-testid="TN_Search_popover_check4"
              />
            }
            label="Subcategory"
          />
          <Button
            style={{ textTransform: "none" }}
            onClick={() => { navigation.navigate('CoursesList') }}
            variant='text'
            data-testid='TN_Search_Popover_clearBtn'
          >Clear</Button>
        </div>
      </Popover>
    </div>
  );
}

