import React, { Component } from "react";
import { success } from "./assets";
import Banner from "./Components/Banner.web";
import BottomNavigation from "./BottomNavigation.web";
import PopularCourses from "./PopularCourses.web";
import RegSuccessController, { Props } from "./RegSuccessController.web";
import TopNavigation from "./TopNavigation.web";

export default class RegSuccess extends RegSuccessController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="reg-su-container">
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className="reg-su-container-c">
          <div className="reg-su-img-c">
            <img src={success} alt="success" />
          </div>
          <div className="reg-su-title-c">
            <p className="reg-su-title">Welcome!, Your have successfully registered with us</p>
            <p className="reg-su-sub-title">we have sent you an email with your login credentials</p>
          </div>
          <hr className="reg-su-hor-line" />
          <div className="reg-su-sub-t">
            <p>Please click the button below to login with the credentials emailed to you</p>
          </div>
          <div className="reg-su-btn-c">
            <button
              className="reg-su-login-btn"
              onClick={this.handleLogInNavigate}
              data-testId="TSS2_handleLogInNavigate"
            >
              Login
            </button>
          </div>
        </div>
        <PopularCourses
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
          classes={undefined}
        />
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
