import React from "react";

// Customizable Area Start
import { InProgress ,CrossImg,tickmark} from "./assets";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";


import {withStyles, Theme } from "@material-ui/core/styles";

export const styles:any = (theme: Theme) =>
  ({
    root: {
      // marginTop:"3%",
      // marginBottom:"3%"
    },
    viewAppliedJobsMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    viewAppliedJobsleftsec: {
      flex: 2,
    },
    viewAppliedJobsRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    }, 
    viewAppliedJobsTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },
    applicationStatus:{
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "1.4em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginBottom:"1em"

    },
    applicationStatusValueAccepted:{
      color: "#6F924A",
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginLeft:"0.3em"

    },
    applicationStatusValueRejected:{
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginLeft:"0.3em"

    },
    
    applicationStatusValueUnderReview:{
      color: "#5e5858",
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginLeft:"0.3em"
    },
    sendAmessage:{
     color: "#FFFFFF",
     fontFamily: "Inter, sans-serif",
     backgroundColor: "#FF0000",
     borderRadius: "50px",
     padding:"1em 2em",
     fontSize: "1em",
     textTransform: "none",
     fontWeight: 700,
    },
    contractInfo:{
      color: "#333333",
      fontSize: "1.5em",
      marginBottom:"1em",
      fontWeight: 600,

    },   

    processIcon:{
    height: "60px",
    width: "60px",
    justifyContent: "center",
    marginBottom:"1em"
    },
     contractUnderProcess:{
      color: "#f00",
      fontSize: "20px",
      justifyContent: "center",
      marginBottom:"1em" 
     },

    appliedJobLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
    View_applied_container:{
      marginTop: "3%",
      marginBottom: "3%"
    },
    View_applied_container2: {
      background: "#f7f7f7",
      padding: "40px",
      borderRadius: "20px",
      justifyContent: "center",
      marginBottom:"2em"
    },
    View_applied_grid: {
      display: "flex",flexDirection: "column",marginTop: "10px",alignItems: "center",
    }

    
  });

import { Divider } from "react-native-elements";

import ViewAppliedJobController, { Props } from "./ViewAppliedJobController.web";
import AppliedJobTopHeaderWeb from "./AppliedJobTopHeader.web";
import CircularProgress from '@material-ui/core/CircularProgress';
export class ViewAppliedJobs extends ViewAppliedJobController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    console.log(this.state?.viewAppliedJobDetails?.attributes?.status,"########################")

    const renderContractInfo=()=>{
      if(this.state?.viewAppliedJobDetails?.attributes?.contract_status === "Under Review"){
       return(
        <div className={classes.View_applied_container2}>
                            <Grid container className={classes.View_applied_grid}>
                              <img
                                alt=""
                                src={InProgress}
                               className={classes.processIcon}
                              />
                              <h5 className={classes.contractUnderProcess}>
                                Contract Under Process
                              </h5>
                              <h6 style={{ justifyContent: "center", fontSize: "15px" }}>
                                Your Contract info is under process currently and will be available once it is
                                submitted by the Job Poster
                              </h6>
                            </Grid>
                        </div>
       )
      }
      else if( this.state?.viewAppliedJobDetails?.attributes?.contract_status?.data[0]?.attributes?.status === "Pending"){
      return(
        <>
        <div className={classes.View_applied_container2}>
          <Grid container className={classes.View_applied_grid}>
            <img
              alt=""
              src={InProgress}
             className={classes.processIcon}
            />
            <h5 className={classes.contractUnderProcess}>
              Contract Under Process
            </h5>
            <h6 style={{ justifyContent: "center", fontSize: "15px",marginBottom:"2em" }}>
            Contract submitted by the Job Poster. Click on the view Contract below.
            </h6>
            <div style={{ justifyContent: "center", display: "flex" }}>
                  <Button
                    disableElevation
                    variant="contained"
                    style={{
                      marginLeft: "10px",
                      fontFamily: "Inter, sans-serif",
                      backgroundColor: "rgb(240, 210, 124)",
                      borderRadius: "50px",
                      width: "12vw",
                      height: "2.8vw",
                      fontSize: "0.9vw",
                      textTransform: "none",
                      fontWeight: 700,
                    }}
                    onClick={()=>{this.props.navigation.navigate("ViewContract")}}

                  >
                    View Contract
                  </Button>
            </div>
          </Grid>
       </div>
       </>
      )
      }
      else if(this.state?.viewAppliedJobDetails?.attributes?.contract_status?.data[0]?.attributes?.status === "accepted"){
        return( <>
          <div
            style={{
              background: "#f7f7f7",
              padding: "40px",
              borderRadius: "20px",
              justifyContent: "center",
            }}
            >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <img
                alt=""
                src={tickmark}
                style={{ height: "60px", width: "60px"}}
              />
              <h5 style={{ color: "#f00", fontSize: "20px" }}>
                Contract Accepted
              </h5>
              <h6 style={{margin:"1em 0em" }}>Congrats and Welcome Onboard!</h6>
              <h6 style={{margin:"0em 0em 1em" }}>
                You have accepted the contract details and has been onboarded on Techfin Platform
              </h6>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  onClick={()=>{this.props.navigation.navigate("AppliedJobs")}}
                  disableElevation
                  variant="contained"
                  style={{color: "#FFFFFF",fontFamily: "Inter, sans-serif",backgroundColor: "#FF0000",borderRadius: "50px",width: "12vw",height: "2.8vw",fontSize: "0.9vw",textTransform: "none",fontWeight: 700,}}
                >
                  Back to Applied Jobs
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  style={{
                    marginLeft: "10px",
                    fontFamily: "Inter, sans-serif",
                    backgroundColor: "rgb(240, 210, 124)",
                    borderRadius: "50px",
                    width: "12vw",
                    height: "2.8vw",
                    fontSize: "0.9vw",
                    textTransform: "none",
                    fontWeight: 700,
                  }}
                  onClick={()=>{this.props.navigation.navigate("ViewContract")}}

                >
                  View Contract
                </Button>
              </div>
            </Grid>

          </div>
                      </>)
      }
      else{
        return( <>
          <div
          style={{
            background: "#f7f7f7",
            padding: "40px",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              src={CrossImg}
              style={{ height: "60px", width: "60px" }}
            />
            <h5 style={{ color: "#f00", fontSize: "20px"}}>Contract Rejected</h5>
            <h6 style={{margin:"1em 0em" }}>We wish you better lucknext time</h6>
            <h6 style={{margin:"0em 0em 1em" }}>You seem to have not interested with the terms mentioned in the Contract.</h6>
          
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                onClick={()=>{this.props.navigation.navigate("AppliedJobs")}}
                variant="contained"
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter, sans-serif",
                  backgroundColor: "#FF0000",
                  borderRadius: "50px",
                  width: "12vw",
                  height: "2.8vw",
                  fontSize: "0.9vw",
                  textTransform: "none",
                  fontWeight: 700,
                }}
              >
                Back to Applied Jobs
              </Button>
              <Button
                variant="contained"
                style={{
                  marginLeft: "10px",
                  fontFamily: "Inter, sans-serif",
                  backgroundColor: "rgb(240, 210, 124)",
                  borderRadius: "50px",
                  width: "12vw",
                  height: "2.8vw",
                  fontSize: "0.9vw",
                  textTransform: "none",
                  fontWeight: 700,
                }}
              onClick={()=>{this.props.navigation.navigate("ViewContract")}}
              >
                View Contract
              </Button>
            </div>
          </Grid>

        </div>
      </>)
      }
    }
    return (
      // Customizable Area Start
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.viewAppliedJobsMainSection}>
        <div className={classes.viewAppliedJobsleftsec}>
          <DashSidebar1 active="AppliedJobs" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            Buttontitle="View your Classes"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.viewAppliedJobsRightSec}>
        <div>
              <Typography className={classes.viewAppliedJobsTitle}>Job Details</Typography>
        </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>
            {this.state.isappliedJobLoading ? <div className={classes.appliedJobLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
            <div style={{ marginTop: "1.25em" }}>
             <AppliedJobTopHeaderWeb navigation={this.props.navigation} id={""} jobDetails={this.state?.viewAppliedJobDetails?.attributes?.hire_teacher_request?.data}/>
                      <div style={{marginBottom:"2em"}}>
                        <p className={classes.applicationStatus}>
                          Application: 
                          {
                          this.state?.viewAppliedJobDetails?.attributes?.status==="accepted" && 
                            <span className={classes.applicationStatusValueAccepted}>Accepted</span>}
                           {this.state?.viewAppliedJobDetails?.attributes?.status==="Under Review" &&
                            <span className={classes.applicationStatusValueUnderReview}>Under Review</span>}
                            {this.state?.viewAppliedJobDetails?.attributes?.status==="rejected" &&
                            <span className={classes.applicationStatusValueRejected}>Rejected</span>}
                        </p>
                        {this.state?.viewAppliedJobDetails?.attributes?.status==="accepted" &&
                        <Button className={classes.sendAmessage} disableElevation variant="contained">Send a Message</Button>}
                      </div>
           
                
                       {
                         (this.state?.viewAppliedJobDetails?.attributes?.status!=="rejected" ) &&
                         (this.state?.viewAppliedJobDetails?.attributes?.status!=="Under Review" )&&
                        <div>
                         
                      <div>
                            <p className={classes.contractInfo}>Contract Info</p>
                      </div>
                      {renderContractInfo()}
                        </div>
                        
                      }
                    
            </div>
            }
      
                
      
        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(ViewAppliedJobs);

// Customizable Area End
