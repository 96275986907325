import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  data: any;
  isLoading: boolean;
  courseModules: any;
  courseArray: any;
  chapterData: any;
  is_readed: boolean;
  canGoNext: boolean;
  courseId: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class StartLearningController extends BlockComponent<Props, S, SS> {
  courseDetailsCallId: any;
  chapterDetailsCallId: any;
  readChapterCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      data: [],
      isLoading: true,
      courseModules: [],
      courseArray: [],
      chapterData: [],
      is_readed: false,
      canGoNext: false,
      courseId: null,
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.courseDetailsCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("courseDetails_Response", responseJson);
            this.setState({
              data: responseJson.data,
              isLoading: false,
            });
            await this.setState({
              courseModules: responseJson?.data?.attributes?.course_module,
            });
            console.log("ModuleOfCourse", this.state.courseModules);
          } else {
            console.log("ERR_courseDetails_Response", responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.chapterDetailsCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("chapterDetails_Response", responseJson);
            this.setState({
              chapterData: responseJson.data.attributes,
              isLoading: false,
            });
          } else {
            console.log("ERR_chapterDetails_Response", responseJson);
            if (responseJson.errors[0].token === "Token has Expired") {
              this.props.navigation.navigate("EmailLogin");
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.readChapterCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("readChapter_Response", responseJson);
            this.setState({ canGoNext: true });
          } else {
            await this.setState({ is_readed: false });
            console.log("ERR_readChapter_Response", responseJson);
            if (responseJson.errors) {
              if (responseJson?.errors[0]?.token === "Token has Expired") {
                this.props.navigation.navigate("EmailLogin");
              }
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  //   getCourseDetail

  getCoursesDetails() {
    const token = localStorage.getItem("loginT");
    const parms = this.props.navigation.getParam("courseId");
    const courseId = parms;
    const endPoint = `${configJSON.coursesDetailEndPoint}?id=${courseId}`;
    // console.log("getCoursesDetails")
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };
    const getCourseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseDetailsCallId = getCourseRequestMsg.messageId;
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getCourseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.coursesDetailMethodType
    );

    runEngine.sendMessage(getCourseRequestMsg.id, getCourseRequestMsg);
    // console.log("httpBody", httpBody);
    return true;
  }

  getChapterDetails() {
    const token = localStorage.getItem("loginT");
    const parms = this.props.navigation.getParam("chapterId");
    const chapterId = parms;
    console.log("chapterId", chapterId);
    const endPoint = `${configJSON.chapterApiEndpoint}?chapter_id=${chapterId}`;
    // console.log("getCoursesDetails")
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };
    const getCourseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.chapterDetailsCallId = getCourseRequestMsg.messageId;
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getCourseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.coursesDetailMethodType
    );

    runEngine.sendMessage(getCourseRequestMsg.id, getCourseRequestMsg);
    // console.log("httpBody", httpBody);
    return true;
  }

  readChapter(playback?: string) {
    const token = localStorage.getItem("loginT");
    const endPoint = `${configJSON.readChapterApiEndpoint}?chapter_id=${this.state.chapterData?.id}&playback_from=${playback}&is_readed=${this.state.is_readed}`;
    // console.log("getCoursesDetails")
    const headers = {
      "Content-Type": configJSON.resetpasswordcontentType,
      token: token,
    };
    const getCourseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.readChapterCallId = getCourseRequestMsg.messageId;
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);

    runEngine.sendMessage(getCourseRequestMsg.id, getCourseRequestMsg);
    // console.log("httpBody", httpBody);
    return true;
  }

  setPercentage = async (percentage: number) => {
    console.log("PPPPPPPPPPPPPPercentage", percentage);
    if (percentage >= 100) {
      await this.setState({ is_readed: true });
      console.log("is_readed", this.state.is_readed);
      this.readChapter();
    }
  };

  DownloadChapterFile = () => {
    fetch(this.state.chapterData?.url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `Chapter ${this.state.chapterData?.id}`;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  EndReached = async (duration: any, played: any, compleated: boolean) => {
    console.log("EndReached duration", duration);
    console.log("EndReached played", played);

    // const pad = (value: number) => `0${value}`.slice(-2);

    // const format = (seconds: number) => {
    //   const date = new Date(seconds * 1000);
    //   const hh = date.getUTCHours();
    //   const mm = date.getUTCMinutes();
    //   const ss = pad(date.getUTCSeconds());
    //   if (hh) {
    //     return `${hh}:${pad(mm)}:${ss}`;
    //   }
    //   return `${mm}:${ss}`;
    // };

    // const time = format(duration * played)

    console.log("EndTime played", played);
    await this.setState({ is_readed: compleated });
    console.log("is_readed", this.state.is_readed);

    this.readChapter(played);
  };

  NextChapter = () => {
    const parmsCourseId = this.props.navigation.getParam("courseId");
    console.log("nextChapterEnter");
    if (this.state.is_readed) {
      console.log("nextChapterEnterEnter");
      if (this.state.chapterData.next_chapter_id != null) {
        console.log("nextChapterEnterStartLearning");
        this.props.navigation.navigate("StartLearning", {
          courseId: parmsCourseId,
          chapterId: this.state.chapterData.next_chapter_id,
        });
        window.location.reload();
      } else if (
        this.state.chapterData.next_chapter_id == null &&
        this.state.chapterData.assessment_id != null
      ) {
        console.log("nextChapterEnterAssessment");
        this.props.navigation.navigate("Assessment", { assessmentId: this.state.chapterData.assessment_id });
        window.location.reload();
      } else if (
        this.state.chapterData.next_chapter_id == null &&
        this.state.chapterData.assessment_id == null
      ) {
        this.props.navigation.navigate("CourseDetails", { courseId: parmsCourseId });
      }
    }
  };

  async componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const parmschapterId = this.props.navigation.getParam("chapterId");
    const parmscourseId = this.props.navigation.getParam("courseId");
    await localStorage.setItem("currentCourseId", parmscourseId);
    console.log("parms", parmschapterId);
    this.setState({
      courseId: parmscourseId,
    });
    await this.getCoursesDetails();
    await this.getChapterDetails();
  }
}
