import React from "react";

// Customizable Area Start

import Button from "@material-ui/core/Button";
import {
  hireteacher,
  tickmark,
} from "./assets";
import Grid from "@material-ui/core/Grid";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal"
import Typography from "@material-ui/core/Typography";

export const styles:any = (theme: any) =>(
 {
    root: {
      // marginTop:"3%",
      // marginBottom:"3%"
    },
    payProposedBudgetMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    payProposedBudgetleftsec: {
      flex: 2,
    },
    payProposedBudgetRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },

    PayBudgetpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "40vw",
      height: "25vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px"
      },
    },

    successicon: {
      height: "50px",
      width: "50px",
      justifyContent: "center",
      // marginLeft:"14em", 
      marginTop: "8px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "80px",
      }
    },
    PayBudgetFailurepopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "30vw",
      height: "20vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px"
      },
    },
  })

import PayProposedBudgetController, { Props } from "./PayProposedBudgetController.web";
import { Divider } from "react-native-elements";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import RazorpayCmp from "./Razorpay.web";

export class PayProposedBudget extends PayProposedBudgetController {
   constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
      <div className={classes.root}>
      <RazorpayCmp handleRazorPay={this.handleBudgetPaymentInRazorPay} />
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        {/* Banner */}
        <div className={classes.payProposedBudgetMainSection}>
          <div className={classes.payProposedBudgetleftsec}>
            <DashNav
             styles={{ marginBottom: "1.5vw" }}
              active="HireATeacher"
              navigation={this.props.navigation}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              styles={{ marginBottom: "1.5vw" }}
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              // onClick={() => {
              //   console.log("Next Class");
              // }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              onClick={() => { }}
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
            />
          </div>
          <div className={classes.payProposedBudgetRightSec}>
          <div>
                <p style={{
                    color: "#14292d",
                    margin:" 0px",
                    fontSize:" 1.55vw",
                    marginTop: "1vw",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    marginBottom: "1vw"
                  }}>Hire a Teacher</p>
                <Divider />
              </div>
              <div style={{ display: "flex", marginTop: "35px" }}>
                <img alt="" src={hireteacher} style={{ height: "210px", width: "210px", marginRight: "35px" }}/>
                <p style={{ lineHeight: "30px", marginTop: "25px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facere exercitationem,
                  deserunt illo illu repellendus qui recusandae ipsa nihil iure asperiores, dolores temporibus
                  officia minus corporis dolore ab veritatis.Fusce in dui dui. Sed et mi venenatis, euismod
                  risus et, facilisis magna. Aliquam non malesuada lectus. Aenean nec augue egestas.
                </p>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      background: "#f7f7f7",
                      padding: "40px",
                      borderRadius: "20px",
                      justifyContent: "center",
                      marginTop:"5em"
                    }}
                  >
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt=""
                        src={tickmark}
                        style={{ height: "80px", width: "80px", justifyContent: "center" }}
                      />
                      <h5 style={{ margin: "0.25em 0em" ,color: "#f00", fontSize: "20px", justifyContent: "center" }}>
                        Your requirement is under process
                      </h5>

                      <div style={{margin: "1em 0em", borderBottom: "1px solid #d7d7d7", width: "100%" }} />

                      <h6 style={{ margin: "1em 0em", justifyContent: "center", fontSize: "15px" }}>
                        Please pay the proposed budget amount now to make the requirement active
                      </h6>

                      <div style={{ justifyContent: "center" }}>
                        <Button
                        data-testId="payBudgetButton"
                          onClick={() => {
                              this.handleBudgetPayButton();
                          }}
                          variant="contained"
                          style={{
                            color: "#FFFFFF",
                            fontFamily: "Inter, sans-serif",
                            backgroundColor: "#FF0000",
                            borderRadius: "50px",
                            width: "12vw",
                            height: "2.8vw",
                            fontSize: "0.9vw",
                            textTransform: "none",
                            fontWeight: 700,
                          }}
                        >pay</Button>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
        {/* Sucess modal */}
        <Modal
            open={this.state.openPaymentModal}
            style={{
              position: "absolute",
              height: 545,
              width: 700,
              margin: "auto",
              borderRadius: "20px",
            }}
          >
            <div className={classes.PayBudgetpopup}>
              <h2 style={{ color: "#ff0000" }}>Payment Successful</h2>
              <hr />
              <div style={{ width: "100%", textAlign: "center" }}>
                <img className={classes.successicon} src={tickmark} alt="" />
              </div>
              <br></br>
              <p>
                Your payment of Budget Rs.{this.state.verifyCallData?.attributes?.hire_teacher_request?.data?.attributes?.proposed_budget} has been successfully processed.
              </p>
              <Typography style={{ fontWeight: 600 }}>
                Your Order ID: {this.state.verifyCallData?.attributes?.razorpay_order_id}
              </Typography>
              <Typography style={{ fontWeight: 500, marginTop: "1vw" }}>
                We have send you an email regarding your order information to your registered email address
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2vw",
                  marginBottom: "2vw",
                }}
              >
                <div>
                  <Button
                    // onClick={this.handleClose}
                    onClick ={this.handleClickOnContinue}
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ffffff",
                      width: "14vw",
                      height: "3vw",
                      border: "1px solid red",
                      textTransform: "none",
                      // marginTop: "10px",
                    }}
                    variant="outlined"
                  >
                    <b>Continue</b>
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
           {/* paymentFailureModal */}
           <Modal
            open={this.state.paymentFailureModal}
            // open={true}
            style={{
              position: "absolute",
              height: 545,
              width: 700,
              margin: "auto",
              borderRadius: "20px",
            }}
          >
            <div className={classes.PayBudgetFailurepopup}>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2vw",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <h2 style={{ color: "#ff0000" }}>Payment Failed</h2>
                <h3>Please Pay  Budget to make  requirement active</h3>
                <Button
                  onClick={() => this.paymentBudgetFailure(false)}
                  data-testId="paymentBudgetFailure"
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#ffffff",
                    width: "14vw",
                    height: "3vw",
                    border: "1px solid red",
                    textTransform: "none",
                    // marginTop: "10px",
                  }}
                  variant="outlined"
                >
                  <b>Ok</b>
                </Button>

              </div>
            </div>
          </Modal>
    </>



      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(PayProposedBudget);

// Customizable Area End
