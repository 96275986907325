import React, { Component } from "react";

// Customizable Area Start
// import {InProgress} from "./assets";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import "./JobDetail.css";
import Button from "@material-ui/core/Button";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import ContractInfo from "./ContractInfo.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // marginTop:"3%",
      // marginBottom:"3%"
    },
    contractMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    contractleftsec: {
      flex: 2,
    },
    contractRightsec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    }, 
    contractTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },
    contractScreenLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },
   
    applicationStatusViewContract:{
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "1.4em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginBottom:"1em"

    },
    acceptedInViewContract:{
      color: "#6F924A",
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginLeft:"0.3em"

    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    rejectedInViewContract:{
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      fontWeight: 600,
      letterSpacing: "0.09166667px",
      marginLeft:"0.3em"

    },
    
    sendAmessageBtnInViewContract:{
     color: "#FFFFFF",
     fontFamily: "Inter, sans-serif",
     backgroundColor: "#FF0000",
     borderRadius: "50px",
     padding:"1em 2em",
     fontSize: "1em",
     textTransform: "none",
     fontWeight: 700,
    },
    
  });

import { Formik } from "formik";
import { Divider } from "react-native-elements";

import ViewContractController, { Props } from "./ViewContractController.web";
import AppliedJobTopHeaderWeb from "./AppliedJobTopHeader.web";
export class ViewContract extends ViewContractController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End

  }

  render() {
    const { classes } = this.props;
    console.log(this.state.viewContractDetails?.attributes?.hire_teacher_request?.data?.attributes?.subject,"####################viewContractDetails############")
    return (


      // Customizable Area Start
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.contractMainSection}>
        <div className={classes.contractleftsec}>
          <DashSidebar1 active="AppliedJobs" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.contractRightsec}>
        <div>
              <p className={classes.contractTitle}>Job Details</p>
        </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>
            {this.state.isViewContractLoading ? <div className={classes.contractScreenLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
          <div style={{ marginTop: "20px" }}>
          <AppliedJobTopHeaderWeb navigation={this.props.navigation} id={""} jobDetails={this.state.viewContractDetails?.attributes?.hire_teacher_request?.data}/>
      
          <div style={{marginBottom:"2em"}}>
                  <p className={classes.applicationStatusViewContract}>
                    Application: 
                    {
                    this.state?.viewContractDetails?.attributes?.status==="accepted" && 
                      <span className={classes.acceptedInViewContract}>Accepted</span>}
                      {this.state?.viewContractDetails?.attributes?.status==="rejected" &&
                      <span className={classes.rejectedInViewContract}>Rejected</span>}
                  </p>
                  {this.state?.viewContractDetails?.attributes?.status==="accepted" &&
                  <Button className={classes.sendAmessageBtnInViewContract} disableElevation variant="contained">Send a Message</Button>}
                </div>
      
          <div>
            <ContractInfo navigation={this.props.navigation} id={""}  contractDetails={this.state?.viewContractDetails?.attributes?.contract_status} subject={this.state?.viewContractDetails?.attributes?.hire_teacher_request?.data?.attributes.subject}/>
          </div>
        </div>
            }
      
                
      
        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(ViewContract);

// Customizable Area End
