import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
  // route?: any;
}

export interface S {
  // Customizable Area Start
  courseDetails: any;
  categorieList: any;
  similarCourseList: any;
  instructure: any;
  previewUrl: any;
  featuress: any;
  previewAvailable: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class CoursesDetailsController extends BlockComponent<Props, S, SS> {
  apiCourseDetailsCallId: any;
  apiCoursesPreviewVideoCallId: any;
  apiCategoriesCallId: any;
  apiPopularCoursesListCallId: any;
  apiCoursesListCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      courseDetails: [],
      categorieList: [],
      similarCourseList: [],
      instructure: [],
      previewUrl: null,
      featuress: [],
      previewAvailable: true
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiCourseDetailsCallId) {
          if (!responseJson.errors && !responseJson.error) {

            if (responseJson?.data?.attributes?.is_logged_in == true) {
              this.props.navigation.navigate('CourseDetails', { courseId: responseJson?.data?.id })
            }
            console.log("CourseDetails_Response", responseJson);
            await this.setState({ courseDetails: responseJson.data.attributes })
            await this.setState({ instructure: responseJson?.data?.attributes?.instructor?.data?.attributes })
            const myArray = responseJson?.data?.attributes?.features.split("\r\n");
            await this.setState({ featuress: myArray })
            // await this.setState({ coursesList: responseJson });
            // await this.setState({ lQualification: responseJson });
            console.log("courseDetails State", this.state.courseDetails);
            await this.setState({ similarCourseList: this.state.courseDetails.similar_courses })
            console.log("similarCourseList State", this.state.similarCourseList);
            console.log("instructure State", this.state.instructure);
            console.log("instructure State", this.state.featuress);

          } else {
            console.log("ERR_CourseDetails_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.apiCoursesPreviewVideoCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("CoursesPreviewVideo_Response", responseJson);
            if (responseJson.message == 'no preview video available') {
              this.setState({ previewAvailable: false })
            }

            await this.setState({ previewUrl: responseJson.url });
            console.log("Popular List State", this.state.previewUrl);

          } else {
            console.log("ERR_CoursesPreviewVideo_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiErrorResponse(responseJson);

        }
      }
    }

    // Customizable Area End
  }


  getCourseDetails = () => {
    let token = localStorage?.getItem("loginT");
    const parms = this.props.navigation.getParam('courseId');
    const currentCourseId = parms
    const endPoint = `${configJSON.coursesDetailsApiEndpoint
      }?id=${currentCourseId}`;
    if (token == null) {
      token = ''
    }
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    console.log('token:', token)

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCourseDetailsCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };


  getCoursePreviewVideo = () => {
    const parms = this.props.navigation.getParam('courseId');
    const currentCourseId = parms
    const endPoint = `${configJSON.coursePreviewVideoApiEndpoint
      }?course_id=${currentCourseId}`;
    const headers = {
      "Content-Type": configJSON.dashboarContentType,

    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCoursesPreviewVideoCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  async componentDidMount() {
    console.log('componentDidMount CoursesDetails')
    this.getCourseDetails();
    this.getCoursePreviewVideo();
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    // const { CourseId } = this.props.route.params;
    // console.log("CourseId", CourseId);
    // this.getCourseDetils();
  }
  navigateToLogin = () => {
    this.props.navigation.navigate("EmailLogin");
  };

  navigateToCoursesList = () => {
    this.props.navigation.navigate("CoursesList", { page: 1 });
  };
  navigateToCoursesDetails = async (id: any) => {
    await localStorage.setItem("currentCourseId", id);
    this.props.navigation.navigate("CoursesDetails", { courseId: id });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    window.location.reload();
  };

}
