import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import React, { Component } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
}

export interface S {
  // Customizable Area Start
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OtpSuccessController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
  }
  componentWillMount() {}

  handleStep2 = () => {
    const cType: any = JSON.parse(localStorage.getItem("CType") || "{}");
    const customerType = cType;
    if (customerType == "learner") {
      this.props.navigation.navigate("LearnerSignUpStep2");
    } else if (customerType == "teacher") {
      this.props.navigation.navigate("TeacherSignUpStep2");
    }
  };
  // Customizable Area End
}
