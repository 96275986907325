import * as React from "react";
import { createStore, Plugin, PluginFunctions } from "@react-pdf-viewer/core";

import ReadingIndicator from "./ReadingIndicator";
import StoreProps from "./StoreProps";

export interface RReadingIndicatorPlugin extends Plugin {
  ReadingIndicator: () => React.ReactElement;
  percentages: number;
}

const RReadingIndicatorPlugin = (): RReadingIndicatorPlugin => {
  const store = React.useMemo(() => createStore<StoreProps>({}), []);
  const [percentage, setPercentage] = React.useState(0);
  const setPercente = (percentage: number) => {
    setPercentage(percentage);
  };

  const ReadingIndicatorDecorator = () => <ReadingIndicator setPer={setPercente} store={store} />;

  return {
    install: (pluginFunctions: PluginFunctions) => {
      store.update("getPagesContainer", pluginFunctions.getPagesContainer);
    },
    ReadingIndicator: ReadingIndicatorDecorator,
    percentages: percentage,
  };
};

export default RReadingIndicatorPlugin;
