import React from "react";
import AddContractInfoController, { Props } from "./AddContractInfoController.web";
import DashAdvartiseCard from "./components/DashAdvartiseCard.web";
import DashNav from "./components/DashNav.web";
import NextClassNotification from "./components/NextClassNotification.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ContractInfoEdit from "./components/ContractInfoEdit";
import ContractInfo from "./components/ContractInfo.web";
import AddContractsuccess from "./components/AddContractsuccess.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import { defaultProfilePic } from "../../email-account-registration/src/assets";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    add_contract_container_ACI: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      display: "flex",
    },
    dash_left_c_ACI: {
      flex: 2,
    },
    add_contract_cc_ACI: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    add_contract_Hr_ACI: {
      borderBottom: "0.5px solid #e4e4e5",
    },
    add_contract_title_ACI: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginBottom: "1vw",
    },
    add_contract_card_c_ACI: {
      boxShadow: "0px 5px 30px rgba(0,0,0,0.05)",
      borderRadius: "1vw",
      padding: "2vw",
      marginTop: "2vw",
    },
    add_contract_card_row_1_ACI: {
      display: "flex",
      justifyContent: "space-between",
    },
    add_contract_card_row_1_sec_1_ACI: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    add_contract_card_row_1_sec_1_img_ACI: {
      width: "4vw",
      height: "4vw",
      objectFit: "cover",
      borderRadius: "50%",
    },
    add_contract_card_row_1_sec_1_name_ACI: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.2vw",
      marginLeft: "1vw",
    },
    add_contract_card_row_1_sec_1_stat_c_ACI: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.85vw",
    },
    add_contract_card_row_1_sec_1_stat_ACI: {
      color: "#6f924a",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.85vw",
      margin: 0,
    },
    add_contract_card_row_2_ACI: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1.5vw",
    },
    add_contract_card_row_2_tit_ACI: {
      margin: "0px",
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1vw",
    },
    add_contract_card_row_2_tit_tit_ACI: {
      margin: "0px",
      marginTop: "0.7vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85vw",
    },
    add_contract_card_row_3_ACI: {
      marginTop: "1.5vw",
      marginBottom: "1.5vw",
    },
    add_contract_card_row_3_btn_ACI: {
      borderColor: "#ff4452",
      borderWidth: "2px",
      borderRadius: "10em",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.7vw",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    add_contract_card_row_4_ACI: {
      marginTop: "1.5vw",
    },
    add_contract_card_row_4_btn1_ACI: {
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      marginRight: "1vw",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.7vw",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    add_contract_card_row_4_btn2_inactive_ACI: {
      backgroundColor: "#f0d17c",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      color: "#333333",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.7vw",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    add_contract_details_cc_ACI: {
      marginTop: "4vw",
    },
    add_contract_details_title_ACI: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "1.2vw",
      fontWeight: 600,
    },
    loadingContract: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "2em",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },
  });
// Customizable Area End

export class AddContractInfo extends AddContractInfoController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.add_contract_container_ACI}>
          <div className={classes.dash_left_c_ACI}>
            <DashNav
              active="Dashboard"
              styles={{ marginBottom: "1.5vw" }}
              navigation={this.props.navigation}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              onClick={() => { }}
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
              onClick={() => { }}
            />
          </div>
          {this.state.getDetailsLoading ? <div className={classes.loadingContract}>
            <CircularProgress />
          </div> :
            <div className={classes.add_contract_cc_ACI}>
              <p className={classes.add_contract_title_ACI}>Candidate Details</p>
              <div className={classes.add_contract_Hr_ACI} />
              <div className={classes.add_contract_card_c_ACI}>
                <div className={classes.add_contract_card_row_1_ACI}>
                  <div className={classes.add_contract_card_row_1_sec_1_ACI}>
                    {this.state.contractDetails?.attributes?.account?.data?.attributes?.profile_image ? <img
                      src={this.state.contractDetails?.attributes?.account?.data?.attributes?.profile_image}
                      alt=""
                      className={classes.add_contract_card_row_1_sec_1_img_ACI}
                    />
                      :
                      <img
                        src={defaultProfilePic}
                        alt=""
                        className={classes.add_contract_card_row_1_sec_1_img_ACI}
                      />
                    }
                    <p className={classes.add_contract_card_row_1_sec_1_name_ACI}>
                      {this.state.contractDetails?.attributes?.account?.data?.attributes?.first_name}
                      {" "}
                      {this.state.contractDetails?.attributes?.account?.data?.attributes?.first_name}
                    </p>
                  </div>
                  <div className={classes.add_contract_card_row_1_sec_1_ACI}>
                    <p className={classes.add_contract_card_row_1_sec_1_stat_c_ACI}>Status:&nbsp;&nbsp;</p>
                    <p className={classes.add_contract_card_row_1_sec_1_stat_ACI}>
                      {this.state.contract_status}
                    </p>
                  </div>
                </div>
                <div className={classes.add_contract_card_row_2_ACI}>
                  <div>
                    <p className={classes.add_contract_card_row_2_tit_ACI}>Experience</p>
                    <p className={classes.add_contract_card_row_2_tit_tit_ACI}>{this.state.contractDetails?.attributes?.account?.data?.attributes?.academic?.data?.attributes?.total_experience}</p>
                  </div>
                  <div>
                    <p className={classes.add_contract_card_row_2_tit_ACI}>Qualification</p>
                    <p className={classes.add_contract_card_row_2_tit_tit_ACI}>{this.state.contractDetails?.attributes?.account?.data?.attributes?.academic?.data?.attributes?.qualification?.name}</p>
                  </div>
                  <div>
                    <p className={classes.add_contract_card_row_2_tit_ACI}>Specialization</p>
                    <p className={classes.add_contract_card_row_2_tit_tit_ACI}>{this.state.contractDetails?.attributes?.account?.data?.attributes?.academic?.data?.attributes?.specialization}</p>
                  </div>
                  <div>
                    <p className={classes.add_contract_card_row_2_tit_ACI}>Languages</p>
                    <p className={classes.add_contract_card_row_2_tit_tit_ACI}>{this.state.contractDetails?.attributes?.account?.data?.attributes?.academic?.data?.attributes?.known_languages[0]}</p>
                  </div>
                  <div>
                    <p className={classes.add_contract_card_row_2_tit_ACI}>Availability</p>
                    <p className={classes.add_contract_card_row_2_tit_tit_ACI}>{this.state.contractDetails?.attributes?.account?.data?.attributes?.academic?.data?.attributes?.availability}</p>
                  </div>
                </div>
                <div className={classes.add_contract_card_row_3_ACI}>
                  <Button variant="outlined" className={classes.add_contract_card_row_3_btn_ACI}>
                    Download Resume
                  </Button>
                </div>
                <div className={classes.add_contract_Hr_ACI} />
                <div className={classes.add_contract_card_row_4_ACI}>
                  <Button variant="contained" className={classes.add_contract_card_row_4_btn1_ACI}>
                    Send Message
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.add_contract_card_row_4_btn2_inactive_ACI}
                    onClick={this.handleAddContract}
                    style={
                      this.state.addContract || this.state.contractSuccess
                        ? { backgroundColor: "transparent", border: "1px solid #dcdcdc" }
                        : {}
                    }
                    data-testid="handleAddContractBtn"
                  >
                    Add Contract Info
                  </Button>
                </div>
              </div>
              {this.state.addContract || this.state.contractSuccess ? (
                <div className={classes.add_contract_details_cc_ACI}>
                  <p className={classes.add_contract_details_title_ACI}>Contract Info</p>
                  {this.state.addContract  &&
                    !this.state.contractSuccess  &&
                    !this.state.contractSubmitted  ? (
                    <div>
                      <ContractInfoEdit
                        startTime={this.startTime}
                        endTime={this.endTime}
                        sendContract={this.handleSendContractInfo}
                        from={this.state.timeFrom}
                        to={this.state.timeTo}
                        contractTypeData={this.state.contractTypeData}
                        paymentConditionData={this.state.paymentConditionData}
                        distributionMethodData={this.state.distributionMethodData}
                        currencyTypeData={this.state.currencyTypeData}
                        proposed_budget={this.state.proposed_budget}
                      />
                    </div>
                  ) : null}

                  {this.state.contractSubmitted  && (
                    <div>
                      <ContractInfo
                        navigation={this.props.navigation}
                        contractDetails={this.state?.contractDetails?.attributes?.contract_status}
                        subject={this.state?.contractDetails?.attributes?.hire_teacher_request?.data?.attributes.subject}
                      />
                    </div>
                  ) }

                  {this.state.contractSuccess  && (
                    <div>
                      <AddContractsuccess />
                    </div>
                  ) }
                </div>
              ) : null}
            </div>}
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(AddContractInfo);
