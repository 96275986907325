import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { dropdown } from "../assets";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { DateRangePicker } from "rsuite";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prl_input_left_CLF: {
      position: "relative",
      width: "17vw",
      marginRight: "2em",
    },
    prl_input_left_CLF__customeDate: {
      position: "relative",
      width: "18vw",
      marginRight: "2em",
      height: "3.5em",
    },
    prl_input_lable_CLF: {
      display: "flex",
      fontSize: "0.9em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      marginBottom: "0.7em",
      color: "rgba(51, 51, 51, 1)",
    },
    prl_input_dd_CLF: {
      position: "relative",
    },
    prl_input_dd_s_CLF: {
      width: "100%",
      height: "3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
    },
    prl_input_arrow_CLF: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "2em",
      height: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      marginTop: "0.2em",
      marginRight: "0.2em",
      pointerEvents: "none",
    },
  })
);

interface Props {
  handleFilterByChange: any;
  handleFilterByPriceChange: any;
  handleFilterByStartEndDateChange: any;

  CSLselectFilterBy: any;
  CSLselectFilterByPrice: any;
  CSLstartDate: Date;
  CSLendDate: any;
}

const CoursesListFilters = ({
  handleFilterByChange,
  handleFilterByPriceChange,
  handleFilterByStartEndDateChange,

  CSLselectFilterBy,
  CSLselectFilterByPrice,
  CSLstartDate,
  CSLendDate,
}: Props) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [value, setValue] = React.useState<[Date, Date]>([new Date(), new Date()]);

  useEffect(() => {
    if (CSLstartDate != null && CSLendDate != null) {
      console.log("noentry", CSLstartDate, CSLendDate);
      const start: Date = moment(CSLstartDate).toDate();
      const end: Date = moment(CSLendDate).toDate();
      setValue([start, end]);
      setStartDate(start);
      setEndDate(end);
      console.log("dateeee", start, end, startDate, endDate);
    }
  }, [CSLstartDate, CSLendDate]);

  return (
    <div style={{ width: "100%" }}>
      <Grid style={{ display: "flex", width: "100%" }}>
        <div>
          <div className={classes.prl_input_left_CLF}>
            <label className={classes.prl_input_lable_CLF}>Filter By</label>
            <div className={classes.prl_input_dd_CLF}>
              <select
                value={CSLselectFilterBy == null ? undefined : CSLselectFilterBy}
                className={classes.prl_input_dd_s_CLF}
                defaultValue="select"
                onChange={(e) => {
                  handleFilterByChange(e);
                }}
                data-testId="CoursesFilterBy"
              >
                <option value="select" key={0}>
                  Select
                </option>
                <option value="popular" key={1}>
                  Popular
                </option>
                <option value="all" key={2}>
                  All
                </option>
              </select>
              <span className={classes.prl_input_arrow_CLF}>
                <img src={dropdown} alt="arrow" />
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.prl_input_left_CLF}>
            <label className={classes.prl_input_lable_CLF}>Price</label>
            <div className={classes.prl_input_dd_CLF}>
              <select
                value={CSLselectFilterByPrice == null ? undefined : CSLselectFilterByPrice}
                className={classes.prl_input_dd_s_CLF}
                defaultValue="select"
                onChange={(e) => {
                  handleFilterByPriceChange(e);
                }}
                data-testId="CoursesFilterByPrice"
              >
                <option value="select" key={0}>
                  Select
                </option>
                <option value="paid" key={1}>
                  Paid
                </option>
                <option value="free" key={2}>
                  Free
                </option>
              </select>
              <span className={classes.prl_input_arrow_CLF}>
                <img src={dropdown} alt="arrow" />
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className={classes.prl_input_left_CLF__customeDate}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label className={classes.prl_input_lable_CLF}>Start Date / End Date</label>
              {startDate && endDate ? (
                <button
                  // variant="contained"
                  onClick={async () => {
                    await setStartDate(null);
                    await setEndDate(null);
                    await handleFilterByStartEndDateChange(null, null);
                    setValue([new Date(), new Date()]);
                  }}
                  data-testId="CoursesFilterByDateClear"
                  style={{
                    fontWeight: "bold",
                    border: "0px",
                    background: "transparent",
                    fontSize: "0.85em",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: "0.7em",
                    color: "#ff0000",
                    cursor: "pointer",
                  }}
                >
                  X
                </button>
              ) : null}
            </div>
            <div className="prl_input_left_CLF__customeDate_C">
              <Button
                // className="dateBtn"
                variant="outlined"
                color="primary"
                style={{
                  width: "100%",
                  height: "3.5rem",
                  background: "white",
                  color: "#a8a8a8",
                  cursor: "pointer",
                  border: "1px solid #c7c7c7",
                  paddingLeft: "1.5em",
                  fontSize: "0.8em",
                  fontFamily: "Inter, sans-serif",
                  borderRadius: "0.5em",
                }}
                disabled
                data-testId="CoursesFilterByDateBtn"
              >
                <div style={{ width: "100%", display: "flex" }}>
                  {startDate ? moment(startDate).format("DD/MM/YYYY") : "dd/mm/yyyy"} -{" "}
                  {endDate ? moment(endDate).format("DD/MM/YYYY") : "dd/mm/yyyy"}
                </div>
              </Button>
              <div className="prl_input_left_CLF__customeDate_CC">
                <DateRangePicker
                  value={value}
                  onChange={async (val: any) => {
                    console.log("val", val, "vv");
                    await setStartDate(val[0]);
                    console.log("startDate", startDate);
                    await setEndDate(val[1]);
                    console.log("endDate", endDate);
                    await setValue(val);
                    handleFilterByStartEndDateChange(val[0], val[1]);
                  }}
                  showMeridian
                  format="dd-MM-yyyy"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};
export default CoursesListFilters;
