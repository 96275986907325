import React from "react";

// Customizable Area Start

import Button from "@material-ui/core/Button";
import { hireteacher } from "./assets";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";

import moment from "moment";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    hireTeacherMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    hireteacherleftsec: {
      flex: 2,
    },
    hireTeacherRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    teacherSubmitButton:{
      color: "#FFFFFF",
      fontFamily: "Inter, sans-serif",
      backgroundColor: "#FF0000",
      borderRadius: "50px",
      fontSize: "0.9vw",
      textTransform: "none",
      padding:" 1em 3em",
      fontWeight: 700,                  

    },
    errorstyling:{
      margin: "0px",
      color: "#fb7b7c",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif"
    }
  });

  
// Customizable Area End

import HireTeacherController, { Props } from "./HireTeacherController.web"

import { Divider } from "react-native-elements";

import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

export class HireTeacher extends HireTeacherController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        <div className={classes.root}>
          <TopNavigation
            navigation={this.props.navigation}
            history={this.props.history}
            location={this.props.location}
          />
          {/* Banner */}
          <div className={classes.hireTeacherMainSection}>
            <div className={classes.hireteacherleftsec}>
              <DashNav
               styles={{ marginBottom: "1.5vw" }}
                active="HireATeacher"
                navigation={this.props.navigation}
              />
              <NextClassNotification
                timings="10:00 AM - 11:00 PM"
                title="Introduction to foundation of digital marketing and e-commerce"
                styles={{ marginBottom: "1.5vw" }}
                subtitle=" Lorem Ipsum is simply dummy text of the printing"
                // onClick={() => {
                //   console.log("Next Class");
                // }}
              />
              <DashAdvartiseCard
                title="One on One classes with Teachers"
                // onClick={() => { }}
                subtitle="Lorem Ipsum is simply dummy text of the printing"
                styles={{ marginBottom: "1.5vw" }}
              />
            </div>
            <div className={classes.hireTeacherRightSec}>
              <div>
                <p
                  style={{
                    color: "#14292d",
                    margin:" 0px",
                    fontSize:" 1.55vw",
                    marginTop: "1vw",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    marginBottom: "1vw"
                  }}
                >
                  Hire a Teacher
                </p>
                <Divider />
              </div>
              <div style={{ display: "flex", marginTop: "35px" }}>
                <img
                  alt=""
                  src={hireteacher}
                  style={{ height: "210px", width: "210px", marginRight: "35px" }}
                />
                <p style={{ lineHeight: "30px", marginTop: "25px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facere exercitationem,
                  deserunt
                  <br /> illo illum repellendus qui recusandae ipsa nihil iure asperiores, dolores temporibus
                  officia minus
                  <br /> corporis dolore ab veritatis.Fusce in dui dui. Sed et mi venenatis, euismod <br />
                  risus et, facilisis magna. Aliquam non malesuada lectus. Aenean nec augue egestas, efficitur
                  <br /> elit eget, lobortis risus. Proin velit urna.{" "}
                </p>
              </div>
              <div>
                <div>
                  <div className="pr-title"  style={{margin:"2em 0em"}}>
                    <p>Fill up the form below</p>
                  </div>
                  <div style={{ background: "#f7f7f7", padding: "30px", borderRadius: "20px" }}>
                    <Grid container style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                      <Grid style={{ display: "flex", marginBottom: "15px" }}>
                        <Grid item xs={6}>
                          <div style={{ marginRight: "15px" }}>
                            <label className="prl-input-lable">Duration<sup className="required">*</sup></label>
                            <div className="prl-input-dd">
                              <select
                                className="prl-input-dd-s"
                                data-testId="hire-teacher-duration"
                                value={this.state.duration}
                                onChange={(event) => {
                                  this.handleDuration(event);
                                }}
                               style={this.state.ErrorForDuraiton ? this.errorStyle : {}}
                              >
                              <option value=''>Select Duration</option>
                              {this.state.durationList?.map((eachDuration: any, index: any) => {
                                return (
                                  <option value={eachDuration.duration} key={index}>
                                    {eachDuration.duration}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                            {this.state.ErrorForDuraiton? (
                        <p className={classes.errorstyling} >
                         Please select a value
                        </p>
                      ) : (
                        <></>
                      )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ marginLeft: "15px" }}>
                            <label className="prl-input-lable">Experience <sup className="required">*</sup></label>
                            <div className="prl-input-dd">
                              <select
                                className="prl-input-dd-s"
                                data-testId="hireTeacherExpereience"
                                value={this.state.experience}
                                onChange={(event) => {
                                  this.handleExperience(event);
                                }}
                                style={this.state.ErrorForExperience ? this.errorStyle : {}}
                              >   <option value=''>Select Experience</option>
                              {this.state.experienceList?.map((eachexp: any, index: any) => {
                                return (
                                  <option value={eachexp.experience} key={index}>
                                    {eachexp.experience}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                            {this.state.ErrorForExperience? (
                        <p className={classes.errorstyling} >
                         Please select a value
                        </p>
                      ) : (
                        <></>
                      )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={{ display: "flex", marginBottom: "15px" }}>
                        <Grid item xs={6}>
                          <div style={{ marginRight: "15px" }}>
                            <label className="prl-input-lable">Qualification <sup className="required">*</sup></label>
                            <div className="prl-input-dd">
                              <select
                                className="prl-input-dd-s"
                               data-testId = "hireTeacherQualification"
                               value={this.state.qualification}
                                onChange={(event) => {
                                  this.handleteacherQualification(event);
                                }}
                              
                            style={this.state.ErrorForQualification ? this.errorStyle : {}}
                              >
                              <option value=''>Select Qulaification</option>
                              {this.state.qualificationList?.map((eachQualification: any, index: any) => {
                                return (
                                  <option value={eachQualification.name} key={index}>
                                    {eachQualification.name}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                            {this.state.ErrorForQualification ? (
                        <p className={classes.errorstyling}> 
                          Please enter a value.
                        </p>
                      ) : (
                        <></>
                      )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ marginLeft: "15px" }}>
                            <label className="prl-input-lable">Subject <sup className="required">*</sup></label>
                            <input
                              className="prl-input"
                              data-testId = "hireTeacherSubject"
                              type="text"
                               value={this.state.subject}
                              onChange={(e:any) => {
                                this.handleSubject(e);
                              }}
                              placeholder="Enter Subject"
                        style={this.state.ErrorForSubject || this.state.ErrorForSubjectMaxChar? this.errorStyle : {}}
                            />
                      {this.state.ErrorForSubject ? (
                        <p className={classes.errorstyling}> 
                          Please enter a value.
                        </p>
                      ) : (
                        <></>
                      )}
                      {this.state.ErrorForSubjectMaxChar? (
                        <p className={classes.errorstyling} >
                          Maximum 20 characters
                        </p>
                      ) : (
                        <></>
                      )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={{ display: "flex", marginBottom: "15px" }}>
                        <Grid item xs={6}>
                          <div style={{ marginRight: "15px" }}>
                            <label className="prl-input-lable">Start Date <sup className="required">*</sup></label>
                            <input
                              className="prl-input"
                              data-testId = "hireTeacherStartDate"
                              type="date"
                              value={this.state.startDate}
                              onChange={(event:any) => {
                              this.handlestartDate(event);
                              }}
                              min={moment().format("YYYY-MM-DD")}
                              placeholder="01-08-2022"
                             style={this.state.ErrorForStartDate ? this.errorStyle : {}}
                            />
                             {this.state.ErrorForStartDate? (
                        <p className={classes.errorstyling} >
                         Please select a value
                        </p>
                      ) : (
                        <></>
                      )}
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ marginLeft: "15px" }}>
                            <label className="prl-input-lable">Last Date to Submit Application</label>
                            <input
                            disabled={!this.state.startDate.length}
                            data-testId = "hireTeacherLastDateSubmit"
                              className="prl-input"
                              type="date"
                              value={this.state.lastDateToSubmit}
                              onChange={(e:any) => {
                                this.handlelastDateToSubmit(e);
                                }}
                              max={this.state.startDate}
                              min={moment().format("YYYY-MM-DD")}
                              placeholder="15-08-2022"
                              style={this.state.ErrorForLastDateSubmit ? this.errorStyle : {}}
                            />
                              {this.state.ErrorForLastDateSubmit? (
                        <p className={classes.errorstyling} >
                         Please select a Value
                        </p>
                      ) : (
                        <></>
                      )}
                            
                          </div>
                        </Grid>
                      </Grid>
                      <Grid style={{ display: "flex", marginBottom: "15px" }}>
                        <Grid item xs={6}>
                          <div style={{ marginRight: "15px" }}>
                            <label className="prl-input-lable">Schedule <sup className="required">*</sup></label>
                            <div className="prl-input-dd">
                              <select
                                className="prl-input-dd-s"
                                data-testId = "hireTeacherschedule"
                                onChange={(event) => {
                                  this.handleSchedule(event);
                                }}
                                style={this.state.ErrorForSchedule ? this.errorStyle : {}}
                              >
                              <option value=''>Select Schedule</option>
                              {this.state.scheduleList?.map((eachschedule: any, index: any) => {
                                return (
                                  <option value={eachschedule.schedule} key={index}>
                                    {eachschedule.schedule}
                                  </option>
                                );
                              })}
                              </select>
                            </div>
                            {this.state.ErrorForSchedule ? (
                        <p className={classes.errorstyling}> 
                          Please enter a value.
                        </p>
                      ) : (
                        <></>
                      )}

                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ marginLeft: "15px" }}>
                            <label className="prl-input-lable">Proposed Budget <sup className="required">*</sup></label>
                            <input
                              className="prl-input"
                              type="number"
                              data-testId = "hireTeacherBudget"
                               value={this.state.proposedBudget}
                              onChange={(e) => {
                              this.handleProposedBudget(e);
                              }}
                              placeholder="Enter Budget"
                             style={this.state.ErrorForPropsedBudget || this.state.ErrorForProspsedBudgetMax? this.errorStyle : {}}
                            />
                            {this.state.ErrorForPropsedBudget ? (
                        <p className={classes.errorstyling}> 
                          Please enter a value.
                        </p>
                          ) : (
                        <></>
                            )}
                      {this.state.ErrorForProspsedBudgetMax? (
                        <p className={classes.errorstyling}>
                          Please Enter 5 digit value
                        </p>
                      ) : (
                        <></>
                      )}


                          </div>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid item xs={12}>
                          <label className="prl-input-lable">Description</label>
                          <input
                            className="prl-input"
                            data-testId="hireTeacherDescription"
                            type="text"
                             value={this.state.description}
                            onChange={(e:any) => {
                            this.handleDescription(e);
                            }}
                            placeholder="Enter Description"
                          style={this.state.ErrorForDescriptionMaxChar ? this.errorStyle : {}}
                          />
                           {this.state.ErrorForDescriptionMaxChar? (
                        <p className={classes.errorstyling} >
                          Maximum 250 characters
                        </p>
                      ) : (
                        <></>
                      )}
                          
                        </Grid>
                      </Grid>
                    </Grid>
                    <div style={{ marginTop: "25px" }}>
                      <Button
                        className={classes.teacherSubmitButton}
                        data-testId="hireTeacherSubmitButton"
                        onClick={() => {
                            this.handleHireATeacherSubmit();
                        }}
                        variant="contained"
                      >
                          Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BottomNavigation
            navigation={this.props.navigation}
            history={this.props.history}
            location={this.props.location}
          />
        </div>
      </>

      // Customizable Area End
    );
  }
}

export default withStyles(styles, { withTheme: true })(HireTeacher);

// Customizable Area End
