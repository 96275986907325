// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start

  // Customizable Area End
}

export interface SS {
  id: any;

}
export default class StudentSubjectDetialsController extends BlockComponent<Props, S, SS> {
  constructor(props: any) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    this.state = {
      // Customizable Area Start
      subjectDetails:{},
      isSubjectDetailsLoading:false
      // Customizable Area End
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {

    // Customizable Area Start

    // Customizable Area End
  }




  async receive(from: string, message: Message) {
    // Customizable Area Start
  
  

    // Customizable Area End
  }



 
}


  // Customizable Area End