import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
   // Customizable Area Start
   // Customizable Area End
 
}

export interface S {
  // Customizable Area Start  
  jobsList:any;
  page:number;
  coursesList: any;
  currPage: number;
  totPage: number;
  pageFrom:any;
  paginationjobsList: any;
  JLPtotalPage:number;
  JPLcurrentPage:number;
  numberOfPages : number
  isLoading:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}


 // Customizable Area Start
export default class JobListingController extends BlockComponent<Props, S, SS> {
  getAllJobsId: any;
  JLapiRequestCallId: any;
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      jobsList:[],
      page:1,
      coursesList: [],
      currPage: 1,
      totPage: 2,
      pageFrom:"",
      paginationjobsList:[],
      JLPtotalPage:1,
      JPLcurrentPage:1,
      numberOfPages : 6,
      isLoading:true,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getJobListingDetails()
    // Customizable Area End
  }
  


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    this.handleResponseForJobs(from,message)
    

    // Customizable Area End
  }
 // Customizable Area Start
  handleResponseForJobs=(from: string, message: Message)=>{
  
      if(getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const JLapiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage));
          
            const  responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
             );

            if (JLapiRequestCallId === this.getAllJobsId){
              return this.handelJobListingResponse(responseJson)
            }
            else {
              return this.handleJobListingErrorResponse(responseJson)
            }
    }

    
  }
  
   handelJobListingResponse = (responseJson:any) =>{
     console.log("CoursesList_Response", responseJson);
           this.setState({ jobsList: responseJson?.data ,paginationjobsList: responseJson.data,JPLcurrentPage: responseJson.page,JLPtotalPage: responseJson.page_count });
           this.setState({isLoading:false})
   }

   handleJobListingErrorResponse = (responseJson:any) =>{
     console.log("Error in response for pagination........", responseJson);
    
   }


  getJobListingDetails() {
    const token = localStorage.getItem("loginT");
    const endPoint = `get_all_jobs?per_page=${this.state.numberOfPages}&page=${this.state.currPage}`;


    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getJobssRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllJobsId = getJobssRequestMsg.messageId;


    getJobssRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getJobssRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getJobssRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getJobssRequestMsg.id, getJobssRequestMsg);

  }

  handleJobDetails=(eachJOb:any)=>{
    localStorage.setItem("jobdetialId",eachJOb?.id);
    this.props.navigation.navigate("JobDetails")
    
  }



}
// Customizable Area End
  