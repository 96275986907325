import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  StatusBar,
  SafeAreaView,
  Platform
} from "react-native";

//@ts-ignore
import Scale from "../../../components/src/Scale";
import { emailIcon, loginbg, passwordIcon, backArrow, imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  
  // Customizable Area Start
  renderTextInput = () => {
    return (
      <View>
        <View style={styles.blankView} />
        <TouchableOpacity testID="backButtonId" onPress={this.backButtonContainer}>
          <Image style={styles.backArrowImage} source={backArrow} />
        </TouchableOpacity>
        <Image source={loginbg} style={styles.img} />
        <Text style={styles.loginText}>Login</Text>

        <View style={styles.emailView}>
          <Image
            style={styles.visibleImage}
            source={emailIcon}
          />
          <TextInput style={styles.textInputText}
            testID="emailTextInputId"
            placeholder="Email Id"
            placeholderTextColor="#b7b7b7"
            onChangeText={(text: any) => this.setState({ email: text, emailMessage: "" })}
            value={this.state.email}
            autoCapitalize="none"
          />
        </View>
        {this.state.emailMessage !== "" &&
          <Text style={styles.emailMessageText}>{this.state.emailMessage}</Text>
        }
        <View style={styles.passwordView}>
          <Image
            style={styles.visibleImage}
            source={passwordIcon}
          />
          <TextInput style={styles.passwordTextInputText}
            testID="passwordTextInputId"
            placeholder="Password"
            placeholderTextColor="#b7b7b7"
            secureTextEntry={!this.state.checkbox}
            onChangeText={(text) => this.setState({ password: text, passwordMessage: "" })}
            value={this.state.password}
          />
          <TouchableOpacity testID="toggleEyeButtonId" onPress={() => this.setState({ checkbox: !this.state.checkbox })}>
            <Image
              style={styles.visibleImage}
              source={this.state.checkbox ? imgPasswordVisible : imgPasswordInVisible}
            />
          </TouchableOpacity>
        </View>
        {this.state.passwordMessage !== "" &&
          <Text style={styles.emailMessageText}>{this.state.passwordMessage}</Text>
        }
        <TouchableOpacity testID="forgetButtonId" onPress={this.forgetButtonContainer}>
          <Text style={styles.forgotPassText}>Forgot Password?</Text>
        </TouchableOpacity>
        <TouchableOpacity testID="logInButtonId" onPress={this.Login}
          style={styles.logInView}>
          <Text style={styles.logInText}>Login</Text>
        </TouchableOpacity>
        <View style={styles.newUserView}>
          <Text style={styles.newUserText}>New to TECHFIN? </Text>
          <TouchableOpacity testID="cfDedicationButtonId" onPress={this.cfDedicationButtonContainer}>
            <Text style={styles.registerText}>Register</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Required for all blocks
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        {/* Required for all blocks */}
        <TouchableWithoutFeedback
          testID={"Background"}
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <SafeAreaView style={styles.mainContainer}>
            <StatusBar backgroundColor="#000" />
            {this.renderTextInput()}
          </SafeAreaView>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
  },
  blankView: {
    height: Scale(40),
  },
  backArrowImage: {
    height: Scale(20),
    width: Scale(10),
    marginLeft: Scale(30)
  },
  img: {
    alignSelf: "center",
    resizeMode: "contain",
    height: Scale(200),
    width: Scale(200),
  },
  loginText: {
    fontSize: 24,
    fontWeight: '600',
    color: "#494949",
    marginHorizontal: Scale(18),
    padding: 10
  },
  emailView: {
    height: Scale(50),
    marginHorizontal: Scale(20),
    borderWidth: Scale(1.5),
    borderRadius: Scale(8),
    borderColor: "#b7b7b7",
    paddingHorizontal: Scale(10),
    flexDirection: "row",
    alignItems: "center",
    marginTop: Scale(20),
  },
  passwordView: {
    height: Scale(50),
    marginHorizontal: Scale(20),
    borderWidth: Scale(1.5),
    borderRadius: Scale(8),
    borderColor: "#b7b7b7",
    paddingHorizontal: Scale(10),
    flexDirection: "row",
    alignItems: "center",
    marginTop: Scale(20),
  },
  textInputText: {
    width: Scale(290),
    marginLeft: Scale(10)
  },
  passwordTextInputText: {
    width: Scale(260),
    marginLeft: Scale(10)
  },
  visibleImage: {
    height: Scale(24),
    width: Scale(24),
  },
  forgotPassText: {
    color: "#272727",
    fontSize: Scale(16),
    lineHeight: 30,
    alignSelf: 'flex-end',
    marginRight: Scale(20),
    fontWeight: 'bold'
  },
  logInView: {
    height: Scale(50),
    marginHorizontal: Scale(25),
    borderRadius: Scale(25),
    marginTop: Scale(40),
    backgroundColor: "#fc0b1b",
    alignItems: "center",
    justifyContent: "center"
  },
  logInText: {
    fontSize: Scale(19),
    fontWeight: "bold",
    color: "#ffffff"
  },
  newUserView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: Scale(25)
  },
  newUserText: {
    fontSize: Scale(15),
    fontWeight: "500",
    color: '#565656'
  },
  registerText: {
    color: "#fc0b1b",
    fontWeight: "600"
  },
  emailMessageText: {
    fontSize: Scale(15),
    color: 'red',
    marginLeft: Scale(20)
  }
});
// Customizable Area End
