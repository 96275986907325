import React, { Component } from "react";
import { TL_BNR } from "./assets";
import TeachersLandingPageController, { Props } from "./TeachersLandingPageController.web";

export default class BannerTeacher extends TeachersLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="tbnr-container">
        <div className="tbnr-container-c">
          <img src={TL_BNR} alt="banner" className="tbnr-img" />
          <div
            className="tbnr-title-c"
            style={{ fontFamily: "Inter-SemiBold" }}
          >
            <p>
              Inspire Learners & Expand
              <br />
              Your Network
            </p>
            <button className="tbnr-btn">Register Now</button>
          </div>
        </div>
      </div>
    );
  }
}
