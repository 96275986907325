import React from 'react'
import CourseCard from './CourseCard.web';
import Grid from "@material-ui/core/Grid";

interface Props {
    CSLpopularCourseList: any;
    navigateToCoursesDetails: (id: any) => void
}

const PopularCoursesList = ({ CSLpopularCourseList, navigateToCoursesDetails }: Props) => {
    return (
        <>
            <div className="CL_Title">
                <label>Popular Courses</label>
            </div>
            <div>
                <Grid
                    container
                    justifyContent="flex-start"
                    style={{
                        marginTop: "3em",
                        width: "100%",
                        columnGap: "1.5vw",
                        rowGap: "1.5vw",
                        marginBottom: "1.5em",
                    }}
                >
                    {CSLpopularCourseList.map((val: any, key: any) => {
                        return (
                            <div key={val.id}>
                                <CourseCard
                                    id={val?.id}
                                    image_url={val?.image_url}
                                    name={val?.name}
                                    course_description={val?.course_description}
                                    selected_count={val?.selected_count}
                                    course_type={val?.course_type}
                                    course_cost={val?.course_cost}
                                    start_date={val?.start_date}
                                    is_paid={val?.is_paid}
                                    handleViewCourse={navigateToCoursesDetails}
                                    handleBuyCourse={navigateToCoursesDetails}
                                />
                            </div>
                        );
                    })}
                </Grid>
            </div>
        </>
    )
}

export default PopularCoursesList