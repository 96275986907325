import React from "react";
import CoursesListPageItem from "./CoursesListPageItem.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CourseListPaginate from "../../../dashboard/src/components/CourseListPaginate.web";
import CoursesListFilters from "../../../dashboard/src/components/CoursesListFilters.web";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    CL_CC_C: {
      display: "flex",
      backgroundColor: "white",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    loadingProfile_CLPN: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },
  })
);

interface Props {
  enableFilters?: boolean;
  currPage: any;
  navigateToCoursesDetails: any;
  handleFilterByChange?: any;
  coursesList: any;
  handleFilterByPriceChange?: any;
  totPage: any;
  navigation: any;
  handleFilterByStartEndDateChange?: any;

  pageFrom: "CoursesList" | "CourseList" | "ElasticSearch";
  CSLselectFilterBy?: any;
  CSLendDate?: any;
  CSLselectFilterByPrice?: any;
  CSLstartDate?: any;
  fetchCourseListLoading: boolean;
}

const CourseListPagination = ({
  handleFilterByStartEndDateChange,
  totPage,
  navigateToCoursesDetails,
  coursesList,
  handleFilterByChange,
  navigation,
  currPage,
  handleFilterByPriceChange,
  enableFilters,

  CSLselectFilterBy,
  CSLselectFilterByPrice,
  CSLstartDate,
  CSLendDate,
  pageFrom,
  fetchCourseListLoading,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.CL_CC_C}>
      {enableFilters && (
        <CoursesListFilters
          handleFilterByChange={handleFilterByChange}
          handleFilterByPriceChange={handleFilterByPriceChange}
          handleFilterByStartEndDateChange={handleFilterByStartEndDateChange}
          CSLselectFilterBy={CSLselectFilterBy}
          CSLselectFilterByPrice={CSLselectFilterByPrice}
          CSLstartDate={CSLstartDate}
          CSLendDate={CSLendDate}
        />
      )}
      {fetchCourseListLoading ? (
        <div className={classes.loadingProfile_CLPN} style={{ marginLeft: "2em" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <CoursesListPageItem posts={coursesList} navigateToCoursesDetails={navigateToCoursesDetails} />
          <CourseListPaginate
            coursesList={coursesList}
            totPage={totPage}
            currPage={currPage}
            navigation={navigation}
            CSLselectFilterBy={CSLselectFilterBy}
            CSLselectFilterByPrice={CSLselectFilterByPrice}
            CSLstartDate={CSLstartDate}
            CSLendDate={CSLendDate}
            pageFrom={pageFrom}
          />
        </>
      )}
    </div>
  );
};

export default CourseListPagination;
