import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  viewAppliedJobDetails:any
  isappliedJobLoading:any
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class ViewAppliedJobController extends BlockComponent<Props, S, SS> {
 // Customizable Area Start   
 viewAppliedJobCallId:any
  //Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      viewAppliedJobDetails:{},
      isappliedJobLoading:true
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
     // Customizable Area Start

    this.getEachAppliedJobDetails()
       // Customizable Area End 
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (this.viewAppliedJobCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          viewAppliedJobDetails: apiResponse?.data,
          isappliedJobLoading:false
        })
      }

  }




   // Customizable Area End 

  }
  // Customizable Area Start
  getEachAppliedJobDetails=()=>{
    const JobDetailsId = localStorage.getItem("viewAppliedJob");
    const endPoint = `${"view_applied_job"}?id=${JobDetailsId}`;
      const token = localStorage.getItem("loginT");
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      const getViewAppliedJobDetails = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.viewAppliedJobCallId = getViewAppliedJobDetails.messageId;
  
  
      getViewAppliedJobDetails.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      );
  
      getViewAppliedJobDetails.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getViewAppliedJobDetails.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getHttpMethodType
      );
      runEngine.sendMessage(getViewAppliedJobDetails.id, getViewAppliedJobDetails);
  
  
  }




  // Customizable Area End 
}
