import React, { useState } from "react";
import { dropdown } from "../assets";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Timers from "./Timers.web";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Contract_container: {
      background: "#f7f7f7",
      padding: "2vw",
      borderRadius: "1vw",
      marginBottom: "3vw",
    },
    prl_input_cc: {
      display: "flex",
      width: "100%",
      marginBottom: "1em",
    },
    prl_input_left: {
      position: "relative",
      width: "100%",
      marginRight: "1em",
    },
    prl_input_left_Count_c: {
      position: "relative",
      width: "100%",
      paddingRight: "1em",
      display: "flex",
    },
    prl_input_right: {
      position: "relative",
      width: "100%",
      marginLeft: "1em",
    },
    prl_input_lable: {
      display: "flex",
      fontSize: "0.9em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      marginBottom: "0.7em",
      color: "rgba(51, 51, 51, 1)",
    },
    prl_input_dd: {
      position: "relative",
    },
    prl_input_dd_s: {
      width: "100%",
      height: "3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
      "&:focus-visible": {
        outline: "0px",
      },
    },
    prl_input_dd_currency: {
      position: "relative",
      width: "8vw",
      height: "3vw",
    },
    prl_input_dd_s_currency: {
      width: "8vw",
      height: "100%",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "1em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
      "&:focus-visible": {
        outline: "0px",
      },
    },
    required: {
      color: "#ff0000",
    },
    prl_input_arrow: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "2em",
      height: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      marginTop: "0.2em",
      marginRight: "0.2em",
      pointerEvents: "none",
    },
    prl_input_arrow_currency: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "2em",
      height: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      marginTop: "0.2em",
      marginRight: "0.2em",
      pointerEvents: "none",
    },
    Contract_payInfo_txt: {
      color: "#333333",
      fontWeight: 600,
      fontSize: "1vw",
      fontFamily: "Inter, sans-serif",
      marginTop: "2vw",
      marginBottom: "1.5vw",
    },
    countC: {
      border: "0px",
      fontSize: "0.8vw",
      textAlign: "center",
      width: "100%",
      "&:focus-visible": {
        outline: "0px",
      },
    },
    addRmBtnC: {
      border: "0px",
      width: "3vw",
      fontSize: "1.5vw",
      background: "transparent",
      fontWeight: "normal",
      color: "#14292d",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    countCC: {
      border: "0.5px solid #c7c7c7",
      borderRadius: "0.25vw",
      height: "3vw",
      display: "flex",
      // width: '6vw',
      justifyContent: "space-between",
      backgroundColor: "#fff",
      marginRight: "0.7vw",
    },
    contract_scroll_C: {
      background: "#ffffff",
    },
    contract_scroll_header: {
      background: "#14292d",
      borderRadius: "0.25vw",
      display: "flex",
      paddingTop: "1vw",
      paddingBottom: "1vw",
    },
    contract_scroll_head_p1: {
      flex: 3,
      textAlign: "center",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      color: "#ffffff",
      margin: "0px",
    },
    contract_scroll_head_p2: {
      flex: 7,
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      color: "#ffffff",
      margin: "0px",
    },
    contract_sroll_item_c: {
      display: "flex",
      paddingTop: "0.8vw",
      paddingBottom: "0.8vw",
    },
    contract_sroll_item_p1: {
      flex: 3,
      textAlign: "center",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85vw",
    },
    contract_sroll_item_p2_c: {
      flex: 3,
    },
    contract_sroll_item_p2: {
      paddingLeft: "1vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85vw",
    },
    contract_sroll_item_input: {
      flex: 4,
      paddingLeft: "0.8vw",
      border: "0.5px solid #c7c7c7",
      borderRadius: "0.25vw",
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.75vw",
      "&:focus-visible": {
        outline: "0px",
      },
    },
    contract_send_info_btn: {
      backgroundColor: "#ff0000",
      marginTop: "1.5em",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      color: "#ffffff",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.85em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    ErrMessage: {
      marginTop: "1.5em",
      marginLeft: "1.5em",
      color: "#ff0000",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.85em",
      alignSelf: "center",
    },
  })
);

interface Props {
  startTime: any;
  endTime: any;
  sendContract: (
    numOfPayments: number,
    totClasses: number,
    contrType: string,
    paymCondition: string,
    distrMethod: string,
    currType: string,
    timeslot: string[]
  ) => void;
  from?: any;
  to?: any;
  contractTypeData: any[];
  paymentConditionData: any[];
  distributionMethodData: any[];
  currencyTypeData: any[];
  proposed_budget: number;
}

const ContractInfoEdit = ({
  startTime,
  endTime,
  sendContract,
  from,
  to,
  contractTypeData,
  paymentConditionData,
  distributionMethodData,
  currencyTypeData,
  proposed_budget,
}: Props) => {
  const classes = useStyles();
  const [totClasses, setTotClasses] = useState<string>("1");
  const [noOfPayments, setNoOfPayments] = useState<string>("1");
  const [contrType, setContrType] = useState<string>("select");
  const [paymCondition, setPaymCondition] = useState<string>("select");
  const [distrMethod, setDistrMethod] = useState<string>("select");
  const [currType, setCurrType] = useState<string>("select");
  const [paymentAmount, setPaymentAmount] = useState<any[]>([proposed_budget]);
  const [validateAmount, setValidateAmount] = useState<"null" | "yes" | "more" | "less">("null");
  const [startT, setStartT] = useState("10:00$AM");
  const [endT, setEndT] = useState("11:00$AM");
  const [inputsERR, setInputsERR] = useState(false);

  const setTimers = (start: string, end: string) => {
    setStartT(start);
    setEndT(end);
    console.log("notaion", startT, endT);
  };

  const handleIncTotClasses = () => {
    if (totClasses !== "") {
      const inc = parseInt(totClasses) + 1;
      setTotClasses(inc.toString());
    }
  };
  const handleDecTotClasses = () => {
    if (totClasses !== "" && totClasses !== "0" && totClasses !== "1") {
      const inc = parseInt(totClasses) - 1;
      setTotClasses(inc.toString());
    }
  };
  const handleIncNoOfClasses = () => {
    if (noOfPayments !== "") {
      const inc = parseInt(noOfPayments) + 1;
      setNoOfPayments(inc.toString());
    }
  };
  const handleDecNoOfClasses = () => {
    if (noOfPayments !== "" && noOfPayments !== "0" && noOfPayments !== "1") {
      const inc = parseInt(noOfPayments) - 1;
      setNoOfPayments(inc.toString());
    }
  };

  const divideProposedBudget = (noOfPayments: number, proposedBudget: number): number[] => {
    const splitpaymentAmount = Math.round((proposedBudget / noOfPayments) * 100) / 100;
    return Array(noOfPayments).fill(splitpaymentAmount);
  };

  React.useEffect(() => {
    const payments = divideProposedBudget(parseInt(noOfPayments), proposed_budget);
    setPaymentAmount(payments);
    console.log("||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||", paymentAmount); // Output: ["0", "0", "0", "0"]
  }, [noOfPayments]);

  const validate = async (arr: string[]) => {
    let sum = 0;
    arr.forEach((num) => {
      sum += parseInt(num, 10);
    });
    if (sum == proposed_budget) {
      setValidateAmount("yes");
    } else if (sum > proposed_budget) {
      setValidateAmount("more");
    } else if (sum < proposed_budget) {
      setValidateAmount("less");
    }
  };

  return (
    <div className={classes.Contract_container}>
      <div className={classes.prl_input_cc}>
        <div className={classes.prl_input_left}>
          <label className={classes.prl_input_lable}>Class Timings</label>
          <Timers startTime={startTime} endTime={endTime} setTimers={setTimers} />
        </div>
        <div className={classes.prl_input_right}>
          <label className={classes.prl_input_lable}>Contract Type</label>
          <div className={classes.prl_input_dd}>
            <select
              className={classes.prl_input_dd_s}
              value={contrType}
              onChange={(e) => {
                setContrType(e.target.value);
              }}
            >
              <option value="select">select</option>
              {contractTypeData.map((val, key) => {
                return <option value={val.name} key={key}>{val.name}</option>;
              })}
            </select>
            <span className={classes.prl_input_arrow}>
              <img src={dropdown} alt="arrow" />
            </span>
          </div>
        </div>
      </div>
      <p className={classes.Contract_payInfo_txt}>Payment Info</p>
      <div className={classes.prl_input_cc}>
        <div className={classes.prl_input_left}>
          <label className={classes.prl_input_lable}>Payment Condition</label>
          <div className={classes.prl_input_dd}>
            <select
              className={classes.prl_input_dd_s}
              value={paymCondition}
              onChange={(e) => {
                setPaymCondition(e.target.value);
              }}
            >
              <option value="select">select</option>
              {paymentConditionData.map((val, key) => {
                return (
                  <option value={val.name} data-key="Empty"key={key}>
                    {val.name}
                  </option>
                );
              })}
            </select>
            <span className={classes.prl_input_arrow}>
              <img src={dropdown} alt="arrow" />
            </span>
          </div>
        </div>
        <div className={classes.prl_input_right}>
          <label className={classes.prl_input_lable}>Distribution Method</label>
          <div className={classes.prl_input_dd}>
            <select
              className={classes.prl_input_dd_s}
              value={distrMethod}
              onChange={(e) => {
                setDistrMethod(e.target.value);
              }}
            >
              <option value="select">select</option>
              {distributionMethodData.map((val, key) => {
                return (
                  <option value={val.name} key={key}>
                    {val.name}
                  </option>
                );
              })}
            </select>
            <span className={classes.prl_input_arrow}>
              <img src={dropdown} alt="arrow" />
            </span>
          </div>
        </div>
      </div>
      <p className={classes.Contract_payInfo_txt}>Payment Structure</p>
      <div className={classes.prl_input_cc}>
        <div className={classes.prl_input_left_Count_c}>
          <div>
            <label className={classes.prl_input_lable}>Total Classes</label>
            <div className={classes.countCC}>
              <button
                className={classes.addRmBtnC}
                onClick={handleDecTotClasses}
                data-testid="handleDecTotClassesBtn"
              >
                <p style={{ width: "2.5em" }}>-</p>
              </button>
              <input
                name="startTime"
                type="text"
                pattern="[0-9]*"
                className={classes.countC}
                value={totClasses}
                onChange={async (e) => {
                  const re = /^[0-9\b]+$/;
                  console.log(re.test(e.target.value));
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (parseInt(e.target.value) !== 0) {
                      setTotClasses(e.target.value);
                    } else {
                      setTotClasses("1");
                    }
                  }
                }}
                // tabIndex={3}
                // maxLength={2}
                // onKeyUp={(e) => inputfocus(e)}
                data-testid="TotalClassesInputComp"
              />
              <button
                className={classes.addRmBtnC}
                onClick={handleIncTotClasses}
                data-testid="handleIncTotClassesBtn"
              >
                <p style={{ width: "2.5em" }}>+</p>
              </button>
            </div>
          </div>
          <div>
            <label className={classes.prl_input_lable}>No: of Payments</label>

            <div className={classes.countCC}>
              <button
                className={classes.addRmBtnC}
                style={{}}
                onClick={handleDecNoOfClasses}
                data-testid="handleDecNoOfClassesBtn"
              >
                <p style={{ width: "2.5em" }}>-</p>
              </button>
              <input
                name="startTime"
                type="text"
                autoComplete="off"
                className={classes.countC}
                value={noOfPayments}
                onChange={async (e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (parseInt(e.target.value) !== 0) {
                      setNoOfPayments(e.target.value);
                    } else {
                      setNoOfPayments("1");
                    }
                  }
                }}
                // tabIndex={3}
                // maxLength={2}
                // onKeyUp={(e) => inputfocus(e)}
                data-testid="numberOfPaymentInputComp"
              />
              <button
                className={classes.addRmBtnC}
                onClick={handleIncNoOfClasses}
                data-testid="handleIncNoOfClassesBtn"
              >
                <p style={{ width: "2.5em" }}>+</p>
              </button>
            </div>
          </div>
          <div>
            <label className={classes.prl_input_lable}>Currency</label>
            <div className={classes.prl_input_dd_currency}>
              <select
                className={classes.prl_input_dd_s_currency}
                value={currType}
                onChange={(e) => {
                  setCurrType(e.target.value);
                }}
              >
                <option value="select">select</option>
                {currencyTypeData.map((val, key) => {
                  return <option value={val.name} key={key}>{val.name}</option>;
                })}
              </select>
              <span className={classes.prl_input_arrow_currency}>
                <img src={dropdown} alt="arrow" />
              </span>
            </div>
          </div>
        </div>
        <div className={classes.prl_input_right} />
      </div>
      <div className={classes.contract_scroll_C}>
        <div className={classes.contract_scroll_header}>
          <p className={classes.contract_scroll_head_p1}>Payment No:</p>
          <p className={classes.contract_scroll_head_p2}>Payment</p>
        </div>
        <div>
          {paymentAmount.map((val, index) => {
            return (
              <div key={index}>
                <div className={classes.contract_sroll_item_c}>
                  <p className={classes.contract_sroll_item_p1}>PN00{index + 1}</p>
                  <input
                    type="number"
                    className={classes.contract_sroll_item_input}
                    value={paymentAmount[index]}
                    // onChange={async (e) => {
                    //   const arrAmount = paymentAmount;
                    //   arrAmount[index] = e.target.value;
                    //   setPaymentAmount(arrAmount);
                    //   console.log("arr", paymentAmount);
                    //   console.log("arrrrrrrr", arrAmount);
                    //   console.log("arrrrrrrr", arrAmount[index]);
                    //   validate(paymentAmount);
                    // }}
                    disabled
                  />
                  <div className={classes.contract_sroll_item_p2_c}>
                    <p className={classes.contract_sroll_item_p2}>₹</p>
                  </div>
                </div>
                {index !== paymentAmount.length - 1 && (
                  <div
                    style={{ marginLeft: "2em", marginRight: "2em", borderBottom: "0.5px solid #c7c7c7" }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button
          variant="contained"
          className={classes.contract_send_info_btn}
          onClick={async () => {
            await validate(paymentAmount);
            if (
              contrType == "select" ||
              paymCondition == "select" ||
              distrMethod == "select" ||
              currType == "select"
            ) {
              console.log("errrrrr", contrType, paymCondition, distrMethod, currType);
              await setInputsERR(true);
            } else {
              console.log("yyyyyy", contrType, paymCondition, distrMethod, currType);
              await setInputsERR(false);
            }

            if (validateAmount == "yes") {
              if (
                parseInt(noOfPayments) > 0 &&
                parseInt(totClasses) > 0 &&
                currType.length > 0 &&
                paymCondition.length > 0 &&
                distrMethod.length > 0 &&
                currType.length > 0 &&
                contrType != "select" &&
                paymCondition != "select" &&
                distrMethod != "select" &&
                currType != "select" &&
                !inputsERR
              ) {
                const timeslot = [startT, endT];
                sendContract(
                  parseInt(noOfPayments),
                  parseInt(totClasses),
                  contrType,
                  paymCondition,
                  distrMethod,
                  currType,
                  timeslot
                );
              }
            }
          }}
          data-testid="sendContractBtn"
        >
          Send Contract Info to Candidate
        </Button>
        {validateAmount != "null" && validateAmount != "yes" ? (
          <p className={classes.ErrMessage}>
            Amount is {validateAmount == "more" && "more"} {validateAmount == "less" && "less"} than{" "}
            {proposed_budget}
          </p>
        ) : null}
        {inputsERR && <p className={classes.ErrMessage}>{"     "}Fill All the Madatory Fields</p>}
      </div>
    </div>
  );
};

export default ContractInfoEdit;
