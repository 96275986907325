export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const laptop = require("../assets/laptop.png");
export const boygirl = require("../assets/boygirl.png");
export const youngboy = require("../assets/youngboy.png");
export const freetag = require("../assets/freetag.png");
export const calender = require("../assets/calender.png");
export const twomembers = require("../assets/twomembers.png");
export const smallgirl = require("../assets/smallgirl.png");
export const book = require("../assets/book.png");
export const girllaptop = require("../assets/girllaptop.png");
export const boylaptop = require("../assets/boylaptop.png");
export const boyphone = require("../assets/boyphone.png");
export const dropdown = require("../assets/dropdown.png");
export const success = require("../assets/success.png");
export const fileUploadIcon = require("../assets/fileUploadIcon.png");
export const Logo = require("../assets/Logo.png");
export const cart = require("../assets/cart.png");
export const search = require("../assets/search.png");
export const email = require("../assets/email.png");
export const phone = require("../assets/phone.png");
export const google = require("../assets/google.png");
export const twitter = require("../assets/twitter.png");
export const fb = require("../assets/fb.png");
export const linkedin = require("../assets/linkedin.png");
export const defaultProfilePic = require("../assets/defaultProfilePic.png");
//export const time = require("../assets/time.png")
export const signupbg = require("../assets/signupBG.png");

export const searchRed = require("../assets/searchRed.png");
// export const tickmark = require("../assets/tickmark.png");
