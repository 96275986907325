import React from "react";Checkbox
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography, Checkbox } from "@material-ui/core";
import { unCheckedTeach, checkedTeach} from "./assets";
import { dropdown, } from "../../email-account-registration/src/assets";
import ViewQuestionController, { Props } from "./ViewQuestionController.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import QuestionShow from "./QuestionShow.web";


const styles = (theme: Theme) =>
createStyles({
    root: {},

    redbutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      padding: "1.2em 4.5em 1.2em",
      color: "#fff",
      textTransform: "none",
      height: "80%",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    add_contract_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
      width: "85%",
      marginBottom:"25px"
    },
    buttonSection: {
      display: "flex",
      marginTop: "1.5em",
      justifyContent: "space-between",
      width: "85%",
      marginBottom: "10em",
    },
    CA_checkbox_container: {
      display: "flex",
      alignItems: "center",
      marginBottom: "1em",
    },
    CA_checkbox: {
      marginRight: "0.5em",
      "&.MuiIconButton-root": {
        padding: 0,
      },
    },
    CA_checkbox_Img: {
      width: "0.65em",
    },
    CA_Checkbox_label: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "1em",
    },
    questionContainer: {
      display: "flex",
      width: "85%",
      backgroundColor: "#F7F7F7",
      flexDirection: "column",
      marignTop: "2em",
      padding: "2em 2em",
      marginBottom: "4em",
      borderRadius:"10px"
    },
    question: {
      color: "#14292D",
      fontSize: 20,
      fontWeight: 550,
      marginBottom: "1em",
    },
    sectionName: {
      color: "#14292D",
      fontSize: 22,
      fontWeight: 550,
      marginTop: "2em",
      marginBottom: "2em",
    },
    CA_Dropdown_C: {
      position: "relative",
      width: "60%",
      marginTop: "1em",
      marginBottom: "2em",
    },
    CA_Dropdown_select: {
      width: "100%",
      height: "3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
    },
    CA_Dropdown_select_arrow: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "2em",
      height: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      marginTop: "0.2em",
      marginRight: "0.2em",
      pointerEvents: "none",
    },
    MC_Question_input: {
      width: "100%",
      boxSizing: "border-box",
      color: "#000000",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.3em",
      paddingTop: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.4em",
      "&:focus::placeholder": {
        color: "transparent",
      },
    },
    textArea: {
      position: "relative",
      width: "80%",
      marginBottom: "1em",
    },

  });


  class ViewQuestion extends ViewQuestionController {
    constructor(props: Props) {
      super(props);
    }
    render() {
      const { classes } = this.props;
      return (
       // Customizable Area Start
      <div className={classes.root}>
      <TopNavigation
        navigation={this.props.navigation}
        history={this.props.history}
        location={this.props.location}
      />
      {/* Banner */}
      <div style={{ marginLeft: "210px" }}>

        <div>
          <Typography style={{ color: "#14292D", fontSize: 26, fontWeight: 550, marginTop: "20px",marginBottom:"13px" }}>Subject - {this.state.assessmentViewQuestion.name}</Typography>
          <div className={classes.add_contract_Hr} />
        </div>
        <div className={classes.questionContainer}>
          <div style={{ marginLeft: "2em", padding: "1em", }}>


          <QuestionShow questionData = {this.state.assessmentViewQuestion?.questions?.data || []} activeQuestiionIndex={0} />

            {/* {
              this.state.assessmentViewQuestion?.questions['data'].attributes?.question_type == "mcq" ?
                (<>
                  {this.state.assessmentViewQuestion?.questions?.data.map((option: any, index: any) => {
                    return (
                     
                      <div className={classes.CA_checkbox_container} key={index}>
                        <Typography className={classes.question}>

                          {this.state.assessmentViewQuestion?.questions?.data?.attributes?.description}
                        </Typography>

                        <Checkbox
                          checked={this.state.checked[index]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img} />}
                          onClick={() => {
                            this.checkedHandler(index);
                          }}
                          className={classes.CA_checkbox}
                        />
                        <Typography className={classes.CA_Checkbox_label}>
                          {option?.attributes?.question_type}
                        </Typography>
                      </div>
                    );
                  })}
                </>)
                :
                this.state.assessmentViewQuestion?.questions?.data?.attributes?.question_type == "drop_down"
                  ?
                  (<>
                    <Typography className={classes.question}>
                    {this.state.assessmentViewQuestion?.questions?.data?.attributes?.description}
                    </Typography>
                    <div className={classes.CA_Dropdown_C}>
                      <select
                        className={classes.CA_Dropdown_select}
                        value={this.state.dropDwnanswer}
                        onChange={(e: any) => {
                          this.handleDropDownChange(e)
                        }}
                      >
                        <option value={"select_below"}>Select below</option>
                        {this.state.assessmentViewQuestion?.questions?.data.map((option: any, index: any) => {
                          return (
                            <option key={index} value={option.id}>{option.description}</option>
                          );
                        })}

                      </select>
                      <span className={classes.CA_Dropdown_select_arrow}>
                        <img src={dropdown} alt="arrow" />
                      </span>
                    </div>
                  </>)
                  :
                  <>
                    <Typography className={classes.question}>
                      {this.state.assessmentViewQuestion?.questions?.data?.attributes?.description}
                    </Typography>
                    <div className={classes.textArea}>
                      <textarea
                        className={classes.MC_Question_input}
                        value={this.state.textAreaanswer}
                        // required
                        onChange={(e: any) => {
                          this.handletextAreaChange(e)
                        }}
                        placeholder="Type Question"
                        rows={5}
                      />
                    </div>
                  </>
            } */}
          </div>
        </div>
          <div className={classes.buttonSection}>
              <div>
                Question 1 of {this.state.assessmentViewQuestion?.questions?.data.length}
              </div>
              {/* button container */}
              <div style={{ display: "flex", }}>
                <div style={{ display: this.state.currentQuestion === 0 ? "none" : "block", }}>
                  <Button
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ffffff",
                      border: "1px solid red",
                      textTransform: "none",
                      padding: " 0.7em 3em 0.7em",
                      marginRight: "0.8em"

                    }}
                    variant="outlined"
                    onClick={this.handlePreviousButton}
                  >
                    <b>Previous</b>
                  </Button>
                </div>

                <Button
                      className={classes.redbutton}
                      variant="contained"
                      // onClick={this.handleNextQuestion}
                      // disabled={
                      //   this.state.previousSelectedAnswers[this.state.currentQuestion]?.isAnswered == undefined ? true : false}
                    >
                      Next
                </Button>
                {/* <div>
                  {this.state.currentQuestion === this.state.assesmentAttributes?.questions?.data.length - 1 ?
                    <Button
                      className={classes.redbutton}
                      variant="contained"
                      onClick={this.handleNextQuestion}
                      disabled={
                        this.state.previousSelectedAnswers[this.state.currentQuestion]?.isAnswered == undefined ? true : false}
                    >
                      Submit
                    </Button> :
                    <Button
                      className={classes.redbutton}
                      variant="contained"
                      onClick={this.handleNextQuestion}
                      disabled={
                        this.state.previousSelectedAnswers[this.state.currentQuestion]?.isAnswered == undefined ? true : false}
                    >
                      Next
                    </Button>}
                </div> */}

              </div>

            </div>
              

      </div>

      <BottomNavigation
        navigation={this.props.navigation}
        history={this.props.history}
        location={this.props.location}
      />
    </div>
    // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
export default withStyles(styles, { withTheme: true })(ViewQuestion);