import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentQuestion: any,
  questions: any,

  checked:boolean[],
  dropDwnanswer:any
  textAreaanswer:any,
  uploadFile:any,
  assesmentAttributes:any
  selectedAnswers:any
  previousSelectedAnswers:any
  assesmentSubmitMessage:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: any;
  assesmentCallId: string = "";
  submitAnswerCallId: string = "";
  assessmentSubmitId:string=""
  // Customizable Area End

  constructor(props: any) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      questions: [],
      currentQuestion: 0,
      checked:[false, false, false, false],
      dropDwnanswer:"",
      textAreaanswer:"",
      uploadFile:null,
      assesmentAttributes:{},
      selectedAnswers:[],
      previousSelectedAnswers:[],
      assesmentSubmitMessage:""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async componentDidMount() {
    // this.callGetValidationApi();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getAssesmentDetails()
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (this.assesmentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        alert(apiResponse?.errors)
      } else {
        this.setState({
          assesmentAttributes:apiResponse?.data?.attributes
        })

      }  
    }
    if (this.submitAnswerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if(apiResponse?.errors){
     alert(apiResponse?.errors)
      } else {
         console.log('Response for submitAnswerApi', apiResponse);
      }  
    }
    if (this.assessmentSubmitId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.errors){
      alert(apiResponse?.errors)
      } else {
        const status = apiResponse?.data?.attributes?.passing_status
        const assesmentStatus = apiResponse?.data?.attributes
        await localStorage.setItem("assesmentStatus", assesmentStatus);
        this.props.navigation.navigate("AssessResult", { "status":status })
      }  
    }
    
    // Customizable Area End
  }

 // Customizable Area Start



  checkedHandler = (index: number) => {
    const newChecked = [false, false, false, false];
    newChecked[index] = true; // toogle the checkbox

    if(this.state.currentQuestion>=this.state.previousSelectedAnswers.length){
    const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
    const  selectedAnswerObj  = currentQuestionObj?.attributes?.answer_options[index]
    const newObj = {
      currentQuestionObj:currentQuestionObj,
      selectedAnswerObj:selectedAnswerObj,
      selectedIndex:index,
      isAnswered:true,
      type:"mcq"
    }
     const newSelectedArray = [...this.state.previousSelectedAnswers,newObj]
     this.setState({previousSelectedAnswers:newSelectedArray})

    }else{

    const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
    const  selectedAnswerObj  = currentQuestionObj?.attributes?.answer_options[index]
    const newObj = {
      currentQuestionObj:currentQuestionObj,
      selectedAnswerObj:selectedAnswerObj,
      selectedIndex:index,
      isAnswered:true,
      type:"mcq"
    }

    this.setState({
      previousSelectedAnswers: [
         ...this.state.previousSelectedAnswers.slice(0,this.state.currentQuestion),
         Object.assign({}, this.state.previousSelectedAnswers[this.state.currentQuestion], newObj),
         ...this.state.previousSelectedAnswers.slice(this.state.currentQuestion+1)
      ]
    });



  }
   
       
    this.setState({ checked: newChecked });

  };

  handleDropDownChange= (e:any) => {
    this.setState({ dropDwnanswer: e.target.value });

    if(this.state.currentQuestion>=this.state.previousSelectedAnswers.length){
    const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
    const newObj = {
      currentQuestionObj:currentQuestionObj,
      currentId:e.target.value,
      isAnswered:true,
      type:"dropDown"
    }

    const newSelectedArray = [...this.state.previousSelectedAnswers,newObj]
    // Find selected answer
     this.setState({previousSelectedAnswers:newSelectedArray})}
     else{
      const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
      const newObj = {
        currentQuestionObj:currentQuestionObj,
        currentId:e.target.value,
        isAnswered:true,
        type:"dropDown"
      }
      this.setState({
        previousSelectedAnswers: [
           ...this.state.previousSelectedAnswers.slice(0,this.state.currentQuestion),
           Object.assign({}, this.state.previousSelectedAnswers[this.state.currentQuestion], newObj),
           ...this.state.previousSelectedAnswers.slice(this.state.currentQuestion+1)
        ]
      });

     }



  };
  handletextAreaChange= (e:any) => {
    this.setState({ textAreaanswer: e.target.value });
    
    if(e.target.value.length===1){
      const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
      const newObj = {
        currentQuestionObj:currentQuestionObj,
        currentValue:e.target.value,
        isAnswered:true,
        type:"descriptive"
      }
      const newSelectedArray = [...this.state.previousSelectedAnswers,newObj]
      // Find selected answer
       this.setState({previousSelectedAnswers:newSelectedArray})
    }
    else{
      const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
      const newObj = {
        currentQuestionObj:currentQuestionObj,
        currentValue:e.target.value,
        isAnswered:true,
        type:"descriptive"
      }
      

    this.setState({
      previousSelectedAnswers: [
         ...this.state.previousSelectedAnswers.slice(0,this.state.currentQuestion),
         Object.assign({}, this.state.previousSelectedAnswers[this.state.currentQuestion], newObj),
         ...this.state.previousSelectedAnswers.slice(this.state.currentQuestion+1)
      ]
    });


    }
   

  };

  handleFile = async (e: any) => {
    const files = e.target.files[0];
    this.setState({ uploadFile: files });
  };

  getAssesmentDetails=()=>{
    const parms = this.props.navigation.getParam('assessmentId');
    const endPoint = `${configJSON.assesmentEndPoint}/${parms}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:token
    };

    const assesmentDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assesmentCallId = assesmentDetailsRequestMessage.messageId;
   
    assesmentDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    assesmentDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    assesmentDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(assesmentDetailsRequestMessage.id, assesmentDetailsRequestMessage);
  }

  handleNextQuestion=async()=>{
  await this.handleSubmitAnswer()

   if (this.state.assesmentAttributes?.questions?.data.length  > this.state.currentQuestion +1 ) {
    await this.setState({currentQuestion:this.state.currentQuestion + 1})
   }else
   {
   this.handleOnAssesmentSubmit()
   }
 

   if(this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion ]?.attributes?.question_type == "mcq"){
   
    if(this.state.previousSelectedAnswers.length>this.state.currentQuestion || this.state.assesmentAttributes?.questions?.data.length == this.state.previousSelectedAnswers.length){
      const value = this.state.previousSelectedAnswers[this.state.currentQuestion].selectedIndex
      const newChecked = [false, false, false, false];
      newChecked[value] = true; // toogle the checkbox
      this.setState({ checked: newChecked }); 
    }else{
    const IntialValues = [false, false, false, false];
    this.setState({ checked: IntialValues });
    }
  }else if(this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion  ]?.attributes?.question_type == "drop_down"){
    if(this.state.previousSelectedAnswers.length>this.state.currentQuestion || this.state.assesmentAttributes?.questions?.data.length == this.state.previousSelectedAnswers.length){

    const value = this.state.previousSelectedAnswers[this.state.currentQuestion ].currentId
    this.setState({ dropDwnanswer: value });}
    else{
      this.setState({ dropDwnanswer: "select" })
    }
  }else{
    if(this.state.previousSelectedAnswers.length>this.state.currentQuestion || this.state.assesmentAttributes?.questions?.data.length == this.state.previousSelectedAnswers.length){
      const value = this.state.previousSelectedAnswers[this.state.currentQuestion].currentValue
      this.setState({ textAreaanswer: value });
    }
      else{
        this.setState({ textAreaanswer: "" });
      }
  }


  
 }

  handlePreviousButton=async()=>{

    if(this.state.currentQuestion >= 0){ 
      this.setState({currentQuestion:this.state.currentQuestion -1}) 
    }
    
    if(this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion - 1 ]?.attributes?.question_type == "mcq"){
      if(this.state.previousSelectedAnswers.length>=this.state.currentQuestion){
        const value = this.state.previousSelectedAnswers[this.state.currentQuestion - 1].selectedIndex
        const newChecked = [false, false, false, false];
        newChecked[value] = true; // toogle the checkbox
        this.setState({ checked: newChecked }); 
      }else{
      const IntialValues = [false, false, false, false];
      this.setState({ checked: IntialValues });
      }
    }else if(this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion -1 ]?.attributes?.question_type == "drop_down"){
     
      if(this.state.previousSelectedAnswers.length>=this.state.currentQuestion){
  
      const value = this.state.previousSelectedAnswers[this.state.currentQuestion - 1].currentId
      this.setState({ dropDwnanswer: value });}
      else{
        this.setState({ dropDwnanswer: "select" })
      }
    }else{
      if(this.state.previousSelectedAnswers.length>=this.state.currentQuestion){
        const value = this.state.previousSelectedAnswers[this.state.currentQuestion -1].currentValue
        this.setState({ textAreaanswer: value });
      }
        else{
          this.setState({ textAreaanswer: "" });
        }
    }

  }

  handleSubmitAnswer=async()=>{
  const formData = new FormData();
  const  assessmentId  = this.state.assesmentAttributes?.id
  const currentQuestionObj =   this.state.assesmentAttributes?.questions?.data[this.state.currentQuestion]
  const selectedCurrentObject = this.state.previousSelectedAnswers[this.state.currentQuestion]
  const  questionId  = currentQuestionObj?.attributes?.id

      formData.append("assessment_id", assessmentId)
      formData.append("question_id", questionId)

  if(selectedCurrentObject.type=="mcq"){
   const selcectedOption = currentQuestionObj?.attributes?.answer_options[selectedCurrentObject.selectedIndex]
   const selectedOptionId =selcectedOption?.id

   const obtainedMarks  = selcectedOption.is_correct?currentQuestionObj?.attributes?.total_marks:0

   formData.append("obtain_marks",obtainedMarks)
   formData.append("selected_option_id",selectedOptionId)
  }
  else if(selectedCurrentObject.type=="dropDown"){
    const selectedOptionId  = selectedCurrentObject.currentId
    const answerOptions =selectedCurrentObject.currentQuestionObj?.attributes?.answer_options
    const selectedAnswerObject = answerOptions.find((obj:any) => obj.id === Number(selectedOptionId))  
    const obtainedMarks  = selectedAnswerObject.is_correct?selectedCurrentObject.currentQuestionObj?.attributes?.total_marks:0

    formData.append("obtain_marks",obtainedMarks)
    formData.append("selected_option_id",selectedOptionId)
  }
  else{
    const descriptiveValue = selectedCurrentObject.currentValue
    formData.append("descriptive_answer",descriptiveValue)
  }
  

    const token = localStorage.getItem("loginT");
    const headers = {
      token:token
    };

     const submitAnswerDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitAnswerCallId = submitAnswerDetailsRequestMessage.messageId;
   
    submitAnswerDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitAnswer
    );

    submitAnswerDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    submitAnswerDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    submitAnswerDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(submitAnswerDetailsRequestMessage.id, submitAnswerDetailsRequestMessage);

  }

  handleOnAssesmentSubmit=()=>{
      const  assessmentId  = this.state.assesmentAttributes?.id
  
      const formData = new FormData();
      formData.append("assessment_id", assessmentId)

      const token = localStorage.getItem("loginT");
      const headers = {
        token:token
      };
  
       const submitAssesmentRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.assessmentSubmitId = submitAssesmentRequestMessage.messageId;
     
      submitAssesmentRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.submitAssesment
      );
  
      submitAssesmentRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      submitAssesmentRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      submitAssesmentRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
  
      runEngine.sendMessage(submitAssesmentRequestMessage.id, submitAssesmentRequestMessage);
  }

    // Customizable Area End

}
