import React from "react";
import Button from "@material-ui/core/Button";
import { download } from "./assets";
import Typography from "@material-ui/core/Typography";
import ReactPlayerLearning from "./ReactPlayerLearning";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "2em",
      paddingBottom: "2em",
      paddingLeft: "10em",
      paddingRight: "10em",
    },

    cardButton: {
      marginBottom: 25,
      borderRadius: 100,
      textTransform: "none",
      fontWeight: 700,
      fontSize: "0.8vw",
      width: "15vw",
      height: "3vw",
      marginLeft: "20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        width: 130,
        height: 30,
      },
    },
    cancelbutton: {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "15vw",
      fontWeight: 700,
      fontSize: "0.8vw",
      border: "1px solid red",
      textTransform: "none",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      height: "3vw",
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    submitbutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "12vw",
      // marginLeft: "515px",
      color: "#fff",
      textTransform: "none",
      height: "3vw",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    download: {
      borderRadius: "2vw",
      backgroundColor: "#ff0000",
      width: "12vw",
      marginLeft: "10px",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    Image: {
      width: "1200px",
      height: "600px",
    },
    Imagetext: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontweight: "400",
      fontSize: "1vw",
      lineHeight: "38px",
      color: "#181617",
      marginTop: "4vw",
    },

    RelatedCourseCard: {
      width: 287,
      height: "100%",
    },
    add_contract_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
    },
  });

import StartLearningController, { Props } from "./StartLearningController.web";
import ReadingProgress from "./ReactPdfViwer/ReadingProgress";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

export class StartLearning extends StartLearningController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
        <div className={classes.root}>
          <Typography style={{ fontSize: "2vw", fontWeight: 600 }}>{this.state.chapterData?.course_name}</Typography>
          <div className={classes.add_contract_Hr} />
          <div
            style={{
              display: "flex",
              marginBottom: "15px",
              marginTop: "2vw",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography style={{ fontSize: "1.3vw", fontWeight: 600 }}>
                {this.state.chapterData?.serial_number}, {this.state.chapterData?.title} - {this.state.chapterData?.duration}
              </Typography>
            </div>
            <div>
              <Button className={classes.download} variant="contained" onClick={this.DownloadChapterFile}>
                <img style={{ width: "1vw", height: "1vw" }} src={download} alt="" />
                &nbsp;
                <Typography style={{ fontSize: "0.8vw" }}>DownLoad {this.state.chapterData?.content_type === 'video' && 'Video'}{this.state.chapterData?.content_type === 'pdf' && 'PDF'}</Typography>
              </Button>
            </div>
          </div>
          <div>
            {this.state.chapterData?.content_type === 'video' && <div style={{ marginTop: "4vw", marginBottom: "4vw" }}>
              <ReactPlayerLearning urll={this.state.chapterData?.url} EndReached={this.EndReached} handleEnd={true} playBackFrom={this.state.chapterData.playback_from} />
            </div>}
            {this.state.chapterData?.content_type === 'pdf' && <div
              style={{
                maxWidth: "100%",
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "80vh",
                minHeight: '450px'
              }}
            >
              <ReadingProgress
                setPercent={this.setPercentage}
                fileUrl={this.state.chapterData?.url}
              />
            </div>}
          </div>
          <div>
            <Typography className={classes.Imagetext}>
              {this.state.chapterData?.description}
            </Typography>
          </div>
          <div style={{ display: "flex", marginTop: "2vw", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div>
                <Button className={classes.cancelbutton} variant="outlined"
                  onClick={() => {
                    this.props.navigation.navigate("CourseList", { page: 1 });
                  }}
                >
                  Back to Course Listing
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  style={{ background: "#f0d17c" }}
                  className={classes.cardButton}
                  onClick={() => {
                    this.props.navigation.navigate("CourseDetails", { courseId: this.state.courseId });
                  }}
                >
                  View Course Details
                </Button>
              </div>
            </div>

            <div>
              <Button className={classes.submitbutton} variant="contained" onClick={this.NextChapter} disabled={!this.state.is_readed}>
                Next
              </Button>
            </div>
          </div>
        </div>
        <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(StartLearning);
