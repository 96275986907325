import React from "react";
import { defaultProfilePic } from "../assets";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ButtonContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "0.7em",
    },
    WelcomeTxt: {
      color: "white",
      fontSize: "0.85vw",
      marginRight: "0.8vw",
      fontWeight: "normal",
      fontFamily: "Inter, sans-serif",
      textTransform: "none",
    },
    profileImg: {
      width: "3em",
      height: "3em",
      borderRadius: "10em",
      objectFit: 'cover'
    },
    profileArrowIcon: {
      width: 0,
      height: 0,
      borderLeft: "7px solid transparent",
      borderRight: "7px solid transparent",
      borderTop: "7px solid #f0d27c",
      marginLeft: "0.5em",
    },
    DropDownBtns: {
      color: "#fff",
      width: "100%",
      border: "0px",
      height: "3em",
      textAlign: "start",
      background: "#b30020",
      borderRadius: 0,
      "&:hover": {
        background: "#94000A",
      },
    },
  })
);

interface Props {
  handleLogout: () => Promise<void>;
  handleSettings: () => Promise<void>;
  handleProfile: () => Promise<void>;
  FName: string;
  profilePic: string | null;
}


const TopNavigationPoverMenu = ({ handleLogout, handleSettings, handleProfile, FName, profilePic }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div >
      <Button
        onClick={handleClick}
        aria-describedby={id}
        variant="text"
        className={classes.ButtonContainer}
        aria-controls="simple-menu"
        aria-haspopup="true"
        //   className="dropdown"
        data-testid="TN_PopoverDropdown"
      >
        <Typography className={classes.WelcomeTxt}>Welcome {FName}!</Typography>
        {profilePic ? <img src={profilePic} alt="ProfilePic" className={classes.profileImg} />
          :
          <img src={defaultProfilePic} alt="ProfilePic" className={classes.profileImg} />}
        <div className={classes.profileArrowIcon}></div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // container={anchorEl.parentNode}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ width: "100vw" }}
        disableScrollLock
      >
        <Button onClick={handleProfile} className={classes.DropDownBtns} data-testid="TN_profile" >
          Profile
        </Button>
        <Button onClick={handleSettings} className={classes.DropDownBtns} data-testid="TN_settings">
          Settings
        </Button>
        <Button onClick={handleLogout} className={classes.DropDownBtns} data-testid="TN_logout">
          Logout
        </Button>
      </Popover>
    </div>
  );
};

export default TopNavigationPoverMenu;

