import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");

export interface Props {
  history: any;
  location: any;
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
}

export interface S {
  // Customizable Area Start
  value: string;
  otp: string;
  errOTP: boolean;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  disable: boolean;
  errPin: boolean;
  phone: string;

  address: string;
  city: string;
  country: string;
  date_of_birth: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  gender: string;
  last_name: string;
  password: string;
  password_confirmation: string;
  role: string;
  state: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OtpAuthController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createAccountApiCallId: any;
  resendSignUpCallId: any;
  validationApiCallId: string = "";
  apiOtpCallId: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      value: "",
      otp: "",
      errOTP: false,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      disable: true,
      errPin: false,
      phone: "",
      address: "",
      city: "",
      country: "",
      date_of_birth: "",
      email: "",
      first_name: "",
      full_phone_number: "",
      gender: "",
      last_name: "",
      password: "",
      password_confirmation: "",
      role: "",
      state: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiOtpCallId) {
          if (!responseJson.errors && !responseJson.error) {
            this.setState({ errPin: false, errOTP: false });
            console.log("Otp_Response", responseJson);
            await localStorage.setItem("CType", JSON.stringify(responseJson.data.attributes.user_role));
            await localStorage.setItem("SignUpToken", JSON.stringify(responseJson.meta.token));
            this.props.navigation.navigate("OtpSuccess");
          } else {
            console.log("ERR_Otp_Response", responseJson);
            if (responseJson.errors[0].pin == "Invalid Pin for Phone Number") {
              await this.setState({ errPin: true, errOTP: true });
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.resendSignUpCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("Resend_SignUp_Response", responseJson);
            await localStorage.setItem("SignUpToken", JSON.stringify(responseJson.meta.token));
          } else {
            console.log("ERR_Resend_Otp_Response", responseJson);
            if (responseJson.error) {
              alert(responseJson.error);
            }
            if (responseJson.errors[0].account) {
              if (
                responseJson.errors[0].account ==
                "Account already exists, Verification code has been sent on your phone number, Please verify your phone number number to activate your account"
              ) {
                await localStorage.setItem("SignUpToken", JSON.stringify(responseJson.data.meta.token));
                alert("OTP has been sent your phone.");
              } else if (
                responseJson.errors[0].account == "Account already registerd with entered mobile number"
              ) {
                alert(responseJson.errors[0].account);
              }
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  async componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const uDetails: any = JSON.parse(localStorage.getItem("SignUpUserDetails") || "{}");
    await this.setState({
      phone: uDetails.phone,
      address: uDetails.address,
      city: uDetails.city,
      country: uDetails.country,
      date_of_birth: uDetails.date_of_birth,
      email: uDetails.email,
      first_name: uDetails.first_name,
      full_phone_number: uDetails.full_phone_number,
      gender: uDetails.gender,
      last_name: uDetails.last_name,
      password: uDetails.password,
      password_confirmation: uDetails.password_confirmation,
      role: uDetails.role,
      state: uDetails.state,
    });
  }

  handleOTPChange = async (otp: string) => {
    await this.setState({ otp });
  };

  handleBackToProfile = () => {
    this.props.navigation.navigate("SignUp");
  };

  handleSubmit = async () => {
    if (this.state.otp.length < 4) {
      alert("Enter OTP");
    } else if (this.state.otp.length === 4) {
      const UDetails: any = JSON.parse(localStorage.getItem("SignUpToken") || "{}");
      const token = UDetails;
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: token,
      };

      const httpBody = {
        pin: this.state.otp,
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiOtpCallId = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.otpAPiEndPoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.signupAPiMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  handleResendOTP = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      full_phone_number: this.state.full_phone_number,
      gender: this.state.gender,
      role: this.state.role,
      date_of_birth: this.state.date_of_birth,
      country: this.state.country,
      city: this.state.city,
      state: this.state.state,
      address: this.state.address,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };

    const data = {
      type: "sms_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.resendSignUpCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.signupAPiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.signupAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
}
