import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  history?: any;
  location?: any;
  push?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  jobDetails:any
  isjobDetailsLoading:any
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class JobDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  jobDetailsCallId:any
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      jobDetails:[],
      isjobDetailsLoading:true
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {

    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getEachJobDetails()
    
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (this.jobDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          jobDetails: apiResponse?.data,
          isjobDetailsLoading:false
        })
      }

  }






  }


  getEachJobDetails=()=>{
    console.log("getEachJobDetailsgetEachJobDetailsgetEachJobDetails")
    const JobDetailsId = localStorage.getItem("jobdetialId");
    const endPoint = `${"view_job_details"}?hiring_request_id=${JobDetailsId}`;
      const token = localStorage.getItem("loginT");
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      const getEachJobDetails = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.jobDetailsCallId = getEachJobDetails.messageId;
  
  
      getEachJobDetails.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      );
  
      getEachJobDetails.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getEachJobDetails.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getHttpMethodType
      );
     console.log(getEachJobDetails,"getEachJobDetailsgetEachJobDetailsgetEachJobDetailsgetEachJobDetailsgetEachJobDetails")
      runEngine.sendMessage(getEachJobDetails.id, getEachJobDetails);
  
  }




    // Customizable Area End
  }

