import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography, Checkbox } from "@material-ui/core";
import { unCheckedTeach, checkedTeach, bin, fileUploadIcon, SuccessIcon } from "./assets";
import Modal from "@material-ui/core/Modal";
import CreateAssessmentController, { Props } from "./CreateAssessmentController.web";
import DashSidebar1 from "../../TeachersDashboard9/src/Components/DashSidebar1.web";
import DashSidebar2 from "../../TeachersDashboard9/src/Components/DashSidebar2.web";
import DashSidebar3 from "../../TeachersDashboard9/src/Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import { borderRadius } from "react-select/src/theme";

const styles = (theme: Theme) =>
  createStyles({
    CA_Container_CC_CreateAssess: {
      display: "flex",
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3em",
    },
    CA_LeftDashNav_CreateAssess: {
      flex: 2,
    },
    CA_RightDashNav_CreateAssess: {
      flex: 7.5,
      width: "100%",
      marginLeft: "2vw",
    },
    CA_Hr_CreateAssess: {
      borderBottom: "0.5px solid #e4e4e5",
      marginBottom: "2vw",
    },
    CA_Title_CreateAssess: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginBottom: "1vw",
    },
    CA_Add_btn_CreateAssess: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      textAlign: "center",
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "2em",
      paddingRight: "2em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    CA_Modal_C_CreateAssess: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    CA_Modal_CC_CreateAssess: {
      minWidth: "30em",
      border: "1px solid #979797",
      background: "#ffffff",
      borderRadius: "1em",
      padding: "3em",
    },
    CA_Modal_Hr_CreateAssess: {
      borderBottom: "0.5px solid #e4e4e5",
      marginTop: "1em",
      marginBottom: "2.5em",
    },
    CA_Modal_Title_CreateAssess: {
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.5em",
    },
    CA_Modal_TitleT_CreateAssess: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.25em",
    },
    CA_checkbox_C_CreateAssess: {
      marginTop: "1em",
    },
    CA_checkbox_container_CreateAssess: {
      display: "flex",
      alignItems: "center",
      marginBottom: "1em",
    },
    CA_checkbox_CreateAssess: {
      marginRight: "0.5em",
      "&.MuiIconButton-root": {
        padding: 0,
      },
    },
    CA_checkbox_Img_CreateAssess: {
      width: "0.65em",
    },
    CA_Checkbox_label_CreateAssess: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "1em",
    },
    CA_Modal_btn_c_CreateAssess: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: "2em",
    },
    CA_Modal_btn_1_CreateAssess: {
      width: "10em",
      borderRadius: "10em",
      backgroundColor: "#ff0000",
      color: "#ffffff",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      textAlign: "center",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    CA_Modal_btn_2_CreateAssess: {
      width: "10em",
      borderRadius: "10em",
      backgroundColor: "#f0d17c",
      color: "#181617",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "1em",
      textAlign: "center",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    MC_Question_input_CreateAssess: {
      //   height: "100%",
      width: "100%",
      resize:"none",
      // height: '3.5rem',
      boxSizing: "border-box",
      color: "#000000",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.3em",
      paddingTop: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.4em",
      //   background: "#f9f8fd",
      "&:focus::placeholder": {
        color: "transparent",
      },
    },
    MC_Question_input_label_CreateAssess: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.88em",
      lineHeight: "2em",
    },
    MC_Question_input_label2_CreateAssess: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.88em",
      lineHeight: "2em",
      marginTop: "1em",
    },
    MC_Option_C_CreateAssess: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginTop: "1em",
    },
    MC_Option_circle_CreateAssess: {
      width: "0.88em",
      height: "0.88em",
      border: "1px solid #14292d",
      background: "#ffffff",
      borderRadius: "10em",
      marginRight: "1.5em",
    },
    MC_Option_input_CreateAssess: {
      border: "0.5px solid #979797",
      //   background: "#f9f8fd",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.9em",
      color: "#000000",
      height: "3em",
      width: "65%",
      borderRadius: "0.3em",
      paddingLeft: "1em",
    },
    MC_option_img_CreateAssess: {
      width: "1.2em",
    },
    MC_option_img_btn_CreateAssess: {
      minWidth: 0,
      padding: 0,
      marginLeft: "1em",
      marginRight: "1em",
    },
    MC_option_btn_CreateAssess: {
      border: "2px solid #ff0000",
      borderRadius: "10em",
      textTransform: "none",
      textAlign: "center",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.1em",
      color: "#000",
    },
    MC_marks_input_CreateAssess: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.9em",
      border: "0.5px solid #979797",
      height: "3em",
      width: "67.1%",
      borderRadius: "0.3em",
      paddingLeft: "1em",
    },
    MC_Post_btn_CreateAssess: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      textAlign: "center",
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "9em",
      marginRight: "1em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    MC_Cancel_btn_CreateAssess: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      textAlign: "center",
      backgroundColor: "#f0d17c",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "2em",
      paddingRight: "2em",
      width: "9em",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    MC_Btn_CC_CreateAssess: {
      marginTop: "2em",
    },
    MC_Error_CreateAssess: {
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      margin: 0,
    },
    MC_input_file_c_CreateAssess: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      width: "65%",
    },
    MC_input_file_CreateAssess: {
      width: "65%",
      height: "3rem",
      boxSizing: "border-box",
      backgroundColor: "#eeeeee",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "0px",
      paddingLeft: "1.3em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
      display: "none",
      "&::-webkit-file-upload-button": {
        background: "transparent",
        border: "0px",
        color: "transparent",
        cursor: "pointer",
      },
    },
    MC_input_file_img_c_CreateAssess: {
      height: "3rem",
      width: "100%",
      boxSizing: "border-box",
      backgroundColor: "#eeeeee",
      fontSize: "0.8em",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "0px",
      paddingLeft: "1.3em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
    },
    MC_input_file_img_label_CreateAssess: {
      fontSize: "1em",
      marginLeft: "1em",
    },
    MC_Succ_C_CreateAssess: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "#f7f7f7",
      borderRadius: "1em",
      minHeight: "16em",
    },
    MC_Succ_img_CreateAssess: {
      width: "4em",
    },
    MC_Succ_txt_CreateAssess: {
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontSize: "1.9em",
      fontWeight: 600,
    },
    Post_btn_succ_CreateAssess: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      textAlign: "center",
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      marginRight: "1em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    Cancel_btn_succ_CreateAssess: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      textAlign: "center",
      backgroundColor: "#f0d17c",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
  });

class CreateAssessment extends CreateAssessmentController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.CA_Container_CC_CreateAssess}>
          <div className={classes.CA_LeftDashNav_CreateAssess}>
            <DashSidebar1
              active="MyClasses"
              styles={{ marginBottom: "1.5vw" }}
              navigation={this.props.navigation}
            />
            <DashSidebar2
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              onClick={() => {
                console.log("Next Class");
              }}
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashSidebar3
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
              onClick={() => { }}
            />
          </div>
          <div className={classes.CA_RightDashNav_CreateAssess}>
            <p className={classes.CA_Title_CreateAssess}data-testId="creatassessment">Create Assessment</p>
            <div className={classes.CA_Hr_CreateAssess} />
            {this.state.mode == "default" && (
              <div>
                <Button variant="contained" className={classes.CA_Add_btn_CreateAssess} onClick={this.handleOpen} data-testId="txtInputNextBtn">
                  Add Questions
                </Button>
                <Modal
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={classes.CA_Modal_C_CreateAssess}
                >
                  <div className={classes.CA_Modal_CC_CreateAssess}>
                    <Typography className={classes.CA_Modal_Title_CreateAssess}>Select Question Type</Typography>
                    <div className={classes.CA_Modal_Hr_CreateAssess} />
                    <Typography className={classes.CA_Modal_TitleT_CreateAssess}>Select Question Type</Typography>
                    <div className={classes.CA_checkbox_C_CreateAssess}>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[0]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(0);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Multiple Choice</Typography>
                        {/* {this.state.checked.indexOf(true)} */}
                      </div>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[1]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(1);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Dropdown</Typography>
                      </div>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[2]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(2);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Textbox/Descriptive</Typography>
                      </div>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[3]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(3);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Upload</Typography>
                      </div>
                    </div>
                    <div className={classes.CA_Modal_btn_c_CreateAssess}>
                      <Button
                        variant="contained"
                        className={classes.CA_Modal_btn_1_CreateAssess}
                        onClick={() => {
                          this.handleChooseQuestionType(this.state.checkedIndex);
                        }}
                      >
                        Continue
                      </Button>
                      <Button
                        onClick={this.handleClose}
                        variant="contained"
                        className={classes.CA_Modal_btn_2_CreateAssess}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            )}

            {/********************************************* MultipleChoice, Dropdown, Descriptive and Upload Questions *******************************************************************************/}
            {this.state.mode == "mcq" ||
              this.state.mode == "drop_down" ||
              this.state.mode == "descriptive" ||
              this.state.mode == "upload" ? (
              <div>
                <label className={classes.MC_Question_input_label_CreateAssess}>Question</label>
                <textarea
                  className={classes.MC_Question_input_CreateAssess}
                  value={this.state.question}
                  required
                  onChange={async (e) => {
                    await this.setState({ question: e.target.value });
                    console.log(this.state.question);
                  }}
                  placeholder="Type Question"
                  //   cols={10}
                  rows={6}
                // style={this.state.errFName ? this.errStyle : {}}
                />
                <div className={classes.MC_Question_input_label2_CreateAssess}>
                  {this.state.mode == "mcq" && <label>Options</label>}
                  {this.state.mode == "drop_down" && <label>Drop down Values</label>}
                  {this.state.mode == "descriptive" && <label>Textbox / Descriptive</label>}
                  {this.state.mode == "upload" && <label>Upload File</label>}
                </div>
                {this.state.mode == "mcq" || this.state.mode == "drop_down" ? (
                  <div>
                    <div className={classes.MC_Option_C_CreateAssess}>
                      {this.state.mode == "mcq" && <div className={classes.MC_Option_circle_CreateAssess} />}
                      <input
                        value={
                          this.state.mode == "mcq" ? this.state.MCoption1 : this.state.DDoption1
                        }
                        onChange={async (e) => {
                          if (this.state.mode == "mcq") {
                            await this.setState({ MCoption1: e.target.value });
                            console.log("mil rahe...",this.state.MCoption1);
                          } else {
                            await this.setState({ DDoption1: e.target.value });
                            console.log(this.state.DDoption1);
                          }
                        }}
                        placeholder={this.state.mode == "mcq" ? "Option" : "Value"}
                        className={classes.MC_Option_input_CreateAssess}
                      />
                      <Button variant="text" className={classes.MC_option_img_btn_CreateAssess}>
                        <img src={bin} alt="bin" className={classes.MC_option_img_CreateAssess} />
                      </Button>
                      <Button
                        variant="outlined"
                        className={classes.MC_option_btn_CreateAssess}
                        style={
                          this.state.MCchecked[0] || this.state.DDchecked[0]
                            ? { backgroundColor: "#ff0000", color: "#ffffff" }
                            : { backgroundColor: "#ffffff" }
                        }
                        onClick={() => {
                          if (this.state.mode == "mcq") {
                            this.MChandleCorrectCheck(0);
                          } else {
                            this.DDhandleCorrectCheck(0);
                          }
                        }}
                      >
                        Correct Answer
                      </Button>
                    </div>
                    <div className={classes.MC_Option_C_CreateAssess}>
                      {this.state.mode == "mcq" && <div className={classes.MC_Option_circle_CreateAssess} />}
                      <input
                        value={
                          this.state.mode == "mcq" ? this.state.MCoption2 : this.state.DDoption2
                        }
                        onChange={async (e) => {
                          if (this.state.mode == "mcq") {
                            await this.setState({ MCoption2: e.target.value });
                            console.log("2no. mila",this.state.MCoption2);
                          } else {
                            await this.setState({ DDoption2: e.target.value });
                            console.log(this.state.DDoption2);
                          }
                        }}
                        placeholder={this.state.mode == "mcq" ? "Option" : "Value"}
                        className={classes.MC_Option_input_CreateAssess}
                      />
                      <Button variant="text" className={classes.MC_option_img_btn_CreateAssess}>
                        <img src={bin} alt="bin" className={classes.MC_option_img_CreateAssess} />
                      </Button>
                      <Button
                        variant="outlined"
                        className={classes.MC_option_btn_CreateAssess}
                        style={
                          this.state.MCchecked[1] || this.state.DDchecked[1]
                            ? { backgroundColor: "#ff0000", color: "#ffffff" }
                            : { backgroundColor: "#ffffff" }
                        }
                        onClick={() => {
                          if (this.state.mode == "mcq") {
                            this.MChandleCorrectCheck(1);
                          } else {
                            this.DDhandleCorrectCheck(1);
                          }
                        }}
                      >
                        Correct Answer
                      </Button>
                    </div>
                    <div className={classes.MC_Option_C_CreateAssess}>
                      {this.state.mode == "mcq" && <div className={classes.MC_Option_circle_CreateAssess} />}
                      <input
                        value={
                          this.state.mode == "mcq" ? this.state.MCoption3 : this.state.DDoption3
                        }
                        onChange={async (e) => {
                          if (this.state.mode == "mcq") {
                            await this.setState({ MCoption3: e.target.value });
                            console.log(this.state.MCoption3);
                          } else {
                            await this.setState({ DDoption3: e.target.value });
                            console.log(this.state.DDoption3);
                          }
                        }}
                        placeholder={this.state.mode == "mcq" ? "Option" : "Value"}
                        className={classes.MC_Option_input_CreateAssess}
                      />
                      <Button variant="text" className={classes.MC_option_img_btn_CreateAssess}>
                        <img src={bin} alt="bin" className={classes.MC_option_img_CreateAssess} />
                      </Button>
                      <Button
                        variant="outlined"
                        className={classes.MC_option_btn_CreateAssess}
                        style={
                          this.state.MCchecked[2] || this.state.DDchecked[2]
                            ? { backgroundColor: "#ff0000", color: "#ffffff" }
                            : { backgroundColor: "#ffffff" }
                        }
                        onClick={() => {
                          if (this.state.mode == "mcq") {
                            this.MChandleCorrectCheck(2);
                          } else {
                            this.DDhandleCorrectCheck(2);
                          }
                        }}
                      >
                        Correct Answer
                      </Button>
                    </div>
                    <div className={classes.MC_Option_C_CreateAssess}>
                      {this.state.mode == "mcq" && <div className={classes.MC_Option_circle_CreateAssess} />}
                      <input
                        value={
                          this.state.mode == "mcq" ? this.state.MCoption4 : this.state.DDoption4
                        }
                        onChange={async (e) => {
                          if (this.state.mode == "mcq") {
                            await this.setState({ MCoption4: e.target.value });
                            console.log(this.state.MCoption4);
                          } else {
                            await this.setState({ DDoption4: e.target.value });
                            console.log(this.state.DDoption4);
                          }
                        }}
                        placeholder={this.state.mode == "mcq" ? "Option" : "Value"}
                        className={classes.MC_Option_input_CreateAssess}
                      />
                      <Button variant="text" className={classes.MC_option_img_btn_CreateAssess}>
                        <img src={bin} alt="bin" className={classes.MC_option_img_CreateAssess} />
                      </Button>
                      <Button
                        variant="outlined"
                        className={classes.MC_option_btn_CreateAssess}
                        style={
                          this.state.MCchecked[3] || this.state.DDchecked[3]
                            ? { backgroundColor: "#ff0000", color: "#ffffff" }
                            : { backgroundColor: "#ffffff" }
                        }
                        onClick={() => {
                          if (this.state.mode == "mcq") {
                            this.MChandleCorrectCheck(3);
                          } else {
                            this.DDhandleCorrectCheck(3);
                          }
                        }}
                      >
                        Correct Answer
                      </Button>
                    </div>
                  </div>
                ) : null}

                {this.state.mode == "descriptive" && (
                  <textarea
                    className={classes.MC_Question_input_CreateAssess}
                    value={this.state.Danswer}
                    required
                    onChange={async (e) => {
                      await this.setState({ Danswer: e.target.value });
                      console.log(this.state.Danswer);
                    }}
                    placeholder="Answer"
                    //   cols={10}
                    rows={5}
                  // style={this.state.errFName ? this.errStyle : {}}
                  />
                )}

                {this.state.mode == "upload" && (
                  <div className={classes.MC_input_file_c_CreateAssess}>
                    <input
                      type="file"
                      id="file"
                      accept=".doc,.docx,.pdf"
                      className={classes.MC_input_file_CreateAssess}
                      value=""
                      required
                      onChange={(e) => {
                        this.UFhandleFile(e);
                      }}
                      placeholder="Enter"
                    />
                    <label htmlFor="file" className={classes.MC_input_file_img_c_CreateAssess}>
                      <img src={fileUploadIcon} alt="file" />

                      {this.state.UFfile ? (
                        <label className={classes.MC_input_file_img_label_CreateAssess}>{this.state.UFfile.name}</label>
                      ) : (
                        <label className={classes.MC_input_file_img_label_CreateAssess}>Upload file</label>
                      )}
                    </label>
                  </div>
                )}

                <div style={{ display: "flex", flexDirection: "column", marginTop: "2em" }}>
                  <label className={classes.MC_Question_input_label_CreateAssess}>Marks</label>
                  <input
                    onChange={async (e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        await this.setState({ marks: e.target.value });
                        console.log(this.state.marks);
                      }
                    }}
                    value={this.state.marks}
                    className={classes.MC_marks_input_CreateAssess}
                    placeholder="Enter Marks"
                  />
                </div>
                {this.state.errPost ? (
                  <p className={classes.MC_Error_CreateAssess}>
                    <sup>*</sup>Note: you must fill in all the fields
                  </p>
                ) : null}
                <div className={classes.MC_Btn_CC_CreateAssess}>
                  <Button onClick={this.handleAssessmentPost} variant="contained" data-test-id="handleAssessmentButton" className={classes.MC_Post_btn_CreateAssess}>
                    Post
                  </Button>
                  <Button variant="contained" className={classes.MC_Cancel_btn_CreateAssess} onClick={this.handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            ) : null}

            {this.state.mode == "success" && (
              <div>
                <div className={classes.MC_Succ_C_CreateAssess}>
                  <img src={SuccessIcon} alt="" className={classes.MC_Succ_img_CreateAssess} />
                  <p className={classes.MC_Succ_txt_CreateAssess}>Question Posted Successfully</p>
                </div>
                <div className={classes.MC_Btn_CC_CreateAssess}>
                  <Button onClick={this.AddAnotherQuestionhandleOpen} variant="contained" className={classes.Post_btn_succ_CreateAssess}>
                    Add Another Question
                  </Button>
                  <Button onClick={this.ViewPostQuestionhandle} variant="contained" className={classes.Cancel_btn_succ_CreateAssess}>
                    View Posted Question
                  </Button>
                </div>
                <Modal
                  open={this.state.open}
                  onClose={this.handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={classes.CA_Modal_C_CreateAssess}
                >
                  <div className={classes.CA_Modal_CC_CreateAssess}>
                    <Typography className={classes.CA_Modal_Title_CreateAssess}>Select Question Type</Typography>
                    <div className={classes.CA_Modal_Hr_CreateAssess} />
                    <Typography className={classes.CA_Modal_TitleT_CreateAssess}>Select Question Type</Typography>
                    <div className={classes.CA_checkbox_C_CreateAssess}>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[0]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(0);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Multiple Choice</Typography>
                        {/* {this.state.checked.indexOf(true)} */}
                      </div>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[1]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(1);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Dropdown</Typography>
                      </div>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[2]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(2);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Textbox/Descriptive</Typography>
                      </div>
                      <div className={classes.CA_checkbox_container_CreateAssess}>
                        <Checkbox
                          checked={this.state.checked[3]}
                          icon={<img src={unCheckedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          checkedIcon={<img src={checkedTeach} alt="" className={classes.CA_checkbox_Img_CreateAssess} />}
                          onClick={() => {
                            this.checkedHandler(3);
                          }}
                          className={classes.CA_checkbox_CreateAssess}
                        />
                        <Typography className={classes.CA_Checkbox_label_CreateAssess}>Upload</Typography>
                      </div>
                    </div>
                    <div className={classes.CA_Modal_btn_c_CreateAssess}>
                      <Button
                        variant="contained"
                        className={classes.CA_Modal_btn_1_CreateAssess}
                        onClick={() => {
                          this.handleChooseQuestionType(this.state.checkedIndex);
                        }}
                      >
                        Continue
                      </Button>
                      <Button
                        onClick={this.handleClose}
                        variant="contained"
                        className={classes.CA_Modal_btn_2_CreateAssess}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Modal>
              </div>
            )}
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}



export default withStyles(styles, { withTheme: true })(CreateAssessment);
