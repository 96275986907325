Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.coursesListApiEndpoint = "courses";
exports.coursesDetailsApiEndpoint = "get_course";
exports.coursePreviewVideoApiEndpoint = "course_preview_video";
exports.popularCourseListApiEndpoint = "popular_courses";
exports.categoriesApiEndpoint = "categories/categories";
exports.ContactUsFormAPiEndPoint = "contacts";
exports.ContactUsHelpListApiEndPoint = "query_types";
exports.FAQListApiEndPoint = "contacts/frequently_asked_questions";
exports.ContactUsMapLocationApiEndPoint = "store_location_info";
exports.PostMethod = "POST";
//user details
exports.userDetailsApiEndpoint = "profile_details";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

// View Contract
exports.viewContractEndpoint = "view_contract";
// get contract types
exports.contractTypeEndpoint = "contract_type";
// get payment condition
exports.paymentConditionEndpoint = "payment_condition";
// get distribution method
exports.distributionMethodEndpoint = "distribution_method";
// add contract info end point
exports.addContractInfoEndpoint = "add_contract_info";
// update contract info end point
exports.updateContractInfoEndpoint = "update_contract";

// Customizable Area End
