import React from "react";
import TopNavigationController, { Props } from "./TopNavigationController.web";
import Button from "@material-ui/core/Button";
import { Logo, cart, search, defaultProfilePic } from "./assets";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect, Link } from "react-router-dom";
import TopNavigationPoverMenu from "./Components/TopNavigationPoverMenu.web";
import TopNavigationSearchComp from "./Components/TopNavigationSearchComp.web";

export default class TopNavigation extends TopNavigationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="tn-container">
        <div className="tn-container-c">
          <button className="tn-logo-container" onClick={this.handleNavToLandingPage} data-testid="TN_logo">
            <img src={Logo} alt="Logo" />
          </button>
          <div className="tn-select-container">
            <Button
              className="tn-btn"
              onClick={this.handleNavHome}
              style={{
                color: "white",
                fontSize: "0.85vw",
                marginRight: "0.8vw",
                fontWeight: "normal",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
              }}
              data-testid="TN_home"
            >
              Home
            </Button>

            <Button
              className="tn-btn"
              onClick={this.handleNavCourses}
              disableElevation
              style={{
                color: "white",
                fontSize: "0.85vw",
                marginRight: "0.8vw",
                fontWeight: "normal",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
              }}
              data-testid="TN_courses"
            >
              Courses
            </Button>

            <Button
              className="tn-btn"
              onClick={this.handleNavLWU}
              disableElevation
              style={{
                color: "white",
                fontSize: "0.85vw",
                marginRight: "0.8vw",
                fontWeight: "normal",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
              }}
              data-testid="TN_learnWithUs"
            >
              Learn with Us
            </Button>

            <Button
              className="tn-btn"
              onClick={this.handleNavBAT}
              disableElevation
              style={{
                color: "white",
                fontSize: "0.85vw",
                marginRight: "0.8vw",
                fontWeight: "normal",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
              }}
              data-testid="TN_becomeATeacher"
            >
              Become a Teacher
            </Button>

            <Button
              className="tn-btn"
              onClick={this.handleNavCU}
              disableElevation
              style={{
                color: "white",
                fontSize: "0.85vw",
                marginRight: "0.8vw",
                fontWeight: "normal",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
              }}
              data-testid="TN_contactUs"
            >
              Contact Us
            </Button>
            {this.state.userDetailsApiRes ? (
              this.state.is_loggedIn ? (
                <div>
                  <TopNavigationPoverMenu
                    handleLogout={this.handleLogout}
                    handleSettings={this.handleSettings}
                    handleProfile={this.handleProfile}
                    FName={this.state.userDetails.first_name}
                    profilePic={this.state.userDetails.profile_image}
                  />
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Button
                    className="tn-su-btn"
                    onClick={this.handleNavSU}
                    variant="contained"
                    disableElevation
                    style={{
                      width: "6.5vw",
                      height: "2.3vw",
                      backgroundColor: "#f0d27c",
                      borderRadius: 50,
                      color: "black",
                      fontSize: "0.85vw",
                      fontWeight: "normal",
                      textTransform: "none",
                      marginRight: "0.8vw",
                    }}
                    data-testid="TN_signUp"
                  >
                    Signup
                  </Button>
                  <Button
                    className="tn-li-btn"
                    variant="outlined"
                    onClick={this.handleNavLI}
                    disableElevation
                    style={{
                      width: "6.5vw",
                      height: "2.3vw",
                      borderRadius: 50,
                      borderWidth: "2px",
                      borderColor: "white",
                      color: "white",
                      fontSize: "0.85vw",
                      fontWeight: "normal",
                      textTransform: "none",
                      marginRight: "0.8vw",
                    }}
                    data-testid="TN_login"
                  >
                    Login
                  </Button>
                </div>
              )
            ) : null}
            <TopNavigationSearchComp searchNav={this.searchNav} navigation={this.props.navigation} />
          </div>
        </div>
      </div>
    );
  }
}
