import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  location?: any;
  classes?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  EditMode: boolean;
  getDetailsLoading: boolean;
  contractSubmitted: boolean;
  ViewScheduleId: any;
  tempCardData: any;
  contractType: any;
  contractDetails: any;
  contract_status: any;
  contractData: any;
  proposed_budget: number;
  timeSlot: string[];
  timeFrom: any;
  timeTo: any;
  contractTypeData: any[];
  paymentConditionData: any[];
  distributionMethodData: any[];
  currencyTypeData: any[];
  status_modal: boolean;
  update_contract_response: any;

  // Customizable Area End
}
interface SS {
  id: any;
}
export default class ViewScheduleController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiViewScheduleCallId: any;
  contractTypeCallId: any;
  paymentConditionCallId: any;
  distributionMethodCallId: any;
  updateContractCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      EditMode: true,
      getDetailsLoading: true,
      contractSubmitted: false,
      tempCardData: [1, 2, 3],
      ViewScheduleId: {},
      contractDetails: {},
      contractData: {},
      contract_status: null,
      contractType: "",
      proposed_budget: 0,
      timeSlot: ["00:00 AM", "00:00 AM"],
      timeFrom: "10:00",
      timeTo: "11:00",
      contractTypeData: [],
      paymentConditionData: [],
      distributionMethodData: [],
      currencyTypeData: [{ name: "INR" }],
      status_modal: false,
      update_contract_response: {}
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiViewScheduleCallId) {
          this.handleViewScheduleResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.contractTypeCallId) {
          this.handleContractTypeResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.paymentConditionCallId) {
          this.handlePaymentConditionResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.distributionMethodCallId) {
          this.handleDistributionMethodResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.updateContractCallId) {
          this.handleUpdateContractResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  handleViewScheduleResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("getContractDetails_Response", responseJson);
      console.log(
        "contract_statuss_Response",
        responseJson?.data?.attributes?.contract_status
      );
      this.setState({
        contractDetails: responseJson.data,
        getDetailsLoading: false,
      });
      if (responseJson?.data?.attributes?.contract_status == "Under Review") {
        this.setState({
          contract_status: "Under Review",
          contractSubmitted: false,
          EditMode: false,
        });
      } else if (
        Object.keys(responseJson?.data?.attributes?.contract_status)?.length > 0
      ) {
        const slot = this.parseTimeString(
          this.state?.contractDetails?.attributes?.contract_status?.data[0]
            ?.attributes?.class_slot
        );
        this.setState({
          timeSlot: slot,
          timeFrom: slot[0],
          timeTo: slot[1],
          contractSubmitted: true,
          EditMode: true,
          contractData: responseJson?.data?.attributes?.contract_status,
          contract_status: "Accepted",
        });
        this.handleContractTypeChange(
          responseJson?.data?.attributes?.contract_status.data[0].attributes
            .contract_type
        );
      }
      this.setState({
        proposed_budget:
          responseJson.data.attributes.hire_teacher_request.data.attributes
            .proposed_budget,
      });
    } else {
      if (responseJson?.errors[0]?.token == "Token has Expired") {
        alert(JSON.stringify(responseJson?.errors[0]?.token));
      }
      console.log("ERR_getContractDetails_Response", responseJson);
      alert(JSON.stringify(responseJson?.errors[0].token));
      this.setState({
        getDetailsLoading: false,
      });
    }
  };

  handleContractTypeResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("contractType_Response", responseJson);
      this.setState({
        contractTypeData: responseJson,
      });
    } else {
      console.log("ERR_contractType_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  };
  handlePaymentConditionResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("paymentCondition_Response", responseJson);
      this.setState({
        paymentConditionData: responseJson,
      });
    } else {
      console.log("ERR_paymentCondition_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  };
  handleDistributionMethodResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("distributionMethod_Response", responseJson);
      this.setState({
        distributionMethodData: responseJson,
      });
    } else {
      console.log("ERR_distributionMethod_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  };
  handleUpdateContractResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("UpdateContract_Response", responseJson);
      this.ViewContractDetails(this.state.ViewScheduleId);
      this.setState({
        status_modal: true,
        update_contract_response: responseJson.meta
      });
    } else {
      if (responseJson?.errors[0]?.token == "Token has Expired") {
        alert(JSON.stringify(responseJson?.errors[0]?.token));
      }
      console.log("ERR_UpdateContract_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  };

  handleContractTypeChange = (val: any) => {
    this.setState({
      contractType: val,
    });
  };

  ViewContractDetails = (id: any) => {
    this.setState({
      getDetailsLoading: true
    })
    console.log("getContractDetails");
    const endPoint = `${configJSON.viewContractEndpoint}?application_id=${id}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const ViewContractDetailsCallIdMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiViewScheduleCallId = ViewContractDetailsCallIdMsg.messageId;

    ViewContractDetailsCallIdMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    ViewContractDetailsCallIdMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    ViewContractDetailsCallIdMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      ViewContractDetailsCallIdMsg.id,
      ViewContractDetailsCallIdMsg
    );
  };

  getContractTypeUpdate = () => {
    console.log("getContractType");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const getContractTypeUpdateMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contractTypeCallId = getContractTypeUpdateMsg.messageId;
    getContractTypeUpdateMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contractTypeEndpoint
    );
    getContractTypeUpdateMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getContractTypeUpdateMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      getContractTypeUpdateMsg.id,
      getContractTypeUpdateMsg
    );
  };
  getPaymentConditionUpdate = () => {
    console.log("getPaymentCondition");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const getPaymentConditionUpdateMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.paymentConditionCallId = getPaymentConditionUpdateMsg.messageId;
    getPaymentConditionUpdateMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentConditionEndpoint
    );
    getPaymentConditionUpdateMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPaymentConditionUpdateMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      getPaymentConditionUpdateMsg.id,
      getPaymentConditionUpdateMsg
    );
  };
  getDistributionMethodUpdate = () => {
    console.log("getDistributionMethod");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const getDistributionMethodUpdateMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.distributionMethodCallId = getDistributionMethodUpdateMsg.messageId;
    getDistributionMethodUpdateMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.distributionMethodEndpoint
    );
    getDistributionMethodUpdateMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDistributionMethodUpdateMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(
      getDistributionMethodUpdateMsg.id,
      getDistributionMethodUpdateMsg
    );
  };

  async componentWillMount() {
    this.getContractTypeUpdate();
    this.getPaymentConditionUpdate();
    this.getDistributionMethodUpdate();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const parmsViewScheduleId =
      this.props.navigation.getParam("ViewScheduleId");
    await this.setState({ ViewScheduleId: parmsViewScheduleId });
    this.ViewContractDetails(parmsViewScheduleId);
  }

  startTime = (val: any) => {
    console.log("startssssssssssssssssss", val);
  };
  endTime = (val: any) => {
    console.log("Enddddddddddddddddddddddddddd", val);
  };

  handleEditMode = () => {
    this.setState({ EditMode: false });
  };

  handleDownloadResume = (data: any) => {
    console.log(data);
    fetch(data?.cv).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${data?.cv_filename}`;
        a.click();
      });
    });
  };

  parseTimeString = (timeString: any) => {
    const times = timeString?.split(" - ");
    const parsedTimes = times?.map((time: any) => time?.replace("$", " "));
    return parsedTimes;
  };

  handleUpdateContractInfo = (
    numOfPayments: number,
    totClasses: number,
    contrType: string,
    paymCondition: string,
    distrMethod: string,
    currType: string,
    timeslot: string[]
  ) => {
    console.log(
      numOfPayments,
      totClasses,
      contrType,
      paymCondition,
      distrMethod,
      currType,
      timeslot[0],
      timeslot[1]
    );
    let token = localStorage?.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const attributes = {
      // "contract_cp[]": paymentAmount.map(String).join(','),
      contract_id: this.state.contractData.data[0].id,
      class_from: timeslot[0],
      class_to: timeslot[1],
      contract_type: contrType,
      distribution_method: distrMethod,
      payment_condition: paymCondition,
      total_classes: totClasses,
      no_of_payments: numOfPayments,
      currency: currType,
      milestone_status: "completed"
    };

    const updateContractInfoMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateContractCallId = updateContractInfoMsg.messageId;

    updateContractInfoMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateContractInfoEndpoint
    );

    updateContractInfoMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateContractInfoMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethod
    );
    updateContractInfoMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attributes)
    );
    runEngine.sendMessage(updateContractInfoMsg.id, updateContractInfoMsg);
  };
}
