import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  coursesList: any;
  popularCourseList: any;
  categoriesList: any;
  totalPage: number;
  currentPage: number;
  selectCategory: any;
  startDate: any;
  appliedJobs:any;
  appliedJobsLoader:any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class AppliedJobsController extends BlockComponent<Props, S, SS> {
  apiCoursesListCallId: any;
  apiPopularCoursesListCallId: any;
  apiCategoriesCallId: any;
  getAppliedJobsId:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      coursesList: [],
      popularCourseList: [],
      categoriesList: [],
      totalPage: 1,
      currentPage: 1,
      selectCategory: null,
      startDate: null,
      appliedJobs:[],
      appliedJobsLoader:true
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Receive Fun() //////////////////////////////////////////////////////////
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.getAppliedJobsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          appliedJobs: apiResponse?.data,
          appliedJobsLoader:false
        })
      }
  }
    

    // Customizable Area End
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    this.getappliedJobsDetails()
    
  }


  getappliedJobsDetails() {
    const token = localStorage.getItem("loginT");

    
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getappliedJobRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAppliedJobsId = getappliedJobRequestMsg.messageId;


    getappliedJobRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "applied_jobs"
    );

    getappliedJobRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getappliedJobRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getappliedJobRequestMsg.id, getappliedJobRequestMsg);

  }

  handleClickOnVisibilty=(eachJob:any)=>{
      localStorage.setItem("viewAppliedJob",eachJob?.id);
      localStorage.setItem("viewContractId",eachJob?.id);
      this.props.navigation.navigate("ViewAppliedJobs")
  }
  handleClickOnAddContract=(eachJob:any)=>{
    localStorage.setItem("viewContractId",eachJob?.id);
    this.props.navigation.navigate("ViewContract")
}

 
}

// Customizable Area End
