import React from "react";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import SearchController, { Props } from "./SearchController.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import CourseListPagination from "../../dashboard/src/components/CourseListPagination.web";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    sDashboard_cc: {
      minHeight: "80vh",
      paddingLeft: "17em",
      paddingRight: "17em",
    },
    loadingSearchRes: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      marginLeft: "2em",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },
  });

class Search extends SearchController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.sDashboard_cc}>
          {this.state.query.length > 0 && (
            <div>
              <Typography
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontSize: "25px",
                  fontWeight: 500,
                  margin: "1em 0em",
                }}
              >
                {this.state.totalCourses} Results for "{this.state.query}"
              </Typography>
            </div>
          )}
          {this.state.is_search_loaded ? (
            <Grid style={{ justifyContent: "space-between", marginTop: "10px" }}>
              <CourseListPagination
                navigateToCoursesDetails={this.navigateToCoursesDetails}
                coursesList={this.state.coursesList}
                totPage={this.state.totalPage}
                currPage={this.state.currentPage}
                navigation={this.props.navigation}
                enableFilters={false}
                pageFrom="ElasticSearch"
                fetchCourseListLoading={false}
              />
            </Grid>
          ) : (
            <div className={classes.loadingSearchRes}>
              <CircularProgress />
            </div>
          )}
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Search);
