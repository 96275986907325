import React from "react";



// Customizable Area Start
import {
  Grid, Card, Typography, Button,
  DialogContent, Dialog, DialogTitle,DialogContentText, DialogActions, CircularProgress, Link
} from "@material-ui/core";
import ExitToApp from "@material-ui/icons/ExitToApp"
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {PropsContext, MaxUidContext, MinUidContext, MaxVideoView, MinVideoView, TracksConfigure, LocalControls, LocalUserContext,
  RtcConfigure, RtmConfigure, RemoteMutePopUp} from "agora-react-uikit";
  import "react-datepicker/dist/react-datepicker.css";
  


// Customizable Area End

import WebinarsController, {
  configJSON
} from "./WebinarsController.web";
import WebinarsList from "./WebinarsList.web";

class Webinars extends WebinarsController {


  // Customizable Area Start  
  // Customizable Area End

  render() {
                 
    return (
      // Customizable Area Start
      <>          
      {this.state.isJoined ? (
     <>         
      <PropsContext.Provider
            value={{
                  rtcProps:{
                      appId:configJSON.webinarAgoraId,
                      channel:this.state.webinarName,                       
                      token: this.state.rtcToken,
                      enableAudio: true,
                      enableVideo: true,
                      uid: Number(this.state.uid),   
                      enableScreensharing:false,                                                                
                    },                    
                  rtmProps:{
                      displayUsername: true,
                      username: this.state.userName,                        
                      token: this.state.rtmToken,
                      uid: String(this.state.uid),                                                                      
                    },                  
                                    
                  styleProps:{                  
                    remoteBtnStyles: {                                                
                    muteRemoteVideo:{                  
                      display: this.state.isHost ? 'flex' : 'none'
                    },
                    muteRemoteAudio:{
                      display: this.state.isHost ? 'flex' : 'none'
                    },                         
                  },                
                      minViewContainer:{                        
                        borderRadius:8
                    },
                    maxViewStyles:{                      
                      width:'100%', height:'100%'
                    },
                    minViewStyles:{
                      height:'100%', width:'100%'
                      
                    },                      
                    videoMode:{
                      max: 'contain'
                    },                  
                    localBtnContainer:{   
                      backgroundColor:'transparent',                                  
                        width: '195px',
                        height: "70px",
                        zIndex: 10,
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        position: "absolute",
                        bottom: "5%",
                        left: "50%",
                        transform: "translate(-50%, 0%)",
  
                    },
                    iconSize:18,                                    
                    localBtnStyles:{
                      endCall:{
                        display:'none'
                      }
                    }
                  }  
            }}>
    <TracksConfigure>
  <RtcConfigure>
  <RtmConfigure> 
  
  <Grid container style={{ width:'100%',height:'100%', 
  display:'flex',
  flexDirection: 'column-reverse',
  justifyContent:'space-between', 
  padding:8, 
  flexWrap:'nowrap',}} justifyContent="space-evenly" direction="row" >
   
    <Grid item style={{
      width: '100%', height:'100%'
    }} >   
    <div id="maxUid">
    <MaxUidContext.Consumer>      
      {                
      (maxusers) => {
        return(  
          maxusers[0] && (
            <Card  style={{position:'relative'}}>              
            <MaxVideoView user={maxusers[0]} key={maxusers[0].uid} />     
            {this.state.isHost && maxusers[0].uid !== 0 && <ExitToApp 
            style={{
              position:'absolute', top:10, right:30, color:"#f00", cursor:'pointer'
            }}
              onClick={()=>{this.handleKickUser(String(maxusers[0].uid))}}  id="removeUserMax"
            />  }   
            <Button
               id="stopWebinar"
              onClick={this.endCallHandler} 
              style={{ 
                      position: "absolute",
                      bottom: "3%",
                      right: "3%",
                      transform: "translate(-5%, 0%)",
                      color:'#fff',
                      textTransform:'capitalize',
                      background:'#bb386d'
            }}
              variant="outlined"
              color="secondary"
              size="small"
            >{configJSON.endCallLabel}</Button>        
            {maxusers[0].uid === this.state.hostId &&  !this.state.isHost &&  <Typography 
            style={{
              backgroundColor:'rgb(25 132 169)',color: 'rgb(255, 255, 255)',padding: '4px',
              fontWeight:'bold',position: 'absolute',top: '10px',right: '15px',borderRadius: '8px',
              fontSize:'12px',border: '2px solid #cfcb17',
            }}  id='hostLabel'>{configJSON.hostLabel}</Typography> }
          

  <LocalUserContext>
  <LocalControls/>
  <RemoteMutePopUp/>  
  </LocalUserContext>
  </Card>
        
        ))}}
        
    </MaxUidContext.Consumer>
    </div>
    </Grid>
  
    <Grid item style={{
      width:  '100%', 
      height:'fit-content', 
      
    }} >
      <Card style={{width:'100%', 
      height:'100%',
      display:'flex', alignItems:'center', 
      flexDirection:  "row",                             
      
      overflowX:  "scroll",
      marginRight: "2px", }}>
     
        <div id="minUid"  style={{display:'flex',  }}>
        <MinUidContext.Consumer>
      {    
      minUser => minUser.map(user => {       
        return(
          <div  style={{

            margin:8,position:'relative',
             width:200,
             height:150,
             padding:8,marginTop:0,                  
            
          }} key={user.uid} >
        <MinVideoView user={user} key={user.uid} />  
        {this.state.isHost && user.uid !== 0 && <ExitToApp 
        style={{
          position:'absolute', top:10, right:30, color:"#f00", cursor:'pointer'
        }}
              onClick={()=>{this.handleKickUser(String(user.uid))}} id="removeUserMin"
            />  }            
            {user.uid === this.state.hostId &&  !this.state.isHost &&  <Typography 
           style={{
            backgroundColor:'rgb(25 132 169)',color: 'rgb(255, 255, 255)',padding: '4px',
            fontWeight:'bold',position: 'absolute',top: '10px',right: '15px',borderRadius: '8px',
            fontSize:'12px',border: '2px solid #cfcb17',
          }} id='hostLabel'>{configJSON.hostLabel}</Typography> }
   
        </div>
      
   )})}
    </MinUidContext.Consumer>
    </div>
   </Card>
  </Grid>
  
  </Grid>
       
  </RtmConfigure>
  </RtcConfigure>
  </TracksConfigure>    
        </PropsContext.Provider>
  
  <Dialog
          open={this.state.isRemoveuserDialogOpen}          
          aria-labelledby="Remove User"
          aria-describedby="Are you sure you want to remove this user from the channel?"
        >
          <DialogTitle id="Remove-User-dialog-title">{configJSON.removeUserTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="Remove-User-dialog-description">
            {configJSON.removeUserDescription}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeRemoveUserDialog} color="primary" 
            id="NotRemoveUserBtn"
            >
              {configJSON.noBtnLabelText}
            </Button>
            <Button onClick={             
              ()=>this.kickUser(this.state.kickUserId)} color="primary" autoFocus 
             id="RemoveUserBtn"
            >
              {configJSON.yesBtnLabelText}
            </Button>
          </DialogActions>
  </Dialog>

  <Dialog
    open={this.state.isDownloadRecordingDialogOpen}                    
    aria-labelledby="Webinar-Recording-dialog-title"
    aria-describedby="Webinar-Recording-dialog-description"
  >   
    { this.state.loading && <div  style={{ width:300,
      height: 250,
      display:'flex',
      justifyContent:'center',
      alignItems:'center'}}>
        <CircularProgress  size={40} thickness={4}/>
      </div> }
      
      { !this.state.loading &&  <>
      <DialogTitle id="Webinar-Recording-dialog-title" >
        <Typography style={{fontWeight:'bold', fontSize:18}}>{configJSON.webinarRecordingTitle}</Typography>
        </DialogTitle>
      <DialogContent>
        <DialogContentText id="Webinar-Recording-dialog-description">
        <Typography style={{fontSize:16}}>
        {configJSON.webinarRecordingDescription}
        </Typography>
        <div style={{display:'flex', justifyContent:'center'}}>
        <Button variant="outlined" color="primary" style={{textTransform: 'none'}} 
        onClick={()=>{this.copyToClipboard(this.state.recordingData)}} id="downloadLinkButton"
        endIcon={<FilterNoneIcon/>}>{configJSON.webinarRecordingLinkBtn}</Button>
        </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeRecordingModal} color="primary" 
        style={{ textTransform: 'capitalize',}}
        variant="outlined"            
        >
          {configJSON.cancelBtnLabelText}
        </Button>
        <Button color="primary" autoFocus
        style={{ textTransform: 'capitalize',}}
        variant="contained"                        
        id="downloadBtn"         
        onClick={this.closeRecordingModal}
        endIcon={<CloudDownloadIcon fontSize="large"/>}
        >                      
        <Link href={this.state.recordingData} target="_blank" style={{color:'#fff', textDecoration:'none'}}>
        {configJSON.downloadBtnLabelText}
          </Link>
        </Button>
      </DialogActions>
      </> }
          
  </Dialog> 
  
 </> ) : (
      
  <WebinarsList
  {...this.props}
  isHost={this.state.isHost}
  onJoinCall={this.onJoinCall}
  />  
      )}

  </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default Webinars
// Customizable Area End
