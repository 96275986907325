import React, { Component } from "react";
import { fullscreen, mute, pause, play, playCenterBtn, record, vidSetting, volumeIcon } from "../assets";
import ReactPlayerController, { ReactPlayerTestProps } from "./ReactPlayerController.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slider from "@material-ui/core/Slider";
import Duration from "./Duration.web";
import { withStyles } from "@material-ui/core/styles";
import VideoStyles from "./Styles.web";

interface Props {
  classes?: any;
  settingsMouseLeave: () => Promise<void>;
  handlePlayPause: () => Promise<void>;
  handleSeekMouseDown: (e: React.SyntheticEvent<HTMLInputElement>) => Promise<void>;
  handleSeekChange: (e: React.SyntheticEvent<HTMLInputElement>) => Promise<void>;
  handleSeekMouseUp: (e: React.SyntheticEvent<HTMLInputElement>) => Promise<void>;
  handleToggleMuted: () => Promise<void>;
  handleSetPlaybackRate: (e: React.SyntheticEvent<HTMLButtonElement>) => Promise<void>;
  handleClickFullscreen: () => Promise<void>;
  buffering: boolean;
  playing: boolean;
  muted: boolean;
  settingActive: boolean;
  duration: number;
  played: number;
  loaded: number;
  playbackRate: number;
  mode: "sl" | "cd";
}

export class VideoControls extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className={this.props.mode == "sl" ? "VPSL-videoControlsccc" : "VPCD-videoControlsccc"}
        onMouseLeave={() => {
          this.props.settingsMouseLeave();
        }}
        data-testId="VideoControlsMouseActions"
      >
        <div className={this.props.mode == "sl" ? "VPSL-videoSliderCccc" : "VPCD-videoSliderCccc"}>
          {this.props.buffering === true ? (
            <CircularProgress style={{ color: "#fff" }} size="5rem" />
          ) : (
            <button className="VPSL-videoCenterBtn" onClick={this.props.handlePlayPause}>
              {this.props.playing === false ? (
                <img src={playCenterBtn} alt="" className="VPSL-videoCenterBtnImg" />
              ) : null}
            </button>
          )}
        </div>
        <div className="VPSL-videoBottomControllsCCC">
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "1em",
                marginRight: "1em",
                position: "relative",
                marginBottom: "0.7em",
              }}
            >
              <Slider
                value={this.props.loaded}
                aria-labelledby="continuous-slider"
                min={0}
                max={0.999999}
                className={classes.MSliderLoaded}
              />
              <Slider
                value={this.props.played}
                aria-labelledby="continuous-slider"
                min={0}
                max={0.999999}
                className={classes.MSlider}
              />
              <input
                type="range"
                min={0}
                max={0.999999}
                step="any"
                value={this.props.played}
                onMouseDown={this.props.handleSeekMouseDown}
                onChange={this.props.handleSeekChange}
                onMouseUp={this.props.handleSeekMouseUp}
                style={{ width: "100%", margin: "0px" }}
                className={classes.sliderInput}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="VPSL-videoControlsLeft">
              <button className="VPSL-videoControlsPlayBtn" onClick={this.props.handlePlayPause}>
                {this.props.playing === false ? (
                  <img src={play} alt="" style={{ width: "1.7vw" }} />
                ) : (
                  <img src={pause} alt="" style={{ width: "1vw" }} />
                )}
              </button>

              <button className="VPSL-videoControlsVolumeBtn" onClick={this.props.handleToggleMuted}>
                {this.props.muted === false ? (
                  <img src={volumeIcon} alt="" style={{ width: "1.3vw" }} />
                ) : (
                  <img src={mute} alt="" style={{ width: "1.3vw" }} />
                )}
              </button>

              <p
                style={{
                  color: "#a0a0a0",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  fontSize: "0.7vw",
                }}
              >
                <Duration seconds={this.props.duration * this.props.played} /> /{" "}
                <Duration seconds={this.props.duration} />
              </p>
              <img src={record} alt="" style={{ width: "1.1vw", marginLeft: "0.9vw" }} />
              <p
                style={{
                  color: "#ffffff",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  fontSize: "0.7vw",
                  marginLeft: "0.5vw",
                }}
              >
                Record
              </p>
            </div>
            <div className="VPSL-videoControlsRight">
              <div style={{ position: "relative" }}>
                {this.props.settingActive && (
                  <div
                    style={{
                      position: "absolute",
                      top: -115,
                      left: -23,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      width: "5em",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #000000",
                    }}
                    onMouseEnter={async () => {
                      await this.setState({ settingActive: true });
                      console.log(this.props.settingActive);
                    }}
                    onMouseLeave={async () => {
                      await this.setState({ settingActive: false });
                      console.log(this.props.settingActive);
                    }}
                    data-testId="VideoControlsMouseActionsEnterAndLeave"
                  >
                    <button
                      onClick={this.props.handleSetPlaybackRate}
                      value={0.5}
                      className={classes.playbackRateBtn}
                      style={
                        this.props.playbackRate === 0.5
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              fontFamily: "Inter, sans-serif",
                            }
                          : {}
                      }
                    >
                      0.5x
                    </button>
                    <button
                      onClick={this.props.handleSetPlaybackRate}
                      value={1}
                      className={classes.playbackRateBtn}
                      style={
                        this.props.playbackRate === 1
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              fontFamily: "Inter, sans-serif",
                            }
                          : {}
                      }
                    >
                      1x
                    </button>
                    <button
                      onClick={this.props.handleSetPlaybackRate}
                      value={1.5}
                      className={classes.playbackRateBtn}
                      style={
                        this.props.playbackRate === 1.5
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              fontFamily: "Inter, sans-serif",
                            }
                          : {}
                      }
                    >
                      1.5x
                    </button>
                    <button
                      onClick={this.props.handleSetPlaybackRate}
                      value={2}
                      className={classes.playbackRateBtn}
                      style={
                        this.props.playbackRate === 2
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              fontFamily: "Inter, sans-serif",
                            }
                          : {}
                      }
                    >
                      2x
                    </button>
                  </div>
                )}

                <button
                  className="VPSL-videoControlsSettingBtn"
                  onClick={async () => {
                    await this.setState({ settingActive: !this.props.settingActive });
                    console.log(this.props.settingActive);
                  }}
                >
                  <img src={vidSetting} alt="" className="VPSL-videoControlsSettingImg" />
                </button>
              </div>

              <button
                onClick={this.props.handleClickFullscreen}
                disabled={!this.props.playing}
                className="VPSL-videoControlsFullscreenBtn"
              >
                <img src={fullscreen} alt="" className="VPSL-videoControlsFullscreenImg" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(VideoStyles, { withTheme: true })(VideoControls);
