import React, { useState } from "react";
import { dropdown } from "../assets";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Timers from "./Timers.web";
import { Button, Checkbox } from "@material-ui/core";
import { checkedTeach, unCheckedTeach } from "../../../AssessmentTest/src/assets";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        Contract_container: {
            background: "#f7f7f7",
            padding: "2vw",
            borderRadius: "1vw",
            marginBottom: "3vw",
        },
        prl_input_cc: {
            display: "flex",
            width: "100%",
            marginBottom: "1em",
        },
        prl_input_left: {
            position: "relative",
            width: "100%",
            marginRight: "1em",
        },
        prl_input_left_Count_c: {
            position: "relative",
            width: "100%",
            paddingRight: "1em",
            display: "flex",
        },
        prl_input_right: {
            position: "relative",
            width: "100%",
            marginLeft: "1em",
        },
        prl_input_lable: {
            display: "flex",
            fontSize: "0.9em",
            fontWeight: 600,
            fontFamily: "Inter, sans-serif",
            marginBottom: "0.7em",
            color: "rgba(51, 51, 51, 1)",
        },
        prl_input_dd: {
            position: "relative",
        },
        prl_input_dd_s: {
            width: "100%",
            height: "3.5rem",
            background: "white",
            color: "#a8a8a8",
            cursor: "pointer",
            border: "1px solid #c7c7c7",
            paddingLeft: "1.5em",
            fontSize: "0.8em",
            fontFamily: "Inter, sans-serif",
            borderRadius: "0.5em",
            "&:focus-visible": {
                outline: "0px",
            },
        },
        prl_input_dd_currency: {
            position: "relative",
            width: "8vw",
            height: "3vw",
        },
        prl_input_dd_s_currency: {
            width: "8vw",
            height: "100%",
            background: "white",
            color: "#a8a8a8",
            cursor: "pointer",
            border: "1px solid #c7c7c7",
            paddingLeft: "1.5em",
            fontSize: "1em",
            fontFamily: "Inter, sans-serif",
            borderRadius: "0.5em",
            "&:focus-visible": {
                outline: "0px",
            },
        },
        required: {
            color: "#ff0000",
        },
        prl_input_arrow: {
            position: "absolute",
            top: 0,
            right: 0,
            width: "2em",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: "0.2em",
            marginRight: "0.2em",
            pointerEvents: "none",
        },
        prl_input_arrow_currency: {
            position: "absolute",
            top: 0,
            right: 0,
            width: "2em",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: "0.2em",
            marginRight: "0.2em",
            pointerEvents: "none",
        },
        Contract_payInfo_txt: {
            color: "#333333",
            fontWeight: 600,
            fontSize: "1vw",
            fontFamily: "Inter, sans-serif",
            marginTop: "2vw",
            marginBottom: "1.5vw",
        },
        countC: {
            border: "0px",
            fontSize: "0.8vw",
            textAlign: "center",
            width: "100%",
            "&:focus-visible": {
                outline: "0px",
            },
            "&:disabled": {
                backgroundColor: "transparent",
            },
        },
        addRmBtnC: {
            border: "0px",
            width: "3vw",
            fontSize: "1.5vw",
            background: "transparent",
            fontWeight: "normal",
            color: "#14292d",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:disabled": {
                backgroundColor: "transparent",
                color: "#14292d",
            },
        },
        countCC: {
            border: "0.5px solid #c7c7c7",
            borderRadius: "0.25vw",
            height: "3vw",
            display: "flex",
            // width: '6vw',
            justifyContent: "space-between",
            backgroundColor: "#fff",
            marginRight: "0.7vw",
        },
        contract_scroll_C: {
            background: "#ffffff",
        },
        contract_scroll_header: {
            background: "#14292d",
            borderRadius: "0.25vw",
            display: "flex",
            paddingTop: "1vw",
            paddingBottom: "1vw",
        },
        contract_scroll_head_p1: {
            flex: 3,
            textAlign: "center",
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "0.8vw",
            color: "#ffffff",
            margin: "0px",
        },
        contract_scroll_head_p2: {
            flex: 7,
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "0.8vw",
            color: "#ffffff",
            margin: "0px",
        },
        contract_sroll_item_c: {
            display: "flex",
            paddingTop: "0.8vw",
            paddingBottom: "0.8vw",
            alignItems: "center",
        },
        contract_sroll_item_p1: {
            flex: 3,
            textAlign: "center",
            color: "#181617",
            fontFamily: "Inter, sans-serif",
            fontWeight: 400,
            fontSize: "0.85vw",
        },
        contract_sroll_item_p2_c: {},
        contract_sroll_item_p2: {
            paddingLeft: "1vw",
            color: "#181617",
            fontFamily: "Inter, sans-serif",
            fontWeight: 400,
            fontSize: "0.85vw",
        },
        contract_sroll_item_input: {
            paddingLeft: "0.8vw",
            border: "0.5px solid #c7c7c7",
            borderRadius: "0.25vw",
            color: "#666666",
            fontFamily: "Inter, sans-serif",
            fontWeight: 400,
            fontSize: "0.75vw",
            height: "4em",
            width: "25em",
            "&:focus-visible": {
                outline: "0px",
            },
        },
        ErrMessage: {
            marginTop: "1.5em",
            marginLeft: "1.5em",
            color: "#ff0000",
            fontWeight: 700,
            fontFamily: "Inter, sans-serif",
            fontSize: "0.85em",
            alignSelf: "center",
        },
        CA_checkbox_Img: {
            width: "0.65em",
        },
        CA_checkbox: {
            marginRight: "0.5em",
            "&.MuiIconButton-root": {
                padding: 0,
            },
            "&.MuiCheckbox-colorSecondary": {
                color: "#ff0000",
            },
            "&.Mui-disabled": {
                color: "#cccccc"
            }
        },
        submit_btn: {
            backgroundColor: "#ff0000",
            marginTop: "1.5em",
            border: '0px',
            borderRadius: "10em",
            textTransform: "none",
            padding: "4px 10px",
            paddingLeft: "1.5em",
            paddingRight: "1.5em",
            color: "#ffffff",
            fontWeight: 700,
            fontFamily: "Inter, sans-serif",
            fontSize: "1em",
            lineHeight: 1.75,
            boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
            "&:hover": {
                backgroundColor: "#ff0000",
            },
        },
    })
);

interface Props {
    startTime: any;
    endTime: any;
    updateContract: (
        numOfPayments: number,
        totClasses: number,
        contrType: string,
        paymCondition: string,
        distrMethod: string,
        currType: string,
        timeslot: string[]
    ) => void;
    from?: any;
    to?: any;
    contractTypeData: any[];
    paymentConditionData: any[];
    distributionMethodData: any[];
    currencyTypeData: any[];
    proposed_budget: number;
    numberOfPaymentsUpdate: any;
    totClassesUpdate: any;
    currTypeUpdate: any;
    contrTypeUpdate: any;
    paymConditionUpdate: any;
    distrMethodUpdate: any;
    contractPayments: any;
}

const UpdateContractInfo = ({
    startTime,
    endTime,
    updateContract,
    from,
    to,
    contractTypeData,
    paymentConditionData,
    distributionMethodData,
    currencyTypeData,
    proposed_budget,
    numberOfPaymentsUpdate,
    totClassesUpdate,
    currTypeUpdate,
    contrTypeUpdate,
    paymConditionUpdate,
    distrMethodUpdate,
    contractPayments,
}: Props) => {
    console.log("dsdsds", from, to);
    const classes = useStyles();
    const [totClasses, setTotClasses] = useState<string>("1");
    const [noOfPayments, setNoOfPayments] = useState<string>("1");
    const [contrType, setContrType] = useState<string>("select");
    const [paymCondition, setPaymCondition] = useState<string>("select");
    const [distrMethod, setDistrMethod] = useState<string>("select");
    const [currType, setCurrType] = useState<string>("select");
    const [paymentAmount, setPaymentAmount] = useState<any[]>([contractPayments]);
    const [startT, setStartT] = useState("10:00$AM");
    const [endT, setEndT] = useState("11:00$AM");
    const [inputsERR, setInputsERR] = useState(false);
    const [compleatedMS, setCompleatedMS] = useState<any>([]);
    const [incompleateMS, setIncompleateMS] = useState<any>([]);
    const [milestoneCheck, setMilestoneCheck] = useState(false);

    const setTimers = (start: string, end: string) => {
        setStartT(start);
        setEndT(end);
        console.log("notaion", startT, endT);
    };




    const divideProposedBudget = (noOfPayments: number, proposedBudget: number): number[] => {
        const splitpaymentAmount = Math.round((proposedBudget / noOfPayments) * 100) / 100;
        return Array(noOfPayments).fill(splitpaymentAmount);
    };

    const separateByMilestoneStatus = (items: any) => {
        const notNull = items.filter((item: any) => item.milestone_status !== null);
        const isNull = items.filter((item: any) => item.milestone_status === null);
        return [notNull, isNull];
    }

    React.useEffect(() => {
        console.log('paymentttt', totClassesUpdate)
        const separate = separateByMilestoneStatus(contractPayments)
        setCompleatedMS(separate[0])
        setIncompleateMS(separate[1])
        setNoOfPayments(numberOfPaymentsUpdate);
        setTotClasses(totClassesUpdate);
        setCurrType(currTypeUpdate);
        setContrType(contrTypeUpdate);
        setPaymCondition(paymConditionUpdate);
        setDistrMethod(distrMethodUpdate);
    }, []);

    React.useEffect(() => {
        const payments = divideProposedBudget(parseInt(noOfPayments), proposed_budget);
        setPaymentAmount(payments);
        console.log("||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||", paymentAmount); // Output: ["0", "0", "0", "0"]
    }, [noOfPayments]);

    const handleUpdateContract = async () => {
        console.log("CONTENTTYPE", contractPayments, numberOfPaymentsUpdate, totClassesUpdate, currTypeUpdate, contrTypeUpdate, paymConditionUpdate, distrMethodUpdate)
        if (
            contrType == "select" ||
            paymCondition == "select" ||
            distrMethod == "select" ||
            currType == "select"
        ) {
            console.log("errrrrr", contrType, paymCondition, distrMethod, currType);
            await setInputsERR(true);
        } else {
            console.log("yyyyyy", contrType, paymCondition, distrMethod, currType);
            await setInputsERR(false);
        }

        if (!inputsERR ) {
            if (
                parseInt(noOfPayments) > 0 &&
                parseInt(totClasses) > 0 &&
                currType?.length > 0 &&
                paymCondition?.length > 0 &&
                distrMethod?.length > 0 &&
                currType?.length > 0 &&
                contrType != "select" &&
                paymCondition != "select" &&
                distrMethod != "select" &&
                currType != "select" &&
                !inputsERR
            ) {
                const timeslot = [startT, endT];
                updateContract(
                    parseInt(noOfPayments),
                    parseInt(totClasses),
                    contrType,
                    paymCondition,
                    distrMethod,
                    currType,
                    timeslot
                );
            }
        }
    }
    return (
        <div className={classes.Contract_container}>
            <div className={classes.prl_input_cc}>
                <div className={classes.prl_input_left}>
                    <label className={classes.prl_input_lable}>Class Timings</label>
                    <Timers startTime={startTime} endTime={endTime} from={from} to={to} setTimers={setTimers} />
                </div>
                <div className={classes.prl_input_right}>
                    <label className={classes.prl_input_lable}>Contract Type</label>
                    <div className={classes.prl_input_dd}>
                        <select
                            className={classes.prl_input_dd_s}
                            value={contrType}
                            disabled
                        >
                            <option value="select">select</option>
                            {contractTypeData?.map((val, key) => {
                                return <option value={val.name} key={key}>{val.name}</option>;
                            })}
                        </select>
                        <span className={classes.prl_input_arrow}>
                            <img src={dropdown} alt="arrow" />
                        </span>
                    </div>
                </div>
            </div>
            <p className={classes.Contract_payInfo_txt}>Payment Info</p>
            <div className={classes.prl_input_cc}>
                <div className={classes.prl_input_left}>
                    <label className={classes.prl_input_lable}>Payment Condition</label>
                    <div className={classes.prl_input_dd}>
                        <select
                            className={classes.prl_input_dd_s}
                            value={paymCondition}
                            disabled
                        >
                            <option value="select">select</option>
                            {paymentConditionData?.map((val, key) => {
                                return (
                                    <option value={val.name} key={key} data-key="Empty">
                                        {val.name}
                                    </option>
                                );
                            })}
                        </select>
                        <span className={classes.prl_input_arrow}>
                            <img src={dropdown} alt="arrow" />
                        </span>
                    </div>
                </div>
                <div className={classes.prl_input_right}>
                    <label className={classes.prl_input_lable}>Distribution Method</label>
                    <div className={classes.prl_input_dd}>
                        <select
                            className={classes.prl_input_dd_s}
                            value={distrMethod}
                            disabled
                        >
                            <option value="select">select</option>
                            {distributionMethodData?.map((val, key) => {
                                return <option value={val.name} key={key}>{val.name}</option>
                            })}
                        </select>
                        <span className={classes.prl_input_arrow}>
                            <img src={dropdown} alt="arrow" />
                        </span>
                    </div>
                </div>
            </div>
            <p className={classes.Contract_payInfo_txt}>Payment Structure</p>
            <div className={classes.prl_input_cc}>
                <div className={classes.prl_input_left_Count_c}>
                    <div>
                        <label className={classes.prl_input_lable}>Total Classes</label>
                        <div className={classes.countCC}>
                            <button
                                className={classes.addRmBtnC}
                                data-testid="handleDecTotClassesBtn"
                                disabled
                            >
                                <p style={{ width: "2.5em" }}>-</p>
                            </button>
                            <input
                                name="startTime"
                                type="text"
                                pattern="[0-9]*"
                                className={classes.countC}
                                value={totClasses}
                                // onChange={async (e) => {
                                //     const re = /^[0-9\b]+$/;
                                //     console.log(re.test(e.target.value));
                                //     if (e.target.value === "" || re.test(e.target.value)) {
                                //         if (parseInt(e.target.value) !== 0) {
                                //             setTotClasses(e.target.value);
                                //         } else {
                                //             setTotClasses("1");
                                //         }
                                //     }
                                // }}
                                data-testid="TotalClassesInputComp"
                                disabled
                            />
                            <button
                                className={classes.addRmBtnC}
                                data-testid="handleIncTotClassesBtn"
                                disabled
                            >
                                <p style={{ width: "2.5em" }}>+</p>
                            </button>
                        </div>
                    </div>
                    <div>
                        <label className={classes.prl_input_lable}>No: of Payments</label>

                        <div className={classes.countCC}>
                            <button
                                className={classes.addRmBtnC}
                                data-testid="handleDecNoOfClassesBtn"
                                disabled
                            >
                                <p style={{ width: "2.5em" }}>-</p>
                            </button>
                            <input
                                name="startTime"
                                type="text"
                                autoComplete="off"
                                className={classes.countC}
                                value={noOfPayments}
                                // onChange={async (e) => {
                                //     const re = /^[0-9\b]+$/;
                                //     if (e.target.value === "" || re.test(e.target.value)) {
                                //         if (parseInt(e.target.value) !== 0) {
                                //             setNoOfPayments(e.target.value);
                                //         } else {
                                //             setNoOfPayments("1");
                                //         }
                                //     }
                                // }}
                                data-testid="numberOfPaymentInputComp"
                                disabled
                            />
                            <button
                                className={classes.addRmBtnC}
                                data-testid="handleIncNoOfClassesBtn"
                                disabled
                            >
                                <p style={{ width: "2.5em" }}>+</p>
                            </button>
                        </div>
                    </div>
                    <div>
                        <label className={classes.prl_input_lable}>Currency</label>
                        <div className={classes.prl_input_dd_currency}>
                            <select
                                className={classes.prl_input_dd_s_currency}
                                value={currType}
                                onChange={(e) => {
                                    setCurrType(e.target.value);
                                }}
                            >
                                <option value="select">select</option>
                                {currencyTypeData.map((val, key) => {
                                    return <option value={val.name} key={key}>{val.name}</option>;
                                })}
                            </select>
                            <span className={classes.prl_input_arrow_currency}>
                                <img src={dropdown} alt="arrow" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.prl_input_right} />
            </div>
            <div className={classes.contract_scroll_C}>
                <div className={classes.contract_scroll_header}>
                    <p className={classes.contract_scroll_head_p1}>Payment No:</p>
                    <p className={classes.contract_scroll_head_p2}>Payment</p>
                </div>
                <div>
                    {compleatedMS?.map((val: any, index: any) => {
                        return (
                            <div key={index + 1}>
                                <div className={classes.contract_sroll_item_c}>
                                    <p className={classes.contract_sroll_item_p1}>{val.id}</p>
                                    <div
                                        style={{ flex: 7, display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                                    >
                                        <input
                                            type="number"
                                            className={classes.contract_sroll_item_input}
                                            value={val.amount}
                                            disabled
                                        />
                                        <div className={classes.contract_sroll_item_p2_c}>
                                            <p className={classes.contract_sroll_item_p2}>₹</p>
                                        </div>
                                        <div style={{ marginLeft: "2em", display: "flex" }}>
                                            <div style={{ display: "flex" }}>
                                                <Checkbox
                                                    checked={true}
                                                    onClick={() => { }}
                                                    className={classes.CA_checkbox}
                                                    disabled
                                                />
                                                <p>Milestone {index + 1} Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {index !== paymentAmount?.length - 1 && (
                                    <div
                                        style={{ marginLeft: "2em", marginRight: "2em", borderBottom: "0.5px solid #c7c7c7" }}
                                    />
                                )}
                            </div>
                        );
                    })}
                    {incompleateMS?.map((val: any, index: any) => {
                        console.log("RRRRR", index)
                        return (
                            <div key={compleatedMS?.length + index + 1}>
                                <div className={classes.contract_sroll_item_c}>
                                    <p className={classes.contract_sroll_item_p1}>{val.id}</p>
                                    <div
                                        style={{ flex: 7, display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                                    >
                                        <input
                                            type="number"
                                            className={classes.contract_sroll_item_input}
                                            value={val.amount}
                                            disabled
                                        />
                                        <div className={classes.contract_sroll_item_p2_c}>
                                            <p className={classes.contract_sroll_item_p2}>₹</p>
                                        </div>
                                        <div style={{ marginLeft: "2em", display: "flex" }}>
                                            {val.milestone_status ?
                                                <p>Milestone {compleatedMS?.length + index + 1} Completed</p> :
                                                <div style={{ display: "flex" }}>
                                                    <Checkbox
                                                        aria-label={`Milestone ${compleatedMS?.length + index + 1} Completed`}
                                                        checked={index == 0 ? milestoneCheck : false}
                                                        onClick={() => { setMilestoneCheck(!milestoneCheck) }}
                                                        className={classes.CA_checkbox}
                                                        data-testid={`milestoneCheckBox${0}`}
                                                        disabled={index != 0}

                                                    />
                                                    <p>Milestone {compleatedMS?.length + index + 1} Completed</p>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                {index !== paymentAmount?.length - 1 && (
                                    <div
                                        style={{ marginLeft: "2em", marginRight: "2em", borderBottom: "0.5px solid #c7c7c7" }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                    className={classes.submit_btn}
                    onClick={handleUpdateContract}
                    data-testid="UpdateContractButton"
                    disabled={incompleateMS?.length > 0 && !milestoneCheck }
                >
                    Send Contract Info to Candidate

                </button>
                {inputsERR && <p className={classes.ErrMessage}>{"     "}Fill All the Madatory Fields</p>}
            </div>
        </div>
    );
};

export default UpdateContractInfo;
