import * as React from "react";
import ReactPlayer from "react-player";
import Duration from "../../../dashboard/src/ReactPlayerTest/Duration.web";
import { fullscreen, mute, pause, play, playCenterBtn, record, vidSetting, volumeIcon } from "../assets";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPlayerController, {
  ReactPlayerTestProps,
} from "../../../dashboard/src/ReactPlayerTest/ReactPlayerController.web";
import VideoStyles from "../../../dashboard/src/ReactPlayerTest/Styles.web";
import VideoControls from "../../../dashboard/src/ReactPlayerTest/VideoControls.web";

//---//

class ReactPlayerTest extends ReactPlayerController {
  constructor(props: ReactPlayerTestProps) {
    super(props);
  }

  render() {
    const { url, playing, controls, light, volume, muted, loop, playbackRate, pip } = this.state;
    return (
      <>
        <div>
          <div className="flex w-full justify-center">
            <div className="aspect-video h-60 bg-gray-100">
              <div
                className="VideoContainerC"
                id="temp"
                ref={(ref: any) => {
                  this.rrf = ref;
                }}
              >
                <div className="VPSL-Video">
                  <ReactPlayer
                    style={{ ...this.props.styles }}
                    ref={this.ref}
                    className="react-player"
                    width="100%"
                    height="100%"
                    url={url}
                    pip={pip}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    onDuration={this.handleDuration}
                    onPlay={this.handlePlay}
                    onDisablePIP={this.handleDisablePIP}
                    onPause={this.handlePause}
                    onEnablePIP={this.handleEnablePIP}
                    onEnded={this.handleEnded}
                    onProgress={this.handleProgress}
                    onPlaybackRateChange={this.handleOnPlaybackRateChange}
                    onSeek={(e) => console.log("onSeek", e)}
                    onError={(e) => console.log("onError", e)}
                    onReady={async () => {
                      console.log("onReady");
                      await this.setState({ buffering: false });
                      console.log(this.state.buffering);
                      if (!this.state.isReady) {
                        if (this.player) {
                          this.player.seekTo(this.state.initStartTimeInSeconds, "seconds");
                          this.setState({ isReady: true });
                          console.log(
                            "onStart initTime",
                            this.state.initStartTimeInSeconds,
                            typeof this.state.initStartTimeInSeconds
                          );
                        }
                      }
                    }}
                    onStart={async () => {
                      console.log("onStart");
                      await this.setState({ buffering: false });
                      console.log(this.state.buffering);
                    }}
                    onBuffer={async () => {
                      console.log("onBuffer");
                      await this.setState({ buffering: true });
                      console.log(this.state.buffering);
                    }}
                  />
                  <VideoControls
                    handleSeekChange={this.handleSeekChange}
                    handleSeekMouseUp={this.handleSeekMouseUp}
                    handleToggleMuted={this.handleToggleMuted}
                    settingsMouseLeave={this.settingsMouseLeave}
                    handleSetPlaybackRate={this.handleSetPlaybackRate}
                    handlePlayPause={this.handlePlayPause}
                    handleSeekMouseDown={this.handleSeekMouseDown}
                    handleClickFullscreen={this.handleClickFullscreen}
                    muted={this.state.muted}
                    playbackRate={this.state.playbackRate}
                    loaded={this.state.loaded}
                    playing={this.state.playing}
                    settingActive={this.state.settingActive}
                    buffering={this.state.buffering}
                    played={this.state.played}
                    duration={this.state.duration}
                    mode="sl"
                  />
                </div>
              </div>
              {/* {ReactPlayer.canPlay(urll) ? ( ) : null} */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReactPlayerTest;
