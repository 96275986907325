import React from "react";

// Customizable Area Start

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  laptop,
  boygirl,
  tickmark,
} from "./assets";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";

import PopularCourses from "../../email-account-registration/src/PopularCourses.web";

import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    emailBanner: {
      position: "relative",
    },
    emailBannerTitleContainer: {
      position: "absolute",
      bottom: "45px",
      left: "24%",
      transform: "translate(-24%, -9%)",
      fontSize: 41,
      color: "#fff",
    },
    emailBannerTitle: {
      fontSize: 41,
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: 36,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
      },
    },
    emailBannerLMBtn: {
      marginTop: 25,
      borderRadius: 100,
      backgroundColor: "#f0d17c",
      width: 180,
      height: 50,
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 20,
        height: 30,
        width: 130,
        fontSize: 14,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 15,
        height: 30,
        width: 100,
        fontSize: 12,
      },
      [theme.breakpoints.down("xs")]: {
        height: 20,
        width: 90,
        fontSize: 10,
      },
    },
    emailLoginpasswordContainer: {
      minHeight: 400,
      background: "#f7f7f7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: 25,
      paddingLeft: "17vw",
      paddingRight: "17vw",
      [theme.breakpoints.up("xl")]: {
        minHeight: 500,
      },
      [theme.breakpoints.down("sm")]: {
        columnGap: 0,
        flexDirection: "column",
        rowGap: 25,
      },
      [theme.breakpoints.down("xs")]: {
        margin: 5,
      },
      [theme.breakpoints.down("md")]: {
        margin: 5,
      },
    },
    emailLoginbanerImg: {
      [theme.breakpoints.up("xl")]: {
        height: 400,
      },
      [theme.breakpoints.between(1280, 1336)]: {
        height: 300,
      },
      height: 350,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 250,
      },
    },
    emailForgotpasswordpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "35em",
      borderRadius: "20px",
      padding: "1em 2em 1em 2em",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto",
      },
    },
    emailLoginTextfield: {
      width: "100%",
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.down("xs")]: {
        width: "212px",
      },
    },
    emailCancelbutton: {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "10em",
      border: "1px solid red",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    emailLoginsubmitButton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "150px",
      marginLeft: "10px",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    emailTickIcon: {
      height: "50px",
      width: "50px",
      justifyContent: "center",
      marginLeft: "189px",
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "90px",
      },
    },

    resetpassword: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px,50px,20px,50px !important",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("xs")]: {
        width: "250px",
      },
    },
    
    resetpasswordloginbutt: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "170px",
      color: "#fff",
      justifyContent: "center",
      marginLeft: "146px",
      top: "27px",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "34px",
      },
    },
    tickmarkloginbutt: {
      height: "80px",
      width: "80px",
      justifyContent: "center",
      marginLeft: "200px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "77px",
        textalign: "center",
      },
    },
    loginpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "220px",
      height: "150px",
      borderRadius: "20px",
      padding: "2px 27px 49px 27px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto",
      },
    },

    RelatedCourseCard: {
      width: 287,
      height: "100%",
    },
    courseDescription: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8vw",
      fontWeight: 400,
      lineHeight: "2em",
      maxLines: 3,
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 3,
      minHeight: "6em",
    },
    courseDescriptionsmall: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8vw",
      fontWeight: 400,
      lineHeight: "2em",
      maxLines: 2,
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      minHeight: "4em",
    },
  });
// Customizable Area End

import EmailLoginController, { Props } from "./EmailLoginController.web";
import LoginInputSection from "./LoginInputSection.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

export class EmailLogin extends EmailLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <div className={classes.root}>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />

        {/* Banner */}

        <div className={classes.emailBanner}>
          <img src={boygirl} alt="" style={{ width: "100%", height: "auto" }} />
          <div className={classes.emailBannerTitleContainer}>
            <Typography className={classes.emailBannerTitle}>
              <b>
                Wide Range of Courses <br /> Available
              </b>
            </Typography>
            <Button className={classes.emailBannerLMBtn}>Learn More</Button>
          </div>
        </div>

        <div className={classes.emailLoginpasswordContainer}>
          {this.state.resetpassword ? (
            <div className={classes.resetpassword} />
          ) : (
            <>
              <LoginInputSection
                loginFailed={this.state.loginFailed}
                handleOpen={this.handleOpen}
                failedRes={this.state.failedRes}
                navigation={this.props.navigation}
                doEmailLogInt={this.handleEmailLogin}
              />
              <div style={{ width: "50%" }}>
                <img src={laptop} alt="" className={classes.emailLoginbanerImg} />
              </div>
            </>
          )}
        </div>
        {/* reset password */}
        <Modal
          open={this.state.opensubmit}
          // open={false}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.emailForgotpasswordpopup}>
            <h2 style={{ color: "#ff0000" }}>Forgot Password</h2>
            <hr />
            <div>
              <img className={classes.emailTickIcon} src={tickmark} alt="" />
            </div>
            <br />
            <p>We have send a link for reseting your password to the mentioned email address</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <div>
                <Button
                  onClick={this.handleCloseEmail}
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#ffffff",
                    width: "150px",
                    border: "1px solid red",
                    textTransform: "none",
                    marginTop: "10px",
                  }}
                  variant="outlined"
                >
                  <b>Close</b>
                </Button>
              </div>
              <div />
            </div>
          </div>
        </Modal>
        {/* reset password */}

        {/* Email Link */}
        <Modal
          open={this.state.open}
          // open={false}
          style={{
            position: "absolute",
            margin: "auto 0px",
            borderRadius: "20px",
          }}
        >
          <div className={classes.emailForgotpasswordpopup}>
            <h2 style={{ color: "#ff0000" }}>Forgot Password</h2>
            <hr />
            Please enter your registered email address in the field below and we will sending you the link to
            reset you password
            <div>
              <br />
              <b>
                Email<sup style={{ color: "red" }}>*</sup>
              </b>
              <form onSubmit={this.resetpasswordlink}>
                <TextField
                  style={this.state.emailFailedError ? { backgroundColor: "#fcecec" } : {}}
                  type="email"
                  name="email"
                  required
                  className={classes.emailLoginTextfield}
                  id="outlined-basic"
                  placeholder="Enter Email"
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({ emailforgot: e.target.value });
                    this.handleResetPwd(e)
                    }}
                  value={this.state.emailforgot}
                />
              {this.state.openResetPwdError && this.state.emailforgot &&
                 <p style={{color: "red", textAlign: "right"}}>{this.state.resetPwdErrorData}</p>
                }
                {this.state.failedResemail && (
                  <Typography style={{ color: "red", textAlign: "right" }}>
                    {" "}
                    {this.state.failedResemail}
                  </Typography>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <Button onClick={this.handleClose} className={classes.emailCancelbutton} variant="outlined">
                      <b>Cancel</b>
                    </Button>
                  </div>
                  <div>
                    <div>
                      <Button
                        type="submit"
                        className={classes.emailLoginsubmitButton}
                        variant="contained"
                      // disabled={!this.state.emailforgot}
                      data-testId="resetPasswordBtn" 
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
        <PopularCourses
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
          classes={undefined}
        />
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
export default withStyles(styles, { withTheme: true })(EmailLogin);
