import React, { Component } from "react";

// Customizable Area Start
import { Divider } from "react-native-elements";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  thirdcard,
  redsidearrow,
  sidearrow,
  message1,
  message2,
  message3,
  message4,
  message5,
  message6,
  chatprofile,
} from "./assets";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";

import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {

    },
    teacherMessagesMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    teacherMessagesLeftSection: {
      flex: 2,
    },
    teacherMessagesRightSection: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    teacherMessagesTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    }, 
    sendmesssgaebutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      // marginLeft: "350px",
      color: "#fff",
      // marginTop: "20px",
      textTransform: "none",
      height: 45,
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    chatSec:{
      "&::-webkit-scrollbar": {
        width: "0.5em",
      },
    }

  })
// Customizable Area End

import TeacherMessagesController, { Props } from "./TeacherMessagesController.web";

class TeacherMessages extends TeacherMessagesController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start'
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.teacherMessagesMainSection}>
        <div className={classes.teacherMessagesLeftSection}>
          <DashSidebar1 active="Messages" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.teacherMessagesRightSection}>
             <div>
            <Typography className={classes.teacherMessagesTitle}>Messages</Typography>
            </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>

            <Grid container style={{ display: "flex" }}>
              <Grid item xs={4} style={{ marginTop: "15px" }}>
              <div className={classes.chatSec}style={{ overflow: "scroll", height: 684 }}>
                  <div>
                    <div style={{ display: "flex", marginTop: "2vw", backgroundColor: "#FF0000", padding: "12px" }}>
                      <img src={message1} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, color: "#FFFFFF", }}>
                          <Typography>Name</Typography>
                          <Typography>12:48PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, color: "#FFFFFF", marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                      <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                      <div>
                        <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                          <Typography>Name</Typography>
                          <Typography>9:30PM</Typography>
                        </div>
                        <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={8} style={{ marginTop: "15px" }}>
                <div style={{ width: 600, height: 800, backgroundColor: "#FFFFFF", marginTop: 30, }}>
                  <div style={{ display: "flex", marginLeft: 20, }}>
                    <img src={chatprofile} alt="" style={{ width: "58", height: "58", }} />
                    <div style={{ marginLeft: 20 }}>
                      <div>
                        <Typography style={{ color: "#333333", fontSize: 22, fontWeight: 550 }}>Name</Typography>
                      </div>
                      <div>
                        <Typography>Online</Typography>
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "#ECECF1", border: "1px solid #ECECF1", width: "571px" }}></hr>

                  <div style={{ overflow: "scroll", height: 578 }}>
                    <div style={{ marginLeft: "250", padding: 15 }}>
                      <Typography>Today</Typography>
                    </div>
                    <div style={{ backgroundColor: "#F9EFD5", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 205, }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                      <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F9EFD5", width: 500, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 58, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                    </div>

                    <div style={{ marginLeft: "250", padding: 15 }}>
                      <Typography>April 3, 2022</Typography>
                    </div>
                    <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                      <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F9EFD5", width: 250, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 306, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor</Typography>
                      <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                      <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                      <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F9EFD5", width: 250, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 306, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor</Typography>
                      <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                      <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F9EFD5", width: 250, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 306, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor</Typography>
                      <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F9EFD5", width: 500, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 58, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                    </div>

                    <div style={{ backgroundColor: "#F9EFD5", width: 500, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 58, marginTop: 10 }}>
                      <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                      <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                    </div>
                  </div>

                  <div>
                    <div style={{ backgroundColor: "#F0D17C", display :'flex', padding: 20,  marginTop: 30 }}>
                      <TextField style={{ backgroundColor: "#FFFFFF", width: 247 }} id="outlined-basic" placeholder="Type a message" variant="outlined" />
                      <AttachFileIcon style={{marginTop:'18px',marginRight:'15px',marginLeft:'14px'}} />
                      <Button className={classes.sendmesssgaebutton} variant="contained" style={{marginTop:'5px'}} >
                        Send Message
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
        </div>

      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </>


      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
export default withStyles(styles, { withTheme: true })(TeacherMessages);


