import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class TeachersDashboardAssignmentController extends BlockComponent<
Props, S, SS
> {

    // Customizable Area Start


   // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

     // Customizable Area Start

     this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
      ];

      this.state = {
        // Customizable Area Start


        // Customizable Area End
      };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    
  }

  async componentWillMount() {
    // Customizable Area Start


   // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
 


    // Customizable Area End
  }


   // Customizable Area Start

   
   // Customizable Area End


}
