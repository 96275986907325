import React from "react";
import Banner from "../../email-account-registration/src/Components/Banner.web";
import ContactUsController, { Props } from "./ContactUsController.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button,Box } from "@material-ui/core";
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import {
  contactUsCollapse,
  contactUsEmail,
  contactUsExpand,
  contactUsLoc,
  contactusPhone,
  contactUsSec2Img,
  dropdown,
} from "./assets";
import ContactUsMap from "./components/ContactUsMap.web";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    contactUs: {},
    contactUs_sec_2_c: {
      backgroundColor: "#f7f7f7",
      paddingLeft: "17%",
      paddingRight: "17%",
    },
    contactUs_sec_2_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.7vw",
      paddingTop: "2vw",
      margin: 0,
      marginBottom: "2vw",
    },
    CONTACT_sub_c: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    CONTACT_sub_btn: {
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      width: "15vw",
      height: "2.8vw",
      marginBottom: "6vw",
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8vw",
      fontWeight: 700,
      textTransform: "none",
    },
    contactUs_sec_3_c: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "17%",
      paddingRight: "17%",
      // marginTop: '8vw',
      backgroundColor: "#ffffff",
      position: "relative",
      height: "25vw",
      alignItems: "center",
    },
    contactUs_sec_4_c: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "4vw",
      background: "linear-gradient(#14292d 50%, #ffffff 0%)",
    },
    contactUs_sec_4_Map_c: {
      width: "66vw",
      height: "22vw",
    },
    contactUs_sec_4_title: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "2vw",
      marginTop: "3vw",
      marginBottom: "3vw",
    },
    contactUs_sec_5_c: {
      paddingLeft: "17vw",
      paddingRight: "17vw",
      marginBottom: "4vw",
    },
    contactUs_sec_5_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "2vw",
      marginTop: "3vw",
      marginBottom: "3vw",
    },
    contactUs_accord_cc: {
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)",
    },
    contactUs_Hr: {
      borderBottom: "0.5px solid #f0d17c",
    },
    contactUs_accord_img: {
      width: "1.3vw",
    },
    contactUs_accord_heading: {
      color: "#333333",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "1vw",
      marginTop: "0.5vw",
      marginBottom: "0.5vw",
    },
    contactUs_accord_title: {
      padding: "0px",
    },
    contactUs_accord_subtitle: {
      padding: "0px",
    },
    contactUs_accord_subheading: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.9vw",
      fontWeight: 400,
      lineHeight: "2em",
    },

  });
// Customizable Area End

export class ContactUs extends ContactUsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className={classes.contactUs}>
          <div className={classes.contactUs_sec_2_c}>
            <p className={classes.contactUs_sec_2_title}>Send Us a Message</p>
            <div className="prl-input-cc-ContactUS">
              <div className="prl-input-left-ContactUS">
                <label className="prl-input-lable-ContactUS">
                  I am a<sup className="required">*</sup>
                </label>
                <div style={{position:"relative"}}>
                  <select
                    className="prl-input-ContactUS "
                    value={this.state.cType}
                    onChange={(e) => {
                      this.handleLernerChange(e);
                    }}
                    style={this.state.cType ? this.errStyle : {}}
                    data-testId="ContactUsCType"
                  >
                    <option value="learner" data-key="Empty">
                      Learner
                    </option>
                    <option value="teacher" data-key="Empty">
                      Teacher
                    </option>
                  </select>
                  <span
                    className="prl-input-arrow-ContactUS"
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                </div>
              </div>
              <div className="prl-input-right-ContactUS">
                <label className="prl-input-lable-ContactUS">
                  What can we help you with<sup className="required">*</sup>
                </label>
                <div style={{position:"relative"}}>
                  <select
                    className="prl-input-ContactUS"
                    value={this.state.selectHelpList}
                    onChange={(e) => {
                      this.handleHelpListChange(e);
                    }}
                    style={this.state.errqueryTypeId ? this.errStyle : {}}
                    data-testId="ContactUsHelpList"
                  >
                    <option value="select" className="prl-input-dd-s-p-ContactUS">Please Select</option>
                    {this.state.helpList.map((val: any, key: any) => {
                      return (
                        <option value={val.id} key={val.id}>
                          {val.query_for}
                        </option>
                      );
                    })}
                  </select>
                  <span className="prl-input-arrow-ContactUS">
                    <img src={dropdown} alt="arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="prl-input-cc-ContactUS">
              <div className="prl-input-left-ContactUS">
                <label className="prl-input-lable-ContactUS">
                  Your Name<sup className="required">*</sup>
                </label>
                <input
                  className="prl-input-ContactUS"
                  type="text"
                  value={this.state.fName}
                  required
                  onChange={(e) => {
                    this.handleFNameChange(e);
                  }}
                  placeholder="Enter Name"
                  style={this.state.errFName ? this.errStyle : {}}
                  data-testId="ContactUsFirstNameInput"
                />
                {this.state.errFName ? <p className="ContactUs_prl-input-err-txt">Please enter a value. </p> : <></>}
                {this.state.errMaxFName ? <p className="ContactUs_prl-input-err-txt">Maximum 15 characters</p> : <></>}
              </div>
              <div className="prl-input-right-ContactUS">
                <label className="prl-input-lable-ContactUS">
                  Email<sup className="required">*</sup>
                </label>
                <input
                  className="prl-input-ContactUS"
                  type="email"
                  value={this.state.cemail}
                  required
                  onChange={(e) => {
                    this.handleEmailChange(e);
                  }}
                  placeholder="email@gmail.com"
                  style={this.state.errEmail ? this.errStyle : {}}
                  data-testId="ContactUsEmailIdInput"
                />
                {this.state.errEmail ? (
                  <p className="ContactUs_prl-input-err-txt">Please enter a valid email address.</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="prl-input-cc-message-ContactUS">
              <div className="prl-input-message-ContactUS">
                <label className="prl-input-lable-ContactUS">
                  Your Message<sup className="required">*</sup>
                </label>
                <textarea
                  className="massage-box-ContactUs"
                  value={this.state.description}
                  required
                  onChange={(e) => {
                    this.handleDescriptionChange(e);
                  }}
                  placeholder="Enter Details"
                  cols={10}
                  rows={10}
                  style={this.state.errDescription ? this.errStyle : {}}
                  data-testId="ContactUsDescriptionInput"
                />
                {this.state.errDescription ? (
                  <p className="ContactUs_prl-input-err-txt">Please enter a value.</p>
                ) : (
                  <></>
                )}
                {this.state.errMaxDescription ? (
                  <p className="ContactUs_prl-input-err-txt">Maximum 80 characters</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={classes.CONTACT_sub_c}>
              <Button
                className={classes.CONTACT_sub_btn}
                variant="contained"
                type="submit"
                onClick={() => {
                  this.handleSubmit();
                }}
                data-testId="ContactUsSubmitBtn"
              >
                Submit
              </Button>
            </div>
            {/* MODAL START HERE */}
            <Dialog
              open={this.state.open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box display='flex' justifyContent='center' pt={1}>
                <Box width={40} height={40} borderRadius={50} style={{ backgroundColor: '#d8bc6f' }}>
                  <DoneOutlinedIcon style={{ color: 'red',alignItems: "center", fontSize: '40px' }} />
                </Box>
              </Box>
              <Box justifyContent='center'>
                <DialogTitle id="alert-dialog-title">{'Succesfully Submitted'}</DialogTitle>
              </Box>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button size="small" variant="contained" onClick={this.handleClose} style={{ backgroundColor: 'red' }}>
                  Ok
                </Button>

              </DialogActions>
            </Dialog>
            {/* MODAL END HERE */}
          </div>
          <div className={classes.contactUs_sec_3_c}>
          <div className="contactUs-sec-2-txt-ContactUS">
              <div className="contactUs-sec-2-title-ContactUS">
                <p>Keep in touch with us</p>
              </div>
              <div className="contactUs-sec-2-sub-c">
              <div className="contactUs-sec-2-txt-subtxt-ContactUS">
                  <div className="contactUs-sec-2-txt-s-ContactUS">
                    <img src={contactUsLoc} alt="" />
                  </div>
                  <p className="contactUs-sec-2-txt-t-ContactUS">{this.state.MapLocation.address}</p>
                </div>
                <div className="contactUs-sec-2-txt-subtxt-ContactUS">
                  <div className="contactUs-sec-2-txt-s-ContactUS">
                    <img src={contactUsEmail} alt="" />
                  </div>
                  <p className="contactUs-sec-2-txt-t-ContactUS">{this.state.MapLocation.email}</p>
                </div>
                <div className="contactUs-sec-2-txt-subtxt-ContactUS">
                  <div className="contactUs-sec-2-txt-s-ContactUS">
                    <img src={contactusPhone} alt="" />
                  </div>
                  <p className="contactUs-sec-2-txt-t-ContactUS">{this.state.MapLocation.full_phone_number}</p>
                </div>
              </div>
            </div>
            <div className="contactUs-sec-2-img-ContactUS">
              <img src={contactUsSec2Img} alt="img" />
            </div>
          </div>
          <div className={classes.contactUs_sec_4_c}>
            <p className={classes.contactUs_sec_4_title}>View us on Map</p>
            <div className={classes.contactUs_sec_4_Map_c}>
              <ContactUsMap
                MapLocationLatitude={this.state.MapLocation.latitude}
                MapLocationLongitude={this.state.MapLocation.longitude}
              />
            </div>
          </div>
          <div className={classes.contactUs_sec_5_c}>
            <p className={classes.contactUs_sec_5_title}>FAQs</p>
            <div
            // className={classes.contactUs_sec_5_Accord_c}
            >
              {this.state.FAQList.map((val: any, key: any) => {
                return (
                  <>
                    <Accordion
                      key={val.id}
                      className={classes.contactUs_accord_cc}
                      expanded={this.state.expand === val.id}
                      onChange={(event: any, isExpanded: boolean) => {
                        this.handleExpand(isExpanded, val.id);
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          this.state.expand == val.id ? (
                            <img src={contactUsCollapse} alt="" className={classes.contactUs_accord_img} />
                          ) : (
                            <img src={contactUsExpand} alt="" className={classes.contactUs_accord_img} />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.contactUs_accord_title}
                      >
                        <Typography className={classes.contactUs_accord_heading}>{val.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.contactUs_accord_subtitle}>
                        <Typography className={classes.contactUs_accord_subheading}>{val.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                    <div className={classes.contactUs_Hr} />
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ContactUs);
