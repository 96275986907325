import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Country, State, City } from "country-state-city";
let initialValues = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  mobileNo: "",
  countryCode:"",
  email: "",
  currentlyWorking:"",
  qualification:"",
  collegeName:"",
  coursesOfInterests:"",
  specialization:"",
  otherLanguages:"",
  country:"",
  city:"",
  address:"",
  state:"",
  CVUploaded:"",
  governmentId:"",
  languagesKnown:{
    english:true,
    hindi:false,
    others:true
  }

};
export const configJSON = require("./config");
  // Customizable Area Start
  
  // Customizable Area End


export interface Props {
  history: any;
  location: any;
  navigation: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End

}

export interface S {
  // Customizable Area Start
    userInputValues:any
    cvUploaded:any,
    govtIdUploaded:any
    updateProfilePicModalStatus:any
    takePhotoModalStatus:any
    teacherImage:any
    previewConfirmModalStatus:any
    uploadPicFile:any
    previewUploadFile:any
    errorStatusFirstName:any
    errorStatusLastName:any
    errFirstName:any
    errLastName:any
    errDate:any
    errEmail:any
    errPhone:any
    errMaxPhone:any
    errAddress:any

    countriesList:any
    listOftheStates:any
    citiesList:any
    
    errCountry:boolean
    errState:boolean
    errCity:any

    sCountry: any
    sCountryCode: any
    sState: any
    sStateCode: any
    sCity: string;
    sCityCode: string;
  
    profileData:any

    formDataPic: any;

    qualifactionListData:any
    cousesInterestListData:any
  
    selectedState:any
    selectedCity:any
   
    errFile1:any
    
    qualificationId:any
    opensucessMessageModal:any
    isLoading:any
    

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeachersProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sampleAPIId: any;
  errStyle:any
  getProfileDetailsId: string = "";
  getTeachersQualificationId: string = "";
  getTeachersCoursesInterestId: string = "";
  teachersupdateCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      userInputValues: initialValues,
      cvUploaded:"",
      govtIdUploaded:"",
      updateProfilePicModalStatus:false,
      takePhotoModalStatus:false,
      teacherImage:"",
      previewConfirmModalStatus:false,
      uploadPicFile:"",
      previewUploadFile:"",
      errorStatusFirstName:false,
      errorStatusLastName:false,
      errFirstName:false,
      errLastName:false,
      errDate:false,
      errEmail:false,
      errPhone:false,
      errMaxPhone:false,
      errAddress:false,

      countriesList:[],
      listOftheStates:[],
      citiesList:[],

      errCountry:false,
      errState:false,
      errCity:false,


      sCountry: "",
      sCountryCode: "",
      sState: "",
      sStateCode: "",
      sCity: "",
      sCityCode: "",
      profileData:{
        attributes:{}
      },

      formDataPic: new FormData(),


    qualifactionListData:[],
    cousesInterestListData:[],

    selectedState:"",
    selectedCity:"",

    errFile1:false,
    
    qualificationId:"",

    opensucessMessageModal:false,
    isLoading:true
    };
    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };
  
    //Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const country_list = Country.getAllCountries();
    this.setState({ countriesList: country_list });
     this.getTeacherQualificationList()
     this.getTeacherCoursesOfInterestList()
    this.getTeacherProfileDetails()
   
   
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.getProfileDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      
      if(apiResponse?.error){
         alert(apiResponse?.error)
      } else {
        this.setState({
          profileData: apiResponse.data,
          isLoading:false
        })

        const languagesArray = await apiResponse?.data?.attributes?.academic?.data?.attributes?.known_languages
        const otherlanguages = await apiResponse?.data?.attributes?.academic?.data?.attributes?.other_languages
        let isEnglishChecked = false
        let isHindiChecked = false
        if(languagesArray.length){
         isEnglishChecked = languagesArray[0].includes("english")
         isHindiChecked  = languagesArray[0].includes("hindi")}
        else{
          isEnglishChecked = false
          isHindiChecked = false
        }
        const isOthersChecked = otherlanguages===""?false:true
        const {first_name,last_name,phone_number,country_code,qualification,email} = await apiResponse?.data?.attributes
        const {date_of_birth,country,state,city,address} = await apiResponse?.data?.attributes?.academic?.data?.attributes?.account  
        const {currently_working,specialization,interest,college_name} =await apiResponse?.data?.attributes?.academic?.data?.attributes
        const selectedcurrentlyworking = currently_working?"Yes":"No"
          this.setState(prevState => ({
          ...prevState,
          userInputValues: {
              ...prevState.userInputValues,
              languagesKnown: {
                  ...prevState.userInputValues.languagesKnown, 
                  english:isEnglishChecked,
                  hindi:isHindiChecked,
                  others:isOthersChecked
              },
              city:city,
              state:state,
              country:country,
              countryCode:country_code,
              address:address,
              firstName:first_name,
              lastName:last_name,
              dateOfBirth:date_of_birth,
              mobileNo:phone_number,
              qualification:qualification,
              currentlyWorking:selectedcurrentlyworking,
              specialization:specialization,
              coursesOfInterests:interest?.name,
              collegeName:college_name,
              email:email,
              otherLanguages:otherlanguages,
          }
      }))
      }  
    }
    
    if (this.getTeachersQualificationId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if(apiResponse?.error){
      
        alert(apiResponse?.error)
      } else {
        this.setState({
          qualifactionListData: apiResponse
        })
      }  
    }
    if (this.getTeachersCoursesInterestId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
       alert( apiResponse?.errors[0].token)
      } else {
        
        this.setState({
          cousesInterestListData: apiResponse
        })
      }  
    }


    if (this.teachersupdateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.error){
         alert(apiResponse.error)
      } else {
        this.setState({
          profileData: apiResponse.data
        })
        this.getTeacherProfileDetails()
        this.handleTeacherUploadProfileConfirmModal(false)
        this.handleTeacherUploadProfilePicture(false)
        this.handleteacherSucessMessageModal(true)
      }  
    }
 
    // Customizable Area End
  } 

  handleInputChange=async(e: any)=>{
    const {value,name} = e.target
  if(name=="firstName"){

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      this.setState({ userInputValues: {
        ...this.state.userInputValues,
        [name]: value,
      } });
    }

    if (value.length > 15) {
      this.setState({ errorStatusFirstName: true });
    } else {
      this.setState({ errorStatusFirstName: false });
    }

  }

  else if(name=="lastName"){
    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      this.setState({ userInputValues: {
        ...this.state.userInputValues,
        [name]: value,
      } });
    }

    if (value.length > 15) {
      this.setState({ errorStatusLastName: true });
    } else {
      this.setState({ errorStatusLastName: false });
    }

  }

  else if(name=="email"){
    this.setState({ userInputValues: {
      ...this.state.userInputValues,
      [name]: value,
    } });

    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
   const VEmail = this.state.userInputValues.email.match(regexEmail)
    
    if (this.state.userInputValues.email.length <= 0 || VEmail == null) {
      this.setState({ errEmail: true });

    } else {
      this.setState({ errEmail: false });
    }
  }
    
  else if(name=="mobileNo"){
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
       this.setState({ userInputValues: {
        ...this.state.userInputValues,
        [name]: value,
      } });
    }

    if (this.state.userInputValues.mobileNo.length > 15) {
      this.setState({ errMaxPhone: true });
    } else {
      this.setState({ errMaxPhone: false });
    }
  }else{
    this.setState({ userInputValues: {
      ...this.state.userInputValues,
      [name]: value,
    } });
  }

  } 

  handleDateChange=async(e: any)=>{
  const {value,name} = e.target
    this.setState({ userInputValues: {
      ...this.state.userInputValues,
      [name]: value,
    } });
    let select_date = new Date(this.state.userInputValues.dateOfBirth)

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (select_date > today) {
      this.setState({ errDate: true });
    } else {
      this.setState({ errDate: false });
    }
  }

  async handleCountryChange(e: any) {
    e.persist();
    const value = e.target.value;

    const filtered_country = this.state.countriesList[value];
    this.setState({
      sCountry: filtered_country.name,
      sCountryCode: filtered_country.isoCode,
    });

   this.setState(prevState => ({
      ...prevState,
      userInputValues: {
          ...prevState.userInputValues,
          country:filtered_country.name,
          city:"",
          state:""
      }
  }))

    const state_list = await State.getStatesOfCountry(this.state.sCountryCode);
    this.setState({ listOftheStates: [] });
    this.setState({ listOftheStates: state_list });
    this.setState({ citiesList: [] });
    
    if (
      this.state.sCountry.length <= 0 ||
      this.state.sCountry == "Select Country"
    ) {
      this.setState({ errCountry: true });
    } else {
      this.setState({ errCountry: false });
    }
  }

  async handleStateChange(e: any) {
    e.persist();

    const value = e.target.value;
    const filtered_State = this.state.listOftheStates[value];
    this.setState({
      sState: filtered_State.name,
      sStateCode: filtered_State.isoCode,
    });


    const city_list = await City.getCitiesOfState(
      this.state.sCountryCode,
      this.state.sStateCode
    );


    this.setState(prevState => ({
      ...prevState,
      userInputValues: {
          ...prevState.userInputValues,
          state:filtered_State.name,
      }
  }))

    this.setState({ citiesList: city_list });
    if (this.state.sState.length <= 0 || this.state.sState == "Select State") {
      this.setState({ errState: true });
    } else {
      this.setState({ errState: false })
    }
  }
  async handleCityChange(e: any) {
    e.persist();
    this.setState({ sCity: e.target.value });
    this.setState(prevState => ({
      ...prevState,
      userInputValues: {
          ...prevState.userInputValues,
          city:e.target.value,
      }
  }))

    
    if (this.state.sCity.length <= 0 || this.state.sCity == "Select City") {
      this.setState({ errCity: true });
    } else {
      this.setState({ errCity: false });
    }
  }

  handleFormValue=(e:any)=>{
    e.preventDefault()
  }
  handleCheckBoxChanges=(event:any)=>{
   const {name,checked} = event.target
    this.setState(prevState => ({
      ...prevState,
      userInputValues: {
          ...prevState.userInputValues,
          languagesKnown: {
              ...prevState.userInputValues.languagesKnown, 
              [name]: checked,
          }
      }     
  }))


  if(name=="others" && checked ===false){
    this.setState(prevState => ({
      ...prevState,
      userInputValues: {
        ...prevState.userInputValues,
        otherLanguages:"",
    }
  }))
  }


  }
  handleUploadedCvFile=(e:any)=>{
    const files = e.target.files[0];
    this.setState({cvUploaded:files})
    this.setState({ errFile1: false });
   }

   handleUploadedGovtFile=(e:any)=>{
    const files = e.target.files[0];
    this.setState({govtIdUploaded:files})
   }
   handleTeacherUploadProfilePicture=(value:any)=>{
    this.setState({
      updateProfilePicModalStatus:value
    })
   }

   handleteacherTakePhotoModal=(value:any)=>{
    this.handleTeacherUploadProfilePicture(false)
    this.setState({
      takePhotoModalStatus:value
    })
   }

   handleteacherTakePhotoCapture=async(value:any)=>{
   this.setState({
      uploadPicFile: "",
    });

    this.setState({
      teacherImage:value
    })
    this.handleteacherTakePhotoModal(false)
    this.handleTeacherUploadProfileConfirmModal(true)
   }

   handleTeacherUploadProfileConfirmModal=(value:any,cancel?:any)=>{
    this.setState({
      previewConfirmModalStatus:value
    })
    if(cancel)
    this.setState({
      uploadPicFile: "",
      previewUploadFile:""
    });
   }


   handleTeacherUploadProfilePictureUpdate=async(event:any)=>{ 
    if (event.target.files && event.target.files[0]) {
      this.setState({
        uploadPicFile: event.target.files[0],
        previewUploadFile: URL.createObjectURL(event.target.files[0])
      });
      this.handleTeacherUploadProfileConfirmModal(true)
    }
    this.setState({
      teacherImage:""
    })


    
   }

   checkValidations=()=>{
        //firstName validations
        if (this.state.userInputValues.firstName.length<=0) {
          this.setState({ errFirstName: true });
          } else {
          this.setState({ errFirstName: false });
          }
          if (this.state.userInputValues.firstName.length > 15) {
            this.setState({ errorStatusFirstName: true });
          } else {
            this.setState({ errorStatusFirstName: false });
          }
           //lastname validations
          if (this.state.userInputValues.lastName.length <= 0 ) {
             this.setState({ errLastName: true });
          } else {
            this.setState({ errLastName: false });
          }
          if (this.state.userInputValues.lastName.length > 15) {
            this.setState({ errorStatusLastName: true });
          } else {
            this.setState({ errorStatusLastName: false });
          }
   }
   checkValidation2=()=>{
    //Mobile Validation
    if (this.state.userInputValues.mobileNo.length <= 0 ) {
      this.setState({ errPhone: true });
    } else {
      this.setState({ errPhone: false });
    }
    if (this.state.userInputValues.mobileNo.length > 15) {
      this.setState({ errMaxPhone: true });
    } else {
      this.setState({ errMaxPhone: false });
    }


    //address validation
    if (this.state.userInputValues.address.length <= 0) {
      this.setState({ errAddress: true });
    } else {
      this.setState({ errAddress: false });
    }
    
    if (this.state.userInputValues.country<=0) {
      this.setState({ errCountry: true });
    } else {
      this.setState({ errCountry: false });
    }

    if (this.state.userInputValues.state <=0) {
      this.setState({ errState: true });
    } else {
      this.setState({ errState: false })
    }
   
      
    if (this.state.userInputValues.city <=0) {
      this.setState({ errCity: true });
    } else {
      this.setState({ errCity: false });
    }

   }

   getKnownLanguages=()=>{
    let knownlanguages = ""
    if(this.state.userInputValues.languagesKnown.english && this.state.userInputValues.languagesKnown.hindi){
      knownlanguages= "english,hindi"
    }
    else if(this.state.userInputValues.languagesKnown.english){
      knownlanguages= "english"
    }
    else if(this.state.userInputValues.languagesKnown.hindi){
      knownlanguages= "hindi"
    }
    return knownlanguages
   }

   checkCurrentlyWorking=()=>{

    if(this.state.userInputValues.currentlyWorking === "Yes"){
       return true
      }
       return false
   }

   getformDataForUpdate=()=>{
    const acdemicid = this.state.profileData.attributes?.academic?.data?.attributes?.id
    const formData = new FormData();

    const selcectedqualifationObject = this.state.qualifactionListData.find((each:any)=>{
      return each.name === this.state.userInputValues.qualification
    })
    const selcectedInterestedObject = this.state.cousesInterestListData.find((each:any)=>{
      return each.name === this.state.userInputValues.coursesOfInterests
    })
   
  
      formData.append("first_name", this.state.userInputValues.firstName)
      formData.append("last_name", this.state.userInputValues.lastName);
      formData.append("date_of_birth", this.state.userInputValues.dateOfBirth);
      formData.append("address", this.state.userInputValues.address);
    
      let knownlanguages =  this.getKnownLanguages()
      formData.append("[academic_attributes][known_languages][]", knownlanguages);
      
  
    if(this.state.userInputValues.email !==this.state.profileData?.attributes?.email){
      formData.append("email", this.state.userInputValues.email);
    }
    if(this.state.userInputValues.countryCode+this.state.userInputValues.mobileNo !==this.state.profileData?.attributes?.full_phone_number){
      formData.append("full_phone_number", `${this.state.userInputValues.countryCode+this.state.userInputValues.mobileNo}`);
    }
  
   
    if(this.state.userInputValues.country !==this.state.profileData?.attributes?.academic?.data?.attributes?.account.country){
      formData.append("country", this.state.userInputValues.country);
    }
    if(this.state.userInputValues.state !==this.state.profileData?.attributes?.academic?.data?.attributes?.account?.state){
      formData.append("state", this.state.userInputValues.state);
    }
    if(this.state.userInputValues.city!==this.state.profileData?.attributes?.academic?.data?.attributes?.account?.city){
      formData.append("city", this.state.userInputValues.city);
    }
  
    formData.append("[academic_attributes][id]",acdemicid);
  
  
  
    if(this.state.userInputValues.specialization!==this.state.profileData?.attributes?.academic?.data?.attributes?.specialization){
    
    formData.append("[academic_attributes][specialization]", this.state.userInputValues.specialization);
  }
  
  if(this.state.userInputValues.qualification!==this.state.profileData?.attributes?.qualification){
  
    formData.append("[academic_attributes][qualification_id]", selcectedqualifationObject.id);
  }
  
  if(this.state.userInputValues.collegeName!==this.state.profileData?.attributes?.academic?.data?.attributes?.college_name){
  
    formData.append("[academic_attributes][college_name]", this.state.userInputValues.collegeName);
  }
  
  if(this.state.userInputValues.coursesOfInterests!==this.state.profileData?. attributes?.academic?.data?.attributes?.interest?.name){
    formData.append("[academic_attributes][interest_id]", selcectedInterestedObject.id);
  }
  
  if(this.state.userInputValues.otherLanguages!==this.state.profileData?.attributes?.academic?.data?.attributes?.other_languages){
    formData.append("[academic_attributes][other_languages]", this.state.userInputValues.otherLanguages);
  }
  
  const cwcheck:any = this.checkCurrentlyWorking()
  if(this.state.userInputValues.currentlyWorking!==this.state.profileData?.attributes?.academic?.data?.attributes?.currently_working?"Yes":"No"){
    formData.append("[academic_attributes][currently_working]", cwcheck);
  }
      
    if(this.state.cvUploaded!==""){
      formData.append("[academic_attributes][cv]", this.state.cvUploaded);
    }
    if(this.state.govtIdUploaded!==""){
      formData.append("[academic_attributes][government_id]", this.state.govtIdUploaded);
    }



    return formData
   }




   handleTeacherUpdateData=async()=>{
    this.checkValidations()
    this.checkValidation2()

  //date  validations
   let select_date = new Date(this.state.userInputValues.dateOfBirth);
   let today = new Date();
   today.setHours(0, 0, 0, 0);
   if (select_date > today) {
     this.setState({ errDate: true });
   } 
   //email validations
   const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
   const VEmail = this.state.userInputValues.email.match(regexEmail)

     if (this.state.userInputValues.email.length <= 0 || VEmail == null) {
      this.setState({ errEmail: true });
    } 

    if(this.state.userInputValues.firstName.length <= 0 || this.state.userInputValues.lastName.length <= 0 ||this.state.userInputValues.firstName.length > 15||
      this.state.userInputValues.lastName.length > 15|| (select_date > today) || (this.state.userInputValues.email.length <= 0 || VEmail == null) 
      || this.state.userInputValues.mobileNo.length <= 0 ||this.state.userInputValues.mobileNolength > 15|| this.state.userInputValues.address.length <= 0||
      this.state.userInputValues.country<=0 ||this.state.userInputValues.state <=0 || this.state.userInputValues.city <=0
      ){
      return
    }

  //api call from here
  
  const formData  = this.getformDataForUpdate()

   const usertoken = localStorage.getItem("loginT");
   const headers = {
     token: usertoken,
   };
  
   const updateRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
   this.teachersupdateCallId = updateRequestMsg.messageId;
  

   updateRequestMsg.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.updateProfileEndpoint
   );

   updateRequestMsg.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headers)
   );
   updateRequestMsg.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.httpPostMethod
   );
   updateRequestMsg.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),
     formData
   );

   runEngine.sendMessage(updateRequestMsg.id, updateRequestMsg);
    
   }


   handleTeacherUploadProfilePictureConfirmUpdate=async()=>{
    //update the pic here
    const teacherFormData = new FormData();

    teacherFormData.append("profile_image", this.state.uploadPicFile);
    if(this.state.teacherImage){ 
     function teacherdataURLtoFile(dataurl:any, filename:any) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
      }
    let file = teacherdataURLtoFile(this.state.teacherImage,'hello.txt');
  
    teacherFormData.append("profile_image",file);
    }
    const usertkn = localStorage.getItem("loginT");
    const headers = {
      token: usertkn,
    };

    const updatePhotoTakenRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teachersupdateCallId = updatePhotoTakenRequestMsg.messageId;
   

    updatePhotoTakenRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndpoint
    );

    updatePhotoTakenRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updatePhotoTakenRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    updatePhotoTakenRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      teacherFormData
    );

    runEngine.sendMessage(updatePhotoTakenRequestMsg.id, updatePhotoTakenRequestMsg);

   }
  


  
  
  getTeacherProfileDetails(){
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };


    const getProfileDetailsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileDetailsId = getProfileDetailsMsg.messageId;
   

    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailsEndpoint
    );

    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
  
    runEngine.sendMessage(getProfileDetailsMsg.id, getProfileDetailsMsg);

  }

  getTeacherQualificationList(){

    const getQualificationListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeachersQualificationId = getQualificationListMsg.messageId;
  

    getQualificationListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qualificationsEndpoint 
    );

  
    getQualificationListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
  

    runEngine.sendMessage(getQualificationListMsg.id, getQualificationListMsg);


  }

  getTeacherCoursesOfInterestList(){
    
    const getCoursesOfInterestListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTeachersCoursesInterestId = getCoursesOfInterestListMsg.messageId;
   
    getCoursesOfInterestListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interestsEndpoint 
    );

    getCoursesOfInterestListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
  
    runEngine.sendMessage(getCoursesOfInterestListMsg.id, getCoursesOfInterestListMsg);

  }

  handleteacherSucessMessageModal=(value:any)=>{
    this.setState({opensucessMessageModal:value})
 }


 }
