import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {} from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform } from "react-native";
import moment from "moment";
import {ClassNameMap, } from '@material-ui/styles/withStyles';
// Customizable Area End

export const configJSON = require("./config");

interface IWebianrForm {
  name:{value:string, isValid:boolean},
  date:{value:Date | null, isValid:boolean},
  startTime:{value:Date | null, isValid:boolean},
  endTime:{value:Date | null, isValid:boolean},
  description:{value:string, isValid:boolean}

}
export interface Webinar {
  id: string;
  type: string;
  attributes: {
    description: string;
    id: number;
    name: string;
    scheduled_date: string;
    scheduled_time: string;
    scheduled_start_time: string;
    scheduled_end_time: string;
    agora_record: {
      id: number;
      live_streaming_token: string;
      recording_token: string;
      recording_uid: number;
      resource_id: string;
      webinar_id: number;
      sid: string;
      recording_url: string;
      created_at: string;
      updated_at: string;
      rtm_token: string;
      creator_id: number;
    };
    uid: number;
  };
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:ClassNameMap  
  closeFormHandler: Function;
  editWebinarDetails?:  {
    name:string;
    description:string;
    date:string;
    startTime:string;
    endTime:string;
    webinarId:string
  }  
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  startTime: string;
  endTime: string;
  date: string;
  name: string;
  description: string;
  webinarInputValues:IWebianrForm;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DetailWebinarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiToken = "";
  webinarId = "";
  createWebinarMessageId = "";
  updateWebinarMessageId = "";
  isEdit = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    const params = props.navigation?.state?.params;
    if (params?.webinar?.attributes) {
      this.isEdit = true;
    }
    this.state = {
      date: params?.webinar?.attributes?.scheduled_date
        ? moment(params?.webinar?.attributes?.scheduled_date).format(
            "DD/MM/YYYY"
          )
        : "",
      endTime: params?.webinar?.attributes?.scheduled_end_time
        ? moment(
            params?.webinar?.attributes?.scheduled_date +
              " " +
              params?.webinar?.attributes?.scheduled_end_time.toLocaleLowerCase(),
            "YYYY-MM-DD hh:mm a"
          ).format("hh:mm a")
        : "",
      startTime: params?.webinar?.attributes?.scheduled_start_time
        ? moment(
            params?.webinar?.attributes?.scheduled_date +
              " " +
              params?.webinar?.attributes?.scheduled_start_time.toLocaleLowerCase(),
            "YYYY-MM-DD hh:mm a"
          ).format("hh:mm a")
        : "",
      description: params?.webinar?.attributes?.description || "",
      name: params?.webinar?.attributes?.name || "",
      webinarInputValues:{
        name:{value:'', isValid:true},
        date:{value:null, isValid:true},
        startTime:{value:null, isValid:true},
        endTime:{value:null, isValid:true},
        description:{value:'', isValid:true}
      },
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.createWebinarMessageId
    ) {
      this.handleCreateWebinarSuccess(
        message.properties.RestAPIResponceSuccessMessage
      );
    }
    if (
      message.properties.RestAPIResponceDataMessage ===
      this.updateWebinarMessageId
    ) {
      this.handleUpdateWebinarSuccess(
        message.properties.RestAPIResponceSuccessMessage
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let apiToken = await getStorageData("SignUpToken");
    let loginToken =  await getStorageData('loginT')
    if(Platform.OS === 'web'){
      this.apiToken = loginToken       
      if(this.props.editWebinarDetails?.webinarId) {
        this.isEdit = true
        this.webinarId = this.props.editWebinarDetails?.webinarId || ''      
        this.setState({webinarInputValues: {...this.state.webinarInputValues, 
          name:{value: this.props.editWebinarDetails?.name, isValid: true},
          date:{value: new Date(this.props.editWebinarDetails?.date), isValid: true},
          startTime:{value: new Date(this.props.editWebinarDetails?.date.replace(/\-/g, "/") + ' ' + this.props.editWebinarDetails.startTime), isValid: true},
          endTime:{value: new Date(this.props.editWebinarDetails?.date.replace(/\-/g, "/") + ' ' + this.props.editWebinarDetails.endTime), isValid: true},
          description:{value: this.props.editWebinarDetails?.description, isValid: true} 
          }})   
      }
    }else {
      this.apiToken = apiToken;
    }    
    return Promise.resolve();
  }

  handleCreateWebinarSuccess = (data: string) => {
    if(Platform.OS === 'web') {
      this.props.closeFormHandler()      
      
    } else {
      Alert.alert(configJSON.createWebinarMessage);
      this.props.navigation.pop();
    }
  };

  handleUpdateWebinarSuccess = (data: string) => {
    if(Platform.OS === 'web') {
      this.props.closeFormHandler()      
    } else {
    Alert.alert(configJSON.updateWebinarMessage);
    this.props.navigation.pop();
    }
  };

  back = () => {
    this.props.navigation.pop();
  };

  onChangeName = (value: string) => {
    this.setState({ name: value });
  };

  onChangeDescription = (value: string) => {
    this.setState({ description: value });
  };

  onChangeDate = (value: string) => {
    this.setState({ date: value });
  };

  onChangeStartTime = (value: string) => {
    this.setState({ startTime: value });
  };

  onChangeEndTime = (value: string) => {
    this.setState({ endTime: value });
  };

  saveWebinar = (isEdit?: boolean) => {
    let endPoint = configJSON.webinarGetStreamAPILink as string;
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.apiToken,
    };

    const saveWebinarMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const { date, description, endTime, name, startTime } = this.state;
    const body = {
      name,
      description,
      scheduled_date: date,
      scheduled_start_time: startTime,
      scheduled_end_time: endTime,
    };
    if (isEdit) {
      const { webinar } = Platform.OS !== 'web' && this.props.navigation?.state?.params;
      saveWebinarMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint + `${Platform.OS === 'web'  ? this.webinarId :webinar.id}`
      );
      this.updateWebinarMessageId = saveWebinarMessage.messageId;
    } else {
      saveWebinarMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      this.createWebinarMessageId = saveWebinarMessage.messageId;
    }

    saveWebinarMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    saveWebinarMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    saveWebinarMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isEdit ? configJSON.putMethodType : configJSON.apiPostMethodType
    );
    runEngine.sendMessage(saveWebinarMessage.id, saveWebinarMessage);
  };

  onSubmit = () => {
    const { date, description, endTime, name, startTime } = this.state;
    if (!name || !description || !date || !endTime || !startTime) {
      Alert.alert(configJSON.validateInput);
      return;
    }
    this.saveWebinar(this.isEdit);
  };

  webinarInputsChangeHandler =(inputIndentifier:string, enteredValue: string |Date | null)=>{                    
    this.setState({webinarInputValues: {...this.state.webinarInputValues, [inputIndentifier] : {value:enteredValue, isValid: true}}})
  }
  
    webinarSubmitHandler = () => {
    
      const webianrData = {        
          name: this.state.webinarInputValues.name.value,
          date: this.state.webinarInputValues.date.value,
          startTime: this.state.webinarInputValues.startTime.value,
          endTime: this.state.webinarInputValues.endTime.value,
          description: this.state.webinarInputValues.description.value
      };            
  
      const nameIsValid = webianrData.name.trim().length > 0;
      const dateIsValid = webianrData.date !== null;
      const startTimeIsValid = webianrData.startTime !== null;
      const endTimeIsValid = webianrData.endTime !== null;
      const descriptionIsValid = webianrData.description.trim().length > 0;
  
      if(!nameIsValid || !dateIsValid || !startTimeIsValid || !endTimeIsValid || !descriptionIsValid) {        
  
          this.setState({webinarInputValues: {...this.state.webinarInputValues, 
              name:{value: this.state.webinarInputValues.name.value, isValid: nameIsValid},
              date:{value: this.state.webinarInputValues.date.value, isValid: dateIsValid},
              startTime:{value: this.state.webinarInputValues.startTime.value, isValid: startTimeIsValid},
              endTime:{value: this.state.webinarInputValues.endTime.value, isValid: endTimeIsValid},
              description:{value: this.state.webinarInputValues.description.value, isValid: descriptionIsValid} 
          }})        
        }else {
          this.dataTypeChangeHandler()          
        }
  }

  dataTypeChangeHandler = async () => {
    const scheduledDate = `${this.state.webinarInputValues.date.value?.getDate()}/${Number(this.state.webinarInputValues.date.value?.getMonth()) + 1}/${this.state.webinarInputValues.date.value?.getFullYear()}`
   
     this.setState({
        name:  this.state.webinarInputValues.name.value,
        description: this.state.webinarInputValues.description.value,
        date: scheduledDate,
        startTime: this.state.webinarInputValues.startTime.value?.toLocaleTimeString("en-US",{hour: '2-digit', minute:'2-digit'}) || '',
        endTime: this.state.webinarInputValues.endTime.value?.toLocaleTimeString("en-US",{hour: '2-digit', minute:'2-digit'}) || '',
    },() => this.saveWebinar(this.isEdit));
     
  }
  // Customizable Area End
}
