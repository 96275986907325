import React, { Component } from "react";
import { success } from "./assets";
import Banner from "./Components/Banner.web";
import BottomNavigation from "./BottomNavigation.web";
import OtpSuccessController, { Props } from "./OtpSuccessController.web";
import PopularCourses from "./PopularCourses.web";
import TopNavigation from "./TopNavigation.web";

export default class OtpSuccess extends OtpSuccessController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="reg-suc-container">

        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className="reg-suc-container-c">
          <div className="reg-suc-img-c">
            <img src={success} alt="success" />
          </div>
          <div className="reg-suc-title-c">
            <p className="reg-suc-title">Success!, Your Mobile Number is verified</p>
            <p className="reg-suc-sub-title">we have sent you an email with your login credentials</p>
          </div>
          <hr className="reg-suc-hor-line" />
          {/* <div className="reg-suc-hor-line"></div> */}
          <div className="reg-suc-btn-c">
            <button className="reg-suc-back-btn">Back to Profile Page</button>
            <button className="reg-suc-next-btn" onClick={this.handleStep2} data-testId="OTP_SUCCESS_handleStep2">
              Proceed to Step 2
            </button>
          </div>
        </div>
        <PopularCourses
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
          classes={undefined}
        />
        <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </div>
    );
  }
}


