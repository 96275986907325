import React from "react";



// Customizable Area Start
import {
  Grid, Card, Typography, IconButton, Tooltip, Button, Dialog, TextField
} from "@material-ui/core";
import {copyIcon} from './assets'   
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
// Customizable Area End

import WebinarsListController, {
  Props, configJSON,
} from "./WebinarsListController";
import DetailWebinar from "./DetailWebinar.web";

class WebinarsList extends WebinarsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {        
    
    return (
      // Customizable Area Start
      <>
       {this.props.isHost ? 
      <Grid container direction="column" style={{
         height:'100vh', flexWrap:'nowrap'
      }}     
       >     
        <Grid item style={{display:'flex',justifyContent:"space-between", alignItems:"center",margin:8}} >
          <Typography style={{fontSize:18, fontWeight:'bold',}}>{configJSON.webinarsListLabel}</Typography>
          <IconButton size="small" onClick={this.openCreateNew} id='createNewWebinar'>
            <Tooltip title={configJSON.createWebinarTitle}>
          <AddIcon color="primary" fontSize="large"/>
          </Tooltip>
          </IconButton>
        </Grid>
               
         <Grid item style={{margin:8}}>
          
        {this.state.listWebinar.length === 0 ? 
       <Typography style={{textAlign:'center'}}>{configJSON.noWebinarFound}</Typography> : <>{
       this.state.listWebinar.map((webinar)=>{
        return(
    <Card  key={webinar.id}
    style={{  marginTop:12, padding:8, background:'#dfdada',
            display:"flex",justifyContent:'space-between',alignItems:'center',}}>
  <Card 
  style={{ width:"100%", height:'100%', display:"flex",flexDirection:'column',justifyContent:'center',       
  padding:8,cursor:'pointer',}}
        onClick={()=>this.startCall(webinar)}
         id="selectWebinar">
    <Typography style={{  margin:4}}><span style={{fontWeight:'bold'}}>{configJSON.nameLabel}: </span>{webinar.attributes.name}</Typography>
    <Typography style={{  margin:4}}><span style={{fontWeight:'bold'}}>{configJSON.dateLabel}: </span>{webinar.attributes.scheduled_date}</Typography>
    <Typography style={{  margin:4}}><span style={{fontWeight:'bold'}}>{configJSON.startTimeLabel}: </span>{webinar.attributes.scheduled_start_time}</Typography>
    <Typography style={{  margin:4}}><span style={{fontWeight:'bold'}}>{configJSON.endTImeLabel}: </span>{webinar.attributes.scheduled_end_time}</Typography>
  </Card>
  
  <div  style={{ display:'flex', justifyContent:'space-evenly', alignItems:'center', width:300}}>
 
  <IconButton size="small" 
  id="updateWebinarButton"
  onClick={this.openEditWebinar(webinar)}
  >
    <Tooltip title='update webinar'>
  <EditIcon color="primary" fontSize="large"/>
  </Tooltip>
  </IconButton>

  <IconButton size="small" id="deleteWebinarButton"
  onClick={()=>{this.deleteAPIFunctionHandler(webinar)}}
  >
  <Tooltip title='delete webinar'>
  <DeleteForeverIcon color="error" fontSize="large"/>
  </Tooltip>
  </IconButton>
         
  <IconButton size="small" 
  onClick={()=>this.copyToClipboard(`${configJSON.webinarSharedLink}${webinar.id}/${webinar.attributes.name}`)} id='webinarLinkCopy'
  >   
  <Tooltip title='copy'>
  <img src={copyIcon} alt='copyIcon' width={32} height={32}/>
    </Tooltip>   
  </IconButton>

  </div>

   </Card>
  )})}</>
    }
      </Grid> 
      <Dialog open={this.state.isFormOpen}
      onClose={this.closeFormHandler}      
      >
      <DetailWebinar
      {...this.props}
      closeFormHandler={this.closeFormHandler}
      editWebinarDetails={this.state.editWebinar}      
      />    
      </Dialog>
    </Grid>
      :
      <Grid container direction="column"  style={{
         height:'100vh', flexWrap:'nowrap'
      }}>
           <Grid item style={{margin: 8}}>
           <Typography style={{fontSize:16, textAlign:'center', fontWeight:'bold'}} >{configJSON.webinarLinkTitle}</Typography>
           </Grid>
           <Grid item  style={{margin: 8}}>
              <TextField          
                onChange={(event)=>{this.onChangeWebinarLink(event.target.value)}}  
                value={this.state.link}              
                placeholder={configJSON.webinarLinkInputPlaceholder}
                fullWidth
                label={configJSON.webinarLinkLabel}
                variant="outlined"
                id='webinarName'
                color="primary"                
                required                           
              />
              </Grid>           
              <Grid item style={{margin: '0px auto', justifyContent:'center'}} >
                <Button color="primary" variant="contained" style={{textTransform:'none'}}
                    onClick={()=>this.startCall()}
                    id="joinWebinarButton">{configJSON.joinButtonText}</Button>
              </Grid>
      </Grid>
      }       
    
    </>
      // Customizable Area End
    );
  }
}
export default WebinarsList
// Customizable Area Start
// Customizable Area End
