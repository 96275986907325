import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
  classes: any;
}

interface S {
  // Customizable Area Start
  open: boolean;
  opensubmit: boolean;
  resetpassword: boolean;
  openResetPwdError: boolean;
  resetPwdErrorData: any;
  loginFailed: boolean;
  failedRes: any;
  emailforgot: string;
  emailFailedError: boolean;
  failedResemail: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailLoginController extends BlockComponent<Props, S, SS> {
  apiResetPasswordCallId: string = "";
  apiEmailWebLoginCallId: any;
  apiUserDetailsCallId: any;
  errStyle: any;

  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      open: false,
      opensubmit: false,
      resetpassword: false,
      openResetPwdError: false,
      resetPwdErrorData: "",
      loginFailed: false,
      failedRes: "",
      emailforgot: "",
      emailFailedError: false,
      failedResemail: "",
    };
    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId != null) {
        this.handleEmailLoginCallBack(responseJson, apiRequestCallId, errorReponse);
        this.handleResetPasswordCallBack(responseJson, apiRequestCallId, errorReponse);
        this.handleUserDetailsCallBack(responseJson, apiRequestCallId, errorReponse);
      }
    }
    // Customizable Area End
  }

  handleEmailLoginCallBack = (responseJson: any, apiRequestCallId: any, errorReponse: any) => {
    if (apiRequestCallId === this.apiEmailWebLoginCallId) {
      if (!responseJson.errors) {
        console.log("EmailLogin_Success", responseJson);
        localStorage.setItem("loginT", responseJson.meta.token);
        setStorageData("userRole", JSON.stringify(responseJson.meta.role));
        if (responseJson.meta.role == "teacher") {
          this.props.navigation.navigate("TeacherDashboard");
          window.location.reload();
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else if (responseJson.meta.role == "learner") {
          this.props.navigation.navigate("StudentDashboard");
          window.location.reload();
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      } else {
        console.log("__failedresponse", responseJson.errors);
        this.setState({
          loginFailed: true,
          failedRes: responseJson.errors[0].failed_login,
        });
      }
      console.log("EmailLogin_Response", responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  handleResetPasswordCallBack = (responseJson: any, apiRequestCallId: any, errorReponse: any) => {
    if (apiRequestCallId === this.apiResetPasswordCallId) {
      if (!responseJson.errors && !responseJson.error) {
        if (responseJson.message) {
          if (responseJson.message == "Reset password link sent successfully") {
            return this.setState({ open: false, opensubmit: true });
          }
        }
      } else {
        this.setState({ openResetPwdError: true, resetPwdErrorData: responseJson?.errors[0]?.account });
        console.log("ERR_Otp_Response", responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  handleUserDetailsCallBack = (responseJson: any, apiRequestCallId: any, errorReponse: any) => {
    if (apiRequestCallId === this.apiUserDetailsCallId) {
      if (!responseJson.errors && !responseJson.error) {
        console.log(
          "UserDetails_Response",
          responseJson?.data?.attributes?.academic?.data?.attributes?.account?.role
        );
        if (responseJson?.data?.attributes?.academic?.data?.attributes?.account?.role == "teacher") {
          this.props.navigation.navigate("TeacherDashboard");
        } else if (responseJson?.data?.attributes?.academic?.data?.attributes?.account?.role == "student") {
          this.props.navigation.navigate("StudentDashboard");
        }
      } else {
        console.log("ERR_UserDetails_Response", responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  getUserDetails_EL = async () => {
    const token = localStorage.getItem("loginT");
    const headers_TN = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getUserDetailsMsg_EL = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserDetailsCallId = getUserDetailsMsg_EL.messageId;

    getUserDetailsMsg_EL.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailsApiEndpoint
    );

    getUserDetailsMsg_EL.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers_TN)
    );
    getUserDetailsMsg_EL.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getUserDetailsMsg_EL.id, getUserDetailsMsg_EL);
  };
  handleResetPwd = (e: any) => {
    if (e.target.value == "") {
      this.setState({ openResetPwdError: false, resetPwdErrorData: "" });
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  resetpasswordlink = async (e: any) => {
    e.preventDefault();
    if (this.state.emailforgot.length < 4) {
      return this.setState({ emailFailedError: true });
    }
    // const formData = new FormData();
    // formData.append("email", this.state.emailforgot);
    // try {
    //     let response = await fetch(`${baseURL.baseURL}/reset_passwords/get_reset_password_link`, {
    //         method: "post",
    //         body: formData
    //     });
    //     const json = await response.json();
    //     if (json?.message == 'Reset password link sent successfully') {
    //         return this.setState({ opensubmit: true })
    //     } else if (json?.errors[0]?.account) {
    //         return this.setState({ failedResemail: json?.errors[0]?.account, })
    //     } else {
    //         console.log(json, 'reset__results')
    //     }
    //     console.log(json, '___jsonData')
    // } catch (error) {
    //     console.error(error);
    // }

    const httpBody = {
      email: this.state.emailforgot,
    };
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiResetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetpasswordEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.loginAPiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseEmail = () => {
    // this.setState({opensubmit:false})
    this.setState({ resetpassword: true });
    window.location.reload();
  };

  //  login function
  handleEmailLogin = async (email: any, password: any) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const attrs = {
      email: email,
      password: password,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiEmailWebLoginCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.loginAPiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.loginAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    console.log("data", data);
    return true;
  };

  componentDidMount = async () => {
    this.getUserDetails_EL();
  };
}
