Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Login";

exports.signuptxt = "New to TECHFIN?";
exports.register = "Register";
exports.forgotassword= "Forgot password?"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
//login
exports.loginAPiEndPoint = "login";
exports.loginAPiMethod = "POST";
exports.ResetPasswordMethod = "POST";
exports.resetpasswordEndPoint = "reset_passwords/get_reset_password_link";
exports.resetpassword = "reset_passwords/reset_password";
exports.resetpasswordcontentType = "multipart/form-data";
exports.ResetPasswordEndPoint = "reset_passwords/reset_password";
//coursesDetail
exports.coursesDetailMethodType = "GET";
exports.coursesDetailContentType = "application/json";
exports.coursesDetailEndPoint = "get_course";

exports.coursesListApiEndpoint = "courses";
exports.categoriesApiEndpoint = "categories/categories";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";

exports.popularCourseDataType = "application/json";
exports.popularCourseDataMethodType = "GET";
exports.popularCourseDataEndPoint = "popular_courses";

//payment
exports.buyCourseApiEnd = "account_courses";
exports.verifyCourseApiEnd = "account_courses/verify_signature";
exports.captureCourseApiEnd = "account_courses/capture";
exports.httpPostMethod = "POST";

//user details
exports.userDetailsApiEndpoint = "profile_details";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

//user details
exports.chapterApiEndpoint = "show_chapter";
//chapter Read
exports.readChapterApiEndpoint = "read_chapter";

exports.scheduledCoursesApiEnd = "account_courses/scheduled_courses";
exports.completedCoursesApiEnd = "account_courses/completed_courses";

exports.durationEndpoint = "durations";
exports.experienceEndpoint = "experiences";
exports.qualificationListEndpoint = "qualifications";
exports.scheduleEndpoint = "schedules";
exports.getHttpMethodType = "GET";
exports.hireAteacherContentType = "application/json";
exports.postHttpMethodType = "POST";
exports.hireAteacherSubmitEndpoint = "hire_teacher_request";

//payment
exports.budgetPayRequest = "budget_pay_request";
exports.budgetPayVerify = "budget_payment_verify";
exports.budgetPayCapture = "budget_payment_capture";

// View Submission Subject
exports.ViewSubmissionSubjectEndpoint = "view_submission_subject";

// View Submission List
exports.ViewSubmissionListEndpoint = "view_submission_list";

// update accept reject status
exports.httpPutMethod = "PUT";
exports.SubmissionAcceptEndpoint = "update_accept_status";
exports.SubmissionRejectEndpoint = "update_reject_status";

// remove from the list

exports.removeFromTheListEndPoint = "remove_from_list";

// Course Compleation

exports.courseCompleationEndPoint = "course_completion_percentage";

//hiringposts
exports.hiringPostsEndpoint = "hiring_posts"
exports.recentlyHiredTeachersEndpoint = "recently_hiring_teachers"

// average assessment score

exports.currentScoreEndpoint = "average_assessment_score"

// Customizable Area End
