//import { diamonds, calender, messages, usercircleadd } from './assets'

export const diamonds = require("../assets/diamonds.png");
export const messages = require("../assets/messages.png");
export const usercircleadd = require("../assets/usercircleadd.png");
//import { laptop, youngboy, logo} from "./assets";

export const laptop = require("../assets/laptop.png");
export const youngboy = require("../assets/youngboy.png");
export const logo = require("../assets/mainLogo.png");

export const viewpath = require("../assets/viewpath.png");

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const DashAdImg = require("../assets/DashAdImg.png");
export const dashArrowActive = require("../assets/dashArrowActive.png");
export const dashArrowInactive = require("../assets/dashArrowInactive.png");
export const studentPicture = require("../assets/studentPicture.png");
export const Women = require("../assets/Women.png");
export const cardBoys = require("../assets/cardBoys.png");
export const calender = require("../assets/calender.png");
export const hours = require("../assets/hours.png");
export const TClassesImg1 = require("../assets/TClassesImg1.png");
export const TClassesImg2 = require("../assets/TClassesImg2.png");
export const TClassesImg3 = require("../assets/TClassesImg3.png");
export const TClassesImgM = require("../assets/TClassesImgM.png");
export const studentDp = require("../assets/studentDp.png");
export const updatepicture = require("../assets/updatepicture.png");
export const dropdown = require("../assets/dropdown.png");
export const fileUploadIcon = require("../assets/fileUploadIcon.png");

export const takePhotoRed = require("../assets/takePhotoRed.png");
export const  takePhotoWhite = require("../assets/takePhotoWhite.png");
export const UploadPhotoRed = require("../assets/UploadPhotRed.png");
export const crossImage = require("../assets/crossImage.png");
export const InProgress = require("../assets/InProgress.png");
export const CrossImg = require("../assets/CrossImg.png");
export const tickmark = require("../assets/tickmark.png");
export const viewIconPng =  require("../assets/viewIconPng.png");
export const back = require("../assets/back.png");
export const next = require("../assets/next.png");
export const fastback = require("../assets/fastback.png");
export const fastforward = require("../assets/fastforward.png");

export const desktop = require("../assets/desktop.png");
export const screenView = require("../assets/screenView.png");
export const timeIcon = require("../assets/timeIcon.png");

export const defaultProfilePic = require("../assets/defaultProfilePic.png");

export const thirdcard = require("../assets/thirdcard.png");
export const redsidearrow = require("../assets/redsidearrow.png");
export const sidearrow = require("../assets/sidearrow.png");
export const message1 = require("../assets/message1.png");
export const message2 = require("../assets/message2.png");
export const message3 = require("../assets/message3.png");
export const message4 = require("../assets/message4.png");
export const message5 = require("../assets/message5.png");
export const message6 = require("../assets/message6.png");
export const chatprofile = require("../assets/chatprofile.png");
export const cross = require("../assets/cross.png");
export const check = require("../assets/check.png");
export const add = require("../assets/next.png");
export const shapecopy = require("../assets/ShapeCopy.png");
