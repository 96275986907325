Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TeachersDashboard9";
exports.labelBodyText = "TeachersDashboard9 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getHttpMethodType ="GET"
exports.httpPostMethod = "POST"
exports.httpPUTMethod = "PUT"
exports.updateProfileEndpoint = "update_profile"
exports.profileDetailsEndpoint = "profile_details"
exports.qualificationsEndpoint = "qualifications"
exports.interestsEndpoint = "interests"

exports.experienceEndpoint = "experiences"
exports.avalibilityEndPoint = "teacher_availability"
exports.applyJobEndpoint = "apply_job"


exports.updateteacherNotificationAPIEndPoint = "update_notification_permissions"
exports.getteacherNotificationAPIEndPoint = "get_notifications_permissions"

exports.viewSubjectDetails = "view_subject_details"



exports.scheduleClassesEndPoint = "scheduled_calsses"
exports.addedJobsEndPoint = "recently_added_jobs"


exports.profileDetailsEndPoint = "profile_details"
// Customizable Area End