import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  StatusBar,
  Image,
  SafeAreaView,
  TouchableOpacity,
  TextInput
} from "react-native";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import Scale, { verticalScale } from "../../../components/src/Scale";
import { forgotPasswordBG, backArrow } from "./assets";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  //Customizable Area Start
  renderHeaderView = () => {
    return (
      <View>
        <View style={styles.blankView} />
        <TouchableOpacity onPress={this.goBack}>
          <Image style={styles.backArrowImage} source={backArrow} />
        </TouchableOpacity>
        <Image style={styles.mainImage} source={forgotPasswordBG} />
        <Text style={styles.forgotPasswordText}>Forgot Password</Text>
        <View style={styles.mobileNoView}>
          <TextInput
            testID="EmailTestID"
            placeholder="Enter Email Id"
            placeholderTextColor="#b7b7b7"
            autoCapitalize="none"
            onChangeText={(text) => this.setState({ email: text, emailMessage: "" })}
            value={this.state.email}
          />
        </View>
        {this.state.emailMessage ?
          <Text style={styles.textDanger}>{this.state.emailMessage}</Text>
          : null
        }
        <TouchableOpacity
          onPress={this.onPressForgotPassword}
          style={styles.nextView}>
          <Text style={styles.nextText}>Next</Text>
        </TouchableOpacity>
        <View style={styles.accountView}>
          <Text style={styles.accountText}>Don't have accont? </Text>
          <TouchableOpacity onPress={this.goBackCfDedicationForm}>
            <Text style={styles.signUpText}>Sign up</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  //Customizable Area End

  render() {
    const { navigation } = this.props;

    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <SafeAreaView style={styles.mainContainer}>
              <StatusBar backgroundColor="#000" />
              {this.renderHeaderView()}
            </SafeAreaView>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
  },
  blankView: {
    height: Scale(40),
  },
  backArrowImage: {
    height: Scale(20),
    width: Scale(10),
    marginLeft: Scale(30)
  },
  mainImage: {
    height: Scale(200),
    width: Scale(200),
    resizeMode: "contain",
    alignSelf: "center",
    backgroundColor: "pink",
    marginVertical: Scale(30),
  },
  forgotPasswordText: {
    fontSize: Scale(20),
    fontWeight: "500",
    marginHorizontal: Scale(25),
  },
  mobileNoView: {
    height: Scale(50),
    marginHorizontal: Scale(25),
    borderWidth: Scale(1.5),
    justifyContent: "center",
    borderRadius: Scale(8),
    borderColor: "#b7b7b7",
    paddingHorizontal: Scale(10),
    marginTop: Scale(30),
  },
  nextView: {
    height: Scale(50),
    marginHorizontal: Scale(25),
    borderRadius: Scale(25),
    marginVertical: verticalScale(30),
    backgroundColor: "#fc0b1b",
    alignItems: "center",
    justifyContent: "center"
  },
  nextText: {
    fontSize: Scale(19),
    fontWeight: "bold",
    color: "#ffffff"
  },
  accountView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: Scale(25)
  },
  accountText: {
    fontSize: Scale(15),
    fontWeight: "500",
    color: '#565656'
  },
  signUpText: {
    color: "#fc0b1b",
    fontWeight: "600"
  },
  textDanger: {
    color: "#dc3545",
    marginLeft: Scale(25),
    marginTop: verticalScale(5)
  },
  forgotSuccessMainView: {
    flex: 1,
    backgroundColor: 'red'
  },
  forgotSuccessModalView: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center'
  },
  successIcon: {
    height: Scale(100),
    width: Scale(100)
  },
  successSuccText: {
    fontSize: Scale(16),
    fontWeight: '500',
    lineHeight: 70
  },
  okButtonView: {
    height: Scale(50),
    width: Scale(350),
    borderRadius: Scale(25),
    marginVertical: verticalScale(30),
    backgroundColor: "#fc0b1b",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: 'center'
  }

});
