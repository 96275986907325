//@ts-nocheck
import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import { cardBoys,back, next } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import {  withStyles, Theme } from "@material-ui/core/styles";
import ReactPaginate from "react-paginate";
import CircularProgress from '@material-ui/core/CircularProgress';
export const styles:any = (theme: Theme) =>
({
    root: {
    },

    jobListingMainContainer: {
      paddingTop: "3vw",
      display: "flex",
      paddingLeft: "13vw",
      paddingRight: "13vw",
    },
    jobListingLeftContainer: {
      flex: 2,
    },
    jobListingRightContainer: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
      marginBottom:"6em"
    },
    jobListingTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"

    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },

    JoblistingCourseCard:{
      width: "100%",
      height: "100%",
      boxShadow: "0px 5px 20px rgba(0,0,0,0.06)",
      borderRadius:"5px"
    },
    JoblistingCourseCardImage: {
      height: "12.4vw",
      width: "100%",
      objectFit: "cover",
    
  },
  JoblistingSubject:{
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5em",
    fontWeight: 600,
    letterSpacing: "0.1px",
    margin:"0.25em 0em"

  },
  JoblistingQualification:{
    color: "#ff0000",
    fontFamily: "Inter, sans-serif",
    fontSize: "1em",
    fontWeight: 600,
  },
  JoblistingQualificationValue:{
    color: " #666666",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.75em",
    fontWeight: 600,
    marginLeft:"0.2em"
  },
    table: {
      height: "50px",
    },
    jobList_title: {
      fontFamily: "Inter, sans-serif",
      fontSize: "25px",
      fontWeight: 500,
      margin: "10px",
    },
    descriptionForTheSubject: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8vw",
      fontWeight: 400,
      lineHeight: "2em",
      maxLines: 2,
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      minHeight: '4em',
      margin:"0.5em 0em"
  },
    jobList_Action_button:{
      width:"100%",
      display:"flex"
    },
    jobList_button:{
      background: "#ff0000",
      textTransform: "none",
      borderRadius: "25px",
      color: "#fff",
      fontWeight: 400,
      padding: "0em 2em",
      width:"50%",
      fontSize:"1em",
      marginBottom: "2em"
    },
    jobList_button_jobDeatils:{
      background: "#f0d17c",
      textTransform: "none",
      borderRadius: "25px",
      fontWeight: 600,
      width:"50%",
      fontSize:"1em",
      marginLeft:"0.5em",
      marginBottom: "2em"
    },
    jobDetailLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },

  });
// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End

import JobListingController, { Props } from "./JobListingController.web";
import { Divider } from "react-native-elements";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";


export class JobListing extends JobListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  render() {
    const { classes } = this.props; 
    return (
      // Customizable Area Start
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.jobListingMainContainer}>
        <div className={classes.jobListingLeftContainer}>
          <DashSidebar1 active="JobListings" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            Buttontitle="View your Classes"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.jobListingRightContainer}>
        <div>
              <Typography className={classes.jobListingTitle}>Job Listings</Typography>
        </div>
            <Divider style={{marginBottom:"1.2em"}}></Divider>
            {this.state.isLoading ?
              <div className={classes.jobDetailLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
              this.state.jobsList?.length===0?
              <div style={{display:"flex",justifyContent:"center",}}>No Jobs Found</div>:  
<>
        <Grid
          container
          spacing={3}
          style={{ display: "flex", flexDirection: "row"}}
          >
          {
              this.state.jobsList?.map((eachJob:any,index:any)=>{
                const {attributes} = eachJob
                return ( 
                
                <React.Fragment key={eachJob.id}>
                    <Grid item xs={4} style={{marginBottom:"1.5em"}}>
                      <Card className={classes.JoblistingCourseCard}>
                        <CardMedia>
                          <img className={classes.JoblistingCourseCardImage} src={cardBoys} alt="" />
                        </CardMedia>
                        <CardContent>
                          <Typography className={classes.JoblistingSubject}>{attributes.subject}</Typography>
                          <div>
                            <span className={classes.JoblistingQualification}>Qualification:</span>
                            <span className={classes.JoblistingQualificationValue}>{attributes.qualification}</span>
                          </div>
                          <Typography variant="body2" component="p" className={classes.descriptionForTheSubject}>
                            {attributes.description}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <div className={classes.jobList_Action_button}>
                            <Button
                              variant="contained"
                              data-testId={`jobListingApplyButton${eachJob.id}`}
                              onClick={() => this.handleJobDetails(eachJob)}
                              className={classes.jobList_button}
                              size="small"
                            >
                              Apply
                            </Button>
                            <Button
                              variant="contained"
                              data-testId={`jobListingViewDetails${eachJob.id}`}
                              onClick={() => this.handleJobDetails(eachJob)}
                              className={classes.jobList_button_jobDeatils}
                              size="small"
                            >
                              View Details
                            </Button>
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  </React.Fragment>
                  
                )
              })               
          }
        </Grid>
                  <ReactPaginate
                      previousLabel={<img src={back} alt="next" className="page-img" />}
                      nextLabel={<img src={next} alt="next" className="page-img" />}
                      pageCount={this.state.JLPtotalPage}
                      forcePage={this.state.page-1}
                      renderOnZeroPageCount={1}
                      containerClassName={"pagination-C"}
                      onPageChange={(event)=>{
                        this.setState({currPage:event.selected+1},()=>{
                          this.getJobListingDetails()
                        })
                          console.log("event",event)
                      }}
                      activeClassName={"active"}
                  />
</>

}
      
        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(JobListing);

// Customizable Area End
