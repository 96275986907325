import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import JobDetailsContentController, { Props } from "./JobDetailsContentController.web";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { dropdown,fileUploadIcon} from "./assets";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DoneIcon from "@material-ui/icons/Done";

import AppliedJobTopHeaderWeb from "./AppliedJobTopHeader.web";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width:"64em",
  height: 600,
  overflow: "scroll",
  borderRadius: "5px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const styles = (theme: Theme) =>({
    requireTag:{
      color: "red"
    },
    alreadyAppliedJobPopUp: {
      // position: "fixed",
      backgroundColor: "#ffffff",
      width: "29vw",
      height: "16vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px"
      },
    },
    appliedTxt:{
      fontSize:"1.5em",
      fontWeight:700,
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      marginBottom:"1em"
    },
    backToJobLisitingBtn:{
        background: "#f0d17c",
        color: "#000",
        borderRadius: 100,
        fontWeight: 550,
        padding:"1em 3em"
    },
  })

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  
    width: 700,
    height: 350,
    borderRadius: "5px",
    bgcolor: "background.paper",
    border: "none",
    // boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export class JobDeatailContentWeb extends JobDetailsContentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
 
    // Customizable Area End
  }
  render() {
    const {classes} = this.props;
    return (
      <div className="job-box">
        <Container maxWidth="md">
          <div
            // className={classes.popularCourseContainer}
            style={{
              display: "grid",
              gridGap: "20px",
            }}
          >
            <AppliedJobTopHeaderWeb navigation={this.props.navigation} id={""}  jobDetails={this.props.jobDetails}/>
            <div
              style={{
                display: "flex",
                columnGap: 20,
                marginBottom: 20,
              }}
            >
              <Button
                variant="contained"
                data-testId="backToJobListing"
                onClick={() => this.props.navigation.navigate("JobListing")}
                style={{
                  background: "#f00",
                  color: "#fff",
                  textTransform: "none",
                  borderRadius: 100,
                  width: "250px",
                  height: "50px",
                }}
                size="medium"
              >
                Back to Job Listings
              </Button>
              <Button
                variant="contained"
                onClick={this.handleApplyJobModal}
                data-testId="applyNowButton"
                style={{
                  background: "#f0d17c",
                  textTransform: "none",
                  borderRadius: 100,
                  fontWeight: 550,
                  width: "150px",
                  height: "50px",
                }}
                size="medium"
              >
                Apply Now
              </Button>
            </div>
          </div>
          <div className="model-box">
            <Modal
              open={this.state.modalopen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  component="h2"
                  style={{
                    color: "#ff0000",
                    margin:" 0px",
                    fontSize:"1.5em",
                    marginTop: "1vw",
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    marginBottom: "1em"
                  }}
                >
                  Apply for the Post
                </Typography>
                <form>
                  <Box>
                  <Grid container>
                        <Grid item md={6}>
                        <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                First Name <span className={classes.requireTag}>*</span>
                              </label>
                              <input
                                data-testId="first_Name_Field"
                                className="prl-input"
                                type="text"
                                value={this.state.teacherInputValues.firstName}
                                onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                placeholder="Enter First Name"
                                name="firstName"
                                 style={this.checkForTheStyle(this.state.errorForFirstName)}
                              />
                              {this.checkForTheEnterValues(this.state.errorForFirstName)}
                              {this.state.errorStatusFirstName &&
                                <p className="prl-input-err-txt">Maximum 15 characters</p>
                              }
                            </div>
                        </div>
                        <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Email<span className={classes.requireTag}>*</span>
                              </label>
                              <input
                              data-testId="email_field"
                                className="prl-input"
                                type="text"
                                value={this.state.teacherInputValues.email}
                                placeholder="example@gmail.com"
                                onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                name="email"
                               style={this.checkForTheStyle(this.state.errorForEmail)}
                              />
                              {this.state.errorForEmail &&
                                <p className="prl-input-err-txt">Please enter a valid email address.</p>
                              }
                            </div>

                       </div>
                       <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">Qualification <span className={classes.requireTag}>*</span> </label>
                              <div className="prl-input-dd">
                                <select
                                data-testId="teacher_qualification"
                                 value={this.state.teacherInputValues.qualification}
                                  className="prl-input-dd-s"
                                 
                                  onChange={(e: any) => {
                                 this.handleteacherInputChange(e)
                               }}
                                  placeholder="Post Graduate"
                                  name="qualification"
                                  style={this.checkForTheStyle(this.state.errorFrQualification)}
                                >
                                  <option value={this.state.teacherInputValues.qualification}>{this.state.teacherInputValues.qualification?this.state.teacherInputValues.qualification:"Select qualification"}</option>
                                  {this.state.teacherQualifactionListData.map((eachQualification: any, index_value: any) => {
                                    return (
                                      <option value={eachQualification.name} key={index_value}>
                                        {eachQualification.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                             {this.checkForTheValue(this.state.errorFrQualification)}
                              </div>
                            </div>
                        </div>
                        <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">Total Experience<span className={classes.requireTag}>*</span></label>
                              <div className="prl-input-dd">
                                <select
                                data-testId="teacher_experience"
                                  className="prl-input-dd-s"
                                  value={this.state.teacherInputValues.totalExperience}
                                  onChange={(e: any) => {
                                 this.handleteacherInputChange(e)
                               }}
                                  placeholder="Post Graduate"
                                  name="totalExperience"
                                  style={this.checkForTheStyle(this.state.erorFrExperience)}
                                  
                                >
                                  <option value={this.state.teacherInputValues.totalExperience}>{this.state.teacherInputValues.totalExperience?this.state.teacherInputValues.totalExperience:"Select Experience"}</option>
                                  {this.state.teachersExperienceListData.map((eachExp: any, index_value: any) => {
                                    return (
                                      <option value={eachExp.experience} key={index_value}>
                                        {eachExp.experience}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                                {this.checkForTheValue(this.state.erorFrExperience)}
                              </div>
                            </div>
                        </div>


                        <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable">
                                Languages Known<span className={classes.requireTag}>*</span>
                              </label>
                              <FormGroup row style={{ marginTop: "1.5em" }}>
                                <FormControlLabel   control={<Checkbox checked={this.state.teacherInputValues.languagesKnown.english} onChange={this.handleCheckBoxChanges} name="english"/>} label="English" />
                                <FormControlLabel control={<Checkbox checked={this.state.teacherInputValues.languagesKnown.hindi} onChange={this.handleCheckBoxChanges} name="hindi" />} label="Hindi" />
                                <FormControlLabel control={<Checkbox checked={this.state.teacherInputValues.languagesKnown.others} onChange={this.handleCheckBoxChanges} name="others" />} label="Others" />
                              </FormGroup>
                               {this.checkForTheValue(this.state.errorFrlanguagesKnown)}
                            </div>
                          </div>


                          <div className="prl-input-cc">
                            <div className="prl-input-left">
                              <label className="prl-input-lable"> Course of Interests<span className={classes.requireTag}>*</span></label>
                              <div className="prl-input-dd">
                                <select
                                data-testId="teacher_courseOfinterest"
                                  className="prl-input-dd-s"
                                  value={this.state.teacherInputValues.coursesOfInterests}
                                  onChange={(e: any) => {
                                    this.handleteacherInputChange(e)
                                  }}
                                  placeholder="Statistics, Economics, Maths"
                                  name="coursesOfInterests"
                                  style={this.checkForTheStyle(this.state.errorFrCoursesInterest)}
                                >
                                   {/* <option value="" >Select Course of Interest</option> */}
                                  <option value={this.state.teacherInputValues.coursesOfInterests}>{this.state.teacherInputValues.coursesOfInterests?this.state.teacherInputValues.coursesOfInterests:"Select Course of Interest"}</option>
                                  {this.state.teachersCourseInterestListData.map((eachCourseInterest: any, index_val: any) => {
                                    return (
                                      <option value={eachCourseInterest.name} key={index_val}>
                                        {eachCourseInterest.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                              {this.checkForTheValue(this.state.errorFrCoursesInterest)}
                              </div>
                            </div>
                          </div>



                        </Grid>
                        <Grid item md={6}>
                        <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Last Name<span className={classes.requireTag}>*</span>
                              </label>
                              <input
                               data-testId="lastName_Field"
                                className="prl-input"
                                type="text"
                                value={this.state.teacherInputValues.lastName}
                                onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                placeholder="Enter Last Name"
                                name="lastName"
                                style={this.checkForTheStyle(this.state.errorInLastName)}
                              />
                              {this.checkForTheEnterValues(this.state.errorInLastName)}
                              {this.state.errorStatusLastName ? (
                                <p className="prl-input-err-txt">Maximum 15 characters</p>
                              ) : (
                                <></>
                              )}
                            </div>
                        </div>
                        <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Mobile no<span className={classes.requireTag}>*</span>
                              </label>
                              <div className="prl-input-phone">
                                <div className="prl-input-dd-phone">
                                  <select
                                    className="prl-input-dd-s-phone"
                                    value={this.state.teacherInputValues.countryCode}
                                   onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                    name="countryCode"
                                    style={{ width: "6em" }}
                                    data-testId="teacher_countryCode"
                                  >
                                    <option value="91">+91</option>
                                    <option value="81">+81</option>
                                    <option value="71">+71</option>
                                  </select>
                                  <span className="prl-input-arrow">
                                    <img src={dropdown} alt="arrow" />
                                  </span>
                                </div>
                                <input
                                  className="prl-input-i-phone"
                                  type="text"
                                  // pattern="[0-9]*"
                                  value={this.state.teacherInputValues.mobileNumber}
                                   onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                  placeholder="0000000"
                                  name="mobileNumber"
                                  data-testId="teacher_mobileNumber"
                                   style={this.checkForTheStyle(this.state.errorForPhone)}
                                />
                              </div>
                                {this.checkForTheEnterValues(this.state.errorForPhone)}
                              {this.state.errorForMaxPhone ? (
                                <p className="prl-input-err-txt">Maximum 15 characters</p>
                              ) : (
                                <></>
                              )}
                            </div>
                        </div>
                        <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Specialization<span className={classes.requireTag}>*</span>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                value={this.state.teacherInputValues.specialization}
                                onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                placeholder="Eg:Post Graduate"
                                name="specialization"
                                data-testId="teacher_specialization"
                                style={this.checkForTheStyle(this.state.errorFrspecialization)}
                              />
                              {this.checkForTheEnterValues(this.state.errorFrspecialization)}
                               {this.state.errorStatusspecialization ? (
                                <p className="prl-input-err-txt">Maximum 20 characters</p>
                              ) : (
                                <></>
                              )}
                            </div>
                        </div>
                        <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">Avalibility<span className={classes.requireTag}>*</span></label>
                              <div className="prl-input-dd">
                                <select
                                  className="prl-input-dd-s"
                                  value={this.state.teacherInputValues.avalibility}
                                  onChange={(e: any) => {
                                 this.handleteacherInputChange(e)
                               }}
                                  placeholder="Post Graduate"
                                  name="avalibility"
                                  data-testId="teacher_avalibility"
                                  style={this.checkForTheStyle(this.state.errorFrAvalibility)}
                                >
                                  <option value="">Select avalibility</option>
                                  {this.state.teachersAvalibilityListData.map((eachAvalibilty: any, index_value: any) => {
                                    return (
                                      <option value={eachAvalibilty.available} key={index_value}>
                                        {eachAvalibilty.available}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="prl-input-arrow">
                                  <img src={dropdown} alt="arrow" />
                                </span>
                              {this.checkForTheValue(this.state.errorFrAvalibility)}
                              </div>
                            </div>
                        </div>

                        <div className="prl-input-cc">
                            <div className="prl-input-right">
                              <label className="prl-input-lable">
                                Enter Other Languages<span className={classes.requireTag}>*</span>
                              </label>
                              <input
                                className="prl-input"
                                type="text"
                                style={this.state.teacherInputValues.languagesKnown.others ? {} : { backgroundColor: "#EEEEEE" }}
                                value={this.state.teacherInputValues.otherLanguages}
                                onChange={(e: any) => {
                                  this.handleteacherInputChange(e)
                                }}
                                placeholder="Eg: French, Spanish"
                                name="otherLanguages"
                                disabled={this.state.teacherInputValues.languagesKnown.others ? false : true}
                              />
                                {this.checkForTheEnterValues(this.state.errorFrOtherlanguagesKnown)}
                            </div>
                          </div>
                          
                          <div className="prl-input-cc">
                            <div className="prl-input-right">
                            <input
                              type="file"
                              id="cvUpload"
                              accept=".doc,.docx,.pdf"
                              className="TSU-input-file"
                              value=""
                              onChange={(e: any) => {
                                this.handleUploadedCvFile(e);
                              }}
                              style={this.checkForTheStyle(this.state.errorcvUploaded)}
                            />
                            <label
                              htmlFor="cvUpload"
                              className="TSU-input-file-img-c"
                              style={{
                                marginRight: " 1.2em",
                                marginTop: "2.8em",
                                padding: "2.4em 2em",

                              }}
                            >
                              <img src={fileUploadIcon} alt="file" />
                             {this.getFileNAME()}
                            </label>
                            {this.state.errorcvUploaded ? (
                                <p className="prl-input-err-txt">Please Upload Cv.</p>
                              ) : (
                                <></>
                              )}
                            </div>
                           
                          </div>
                        </Grid>
                  </Grid>
                  </Box>
                  <div style={{ marginTop: "20px" }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          data-testId="closeButton"
                          type="button"
                          onClick={() => this.handleClose()}
                          style={{
                            border: "1px solid red",
                            borderRadius: 100,
                            fontWeight: 550,
                            width: "150px",
                            height: "50px",
                          }}
                        >
                          close
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Button
                            data-testId="reset_Button"
                               onClick={this.handleResetBtn}
                              style={{
                                background: "#f0d17c",
                                textTransform: "none",
                                color: "#fff",
                                borderRadius: 100,
                                fontWeight: 550,
                                width: "150px",
                                height: "50px",
                              }}
                              size="medium"
                            >
                              Reset
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                            data-testId="submit_Button"
                              onClick={this.handleApplyApplicationBtn}
                              style={{
                                background: "#f00",
                                textTransform: "none",
                                color: "#fff",
                                borderRadius: 100,
                                fontWeight: 550,
                                width: "150px",
                                height: "50px",
                              }}
                              size="medium"
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
           {/* Submited model Start  */}
          <div className="submit-model-box">
            <Modal
              open={this.state.submitmodel}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style2}>
                <Typography
                  className="modeltitle"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{fontWeight:700}}
                >
                  Apply for the Post
                </Typography>
                <Box
                  style={{
                    marginTop: "30px",
                    textAlign: "center",
                    width: "500px",
                    margin: "0 auto",
                  }}
                >
                  <Grid>
                    <Grid item style={{ marginTop: "30px" }}>
                      <Button
                        style={{
                          background: "#f0d17c",
                          textTransform: "none",
                          color: "#f00",
                          borderRadius: "50%",
                          fontWeight: 550,
                          padding: "20px",
                        }}
                      >
                        {<DoneIcon />}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography
                        id="submited-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        Application Submitted!
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        id="submited-modal-desc"
                        variant="subtitle2"
                        component="h2"
                      >
                        Your Application has been successfully posted for job
                        listing. We will notify you as soon as there is an
                        update on your application
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        type="button"
                        onClick={() => this.submithandleClose()}
                        style={{
                          border: "1px solid red",
                          borderRadius: 100,
                          fontWeight: 550,
                          width: "150px",
                          height: "50px",
                        }}
                      >
                        close
                      </Button>
                      <Button
                        type="button"
                        onClick={() => this.props.navigation.navigate("JobListing")}
                        style={{
                          background: "#f0d17c",
                          color: "#000",
                          borderRadius: 100,
                          fontWeight: 550,
                          width: "250px",
                          height: "50px",
                          marginLeft: "40px",
                        }}
                      >
                        Back to Job Listings
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
          </div>
         {/* Submited model End  */}
         <div className="submit-model-box">
            <Modal
              open={this.state.openAlreadyAppliedJob}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style2}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <div>
                      <p className={classes.appliedTxt}>You have already applied for this Job</p>
                    </div>
                    <div>
                    <Button
                        onClick={() => this.props.navigation.navigate("JobListing")}
                        className={classes.backToJobLisitingBtn}
                      >
                        Back to Job Listings
                  </Button>
                    </div>
                  </div>
              </Box>
            </Modal>
          </div>
        </Container>
      </div>
    );
  }
}







export default withStyles(styles, { withTheme: true })(JobDeatailContentWeb);
