import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import { studentPicture, Women, cardBoys, hours, calender } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
   
    
  });

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End


import Avatar from "@material-ui/core/Avatar";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import ViewAllSubjectsController, { Props } from "./ViewAllSubjectsController.web";

export class ViewAllSubjects extends ViewAllSubjectsController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
   
    return (
      // Customizable Area Start


      <>
      {/*@ts-ignore*/}
      <div>
        <TopNavigation navigation={this.props.navigation} history={this.props.history}
        location={this.props.location}/>
        {/* Banner */}
        <div>
          <Grid container spacing={3} style={{ marginTop: "3%", marginBottom: "3%" }}>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <DashSidebar1 active="Dashboard" styles={{ marginBottom: "1.5vw" }} navigation={this.props.navigation}/>
              <DashSidebar2 timings="10:00 AM - 11:00 PM" title="Introduction to foundation of digital marketing and e-commerce" styles={{ marginBottom: "1.5vw" }}/>
              <DashSidebar3
                title="Get a chance according to your ability"
                subtitle="Lorem Ipsum is simply dummy text of the printing"
                Buttontitle="Test Now"
                styles={{ marginBottom: "1.5vw" }}
                onClick={() => { }}
              />
            </Grid>
            <Grid item xs={8}>
              <div>
                <p className="ViewAllSubject_heading">Subjects</p>
              </div>
              <Grid container spacing={3} className="ViewAllSubject_Container">
                <Grid item xs={8}>
                  <form className="nosubmit">
                    <input className="nosubmit" type="search" placeholder="Search..."/>
                  </form>

                  <div className="ViewAllSubject_Card_box">
                    <Grid
                      container
                      spacing={3}
                      className="ViewAllSubject_Card_box_grid"
                    >
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      {/* <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="ViewAllSubject_Card_img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography className="ViewAllSubject_Card_text">Subject</Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{lineHeight: 1.8  }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div className="ViewAllSubject_Card_box_container">
                              <div className="ViewAllSubject_Card_box_container_box">
                                <img className="ViewAllSubject_Card_container_box_img" src={hours} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="ViewAllSubject_Card_container_box_dd">
                                <img className="ViewAllSubject_Card_container_box_img" src={calender} alt="" />
                                <Typography className="ViewAllSubject_Card_container_box_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="ViewAllSubject_Card_container_box_CD">
                              <Button variant="contained" className="ViewAllSubject_Card_container_box_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      */}
                      
                      
                    </Grid>
                  </div>
                  
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <Card className="ViewAllSubject_Card_box_LC_Card">
                    <div style={{ background: "#f7f7f7" }}>
                      <Avatar
                        alt="StudentPicture"
                        src={studentPicture}
                        className="ViewAllSubject_Card_box_LC_Card_Avtar"
                      />
                      <Typography variant="h6" style={{ marginLeft: "30%" }}>
                        Teacher Name
                      </Typography>
                      <Typography variant="subtitle2" style={{ marginLeft: "30%", color: "#9ea7a9" }}>
                        email@gmail.com
                      </Typography>
                    </div>
                    <CardContent className="ViewAllSubject_Card_box_LC_Card_content">
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Typo"
                      >
                        Experience
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Card_Typo1"
                      >
                        2-3 years
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Typo"
                      >
                        Scheduled Classes
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Card_Typo1"
                      >
                        5
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Typo"
                      >
                        Qualification
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Card_Typo1"
                      >
                        Graduate
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Typo"
                      >
                        Joined on
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="ViewAllSubject_Card_box_LC_Card_Typo1"
                      >
                        10/08/22
                      </Typography>
                    </CardContent>
                    <CardActions className="ViewAllSubject_Card_box_LC_Typo">
                      <Button
                        variant="contained"
                        style={{
                          background: "#f0d17c",
                          borderRadius: "30px",
                          textTransform: "none",
                          width: "10vw",
                        }}
                      >
                        Update Profle
                      </Button>
                    </CardActions>
                  </Card>
                  <Card style={{ borderRadius: "15px" }}>
                    <img src={Women} className="ViewAllSubject_Card_box_LC_Card_img" />
                    <CardContent style={{ justifyContent: "center" }}>
                      <Typography
                        variant="h5"
                        component="h5"
                        style={{ marginBottom: "5px", fontSize: "11px" }}
                      >
                        Don't forget to setup
                        <br />
                        Training for next week
                      </Typography>
                    </CardContent>
                    <CardActions className="ViewAllSubject_Card_box_LC_Card_Action">
                      <Button
                        variant="contained"
                        className="ViewAllSubject_Card_box_LC_Card_Button"
                      >
                        Set Up Now
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </div>

        <BottomNavigation navigation={this.props.navigation} history={this.props.history}location={this.props.location}/>
      </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(ViewAllSubjects);

// Customizable Area End
