import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import Clipboard from '@react-native-clipboard/clipboard';

interface IWebinarsList {
  type: string;
  id: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    scheduled_date: string;
    scheduled_start_time: string
    scheduled_end_time: string
    agora_record: {
      id: number;
      live_streaming_token: string;
      recording_token: string;
      recording_uid: number;
      resource_id: string;
      webinar_id: number;
      sid: string;
      recording_status:string;
      recording_url: string;
      created_at: string;
      updated_at: string;
      rtm_token: string;
      creator_id:number
    };
    uid:string | null;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start  
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  uid:number;
  userName:string;
  isRemoveuserDialogOpen:boolean;
  kickUserId:string;  
  webinarId:string;  
  recordingData:string;
  isDownloadRecordingDialogOpen:boolean
  loading:boolean;
  webinarName:string;
  rtcToken:string;
  rtmToken:string;
  hostId:number;
  isHost:boolean
  isJoined:boolean 
  // isHost:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebinarsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  token: string | '' = '';
  stopRecordWebinarMessageId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),   
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start      
      uid:0,
      userName:'',
      isRemoveuserDialogOpen:false,
      kickUserId:'',                      
      webinarId:'',      
      recordingData:'#',
      isDownloadRecordingDialogOpen:false,
      loading: false,
      webinarName:'',
      rtcToken:'',
      rtmToken:'',
      hostId:0,           
      isHost:false,
      isJoined:false           
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    
    // Customizable Area Start
    try {
      if (
        message.properties.RestAPIResponceDataMessage ===
          this.stopRecordWebinarMessageId &&
        !!message.properties.RestAPIResponceSuccessMessage
      ) {
         this.webinarRecordingStopResponse(
          message.properties.RestAPIResponceSuccessMessage
        );
      }   
      
    } catch (error) {      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const isHost = (await getStorageData("userRole", true)) === configJSON.userRoleTeacher;
    const usrName = (await getStorageData("userName", true));
    const loginToken =  (await getStorageData("loginT"));      
    this.token = loginToken
    this.setState({isHost, userName: usrName });            
  }

   onJoinCall = async (props: {
    channelName: string;
    agoraRtcToken: string;
    agoraRtmToken: string;
    hostId: number;
    userId: number;
    webinarId: string;
  }) => {
    try {
       this.setState({
        rtcToken: props.agoraRtcToken,
        rtmToken: props.agoraRtmToken,
        uid: props.userId,
        hostId: props.hostId,
        webinarName:props.channelName,
        webinarId: props.webinarId,
      }, ()=>{
        this.setupCall();
      })
    } catch (error) {}
  };

  setupCall = () => {
    this.setState({isJoined: true})
  }

  webinarRecordingStopResponse = ({data}:{data:IWebinarsList}) => {
    this.setState({recordingData: data.attributes.agora_record.recording_url})
    this.setState({loading: false})    
  }

  kickUser = (userId: string) => {  
    this.setState({isRemoveuserDialogOpen: false})
    const headers = {
      Accept: configJSON.jsonApiContentType,
      "Content-Type": configJSON.jsonApiContentType,
      Authorization: configJSON.webinarTokenForRemoveUser,
      };

    const kickUserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    

    const body = JSON.stringify({
      appid: configJSON.webinarAgoraId,
      cname: this.state.webinarName,
      uid: Number(userId),
      time_in_seconds: 3,
      privileges: ["join_channel"],
    });
    kickUserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webinarRemoveUserAPILink
    );
    kickUserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    kickUserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );
    kickUserMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(kickUserMessage.id, kickUserMessage);
  }

  handleKickUser = (userId:string) => {
    this.setState({isRemoveuserDialogOpen: true, kickUserId: userId})
    
  }

  stopRecording = () => {        
    this.setState({loading: true,isDownloadRecordingDialogOpen:true})
    const endPoint = (configJSON.webinarStopRecordingAPILink as string).replace(
      "${id}",
      this.state.webinarId
    );
    
    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.token,
    };

    const stopRecordingMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stopRecordWebinarMessageId = stopRecordingMsg.messageId;

    stopRecordingMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    stopRecordingMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    stopRecordingMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );
    runEngine.sendMessage(stopRecordingMsg.id, stopRecordingMsg);
  };


  endCallHandler = ()=>{
      if(this.state.isHost) {
            this.stopRecording();
          } else {
              this.setState({isJoined:false})
            }
  }
  closeRecordingModal = () =>{
    this.setState({isDownloadRecordingDialogOpen:false,isJoined: false})
  }
  closeRemoveUserDialog = () => {
    this.setState({isRemoveuserDialogOpen:false})
  }

copyToClipboard = (copiedLink:string) => {
  Clipboard.setString(copiedLink);
};

  // Customizable Area End
}
