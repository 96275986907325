import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { resultSuccess ,resultFail} from "./assets";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import AssessResultController, { Props } from "./AssessResultController.web";

const styles = (theme: Theme) =>
    createStyles({
        CA_MC_C: {
            marginLeft: "17em",
            marginRight: "17em",
            marginBottom: "10em",
        },
        CA_Hr: {
            borderBottom: "0.5px solid #e4e4e5",
            marginBottom: "2.5em",
            marginTop: "2.5em",
        },
        CA_Title: {
            color: "#14292d",
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1.55vw",
            margin: "0px",
            marginTop: "2em",
            // marginBottom: "1em",
        },
        CA_Question_label: {
            color: "#ff0000",
            fontSize: "1.85em",
            fontWeight: 600,
            fontFamily: "Inter, sans-serif",
            marginBottom: "1em",
            marginTop: "1em",
        },
        CA_checkbox_C: {
            marginTop: "1em",
            background: "#f7f7f7",
            padding: "2em",
            borderRadius: "1em",
            minHeight: "12em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },

        CA_BT_C: {
            display: "flex",
            justifyContent: "center",
            marginTop: "3em",
        },

        CA_Q_count_btn2: {
            color: "#ffffff",
            fontFamily: "Inter, sans-serif",
            fontWeight: 700,
            fontSize: "1em",
            background: "#ff0000",
            borderRadius: "10em",
            textTransform: "none",
            paddingLeft: "2em",
            paddingRight: "2em",
            marginRight:'2em',
            // width: "10em",
            "&:hover": {
                background: "#ff0000",
            },
        },
        CA_Q_count_btn1: {
            color: "#000000",
            fontFamily: "Inter, sans-serif",
            fontWeight: 700,
            fontSize: "1em",
            background: "#f0d17c",
            borderRadius: "10em",
            textTransform: "none",
            paddingLeft: "2em",
            paddingRight: "2em",
            // width: "10em",
            "&:hover": {
                background: "#f0d17c",
            },
        },

        CA_Img_Success: {
            width: "5em",
            marginTop: "2em",
        },
        reTakeTest:{
                fontFamily: "Inter, sans-serif",
                fontWeight: 600,
                fontSize: "1.25em",
                color: "#14292d",
        }
    });

class AssessResult extends AssessResultController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
      
        return (
            <div>
                <TopNavigation
                    navigation={this.props.navigation}
                    history={this.props.history}
                    location={this.props.location}
                />
                <div className={classes.CA_MC_C}>
                    <p className={classes.CA_Title}>{this.state.assesmentSatus?.course_name}</p>
                    <div className={classes.CA_Hr} />
                    <p className={classes.CA_Title}>{this.state.assesmentSatus?.assessment_name}</p>
                    <div>
                        <div className={classes.CA_checkbox_C}>

                            {this.state.assesmentSatus.passing_status === "Pass"?
                            <><img src={resultSuccess} alt="" className={classes.CA_Img_Success} />
                            <Typography className={classes.CA_Question_label}>
                               Congrats! You have passed with {this.state.assesmentSatus.passing_status} marks in this the assessment test 
                            </Typography></>
                            :this.state.assesmentSatus.passing_status === "Fail"?
                            <><img src={resultFail} alt="" className={classes.CA_Img_Success} />
                            <Typography className={classes.CA_Question_label}>
                                Thank you for trying your level best, better luck next time
                            </Typography>
                            </>:
                            <><img src={resultSuccess} alt="" className={classes.CA_Img_Success} />
                            <Typography className={classes.CA_Question_label}>
                                 We are evaluating your answers, please wait for your result
                            </Typography>
                            </>
                            
                            }
                            {this.state.assesmentSatus === "fail" && 
                            <p className={classes.retakeTest}>
                                Please click the button below to retake the test
                            </p>}
                        </div>
                    </div>
                    <div className={classes.CA_Hr}/>
                    <div className={classes.CA_BT_C}>
                        <Button variant="contained" className={classes.CA_Q_count_btn2} onClick={this.handleBackToCourses} >
                            Back to Course
                        </Button>      
                        <Button variant="contained" className={classes.CA_Q_count_btn1} onClick={this.handleContinueLearning} >
                            Continue Learning 
                        </Button>
                    </div>
                </div>
                <BottomNavigation
                    navigation={this.props.navigation}
                    history={this.props.history}
                    location={this.props.location}
                />
            </div>
        );
    }
}
export default withStyles(styles, { withTheme: true })(AssessResult);
