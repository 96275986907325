import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import { withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';

export const styles:any = (theme: Theme) =>
  ({
   
    jobDetailMainContainer: {
      paddingTop: "3vw",
      display: "flex",
      paddingLeft: "13vw",
      paddingRight: "13vw",
    },
    jobDetailLeftContainer: {
      flex: 2,
    },
    jobDetailRightContainer: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
      marginBottom:"6em"
    },
    jobDetailTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"

    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
    job_details_container: {
      marginTop: "3%", 
      marginBottom: "3%"
    },
    jobDetailLoadingProfile:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    }
  });

// Customizable Area End

import JobDetailController, { Props } from "./JobDetailsController.web";
import { Divider } from "react-native-elements";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import JobDeatailContentWeb from "./JobDeatailContent.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

export class JobDetails extends JobDetailController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.jobDetailMainContainer}>
        <div className={classes.jobDetailLeftContainer}>
          <DashSidebar1 active="JobListings" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            Buttontitle="View your Classes"
            styles={{ marginBottom: '1.5vw' }}

          />
        </div>
        <div className={classes.jobDetailRightContainer}>
        <div>

              <Typography className={classes.jobDetailTitle}>Job Details</Typography>
        </div>
        <Divider style={{margin:"2em 0em"}}></Divider>
            <Grid container spacing={3} style={{ display: "flex", marginTop: "20px" }}>
            {this.state.isjobDetailsLoading ?  <div className={classes.jobDetailLoadingProfile} style={{ marginLeft: "2em" }}><CircularProgress /></div>:
            <JobDeatailContentWeb navigation={this.props.navigation} id={""} jobDetails={this.state.jobDetails}/>}
          </Grid>
  
        </div>
  
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(JobDetails);

// Customizable Area End
