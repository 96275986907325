import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import ReactPaginate from "react-paginate";
import { back, fastback, fastforward, next } from "../../../email-account-login/src/assets";

interface Props {
  coursesList: any;
  currPage: any;
  totPage: any;
  navigation: any;

  CSLselectFilterBy: any;
  CSLselectFilterByPrice: any;
  CSLstartDate: any;
  CSLendDate: any;
  pageFrom: string;
}

const CourseListPaginate = ({
  coursesList,
  currPage,
  totPage,
  navigation,

  CSLselectFilterBy,
  CSLselectFilterByPrice,
  CSLstartDate,
  CSLendDate,
  pageFrom,
}: Props) => {
  console.log("coursesListcoursesListcoursesList", coursesList.length);
  return (
    <div style={{ width: "100%", marginTop: "1em", marginBottom: "3em" }}>
      {coursesList.length > 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className={classnames("pagination-item page-fastPrev", {
              disabled: currPage === 1,
            })}
            onClick={() => {
              // ChangePage(1);
              localStorage.setItem("filter_by", CSLselectFilterBy);
              localStorage.setItem("filter_by_price", CSLselectFilterByPrice);
              localStorage.setItem("filter_by_start_date", CSLstartDate);
              localStorage.setItem("filter_filter_by_end_dateby", CSLendDate);
              if (pageFrom == "CoursesList") {
                navigation.navigate("CoursesList", { page: 1 });
              } else if (pageFrom == "CourseList") {
                navigation.navigate("CourseList", { page: 1 });
              }
              window.location.reload();
              window.scrollTo({ top: 0, left: 0, behavior: "auto" });
            }}
            data-testId="CoursesListPaginateFastBackwards"
          >
            <img src={fastback} alt="next" className="page-img" />
          </div>
          <ReactPaginate
            previousLabel={<img src={back} alt="next" className="page-img" />}
            nextLabel={<img src={next} alt="next" className="page-img" />}
            pageCount={totPage}
            forcePage={currPage - 1}
            onPageChange={(page) => {
              // ChangePage(page.selected + 1);
              console.log("PPPPPPPPPPPPPPP", page);
              if (page.selected + 1 != currPage) {
                console.log("PPPPPPSSSSSSSSSS", typeof page.selected);
                const pp: number = page.selected;
                const Cpage: number = 1 + pp;
                console.log("PLPL", Cpage, "ppp", pp);
                localStorage.setItem("filter_by", CSLselectFilterBy);
                localStorage.setItem("filter_by_price", CSLselectFilterByPrice);
                localStorage.setItem("filter_by_start_date", CSLstartDate);
                localStorage.setItem("filter_filter_by_end_dateby", CSLendDate);
                if (pageFrom == "CoursesList") {
                  navigation.navigate("CoursesList", { page: Cpage });
                } else if (pageFrom == "CourseList") {
                  navigation.navigate("CourseList", { page: Cpage });
                }
                window.location.reload();
                window.scrollTo({ top: 0, left: 0, behavior: "auto" });
              }
            }}
            data-testId="ReactPaginatePagination"
            containerClassName={"pagination-C"}
            activeClassName={"active"}
            pageRangeDisplayed={2}
          />
          <div
            className={classnames("pagination-item page-fastNext", {
              disabled: currPage === 1,
            })}
            onClick={() => {
              // ChangePage(totPage);
              localStorage.setItem("filter_by", CSLselectFilterBy);
              localStorage.setItem("filter_by_price", CSLselectFilterByPrice);
              localStorage.setItem("filter_by_start_date", CSLstartDate);
              localStorage.setItem("filter_filter_by_end_dateby", CSLendDate);
              if (pageFrom == "CoursesList") {
                navigation.navigate("CoursesList", { page: totPage });
              } else if (pageFrom == "CourseList") {
                navigation.navigate("CourseList", { page: totPage });
              }
              window.location.reload();
              window.scrollTo({ top: 0, left: 0, behavior: "auto" });
            }}
            data-testId="CoursesListPaginateFastForwards"
          >
            <img src={fastforward} alt="next" className="page-img" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CourseListPaginate;
