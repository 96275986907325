import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    LoginBtnContainer: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        rowGap: 10,
      },
    },
  })
);

const LoginInputSection = ({ loginFailed, handleOpen, failedRes, navigation, doEmailLogInt }: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();

  return (
    <div style={{ width: "50%" }}>
      <div>
        <Typography
          style={{
            fontSize: 36,
            fontWeight: 600,
            marginBottom: 20,
            color: "#3b3b3b",
          }}
        >
          Login
        </Typography>
        <div>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#3b3b3b",
            }}
          >
            Email<sup style={{ color: "red" }}>*</sup>
          </Typography>

          {/* <input
        style={
          this.state.loginFailedError
            ? { backgroundColor: "#fcecec", border: "0.5px solid red" }
            : {}
        }
        type="email"
        name="email"
        required
        // onChange={(e)=>this.setState({email:e.target.value})}
        onChange={(e: any) => this.handleEmailTyping(e)}
        value={this.state.email}
        className={classes.EmailPassword}
        id="outlined-basic"
        placeholder="Enter Email"

        // style={{backgroundColor: this.state.loginFailedError ? "#fcecec" : "#fff", border: this.state.loginFailedError  ? "1px solid red" : "none"}}
      /> */}

          <input
            data-testId="txtInputEmail"
            className="prl-input"
            id="email"
            type="email"
            name="email"
            value={email}
            required
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Enter Email"
            // style={this.state.errEmail ? this.errStyle : {}}
          />

          {loginFailed && <Typography style={{ color: "red", textAlign: "right" }}> {failedRes}</Typography>}
          {/* {this.state.isEmptyFields ? <Typography style={{color: "red",textAlign:'right'}}>Please enter email</Typography> : null}                    */}
          <span className="error" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <Typography style={{ fontSize: 16, fontWeight: 600, color: "#3b3b3b" }}>Password</Typography>
          <Typography
            style={{ fontSize: 16, fontWeight: 600, color: "#f00", cursor: "pointer" }}
            onClick={() => handleOpen()}
          >
            Forgot Password?
          </Typography>
        </div>

        <input
          className="prl-input"
          id="password"
          type="password"
          name="password"
          required
          // onChange={(e)=>this.setState({password:e.target.value})}
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          placeholder="**********"
          data-testId="txtInputPassword" 
          // style={{backgroundColor: this.state.loginFailedpassError ? "#fcecec" : "#fff", border: this.state.loginFailedError  ? "1px solid red" : "none"}}
        />
        {/* {this.state.isEmptyFields ? <Typography style={{color: "red",textAlign:'right'}}>Please enter password</Typography> : null}                    */}
        <span className="error" />
        <div className={classes.LoginBtnContainer}>
          <Button
            type="submit"
            style={{
              borderRadius: "100px",
              backgroundColor: "#ff0000",
              padding: "0.8em 2.5em 0.8em",
              color: "#fff",
              textTransform: "none",
            }}
            variant="contained"
            onClick={() => {
              doEmailLogInt(email, password);
            }}
            // onClick={()=>{this.emptyFeilds()}}
          >
            {/* {this..state.isLoading ? <CircularProgress size={30} color="inherit"/>:Login} */}
            Login
          </Button>
          <Typography style={{ fontSize: 19 }}>
            New to Techfin?
            <span
              style={{ color: "#ff0000", cursor: "pointer", fontWeight: 600 }}
              onClick={() => {
                navigation.navigate("SignUp");
              }}
            >
              &nbsp; Register now
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LoginInputSection;
