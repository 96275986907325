import React from "react";
import Banner from "../../email-account-registration/src/Components/Banner.web";
import CoursesListController, { Props } from "./CoursesListController.web";
import Grid from "@material-ui/core/Grid";
import CourseListPagination from "./components/CourseListPagination.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import AddAttributesToArray from "./components/AddAttributesToArray.web";
import CourseCard from "./components/CourseCard.web";
import PopularCoursesList from "./components/PopularCoursesList.web";

// Customizable Area End

class CoursesList extends CoursesListController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />

        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className="CL_Container_C">
          <PopularCoursesList
            CSLpopularCourseList={this.state.CSLpopularCourseList}
            navigateToCoursesDetails={this.navigateToCoursesDetails}
          />
          <div className="CL_AllC_Title">
            <label>All Courses</label>
          </div>
          <hr className="CL_Hr_Line" />
          <CourseListPagination
            navigateToCoursesDetails={this.navigateToCoursesDetails}
            coursesList={AddAttributesToArray(this.state.CSLcoursesList)}
            totPage={this.state.CSLtotalPage}
            currPage={this.state.CSLcurrentPage}
            handleFilterByChange={this.handleFilterByChange}
            handleFilterByPriceChange={this.handleFilterByPriceChange}
            handleFilterByStartEndDateChange={this.handleFilterByStartEndDateChange}
            navigation={this.props.navigation}
            enableFilters={true}
            CSLselectFilterBy={this.state.CSLselectFilterBy}
            CSLselectFilterByPrice={this.state.CSLselectFilterByPrice}
            CSLstartDate={this.state.CSLstartDate}
            CSLendDate={this.state.CSLendDate}
            pageFrom="CoursesList"
            fetchCourseListLoading={this.state.CSLfetchCourseListLoading}
          />
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
export default CoursesList;
