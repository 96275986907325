import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    history: any;
    location: any;
    classes?: any;
    // Customizable Area End
   
}

interface S {
  // Customizable Area Start
    duration:string;
    experience:string;
    qualification:string;
    subject:string;
    startDate:string;
    lastDateToSubmit:string;
    schedule:string;
    proposedBudget:string;
    description:string;

    durationList:any;
    qualificationList:any;
    experienceList:any;
    scheduleList:any;

    ErrorForDuraiton:any
    ErrorForExperience:any
    ErrorForQualification:any
    ErrorForSubject:any
    ErrorForSubjectMaxChar:any

    ErrorForStartDate:any
    ErrorForLastDateSubmit:any
    ErrorForSchedule:any
    ErrorForPropsedBudget:any
    ErrorForProspsedBudgetMax:any
    ErrorForDescriptionMaxChar:any

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HireATeacherController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  teacherQualificationsId: string = "";
  hireATeacherCallId: string = "";
  durationId: string = "";
  experienceId: string = "";
  scheduleId: string = "";
  errorStyle: any;
  
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ]
    
    this.state = {
    duration:"",
    experience:"",
    qualification:"",
    subject:"",
    startDate:"",
    lastDateToSubmit:"",
    schedule:"",
    proposedBudget:"",
    description:"",

    durationList:[],
    qualificationList:[],
    experienceList:[],
    scheduleList:[],

    ErrorForDuraiton:false,
    ErrorForExperience:false,
    ErrorForQualification:false,
    ErrorForSubject:false,
    ErrorForSubjectMaxChar:false,
    ErrorForProspsedBudgetMax:false,
    ErrorForStartDate:false,
    ErrorForLastDateSubmit:false,
    ErrorForSchedule:false,
    ErrorForPropsedBudget:false,
    ErrorForDescriptionMaxChar:false,
    
    };
    this.errorStyle = {
        backgroundColor: "#fcecec",
        borderColor: "#fb7b7c",
      };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End

  }

  async componentDidMount() {
    // Customizable Area Start
    await this.getTeacherQualificationList()
    await this.getDurationList()
    await this.getExperienceList()
    await this.getScheduleList()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.teacherQualificationsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.error) {
          alert(apiResponse?.error)
        } else {
          this.setState({
            qualificationList: apiResponse
          })
        }
    }
    if (this.durationId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.error) {
          alert(apiResponse?.error)
        } else {
          this.setState({
            durationList: apiResponse
          })
        }
    }
    if (this.experienceId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.error) {
          alert(apiResponse?.error)
        } else {
          this.setState({
            experienceList: apiResponse
          })
        }
    }
    if (this.scheduleId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (apiResponse?.error) {
          alert(apiResponse?.error)
        } else {
          this.setState({
            scheduleList: apiResponse
          })
        }
    }
    if (this.hireATeacherCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        const budgetId = apiResponse.data.id
        this.props.navigation.navigate("PayProposedBudget", { budgetId: budgetId });
      }
  }

    // Customizable Area End
  }

  
  // Customizable Area Start
  getTeacherQualificationList() {
    const teacherQualificationListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teacherQualificationsId = teacherQualificationListMsg.messageId;

    teacherQualificationListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qualificationListEndpoint
    );

    teacherQualificationListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(teacherQualificationListMsg.id, teacherQualificationListMsg);
  }
  getDurationList() {
    const getDurationListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.durationId = getDurationListMsg.messageId;

    getDurationListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.durationEndpoint
    );

    getDurationListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getDurationListMsg.id, getDurationListMsg);
  }
  getExperienceList() {
    const getExperienceListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.experienceId = getExperienceListMsg.messageId;

    getExperienceListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.experienceEndpoint
    );

    getExperienceListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getExperienceListMsg.id, getExperienceListMsg);
  }
  getScheduleList() {
    const getScheduleListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.scheduleId = getScheduleListMsg.messageId;

    getScheduleListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleEndpoint
    );

    getScheduleListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getScheduleListMsg.id, getScheduleListMsg);
  }

handleDuration=(event:any)=>{
    this.setState({duration:event.target.value})
    if (event.target.value <= 0) {
      this.setState({ ErrorForDuraiton: true });

    } else {
      this.setState({ ErrorForDuraiton: false });
    }
}
handleExperience=(event:any)=>{
    this.setState({experience:event.target.value})
     if (event.target.value <= 0) {
      this.setState({ ErrorForExperience: true });

    } else {
      this.setState({ ErrorForExperience: false });
    }
}
handleSchedule=(event:any)=>{
    this.setState({schedule:event.target.value})
    if (event.target.value <= 0) {
      this.setState({ ErrorForSchedule: true });
  
    } else {
      this.setState({ ErrorForSchedule: false });
    }
}
handleteacherQualification=(event:any)=>{
    this.setState({qualification:event.target.value})
    if (event.target.value <= 0) {
      this.setState({ ErrorForQualification: true });
  
    } else {
      this.setState({ ErrorForQualification: false });
    }
}
handleSubject=(event:any)=>{
    this.setState({subject:event.target.value})
   if (event.target.value> 20) {
       this.setState({ ErrorForSubjectMaxChar: true });
    } else {
     this.setState({ ErrorForSubjectMaxChar: false });
    }
    if (event.target.value <= 0) {
      this.setState({ ErrorForSubject: true });
    } else {
      this.setState({ ErrorForSubject: false });
    }
}
handleProposedBudget=(event:any)=>{
  const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({proposedBudget:event.target.value})
    }
    
    if (event.target.value> 99999) {
      this.setState({ ErrorForProspsedBudgetMax: true });
   } else {
    this.setState({ ErrorForProspsedBudgetMax: false });
   }

   if (event.target.value.length <= 0) {
    this.setState({ ErrorForPropsedBudget: true });
  } else {
    this.setState({ ErrorForPropsedBudget: false });
  }
}
handleDescription=(event:any)=>{
    this.setState({description:event.target.value})
    if (event.target.value>250) {
      this.setState({ ErrorForDescriptionMaxChar: true });
   } else {
    this.setState({ ErrorForDescriptionMaxChar: false });
   }
}
handlestartDate=(event:any)=>{
    this.setState({startDate:event.target.value})

    if (event.target.value.length <= 0) {
      this.setState({ ErrorForStartDate: true });

    } else {
      this.setState({ ErrorForStartDate: false });
    }

}
handlelastDateToSubmit=(event:any)=>{
    this.setState({lastDateToSubmit:event.target.value})
    if (event.target.value.length <= 0) {
      this.setState({ ErrorForLastDateSubmit: true });

    } else {
      this.setState({ ErrorForLastDateSubmit: false });
    }
}

handleHireATeacherSubmit=async()=>{
  //Error validation for subject
  if (this.state.subject.length <= 0) {
    this.setState({ ErrorForSubject: true },);
  }
  //  else {
  //    this.setState({ ErrorForSubject: false });
  // }
  if (this.state.subject.length> 20) {
    this.setState({ ErrorForSubjectMaxChar: true });
 } 
//  else {
//   this.setState({ ErrorForSubjectMaxChar: false });
//  }

  //Error  validation for proposedBudget
  if (this.state.proposedBudget.length <= 0) {
     this.setState({ ErrorForPropsedBudget: true });
  } 
  // else {
  //     this.setState({ ErrorForPropsedBudget: false });
  // }
  if (this.state.proposedBudget.length > 99999) {
    this.setState({ ErrorForProspsedBudgetMax: true });
 } 
//  else {
//   this.setState({ ErrorForProspsedBudgetMax: false });
//  }

  //Error validation For Duration
  if (this.state.duration.length <= 0) {
       this.setState({ ErrorForDuraiton: true });

  } 
  // else {
  //     this.setState({ ErrorForDuraiton: false });
  // }
  //Error validation For experience
  if (this.state.experience.length <= 0) {
      this.setState({ ErrorForExperience: true });

  } 
  // else {
  //     this.setState({ ErrorForExperience: false });
  // }

   //Error  validation For qualification
  if (this.state.qualification.length <= 0) {
      this.setState({ ErrorForQualification: true });

  } 
  // else {
  //    this.setState({ ErrorForQualification: false });
  // }

  //Error validation For sheduele
  if (this.state.schedule.length <= 0) {
      this.setState({ ErrorForSchedule: true });

  } 
  // else {
  //      this.setState({ ErrorForSchedule: false });
  // }

  //Error validation For StartDate
  if (this.state.startDate.length <= 0) {
       this.setState({ ErrorForStartDate: true });

  } 
  // else {
  //     this.setState({ ErrorForStartDate: false });
  // }

//Error validation For lastDateToSubmit
   if (this.state.lastDateToSubmit.length <= 0) {
     this.setState({ ErrorForLastDateSubmit: true });

  } 
  // else {
  //    this.setState({ ErrorForLastDateSubmit: false });
  // }
//Error validation For lastDateToSubmit
  if (this.state.description.length>250) {
     this.setState({ ErrorForDescriptionMaxChar: true });
 } 
//  else {
//    this.setState({ ErrorForDescriptionMaxChar: false });
//  }
  
 if(this.state.duration.length <= 0|| this.state.experience.length <= 0|| this.state.qualification.length <= 0 ||
  this.state.subject.length <= 0 || this.state.subject.length> 20|| this.state.proposedBudget.length>=6
  || this.state.startDate.length <= 0|| this.state.lastDateToSubmit.length <= 0|| this.state.schedule.length <= 0 || 
  this.state.proposedBudget.length <= 0 || this.state.description.length>250
  ){
   return
 }

   
 let usrToken  = localStorage?.getItem("loginT");
 const headers = {
  "Content-Type": configJSON.hireAteacherContentType,
  token: usrToken,
};

const attributes = {
  duration: this.state.duration,
  experience: this.state.experience,
  qualification: this.state.qualification,
  subject: this.state.subject,
  start_date: this.state.startDate,
  last_date: this.state.lastDateToSubmit,
  schedule:this.state.schedule,
  proposed_budget:this.state.proposedBudget,
  description: this.state.description
}

const httpBody = {
  data: {
    attributes:attributes
  }
};

const hireAteacherSubmitMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
this.hireATeacherCallId = hireAteacherSubmitMsg.messageId;


hireAteacherSubmitMsg.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.hireAteacherSubmitEndpoint
);

hireAteacherSubmitMsg.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(headers)
);
hireAteacherSubmitMsg.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.postHttpMethodType
);
hireAteacherSubmitMsg.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(httpBody)
);
runEngine.sendMessage(hireAteacherSubmitMsg.id, hireAteacherSubmitMsg);


}


  
 
  // Customizable Area End

 
}
