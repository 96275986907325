//@ts-nocheck
import React from "react";
// Customizable Area Start
import Button from "@material-ui/core/Button";
import { cardBoys, desktop, screenView, timeIcon,back, next  } from "./assets";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
import ReactPaginate from "react-paginate";
import { withStyles, Theme } from "@material-ui/core/styles";

export const styles: any = (theme: Theme) => ({
  root: {
    // marginTop:"3%",
    // marginBottom:"3%"
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  table: {
    height: "50px",
  },
  sDashboard_cc: {
    width: "100%",
    marginLeft: "2vw",
    flex: 7.5,
  },
  add_contract_container: {
    paddingLeft: "13vw",
    paddingRight: "13vw",
    paddingTop: "3vw",
    display: "flex",
  },
  dash_left_c: {
    flex: 2,
  },
  add_contract_cc: {
    width: "100%",
    marginLeft: "2vw",
    flex: 7.5,
  },
  pageTitle: {
    color: "#14292d",
    fontFamily: "Inter, sans-serif",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: " 0.125px",
    marginBottom: "1em",
  },
  hiringPost: {
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0.1px",
    margin: "1em 0px",
  },
  tableContainerStyle: {
    maxHeight: "20.3em",
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "auto",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px #dcdcdc",
      borderRadius: "10px",
      marginTop: "3vw",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(20, 41, 45, 0.3)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(20, 41, 45, 0.3)",
    },
  },
  tableHeadStyle: {
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#14292d",
      backgroundBlendMode: "normal",
    },
    color: "white",
    fontSize: 16,
    width: "10vw",
  },
  hireTeacherContainer: {
    border: "2px solid #f00",
    margin: "0.5em",
    borderRadius: "20px",
    padding: "0em 0.4em",
    flexGrow: 1,
  },
  avatarSty: {
    height: "60px",
    width: "60px",
    marginTop: "1em",
  },
  teacherName: {
    margin: "0px",
    marginTop: "1em",
    marginLeft: "1em",
    color: " #333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "20px",
    fontWeight: 600,
  },
  teacherSubject: {
    marginLeft: "1em",
    color: "#181617",
    fontFamily: "Inter, sans-serif",
    fontSize: "17px",
    fontWeight: 400,
  },

  hireTeacherTitle: {
    color: " #333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0.1px",
    marginBottom: "22px",
    marginTop: "28px",
  },
  tableCellStyle: {
    color: "#666666",
    fontSize: "0.8em",
  },
  scheduleTitle: {
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: " 0.1px",
    marginBottom: "22px",
    marginTop: "28px",
  },
  scheduledCardImages: {
    width: "100%",
    height: "12.4vw",
    objectFit: "cover",
  },
  courseTitle: {
    color: " #333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0.1px",
  },
  courseContent: {
    lineHeight: "1.3em",
    height: "2.6em",
    color: "#666666",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    letterSpacing: "0.1px",
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    overflow: "hidden"
  },
  typeOfClass: {
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.825em",
    fontWeight: 600,
    marginLeft: "0.825em",
  },
  timeOfTheClass: {
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.9em",
    fontWeight: 600,
    marginLeft: "0.825em",
  },
  typeofView: {
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontSize: "0.825em",
    fontWeight: 600,
    marginLeft: "0.825em",
  },
  loadingProfile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    "& .MuiCircularProgress-colorPrimary": {
      color: "#ff0000",
    },
  },
  viewSubmissionButton: {
    color: "#FFFFFF",
    fontFamily: "Inter, sans-serif",
    backgroundColor: "#FF0000",
    borderRadius: "50px",
    width: "12vw",
    height: "2.0vw",
    fontSize: "0.9vw",
    textTransform: "none",
  },
});

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);utt
//   };
// Customizable Area End

import TeacherClassesController, { Props } from "./TeacherClassesController.web";
import { Formik } from "formik";
import { Divider } from "react-native-elements";
import Avatar from "@material-ui/core/Avatar";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import CircularProgress from "@material-ui/core/CircularProgress";

export class TeacherClasses extends TeacherClassesController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    console.log(this.state.scheduleClassesList,"scheduleClassesListscheduleClassesListscheduleClassesList")
    return (
      // Customizable Area Start
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.add_contract_container}>
          <div className={classes.dash_left_c}>
            <DashNav
              active="Classes&Teachers"
              styles={{ marginBottom: "1.5vw" }}
              navigation={this.props.navigation}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
            />
          </div>
          <div className={classes.add_contract_cc}>
            {this.state.isHiringPostsLoading ? (
              <div className={classes.loadingProfile} style={{ marginLeft: "2em" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <div>
                  <p className={classes.pageTitle}>Classes & Teachers </p>
                </div>
                <Divider style={{ margin: "2em,0em" }} />
                <Typography variant="h5" className={classes.hiringPost}>
                  {" "}
                  Hiring Posts
                </Typography>

                <div>
                  <TableContainer className={classes.tableContainerStyle} component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow style={{ color: "white" }}>
                          <TableCell className={classes.tableHeadStyle} align="center">
                            Subject
                          </TableCell>
                          <TableCell className={classes.tableHeadStyle} align="center">
                            Duration
                          </TableCell>
                          <TableCell className={classes.tableHeadStyle} align="center">
                            Start Date
                          </TableCell>
                          <TableCell className={classes.tableHeadStyle} align="center">
                            Schedule
                          </TableCell>
                          <TableCell className={classes.tableHeadStyle} align="center">
                            Applications
                          </TableCell>
                          <TableCell className={classes.tableHeadStyle} align="center" />
                        </TableRow>
                      </TableHead>
                      {
                        <TableBody>
                          {this.state.hiringPosts?.map((eachPost, key) => {
                            return (
                              <TableRow key={key}>
                                <TableCell scope="row" align="center">
                                  {eachPost.attributes.subject}
                                </TableCell>
                                <TableCell className={classes.tableCellStyle} align="center">
                                  {eachPost.attributes.duration}
                                </TableCell>
                                <TableCell className={classes.tableCellStyle} align="center">
                                  {eachPost.attributes.start_date}
                                </TableCell>
                                <TableCell className={classes.tableCellStyle} align="center">
                                  {eachPost.attributes.schedule}
                                </TableCell>
                                <TableCell className={classes.tableCellStyle} align="center">
                                  {eachPost.attributes.applications}
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    className={classes.viewSubmissionButton}
                                    data-testId={`viewSubmissionBtn${eachPost.id}`}
                                    onClick={() => {
                                      this.handleViewSubmissionBtn(eachPost);
                                    }}
                                    disableElevation
                                    variant="contained"
                                  >
                                    View Submissions
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                </div>
                {this.state.recentlyHiredList?.length && (
                  <>
                    <Typography className={classes.hireTeacherTitle}>Recently Hire Teachers</Typography>
                    <div>
                      <div style={{ display: "flex", width: "100%" }}>
                        {this.state.recentlyHiredList?.slice(0, 3).map((eachPost: any) => {
                          return (
                            <div className={classes.hireTeacherContainer} key={eachPost?.id}>
                              <div style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}>
                                <Avatar
                                  alt=""
                                  src={eachPost?.attributes?.account?.data?.attributes?.profile_image}
                                  className={classes.avatarSty}
                                />
                                <div style={{ justifyContent: "center" }}>
                                  <p className={classes.teacherName}>{`${eachPost.attributes.first_name +
                                    eachPost.attributes.last_name}`}</p>
                                  <p className={classes.teacherSubject}>
                                    {eachPost.attributes.specialization}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: 10,
                                  marginBottom: 20,
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#f00",
                                    color: "#fff",
                                    textTransform: "none",
                                    borderRadius: 100,
                                  }}
                                  size="small"
                                >
                                  Send Message
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    this.props.navigation.navigate("ViewSchedule", {
                                      ViewScheduleId: eachPost.id,
                                    });
                                  }}
                                  style={{
                                    background: "#f0d17c",
                                    textTransform: "none",
                                    borderRadius: 100,
                                    fontWeight: 550,
                                  }}
                                  size="small"
                                >
                                  View Schedule
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div>
                    <Typography className={classes.scheduleTitle}>Scheduled Classes</Typography>
                  </div>

                  <Grid
                    container
                    spacing={3}
                    style={{ display: "flex", flexDirection: "row", marginTop: "1.5em", marginBottom: "2em" }}
                  >
                    {
                      this.state.scheduleClassesList?.map((eachClass: any) => {
                        return (
                        <Grid item xs={4} key={eachClass.id}>
                          <Card className={classes.RelatedCourseCard}>
                            <CardMedia>
                              <img className={classes.scheduledCardImages} src={cardBoys} alt="" />
                            </CardMedia>
                            <CardContent>
                              <Typography className={classes.courseTitle}>{eachClass?.attributes?.hire_teacher_request?.data?.attributes?.subject}</Typography>
                              <Typography className={classes.courseContent}>
                              {eachClass?.attributes?.hire_teacher_request?.data?.attributes?.description}
                              </Typography>
    
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "13px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <img
                                    style={{ height: "16px", width: "16px" }}
                                    src={desktop}
                                    alt="classViewIcon"
                                  />
                                  <Typography className={classes.typeOfClass}>One on One</Typography>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <img
                                    style={{ height: "16px", width: "16px", marginLeft: "1em" }}
                                    src={screenView}
                                    alt="screenViewIcon"
                                  />
                                  <Typography className={classes.timeOfTheClass}>{ eachClass?.attributes?.contract_status!=="Under Review"&&eachClass?.attributes?.contract_status?.data[0]?.attributes?.class_slot?.replace(/\$/g, ' ')}</Typography>
                                </div>
                              </div>
                              <div style={{ display: "flex", marginTop: "1em" }}>
                                <img style={{ height: "16px", width: "16px" }} src={timeIcon} alt="" />
                                <Typography className={classes.typeofView}>{eachClass?.attributes.first_name + " " + eachClass?.attributes.last_name}</Typography>
                              </div>
                            </CardContent>
                            <CardActions>
                              <div
                                style={{
                                  display: "flex",
                                  columnGap: 20,
                                  marginBottom: 20,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#f00",
                                    color: "#fff",
                                    textTransform: "none",
                                    borderRadius: 100,
                                    width: 115,
                                  }}
                                  size="small"
                                  onClick={()=>{this.handleNavigationToStudentSubjectDetails(eachClass)}}
                                >
                                  View Detials
                                </Button>
                              </div>
                            </CardActions>
                          </Card>
                        </Grid>
                        );
                      })
                    
                     } 
              
                  </Grid>
                  <div style={{margin:"2em 0em"}}>
                  <ReactPaginate
                      previousLabel={<img src={back} alt="next" className="page-img" />}
                      nextLabel={<img src={next} alt="next" className="page-img" />}
                      pageCount={this.state.sheduledClassesPageCount}
                      forcePage={this.state.page-1}
                      renderOnZeroPageCount={1}
                      containerClassName={"pagination-C"}
                      onPageChange={(event)=>{
                        this.setState({currentPage:event.selected+1},()=>{
                          this.getScheduleClassesData()
                        })
                          console.log("event",event)
                      }}
                      activeClassName={"active"}
                  />
              
                 </div>
                </div>
              </>
            )}
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(TeacherClasses);

// Customizable Area End
