import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

const initialStateValues = {
  question: "",
  marks: "",
  errPost: false,

  MCchecked: [false, false, false, false],
  MCoption1: "",
  MCoption2: "",
  MCoption3: "",
  MCoption4: "",
  MCerrCorrectCheck: false,

  DDchecked: [false, false, false, false],
  DDoption1: "",
  DDoption2: "",
  DDoption3: "",
  DDoption4: "",
  DDerrCorrectCheck: false,

  Danswer: "",

  UFfile: undefined,
};

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
  webStyle?: any;
}

export interface S {
  // Customizable Area Start
  open: boolean;
  checked: boolean[];
  mode: "default" | "mcq" | "drop_down" | "upload" | "descriptive" | "success";
  checkedIndex: number | undefined;
  question: string;
  marks: string;
  errPost: boolean;

  MCchecked: boolean[];
  MCoption1: string;
  MCoption2: string;
  MCoption3: string;
  MCoption4: string;
  MCerrCorrectCheck: any;

  DDchecked: boolean[];
  DDoption1: string;
  DDoption2: string;
  DDoption3: string;
  DDoption4: string;
  DDerrCorrectCheck: any;

  Danswer: string;

  UFfile: any;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class CreateAssessmentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCreateAssessmentCallId: any;
  apiAddAnotherQuestiontCallId: any;
  apiViewPostQuestionCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      open: false,
      checked: [false, false, false, false],
      mode: "default",

      checkedIndex: undefined,


      ...initialStateValues,

      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiCreateAssessmentCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
                  console.log("Response.......", responseJson);
                  await this.setState({ errPost: false, mode: "success" });
            }
          } 
           this.parseApiCatchErrorResponse(errorReponse);   
        } else if (apiRequestCallId === this.apiAddAnotherQuestiontCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
                  console.log("Response.......", responseJson);
                  localStorage.setItem("assessment_id", responseJson?.assessment_id)
                  await this.setState({ errPost: false, mode: "success",open: true });
            }
          } 
           this.parseApiCatchErrorResponse(errorReponse);   
        } 
      }
    }
  }





  async componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleChooseQuestionType = async (index: number | undefined) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.setState({ open: false });
    switch (index) {
      case 0:
        this.setState({ mode: "mcq" });
        break;
      case 1:
        this.setState({ mode: "drop_down" });
        break;
      case 2:
        this.setState({ mode: "descriptive" });
        break;
      case 3:
        this.setState({ mode: "upload" });
        break;

      default:
        this.setState({ mode: "default" });
        break;
    }
  };

  handleClose = () => {
    this.setState({ open: false, ...initialStateValues });
  };
  handleCancel = () => {
    this.setState({ mode: "default" });
  };

  checkedHandler = (index: number) => {
    const newChecked = [false, false, false, false];
    newChecked[index] = true; // toogle the checkbox
    this.setState({
      checked: newChecked,
      checkedIndex: index,
    });
  };

 

  handleAssessmentPost = async (e:any) => {
    e.preventDefault();
    switch (this.state.mode) {
      case "mcq":
        if (
          this.state.question.length > 0 &&
          this.state.MCoption1.length > 0 &&
          this.state.MCoption2.length > 0 &&
          this.state.MCoption3.length > 0 &&
          this.state.MCoption4.length > 0 &&
          this.state.marks &&
          this.state.MCerrCorrectCheck
        ) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]","abcd");
          formdata.append("[questionbanks_attributes][0][question_type]", this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][description]",this.state.MCoption1);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][is_correct]",  this.state.MCchecked[0]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][description]", this.state.MCoption2 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][is_correct]",  this.state.MCchecked[1]? "true": "false"  );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][description]",  this.state.MCoption3 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][is_correct]",  this.state.MCchecked[2]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][description]",  this.state.MCoption4 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][is_correct]",  this.state.MCchecked[3]? "true": "false"  );

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiCreateAssessmentCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);
     

          console.log("MultipleChoice posttttttttt");

          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      case "drop_down":
        if (
          this.state.question.length > 0 &&
          this.state.DDoption1.length > 0 &&
          this.state.DDoption2.length > 0 &&
          this.state.DDoption3.length > 0 &&
          this.state.DDoption4.length > 0 &&
          this.state.marks &&
          this.state.DDerrCorrectCheck
        ) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]"," cvn c n");
          formdata.append("[questionbanks_attributes][0][question_type]", this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][description]",this.state.MCoption1);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][is_correct]",  this.state.MCchecked[0]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][description]", this.state.MCoption2 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][is_correct]",  this.state.MCchecked[1]? "true": "false"  );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][description]",  this.state.MCoption3 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][is_correct]",  this.state.MCchecked[2]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][description]",  this.state.MCoption4 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][is_correct]",  this.state.MCchecked[3]? "true": "false"  );

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiCreateAssessmentCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);
          
          console.log("Dropdown posttttttttt");
          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      case "descriptive":
        if (this.state.question.length > 0 && this.state.Danswer.length > 0 && this.state.marks) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]",this.state.Danswer);
          formdata.append("[questionbanks_attributes][0][question_type]",this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiCreateAssessmentCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);
          
          console.log("Descriptive posttttttttt");

          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      case "upload":
        if (this.state.question.length > 0 && this.state.UFfile && this.state.marks) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]",this.state.UFfile.name);
          formdata.append("[questionbanks_attributes][0][question_type]", this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiCreateAssessmentCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);

          console.log("Upload posttttttttt");

          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      default:
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log("mode change posttttttttt");
        this.setState({ mode: "default" });
        break;

    } 
      
  };

  AddAnotherQuestionhandleOpen = async () =>{
    switch (this.state.mode) {
      case "mcq":
        if (
          this.state.question.length > 0 &&
          this.state.MCoption1.length > 0 &&
          this.state.MCoption2.length > 0 &&
          this.state.MCoption3.length > 0 &&
          this.state.MCoption4.length > 0 &&
          this.state.marks &&
          this.state.MCerrCorrectCheck
        ) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]","abcd");
          formdata.append("[questionbanks_attributes][0][question_type]", this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][description]",this.state.MCoption1);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][is_correct]",  this.state.MCchecked[0]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][description]", this.state.MCoption2 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][is_correct]",  this.state.MCchecked[1]? "true": "false"  );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][description]",  this.state.MCoption3 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][is_correct]",  this.state.MCchecked[2]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][description]",  this.state.MCoption4 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][is_correct]",  this.state.MCchecked[3]? "true": "false"  );

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiAddAnotherQuestiontCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.AnotherAddQuestionEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);
     

          console.log("MultipleChoice posttttttttt");

          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      case "drop_down":
        if (
          this.state.question.length > 0 &&
          this.state.DDoption1.length > 0 &&
          this.state.DDoption2.length > 0 &&
          this.state.DDoption3.length > 0 &&
          this.state.DDoption4.length > 0 &&
          this.state.marks &&
          this.state.DDerrCorrectCheck
        ) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]",this.state.mode);
          formdata.append("[questionbanks_attributes][0][question_type]", this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][description]",this.state.MCoption1);
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][0][is_correct]",  this.state.MCchecked[0]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][description]", this.state.MCoption2 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][1][is_correct]",  this.state.MCchecked[1]? "true": "false"  );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][description]",  this.state.MCoption3 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][2][is_correct]",  this.state.MCchecked[2]? "true": "false" );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][description]",  this.state.MCoption4 );
          formdata.append("[questionbanks_attributes][0][answer_options_attributes][3][is_correct]",  this.state.MCchecked[3]? "true": "false"  );

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiAddAnotherQuestiontCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);
          
          console.log("Dropdown posttttttttt");
          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      case "descriptive":
        if (this.state.question.length > 0 && this.state.Danswer.length > 0 && this.state.marks) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]",this.state.Danswer);
          formdata.append("[questionbanks_attributes][0][question_type]",this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiAddAnotherQuestiontCallId= requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);
          
          console.log("Descriptive posttttttttt");

          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      case "upload":
        if (this.state.question.length > 0 && this.state.UFfile && this.state.marks) {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

          const token = localStorage.getItem("loginT");
          const subjectNameId:any = localStorage.getItem("SubjectDetailsId");
          
          const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            token: token
          };
          var formdata = new FormData();
          
          formdata.append("subject_id", subjectNameId);
          formdata.append("name", this.state.question);
          formdata.append("passing_percentage", this.state.marks);
          formdata.append("[questionbanks_attributes][0][description]",this.state.UFfile.name);
          formdata.append("[questionbanks_attributes][0][question_type]", this.state.mode);
          formdata.append("[questionbanks_attributes][0][total_marks]", this.state.marks);

          console.log("formdata", formdata);

          const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiAddAnotherQuestiontCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createAssessmentEndPoint
          );

          requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

          requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

          requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);

          runEngine.sendMessage(requestMessage.id, requestMessage);
          console.log("data", formdata);

          console.log("Upload posttttttttt");

          await this.setState({ ...initialStateValues });
        } else {
          this.setState({ errPost: true });
        }
        break;

      default:
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log("mode change posttttttttt");
        this.setState({ mode: "default" });
        break;

    } 
  }
  ViewPostQuestionhandle  = () =>{
    this.props.navigation.navigate("ViewQuestion");

  }

  MChandleCorrectCheck = async (index: number) => {
    const newChecked = [false, false, false, false];
    newChecked[index] = true; // toogle the checkbox
    await this.setState({ MCchecked: newChecked });
    console.log("checked", this.state.MCchecked);
    if (this.state.MCchecked.includes(true)) {
      this.setState({ MCerrCorrectCheck: true });
      console.log("include");
    }
    console.log("MCchecked", this.state.MCchecked)
  };

  DDhandleCorrectCheck = async (index: number) => {
    const newChecked = [false, false, false, false];
    newChecked[index] = true; // toogle the checkbox
    await this.setState({ DDchecked: newChecked });
    console.log("checked", this.state.DDchecked);
    if (this.state.DDchecked.includes(true)) {
      this.setState({ DDerrCorrectCheck: true });
      console.log("include");
    }
  };

  UFhandleFile = async (e: any) => {
    const files = e.target.files[0];
    // const formData = new FormData();
    // formData.append("img", files[0]);
    this.setState({ UFfile: files });
    console.log("File", this.state.UFfile);
  };
}
