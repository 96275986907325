import React, { Component } from "react";
import { dropdown, fileUploadIcon } from "./assets";
import Banner from "./Components/Banner.web";
import BottomNavigation from "./BottomNavigation.web";
import PopularCoursesWeb from "./PopularCourses.web";
import TeacherSignUpStep2Controller, { Props } from "./TeacherSignUpStep2Controller.web";
import TopNavigation from "./TopNavigation.web";

export default class TeacherSignUpStep2 extends TeacherSignUpStep2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="TSU-container">
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className="TSU-container-c">
          <div className="TSU-title-container">
            <p className="TSU-title">Sign Up</p>
            <p className="TSU-sub-title">Step 2 of 2</p>
          </div>
          <div className="TSU-input-container">
            <div className="TSU-as-container">
              <div>
                <p className="TSU-label-1">Teacher Registration</p>
              </div>
            </div>
            <div className="TSU-steps-container">
              <div className="TSU-steps-title-container">
                <p className="TSU-steps-title-1">Profile</p>
                <p className="TSU-steps-title-2">Academics</p>
              </div>
              <div className="TSU-steps-c">
                <div className="TSU-steps">
                  <div className="TSU-steps-c-1">
                    <p>1</p>
                  </div>
                </div>
                <hr className="TSU-steps-hl" />
                <div className="TSU-steps">
                  <div className="TSU-steps-c-2">
                    <p>2</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="TSU-inputs-ccc">
            <div className="TSU-input-cc">
              <div className="TSU-input-left">
                <label className="TSU-input-lable">Qualification*</label>
                <div className="TSU-input-dd">
                  <select
                    className="TSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleQualiChange(e);
                    }}
                    style={this.state.errQualification ? this.errStyle : {}}
                    data-testId="TSS2_handleQualiChange"
                  >
                    <option value="Select">Select</option>
                    {this.state.lQualification.map((value, index) => {
                      return (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className="TSU-input-arrow"
                    style={this.state.errQualification ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                  {this.state.errQualification ? (
                    <p className="prl-input-err-txt">Please select a value.</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="TSU-input-right">
                <label className="TSU-input-lable">Collage Name*</label>
                <input
                  className="TSU-input"
                  type="text"
                  value={this.state.collageName}
                  required
                  onChange={(e) => {
                    this.handleCollageChange(e);
                  }}
                  placeholder="Enter Name"
                  style={this.state.errCollage ? this.errStyle : {}}
                  data-testId="TSS2_handleCollageChange"
                />
                {this.state.errCollage ? <p className="prl-input-err-txt">Please enter a value.</p> : <></>}
              </div>
            </div>
            <div className="TSU-input-cc">
              <div className="TSU-input-left">
                <label className="TSU-input-lable">Specialization*</label>
                <input
                  className="TSU-input"
                  type="text"
                  value={this.state.specialization}
                  required
                  onChange={(e) => {
                    this.handleSpecialChange(e);
                  }}
                  placeholder="Enter details"
                  style={this.state.errSpecial ? this.errStyle : {}}
                  data-testId="TSS2_handleSpecialChange"
                />
                {this.state.errSpecial ? <p className="prl-input-err-txt">Please enter a value.</p> : <></>}
              </div>
              <div className="TSU-input-right">
                <label className="TSU-input-lable">Course of Interest*</label>
                <div className="TSU-input-dd">
                  <select
                    className="TSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleIntCourseChange(e);
                    }}
                    style={this.state.errCourseInt ? this.errStyle : {}}
                    data-testId="TSS2_handleIntCourseChange"
                  >
                    <option value="Select">Select</option>
                    {this.state.lInterest.map((value, index) => {
                      return (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className="TSU-input-arrow"
                    style={this.state.errCourseInt ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                  {this.state.errCourseInt ? (
                    <p className="prl-input-err-txt">Please select a value.</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="TSU-input-cc">
              <div className="TSU-input-left">
                <label className="TSU-input-lable">Total Experience*</label>
                <div className="TSU-input-dd">
                  <select
                    className="TSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleExperienceChange(e);
                    }}
                    style={this.state.errExp ? this.errStyle : {}}
                    data-testId="TSS2_handleExperienceChange"
                  >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                  <span
                    className="TSU-input-arrow"
                    style={this.state.errExp ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                  {this.state.errExp ? <p className="prl-input-err-txt">Please select a value.</p> : <></>}
                </div>
              </div>
              <div className="TSU-input-right">
                <label className="TSU-input-lable">Availability*</label>
                <div className="TSU-input-dd">
                  <select
                    className="TSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleAvailabilityChange(e);
                    }}
                    style={this.state.errAvailable ? this.errStyle : {}}
                    data-testId="TSS2_handleAvailabilityChange"
                  >
                    <option value="Select">Select</option>
                    <option value="1 week">1 week</option>
                    <option value="2 week">2 week</option>
                  </select>
                  <span
                    className="TSU-input-arrow"
                    style={this.state.errAvailable ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                  {this.state.errAvailable ? (
                    <p className="prl-input-err-txt">Please select a value.</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="TSU-input-cc">
              <div className="TSU-input-left">
                <label className="TSU-input-lable">Languages Known*</label>
                <div
                  className="TSU-lang-check-c"
                  style={this.state.errLangData ? { backgroundColor: "#fcecec", borderRadius: "0.3em" } : {}}
                >
                  <div className="TSU-lang-check-cc">
                    <input
                      type="checkbox"
                      className="TSU-lang-checkbox"
                      checked={this.state.langEng}
                      onChange={this.handleEngLang}
                      data-testId="TSS2_handleEngLang"
                    />
                    <p>English</p>
                  </div>
                  <div className="TSU-lang-check-cc">
                    <input
                      type="checkbox"
                      className="TSU-lang-checkbox"
                      checked={this.state.langHindi}
                      onChange={this.handleHindiLang}
                      data-testId="TSS2_handleHindiLang"
                    />
                    <p>Hindi</p>
                  </div>
                  <div className="TSU-lang-check-cc">
                    <input
                      type="checkbox"
                      className="TSU-lang-checkbox"
                      checked={this.state.langOther}
                      onChange={this.handleOtherLang}
                      data-testId="TSS2_handleOtherLang"
                    />
                    <p>Others</p>
                  </div>
                  {this.state.errLang ? <p className="prl-input-err-txt">Please enter a value.</p> : <></>}
                </div>
                {this.state.errLangData ? (
                  <p className="prl-input-err-txt">Please select a language.</p>
                ) : (
                  <></>
                )}
              </div>
              <div className="TSU-input-right">
                <label className="TSU-input-lable">Enter Other Languages</label>
                <input
                  className="TSU-input"
                  type="text"
                  value={this.state.otherLang}
                  required
                  onChange={(e) => {
                    this.handleOtherLangChange(e);
                  }}
                  placeholder="Enter Languages"
                  // style={this.state.errOtherLang ? this.errStyle : {}}
                  style={this.state.langOther ? {} : { backgroundColor: "#EEEEEE" }}
                  disabled={this.state.langOther ? false : true}
                  data-testId="TSS2_handleOtherLangChange"
                />
              </div>
            </div>
            <div className="TSU-input-cc">
              <div className="TSU-input-left">
                <label className="TSU-input-lable">Upload CV*</label>
                {/* <input
                  className="TSU-input-file"
                  type="file"
                  value=""
                  required
                  onChange={(e) => {}}
                  placeholder="Enter Languages"
                /> */}
                <div className="TSU-input-file-c">
                  <input
                    type="file"
                    id="file"
                    accept=".doc,.docx,.pdf"
                    className="TSU-input-file"
                    value=""
                    required
                    onChange={(e) => {
                      this.handleFile1(e);
                    }}
                    placeholder="Enter Languages"
                    data-testId="TSS2_handleFile1"
                  />
                  <label
                    htmlFor="file"
                    className="TSU-input-file-img-c"
                    style={this.state.errFile1 ? this.errStyle : {}}
                  >
                    <img src={fileUploadIcon} alt="file" />

                    {this.state.file1 ? <label>{this.state.file1.name}</label> : <label>Upload file</label>}
                  </label>
                </div>
                {this.state.errFile1 ? <p className="prl-input-err-txt">Please upload CV</p> : <></>}
              </div>
              <div className="TSU-input-right">
                <label className="TSU-input-lable">Upload any valid government ID*</label>
                {/* <input
                  className="TSU-input-file"
                  type="file"
                  value=""
                  required
                  onChange={(e) => {}}
                  placeholder="Enter Languages"
                /> */}
                <div className="TSU-input-file-c">
                  <input
                    type="file"
                    id="file2"
                    accept=".doc,.docx,.pdf"
                    className="TSU-input-file"
                    value=""
                    required
                    onChange={(e) => {
                      this.handleFile2(e);
                    }}
                    placeholder="Enter Languages"
                    data-testId="TSS2_handleFile2"
                  />
                  <label
                    htmlFor="file2"
                    className="TSU-input-file-img-c"
                    style={this.state.errFile2 ? this.errStyle : {}}
                  >
                    <img src={fileUploadIcon} alt="file" />
                    {this.state.file2 ? <label>{this.state.file2.name}</label> : <label>Upload file</label>}
                  </label>
                </div>
                {this.state.errFile2 ? <p className="prl-input-err-txt">Please upload ID</p> : <></>}
              </div>
            </div>
            <div className="TSU-submit-container">
              <div>
                <button
                  className="TSU-submit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  data-testId="TSS2_handleSubmit"
                >
                  Register Now
                </button>
              </div>
              <div className="TSU-already" style={{ marginTop: "1.5em" }}>
                <p>Already on Techfin? </p>
                <button
                  className="TSU-login-btn"
                  onClick={this.handleLoginNav}
                  data-testId="TSS2_handleLoginNav"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <PopularCoursesWeb
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
          classes={undefined}
        />
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
