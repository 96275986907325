Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.updateNotificationAPIEndPoint = "update_notification_permissions"
exports.getNotificationAPIEndPoint = "get_notifications_permissions"
exports.getPaymentDetailsEndPoint = "account_courses/get_all_payments"

exports.getHttpMethodType ="GET"
exports.httpPostMethod = "POST"
exports.updateProfileEndpoint = "update_profile"
exports.profileDetailsEndpoint = "profile_details"
exports.qualificationsEndpoint = "qualifications"
exports.interestsEndpoint = "interests"

// Customizable Area End