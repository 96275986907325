import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  expand: string | false;
  open: any;
  cType: any;
  fName: string;
  cemail: string;
  helpList: any;
  QueryhelpList: any;
  queryTypeId: any;
  FAQList: any;
  MapLocation: any;
  description: string;
  selectHelpList: string;
  errState: boolean;
  errFName: boolean;
  errEmail: boolean;
  errcType: boolean;
  errqueryTypeId: boolean;
  errDescription: boolean;
  errMaxFName: boolean;
  errMaxDescription: boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class ContactUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiContactUsFormCallId: any;
  apiHelpListCallId: any;
  apiFAQListCallId: any;
  apiMapLocationCallId: any;
  errStyle: any;
  mytemplateForm: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      expand: false,
      open: false,
      cType: "",
      fName: "",
      cemail: "",
      description: "",
      helpList: [],
      QueryhelpList: [],
      queryTypeId: "",
      selectHelpList: "",
      FAQList: [],
      MapLocation: "",
      errState: false,
      errFName: false,
      errEmail: false,
      errDescription: false,
      errcType: false,
      errqueryTypeId: false,
      errMaxFName: false,
      errMaxDescription: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.errStyle = {
      // backgroundColor: "#fcecec",
      // borderColor: "#fb7b7c",
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiContactUsFormCallId) {
          if (!responseJson.errors) {
            console.log("Response.......", responseJson);
            this.setState({open : true});
            this.handelRest();
          } else {
            console.log("ERR_Response", responseJson);
            if (responseJson.error) {
              alert(responseJson.error);
            }
          }
          this.parseApiErrorResponse(responseJson);
        } else if (apiRequestCallId === this.apiHelpListCallId) {
          if (!responseJson.error) {
            console.log("HelpList_Response", responseJson);
            this.setState({ helpList: responseJson });
            console.log(" List State", this.state.helpList);
          }
          this.parseApiErrorResponse(responseJson);
        } else if (apiRequestCallId === this.apiFAQListCallId) {
          if (!responseJson.error) {
            console.log("FAQList_Response", responseJson);
            this.setState({ FAQList: responseJson });
            console.log(" FAQList State", this.state.FAQList);
          }
          this.parseApiErrorResponse(responseJson);
        } else if (apiRequestCallId === this.apiMapLocationCallId) {
          if (!responseJson.error) {
            console.log("Location_Response", responseJson);
            this.setState({ MapLocation: responseJson.data });
            console.log(" Location State", this.state.MapLocation);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    console.log("componentDidMount ContactUs");
    this.getHelpList();
    this.MapLocation();
    this.FAQList();
  }
  handleClose=()=>{
    this.setState({
      open:false
    })
  }

  handleExpand = (isExpanded: boolean, panel: string) => {
    this.setState(isExpanded ? { expand: panel } : { expand: false });
  };

  handelRest = () => {
    this.setState({ cType: "learner", selectHelpList: "Select", fName: "", cemail: "", description: "" });
  };

  async handleLernerChange(e: any) {
    await this.setState({ cType: e.target.value });
    if (this.state.cType.length <= 0 || this.state.cType == "Please Select") {
      await this.setState({ errcType: true });
    } else {
      await this.setState({ errcType: false });
    }
  }

  handleHelpListChange = async (event: any) => {
    console.log(event.target.value);
    const val = event.target.value;
    if (val != "select") {
      console.log("val", val);
      await this.setState({ selectHelpList: val });
    }

    // else if (val == "select") {
    //   await this.setState({ selectHelpList: null });
    // }
  };
  async handleFNameChange(e: any) {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      await this.setState({ fName: e.target.value });
    }

    // console.log(this.state.fName);
    if (this.state.fName.length > 15) {
      await this.setState({ errMaxFName: true });
      // console.log("fName error");
    } else {
      await this.setState({ errMaxFName: false });
    }
  }

  async handleEmailChange(e: any) {
    await this.setState({ cemail: e.target.value });
    // console.log(this.state.cemail);
    const VEmail = this.state.cemail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    // console.log(VEmail);
    if (this.state.cemail.length <= 0 || VEmail == null) {
      await this.setState({ errEmail: true });
      // console.log("fName error");
    } else {
      await this.setState({ errEmail: false });
    }
  }

  async handleDescriptionChange(e: any) {
    await this.setState({ description: e.target.value });
    if (this.state.description.length > 30) {
      await this.setState({ errMaxDescription: true });
      // console.log("fName error");
    } else {
      await this.setState({ errMaxDescription: false });
    }
  }

  getHelpList = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "access-control-allow-origin": "no-cors",
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiHelpListCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactUsHelpListApiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  MapLocation = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "access-control-allow-origin": "no-cors",
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiMapLocationCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactUsMapLocationApiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  FAQList = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      "access-control-allow-origin": "no-cors",
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiFAQListCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FAQListApiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  async handleSubmit() {
    const VEmail = this.state.cemail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (this.state.cType.length <= 0) {
      await this.setState({ errcType: true });
    } else {
      await this.setState({ errcType: false });
    }

    if (this.state.queryTypeId.length <= 0) {
      await this.setState({ errqueryTypeId: true });
    } else {
      await this.setState({ errqueryTypeId: false });
    }

    if (this.state.fName.length <= 0) {
      await this.setState({ errFName: true });
      // console.log("fName error");
    } else {
      await this.setState({ errFName: false });
    }
    // if (this.state.fName.length > 15) {
    //   await this.setState({ errMaxFName: true });
    //   // console.log("fName error");
    // } else {
    //   await this.setState({ errMaxFName: false });
    // }

    if (this.state.cemail.length <= 0 || VEmail == null) {
      await this.setState({ errEmail: true });
      // console.log("cemail error");
    } else {
      await this.setState({ errEmail: false });
    }

    if (this.state.description.length <= 0) {
      await this.setState({ errDescription: true });
      // console.log("address error");
    } else {
      await this.setState({ errDescription: false });
    }
    if (this.state.description.length > 30) {
      await this.setState({ errMaxDescription: true });
      // console.log("fName error");
    } else {
      await this.setState({ errMaxDescription: false });
    }

    if (
      this.state.errFName === false &&
      this.state.errEmail === false &&
      this.state.errDescription === false
    ) {
      /////////////////////////////////////////////////////////////////////////

      console.log("Next");

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      var formdata = new FormData();

      formdata.append("name", this.state?.fName);
      formdata.append("email", this.state?.cemail);
      formdata.append("account_type", this.state?.cType);
      formdata.append("query_type_id", this.state?.selectHelpList);
      formdata.append("query_description", this.state?.description);

      console.log("formdata", formdata);

      // const httpBody = {
      //   formdata
      // };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiContactUsFormCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ContactUsFormAPiEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PostMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
      console.log("formdata", formdata);
      return true;
    }
  }
}

// Customizable Area End
