import React, { Component } from "react";
import BannerTeacher from "./BannerTeacher.web";
import TeachersLandingPageController, {
  Props,
} from "./TeachersLandingPageController.web";
import { TL_SEC_1, TL_SEC_2, TL_SEC_3, TL_SEC_4, TL_SEC_5 } from "./assets";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

export default class TeachersLandingPage extends TeachersLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="TLP-container">
        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
        <BannerTeacher
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
        />
        <div className="TL-sec-1-c">
          <div className="TL-sec-1-txt">
            <p className="TL-sec-1-title">
              Become an Instructor. Teach what you master. Be a life-changer to
              yourself and others
            </p>
            <p className="TL-sec-1-subtitle">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              turpis risus, pharetra quis portitor quis, luctus vitae leo.
              Pelientesqe vel eleifend est, a fringilla augue. Donec faucibus
              venenatis urna. luctus vitae leo. Pellentesque vel eleifend est, a
              fringilla augue. Donec faucibus venenatis urna.
            </p>
            <button className="TL-sec-1-btn">Get Started</button>
          </div>
          <div className="TL-sec-1-img">
            <img src={TL_SEC_1} alt="img" />
          </div>
        </div>
        <div className="TL-sec-2-c">
          <div className="TL-sec-2-img">
            <img src={TL_SEC_2} alt="img" />
          </div>
          <div className="TL-sec-2-txt">
            <div className="TL-sec-2-title">
              <p>Simple & Easy to Start</p>
            </div>
            <div className="TL-sec-2-sub-c">
              <div className="TL-sec-2-txt-subtxt">
                <div className="TL-sec-2-txt-s">
                  <p>1</p>
                </div>
                <p className="TL-sec-2-txt-t">Plan Your Curriculum</p>
              </div>
              <div className="TL-sec-2-txt-subtxt">
                <div className="TL-sec-2-txt-s">
                  <p>2</p>
                </div>
                <p className="TL-sec-2-txt-t">Record Your Video</p>
              </div>
              <div className="TL-sec-2-txt-subtxt">
                <div className="TL-sec-2-txt-s">
                  <p>3</p>
                </div>
                <p className="TL-sec-2-txt-t">Launch Your Course</p>
              </div>
            </div>
          </div>
        </div>
        <div className="TL-sec-3-c">
          <div className="TL-sec-3-txt">
            <p className="TL-sec-3-title">
              Make your classes interactive by streaming live.
            </p>
            <p className="TL-sec-3-subtitle">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              turpis risus, pharetra quis porttitor quis, luctus vitae leo.
              Pellentesque vel eleifend est, a fringilla augue. Donec faucibus
              venenatis urna. luctus vitae leo. Pellentesque vel eleifend est, a
              fringilla augue. Donec faucibus venenatis urna. Dolor sit amet,
              consectetur adipiscing elit. Duis turpis risus, pharetra quis
              porttitor quis, luctus vitae leo.
            </p>
            <button
              className="TL-sec-3-btn"
              onClick={this.handleTSignUpNav}
              data-testId='teacherLandingRegisterBtn1'
            >
              Register Now
            </button>
          </div>
          <div className="TL-sec-3-img">
            <img src={TL_SEC_3} alt="img" />
          </div>
        </div>
        <div className="TL-sec-4-c">
          <div className="TL-sec-4-img">
            <img src={TL_SEC_4} alt="img" />
          </div>
          <div className="TL-sec-4-txt">
            <p className="TL-sec-4-title">Interact with Students</p>
            <p className="TL-sec-4-subtitle">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              turpis risus, pharetra quis porttitor quis, luctus vitae leo.
              Pellentesque vel eleifend est, a fringilla augue. Donec faucibus
              venenatis urna. luctus vitae leo. Pellentesque vel eleifend est, a
              fringilla augue. Donec faucibus venenatis urna. Dolor sit amet,
              consectetur adipiscing elit. Duis turpis risus, pharetra quis
              porttitor quis, luctus vitae leo.
            </p>
            <button className="TL-sec-4-btn">Get Started</button>
          </div>
        </div>
        <div
          className="TL-sec-5-c"
          style={{
            backgroundImage: `url(${TL_SEC_5})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <div className="TL-sec-5-img"> */}
          {/* <img src={TL_SEC_5} alt="img" /> */}
          {/* </div> */}
          <div className="TL-sec-5-txt">
            <p className="TL-sec-5-title">Interested in Teaching?</p>
            <p className="TL-sec-5-subtitle">Be an instructor on Techfin</p>
            <button
              className="TL-sec-5-btn"
              onClick={this.handleTSignUpNav}
              data-testId='teacherLandingRegisterBtn2'
            >
              Join Now
            </button>
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
