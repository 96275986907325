import { Grid } from "@material-ui/core";
import React from "react";
import CourseCard from "./CourseCard.web";

interface Props {
  posts: any[];
  navigateToCoursesDetails: any;
}


const CoursesListPageItem = ({ posts, navigateToCoursesDetails }: Props) => {
  console.log("posts", posts);
  return (
    <div style={{ width: '100%' }}>
      {posts?.length > 0 ? <Grid
        container
        justifyContent="flex-start"
        style={{ marginTop: "3em", width: "100%", columnGap: "1.5vw", rowGap: "1.5vw", marginBottom: "1.5em" }}
      >
        {posts.map((post: any) => (
          <div key={post.id}>
            <CourseCard
              id={post?.id}
              image_url={post?.image_url}
              name={post?.name}
              course_description={post?.course_description}
              selected_count={post?.selected_count}
              course_type={post?.course_type}
              course_cost={post?.course_cost}
              start_date={post?.start_date}
              is_paid={post?.is_paid}
              handleViewCourse={navigateToCoursesDetails}
              handleBuyCourse={navigateToCoursesDetails}
            />
          </div>
        ))}
      </Grid>
        :
        <div style={{ textAlign: 'center', margin: '2em', fontFamily: 'Inter, sans-serif', fontWeight: 600, fontSize: '1.5em' }}>No Course Found</div>
      }
    </div>
  );
};

export default CoursesListPageItem;
