import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import { studentPicture, Women } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
    
  });

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End
import { Divider } from "react-native-elements";
import Avatar from "@material-ui/core/Avatar";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import PendingScreenLongAnswerController, { Props } from "./PendingScreenLongAnswerController.web";



export class PendingScreenLongAnswer extends PendingScreenLongAnswerController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start


    // Customizable Area End
  }

  render() {
  
    return (
      // Customizable Area Start
      <>
      
      <div>
        <TopNavigation navigation={this.props.navigation} 
        history={this.props.history} location={this.props.location}
        />
        {/* Banner */}
        <div>
          <Grid container spacing={3} style={{marginTop:"3%",marginBottom:"3%"}}>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <DashSidebar1 active="Dashboard" styles={{ marginBottom: "1.5vw" }} navigation={this.props.navigation}
              />
              <DashSidebar2
                timings="10:00 AM - 11:00 PM"
                title="Introduction to foundation of digital marketing and e-commerce"
                styles={{ marginBottom: "1.5vw" }}
              />
              <DashSidebar3
                title="Get a chance according to your ability"
                Buttontitle="Test Now"
                subtitle="Lorem Ipsum is simply dummy text of the printing"
                styles={{ marginBottom: "1.5vw" }}
                onClick={() => { }}
              />
            </Grid>
            <Grid item xs={8}>
              <div>
                <p
                  className="PSLA_heading"
                >
                  Assessments
                </p>
                <Divider  style={{marginBottom:"1em"}}/>
              </div>
              <Grid container spacing={3} style={{ display: "flex" }}>
                <Grid item xs={8}>
                <h4>November 2021</h4>
                {/* <Divider /> */}
                <div style={{margin:"15px"}}>
                  <h5>Question 2 of 4</h5>
                 {/* <Progress percent={60} />  */}
                </div>
                <div className="PSLA_question_container">
                  <div className="PSLA_question_container1">
                    <Typography className="PSLA_question_container1_typo">Q2.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
                    <div className="PSLA_question_container1_subtitle_container">
                      <Typography className="PSLA_question_container1_Typo1">
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, itaque provident voluptatum, aperiam officia suscipit recusandae magnam blanditiis pariatur optio quisquam saepe cupiditate magni. Accusantium, provident molestias consequuntur exercitationem nihil mollitia atque quos nostrum ullam repudiandae rem.
                      </Typography>
                    </div>
                    <div>
                      <div className="PSLA_question_container1_typo_Marks1">
                      <Typography className="PSLA_question_container1_typo_marks">Enter Marks</Typography>
                      <Typography>/100</Typography>
                      <Button
                        variant="contained"
                        className="PSLA_question_container1_button"
                        size="medium"
                      >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="PSLA_question_container2">
                  <div className="PSLA_question_container1">
                    <Typography className="PSLA_question_container1_typo">Q2.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
                    <div className="PSLA_question_container1_subtitle_container">
                      <Typography className="PSLA_question_container1_Typo1">
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, itaque provident voluptatum, aperiam officia suscipit recusandae magnam blanditiis pariatur optio quisquam saepe cupiditate magni. Accusantium, provident molestias consequuntur exercitationem nihil mollitia atque quos nostrum ullam repudiandae rem.
                      </Typography>
                    </div>
                    <div>
                      <div className="PSLA_question_container1_typo_Marks1">
                      <Typography className="PSLA_question_container1_typo_marks">Enter Marks</Typography>
                      <Typography>/100</Typography>
                      <Button
                        variant="contained"
                        className="PSLA_question_container1_button"
                        size="medium"
                      >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                  
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <Card className="PSLA_LC_Card">
                    <div style={{ background: "#f7f7f7" }}>
                      <Avatar
                        alt="StudentPicture"
                        src={studentPicture}
                        className="PSLA_LC_Card_Avtar"
                      />
                      <Typography variant="h6" style={{ marginLeft: "30%" }}>
                        Teacher Name
                      </Typography>
                      <Typography variant="subtitle2" style={{ marginLeft: "30%", color: "#9ea7a9" }}>
                        email@gmail.com
                      </Typography>
                    </div>
                    <CardContent className="PSLA_LC_Card_content">
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_LC_Typo"
                      >
                        Experience
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_Card_Typo1"
                      >
                        2-3 years
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_LC_Typo"
                      >
                        Scheduled Classes
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_Card_Typo1"
                      >
                        5
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_LC_Typo"
                      >
                        Qualification
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_Card_Typo1"
                      >
                        Graduate
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_LC_Typo"
                      >
                        Joined on
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="PSLA_LC_Card_Typo1"
                      >
                        10/08/22
                      </Typography>
                    </CardContent>
                    <CardActions className="PSLA_LC_Card1_Action">
                      <Button
                        variant="contained"
                       className="PSLA_LC_Card1_button"
                      >
                        Update Profle
                      </Button>
                    </CardActions>
                  </Card>
                  <Card style={{ borderRadius: "15px" }}>
                    <img src={Women} className="PSLA_LC_Card_img" />
                    <CardContent style={{ justifyContent: "center" }}>
                      <Typography
                        variant="h5"
                        component="h5"
                        style={{ marginBottom: "5px", fontSize: "11px" }}
                      >
                        Don't forget to setup
                        <br />
                        Training for next week
                      </Typography>
                    </CardContent>
                    <CardActions className="PSLA_LC_Card2_Action">
                      <Button
                        variant="contained"
                        className="PSLA_LC_Card2_Button"
                      >
                        Set Up Now
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </div>

        <BottomNavigation navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(PendingScreenLongAnswer);

// Customizable Area End
