import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  teacherClassesList:any

  myClassesPageCount:any
  myClassesTotalCount:any
  myClassesCurrentPage:any
  isPageLoading:any

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class TeachersClassesController extends BlockComponent<Props, S, SS> {
  teacherMyClassesId: any;
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      teacherClassesList:[],
      myClassesPageCount:0,
      myClassesTotalCount:0,
      myClassesCurrentPage:0,

      isPageLoading:true,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getTeacherMyclassesDetails()
    // Customizable Area End
  }




  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForMyclassesList(from,message)
    

    // Customizable Area End
  }

  handleResponseForMyclassesList=(from: string, message: Message)=>{
    if (this.teacherMyClassesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          teacherClassesList: apiResponse?.data,
          myClassesPageCount:apiResponse.page_count,
          myClassesTotalCount:apiResponse.count,
          isPageLoading:false
        })
      }
  }

  }




  handleTeachersMyclassesPagination=(myClassesCurrentPage:any)=>{
    this.setState({
      myClassesCurrentPage: myClassesCurrentPage,
    })
  this.getTeacherMyclassesDetails(myClassesCurrentPage +1)

  }
 

  getTeacherMyclassesDetails(page:any=1) {
    this.setState({
      isPageLoading: true,
    })
    const token = localStorage.getItem("loginT");
    const endPoint = `my_classes?&per_page=${9}&page=${page}`;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getTeacherMyClassesMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teacherMyClassesId = getTeacherMyClassesMsg.messageId;


    getTeacherMyClassesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getTeacherMyClassesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTeacherMyClassesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getTeacherMyClassesMsg.id, getTeacherMyClassesMsg);

  }






  handleNavToSubjectDetails = (eachClass:any) => {
    localStorage.setItem("SubjectDetailsId",eachClass?.id);
    this.props.navigation.navigate("SubjectDetails");
  };
}
