import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  razorPaymentObject:any
  razorPayHireTeacherOrderdetails:any
  razorpayBudgetPaymentId: any;
  razorpayBUdgetOrderId: any;
  razorpayBudgetSignature: any;

  verifyCallData:any

  openPaymentModal:boolean
  

  paymentFailureModal:boolean
  

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}


 // Customizable Area Start
export default class PayProposedBudgetController extends BlockComponent<Props, S, SS> {
    paymentBudgetId: any;
    verifyBudgetCallId:any;
    captureBudgetCallId:any;
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      razorPaymentObject:{},
      razorPayHireTeacherOrderdetails:{},

      razorpayBudgetPaymentId: "",
      razorpayBUdgetOrderId: "",
      razorpayBudgetSignature: "",

      verifyCallData:{},

      openPaymentModal:false,
      paymentFailureModal:false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // Customizable Area Start
      // Customizable Area End
  }
  
// Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
     this.handleRecieveResposeOfPayment(from,message)
     this.handleRecieveResponseOfPayment(from,message)
      if (this.captureBudgetCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {  
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        
  
        if (apiResponse?.errors) {
          alert( apiResponse?.errors)
        } else {
         console.log('Response for captureCallId', apiResponse); 
        }
      }

    // Customizable Area End
  }

  handleRecieveResposeOfPayment=(from: string, message: Message)=>{
    if (this.paymentBudgetId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        alert(apiResponse?.errors)
      } else {
        
        const razorPayOrderid = apiResponse?.data?.data?.attributes?.razorpay_order_id
        this.setState({
          razorPayHireTeacherOrderdetails: apiResponse?.data?.data?.attributes
        })
        if (razorPayOrderid) {
          this.handleBudgetRazorPayment(this.verifyPayment,this.paymentBudgetFailure)
        }

      }
  }
  }

  handleRecieveResponseOfPayment=(from: string, message: Message)=>{
    if (this.verifyBudgetCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiResponse?.errors) {
        alert(apiResponse?.errors)
      } else {
        if (apiResponse.data.data.attributes.payment_status === "success") {
          this.capturePayment(apiResponse.data.data.attributes.id)
          this.setState({ verifyCallData: apiResponse?.data?.data },()=>{
          this.handleSucessOpen()
          })
        }
      }
    }
  }

 handleBudgetPaymentInRazorPay = (obj: any) => {
    this.setState({ razorPaymentObject: obj })
  }


  handleBudgetPayButton = () => {
    const budgetId = this.props.navigation.getParam('budgetId');
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    const httpBody = {
        hiring_request_id: budgetId
    };

    const getPayRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.paymentBudgetId = getPayRequestMsg.messageId;


    getPayRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.budgetPayRequest,
    );

    getPayRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPayRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    getPayRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(getPayRequestMsg.id, getPayRequestMsg);
  }

  handleBudgetRazorPayment = async (verifyPayment: any,paymentBudgetFailure:any) => {
    const { razorPayHireTeacherOrderdetails } = this.state
    const { account, hire_teacher_request } = razorPayHireTeacherOrderdetails
    const options = {
      key: "rzp_test_CzInVsmPYOMFQA", // Enter the Key ID generated from the Dashboard
      amount: Number(hire_teacher_request.data.attributes.proposed_budget), 
      currency: "INR",
      name: "Tech Fin",
      description: "Budget Amount for hiring",
      image: "",
      order_id: razorPayHireTeacherOrderdetails.razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response: any) {
        verifyPayment(response)
        paymentBudgetFailure(false)
      },
      prefill: {
        name: account.data.attributes.first_name + account.data.attributes.last_name,
        email: account.data.attributes.email,
        contact: account.data.attributes.full_phone_number,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#ff0000",
      },
    };

    const rzp1 = new this.state.razorPaymentObject(options);

    rzp1.on("payment.failed", function (response: any) {
      //can use the response to show what is the reason for failure
         paymentBudgetFailure(true)
    });

    rzp1.open();
  }

  verifyPayment = async (response: any) => {
   
     this.setState({
      razorpayBudgetPaymentId: response.razorpay_payment_id,
      razorpayBUdgetOrderId: response.razorpay_order_id,
      razorpayBudgetSignature: response.razorpay_signature
    })


    //api call
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    const httpBody = {
      razorpay_payment_id: response.razorpay_payment_id,
      razorpay_order_id: response.razorpay_order_id,
      razorpay_signature: response.razorpay_signature,
    };

    const getVerifyRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.verifyBudgetCallId = getVerifyRequestMsg.messageId;


    getVerifyRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.budgetPayVerify,
    );

    getVerifyRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getVerifyRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    getVerifyRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(getVerifyRequestMsg.id, getVerifyRequestMsg);
  }

  capturePayment = (accountCourseId: any) => {
    //api call
    const token = localStorage.getItem("loginT");

    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    const httpBody = {
      pay_hire_teacher_id: accountCourseId
    };


    const getCaptureRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.captureBudgetCallId = getCaptureRequestMsg.messageId;


    getCaptureRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.budgetPayCapture,
    );

    getCaptureRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCaptureRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    getCaptureRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(getCaptureRequestMsg.id, getCaptureRequestMsg);
  }

  handleSucessOpen = () => {
    this.setState({ openPaymentModal: true })
  };

  handleSucessClose = () => {
    this.setState({ openPaymentModal: false })
  };

  paymentBudgetFailure = async (value: any) => {
    this.setState({
      paymentFailureModal: value,
    })
  }

  handleClickOnContinue=()=>{
    this.handleSucessClose()
    this.props.navigation.navigate("TeacherClasses")
  }


   // Customizable Area End



}
// Customizable Area End
  