import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  is_loggedIn: boolean;
  userDetails: any;
  compleatedCourses: any;
  compleatedCoursesCount: number;
  scheduledCourses: any;
  scheduledCoursesCount: number;
  isLoading: boolean;
  currentScore: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class StudentDashboardController extends BlockComponent<Props, S, SS> {
  apiUserDetailsCallId: any;
  apiCompleatedCourseCallId: any;
  apiScheduledCourseCallId: any;
  apiCurrentScoreCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      is_loggedIn: false,
      userDetails: [],
      compleatedCourses: [],
      compleatedCoursesCount: 0,
      scheduledCourses: [],
      scheduledCoursesCount: 0,
      isLoading: true,
      currentScore: "0.0",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getUserDetails();
    this.getCompleatedCourse();
    this.getScheduledCourse();
    this.getCurrentScore();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  handleNavToProfile = () => {
    this.props.navigation.navigate("Profile");
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiUserDetailsCallId) {
          this.handleUserDetailsResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiCompleatedCourseCallId) {
          this.handleCompleatedCourseResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiScheduledCourseCallId) {
          this.handleScheduledCourseResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiCurrentScoreCallId) {
          this.handleCurrentScoreResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  handleUserDetailsResponse = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("UserDetails_Response", responseJson);
      await this.setState({ is_loggedIn: true });
      await this.setState({ userDetails: responseJson.data.attributes });
      console.log("is_loggedIn", this.state.is_loggedIn);
      console.log("userDetails", this.state.userDetails);
    } else {
      if (responseJson?.errors[0]?.token == "Token has Expired") {
        this.props.navigation.navigate("EmailLogin");
      }
      console.log("ERR_UserDetails_Response", responseJson);
      await this.setState({ is_loggedIn: false });
    }
  };

  handleCompleatedCourseResponse = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      if (responseJson.message == "No completed course found") {
        console.log("No_compleatedCourses_Response", responseJson);
        await this.setState({ compleatedCoursesCount: 0 });
        this.setState({ isLoading: false });
      } else {
        console.log("compleatedCourses_Response", responseJson);
        await this.setState({ compleatedCourses: responseJson.data });
        await this.setState({ compleatedCoursesCount: responseJson.count });
        this.setState({ isLoading: false });
      }
    } else {
      console.log("ERR_compleatedCourses_Response", responseJson);
      this.setState({ isLoading: false });
      await this.setState({ compleatedCoursesCount: 0 });
      alert(JSON.stringify(responseJson?.error));
    }
  };

  handleScheduledCourseResponse = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      if (responseJson.message == "No scheduled courses present") {
        console.log("No_scheduledCoursesCount_Response", responseJson);
        this.setState({ scheduledCoursesCount: 0 });
        this.setState({ isLoading: false });
      } else {
        console.log("scheduledCourses_Response", responseJson);
        this.setState({ scheduledCourses: responseJson.data.data });
        this.setState({ scheduledCoursesCount: responseJson.count });
        this.setState({ isLoading: false });
      }
    } else {
      console.log("ERR_scheduledCourses_Response", responseJson);
      this.setState({ scheduledCoursesCount: 0 });
      this.setState({ isLoading: false });
      alert(JSON.stringify(responseJson?.error));
    }
  };

  handleCurrentScoreResponse = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("currentscore", responseJson);
      if (responseJson.current_score) {
        this.setState({
          currentScore: responseJson.current_score,
        });
      }
    } else {
      console.log("ERR_currentscore_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  };

  // User Details API

  getUserDetails() {
    const token = localStorage.getItem("loginT");
    const headers = {
      token: token,
    };

    const getUserDetailsRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserDetailsCallId = getUserDetailsRequestMsg.messageId;

    getUserDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailsApiEndpoint
    );

    getUserDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getUserDetailsRequestMsg.id, getUserDetailsRequestMsg);
  }

  // Compleated course API

  getCompleatedCourse = async () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getCompleatCoureseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCompleatedCourseCallId = getCompleatCoureseRequestMsg.messageId;

    getCompleatCoureseRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completedCoursesApiEnd
    );

    getCompleatCoureseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCompleatCoureseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getCompleatCoureseRequestMsg.id, getCompleatCoureseRequestMsg);
  };

  // Scheduled course API

  getScheduledCourse = async () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      token: token,
    };

    const getSchesduleCourseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiScheduledCourseCallId = getSchesduleCourseRequestMsg.messageId;

    getSchesduleCourseRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduledCoursesApiEnd
    );

    getSchesduleCourseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSchesduleCourseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getSchesduleCourseRequestMsg.id, getSchesduleCourseRequestMsg);
  };

  getCurrentScore = async () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      token: token,
    };

    const getCurrentScoreRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCurrentScoreCallId = getCurrentScoreRequestMsg.messageId;

    getCurrentScoreRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentScoreEndpoint
    );

    getCurrentScoreRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCurrentScoreRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getCurrentScoreRequestMsg.id, getCurrentScoreRequestMsg);
  };
}
