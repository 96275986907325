import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import { Country, State, City } from "country-state-city";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  fName: string;
  lName: string;
  date: string;
  cemail: string;
  mobile: string;
  qualification: string;
  interest: string;
  address: string;
  city: string;
  country: string;
  state: string;
  pass: string;
  cPass: string;

  currentlyWorking: string;
  interstedCourse: string;


  mobileCode: string;
  errFName: boolean;
  errLName: boolean;
  errDate: boolean;
  errEmail: boolean;
  errPhone: boolean;
  errAddress: boolean;
  errCountry: boolean;
  errState: boolean;
  errPass: boolean;
  errCPass: boolean;
  errQualification: boolean;
  errCity: boolean;
  errMaxFName: boolean;
  errMaxLName: boolean;
  errMaxPhone: boolean;
  errMaxAddress: boolean;

  lCountry: Object;

  listCountry: any[];
  listState: any[];
  listCity: any[];

  sCountry: string;
  sCountryCode: string;
  sState: string;
  sStateCode: string;
  sCity: string;
  sCityCode: string;


  updateProfilePicModalStatus: any
  takePhotoModalStatus: any
  imageSrc: any
  previewConfirmModalStatus: any
  uploadPicFile: any
  previewUploadFile: any

  profileData: any
  qualifactionListData: any
  cousesInterestListData: any
  opensucessMessageModal: boolean
  isLoading: boolean


  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProfileController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  apiEmailWebLoginCallId: any;
  PostIDResest: any;
  PostIDResestpassword: any;
  errStyle: any;
  getProfileDetailsId: string = "";
  getQualificationsId: string = "";
  getCoursesInterestId: string = "";
  updateCallId: string = "";

  // Customizable Area End

  constructor(props: any) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]

    this.state = {
      fName: "",
      lName: "",
      date: "",
      cemail: "",
      mobile: "",
      qualification: "",
      interest: "",
      address: "",

      currentlyWorking: "",
      interstedCourse: "",

      city: "",
      country: "",
      state: "",
      pass: "",
      cPass: "",
      mobileCode: "",
      errFName: false,
      errLName: false,
      errDate: false,
      errEmail: false,
      errPhone: false,
      errAddress: false,
      errCountry: false,
      errState: false,
      errPass: false,
      errCPass: false,
      errQualification: false,
      errCity: false,
      errMaxFName: false,
      errMaxLName: false,
      errMaxPhone: false,
      errMaxAddress: false,

      lCountry: {},
      listCountry: [],
      listState: [],
      listCity: [],

      sCountry: "",
      sCountryCode: "",
      sState: "",
      sStateCode: "",
      sCity: "",
      sCityCode: "",

      updateProfilePicModalStatus: false,
      takePhotoModalStatus: false,
      imageSrc: "",
      previewConfirmModalStatus: false,
      uploadPicFile: "",
      previewUploadFile: "",
      profileData: {},
      qualifactionListData: [],
      cousesInterestListData: [],
      opensucessMessageModal: false,
      isLoading: true
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }


  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const country_list = Country.getAllCountries();
    this.setState({ listCountry: country_list });

    // Customizable Area Start
    // Customizable Area End
    this.getQualificationList()
    this.getCoursesOfInterestList()
    this.getProfileDetails()
  }

  // Customizable Area Start

  async handleFNameChange(e: any) {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ fName: e.target.value });
    }


    if (this.state.fName.length > 15) {
      this.setState({ errMaxFName: true });

    } else {
      this.setState({ errMaxFName: false });
    }
  }
  async handleLNameChange(e: any) {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ lName: e.target.value });
    }
    if (this.state.lName.length > 15) {
      this.setState({ errMaxLName: true });

    } else {
      this.setState({ errMaxLName: false });
    }
  }
  async handleDateChange(e: any) {
    this.setState({ date: e.target.value });

    let select_date = new Date(this.state.date);

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (select_date > today) {
      this.setState({ errDate: true });

    } else {
      this.setState({ errDate: false });
    }
  }

  async handleEmailChange(e: any) {
    this.setState({ cemail: e.target.value });

    const VEmail = this.state.cemail.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    if (this.state.cemail.length <= 0 || VEmail == null) {
      this.setState({ errEmail: true });

    } else {
      this.setState({ errEmail: false });
    }
  }
  async handleMobileChange(e: any) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ mobile: e.target.value });
    }

    if (this.state.mobile.length > 15) {
      this.setState({ errMaxPhone: true });

    } else {
      this.setState({ errMaxPhone: false });
    }
  }
  async handleAddressChange(e: any) {
    this.setState({ address: e.target.value });
    if (this.state.address.length > 30) {
      this.setState({ errMaxAddress: true });

    } else {
      this.setState({ errMaxAddress: false });
    }
  }
  async handleCityChange(e: any) {
    e.persist();
    this.setState({ sCity: e.target.value });
    if (this.state.sCity.length <= 0 || this.state.sCity == "Select City") {
      this.setState({ errCity: true });
    } else {
      this.setState({ errCity: false });
    }
  }
  async handlePassChange(e: any) {
    this.setState({ pass: e.target.value });
    if (this.state.errCPass) {
      if (this.state.cPass == this.state.pass) {
        this.setState({ errCPass: false });
      } else {
        this.setState({ errCPass: true });
      }
    }
  }
  async handleCPassChange(e: any) {
    this.setState({ cPass: e.target.value });
    if (this.state.cPass == this.state.pass) {
      this.setState({ errCPass: false });
    } else {
      this.setState({ errCPass: true });
    }
  }
  async handleQualification(e: any) {
    this.setState({ qualification: e.target.value });
  }
  async handleInterestChange(e: any) {
    this.setState({ interest: e.target.value });
  }


  async handleCountryChange(e: any) {
    const value = e.target.value;

    if (value == "Select Country") {
      await this.setState({
        sCountry: "",
        sCountryCode: "",
      });
    } else {
      const filtered_country = this.state.listCountry[value];
      await this.setState({
        sCountry: filtered_country.name,
        sCountryCode: filtered_country.isoCode,
      });
    }

    const state_list = await State.getStatesOfCountry(this.state.sCountryCode);
    await this.setState({ listState: [] });
    await this.setState({ listState: state_list });
    await this.setState({ listCity: [] });

    if (
      this.state.sCountry.length <= 0 ||
      this.state.sCountry == "Select Country"
    ) {
      this.setState({ errCountry: true });

    } else {
      this.setState({ errCountry: false });
    }
  }

  async handleStateChange(e: any) {
    e.persist();

    const value = e.target.value;

    if (value == "Select State") {
      await  this.setState({
        sState: "",
        sStateCode: "",
      });
    } else {
      const filtered_State = this.state.listState[value];
      await this.setState({
        sState: filtered_State.name,
        sStateCode: filtered_State.isoCode,
      });
    }


    const city_list = City.getCitiesOfState(
      this.state.sCountryCode,
      this.state.sStateCode
    );
    await   this.setState({ listCity: [] });
    await  this.setState({ listCity: city_list });
    if (this.state.sState.length <= 0 || this.state.sState == "Select State") {
      await  this.setState({ errState: true });

    } else {
      await this.setState({ errState: false });
    }
  }
  async handleMobileCodeChange(e: any) {
    this.setState({ mobileCode: e.target.value });
  }
  async handlecurrentlyWorking(e: any) {
    this.setState({ currentlyWorking: e.target.value });
  }
  async handleinterstedCourse(e: any) {
    this.setState({ interstedCourse: e.target.value });
  }





  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (this.getProfileDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleProfileDetailsCallBack(apiResponse)
    }

    if (this.getQualificationsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleQualificationCallBack(apiResponse)
    }

    if (this.getCoursesInterestId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {

        alert(apiResponse?.errors[0].token)
      } else {

        this.setState({
          cousesInterestListData: apiResponse
        })
      }
    }

    if (this.updateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.error) {
        alert(apiResponse?.error)

      } else {
        this.setState({
          profileData: apiResponse.data
        })
        this.getProfileDetails()
        this.handleUploadProfileConfirmModal(false)
        this.handleUploadProfilePicture(false)
        this.handlesucessMessageModal(true)
      }
    }


    // Customizable Area End
  }


  handleProfileDetailsCallBack = async (apiResponse: any) => {
    if (apiResponse?.errors) {
      alert(apiResponse?.errors)
      if (apiResponse?.errors[0]?.token == "Token has Expired") {
        this.props.navigation.navigate("EmailLogin");
      }
    } else {
      this.setState({
        profileData: apiResponse.data,
        isLoading: false
      })
      const { first_name, last_name, phone_number, country_code, qualification, email } = await apiResponse?.data?.attributes
      const { date_of_birth, country, state, city, address } = await apiResponse?.data?.attributes?.academic?.data?.attributes?.account
      const { currently_working, interest } = await apiResponse?.data?.attributes?.academic?.data?.attributes

      const selectedCurrentlyWorking = currently_working ? "Yes" : "No"

      this.setState(prevState => ({
        ...prevState,
        fName: first_name,
        lName: last_name,
        mobile: phone_number,
        mobileCode: country_code,
        cemail: email,
        qualification: qualification,
        date: date_of_birth,
        sCountry: country,
        sState: state,
        sCity: city,
        address: address,
        currentlyWorking: selectedCurrentlyWorking,
        interstedCourse: interest.name
      }))


    }
  }

  handleQualificationCallBack = (apiResponse: any) => {
    if (apiResponse?.error) {
      alert(apiResponse?.error)
    } else {
      this.setState({
        qualifactionListData: apiResponse
      })
    }
  }


  handleUploadProfilePicture = (value: any) => {
    this.setState({
      updateProfilePicModalStatus: value
    })
  }

  handletakePhotoModal = (value: any) => {
    this.handleUploadProfilePicture(false)
    this.setState({
      takePhotoModalStatus: value
    })
  }

  handletakePhotoCapture = async (value: any) => {
    this.setState({
      uploadPicFile: "",
    });

    this.setState({
      imageSrc: value
    })
    this.handletakePhotoModal(false)
    this.handleUploadProfileConfirmModal(true)
    // this.getProfileDetails()
  }

  handleUploadProfileConfirmModal = (value: any, cancel?: any) => {
    this.setState({
      previewConfirmModalStatus: value
    })
    if (cancel)
      this.setState({
        uploadPicFile: "",
        previewUploadFile: ""
      });
  }


  handleUploadProfilePictureUpdate = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({
        uploadPicFile: event.target.files[0],
        previewUploadFile: URL.createObjectURL(event.target.files[0])
      });
      this.handleUploadProfileConfirmModal(true)
    }
    this.setState({
      imageSrc: ""
    })
  }

  handleUploadProfilePictureConfirmUpdate = async () => {
    //update the pic here
    const studentFormData = new FormData();

    studentFormData.append("profile_image", this.state.uploadPicFile);
    if (this.state.imageSrc) {
      function dataURLtoFile(dataurl: any, filename: any) {
        let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
      }
      let file = dataURLtoFile(this.state.imageSrc, 'hello.txt');

      studentFormData.append("profile_image", file);
    }
    const userLogintoken = localStorage.getItem("loginT");
    const headers = {
      token: userLogintoken,
    };

    const updateStudentProfilePicRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCallId = updateStudentProfilePicRequestMsg.messageId;


    updateStudentProfilePicRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndpoint
    );

    updateStudentProfilePicRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateStudentProfilePicRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    updateStudentProfilePicRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      studentFormData
    );

    runEngine.sendMessage(updateStudentProfilePicRequestMsg.id, updateStudentProfilePicRequestMsg);

  }

  getProfileDetails() {
    const token = localStorage.getItem("loginT");

    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token,
    };


    const getProfileDetailsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProfileDetailsId = getProfileDetailsMsg.messageId;


    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailsEndpoint
    );

    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getProfileDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getProfileDetailsMsg.id, getProfileDetailsMsg);

  }

  getQualificationList() {
    const getQualificationListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getQualificationsId = getQualificationListMsg.messageId;


    getQualificationListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qualificationsEndpoint
    );


    getQualificationListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );


    runEngine.sendMessage(getQualificationListMsg.id, getQualificationListMsg);


  }

  getCoursesOfInterestList() {

    const getCoursesOfInterestListMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCoursesInterestId = getCoursesOfInterestListMsg.messageId;

    getCoursesOfInterestListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interestsEndpoint
    );

    getCoursesOfInterestListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getCoursesOfInterestListMsg.id, getCoursesOfInterestListMsg);

  }

  handlesucessMessageModal = (value: any) => {
    this.setState({ opensucessMessageModal: value })
  }

  handleUpdateData = async () => {
    const id = this.state.profileData.attributes?.academic?.data?.attributes?.id
    //firstName validations
    if (this.state.fName.length <= 0) {
      this.setState({ errFName: true });
    } else {
      this.setState({ errFName: false });
    }

    if (this.state.fName.length > 15) {
      this.setState({ errMaxFName: true });

    } else {
      this.setState({ errMaxFName: false });
    }

    //lastname validations
    if (this.state.lName.length <= 0) {
      this.setState({ errLName: true });
    } else {
      this.setState({ errLName: false });
    }
    if (this.state.lName.length > 15) {
      this.setState({ errMaxLName: true });

    } else {
      this.setState({ errMaxLName: false });
    }

    //date  validations
    let select_date = new Date(this.state.date);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (select_date > today) {
      this.setState({ errDate: true });
    } else {
      this.setState({ errDate: false });
    }


    //email validations
    const VEmail = this.state.cemail.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (this.state.cemail.length <= 0 || VEmail == null) {
      this.setState({ errEmail: true });
    } else {
      this.setState({ errEmail: false });
    }


    //Mobile Validation
    if (this.state.mobile.length <= 0) {
      this.setState({ errPhone: true });
    } else {
      this.setState({ errPhone: false });
    }
    if (this.state.mobile.length > 15) {
      this.setState({ errMaxPhone: true });
    } else {
      this.setState({ errMaxPhone: false });
    }


    //address validation
    if (this.state.address.length <= 0) {
      this.setState({ errAddress: true });
    } else {
      this.setState({ errAddress: false });
    }
    if (this.state.address.length > 30) {
      this.setState({ errMaxAddress: true });

    } else {
      this.setState({ errMaxAddress: false });
    }

    //country
    if (this.state.sCountry.length <= 0) {
      this.setState({ errCountry: true });

    } else {
      this.setState({ errCountry: false });
    }

    if (this.state.sState.length <= 0) {
      this.setState({ errState: true });

    } else {
      this.setState({ errState: false })
    }


    if (this.state.sCity.length <= 0) {
      this.setState({ errCity: true });
    } else {
      this.setState({ errCity: false });
    }

    if (this.state.fName.length <= 0 || this.state.lName.length <= 0 || this.state.fName.length >= 15 ||
      this.state.lName.length > 15 || (select_date > today) || (this.state.cemail.length <= 0 || VEmail == null)
      || this.state.mobile.length <= 0 || this.state.mobile.length > 15 || this.state.address.length > 30 || this.state.address.length <= 0 ||
      this.state.sCountry.length <= 0 || this.state.sState.length <= 0 || this.state.sCity.length <= 0
    ) {
      return
    }

    //api call from here
    const studentFormData = new FormData();

    const selcectedqualifationObject = this.state.qualifactionListData.find((each: any) => {
      return each.name === this.state.qualification
    })
    const selcectedInterestedObject = this.state.cousesInterestListData.find((each: any) => {
      return each.name === this.state.interstedCourse
    })

    studentFormData.append("first_name", this.state.fName)
    studentFormData.append("last_name", this.state.lName);
    studentFormData.append("date_of_birth", this.state.date);
    studentFormData.append("address", this.state.address);



    if (this.state.cemail !== this.state.profileData?.attributes?.email) {
      studentFormData.append("email", this.state.interstedCourse);
    }
    if (this.state.mobileCode + this.state.mobile !== this.state.profileData?.attributes?.full_phone_number) {
      studentFormData.append("full_phone_number", `${this.state.mobileCode + this.state.mobile}`);
    }


    if (this.state.sCountry !== this.state.profileData?.attributes?.academic?.data?.attributes?.account.country) {
      studentFormData.append("country", this.state.sCountry);
    }
    if (this.state.sState !== this.state.profileData?.attributes?.academic?.data?.attributes?.account?.state) {
      studentFormData.append("state", this.state.sState);
    }
    if (this.state.sCity !== this.state.profileData?.attributes?.academic?.data?.attributes?.account?.city) {
      studentFormData.append("city", this.state.sCity);
    }

    studentFormData.append("[academic_attributes][id]", id);


    if (this.state.qualification !== this.state.profileData?.attributes?.qualification) {

      studentFormData.append("[academic_attributes][qualification_id]", selcectedqualifationObject.id);
    }



    if (this.state.interstedCourse !== this.state.profileData?.attributes?.academic?.data?.attributes?.interest?.name) {

      studentFormData.append("[academic_attributes][interest_id]", selcectedInterestedObject.id);
    }


    const cwcheck: any = this.state.currentlyWorking === "Yes" ? true : false

    if (this.state.currentlyWorking !== this.state.profileData?.attributes?.academic?.data?.attributes?.currently_working ? "Yes" : "No") {

      studentFormData.append("[academic_attributes][currently_working]", cwcheck);
    }

    const token = localStorage.getItem("loginT");
    const headers = {
      token: token,
    };



    const updateRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCallId = updateRequestMsg.messageId;


    updateRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndpoint
    );

    updateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    updateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      studentFormData
    );

    runEngine.sendMessage(updateRequestMsg.id, updateRequestMsg);

  }

}
