import React from "react";

const ModuleFilter = (moduleData: any[]) => {
  const array: any[] = [];
  console.log("ConsoleInSideComponenet", moduleData);

  const checkReaded = (val: any) => {
    console.log("valval", val);
    const readed = val.is_completed;
    if (readed === false || readed === null) {
      return val.id;
    }
  };

  const checkReadedAssess = (val: any) => {
    console.log("valval", val);
    const readed = val.is_completed;
    val.content_type = 'Assessment'
    console.log('valval', val)
    if (readed === false || readed === null) {
      return val.id;
    }
  };


  moduleData.forEach((val, _key) => {
    console.log("moduleDataMap", val.module_chapters);
    const idVideo = val.module_chapters.video_data.filter(checkReaded);
    array.push(...idVideo);
    const idReadabler = val.module_chapters.reading_data.filter(checkReaded);
    array.push(...idReadabler);
    const idAssess = val.assessment_data.filter(checkReadedAssess);
    array.push(...idAssess);

    console.log("idAfterVideo", idVideo);
    console.log("idAfterReadable", idReadabler);
    console.log("idAssess", idAssess);
    console.log("arrayAfterVideo", array);
  });
  if (array.length > 0) {
    return array;
  } else {
    return null;
  }
}
export default ModuleFilter