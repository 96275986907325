import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailCheckBox: boolean;
  phoneCheckBox: boolean;
  paymentDetails: any;
  sucessMessage: any;
  opensucessMessageModal: boolean
  paymentStatusLoader: boolean;
  notificationChange: boolean


  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  apiEmailWebLoginCallId: any;
  PostIDResest: any;
  PostIDResestpassword: any;
  errStyle: any;
  updateCallId: any;
  paymentDetailsCallId: any;
  notificationDetailsCallId: any;

  // Customizable Area End

  constructor(props: any) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]

    this.state = {

      emailCheckBox: true,
      phoneCheckBox: true,
      paymentDetails: [],
      sucessMessage: "",
      opensucessMessageModal: false,
      paymentStatusLoader: false,
      notificationChange: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }


  async componentDidMount() {

    // Customizable Area Start
    this.getPaymentDetails()
    this.getNotificationDetails()
    // Customizable Area End
  }

  // Customizable Area Start

  handleEmailCheckBox = () => {
    this.setState(prevState => ({
      emailCheckBox: !prevState.emailCheckBox,
      notificationChange: true
    }));
  }
  handlePhoneCheckBox = () => {
    this.setState(prevState => ({
      phoneCheckBox: !prevState.phoneCheckBox,
      notificationChange: true
    }));
  }

  handleUpdate = () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };


    const httpBody = {
      is_email_notification_enabled: this.state.emailCheckBox,
      is_sms_notification_enabled: this.state.phoneCheckBox
    };

    const updateRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCallId = updateRequestMessage.messageId;

    updateRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateNotificationAPIEndPoint
    );

    updateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    updateRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(updateRequestMessage.id, updateRequestMessage);
  }

  getPaymentDetails = () => {
    this.setState({ paymentStatusLoader: true })
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };

    const paymentDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentDetailsCallId = paymentDetailsRequestMessage.messageId;

    paymentDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPaymentDetailsEndPoint
    );

    paymentDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    paymentDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(paymentDetailsRequestMessage.id, paymentDetailsRequestMessage);
  }

  getNotificationDetails = () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: token
    };

    const notificationDetailsRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationDetailsCallId = notificationDetailsRequestMessage.messageId;

    notificationDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationAPIEndPoint
    );

    notificationDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    notificationDetailsRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(notificationDetailsRequestMessage.id, notificationDetailsRequestMessage);

  }

  handlesucessMessageModal = (value: any) => {
    this.setState({ opensucessMessageModal: value })

  }

  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start


    if (this.updateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.errors) {
        alert(apiResponse?.errors)
      } else {
        this.setState({
          sucessMessage: apiResponse?.message
        })
        this.handlesucessMessageModal(true)
      }
    }

    if (this.paymentDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handlePaymentDetailsCallBack(apiResponse)
    }

    if (this.notificationDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        alert(apiResponse?.errors)
      } else {
        this.setState({
          emailCheckBox: apiResponse.permissions.is_email_notification_enabled,
          phoneCheckBox: apiResponse.permissions.is_sms_notification_enabled
        })
      }
    }
    // Customizable Area End
  }

  handlePaymentDetailsCallBack = (apiResponse: any) => {
    if (apiResponse?.errors) {
      if (apiResponse?.errors[0]?.token == "Token has Expired") {
        this.props.navigation.navigate("EmailLogin");
      }
      alert(apiResponse?.errors)
      this.setState({ paymentStatusLoader: false })
    } else if (apiResponse?.message) {
      this.setState({ paymentStatusLoader: false })
    }
    else {
      this.setState({ paymentDetails: apiResponse?.data?.data })
      this.setState({ paymentStatusLoader: false })

    }
  }

}

// Customizable Area End

