
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start
import ModuleFilter from "../../email-account-login/src/Components/ModuleFilter";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    history: any;
    location: any;
    classes?: any;
}

export interface S {
    // Customizable Area Start
    assesmentSatus: any;
    courseModules: any;
    data: any;
    isLoading: boolean
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
export default class AssessResultController extends BlockComponent<Props, S, SS> {
    courseDetailsCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            // Customizable Area Start
            assesmentSatus: "",
            courseModules: [],
            data: [],
            isLoading: true
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.courseDetailsCallId) {
                    if (!responseJson.errors && !responseJson.error) {
                        console.log("PopularCourses_Response", responseJson);
                        console.log('Response for course detail', responseJson);
                        if (responseJson?.data?.attributes?.is_logged_in == false) {
                            this.props.navigation.navigate("CoursesDetails");
                            return;
                        }
                        this.setState({
                            data: responseJson.data,
                            isLoading: false,
                        });
                        this.setState({
                            courseModules: responseJson?.data?.attributes?.course_module,
                        });
                        console.log("ModuleOfCourse", this.state.courseModules);
                        ModuleFilter(this.state.courseModules);
                    }
                    this.parseApiCatchErrorResponse(errorReponse);
                }
            }
        }
        // Customizable Area End
    }

    async componentWillMount() {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }



    getAssesmentResults = () => {
        const AssesmentSatus = localStorage.getItem("assesmentStatus");
        this.setState({ assesmentSatus: AssesmentSatus });
    };
    handleBackToCourses = () => {
        const courseId = localStorage.getItem("currentCourseId");
        this.props.navigation.navigate("CourseDetails", { courseId: courseId });
    };


    handleContinueLearning = async () => {
        const nextChapterID = await ModuleFilter(this.state.courseModules);
        console.log("nextChapterID", nextChapterID);
        if (nextChapterID) {
            if (nextChapterID[0].content_type == "video" || nextChapterID[0].content_type == "pdf") {
                console.log('videopdf', this.state.data.id,
                    nextChapterID[0].id,)
                this.props.navigation.navigate("StartLearning", {
                    courseId: this.state.data.id,
                    chapterId: nextChapterID[0].id,
                });
            } else if (nextChapterID[0].content_type == "Assessment") {
                console.log('assessmentttt', nextChapterID[0].id)
                this.props.navigation.navigate("Assessment", {
                    assessmentId: nextChapterID[0].id,
                });
            }
        } else if (nextChapterID == null) {
            console.log('no more chapter')
            const courseId = localStorage.getItem("currentCourseId");
            this.props.navigation.navigate("CourseDetails", { courseId: courseId });
        }
    };
    getCoursesDetails() {
        const parms = localStorage.getItem("currentCourseId");
        console.log('currentCourseId', parms)
        const courseId = parms;
        const endPoint = `${configJSON.coursesDetailEndPoint}?id=${courseId}`;
        // console.log("getCoursesDetails")
        const token = localStorage.getItem("loginT");
        // console.log(token,"====token======")
        const headers = {
            "Content-Type": configJSON.coursesDetailContentType,
            token: token,
        };

        // const httpBody = {
        //   id:11
        // };

        const getCourseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.courseDetailsCallId = getCourseRequestMsg.messageId;

        getCourseRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

        getCourseRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        getCourseRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.coursesDetailMethodType
        );
        runEngine.sendMessage(getCourseRequestMsg.id, getCourseRequestMsg);
    }
    async componentDidMount() {
        console.log('didddddddd')
        this.getAssesmentResults();
        this.getCoursesDetails();
    }
}
// Customizable Area End
