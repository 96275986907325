import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import ModuleFilter from "./Components/ModuleFilter";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative?: any) => any;
    goBack: () => void;
  };
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  open: boolean;
  value: string;
  opensubmit: boolean;

  isExpanded: any;
  Expanded: any;
  data: any;
  razorPayOrderdetails: any;
  RazorPayy: any;
  razorpayPaymentId: any;
  razorpayOrderId: any;
  razorpaySignature: any;
  verifyCallData: any;
  paymentFailureModal: any;
  isLoading: boolean;
  courseModules: any;
  courseArray: any;
  courseCompleationData: any;
  expand: any;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CourseDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  apiEmailWebLoginCallId: any;
  PostIDResest: any;
  PostIDResestpassword: any;
  errStyle: any;
  courseDetailsCallId: string = "";
  courseCompleationDetailsCallId: string = "";
  paymentCallId: string = "";
  verifyCallId: string = "";
  captureCallId: string = "";
  // Customizable Area End

  constructor(props: any) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      open: false,
      value: "",
      opensubmit: false,

      isExpanded: false,
      Expanded: false,
      data: [],
      razorPayOrderdetails: {},
      RazorPayy: {},
      razorpayPaymentId: "",
      razorpayOrderId: "",
      razorpaySignature: "",
      verifyCallData: {},
      paymentFailureModal: false,
      isLoading: true,
      courseModules: [],
      courseArray: [],
      courseCompleationData: [],
      expand: false,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // let LoginSchema = Yup.object().shape({
  //   email: Yup.string()
  //     .trim()
  //     .email("The email address you entered isn't connected to an account")
  //     .required("Email is required"),

  //   password: Yup.string()
  //     .min(3, "Password must be 3 characters at minimum")
  //     .required("Password is required")
  //     .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}(\S*)$/,"Password must contain at least 8 characters, one lower, one uppercase, one number and one special case character")
  //   });

  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getCoursesDetails();
    this.getCourseCompleationDetails();
    await localStorage.setItem("currentCourseId", this.props.navigation.getParam("courseId"));
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (isExpanded: boolean, panel: string) => {
    this.setState({ Expanded: isExpanded ? panel : false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleRazorPay = (obj: any) => {
    this.setState({ RazorPayy: obj });
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    this.handleCourseDetailsCallBack(message);
    this.handleCourseCompletionCallBack(message);
    this.handlePaymentCallBack(message);
    this.handleVerifyCallBack(message);
    this.handleCaptureCallBack(message);

    // Customizable Area End
  }

  handleCourseDetailsCallBack = (message: any) => {
    if (this.courseDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      // console.log("recive file*****************")

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      // console.log(apiResponse,"???????????????????")
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiResponse?.errors) {
        // console.log('error for api===', apiResponse?.errors)
      } else {
        // console.log('Response for course detail', apiResponse.data.attributes);
        if (apiResponse?.data?.attributes?.is_logged_in == false) {
          this.props.navigation.navigate("CoursesDetails");
          return;
        }
        this.setState({
          data: apiResponse.data,
          isLoading: false,
        });
        this.setState({
          courseModules: responseJson?.data?.attributes?.course_module,
        });
        console.log("ModuleOfCourse", this.state.courseModules);
        ModuleFilter(this.state.courseModules);
      }
    }
  };
  handleCourseCompletionCallBack = (message: any) => {
    if (
      this.courseCompleationDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      // console.log("recive file*****************")

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      // console.log(apiResponse,"???????????????????")

      if (apiResponse?.errors) {
        // console.log('error for api===', apiResponse?.errors)
      } else {
        // console.log('Response for course detail', apiResponse.data.attributes);
        console.log("ressssss", apiResponse);
        this.setState({
          courseCompleationData: apiResponse,
        });
      }
    }
  };
  handlePaymentCallBack = (message: any) => {
    if (this.paymentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      // console.log("recive file*****************")

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //  console.log(apiResponse,"?????????Api respose of payment??????????")

      if (apiResponse?.errors) {
        console.log("error for api===", apiResponse?.errors);
      } else {
        //  console.log('Response for Api respose of payment when sucess', apiResponse);
        const razorPayOrderid = apiResponse.data.data.attributes.razorpay_order_id;
        //  console.log(apiResponse.data.data.attributes,"razorPayOrderdetails==_________")
        this.setState({
          razorPayOrderdetails: apiResponse.data.data.attributes,
        });
        if (razorPayOrderid) {
          this.handleRazorPayment(this.verifyPayment, this.paymentFailure);
        }
      }
    }
  };
  handleVerifyCallBack = async (message: any) => {
    if (this.verifyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      //  console.log("recived verifyCallId*****************")

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      //  console.log(apiResponse,"?????????verifyCallId??????????")
      if (apiResponse?.errors) {
        console.log("error for api verifyCallId===", apiResponse?.errors);
      } else {
        //  console.log('Response for verifyCallId', apiResponse);
        if (apiResponse.data.data.attributes.payment_status === "success") {
          this.capturePayment(apiResponse.data.data.attributes.id);
          this.setState({ verifyCallData: apiResponse?.data?.data });
          await this.handleOpen();
        }
      }
    }
  };
  handleCaptureCallBack = async (message: any) => {
    if (this.captureCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      // console.log("recived captureCallId*****************")

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      // console.log(apiResponse,"?????????captureCallId??????????")

      if (apiResponse?.errors) {
        console.log("error for api captureCallId===", apiResponse?.errors);
      } else {
        // console.log('Response for captureCallId', apiResponse);
        if (true) {
          this.getCoursesDetails();
        }
      }
    }
  };

  getCoursesDetails() {
    const parms = this.props.navigation.getParam("courseId");
    const courseId = parms;
    const endPoint = `${configJSON.coursesDetailEndPoint}?id=${courseId}`;
    // console.log("getCoursesDetails")
    const token = localStorage.getItem("loginT");
    // console.log(token,"====token======")
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    // const httpBody = {
    //   id:11
    // };

    const getCourseRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseDetailsCallId = getCourseRequestMsg.messageId;

    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    getCourseRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getCourseRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.coursesDetailMethodType
    );
    // getCourseRequestMsg.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    runEngine.sendMessage(getCourseRequestMsg.id, getCourseRequestMsg);
  }
  getCourseCompleationDetails() {
    const parms = this.props.navigation.getParam("courseId");
    const courseId = parms;
    const endPoint = `${configJSON.courseCompleationEndPoint}?id=${courseId}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };
    const getCourseCompleationRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseCompleationDetailsCallId = getCourseCompleationRequestMsg.messageId;

    getCourseCompleationRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    getCourseCompleationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCourseCompleationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.coursesDetailMethodType
    );

    runEngine.sendMessage(getCourseCompleationRequestMsg.id, getCourseCompleationRequestMsg);
  }

  handlePayNow = () => {
    const parms = this.props.navigation.getParam("courseId");
    const courseId = parms;
    // const endPoint = `${"account_courses"}?id=${18}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    const httpBody = {
      course_id: courseId,
    };

    const getPayRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.paymentCallId = getPayRequestMsg.messageId;

    getPayRequestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.buyCourseApiEnd);

    getPayRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getPayRequestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    getPayRequestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    runEngine.sendMessage(getPayRequestMsg.id, getPayRequestMsg);
  };

  handleRazorPayment = async (verifyPayment: any, paymentFailure: any) => {
    const { razorPayOrderdetails } = this.state;
    const { account, course } = razorPayOrderdetails;
    const options = {
      key: "rzp_test_CzInVsmPYOMFQA", // Enter the Key ID generated from the Dashboard
      amount: course.data.attributes.course_cost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Tech Fin",
      description: "",
      image: "",
      order_id: razorPayOrderdetails.razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function(response: any) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        verifyPayment(response);
        paymentFailure(false);
      },
      prefill: {
        name: account.data.attributes.first_name + account.data.attributes.last_name,
        email: account.data.attributes.email,
        contact: account.data.attributes.full_phone_number,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#ff0000",
      },
    };

    const rzp1 = new this.state.RazorPayy(options);

    rzp1.on("payment.failed", function(response: any) {
      // alert(response.error.code );
      // alert(response.error.description);
      // alert(response.error.source);
      paymentFailure(true);
      // alert(response.error.step + "say description");
      // alert(response.error.reason +"say description");
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  verifyPayment = async (response: any) => {
    // alert("verifyPayment")
    // console.log(response,"_+_+_+_+_+_+_+_+_+")
    await this.setState({
      razorpayPaymentId: response.razorpay_payment_id,
      razorpayOrderId: response.razorpay_order_id,
      razorpaySignature: response.razorpay_signature,
    });

    //api call
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    const httpBody = {
      razorpay_payment_id: this.state.razorpayPaymentId,
      razorpay_order_id: this.state.razorpayOrderId,
      razorpay_signature: this.state.razorpaySignature,
    };

    const getVerifyRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.verifyCallId = getVerifyRequestMsg.messageId;

    getVerifyRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyCourseApiEnd
    );

    getVerifyRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getVerifyRequestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    getVerifyRequestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    runEngine.sendMessage(getVerifyRequestMsg.id, getVerifyRequestMsg);
  };
  capturePayment = (accountCourseId: any) => {
    //api call
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.coursesDetailContentType,
      token: token,
    };

    const httpBody = {
      account_course_id: accountCourseId,
    };

    const getCaptureRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.captureCallId = getCaptureRequestMsg.messageId;

    getCaptureRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.captureCourseApiEnd
    );

    getCaptureRequestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getCaptureRequestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
    getCaptureRequestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

    runEngine.sendMessage(getCaptureRequestMsg.id, getCaptureRequestMsg);
  };

  paymentFailure = async (value: any) => {
    await this.setState({
      paymentFailureModal: value,
    });
  };

  handleNavToStartLearningScreen = async () => {
    const nextChapterID = await ModuleFilter(this.state.courseModules);
    console.log("nextChapterID", nextChapterID);
    if (nextChapterID) {
      if (nextChapterID[0].content_type == "video" || nextChapterID[0].content_type == "pdf") {
        this.props.navigation.navigate("StartLearning", {
          courseId: this.state.data.id,
          chapterId: nextChapterID[0].id,
        });
      } else if (nextChapterID[0].content_type == "Assessment") {
        this.props.navigation.navigate("Assessment", {
          assessmentId: nextChapterID[0].id,
        });
      }
    }
  };
  handleExpand = (isExpanded: boolean, panel: string) => {
    this.setState(isExpanded ? { expand: panel } : { expand: false });
  };

  videoText = (count: number): string => {
    return count > 1 ? "Videos" : "Video";
  };
  readingText = (count: number): string => {
    return count > 1 ? "Readings" : "Reading";
  };
  practiceExerciseText = (count: number): string => {
    return count > 1 ? "Practice Exercises" : "Practice Exercise";
  };
  getButtonText = (expand: any, moduleId: any): string => {
    return expand === moduleId ? "Hide Contents" : "View Contents";
  };
}
