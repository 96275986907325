import React from "react";
import AssessmentTestController, { Props } from "./AssessmentTestController.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { resultSuccess } from "./assets";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

const styles = (theme: Theme) =>
  createStyles({
    CA_MC_C_CATP: {
      marginLeft: "10em",
      marginRight: "10em",
      marginBottom: "10em",
    },
    CA_Hr_CATP: {
      borderBottom: "0.5px solid #e4e4e5",
      marginBottom: "2.5em",
      marginTop: "2.5em",
    },
    CA_Title_CATP: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginTop: "2em",
      // marginBottom: "1em",
    },
    CA_Question_label_CATP: {
      color: "#ff0000",
      fontSize: "1.85em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      marginBottom: "1em",
      marginTop: "1em",
    },
    CA_checkbox_C_CATP: {
      marginTop: "1em",
      background: "#f7f7f7",
      padding: "2em",
      borderRadius: "1em",
      minHeight: "12em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    CA_BT_C_CATP: {
      display: "flex",
      justifyContent: "center",
      marginTop: "3em",
    },
    CA_Q_count_btn2_CATP: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      background: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "2em",
      paddingRight: "2em",
      // width: "10em",
      "&:hover": {
        background: "#ff0000",
      },
    },
    CA_Img_Success_CATP: {
      width: "5em",
      marginTop: "2em",
    },
    sub_title_CATP: {
      color: "#14292D",
      fontSize: 22,
      fontWeight: 550,
      marginTop: "20px",
    },
    sub_content_CATP: {
      color: "#14292D",
      fontSize: 22,
      fontWeight: 550,
    },
  });

class CourseAssesmentTestPass extends AssessmentTestController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.CA_MC_C_CATP}>
          <p className={classes.CA_Title_CATP}>Course Name - React</p>
          <div className={classes.CA_Hr_CATP} />

          <p className={classes.sub_title_CATP}>{this.state.assesmentAttributes?.serial_number}, {this.state.assesmentAttributes.name}</p>

          <div>
            <div className={classes.CA_checkbox_C_CATP}>
              <img src={resultSuccess} alt="" className={classes.CA_Img_Success_CATP} />
              <Typography className={classes.CA_Question_label_CATP}>
                {this.state.assesmentSubmitMessage}
              </Typography>
              <Typography className={classes.sub_content_CATP}>
                Please click the below button to start the second exercise for this syllabus
              </Typography>
            </div>
          </div>
          <div className={classes.CA_Hr_CATP} style={{}} />
          <div className={classes.CA_BT_C_CATP}>
            <Button variant="contained" className={classes.CA_Q_count_btn2_CATP}>
              Attempt Next Exercise
            </Button>
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(CourseAssesmentTestPass);
