import React, { createRef, useRef, useState } from "react";
import Webcam from "react-webcam";
import {takePhotoWhite} from "./assets";
export const videoConstraints = {
  width: 480,
  height: 380,
  facingMode: "user"
};

export const WebcamCapture = ({handletakePhotoCapture}:any) => {
  const webcamRef = useRef<Webcam>(null);
  // const [imgSrc, setImgSrc] = useState<string | null | undefined>(null);


  const capture = React.useCallback(
    () => {
      // alert("called")
        const imageSrc = webcamRef?.current?.getScreenshot();
        console.log("in imageSrc",imageSrc)
        handletakePhotoCapture(imageSrc)
    },
    [webcamRef]
  );
  return (
    <>
       <>
        <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        style={{width:"55vw",height:"28vw"}}
      />
       <div style={{display:'flex', justifyContent:"center"}}>
         <div style={{borderRadius:"50px",backgroundColor:"#ff0000",position: "relative",width:"40px",height:"40px",marginTop:".2em"}}>
          <div style={{borderRadius:"50px",width:"25px",height:"22x"}}>
           <img src={takePhotoWhite} alt="camera" style={{width:"25px",height:"22px", transform: "translate(29%, 35%)"}} onClick={capture}/>
          </div>
         </div>
       </div>
    </>
  
    </>)
  ;
};
