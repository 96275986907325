Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.putMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.jsonApiContentType = "application/json";
exports.apiGetMethodType = "GET";
exports.apiPostMethodType = "POST";
exports.apiDeleteMethodType = "POST";
exports.webinarStartStreamAPILink = "webinars/${id}/live_streaming_token";
exports.webinarGetListAPILink = "webinars";
exports.webinarJoinStreamAPILink = "webinars/${id}/live_streaming_token_for_joinee";
exports.webinarGetStreamAPILink = "webinars/";
exports.webinarStopRecordingAPILink = "/webinars/${id}/recording";
exports.webinarAgoraId = "071d50d3d07a441881fd5582b87953e0";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Webinars";
exports.currentUserLabel = "You";
exports.userId = "259";
exports.hostId = "259";
exports.hostLabel = "Host";
exports.webinarId = "18";
exports.localUserKey = "local";
exports.labelBodyText = "Webinars Body";
exports.webinarLinkTitle = "Webinar link to join"; 
exports.webinarSharedLink = "https://www.website-url.com/webinars/"; 
exports.webinarSharedLinkTitle = "Share WebinarLink:"; 
exports.WrongWebinarLink = "This link is invalid"; 
exports.notedTitleWebinar = "Webinar List(Enter user Id and click the webinar below to join):";
exports.userIdInputTitle = "User Id:";
exports.joinButtonText = "Join";
exports.removeUserTitle = "Remove User";
exports.removeUserMessage = "Are you sure you want to remove this user from the channel?";
exports.stopRecordTitle = "Webinar Recording";
exports.stopRecordMessage = "The webinar has been recorded successfully. Do you want do download it?";
exports.stopRecordCancelBtn = "Cancel";
exports.confirmTitleButton = "Yes";
exports.stopRecordConfirmBtn = "Download";
exports.endCallLabel = 'End';

exports.deleteMethodType = "DELETE";  
exports.createWebinarMessage = "Create Webinar Success!"; 
exports.updateWebinarMessage = "Update Webinar Success!"; 
exports.validateInput = "Please enter all input"; 
exports.createWebinarTitle = "Create new Webinar"; 
exports.updateWebinarTitle = "Update Webinar"; 
exports.webinarName = "Webinar Name"; 
exports.webinarDescription = "Webinar Description"; 
exports.webinarDate = "Webinar Scheduled Date"; 
exports.webinarStartTime = "Webinar Scheduled Start Time"; 
exports.webinarEndTime = "Webinar Scheduled End Time"; 
exports.webinarStartWarning = "Webinar not started or has ended"; 
exports.webinarEndWarning = "Webinar has ended"; 
exports.saveTxtBtn = "Save"; 
exports.OkTextBtn = "Ok";
exports.webinarTokenForRemoveUser = "Basic ZDYxOWVhMjViOGYxNDRhMjhhMzc0Zjc2MWFmNDRjYzA6MThjYTUxZWQzZDY1NGY1ZGI5YzQ5OWEyNTI1M2NmMzI=";
exports.webinarRemoveUserAPILink = 'https://api.agora.io/dev/v1/kicking-rule';
exports.userRoleTeacher = "teacher";
exports.webinarsListLabel = 'Webinars List';
exports.removeUserDescription = 'Are you sure you want to remove this user from the channel?';
exports.yesBtnLabelText = 'Yes';
exports.noBtnLabelText = 'No';
exports.webinarRecordingTitle = 'Webinar Recording';
exports.webinarRecordingDescription = 'The webinar has been recorded successfully. Do you want do download it?';
exports.downloadBtnLabelText = 'Download';
exports.cancelBtnLabelText = 'Cancel';
exports.joineNameLabelText = 'Joinee Name';
exports.joineNamePlaceholderText = 'Joinee Name';
exports.sharelBtnLabelText = 'Share';
exports.ParticipantsLabelText = 'Participants';
exports.webinarRecordingLinkBtn = 'Clik here to copy the Download Link';
exports.noWebinarFound = 'No Webinars Found';
exports.deleteAPiMethod = 'Delete';
exports.nameLabel = "Name";
exports.dateLabel = "Date";
exports.startTimeLabel = "Start Time";
exports.endTImeLabel = "End Time";
exports.descriptionLabel = "Description";
exports.InvalidInputFiledsLabel= 'Please fill all the fileds'
exports.createButtonLabel = "Create"
exports.updateButtonLabel = "Update"
exports.webinarLinkInputPlaceholder = "Paste the webinar link here to join"
exports.webinarLinkLabel = "https://www.website-url.com/webinars/130/Test"


exports.btnExampleTitle = "CLICK ME";
exports.viewSubmissionListApiEndPoint = "view_submission_list";
exports.rejectSubmissionStatusApiEndPoint = "update_reject_status";
exports.acceptSubmissionStatusApiEndPoint = "update_accept_status";
exports.addContractInfoApiEndPoint = "add_contract_info";
exports.dataScienceText = "Data governance has always been important to minimizing risk but never more so than now. Today, organizations of all sizes want to collect, integrate,access, and share volumes of data from diversesources to drive data science,strategy development, process optimization, and daily operational decisions.Data is in motion as many organizations are moving data to cloud platforms. Democratization of data and analytics is increasing the number of data silos.Data exposure risks are growing - as are the risks that users are making decisions based on poor data quality, faulty knowledge about data assets, and incomplete views because data is too hard to find."
// Customizable Area End