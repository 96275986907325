import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  coursesList_CL: any;
  popularCourseList_CL: any;
  categoriesList_CL: any;
  totalPage_CL: number;
  currentPage_CL: number;
  selectCategory_CL: any;
  selectFilterBy_CL: any;
  selectFilterByPrice_CL: any;
  startDate_CL: any;
  endDate_CL: any;
  clearDateState_CL: boolean;
  is_loggedIn_CL: boolean;
  userDetails_CL: any;
  fetchCourseListLoading_CL: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class CourseList2Controller extends BlockComponent<Props, S, SS> {
  apiCoursesListCallId_CL: any;
  apiCategoriesCallId_CL: any;
  apiUserDetailsCallId_CL: any;
  public dateInput: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.dateInput = React.createRef();
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      coursesList_CL: [],
      popularCourseList_CL: [],
      categoriesList_CL: [],
      totalPage_CL: 1,
      currentPage_CL: 1,
      selectCategory_CL: null,
      selectFilterBy_CL: null,
      selectFilterByPrice_CL: null,
      startDate_CL: null,
      endDate_CL: null,
      clearDateState_CL: false,
      is_loggedIn_CL: false,
      userDetails_CL: [],
      fetchCourseListLoading_CL: true,
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  // Receive Function //////////////////////////////////////////////////////////

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId_CL = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId_CL && responseJson) {
        if (apiRequestCallId_CL === this.apiCoursesListCallId_CL) {
          this.CoursesListCallBack(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId_CL === this.apiCategoriesCallId_CL) {
          this.handleCategoriesCallBack(responseJson);
          this.parseApiErrorResponse(responseJson);
        } else if (apiRequestCallId_CL === this.apiUserDetailsCallId_CL) {
          this.handleUserDetailsCallBack(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  CoursesListCallBack = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error && !responseJson.message) {
      this.setState({ fetchCourseListLoading_CL: false });
      if (responseJson.data) {
        console.log("CoursesList_Response", responseJson);
        await this.setState({ coursesList_CL: responseJson.data });
        await this.setState({ currentPage_CL: responseJson.page });
        await this.setState({ totalPage_CL: responseJson.page_count });
        // await this.setState({ lQualification: responseJson });
        console.log("coursesList State", this.state.coursesList_CL);
      } else if (responseJson.message) {
        console.log("CoursesList_no_Course_available_Response", responseJson);
        await this.setState({ coursesList_CL: [] });
        await this.setState({ currentPage_CL: 1 });
        await this.setState({ totalPage_CL: 1 });
      } else {
        this.parseApiErrorResponse(responseJson);
        console.log("No_Response", responseJson);
      }
    } else {
      this.setState({ fetchCourseListLoading_CL: false });
      if (responseJson.message) {
        console.log("CoursesList_no_Course_available_Response", responseJson);
        localStorage.setItem("courses", JSON.stringify([]));
        await this.setState({ coursesList_CL: [] });
        await this.setState({ currentPage_CL: 1 });
        await this.setState({ totalPage_CL: 1 });
      }
      console.log("ERR_CoursesList_Response", responseJson);
      // alert(JSON.stringify(responseJson.errors[0][0]));
    }
  };

  handleCategoriesCallBack = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("Categories_Response", responseJson);
      await this.setState({ categoriesList_CL: responseJson.data });
      console.log("Categories List State", this.state.categoriesList_CL);
    } else {
      console.log("ERR_Categories_Response", responseJson);
      alert(JSON.stringify(responseJson.errors[0][0]));
    }
  };

  handleUserDetailsCallBack = async (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("UserDetails_Response", responseJson);
      await this.setState({ is_loggedIn_CL: true });
      await this.setState({ userDetails_CL: responseJson.data.attributes });
      console.log("is_loggedIn", this.state.is_loggedIn_CL);
    } else {
      if (responseJson?.errors[0]?.token == "Token has Expired") {
        this.props.navigation.navigate("EmailLogin");
      }
      console.log("ERR_UserDetails_Response", responseJson);
      // alert(JSON.stringify(responseJson.errors[0][0]));
      await this.setState({ is_loggedIn_CL: false });
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////

  getUserDetails = async () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserDetailsCallId_CL = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailsApiEndpoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  getCousesList = (page: number, numberOfPages: number, cat?: any, startDate?: any) => {
    this.setState({ fetchCourseListLoading_CL: true });
    let token = localStorage.getItem("loginT");
    console.log("Token CourseList", token);

    if (token === null) {
      token = "";
    }
    const StartDate_CL = this.state.startDate_CL == null ? "" : this.state.startDate_CL;
    const EndDate_CL = this.state.endDate_CL == null ? "" : this.state.endDate_CL;
    const FilterBy_CL = this.state.selectFilterBy_CL == null ? "" : this.state.selectFilterBy_CL;
    const selectFilterByPrice_CL =
      this.state.selectFilterByPrice_CL == null ? "" : this.state.selectFilterByPrice_CL;
    const body_CL = null;
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", this.state.selectCategory_CL, FilterBy_CL);
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", selectFilterByPrice_CL);
    const endPoint = `${configJSON.coursesListApiEndpoint}?per_page=${numberOfPages}&page=${page}&pricing=${selectFilterByPrice_CL}&filter=${FilterBy_CL}&start_date=${StartDate_CL}&end_date=${EndDate_CL}`;
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", endPoint);

    const form = new FormData();
    form.append("per_page", numberOfPages.toString());
    const header_CL = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const requestMessage_getCourse_CL = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCoursesListCallId_CL = requestMessage_getCourse_CL.messageId;

    requestMessage_getCourse_CL.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header_CL)
    );
    requestMessage_getCourse_CL.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    requestMessage_getCourse_CL.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    body_CL && requestMessage_getCourse_CL.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body_CL);
    runEngine.sendMessage(requestMessage_getCourse_CL.id, requestMessage_getCourse_CL);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  getCategories = () => {
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const requestMessage_getCat_CL = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCategoriesCallId_CL = requestMessage_getCat_CL.messageId;

    requestMessage_getCat_CL.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndpoint
    );

    requestMessage_getCat_CL.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage_getCat_CL.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage_getCat_CL.id, requestMessage_getCat_CL);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  async componentDidMount(): Promise<void> {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log("componentDidMount CourseList");
    const pageParms = this.props.navigation.getParam("page");
    console.log("paramssss", pageParms, this.props.navigation.getParam("page"));

    let filter_by = await localStorage.getItem("filter_by");
    let filter_by_price = await localStorage.getItem("filter_by_price");
    let filter_by_start_date = await localStorage.getItem("filter_by_start_date");
    let filter_by_end_date = await localStorage.getItem("filter_filter_by_end_dateby");

    await this.setState({
      selectFilterBy_CL: filter_by == "null" ? null : filter_by,
      selectFilterByPrice_CL: filter_by_price == "null" ? null : filter_by_price,
      startDate_CL: filter_by_start_date == "null" ? null : filter_by_start_date,
      endDate_CL: filter_by_end_date == "null" ? null : filter_by_end_date,
      currentPage_CL: pageParms,
    });
    await console.log(
      "filter",
      this.state.selectFilterBy_CL,
      this.state.selectFilterByPrice_CL,
      this.state.startDate_CL,
      this.state.endDate_CL,
      this.state.currentPage_CL
    );

    console.log(
      "filterType",
      typeof filter_by,
      typeof filter_by_price,
      typeof filter_by_start_date,
      typeof filter_by_end_date
    );
    localStorage.removeItem("filter_by");
    localStorage.removeItem("filter_by_price");
    localStorage.removeItem("filter_by_start_date");
    localStorage.removeItem("filter_filter_by_end_dateby");

    this.getUserDetails();
    this.getCategories();
    this.getCousesList(this.state.currentPage_CL, 9);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  handleFilterByChange = async (event: any) => {
    // await this.setState({ currentPage: 1 })
    console.log(event.target?.value);
    const val = event.target?.value;
    if (val != "select") {
      console.log("val", val);
      await this.setState({ selectFilterBy_CL: val });
      this.getCousesList(1, 9);
      this.props.navigation.navigate("CourseList", { page: 1 });
    } else if (val == "select") {
      await this.setState({ selectFilterBy_CL: null });
      this.getCousesList(1, 9);
      this.props.navigation.navigate("CourseList", { page: 1 });
    }
  };

  handleFilterByPriceChange = async (event: any) => {
    // await this.setState({ currentPage: 1 })
    console.log(event.target?.value);
    const val = event.target?.value;
    if (val != "select") {
      console.log("val", val);
      await this.setState({ selectFilterByPrice_CL: val });
      this.getCousesList(1, 9);
      this.props.navigation.navigate("CourseList", { page: 1 });
    } else if (val == "select") {
      await this.setState({ selectFilterByPrice_CL: null });
      this.getCousesList(1, 9);
      this.props.navigation.navigate("CourseList", { page: 1 });
    }
  };

  handleFilterByStartEndDateChange = async (startDate: any, endDate: any) => {
    await this.setState({ startDate_CL: startDate });
    await this.setState({ endDate_CL: endDate });
    console.log("fstartDate", startDate);
    console.log("fendDate", endDate);
    console.log("StartDate", this.state.startDate_CL);
    console.log("EndDate", this.state.endDate_CL);
    await this.getCousesList(1, 9);
    this.props.navigation.navigate("CourseList", { page: 1 });
  };

  navigateToCoursesDetails = (id: any) => {
    localStorage.setItem("currentCourseId", id);
    if (this.state.is_loggedIn_CL) {
      this.props.navigation.navigate("CourseDetails", { courseId: id });
    } else {
      this.props.navigation.navigate("CoursesDetails", { courseId: id });
    }
  };
}
