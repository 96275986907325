//@ts-nocheck
import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import { defaultProfilePic, Women, cardBoys, hours, calender } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';




import {  withStyles, Theme } from "@material-ui/core/styles";

export const styles:any = (theme: Theme) =>
  ({
    root:{},
    teacherAssessmentMainSec: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    teacherAssessmentLeftSec: {
      flex: 2,
    },
    teacherAssessmentRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 9,
    },
    teacherAssessmentTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },
    teacherAssessmentsLoadingStlye: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },
    onGoingAssesmentTitle:{
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      letterSpacing: "0.1px",
      marginBottom:"20px",
    },
  
    backgroundContainer:{ 
      background: "#ededed",
      padding: "20px",
      flex:9
    },
    descriptionText:{
      lineHeight: "1.3em",
      height: "2.6em",
      color: "#666666",
      fontSize: "14px",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      letterSpacing: "0.1px",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2  ,
      overflow: "hidden"
    },
    profilePictureImage: {
      width: "6em",
      height: "6em",
      marginTop: "2em",
      borderRadius: "10em",
      objectFit: "cover",
    },
    subjectText:{
      fontSize: "3em",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 1  ,
      overflow: "hidden"

    }

    
  });


// Customizable Area End

import TeachersAssessmentsController, { Props } from "./TeachersAssessmentsController.web";
import { Divider } from "react-native-elements";

import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import moment from "moment";
export class TeachersAssessments extends TeachersAssessmentsController {
  render() {
    const { classes } = this.props;
    
    return (
      // Customizable Area Start
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.teacherAssessmentMainSec}>
        <div className={classes.teacherAssessmentLeftSec}>
          <DashSidebar1 active="Assessments" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.teacherAssessmentRightSec}>
            <div>
            <Typography className={classes.teacherAssessmentTitle}>Assessments</Typography>
            </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>

            {this.state.isAssessmentPageLoading?
              <div className={classes.teacherAssessmentsLoadingStlye} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
              <>
              <div style={{display:"flex",}}>
              <div style={{width:"70%",marginRight:"3em"}}>
                <h5 className="onGoingAssesmentTitle" style={{marginBottom:"1.25em"}}>
                  Ongoing Assessments
                </h5>
                <div className={classes.backgroundContainer}>
                {this.state.onGoingClassesList?.length===0?
                <>
                <div style={{display:"flex",justifyContent:"center",}}>No Assessments Found</div>  
                </>:

                 <>
                  <Grid container spacing={3} style={{ display: "flex", flexDirection: "row" }}>

                  {this.state.onGoingClassesList?.slice(0,3).map((eachClass: any) => {
                          return (
                            <Grid item xs={4} key={eachClass.id}>
                            <Card>
                              <CardMedia>
                              <img style={{ 
                                  height: "auto", 
                                  width: "100%" 
                                }} 
                                src={cardBoys} alt="" />
                              </CardMedia>
                              <CardContent>
                              <Typography style={{ fontSize: 21, fontWeight: 700,  }}>
                               {eachClass?.attributes?.hire_teacher_request.data.attributes.subject}
                              </Typography>
                                <Typography variant="body2" component="p" className={classes.descriptionText}>
                                {eachClass?.attributes?.hire_teacher_request.data.attributes.description}
                                </Typography>
      
                                <div style={{marginTop: "13px",columnGap: 5,}}>
                                  <div style={{display: "flex"}}>
                                    <img style={{height: "16px",width: "16px"}} src={hours} alt="" />
                                    <Typography style={{fontWeight: 550,fontSize: "0.9em",lineHeight:"1.3em",height:"2em"}}>&nbsp;&nbsp;{eachClass?.attributes?.contract_status!=="Under Review"&&eachClass?.attributes?.contract_status?.data[0]?.attributes.class_slot?.replace(/\$/g, ' ')}</Typography>
                                  </div>
                                  <div style={{display: "flex",alignItems: "center"}}>
                                    <img style={{height: "16px",width: "16px"}} src={calender} alt="" />
                                    <Typography style={{fontWeight: 550,fontSize: 14}}>&nbsp;&nbsp;{eachClass?.attributes?.hire_teacher_request.data.attributes.start_date}</Typography>
                                  </div>
                                </div>
                              </CardContent>
      
                              <CardActions>
                                <div
                                  style={{display: "flex",columnGap: 20,marginBottom: 20,justifyContent: "center",}}
                                >
                                  <Button
                                    variant="contained"
                                    style={{background: "#f0d17c",
                                    textTransform: "none",
                                    borderRadius: 100,
                                    fontWeight: 550,
                                    width: 115,}}
                                    size="small"
                                    data-testId={`scheduleClassesviewDetails${eachClass.id}`}

                                    onClick={()=>{this.handleNavigationToSubjectDetails(eachClass)}}
                                  >
                                    View Details
                                  </Button>
                                </div>
                              </CardActions>
                            </Card>
                          </Grid>
                          );
                  })}
                  </Grid>
                  <div style={{ margin: "15px" }}>
                    <Button
                      variant="contained"
                      style={{background: "#f00",borderRadius: "30px",textTransform: "none",color: "#fff",}}
                      onClick={()=>{this.props.navigation.navigate("ViewOngoingSubjectDetials")}}
                    >
                      View All Assessments
                    </Button>
                  </div>
                 </>
                  }
                </div>
                <h5  className="AddedJobsTitle" style={{margin:"2em 0em 1em"}}>Past Assesssments</h5>
                <div className={classes.backgroundContainer} style={{margin:"2em 0em 1em"}} >
              {/* past assesment api is not integrated yet */}
                {this.state.onGoingClassesList?.length===0?
                <>
                <div style={{display:"flex",justifyContent:"center"}}>No Assesssments Found</div>  
                </>:
                <>   
                  <Grid
                    container
                    spacing={3}
                    style={{ display: "flex", flexDirection: "row", borderRadius: "10px" }}
                  >
                      {this.state.onGoingClassesList?.slice(0,3).map((eachJob: any) => {
                          return (
                            <Grid item xs={4} key={eachJob.id}>
                            <Card  style={{ 
                              borderRadius: "15px"
                            }}>
                              <CardMedia>
                                <img style={{ height: "auto", width: "100%" }} src={cardBoys} alt="" />
                              </CardMedia>
                              <CardContent>
                                <p className={classes.subjectText}>{eachJob?.attributes?.subject}</p>
                                <p style={{marginBottom:"2em",height: "1.6em",}}><span style={{ color: "#ff0000",marginRight:"0.2em",fontWeight: 800,}}>Submission:</span><span style={{fontSize:"1em"}}>Completed</span></p>    
                                <p className={classes.descriptionText}>{eachJob?.attributes?.description}</p>
                              </CardContent>
                              <CardActions>
                                <div
                                  style={{display: "flex",marginBottom: 20,justifyContent: "center",}}
                                >
                                  <Button variant="contained" style={{background: "#f0d17c",
                                      textTransform: "none",
                                      borderRadius: 100,
                                      fontWeight: 550,
                                      marginLeft:"0.3em",
                                      fontSize:"0.7em"
                                      }}
                                    onClick={() => this.handleNavigationJobDetails(eachJob)}
                                    data-testId={`teacherDashboardjobviewBtn${eachJob.id}`}
                                    
                                      size="small">
                                    View Details
                                  </Button>
                                </div>
                              </CardActions>
                          </Card>
                          </Grid>
                          );
                  })}
                    

                  </Grid>
                  <div style={{ margin: "15px" }}>
                    <Button
                      variant="contained"
                      style={{
                        background: "#f00",
                        borderRadius: "30px",
                        textTransform: "none",
                        color: "#fff",
                      }}
                      onClick={()=>{this.props.navigation.navigate("ViewOngoingSubjectDetials")}}
                    >
                      View All Assesments
                    </Button>
                  </div>
                  </>}
                </div>
              </div>

              <div>
                <div style={{ marginBottom: "20px",width:"15em",}}>
                  <div style={{ borderRadius:"15px 15px 0px 0px",background: "#f7f7f7" ,display:"flex",alignItems:"center",flexDirection:"column" ,padding:"0em 1em",}}>
                  {this.state.teacherProfileData.profile_image ? (
                        <img
                          src={this.state.teacherProfileData.profile_image}
                          alt=""
                          className={classes.profilePictureImage}
                        />
                      ) : (
                        <img src={defaultProfilePic} alt="" className={classes.profilePictureImage} />
                      )}
                    <Typography variant="h6">
                    {this.state.teacherProfileData.first_name + " " + this.state.teacherProfileData.last_name}
                    </Typography>
                    <Typography variant="subtitle2" style={{  color: "#9ea7a9" ,marginBottom:"1em"}}>
                    {this.state.teacherProfileData.first_name}
                    </Typography>
                  </div>
                  <div style={{ borderRadius:"0px 0px 15px 15px",background: "#f00", display:"flex",flexDirection:"column" ,padding:"0em 1em",}}>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff",marginTop:"1em", }}
                    >
                      Experience
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", marginBottom: "5px" }}
                    >
                      {this.state.teacherProfileData?.academic?.data?.attributes?.total_experience}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", justifyContent: "center" }}
                    >
                      Scheduled Classes
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", marginBottom: "5px", justifyContent: "center" }}
                    >
                      {this.state.scheduleClassesList?.length}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", justifyContent: "center" }}
                    >
                      Qualification
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", marginBottom: "5px", justifyContent: "center" }}
                    >
                     {this.state.teacherProfileData?.academic?.data.attributes.qualification?.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", justifyContent: "center" }}
                    >
                      Joined on
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ color: "#fff", marginBottom: "5px", justifyContent: "center" }}
                    >
                      {moment(this.state.teacherProfileData.created_at).format("DD-MM-YYYY")}
                    </Typography>
                    <Button
                      variant="contained"
                      style={{
                        background: "#f0d17c",
                        borderRadius: "30px",
                        textTransform: "none",
                        padding:"0.5em 1em",
                        marginTop:"1em",
                        marginBottom:"1em"
                      }}
                      onClick={()=>{this.props.navigation.navigate("TeachersProfile")}}
                    >
                      Update Profle
                    </Button>
                  </div>
                </div>
                <Card style={{ borderRadius: "15px" }}>
                  <img src={Women} style={{ height: "200px", width: "100%" }} />
                  <div style={{padding:"0em 1em "}}>
                    <Typography variant="h6" component="h6">
                      Start a Webinar
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ marginBottom: "5px", fontSize: "11px" }}
                    >
                      Lorem ipsum is simply dummy text of
                      <br />
                      the priniting
                    </Typography>
                  </div>
                  <div style={{ display:"flex",justifyContent: "center", marginBottom: "20px" }}>
                    <Button
                      variant="contained"
                      style={{
                        background: "#f00",
                        borderRadius: "30px",
                        color: "#fff",
                        textTransform: "none",
                        padding:"0.5em 1.5em"
                      }}
                    >
                     Schedule Now
                    </Button>
                  </div>
                </Card>
              </div>

              </div>
              </>
              } 
   
        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
     </>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(TeachersAssessments);

// Customizable Area End
