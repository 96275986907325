import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  addContract: boolean;
  contractSuccess: boolean;
  contractSubmitted: boolean;
  jobApplicationId: any;
  contract_status: any;
  timeFrom: any;
  timeTo: any;
  contractType: any;
  paymentCondition: any;
  distributionMethod: any;
  currencyType: any;
  contractTypeData: any[];
  paymentConditionData: any[];
  distributionMethodData: any[];
  currencyTypeData: any[];
  contractData: any;
  proposed_budget: number;
  contractDetails: any;
  getDetailsLoading: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddContractInfoController extends BlockComponent<Props, S, SS> {
  getContractDetailsCallId: any;
  contractTypeCallId: any;
  paymentConditionCallId: any;
  distributionMethodCallId: any;
  postContractDetailsCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      addContract: false,
      contractSuccess: false,
      jobApplicationId: null,
      contract_status: null,
      timeFrom: '10:00',
      timeTo: '11:00',
      contractType: '',
      paymentCondition: '',
      distributionMethod: '',
      currencyType: '',
      contractTypeData: [],
      paymentConditionData: [],
      distributionMethodData: [],
      currencyTypeData: [{ name: 'INR' }],
      contractSubmitted: false,
      contractData: {},
      contractDetails: {},
      proposed_budget: 0,
      getDetailsLoading: true
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getContractDetailsCallId) {
          this.handleGetContractResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.contractTypeCallId) {
          this.handleContractTypeResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.paymentConditionCallId) {
          this.handlePaymentConditionResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.distributionMethodCallId) {
          this.handleDistributionMethodResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.postContractDetailsCallId) {
          this.handlePostContractDetailsResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }


  handleGetContractResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("getContractDetails_Response", responseJson);
      console.log("contract_statuss_Response", responseJson?.data?.attributes?.contract_status);
      this.setState({
        contractDetails: responseJson.data,
        getDetailsLoading: false
      })
      if (responseJson?.data?.attributes?.contract_status == "Under Review") {
        this.setState({
          contract_status: 'Under Review',
          contractSubmitted: false
        })
      } else if (Object.keys(responseJson?.data?.attributes?.contract_status)?.length > 0) {
        this.setState({
          contractSubmitted: true,

          contractData: responseJson?.data?.attributes?.contract_status,
          contract_status: 'Accepted',
          addContract: true,
        })
        this.handleContractTypeChange(responseJson?.data?.attributes?.contract_status.data[0].attributes.contract_type)
      }
      this.setState({
        proposed_budget: responseJson.data.attributes.hire_teacher_request.data.attributes.proposed_budget
      })
    } else {
      if (responseJson?.errors[0]?.token == "Token has Expired") {
        alert(JSON.stringify(responseJson?.errors[0]?.token));

      }
      console.log("ERR_getContractDetails_Response", responseJson);
      alert(JSON.stringify(responseJson?.errors[0].token));
      this.setState({
        getDetailsLoading: false
      })
    }

  }


  handleContractTypeResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("contractType_Response", responseJson);
      this.setState({
        contractTypeData: responseJson
      })
    } else {
      console.log("ERR_contractType_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  }


  handlePaymentConditionResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("paymentCondition_Response", responseJson);
      this.setState({
        paymentConditionData: responseJson
      })
    } else {
      console.log("ERR_paymentCondition_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  }
  handleDistributionMethodResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("distributionMethod_Response", responseJson);
      this.setState({
        distributionMethodData: responseJson
      })
    } else {
      console.log("ERR_distributionMethod_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  }
  handlePostContractDetailsResponse = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      console.log("postContractDetails_Response", responseJson);
      this.setState({
        contractSuccess: true
      })

    } else {
      console.log("ERR_postContractDetails_Response", responseJson);
      alert(JSON.stringify(responseJson?.error));
    }
  }

  async componentDidMount() {
    const parmsJobApplication = this.props.navigation.getParam("jobApplication");
    await this.setState({ jobApplicationId: parmsJobApplication });
    this.getContractDetails(parmsJobApplication)
    this.getContractType()
    this.getPaymentCondition()
    this.getDistributionMethod()
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  startTime = (val: any) => {
    console.log("startssssssssssssssssss", val);
  };
  endTime = (val: any) => {
    console.log("Enddddddddddddddddddddddddddd", val);
  };

  handleAddContract = () => {
    this.setState({ addContract: true });
  };

  getContractDetails = (id: any) => {
    console.log("getContractDetails");
    const endPoint = `${configJSON.viewContractEndpoint}?application_id=${id}`;
    const token = localStorage.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const getContractDetailsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getContractDetailsCallId = getContractDetailsMsg.messageId;

    getContractDetailsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    getContractDetailsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getContractDetailsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getContractDetailsMsg.id, getContractDetailsMsg);
  };

  handleContractTypeChange = (val: any) => {
    this.setState({
      contractType: val
    })
  }

  handlePaymentConditionChange = (val: any) => {
    this.setState({
      paymentCondition: val
    })
  }
  handleDistributionChange = (val: any) => {
    this.setState({
      contractType: val
    })
  }
  handlecurrencyTypeChange = (val: any) => {
    this.setState({
      currencyType: val
    })
  }


  getContractType = () => {
    console.log("getContractType");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const getContractTypeMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contractTypeCallId = getContractTypeMsg.messageId;
    getContractTypeMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.contractTypeEndpoint);
    getContractTypeMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getContractTypeMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getContractTypeMsg.id, getContractTypeMsg);
  }
  getPaymentCondition = () => {
    console.log("getPaymentCondition");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const getPaymentConditionMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.paymentConditionCallId = getPaymentConditionMsg.messageId;
    getPaymentConditionMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.paymentConditionEndpoint);
    getPaymentConditionMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getPaymentConditionMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getPaymentConditionMsg.id, getPaymentConditionMsg);
  }
  getDistributionMethod = () => {
    console.log("getDistributionMethod");
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const getDistributionMethodMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.distributionMethodCallId = getDistributionMethodMsg.messageId;
    getDistributionMethodMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.distributionMethodEndpoint);
    getDistributionMethodMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getDistributionMethodMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getDistributionMethodMsg.id, getDistributionMethodMsg);
  }



  handleSendContractInfo = (
    numOfPayments: number,
    totClasses: number,
    contrType: string,
    paymCondition: string,
    distrMethod: string,
    currType: string,
    timeslot: string[],
  ) => {
    console.log(numOfPayments,
      totClasses,
      contrType,
      paymCondition,
      distrMethod,
      currType,
      timeslot[0],
      timeslot[1]
    )



    let token = localStorage?.getItem("loginT");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const attributes = {
      // "contract_cp[]": paymentAmount.map(String).join(','),
      job_application_id: this.state.jobApplicationId,
      class_from: timeslot[0],
      class_to: timeslot[1],
      contract_type: contrType,
      distribution_method: distrMethod,
      payment_condition: paymCondition,
      total_classes: totClasses,
      no_of_payments: numOfPayments,
      currency: currType
    }


    const addContractInfoMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postContractDetailsCallId = addContractInfoMsg.messageId;


    addContractInfoMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addContractInfoEndpoint
    );

    addContractInfoMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addContractInfoMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethod
    );
    addContractInfoMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attributes)
    );
    runEngine.sendMessage(addContractInfoMsg.id, addContractInfoMsg);
  };
}
