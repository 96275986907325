import React, { Component } from "react";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import {
  StyleSheet,
  Text,
  View,
  Button,
  ScrollView,
  Platform,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native";
import { imgTop } from "./assets";
import Scale from "../../../components/src/Scale";

interface Props {
  navigation: any;
  id: string;
}

interface S {
  message: string;
  title: string;
  buttonText: string;
  buttonMessage: Message;
}

interface SS {
  id: any;
}

export default class InfoPageBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: "An error has occured.",
      buttonText: "Ok",
      buttonMessage: new Message(""),
      title: "ERROR",
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <ScrollView style={styles.container}>
        <View>
          <View style={styles.imgTopView}>
            <Image source={imgTop} style={styles.imgTopView} />
            <Text testID="txtLogo" style={styles.labelTitle}>
              Hello!
            </Text>
            <Text testID="txtLogo" style={styles.maintext}>
              Welcome to TECHFIN a platform{"\n"}where you can learn & grow
            </Text>
          </View>

          <View style={styles.btnStyleMobileView}>
            <TouchableOpacity
              style={styles.btnStyleMobilelogin}
              onPress={() => navigation.navigate("EmailAccountLoginBlock")}
            >
              <Text style={styles.btnStyleMobiletxtlogin}>Login</Text>
            </TouchableOpacity>
            <TouchableOpacity 
            style={styles.btnStyleMobilesignup}
            onPress={() => navigation.navigate("CfDedicatedRegistrationForm")}
            >
              <Text style={styles.btnStyleMobiletxtsigup}>Sign Up</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    );
  }

  async receive(from: String, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      var content = message.getData(getName(MessageEnum.InfoPageBodyMessage));

      var buttonText = message.getData(
        getName(MessageEnum.InfoPageButtonTextMessage)
      );

      var buttonMessage = message.getData(
        getName(MessageEnum.InfoPageNavigationScreenMessage)
      );

      this.setState({
        message: content,
        title: title,
        buttonMessage: buttonMessage,
        buttonText: buttonText,
      });
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    padding: 16,
    backgroundColor: "#fff",
  },
  imgTopView: {
    flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
    // marginTop: Scale(50),
    alignSelf: "center",
    resizeMode: "contain",
    height: Scale(500),
    width: Dimensions.get("screen").width / Scale(1.2),
  },
  labelTitle: {
    marginTop: 20,
    // marginBottom: 32,
    fontSize: 28,
    color: "#000000",
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold",
  },

  maintext: {
    fontSize: 18,
    color: "#000000",
    textAlign: "left",
    marginVertical: 8,
  },

  btnStyleWebContainer: { justifyContent: "flex-end", flexDirection: "row" },
  btnStyleMobileContainer: {},

  btnStyleWeb: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "#6200EE",
  },
  btnStyleMobilelogin: {
    backgroundColor: "#F0D27C",
    width: Dimensions.get("screen").width / Scale(3),
    height: Scale(50),
    borderRadius: Scale(50),
    margin: Scale(10),
  },
  btnStyleMobilesignup: {
    backgroundColor: "#FF0000",
    width: Dimensions.get("screen").width / Scale(3),
    height: Scale(50),
    borderRadius: Scale(50),
    margin: Scale(10),
  },
  btnStyleMobiletxtlogin: {
    fontSize: 18,
    color: "#000000",
    textAlign: "center",
    marginVertical: 8,
    fontWeight: "500",
  },

  btnStyleMobiletxtsigup: {
    fontSize: 18,
    color: "#ffffff",
    textAlign: "center",
    marginVertical: 8,
    fontWeight: "500",
  },

  btnStyleMobileView: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: Scale(50),
  },
});
