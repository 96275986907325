// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  scheduleClassesList:any
  addedJobsList:any
  recentlyAddedJobs:any
  isPageLoading:any

  teacherProfileDetails: any;
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class TeacherDashboardController extends BlockComponent<Props, S, SS> {
  scheduleClassesCallId: any;
  addedJobsCallId: any;
  teacherProfileCallId:any
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      // Customizable Area Start
      scheduleClassesList:[],
      addedJobsList:[],
      recentlyAddedJobs:{},
      isPageLoading:false,
      teacherProfileDetails: {}

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getteacherProfileDetails()
    this.getScheduleClasses()
    this.getAddedJobsList()
  
    // Customizable Area End
  }




  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForScheduleClasses(from,message)
    this.handleResponseForAddedJobs(from,message)
    this.handleTeacherProfileDetailsResponse(from,message)
   
    

    // Customizable Area End
  }

  handleTeacherProfileDetailsResponse =  (from: string, message: Message) => {
    if (this.teacherProfileCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
            teacherProfileDetails:apiResponse.data.attributes,
        })
      }
  }


  
  };





  handleResponseForScheduleClasses=(from: string, message: Message)=>{
    if (this.scheduleClassesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
           scheduleClassesList:apiResponse.data,
          //  isPageLoading:false
        })
      }
  }

  }
  handleResponseForAddedJobs=(from: string, message: Message)=>{
    if (this.addedJobsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiResponse?.error) {
        alert(apiResponse?.error)
      } else {
        this.setState({
          addedJobsList:apiResponse.data,
          isPageLoading:false
        })
      }
  }

  }



  getteacherProfileDetails() {
    this.setState({
      isPageLoading: true,
    })
    const token = localStorage.getItem("loginT");
    const headers = {
      token: token,
    };

    const getteacherProfileDetailsRqstMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.teacherProfileCallId = getteacherProfileDetailsRqstMsg.messageId;

    getteacherProfileDetailsRqstMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailsEndPoint
    );

    getteacherProfileDetailsRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getteacherProfileDetailsRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getteacherProfileDetailsRqstMsg.id, getteacherProfileDetailsRqstMsg);
  }




 

  getScheduleClasses=()=>{
    this.setState({
      isPageLoading: true,
    })
    const token = localStorage.getItem("loginT");
    const endPoint = `my_classes?&per_page=${9}&page=${1}`;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getscheduleClassesRqstMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.scheduleClassesCallId = getscheduleClassesRqstMsg.messageId;


    getscheduleClassesRqstMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getscheduleClassesRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getscheduleClassesRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getscheduleClassesRqstMsg.id, getscheduleClassesRqstMsg);

  }

  getAddedJobsList=()=>{
    this.setState({
      isPageLoading: true,
    })
    const token = localStorage.getItem("loginT");
    const endPoint = `get_all_jobs?per_page=${6}&page=${1}`;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getAddedJobsRqstMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.addedJobsCallId = getAddedJobsRqstMsg.messageId;


    getAddedJobsRqstMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAddedJobsRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAddedJobsRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getAddedJobsRqstMsg.id, getAddedJobsRqstMsg);

  }



  handleNavigationToSubjectDetails = (each:any) => {
    localStorage.setItem("SubjectDetailsId",each?.id);
    this.props.navigation.navigate("SubjectDetails");
  };


  handleNavigationJobDetails=(eachJOb:any)=>{
    localStorage.setItem("jobdetialId",eachJOb?.id);
    this.props.navigation.navigate("JobDetails")
    
  }







}

// Customizable Area End
