export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const back = require("../assets/back.png");
export const next = require("../assets/next.png");
export const TClassesImg1 = require("../assets/TClassesImg1.png");
export const TClassesImg2 = require("../assets/TClassesImg2.png");
export const TClassesImg3 = require("../assets/TClassesImg3.png");
export const TClassesImgM = require("../assets/TClassesImgM.png");
export const studentDp = require("../assets/studentDp.png");
export const checkedTeach = require("../assets/checkedTeach.png");
export const unCheckedTeach = require("../assets/unCheckedTeach.png");
export const bin = require("../assets/bin.png");
export const fileUploadIcon = require("../assets/fileUploadIcon.png");
export const SuccessIcon = require("../assets/SuccessIcon.png");
export const resultSuccess = require("../assets/resultSuccess.png");
export const resultFail = require("../assets/resultFail.png");
export const backArrow = require("../assets/backArrow.png");
export const uncheck = require("../assets/uncheck.png");
export const check = require("../assets/check.png");
export const addCircle = require("../assets/addCircle.png");
export const clock = require("../assets/clock.png");
