//@ts-nocheck
import React from "react";
import TeachersClassesController, { Props } from "./TeachersClassesController.web";
import {  withStyles, Theme } from "@material-ui/core/styles";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import {
  cardBoys,
  desktop,
  screenView,
  timeIcon,
} from "./assets";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { Divider } from "react-native-elements";
import PaginatedItems from "../src/Components/ReactPaginate/ReactPaginate.web"
import CircularProgress from '@material-ui/core/CircularProgress';


export const styles:any = (theme: Theme) =>
  ({
    appliedJobsMainSection: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    appliedJobsleftsec: {
      flex: 2,
    },
    appliedJobsRightSec: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    CardContainer: {
      width: "100%",
      columnGap: "2rem",
      rowGap: "2rem",
      marginBottom: "1.5em",
    },
    scheduledCardImages: {
      width: "100%",
      height: "12.4vw",
      objectFit: "cover",
    },
    teacherscourseTitle: {
      color: " #333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "24px",
      fontWeight: "bold",
      letterSpacing: "0.1px",
    },
    teacherscourseContent: {
      lineHeight: "1.3em",
      height: "2.6em",
      color: "#666666",
      fontSize: "14px",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      letterSpacing: "0.1px",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      overflow: "hidden"
    },
    teacherstypeOfClass: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.725em",
      fontWeight: 800,
      marginLeft: "0.825em",
    },
    teacherstimeOfTheClass: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.725em",
      fontWeight: 800,
      marginLeft: "0.825em",
    },
    teacherstypeofView: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.725em",
      fontWeight: 800,
      marginLeft: "0.825em",
    },
    viewDetailsButton:{
        background: "#f0d17c",
        textTransform: "none",
        padding:"0.5em 3em",
        borderRadius:"25px",
        fontWeight:700,
        fontFamily: "Inter, sans-serif",
    },
    myClassesTitle:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontSize: "2em",
      fontWeight: 600,
      letterSpacing: "0.125px"
    
    },
    myClasseLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },

  });
export class TeachersClasses extends TeachersClassesController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    
    console.log(this.state.teacherClassesList,"teacherClassesListteacherClassesListteacherClassesList")

    return (
      <>
      <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      <div className={classes.appliedJobsMainSection}>
        <div className={classes.appliedJobsleftsec}>
          <DashSidebar1 active="MyClasses" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
          <DashSidebar2
            timings="10:00 AM - 11:00 PM"
            title="Introduction to foundation of digital marketing and e-commerce"
            // subtitle=" Lorem Ipsum is simply dummy text of the printing"
            // onClick={() => { console.log("Next Class") }}
            styles={{ marginBottom: '1.5vw' }}
          />
          <DashSidebar3
            title="One on One classes with Students"
            subtitle="Lorem Ipsum is simply dummy text of the printing"
            styles={{ marginBottom: '1.5vw' }}
          />
        </div>
        <div className={classes.appliedJobsRightSec}>
        <div>
            <Typography className={classes.myClassesTitle}>My Classes</Typography>
            </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>

            {this.state.isPageLoading ?
              <div className={classes.myClasseLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
              this.state.teacherClassesList?.length===0?
              <div style={{display:"flex",justifyContent:"center",}}>No Classes Found</div>:  
           <>
            <Grid
                container
                spacing={3}
                style={{ display: "flex", flexDirection: "row", marginTop: "1.5em", marginBottom: "2em" }}
              >
                   {
              this.state.teacherClassesList?.map((eachClass:any,index:any)=>{
                const {attributes} = eachClass
                return ( 
                <React.Fragment key={eachClass.id}>
                <Grid item xs={4}>
                  <Card className={classes.RelatedCourseCard}>
                    <CardMedia>
                      <img className={classes.scheduledCardImages} src={cardBoys} alt="" />
                    </CardMedia>
                    <CardContent>
                      <Typography className={classes.teacherscourseTitle}>{attributes.hire_teacher_request.data.attributes.subject}</Typography>
                      <Typography className={classes.teacherscourseContent}>
                      {attributes.hire_teacher_request.data.attributes.description}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "13px",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img style={{ height: "16px", width: "16px" }} src={desktop} alt="classViewIcon" />
                          <Typography className={classes.teacherstypeOfClass}>One on One</Typography>
                        </div>
                        <div style={{ display: "flex" }}>
                          <img
                            style={{ height: "16px", width: "16px", marginLeft: "1em" }}
                            src={timeIcon}
                            alt="screenViewIcon"
                          />
                          <Typography className={classes.teacherstimeOfTheClass}>{attributes?.contract_status?.data[0].attributes?.class_slot?.replace(/\$/g, ' ')}</Typography>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "1em" }}>
                        <img style={{ height: "16px", width: "16px" }} src={screenView} alt="" />
                        <Typography className={classes.teacherstypeofView}>{attributes.hire_teacher_request.data.attributes.account.data.attributes.first_name +" "+attributes.hire_teacher_request.data.attributes.account.data.attributes.last_name}</Typography>
                      </div>
                    </CardContent>
                    <CardActions>
                      <div
                        style={{
                          display: "flex",
                          columnGap: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Button
                          data-testId={`TeachersMyClassesViewDetails${eachClass.id}`}
                          variant="contained"
                         className={classes.viewDetailsButton}
                         onClick={()=>{this.handleNavToSubjectDetails(eachClass)}}
                          size="small"
                        >
                          View Details
                        </Button>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>

                  </React.Fragment>
                )
              })               
          }


             
              
            </Grid>
              <div style={{margin:"2em 0em"}}>
              <PaginatedItems pageCount={this.state.myClassesPageCount} handlePagination={this.handleTeachersMyclassesPagination}/>
              </div>
            </>
        } 
     

        </div>
      
      </div>
      <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
  </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(TeachersClasses);
