export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const thirdcard = require("../assets/thirdcard.png");
export const redsidearrow = require("../assets/redsidearrow.png");
export const sidearrow = require("../assets/sidearrow.png");
export const message1 = require("../assets/message1.png");
export const message2 = require("../assets/message2.png");
export const message3 = require("../assets/message3.png");
export const message4 = require("../assets/message4.png");
export const message5 = require("../assets/message5.png");
export const message6 = require("../assets/message6.png");
export const chatprofile = require("../assets/chatprofile.png");
export const updatepicture = require("../assets/updatepicture.png");
export const dropdown = require("../assets/dropdown.png");

export const fileUploadIcon = require("../assets/fileUploadIcon.png");
export const takePhotoRed = require("../assets/takePhotoRed.png");
export const  takePhotoWhite = require("../assets/takePhotoWhite.png");
export const UploadPhotoRed = require("../assets/UploadPhotRed.png");
export const crossImage = require("../assets/crossImage.png");
// export const InProgress = require("../assets/InProgress.png");
export const CrossImg = require("../assets/CrossImg.png");
// export const tickmark = require("../assets/tickmark.png");