import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back_to_view_submission_btn: {
      backgroundColor: "#ff0000",
      marginTop: "1.5em",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      color: "#ffffff",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.85em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
  })
  );

interface Props {
  navigation?: any;
  contractDetails?: any;
  subject?: any;
}
const Contactinfo = ({
   navigation,
  contractDetails,
  subject }: Props) => {
    const classes = useStyles();

  const renderPaymentTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ background: "#14292e", padding: "10px" }} className="dash-tabel-head">
            <TableRow>
              <TableCell align="inherit">Payment No</TableCell>
              <TableCell align="inherit">Payment</TableCell>
              <TableCell align="inherit">Minestones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contractDetails?.data[0]?.attributes?.contract_payments.map((each: any, index: any) => (
              <TableRow
              >
                <TableCell align="inherit">{`PN${index + 1}`}</TableCell>
                <TableCell align="inherit">{each.amount}</TableCell>
                <TableCell align="inherit">{each.payment_status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (

    <div className="dash-conatct-info">
      <>
        <Paper
          style={{
            marginTop: "20px",
            padding: "30px",
            background: "#f7f7f7",
            marginBottom: "30px",
            borderRadius: "15px",
          }}
        >
          <Box
            style={{
              borderBottom: "1px solid lightgrey",
              paddingBottom: "10px",
            }}
          >
            <Grid container direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
              <Grid item xs={6} sm={6} md={3}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Class Timings
                </Typography>
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.class_slot}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Contract Type
                </Typography>{" "}
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.contract_type}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Subject
                </Typography>
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {subject}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Hired on
                </Typography>
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  15-08-2022
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px", marginBottom: "25px" }}
            >
              Payment Info
            </Typography>
            <Grid container direction="row" style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Payment Condition
                </Typography>
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.payment_condition}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Distribution Method
                </Typography>{" "}
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.distribution_method}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <Typography
              variant="h6"
              style={{ fontSize: "18px", fontWeight: "bold", marginTop: "25px", marginBottom: "25px" }}
            >
              Payment Structure
            </Typography>
            <Grid container direction="row" style={{ marginTop: "10px", marginBottom: "20px" }}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Total Classes
                </Typography>
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.total_classes}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  No of Payments
                </Typography>{" "}
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.no_of_payments}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle1" className="dash-cont-title">
                  Currency
                </Typography>{" "}
                <Typography variant="subtitle2" className="dash-cont-subtitle">
                  {contractDetails?.data[0]?.attributes?.currency}
                </Typography>
              </Grid>
            </Grid>
            {renderPaymentTable()}
            <Button
              variant="contained"
              className={classes.back_to_view_submission_btn}
              onClick={async () => {
                navigation.goBack()

              }}
              data-testId="sendContractBtn"
              style={{ backgroundColor: 'red' }}

            >
              Back to View Submissions
            </Button>
          </Box>
        </Paper>
      </>

    </div>
  );

}





export default Contactinfo