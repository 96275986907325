import React, { Component } from "react";
import { Logo, phone, email, fb, twitter, google, linkedin } from "./assets";
import TopNavigationController, { Props } from "./TopNavigationController.web";
import Tooltip from "@material-ui/core/Tooltip";
import { Redirect, Link } from "react-router-dom";

export default class BottomNavigation extends TopNavigationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", fontSize: "1.05em" }}>
        <div className="bn-c-container">
          <div className="bn-container">
            <div className="bn-container-c">
              <div className="bn-sec-1">
                <img src={Logo} alt="Logo" />
                <div className="bn-sec-1-txt-container">
                  <div className="bn-sec-1-txt">{this.state.locationDetails.address}</div>
                  {/* <div className="bn-sec-1-txt">Duis turpis risus, pharetra quis porttitor quis..</div> */}
                </div>
                <div className="bn-sec-1-con">
                  <div className="bn-sec-1-pn-c">
                    <img src={email} alt="email" />
                    <div>{this.state.locationDetails.email}</div>
                  </div>
                  <div className="bn-sec-1-pn-c">
                    <img src={phone} alt="phone" />
                    <div>{this.state.locationDetails.full_phone_number}</div>
                  </div>
                </div>
              </div>
              <div className="bn-sec-2">
                <div className="bn-sec-2-c">
                  <div className="bn-sec-2-1">
                    <button onClick={this.handleNavHome_BNav}>
                      <p>Home</p>
                    </button>
                    <button onClick={this.handleNavCourses_BNav}>
                      <p>Courses</p>
                    </button>
                    <button onClick={this.handleNavLWU}>
                      <p>Learn with Us</p>
                    </button>
                  </div>
                  <div className="bn-sec-2-2">
                    <button className="bn-sec-2-2-btn-1" onClick={this.handleNavBAT_BNav}>
                      <p>Become a Teacher</p>
                    </button>
                    <button onClick={this.handleNavCU_BNav}>
                      <p>Contact Us</p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="bn-sec-3">
                <div className="bn-sec-3-fl">
                  <p>Follow us on</p>
                </div>
                <div className="bn-sec-3-icon-c">
                  <Tooltip title="Twitter">
                    <button onClick={this.handleNavTwitter_BNav}>
                      <img src={twitter} alt="twitter" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Linkedin">
                    <button onClick={this.handleNavLinkedin_BNav}>
                      <img src={linkedin} alt="linkedin" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Facebook">
                    <button onClick={this.handleNavFb_BNav}>
                      <img src={fb} alt="fb" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Google+">
                    <button onClick={this.handleNavGoogle_BNav}>
                      <img src={google} alt="google" />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bn-copywrite-c">
          <p>© 2022 Techfin. All Rights Reserved</p>
        </div>
      </div>
    );
  }
}
