import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Button,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  StatusBar,
  Image,
  Dimensions,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import Scale, { verticalScale } from "../../../components/src/Scale";
import { otpBG, backArrow } from "./assets";
import OtpInputs from 'react-native-otp-inputs'
// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return (
      <View style={styles.headerView}>
        <TouchableOpacity onPress={this.goBack}>
          <Image style={styles.arrow} source={backArrow} />
        </TouchableOpacity>
        <View style={styles.blankView} />
      </View>
    )
  }
  renderOtp = () => {
    return (
      <View>
        <Image source={otpBG} style={styles.img} />
        <Text style={styles.titleOtpInfo}>OTP Verfication</Text>
        <Text style={styles.titleWhySignUp}>Please share OTP sent on this no.{'\n'}+{this.state.mobileNo}</Text>
        <View style={styles.loginFieldContainer}>
          <OtpInputs
            clearTextOnFocus
            defaultValue={this.state.otp}
            handleChange={(value: any) => { this.setState({ otp: value, otpMessage: "" }) }}
            keyboardType="phone-pad"
            numberOfInputs={4}
            focusStyles={{ borderColor: 'red' }}
            style={styles.loginFieldContainer}
            inputStyles={styles.inputContainerStyle}
            selectTextOnFocus={false}
            autofillFromClipboard={false}
          />
        </View>
        {this.state.otpMessage !== "" &&
          <Text style={styles.otpMessageText}>{this.state.otpMessage}</Text>
        }
      </View>

    )
  }
  renderButton = () => {
    return (
      <TouchableOpacity onPress={this.handleSubmit} style={styles.btnStyleMobilesignup} testID={"btnEmailLogIn"}>
        <Text style={styles.btnStyleMobiletxtsigup}>Submit</Text>
      </TouchableOpacity>
    )
  }
  renderResendOtp = () => {
    return (
      <View style={styles.txtSignUp}>
        <Text style={styles.txtSignUp}>OTP not recived ? </Text>
        <TouchableOpacity onPress={this.resendOtp}>
          <Text style={styles.register}>Resend</Text>
        </TouchableOpacity>
      </View>
    )
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <SafeAreaView style={styles.mainContainer}>
            <StatusBar backgroundColor="#000" />
            {this.renderHeader()}
            {this.renderOtp()}
            {this.renderButton()}
            {this.renderResendOtp()}
          </SafeAreaView>
          {/* Customizable Area End */}
          {/* Merge Engine UI Engine Code */}
        </TouchableWithoutFeedback>
      </ScrollView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    width: Platform.OS === "web" ? "75%" : "100%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  mainContainer: {
    flex: 1,
    backgroundColor: '#fff'
  },
  headerView: {
    marginBottom: Scale(20),
    justifyContent: 'flex-end',
    height: verticalScale(50)
  },
  arrow: {
    height: Scale(17),
    width: Scale(10),
    marginLeft: Scale(25)
  },
  headerText: {
    fontSize: Scale(17),
    fontWeight: '500'
  },
  blankView: {
    width: Scale(35)
  },
  titleWhySignUp: {
    fontSize: Scale(14),
    alignSelf: "center",
    marginTop: verticalScale(15),
    textAlign: 'center'
  },
  titleOtpInfo: {
    fontSize: Scale(20),
    color: "#000000",
    fontWeight: "bold",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: verticalScale(12)
  },
  otpMessageText: {
    color: "red",
    marginTop: Scale(10),
  },

  phoneInputMobile: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "center",
    backgroundColor: "#00000000",
    borderWidth: 1,
    borderColor: "#FF0000",
    borderRadius: Scale(10),
    includeFontPadding: true,
    padding: 10,
    margin: Scale(10),
    height: Scale(70),
    width: Dimensions.get("screen").width / 6,
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10,
  },
  imgTopView: {
    flex: 1,
    marginTop: Scale(50),
    alignSelf: "center",
    resizeMode: "contain",
    height: Scale(200),
    width: Dimensions.get("screen").width / Scale(1),
    marginBottom: Scale(40),
  },
  img: {
    alignSelf: "center",
    resizeMode: "contain",
    height: Scale(200),
    width: Scale(200),
  },
  btnStyleMobilesignup: {
    backgroundColor: "#FF0000",
    width: Dimensions.get("screen").width / Scale(1.2),
    height: Scale(50),
    borderRadius: Scale(50),
    marginTop: verticalScale(55),
    marginBottom: Scale(10),
    alignItems: "center",
    justifyContent: "center",
    alignSelf: 'center'
  },
  btnStyleMobiletxtsigup: {
    fontSize: 18,
    color: "#ffffff",
    fontWeight: "bold",
  },
  txtSignUp: {
    fontSize: 14,
    alignSelf: "center",
    color: "#0B0B0B",
    flexDirection: "row"
  },
  register: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: "#FF0000",
    flexDirection: "row",
  },
  loginFieldContainer: {
    marginVertical: verticalScale(10),
    flexDirection: 'row',
    width: Scale(300),
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  inputContainerStyle: {
    color: 'black',
    height: Scale(60),
    width: Scale(60),
    textAlign: 'center',
    backgroundColor: '#fff',
    borderColor: 'red',
    borderWidth: 1,
    borderRadius: 5,
  }
});
// Customizable Area End
