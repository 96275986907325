import React from "react";

// Customizable Area Start
import {cardBoys} from "./assets";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import {withStyles, Theme} from '@material-ui/core/styles';

export const styles:any = (theme:Theme) =>
({
root:{
    // marginTop:"3%",
    // marginBottom:"3%"
},
container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  table:{
    height:"50px"
  }




})

import JobDetailContentController,{Props} from "./JobDetailsContentController.web";

export class AppliedJobTopHeader extends JobDetailContentController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  

  render() {
    const {classes} = this.props;
    return (
      // Customizable Area Start
      <div className={classes.root}>
      <Grid container spacing={3} style={{marginTop:"3%",marginBottom:"3%"}}>
            <div className="job-content">
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <img
                    style={{ height: "auto", width: "100%",borderRadius:"15px" }}
                    src={cardBoys}
                    alt=""
                  />
                </Grid>
                <Grid item xs={8} className="job-block">
                  <Typography variant="subtitle1" className="job-name">
                    {this.props?.jobDetails?.attributes?.subject}
                  </Typography>
                  <Typography className="job-desc" sx-={{ color: "red" }}>
                   {this.props?.jobDetails?.attributes?.description}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div className="qualifiaction">
              <Grid container spacing={6}>
                <Grid item xs={8}>
                  <div className="qualification-block">
                    <Grid
                      container
                    //   rowSpacing={1}
                    //   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={6}>
                        <div
                          className="eligiblity"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            columnGap: 20,
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{fontWeight:600,  fontSize:"0.9em",color: "#14292d", fontFamily: "Inter, sans-serif"}}>Qualification:</h3>
                          <Typography variant="subtitle1" className="datas">
                          {this.props?.jobDetails?.attributes?.qualification}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="eligiblity"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr",
                            columnGap: 20,
                            alignItems: "center",
                          }}
                        >
                          <h3  style={{fontWeight:600,  fontSize:"0.9em",color: "#14292d", fontFamily: "Inter, sans-serif",}}>Course Duration:</h3>
                          <Typography variant="subtitle1" className="datas">
                          {this.props?.jobDetails?.attributes?.duration}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="eligiblity"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            columnGap: 20,
                            alignItems: "center",
                          }}
                        >
                          <h3  style={{fontWeight:600,  fontSize:"0.9em",color: "#14292d", fontFamily: "Inter, sans-serif"}}>Experience:</h3>
                          <Typography variant="subtitle1" className="datas">
                          {this.props?.jobDetails?.attributes?.experience}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="eligiblity"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr",
                            columnGap: 20,
                            alignItems: "center",
                          }}
                        >
                          <h3  style={{fontWeight:600,  fontSize:"0.9em",color: "#14292d", fontFamily: "Inter, sans-serif"}}>Starts form:</h3>
                          <Typography variant="subtitle1" className="datas">
                          {this.props?.jobDetails?.attributes?.start_date}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="eligiblity"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            columnGap: 20,
                            alignItems: "center",
                          }}
                        >
                          <h3  style={{fontWeight:600,  fontSize:"0.9em",color: "#14292d", fontFamily: "Inter, sans-serif"}}>Schedule: </h3>
                          <Typography variant="subtitle1" className="datas">
                          {this.props?.jobDetails?.attributes?.schedule}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          className="eligiblity"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 1fr",
                            columnGap: 20,
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{fontWeight:600,  fontSize:"0.9em",color: "#14292d", fontFamily: "Inter, sans-serif"}}>Last Date for Application: </h3>
                          <Typography variant="subtitle1" className="datas">
                          {this.props?.jobDetails?.attributes?.last_date}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="Proposal-block">
                    <p style={{ color: "#ff0000",
                     fontFamily: "Inter, sans-serif",
                    fontWeight: 700,
                    fontSize:"1.2em"
                    }}>Proposed Budget</p>
                    <Typography className="price">Rs {this.props?.jobDetails?.attributes?.proposed_budget}/-</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
       
      </Grid>

      
    
        

      </div>  
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, {withTheme: true})(AppliedJobTopHeader);

// Customizable Area End







