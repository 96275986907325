import React, { Component } from "react";

// Customizable Area Start

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  laptop,
  boygirl,
  youngboy,
  twomembers,
  calender,
  freetag,
  smallgirl,
  girllaptop,
  book,
  boylaptop,
  boyphone,
  tickmark,
} from "./assets";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import "./EmailLogin.css";
import Modal from "@material-ui/core/Modal";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import {CircularProgress} from "@mui/material";

// import CustomCheckBox from "../../../components/src/CustomCheckBox";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    banner: {
      position: "relative",
    },
    bannerTitleContainer: {
      position: "absolute",
      bottom: "45px",
      left: "24%",
      transform: "translate(-24%, -9%)",
      fontSize: 41,
      color: "#fff",
      // [theme.breakpoints.down("lg")]:{
      //     bottom: '10%',
      //     left: '20%',
      //     transform: 'translate(-20%, -10%)',
      //     fontSize:36,
      // },
      // [theme.breakpoints.down("md")]:{
      //     bottom: '20%',
      //     left: '20%',
      //     transform: 'translate(-20%, -20%)',
      //     fontSize:24,
      // },
      // [theme.breakpoints.down("sm")]:{
      //     bottom: '15%',
      //     left: '20%',
      //     transform: 'translate(-15%, -20%)',
      //     fontSize:18,
      // },
      // [theme.breakpoints.down("xs")]:{
      //     fontSize:10,
      // },
    },
    bannerTitle_reset: {
      fontSize: 41,
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: 36,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
      },
    },
    BannerLMBtn_reset: {
      marginTop: 25,
      borderRadius: 100,
      backgroundColor: "#f0d17c",
      width: 180,
      height: 50,
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 20,
        height: 30,
        width: 130,
        fontSize: 14,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 15,
        height: 30,
        width: 100,
        fontSize: 12,
      },
      [theme.breakpoints.down("xs")]: {
        height: 20,
        width: 90,
        fontSize: 10,
      },
    },
    EmailpasswordContainer: {
      minHeight: 457,
      background: "#f7f7f7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: 25,
      [theme.breakpoints.up("xl")]: {
        minHeight: 557,
      },
      [theme.breakpoints.down("sm")]: {
        columnGap: 0,
        flexDirection: "column",
        rowGap: 25,
      },
      [theme.breakpoints.down("xs")]: {
        margin: 5,
      },
    },
    EmailPassword: {
      width: 500,
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.down(1050)]: {
        width: 440,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
    },
    LoginbanerImg: {
      height: 350,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 250,
      },
    },
    LoginBtnContainer: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        rowGap: 10,
      },
    },
    popCourseName: {
      fontSize: 34,
      fontWeight: 600,
      margin: 50,
      color: "#333333",
      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
        margin: 25,
      },
    },
    popCourseContainer: {
      display: "flex",
      justifyContent: "center",
      gap: 20,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    popCourseCard_reset: {
      width: 600,
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: 400,
      },

      [theme.breakpoints.down("sm")]: {
        width: 500,
      },
      [theme.breakpoints.down("xs")]: {
        width: 350,
      },
    },

    cardButton_reset: {
      marginBottom: 25,
      borderRadius: 100,
      textTransform: "none",
      fontWeight: 700,
      fontSize: 16,
      width: 180,
      height: 50,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        width: 130,
        height: 30,
      },
    },
    forgotpasswordpopup_reset: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "442px",
      height: "250px",
      borderRadius: "20px",
      padding: "2px 27px 49px 27px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto",
      },
    },
    textfieldemail_reset: {
      width: "100%",
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.down("xs")]: {
        width: "212px",
      },
    },
    cancelbutton_reset: {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "150px",
      border: "1px solid red",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    submitbutton_reset: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "150px",
      marginLeft: "10px",
      color: "#fff",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    tickicon_reset: {
      height: "50px",
      width: "50px",
      justifyContent: "center",
      marginLeft: "200px",
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "90px",
      },
    },
    EmailPasswordreset: {
      width: 500,
      // border: "1px solid red",
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.up("xl")]: {
        width: 620,
      },
      [theme.breakpoints.between(1280, 1336)]: {
        width: 461,
      },
      [theme.breakpoints.down(1050)]: {
        width: 440,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
      },
    },
    LoginbanerImgreset: {
      height: 350,
      // border: "1px solid red",
      width: "auto",
      marginLeft: "20px",
      [theme.breakpoints.up("xl")]: {
        height: 400,
      },
      [theme.breakpoints.between(1280, 1336)]: {
        height: 300,
      },
      [theme.breakpoints.down("xs")]: {
        height: 250,
      },
      [theme.breakpoints.down("xs")]: {
        height: 250,
      },
    },
    LoginBtnContainerreset_reset: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        rowGap: 10,
      },
    },
    resetpassword_reset: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px,50px,20px,50px !important",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.down("xs")]: {
        width: "250px",
      },
    },
    resetpasswordloginbutt_reset: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "170px",
      color: "#fff",
      justifyContent: "center",
      marginLeft: "223px",
      top: "27px",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "34px",
      },
    },
    tickmarkloginbutt_reset: {
      height: "70px",
      width: "70px",
      marginTop: "-30px",
      justifyContent: "center",
      marginLeft: "279px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "77px",
        textalign: "center",
      },
    },

    RelatedCourseCard_reset: {
      width: 287,
      height: "100%",
    },
  });
// Customizable Area End

import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import { Formik } from "formik";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import PopularCourses from "../../email-account-registration/src/PopularCourses.web";

export class ResetPassword extends ResetPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <div className={classes.root}>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />

        {/* Banner */}
        <div className={classes.banner}>
          <img src={boygirl} alt="" style={{ width: "100%", height: "auto" }} />
          <div className={classes.bannerTitleContainer}>
            <Typography className={classes.bannerTitle_reset}>
              <b>
                Wide Range of Courses <br /> Available
              </b>
            </Typography>
            <Button className={classes.BannerLMBtn_reset}>Learn More</Button>
          </div>
        </div>

        {/* Login and Banner Image */}
        <div className={classes.EmailpasswordContainer}>
          {/* {this.state.resetpassword ?  */}
          <div className={classes.resetpassword_reset}>
            <div>
              {this.state.resetpasswordsuccesfull ? (
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <div>
                    <img className={classes.tickmarkloginbutt_reset} src={tickmark} alt="" />
                  </div>
                  <Typography style={{ color: "#ff0000", fontSize: 30, fontWeight: 600, marginTop: 10 }}>
                    {" "}
                    You have successfully reset your password
                  </Typography>
                  <Typography
                    style={{
                      color: "#333333",
                      fontSize: 20,
                      fontWeight: 550,
                      marginLeft: "56px",
                      marginTop: "10px",
                    }}
                  >
                    We have sent you an email with your login credentials
                  </Typography>
                  <div style={{ marginTop: "15px" }}>
                    <hr style={{ color: "#CACACA" }} />
                    <Button
                      className={classes.resetpasswordloginbutt_reset}
                      variant="contained"
                      onClick={() => {
                        this.props.navigation.navigate("EmailLogin");
                      }}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <form onSubmit={this.resetpassword}>
                    <Typography
                      style={{
                        fontSize: 24,
                        fontWeight: 700,
                        marginBottom: 20,
                      }}
                    >
                      Reset Your Password
                    </Typography>
                    <div>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                      >
                        Enter New Password
                      </Typography>
                      <FormControl className={classes.EmailPasswordreset} variant="outlined">
                        {/* style={this.state.resetFailedError ? {backgroundColor: "#fcecec", border: "0.5px solid red"}:{}} */}
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={this.state.showPassword ? "text" : "password"}
                          value={this.state.pass}
                          required
                          onChange={(e) => {
                            this.handlePassChange(e);
                          }}
                          data-testId="handlePassChange"
                          placeholder="********************"
                          style={this.state.errPass ? this.errStyle : {}}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                                data-testId="handleClickShowPassword"
                              >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                    <Typography
                      style={{
                        backgroundColor: "#f7f7f7",
                        fontSize: "12",
                        marginTop: "10px",
                        color: "#333333",
                        fontWeight: 550,
                      }}
                    >
                      For better security use special charactrs the @; $ etc and a combination of uppercase{" "}
                      <br /> and lowercase letters
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <Typography style={{ fontSize: 16, fontWeight: 600 }}> Confirm Password</Typography>
                    </div>
                    <FormControl className={classes.EmailPasswordreset} variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={this.state.showPasswordconform ? "text" : "password"}
                        value={this.state.cPass}
                        required
                        onChange={(e) => {
                          this.handleCPassChange(e);
                        }}
                        data-testId="handleCPassChange"
                        placeholder="********************"
                        style={this.state.errCPass ? this.errStyle : {}}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                              data-testId="handleClickShowPassword"
                            >
                              {this.state.showPasswordconform ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div className={classes.LoginBtnContainerreset_reset}>
                      <Button
                        // onClick ={this.successfullychangedpassword}
                        style={{
                          borderRadius: "100px",
                          backgroundColor: "#ff0000",
                          width: 180,
                          height: 50,
                          color: "#fff",
                          textTransform: "none",
                        }}
                        variant="contained"
                        onClick={() => {
                          this.resetpassword();
                        }}
                        data-testId="resetpassword"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              )}
            </div>
            {!this.state.resetpasswordsuccesfull && (
              <div>
                <img src={laptop} alt="" className={classes.LoginbanerImgreset} />
              </div>
            )}
          </div>
          <></>
          {/* } */}
        </div>
        <PopularCourses
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(ResetPassword);

// Customizable Area End
