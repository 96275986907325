import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { twomembers, freetag, calender } from "../assets";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    courseCardContainer: {
      padding: "0px",
      width: "15.3vw",
      boxShadow: "0px 5px 20px rgba(0,0,0,0.06)",
    },
    RelatedCourseCard: {
      width: "100%",
      height: "100%",
      borderRadius: "0px",
    },

    List_image: {
      height: "12.4vw",
      width: "100%",
      objectFit: "cover",
    },
    List_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontSize: "1.3vw",
      fontWeight: 600,
      lineHeight: "1.7em",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 1,
      display: "-webkit-box",
    },
    List_subtitle: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8vw",
      fontWeight: 400,
      lineHeight: "2em",
      maxLines: 2,
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      minHeight: "4em",
    },
    List_info_c: {
      display: "flex",
      flexDirection: "row",
      marginTop: "0.5vw",
      // columnGap: 5,
    },
    List_info_c_c: {
      display: "flex",
      alignItems: "center",
    },
    List_info_img: {
      // height: "0.8vw",
      width: "0.9vw",
      objectFit: "contain",
      marginRight: "0.2vw",
    },
    List_info_txt: {
      fontWeight: 600,
      fontSize: "0.75vw",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
    },
    List_info_break: {
      color: "#f0d17c",
      marginRight: "0.4vw",
    },
    List_info_btn_c: {
      display: "flex",
      // columnGap: 20,
      // rowGap: 20,
      justifyContent: "space-between",
      alignItem: "center",
      width: "100%",
      marginBottom: "1vw",
    },
    List_info_btn_1: {
      background: "#f00",
      color: "#fff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      textTransform: "none",
      borderRadius: 100,
      width: "48%",
      "&:hover": {
        background: "#f00",
      },
    },
    List_info_btn_2: {
      background: "#f0d17c",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      textTransform: "none",
      borderRadius: 100,
      width: "48%",
      "&:hover": {
        background: "#f0d17c",
      },
    },
    List_cardCont: {
      padding: "0.8vw",
    },
    List_cardAct: {
      padding: "0.6vw",
    },
  })
);

interface Props {
  id: any;
  image_url: any;
  name: any;
  course_description: any;
  selected_count: any;
  course_type: any;
  course_cost: any;
  start_date: any;
  is_paid: any;
  handleViewCourse: any;
  handleBuyCourse: any;
}
const CourseCard = ({
  id,
  image_url,
  name,
  course_description,
  selected_count,
  course_type,
  course_cost,
  start_date,
  is_paid,
  handleViewCourse,
  handleBuyCourse,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.courseCardContainer} key={id}>
      <Card className={classes.RelatedCourseCard}>
        <CardMedia>
          <img className={classes.List_image} src={image_url} alt="" />
        </CardMedia>
        <CardContent className={classes.List_cardCont}>
          <Typography className={classes.List_title}>{name}</Typography>
          <Typography variant="body2" component="p" className={classes.List_subtitle}>
            {course_description}
          </Typography>

          <div className={classes.List_info_c}>
            <div className={classes.List_info_c_c}>
              <img className={classes.List_info_img} src={twomembers} alt="" />
              <Typography className={classes.List_info_txt}>&nbsp;{selected_count}&nbsp;</Typography>
              <Typography className={classes.List_info_break}>|</Typography>
            </div>
            <div className={classes.List_info_c_c}>
              <img className={classes.List_info_img} src={freetag} alt="" />
              <Typography className={classes.List_info_txt}>
                &nbsp;{course_type == "free" ? "Free" : course_cost}&nbsp;
              </Typography>
              <Typography className={classes.List_info_break}>|</Typography>
            </div>
            <div className={classes.List_info_c_c}>
              <img className={classes.List_info_img} src={calender} alt="" />
              <Typography className={classes.List_info_txt}>
                &nbsp;{moment(`${start_date}`).format("DD/MM/YYYY")}
              </Typography>
            </div>
          </div>
        </CardContent>

        <CardActions className={classes.List_cardAct}>
          <div className={classes.List_info_btn_c}>
            <Button
              variant="contained"
              className={classes.List_info_btn_1}
              size="small"
              onClick={() => {
                handleViewCourse(id);
              }}
              data-testId="CCard_ViewCourse"
            >
              View Course
            </Button>
            {course_type !== "free" && !is_paid && (
              <Button
                variant="contained"
                className={classes.List_info_btn_2}
                size="small"
                onClick={() => {
                  handleBuyCourse(id);
                }}
                data-testId="CCard_BuyCourse"
              >
                Buy Now
              </Button>
            )}
          </div>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default CourseCard;
