import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  hiringPosts: any[];
  recentlyHiredList: any;
  isHiringPostsLoading: any;
  isRecentlyHiredLoading: any;
  scheduleClassesList:any
  sheduledClassesTotalCount:any
  sheduledClassesCurrentPage:any
  
  page:number;
  currentPage: number;

  sheduledClassesPageCount:number;
  JPLcurrentPage:number;
  numberOfPages : number
  
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
export default class TeacherClassesController extends BlockComponent<Props, S, SS> {
  getHiringPostsId: any;
  getrecentlyHiredTeachersId: any;
  getScheduledClassesId:any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      hiringPosts: [],
      recentlyHiredList: [],
      isHiringPostsLoading: true,
      isRecentlyHiredLoading: true,
      scheduleClassesList:[],

      sheduledClassesTotalCount:0,
      sheduledClassesCurrentPage:0,


      page:1,
      currentPage: 1,

      sheduledClassesPageCount:1,
      JPLcurrentPage:1,
      numberOfPages : 6,

    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    this.getHiringPostsDetails();
    this.getRecentlyHiredTeachers();
    this.getScheduleClassesData()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForHiringPosts(from, message);
    this.handleRecentlyHiredTeachers(from, message);
    this.handleScheduleClasses(from, message)
    // Customizable Area End
  }

  handleResponseForHiringPosts = async (from: string, message: Message) => {
    if (this.getHiringPostsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiResponse?.error) {
        alert(apiResponse?.error);
      } else if (apiResponse?.errors) {
        if (apiResponse?.errors[0]?.token == "Token has Expired") {
          this.props.navigation.navigate("EmailLogin");
        }
      } else {
        await this.setState({
          hiringPosts: apiResponse?.data,
        });
        await this.setState({
          isHiringPostsLoading: false,
        });
      }
    }
  };

  handleRecentlyHiredTeachers = (from: string, message: Message) => {
    if (
      this.getrecentlyHiredTeachersId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiResponse?.error) {
        alert(apiResponse?.error);
      } else if (apiResponse?.errors) {
        if (apiResponse?.errors[0]?.token == "Token has Expired") {
          this.props.navigation.navigate("EmailLogin");
        }
      } else {
        this.setState({
          recentlyHiredList: apiResponse?.data,
          isRecentlyHiredLoading: false,
        });
      }
    }
  };


  handleScheduleClasses=(from: string, message: Message)=>{

    if (this.getScheduledClassesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiResponse?.error) {

        alert(apiResponse?.error)
      } else {
        this.setState({ scheduleClassesList: apiResponse?.data ,sheduledClassesPageCount: apiResponse.page_count });
      }
  }

  }





  getHiringPostsDetails() {
    const token = localStorage.getItem("loginT");

    const headers = {
      "Content-Type": configJSON.popularCourseDataType,
      token: token,
    };

    const getHiringPostsRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHiringPostsId = getHiringPostsRequestMsg.messageId;

    getHiringPostsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hiringPostsEndpoint
    );

    getHiringPostsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getHiringPostsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
    runEngine.sendMessage(getHiringPostsRequestMsg.id, getHiringPostsRequestMsg);
  }

  getRecentlyHiredTeachers() {
    const token = localStorage.getItem("loginT");

    const headers = {
      "Content-Type": configJSON.popularCourseDataType,
      token: token,
    };

    const getRecentlyHiredRequestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getrecentlyHiredTeachersId = getRecentlyHiredRequestMsg.messageId;

    getRecentlyHiredRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recentlyHiredTeachersEndpoint
    );

    getRecentlyHiredRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getRecentlyHiredRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );
    runEngine.sendMessage(getRecentlyHiredRequestMsg.id, getRecentlyHiredRequestMsg);
  }

  handleViewSubmissionBtn = (eachPost: any) => {
    const hiringPostId = eachPost?.id;
    this.props.navigation.navigate("ViewSubmissionList", { hiringPostId: hiringPostId });
  };

  // Customizable Area Start
  getScheduleClassesData() {
    const token = localStorage.getItem("loginT");
    const endPoint = `/assessments/student_scheduled_classes?per_page=${this.state.numberOfPages}&page=${this.state.currentPage}`;


    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const getScheduleClassesRqstMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getScheduledClassesId = getScheduleClassesRqstMsg.messageId;


    getScheduleClassesRqstMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getScheduleClassesRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getScheduleClassesRqstMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getScheduleClassesRqstMsg.id, getScheduleClassesRqstMsg);

  }




  handleNavigationToStudentSubjectDetails = (each:any) => {
    localStorage.setItem("StudentSubjectDetailsId",each?.id);
    this.props.navigation.navigate("StudentSubjectDetials");
  };



  // Customizable Area End
}

// Customizable Area End
