import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import React, { Component } from "react";
const baseURL = require("../../../framework/src/config");

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  resetpasswordsuccesfull: any;
  pass: string;
  cPass: string;
  errPass: boolean;
  errCPass: boolean;
  activeUserId: any;
  showPassword: any;
  showPasswordconform: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS> {
  resetPasswordCallId: any;
  errStyle: any;
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      resetpasswordsuccesfull: false,
      pass: "",
      cPass: "",
      errPass: false,
      activeUserId: null,
      errCPass: false,
      showPassword: false,
      showPasswordconform: false,
      // Customizable Area End
    };

    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.resetPasswordCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("resetPasswordCallId_Response", responseJson);
            this.handleSubmitreset();
          } else {
            console.log("ERR_resetPasswordCallId_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  successfullychangedpassword = () => {
    this.setState({ resetpasswordsuccesfull: true });
  };

  async handleSubmitreset() {
    this.successfullychangedpassword();
    if (this.state.pass.length <= 0) {
      await this.setState({ errPass: true });
    } else {
      await this.setState({ errPass: false });
    }
    if (this.state.cPass.length <= 0) {
      await this.setState({ errCPass: true });
    } else {
      await this.setState({ errCPass: false });
    }
    if (this.state.cPass == this.state.pass) {
      await this.setState({ errCPass: false });
    } else {
      await this.setState({ errCPass: true });
    }
  }

  resetpassword = async () => {
    if (this.state.pass.length < 4 && this.state.cPass.length < 4) {
      return this.setState({ errPass: true, errCPass: true });
    }
    if (this.state.pass === this.state.cPass) {
      const formData = new FormData();
      formData.append("id", this.state.activeUserId);
      formData.append("password", this.state.pass);
      formData.append("password_confirmation", this.state.cPass);
      // formData.append("id", this.state., );

      // try {
      //   let response = await fetch(`${baseURL.baseURL}/reset_passwords/reset_password`, {
      //     method: "post",
      //     body: formData,
      //   });
      //   const json = await response.json();
      //   if (json) {
      //     this.handleSubmitreset();
      //   }
      //   console.log(json, "___jsonData");
      //   // setData(json.movies);
      // } catch (error) {
      //   console.error(error);
      // }

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const httpBody = {
        id: this.state.activeUserId,
        password: this.state.pass,
        password_confirmation: this.state.cPass,
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.resetPasswordCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.ResetPasswordEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.loginAPiMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
      // console.log("httpBody", httpBody);
      return true;
    }
  };

  //resetpassword Functions
  async handlePassChange(e: any) {
    await this.setState({ pass: e.target.value });
    if (this.state.pass.length <= 0) {
      await this.setState({ errPass: true });
    } else {
      await this.setState({ errPass: false });
    }
  }

  async handleCPassChange(e: any) {
    await this.setState({ cPass: e.target.value });
    if (this.state.cPass.length <= 0) {
      await this.setState({ errCPass: true });
    } else {
      await this.setState({ errCPass: false });
    }
    if (this.state.cPass == this.state.pass) {
      await this.setState({ errCPass: false });
    } else {
      await this.setState({ errCPass: true });
    }
  }

  handleClickShowPassword = () => {
    this.state.showPassword ? this.setState({ showPassword: false }) : this.setState({ showPassword: true });
    this.state.showPasswordconform
      ? this.setState({ showPasswordconform: false })
      : this.setState({ showPasswordconform: true });
  };

  handleMouseDownPassword = () => {};

  componentWillMount() {
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log("componentWillMount otpSuccess");

    let pathId = location.href;
    let resetUserId = pathId?.split("?id=")[1];
    if (resetUserId) {
      this.setState({ activeUserId: resetUserId });
    }
  }
}
