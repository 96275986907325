import * as React from "react";
import { Viewer, Worker, SpecialZoomLevel, Plugin } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin, ToolbarProps } from "@react-pdf-viewer/default-layout";
import { ProgressBar } from "@react-pdf-viewer/core";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";


import RReadingIndicatorPlugin from "./RReadingIndicatorPlugin";
import { useEffect } from "react";
import {pageNavigationPlugin,RenderCurrentPageLabelProps} from '@react-pdf-viewer/page-navigation'

export interface ReadingProgressProps {
  fileUrl: string;
  setPercent: (percentage: number) => void;
}

const ReadingProgress: React.FC<ReadingProgressProps> = ({ fileUrl, setPercent }) => {
  const readingIndicatorPluginInstance = RReadingIndicatorPlugin();
  const { ReadingIndicator } = readingIndicatorPluginInstance;
  const { percentages } = readingIndicatorPluginInstance;
  const [numberOfPages, setNumberOfPages] = React.useState<any>(null)

  useEffect(() => {
    const setPercentages = (percent: number) => {
      // console.log('percent', percent)
      setPercent(percent);
    };
    setPercentages(percentages);
  }, [percentages]);

  useEffect(()=>{
    console.log('number', numberOfPages)
    if(numberOfPages<1){
      setPercent(100)
    }

  },[numberOfPages])


  const renderToolbar = React.useCallback(
    (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
      <>
        <Toolbar>
          {(props: ToolbarSlot) => {
            const {
              CurrentPageInput,
              EnterFullScreen,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              Print,
              Zoom,
              ZoomIn,
              ZoomOut,
              Download,
            } = props;
            return (
              <>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomOut />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Zoom />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomIn />
                </div>
                <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                  <GoToPreviousPage />
                </div>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    padding: "0px 2px",
                  }}
                >
                  <CurrentPageInput /> / <NumberOfPages />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <GoToNextPage />
                </div>

                <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                  {/* <Download /> */}
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Print />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <EnterFullScreen />
                </div>
              </>
            );
          }}
        </Toolbar>
        <div
          style={{
            bottom: "-0.25rem",
            position: "absolute",
            left: 0,
            // Take the full width of toolbar
            width: "100%",
          }}
        >
          <ReadingIndicator />
        </div>
      </>
    ),
    []
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin() as Plugin;

  const toolbarPluginInstance = toolbarPlugin() as Plugin;

  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { CurrentPageLabel } = pageNavigationPluginInstance;
  console.log('nummmmm',CurrentPageLabel)


  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <Viewer
        fileUrl={fileUrl}
        plugins={[defaultLayoutPluginInstance, readingIndicatorPluginInstance, toolbarPluginInstance, pageNavigationPluginInstance  as Plugin]}
        renderLoader={(percentages: number) => (
          <div style={{ width: "240px" }}>
            <ProgressBar progress={Math.round(percentages)} />
          </div>
        )}
        defaultScale={SpecialZoomLevel.PageWidth}
      />
      <CurrentPageLabel>
        {(props: RenderCurrentPageLabelProps) => {
          setNumberOfPages(props.numberOfPages)
          return (
            <span>
             
            </span>
          )
        }

        }
      </CurrentPageLabel>
    </Worker>
  );
};

export default ReadingProgress;
