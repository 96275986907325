import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import { studentPicture, Women, cardBoys, hours, calender } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
  });

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End

import TeachersDashboardAssignmentController, { Props } from "./TeachersDashboardAssignmentController.web";
import { Divider } from "react-native-elements";
import Avatar from "@material-ui/core/Avatar";
import DashSidebar1 from "./Components/DashSidebar1.web";
import DashSidebar2 from "./Components/DashSidebar2.web";
import DashSidebar3 from "./Components/DashSidebar3.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

export class TeachersDashboardAssignment extends TeachersDashboardAssignmentController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    
    return (

      // Customizable Area Start
      <>
      {/*@ts-ignore*/}
      <div>
        <TopNavigation
          navigation={this.props.navigation} history={this.props.history}
          location={this.props.location}
        />
        {/* Banner */}
        <div>
          <Grid container spacing={3} className="TDA_Container">
            <Grid item xs={1} />
            <Grid item xs={2}>
              <DashSidebar1
                active="Dashboard"styles={{ marginBottom: "1.5vw" }}
                navigation={this.props.navigation}
              />
              <DashSidebar2
                timings="10:00 AM - 11:00 PM"title="Introduction to foundation of digital marketing and e-commerce"
                styles={{ marginBottom: "1.5vw" }}
              />
              <DashSidebar3
                title="Get a chance according to your ability"subtitle="Lorem Ipsum is simply dummy text of the printing"Buttontitle="Test Now"
                styles={{ marginBottom: "1.5vw" }}
                onClick={() => { }}
              />
            </Grid>
            <Grid item xs={8}>
              <div>
                <p className="TDA_heading"
                >
                  Assessments
                </p>
                <Divider />
              </div>
              <Grid container spacing={3} className="TDA_ongoing_AContainer">
                <Grid item xs={8}>
                  <Typography variant="h5" style={{}}>
                    Ongoing Assessments
                  </Typography>
                  <div className="TDA_ongoing_AContainer1">
                    <Grid container spacing={3} className="TDA_ongoing_AContainer1_grid">
                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="TDA_ongoing_AContainer1_card_img" 
                            src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography 
                          className="TDA_ongoing_AContainer1_card_Typo"
                            >
                              Subject
                            </Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{
                                lineHeight: 1.8 
                                }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div
                              className="TDA_ongoing_AContainer1_card_DetailsD"
                            >
                              <div className="TDA_ongoing_AContainer1_card_Details_dd">
                                <img className="TDA_ongoing_AContainer1_card_Details_dd_img"src={hours} alt="" />
                                <Typography className="TDA_ongoing_AContainer1_card_Details_dd_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="TDA_ongoing_AContainer1_card_Details_dd">
                                <img className="TDA_ongoing_AContainer1_card_Details_dd_img" src={calender} alt="" />
                                <Typography className="TDA_ongoing_AContainer1_card_Details_dd_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="TDA_ongoing_AContainer1_card_Details_action">
                              <Button variant="contained" 
                               className="TDA_ongoing_AContainer1_card_Details_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>

                      {/* <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="TDA_ongoing_AContainer1_card_img" 
                            src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography 
                          className="TDA_ongoing_AContainer1_card_Typo"
                            >
                              Subject
                            </Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{
                                lineHeight: 1.8 
                                }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div
                              className="TDA_ongoing_AContainer1_card_DetailsD"
                            >
                              <div className="TDA_ongoing_AContainer1_card_Details_dd">
                                <img className="TDA_ongoing_AContainer1_card_Details_dd_img"src={hours} alt="" />
                                <Typography className="TDA_ongoing_AContainer1_card_Details_dd_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="TDA_ongoing_AContainer1_card_Details_dd">
                                <img className="TDA_ongoing_AContainer1_card_Details_dd_img" src={calender} alt="" />
                                <Typography className="TDA_ongoing_AContainer1_card_Details_dd_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="TDA_ongoing_AContainer1_card_Details_action">
                              <Button variant="contained" 
                               className="TDA_ongoing_AContainer1_card_Details_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>

                      <Grid item xs={4}>
                        <Card>
                          <CardMedia>
                            <img className="TDA_ongoing_AContainer1_card_img" 
                            src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                          <Typography 
                          className="TDA_ongoing_AContainer1_card_Typo"
                            >
                              Subject
                            </Typography>
                            <Typography
                              variant="body2"
                              component="p" 
                              style={{
                                lineHeight: 1.8 
                                }}
                               >
                                is simply dummy text of printing of printing
                              </Typography>

                            <div
                              className="TDA_ongoing_AContainer1_card_DetailsD"
                            >
                              <div className="TDA_ongoing_AContainer1_card_Details_dd">
                                <img className="TDA_ongoing_AContainer1_card_Details_dd_img"src={hours} alt="" />
                                <Typography className="TDA_ongoing_AContainer1_card_Details_dd_Typo"
                                >
                                  &nbsp;10:00 AM-11:00 AM&nbsp;
                                </Typography>
                              </div>
                              <div className="TDA_ongoing_AContainer1_card_Details_dd">
                                <img className="TDA_ongoing_AContainer1_card_Details_dd_img" src={calender} alt="" />
                                <Typography className="TDA_ongoing_AContainer1_card_Details_dd_Typo"
                                >
                                  &nbsp;5/07/2022
                                </Typography>
                              </div>
                            </div>
                          </CardContent>

                          <CardActions>
                            <div className="TDA_ongoing_AContainer1_card_Details_action">
                              <Button variant="contained" 
                               className="TDA_ongoing_AContainer1_card_Details_Button"
                              size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid> */}
                    </Grid>
                    <div style={{ margin: "15px" }}>
                      <Button
                        variant="contained"
                        className="TDA_ongoing_AContainer1_card_Details_action_b"
                      >
                        View All Subjects
                      </Button>
                    </div>
                  </div>
                  <Typography variant="h5" className="TDA_PassAssesment_heading">Past Assessments</Typography>
                  <div className="TDA_PassAssesment_Container">
                    <Grid
                      container
                      spacing={3}
                      className="TDA_PassAssesment_Container_grid"
                    >
                      <Grid item xs={4}>
                      <Card  className="TDA_PassAssesment_Container_grid_Card">
                          <CardMedia>
                            <img className="TDA_PassAssesment_Container_grid_Card_Img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                            <Typography className="TDA_PassAssesment_Container_grid_Card_contentTypo">Job Name</Typography>
                            <div className="TDA_PassAssesment_Container_grid_d">
                              <p className="TDA_PassAssesment_Container_grid_dp">Qualification:</p>
                              <p>&nbsp;Any</p>
                            </div>
                            <Typography variant="body2" component="p" style={{ lineHeight: 1.8 }}>is simply dummy text of printing of printing</Typography>
                          </CardContent>

                          <CardActions>
                            <div
                              className="TDA_PassAssesment_Container_grid_CardAction"
                            >
                              <Button
                                variant="contained"
                                className="TDA_PassAssesment_Container_grid_Button"
                                size="small"
                              >
                                Apply
                              </Button>
                              <Button variant="contained" 
                                  className="TDA_PassAssesment_Container_grid_Button1"
                                  size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                      
                      {/* <Grid item xs={4}>
                      <Card  className="TDA_PassAssesment_Container_grid_Card">
                          <CardMedia>
                            <img className="TDA_PassAssesment_Container_grid_Card_Img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                            <Typography className="TDA_PassAssesment_Container_grid_Card_contentTypo">Job Name</Typography>
                            <div className="TDA_PassAssesment_Container_grid_d">
                              <p className="TDA_PassAssesment_Container_grid_dp">Qualification:</p>
                              <p>&nbsp;Any</p>
                            </div>
                            <Typography variant="body2" component="p" style={{ lineHeight: 1.8 }}>is simply dummy text of printing of printing</Typography>
                          </CardContent>

                          <CardActions>
                            <div
                              className="TDA_PassAssesment_Container_grid_CardAction"
                            >
                              <Button
                                variant="contained"
                                className="TDA_PassAssesment_Container_grid_Button"
                                size="small"
                              >
                                Apply
                              </Button>
                              <Button variant="contained" 
                                  className="TDA_PassAssesment_Container_grid_Button1"
                                  size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>

                      <Grid item xs={4}>
                      <Card  className="TDA_PassAssesment_Container_grid_Card">
                          <CardMedia>
                            <img className="TDA_PassAssesment_Container_grid_Card_Img" src={cardBoys} alt="" />
                          </CardMedia>
                          <CardContent>
                            <Typography className="TDA_PassAssesment_Container_grid_Card_contentTypo">Job Name</Typography>
                            <div className="TDA_PassAssesment_Container_grid_d">
                              <p className="TDA_PassAssesment_Container_grid_dp">Qualification:</p>
                              <p>&nbsp;Any</p>
                            </div>
                            <Typography variant="body2" component="p" style={{ lineHeight: 1.8 }}>is simply dummy text of printing of printing</Typography>
                          </CardContent>

                          <CardActions>
                            <div
                              className="TDA_PassAssesment_Container_grid_CardAction"
                            >
                              <Button
                                variant="contained"
                                className="TDA_PassAssesment_Container_grid_Button"
                                size="small"
                              >
                                Apply
                              </Button>
                              <Button variant="contained" 
                                  className="TDA_PassAssesment_Container_grid_Button1"
                                  size="small">
                                View Details
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </Grid> */}
                      
                    </Grid>
                    <div style={{ margin: "15px" }}>
                      <Button
                        variant="contained"
                        className="TDA_PassAssesment_Container_grid_Button2"
                      >
                        View All Assessments
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                  <Card className="TDA_PassAssesment_LeftContainer">
                    <div className="TDA_PassAssesment_Container_d">
                      <Avatar
                        alt="StudentPicture"
                        src={studentPicture}
                        className="TDA_PassAssesment_Container_d_Avtar"
                      />
                      <Typography variant="h6" className="TDA_PassAssesment_Container_Card1_typo">
                        Teacher Name
                      </Typography>
                      <Typography variant="subtitle2" className="TDA_PassAssesment_Container_Card1_typo1">
                        email@gmail.com
                      </Typography>
                    </div>
                    <CardContent className="TDA_PassAssesment_Container_Card1_Content">
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_Typo"
                      >
                        Experience
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_TypO2"
                      >
                        2-3 years
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_Typo"
                      >
                        Scheduled Classes
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_TypO2"
                      >
                        5
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_Typo"
                      >
                        Qualification
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_TypO2"
                      >
                        Graduate
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_Typo"
                      >
                        Joined on
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        className="TDA_PassAssesment_Container_Card1_Content_TypO2"
                      >
                        10/08/22
                      </Typography>
                    </CardContent>
                    <CardActions className="TDA_PassAssesment_Container_Card1_Content">
                      <Button
                        variant="contained"
                        className="TDA_Card_action_button"
                      >
                        Update Profle
                      </Button>
                    </CardActions>
                  </Card>
                  <Card className="TDA_LCard2">
                    <img src={Women} className="TDA_LCard2_img" />
                    <CardContent className="TDA_LCard2_Content">
                      <Typography
                        variant="h5"
                        component="h5"
                        className="TDA_LCard2_Typo"
                      >
                        Don't forget to setup
                        <br />
                        Training for next week
                      </Typography>
                    </CardContent>
                    <CardActions className="TDA_LCard2_CardAction">
                      <Button
                        variant="contained"
                        className="TDA_LCard2_Button"
                      >
                        Set Up Now
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </div>

        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(TeachersDashboardAssignment);

// Customizable Area End
