import React, { Component } from "react";

// Customizable Area Start
import { Divider } from "react-native-elements";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  thirdcard,
  redsidearrow,
  sidearrow,
  message1,
  message2,
  message3,
  message4,
  message5,
  message6,
  chatprofile,
} from "./assets";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";

import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {

    },
    sDashboard_container: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },

    submitbutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      marginLeft: "10px",
      color: "#fff",
      textTransform: "none",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    bookbutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      margin: "0 auto",
      color: "#fff",
      textTransform: "none",
      fontSize: 16,
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },



    text: {
      position: "absolute",
      width: "900px",
      height: "168px",
      left: "442px",
      top: "600px",
    },

    NextClass: {
      // position: "absolute",
      width: "300px",
      height: "346px",
      borderRadius: "20px",
      marginleft: "226px",
      marginBottom: "20px"
      // left: "245px",
      // top: "1237px",        
    },
    title: {
      fontSize: "26",
      fontWeight: 600,
    },
    time: {
      fontSize: "26",
      fontWeight: 600,
    },
    joinwebinar: {
      fontSize: "34",
      fontWeight: 600,
    },
    oneononeclasss: {
      fontSize: "26",
      fontWeight: 600,
      color: "#fff",
      marginLeft: "15px"
    },
    thirdcard: {
      width: "300px",
      height: "410px",
      backgroundColor: "#14292D",
      borderRadius: "20px",
      marginleft: "226px",
      padding: "0px"
    },
    firstcard: {
      //  height: "365px",
      width: "300px",
      marginleft: "123px",
      top: "0px",
      borderRadius: "20px",
      backgroundColor: "#F7F7F7",
      marginBottom: "20px"

    },

    UpdatePicture: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      marginLeft: "350px",
      color: "#fff",
      marginTop: "20px",
      textTransform: "none",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    backgroundupdate: {
      position: "absolute",
      width: "800px",
      height: "140px",
      // left: "584px",
      top: "249px",
      background: " #F0D17C",
      // mix-blend-mode: "normal",
      opacity: "0.11",
      borderRadius: "20px",
      border: "2px solid green"

    },
    UpdateInfo: {
      position: "absolute",
      width: "800px",
      height: "auto",
      left: "434px",
      top: "493px",
      background: "#F7F7F7",
      borderRadius: "20px",
      padding: 30,
      // border: "1px solid red"
    },
    updateprofilebutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      // marginLeft: "350px",
      color: "#fff",
      marginTop: "20px",
      textTransform: "none",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    sendmesssgaebutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      // marginLeft: "350px",
      color: "#fff",
      // marginTop: "20px",
      textTransform: "none",
      height: 45,
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    dash_left_c: {
      flex: 2,
      // width: 200,
      // marginLeft: "12vw",
      // marginTop: "1.9vw"
    },
    sDashboard_cc: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    // dash_left_chat:{
    //   width: 290, 
    //   height: 79, 
    //   backgroundColor: "#FF0000", 
    //   padding: 20,
    //   marginLeft: "20px", 
    //   // marginTop: "30px"
    // },
    chat_massgae: {
      width: 290,
      height: 79,
      backgroundColor: "#F7F7F7",
      padding: 20,
      marginLeft: "20px",
      marginTop: "10px"
    },
    chat_massage_Massage: {
      marginLeft: 20,
      marginTop: 20
    }

  })
// Customizable Area End

import MessagesController, { Props } from "./MessagesController.web";
import { Formik } from "formik";
import { borderRadius } from "react-select/src/theme";
import moment from "moment";

class Messages extends MessagesController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start'
      <div className={classes.root}>
        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location}/>
        
        <div className={classes.sDashboard_container}>
          <div className={classes.dash_left_c}>
            <DashNav active="Messages" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              onClick={() => { console.log("Next Class") }}
              styles={{ marginBottom: '1.5vw' }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: '1.5vw' }}
              onClick={() => { }}
            />
          </div>
          <div className={classes.sDashboard_cc}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <p style={{ fontFamily: "Inter", fontSize: "25px", fontWeight: 500, marginTop: "-5px" }}>
                  Messages
                </p>
                <Divider style={{width:'884px'}} />
              </div>

              <Grid container style={{ display: "flex" }}>
                <Grid item xs={4} style={{ marginTop: "15px" }}>
                <div style={{ overflow: "scroll", height: 684 }}>
                    <div>
                      <div style={{ display: "flex", marginTop: "2vw", backgroundColor: "#FF0000", padding: "12px" }}>
                        <img src={message1} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, color: "#FFFFFF", }}>
                            <Typography>Name</Typography>
                            <Typography>12:48PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, color: "#FFFFFF", marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5vw", backgroundColor: "#F7F7F7", padding: "12px" }}>
                        <img src={message2} alt="" style={{ width: "71", height: "71" }} />
                        <div>
                          <div style={{ display: "flex", justifyContent: 'space-between', marginLeft: 20, }}>
                            <Typography>Name</Typography>
                            <Typography>9:30PM</Typography>
                          </div>
                          <Typography style={{ marginLeft: 20, marginTop: 20, fontSize: "11px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ marginTop: "15px" }}>
                  <div style={{ width: 600, height: 800, backgroundColor: "#FFFFFF", marginTop: 30, }}>
                    <div style={{ display: "flex", marginLeft: 20, }}>
                      <img src={chatprofile} alt="" style={{ width: "58", height: "58", }} />
                      <div style={{ marginLeft: 20 }}>
                        <div>
                          <Typography style={{ color: "#333333", fontSize: 22, fontWeight: 550 }}>Name</Typography>
                        </div>
                        <div>
                          <Typography>Online</Typography>
                        </div>
                      </div>
                    </div>
                    <hr style={{ color: "#ECECF1", border: "1px solid #ECECF1", width: "571px" }}></hr>

                    <div style={{ overflow: "scroll", height: 578 }}>
                      <div style={{ marginLeft: "250", padding: 15 }}>
                        <Typography>Today</Typography>
                      </div>
                      <div style={{ backgroundColor: "#F9EFD5", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 205, }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                        <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F9EFD5", width: 500, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 58, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                      </div>

                      <div style={{ marginLeft: "250", padding: 15 }}>
                        <Typography>April 3, 2022</Typography>
                      </div>
                      <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                        <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F9EFD5", width: 250, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 306, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor</Typography>
                        <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                        <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                        <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F9EFD5", width: 250, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 306, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor</Typography>
                        <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F7F7F7", width: 350, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 34, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet</Typography>
                        <Typography style={{ color: "#474747" }}>10:53 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F9EFD5", width: 250, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 306, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor</Typography>
                        <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F9EFD5", width: 500, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 58, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                      </div>

                      <div style={{ backgroundColor: "#F9EFD5", width: 500, height: 30, borderRadius: 15, display: "flex", padding: 10, justifyContent: "space-between", marginLeft: 58, marginTop: 10 }}>
                        <Typography style={{ color: "#474747" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Typography>
                        <Typography style={{ color: "#474747" }}>10:58 AM</Typography>
                      </div>
                    </div>

                    <div>
                      <div style={{ backgroundColor: "#F0D17C", display :'flex', padding: 20,  marginTop: 30 }}>
                        <TextField style={{ backgroundColor: "#FFFFFF", width: 247 }} id="outlined-basic" placeholder="Type a message" variant="outlined" />
                        <AttachFileIcon style={{marginTop:'18px',marginRight:'15px',marginLeft:'14px'}} />
                        <Button className={classes.sendmesssgaebutton} variant="contained" style={{marginTop:'5px'}} >
                          Send Message
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid item xs={1} />
          {/* </Grid> */}

        </div>
          <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location}/>
      </div>


      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
export default withStyles(styles, { withTheme: true })(Messages);


