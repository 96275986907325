import React from "react";
import PopularCoursesScreen from "./Components/PopularCoursesScreen.web";
import PopularCoursesController, { Props } from "./PopularCoursesController.web";

class PopularCourses extends PopularCoursesController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.state.PCTopPopularData.length > 0 ? (
          <PopularCoursesScreen
            bgColor={this.props.bgColor}
            PCTopPopularData={this.state.PCTopPopularData}
            PClistPopularData={this.state.PClistPopularData}
            PCnavigateToCoursesDetails={this.PCnavigateToCoursesDetails}
            PChandleNavToCoursesList={this.PChandleNavToCoursesList}
          />
        ) : null}
      </div>
    );
  }
}

export default PopularCourses;
