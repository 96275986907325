import React from "react";
import Grid from "@material-ui/core/Grid";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Divider } from "react-native-elements";
import CourseList2Controller, { Props } from "./CourseList2Controller.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import AddAttributesToArray from "../../dashboard/src/components/AddAttributesToArray.web";
import CourseListPagination from "./Components/CourseListPagination.web";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    sDashboard_container: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    dash_left_c: {
      flex: 2,
    },
    sDashboard_cc: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    prl_input_left: {
      position: "relative",
      width: "18vw",
      marginRight: "2em",
    },
    prl_input_lable: {
      display: "flex",
      fontSize: "0.9em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      marginBottom: "0.7em",
      color: "rgba(51, 51, 51, 1)",
    },
    prl_input_dd: {
      position: "relative",
    },
    prl_input_dd_s: {
      width: "100%",
      height: "3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border: "1px solid #c7c7c7",
      paddingLeft: "1.5em",
      fontSize: "0.8em",
      fontFamily: "Inter, sans-serif",
      borderRadius: "0.5em",
    },
    prl_input_arrow: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "2em",
      height: "90%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      marginTop: "0.2em",
      marginRight: "0.2em",
      pointerEvents: "none",
    },
  });

export class CourseList extends CourseList2Controller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.sDashboard_container}>
          <div className={classes.dash_left_c}>
            <DashNav active="Courses" styles={{ marginBottom: "1.5vw" }} navigation={this.props.navigation} />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              onClick={() => {
                console.log("Next Class");
              }}
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
              onClick={() => {}}
            />
          </div>
          <div className={classes.sDashboard_cc}>
            <div>
              <p
                style={{ fontFamily: "Inter, sans-serif", fontSize: "25px", fontWeight: 500, margin: "10px" }}
              >
                Courses
              </p>
              <Divider />
            </div>
            <Grid style={{ justifyContent: "space-between", marginTop: "10px" }}>
              <CourseListPagination
                navigateToCoursesDetails={this.navigateToCoursesDetails}
                coursesList={AddAttributesToArray(this.state.coursesList_CL)}
                totPage={this.state.totalPage_CL}
                currPage={this.state.currentPage_CL}
                handleFilterByChange={this.handleFilterByChange}
                handleFilterByPriceChange={this.handleFilterByPriceChange}
                handleFilterByStartEndDateChange={this.handleFilterByStartEndDateChange}
                navigation={this.props.navigation}
                enableFilters={true}
                CSLselectFilterBy={this.state.selectFilterBy_CL}
                CSLselectFilterByPrice={this.state.selectFilterByPrice_CL}
                CSLstartDate={this.state.startDate_CL}
                CSLendDate={this.state.endDate_CL}
                pageFrom="CourseList"
                fetchCourseListLoading={this.state.fetchCourseListLoading_CL}
              />
            </Grid>
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CourseList);
