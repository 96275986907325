import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";

import ContractInfoController from "./ContractInfoController.web";
import "./ContractInfo.css";
import "./JobDetail.css";
import { createStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    ContractRejctroot: {
      // marginTop:"3%",
      // marginBottom:"3%"
    },
   
    ContractRejected_title: {
      fontFamily: "Inter, sans-serif",
      fontSize: "25px",
      fontWeight: 500,
      margin: "10px",
    },
    Button_style: {
      color: "#FFFFFF",
      fontFamily: "Inter, sans-serif",
      backgroundColor: "#FF0000",
      borderRadius: "50px",
      width: "12vw",
      height: "2.8vw",
      fontSize: "0.9vw",
      textTransform: "none",
      fontWeight: 700,
    },
    Button_style_ViewContract: {
      marginLeft: "10px",
      fontFamily: "Inter, sans-serif",
      backgroundColor: "rgb(240, 210, 124)",
      borderRadius: "50px",
      width: "12vw",
      height: "2.8vw",
      fontSize: "0.9vw",
      textTransform: "none",
      fontWeight: 700,
    },
    C_Reject_Container: {
      marginTop: "3%",
      marginBottom: "3%"
    },
    View_contract_link: {
      color: "black",
      textDecoration: "none"
    },
    C_Reject_Container1: {
      background: "#f7f7f7",
      padding: "40px",
      borderRadius: "20px",
      justifyContent: "center",
    },
    C_Reject_Container_grid: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      alignItems: "center",
    },
    C_Reject_send_massage: {
      color: "#fff",
      textDecoration: "none"
    },
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
  });


export default class Contactinfo extends ContractInfoController {
render() {
const renderPaymentTable =()=>{
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ background: "#14292e", padding: "10px" }} className="tabel-head">
          <TableRow>
            <TableCell align="inherit">Payment No</TableCell>
            <TableCell align="inherit">Payment</TableCell>
            <TableCell align="inherit">Minestones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {this.props.contractDetails?.data[0]?.attributes?.contract_payments.map((each:any,index:any) => (
            <TableRow
            >
              <TableCell align="inherit">{`PN${index+1}`}</TableCell>
              <TableCell align="inherit">{each.amount}</TableCell>
              <TableCell align="inherit">{each.payment_status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
    return (
      
      <div className="conatct-info">
        <Typography variant="h5" style={{ fontSize: "20px", fontWeight: "bold" }}>
          Contact Info
        </Typography>
            <>
              <Paper
                style={{
                  marginTop: "20px",
                  padding: "30px",
                  background: "#f7f7f7",
                  marginBottom: "30px",
                  borderRadius: "15px",
                }}
              >
                <Box
                  style={{
                    borderBottom: "1px solid lightgrey",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid container direction="row" alignItems="center" style={{ marginBottom: "20px" }}>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography variant="subtitle1" className="cont-title">
                        Class Timings
                      </Typography>
                      <Typography variant="subtitle2" className="cont-subtitle">
                       {this.props.contractDetails?.data[0]?.attributes?.class_slot}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography variant="subtitle1" className="cont-title">
                        Contract Type
                      </Typography>{" "}
                      <Typography variant="subtitle2" className="cont-subtitle">
                      {this.props.contractDetails?.data[0]?.attributes?.contract_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography variant="subtitle1" className="cont-title">
                        Subject
                      </Typography>
                      <Typography variant="subtitle2" className="cont-subtitle">
                        {this.props.subject}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <Typography variant="subtitle1" className="cont-title">
                        Hired on
                      </Typography>
                      <Typography variant="subtitle2" className="cont-subtitle">
                        15-08-2022
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px", marginBottom: "25px" }}
                  >
                    Payment Info
                  </Typography>
                  <Grid container direction="row" style={{ marginTop: "10px", marginBottom: "20px" }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" className="cont-title">
                        Payment Condition
                      </Typography>
                      <Typography variant="subtitle2" className="cont-subtitle">
                      {this.props.contractDetails?.data[0]?.attributes?.payment_condition}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" className="cont-title">
                        Distribution Method
                      </Typography>{" "}
                      <Typography variant="subtitle2" className="cont-subtitle">
                      {this.props.contractDetails?.data[0]?.attributes?.distribution_method}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={{ marginTop: "10px" }}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: "18px", fontWeight: "bold", marginTop: "25px", marginBottom: "25px" }}
                  >
                    Payment Structure
                  </Typography>
                  <Grid container direction="row" style={{ marginTop: "10px", marginBottom: "20px" }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle1" className="cont-title">
                        Total Classes
                      </Typography>
                      <Typography variant="subtitle2" className="cont-subtitle">
                      {this.props.contractDetails?.data[0]?.attributes?.total_classes}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle1" className="cont-title">
                        No of Payments
                      </Typography>{" "}
                      <Typography variant="subtitle2" className="cont-subtitle">
                      {this.props.contractDetails?.data[0]?.attributes?.no_of_payments}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle1" className="cont-title">
                        Currency
                      </Typography>{" "}
                      <Typography variant="subtitle2" className="cont-subtitle">
                      {this.props.contractDetails?.data[0]?.attributes?.currency}
                      </Typography>
                    </Grid>
                  </Grid>
                    {renderPaymentTable()}

              {this.props.contractDetails?.data[0]?.attributes?.status === "Pending" &&
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "flex-start",
                      padding: "20px",
                    }}
                  >
                    <Button
                      data-test-id-Accepted={"ContractAccepted"}
                      className="submit"
                      disableElevation
                      variant="contained"
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Inter, sans-serif",
                        backgroundColor: "#FF0000",
                        borderRadius: "50px",
                        width: "12vw",
                        height: "2.8vw",
                        fontSize: "0.9vw",
                        textTransform: "none",
                        fontWeight: 700,
                      }}
                      size="medium"
                       onClick={() => this.handleAcceptContract(this.props.contractDetails?.data[0]?.id)}
                    >
                      Accept Contract
                    </Button>
                    <Button
                      data-test-id-Rejected = {"ContractRejected"}
                      className="submit"
                      disableElevation
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        fontFamily: "Inter, sans-serif",
                        backgroundColor: "rgb(240, 210, 124)",
                        borderRadius: "50px",
                        width: "12vw",
                        height: "2.8vw",
                        fontSize: "0.9vw",
                        textTransform: "none",
                        fontWeight: 700,
                      }}
                      size="medium"
                      onClick={() => this.handleRejectContract(this.props.contractDetails?.data[0]?.id)}
                    >
                      Reject Contract
                    </Button>
                  </Box>
               }
               {
               ( this.props.contractDetails?.data[0]?.attributes?.status === "accepted" || 
               this.props.contractDetails?.data[0]?.attributes?.status === "rejected") &&
                <Button
                className="submit"
                onClick={() => this.props.navigation.navigate("JobListing")}
                disableElevation
                variant="contained"
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Inter, sans-serif",
                  backgroundColor: "#FF0000",
                  borderRadius: "50px",
                 padding:"0em 2em",
                  fontSize: "0.9vw",
                  textTransform: "none",
                  fontWeight: 700,
                  marginTop: "20px",
                }}
              >
                  Back to job Listings
              </Button>
               }






                </Box>
              </Paper>
            </>  
       
      </div>
    );
  }
}





