import * as React from "react";
import ReactPlayer from "react-player";
import { withStyles } from "@material-ui/core/styles";
import ReactPlayerController, { ReactPlayerTestProps } from "./ReactPlayerController.web";
import VideoStyles from "./Styles.web";
import VideoControls from "./VideoControls.web";
//---//

export class ReactPlayerTest extends ReactPlayerController {
  constructor(props: ReactPlayerTestProps) {
    super(props);
  }
  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;
    const urll = this.props.urll;
    return (
      <>
        <div>
          <div className="flex w-full justify-center">
            <div className="aspect-video h-60 bg-gray-100">
              <div
                className="VideoContainerC"
                id="temp"
                ref={(ref: any) => {
                  this.rrf = ref;
                }}
              >
                <div className="VPCD-Video">
                  <ReactPlayer
                    style={{ ...this.props.styles }}
                    ref={this.ref}
                    className="react-player"
                    width="100%"
                    height="100%"
                    url={urll}
                    pip={pip}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    onReady={async () => {
                      console.log("onReady");
                      await this.setState({ buffering: false });
                      console.log(this.state.buffering);
                    }}
                    onStart={() => console.log("onStart")}
                    onPlay={this.handlePlay}
                    onEnablePIP={this.handleEnablePIP}
                    onDisablePIP={this.handleDisablePIP}
                    onPause={this.handlePause}
                    onBuffer={async () => {
                      console.log("onBuffer");
                      await this.setState({ buffering: true });
                      console.log(this.state.buffering);
                    }}
                    onPlaybackRateChange={this.handleOnPlaybackRateChange}
                    onSeek={(e) => console.log("onSeek", e)}
                    onEnded={this.handleEnded}
                    onError={(e) => console.log("onError", e)}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration}
                  />
                  <VideoControls
                    settingsMouseLeave={
                      this.settingsMouseLeave
                    }
                    handlePlayPause={
                      this.handlePlayPause
                    }
                    handleSeekMouseDown={
                      this.handleSeekMouseDown
                    }
                    handleSeekChange={
                      this.handleSeekChange
                    }
                    handleSeekMouseUp={
                      this.handleSeekMouseUp
                    }
                    handleToggleMuted={
                      this.handleToggleMuted
                    }
                    handleSetPlaybackRate={
                      this.handleSetPlaybackRate
                    }
                    handleClickFullscreen={
                      this.handleClickFullscreen
                    }
                    buffering={
                      this.state.buffering
                    }
                    playing={
                      this.state.playing
                    }
                    muted={
                      this.state.muted
                    }
                    settingActive={
                      this.state.settingActive
                    }
                    duration={
                      this.state.duration
                    }
                    played={
                      this.state.played
                    }
                    loaded={
                      this.state.loaded
                    }
                    playbackRate={
                      this.state.playbackRate
                    }
                    mode="cd"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(VideoStyles, { withTheme: true })(ReactPlayerTest);
