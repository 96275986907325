import React, { Component } from "react";

// Customizable Area Start

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ViewSubmissionListImg, girllaptop } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
// import { Link } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

// import CustomCheckBox from "../../../components/src/CustomCheckBox";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { orangeViewIcon } from "./assets";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // marginTop:"3%",
      // marginBottom:"3%"
    },
    sDashboard_container: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    dash_left_c: {
      flex: 2,
    },
    sDashboard_cc: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },

    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
    pageTitle: {
      color: "#14292d",
      fontFamily: "Inter",
      fontSize: "2.2em",
      fontWeight: 600,
      marginTop: "-0.2em",
      marginBottom: "0.3em",
    },
    pageDescription: {
      marginBottom: "2em",
      marginTop: "1em",
      color: "#181617",
      fontFamily: "Inter",
      fontSize: "1.2em",
      fontWeight: 400,
    },
    timingHeadings: {
      color: "#ff0000",
      fontFamily: "Inter",
      fontSize: "1.5em",
      fontWeight: 600,
      // marginLeft:"1em"
      // width:"50%"
    },
    timingValues: {
      color: " #333333",
      fontFamily: "Inter",
      fontSize: "1em",
      fontWeight: 500,
      // width:"50%",
      // marginLeft:"2em"
    },
    tableContainerStyle: {
      // maxHeight: "33em",
      marginBottom: "2em",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "auto",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
        marginTop: "4vw",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    tableBodysStyle: {
      "&::-webkit-scrollbar": {
        width: "4px",
        height: "auto",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    tableHeadStyle: {
      color: "#ffffff",
      fontSize: "0.85em",
      fontWeight: 600,
      "&.MuiTableCell-stickyHeader": {
        backgroundColor: "#14292d",
        backgroundBlendMode: "normal",
        color: "#ffffff",
        fontSize: "0.85em",
        fontWeight: 600,
      },
      "&.MuiTableCell-root": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    tableBodyStyle: {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: "0.85em",
      fontWeight: 400,
    },
    tableStatusStyle: {
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: "0.85em",
      fontWeight: 400,
      width: "100%",
    },
    downloadcV: {
      color: "#dd8f1e",
      fontFamily: "Inter",
      fontSize: "0.85em",
      fontWeight: 700,
      paddingLeft: 0,
      paddingRight: 0,
    },
    statusContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    accepted: {
      color: "#6f924a",
      fontFamily: "Inter",
      fontSize: "0.9em",
      fontWeight: 700,
      marginRight: "1em",
    },
    rejected: {
      color: "#ff0000",
      fontFamily: "Inter",
      fontSize: "0.9em",
      fontWeight: 700,
      marginRight: "1em",
    },
    visibility: {
      width: "1.5em",
      objectFit: "cover",
      marginRight: "1em",
    },
    removeFromListBtn: {
      background: "#f0d17c",
      fontFamily: "Inter",
      fontSize: "0.9em",
      fontWeight: 600,
      // padding: "0em 2em",
      borderRadius: "25px",
      textTransform: "none",
      marginRight: "1em",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    rejectBtn: {
      background: "#f0d17c",
      fontFamily: "Inter",
      fontSize: "0.9em",
      fontWeight: 600,
      borderRadius: "25px",
      textTransform: "none",
      // padding: "0.2em 3em",
      marginRight: "1em",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    sendMessageBtn: {
      background: "#ff0000",
      fontFamily: "Inter",
      fontSize: "0.9em",
      fontWeight: 500,
      // padding: "0em 4em",
      borderRadius: "25px",
      color: "#ffffff",
      textTransform: "none",
      marginRight: "1em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    acceptBtn: {
      background: "#ff0000",
      fontFamily: "Inter",
      fontSize: "0.9em",
      fontWeight: 500,
      borderRadius: "25px",
      color: "#ffffff",
      textTransform: "none",
      // padding: "0.2em 3em",
      marginRight: "1em",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    circularLoader: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },
  });

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End

import { Divider } from "react-native-elements";
import Avatar from "@material-ui/core/Avatar";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import ViewSubmissionListController, { Props } from "./ViewSubmissionListController.web";

export class ViewSubmissionList extends ViewSubmissionListController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        <div className={classes.root}>
          <TopNavigation
            navigation={this.props.navigation}
            history={this.props.history}
            location={this.props.location}
          />

          <div className={classes.sDashboard_container}>
            <div className={classes.dash_left_c}>
              <DashNav
                active="Classes&Teachers"
                styles={{ marginBottom: "1.5vw" }}
                navigation={this.props.navigation}
              />
              <NextClassNotification
                timings="10:00 AM - 11:00 PM"
                title="Introduction to foundation of digital marketing and e-commerce"
                subtitle=" Lorem Ipsum is simply dummy text of the printing"
                onClick={() => {
                  console.log("Next Class");
                }}
                styles={{ marginBottom: "1.5vw" }}
              />
              <DashAdvartiseCard
                title="One on One classes with Teachers"
                subtitle="Lorem Ipsum is simply dummy text of the printing"
                styles={{ marginBottom: "1.5vw" }}
                onClick={() => {}}
              />
            </div>
            <div className={classes.sDashboard_cc}>
              {!this.state.subjectDetailsLoading ? (
                <div>
                  <div>
                    <p className={classes.pageTitle}>{this.state.subjectDetails?.subject}</p>
                    <Divider />
                  </div>

                  <Typography variant="subtitle1" className={classes.pageDescription}>
                    {this.state.subjectDetails?.description}
                  </Typography>

                  <div style={{ height: "26vw", position: "relative" }}>
                    <div
                      style={{
                        position: "relative",
                        flex: 1,
                        height: "100%",
                        float: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "25vw",
                          position: "absolute",
                          borderRadius: "14px",
                          padding: "1.5em 0em 1.5em 4em",
                          boxShadow: "-1px 5px 30px rgba(0,0,0,0.15)",
                          zIndex: 10000,
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "50%", marginTop: "1em" }}>
                            <p className={classes.timingHeadings}>Duration</p>
                            <p className={classes.timingValues}>{this.state.subjectDetails?.duration}</p>
                          </div>
                          <div style={{ width: "50%", marginTop: "1em" }}>
                            <p className={classes.timingHeadings}>Qualification</p>
                            <p className={classes.timingValues}>{this.state.subjectDetails?.qualification}</p>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "50%", marginTop: "1em" }}>
                            <p className={classes.timingHeadings}>Experience</p>
                            <p className={classes.timingValues}>{this.state.subjectDetails?.experience}</p>
                          </div>
                          <div style={{ width: "50%", marginTop: "1em" }}>
                            <p className={classes.timingHeadings}>Start Date</p>
                            <p className={classes.timingValues}>{this.state.subjectDetails?.start_date}</p>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "50%", marginTop: "1em" }}>
                            <p className={classes.timingHeadings}>Schedule</p>
                            <p className={classes.timingValues}>{this.state.subjectDetails?.schedule}</p>
                          </div>
                          <div style={{ width: "50%", marginTop: "1em" }}>
                            {/* <p style={{ color: "#f00" }}>Schedule</p>
                        <p>5 days/week</p> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ float: "right", position: "absolute", right: 0 }}>
                      <img
                        alt=""
                        src={ViewSubmissionListImg}
                        style={{
                          width: "35vw",
                          height: "26vw",
                          borderRadius: "14px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.subjectSubmissionList?.length > 0 && (
                <div
                  style={{
                    marginTop: "1em",
                  }}
                >
                  <Typography
                    style={{
                      marginBottom: "1em",
                      // marginTop: "2em",
                      fontFamily: "Inter",
                      fontSize: "1.7em",
                      fontWeight: 500,
                    }}
                  >
                    Submissions
                  </Typography>
                  {/* table */}

                  <div
                    className={classes.tableContainerStyle}
                    style={{
                      overflow: "auto",
                      boxShadow: "0px 5px 20px rgba(0,0,0,0.05)",
                    }}
                  >
                    <Table style={{ width: "100%", borderRadius: "6px", overflow: "hidden" }}>
                      <TableHead>
                        <TableRow
                          style={{
                            color: "white",
                            height: "3.5em",
                            paddingRight: "5px",
                            display: "flex",
                            backgroundColor: "#14292d",
                          }}
                        >
                          <TableCell
                            className={classes.tableHeadStyle}
                            style={{
                              width: "10%",
                            }}
                          ></TableCell>
                          <TableCell
                            className={classes.tableHeadStyle}
                            style={{
                              width: "15%",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            className={classes.tableHeadStyle}
                            style={{
                              width: "15%",
                            }}
                          >
                            Experience
                          </TableCell>
                          <TableCell
                            className={classes.tableHeadStyle}
                            style={{
                              width: "15%",
                            }}
                          >
                            CV
                          </TableCell>
                          <TableCell
                            className={classes.tableHeadStyle}
                            style={{
                              width: "45%",
                            }}
                          >
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                    {
                      <div
                        style={{
                          overflow: "auto",
                          maxHeight: "28em",
                        }}
                        className={classes.tableBodysStyle}
                      >
                        <Table
                          style={{
                            tableLayout: "fixed",
                            width: "100%",
                          }}
                        >
                          <TableBody>
                            {this.state.subjectSubmissionList.map((eachObject: any) => {
                              return (
                                <TableRow key={eachObject?.id}>
                                  <TableCell
                                    className={classes.tableBodyStyle}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      // width: '10%',
                                    }}
                                  >
                                    <Avatar
                                      alt="girllaptop"
                                      src={girllaptop}
                                      style={{
                                        height: "2.5em",
                                        width: "2.5em",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBodyStyle}
                                    style={{
                                      width: "15%",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <span>
                                        {eachObject?.attributes?.first_name}{" "}
                                        {eachObject?.attributes?.last_name}
                                      </span>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableBodyStyle}
                                    style={{
                                      width: "15%",
                                    }}
                                  >
                                    {eachObject?.attributes?.total_experience}
                                  </TableCell>
                                  <TableCell
                                    className={classes.downloadcV}
                                    style={{
                                      width: "15%",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {eachObject?.attributes?.account?.data?.attributes?.academic?.data
                                      ?.attributes.cv ? (
                                      <div
                                        onClick={() => {
                                          console.log(
                                            "eeeeee",
                                            eachObject?.attributes?.account?.data?.attributes?.academic?.data
                                              ?.attributes
                                          );
                                          this.handleDownload(
                                            eachObject?.attributes?.account?.data?.attributes?.academic?.data
                                              ?.attributes
                                          );
                                        }}
                                      >
                                        Download CV
                                      </div>
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    className={classes.tableStatusStyle}
                                    style={{
                                      width: "45%",
                                    }}
                                  >
                                    {eachObject?.attributes?.status == "Under Review" ? (
                                      <div className={classes.statusContainer}>
                                        <Button
                                          variant="contained"
                                          className={classes.acceptBtn}
                                          // size="small"
                                          onClick={() => {
                                            this.putAcceptSubmission(eachObject?.id);
                                          }}
                                          data-testid="putAcceptSubmission"
                                        >
                                          Accept
                                        </Button>
                                        <Button
                                          variant="contained"
                                          className={classes.rejectBtn}
                                          // size="small"
                                          onClick={() => {
                                            this.putRejectSubmission(eachObject?.id);
                                          }}
                                          data-testid="putRejectSubmission"
                                        >
                                          Reject
                                        </Button>
                                        <img
                                          className={classes.visibility}
                                          src={orangeViewIcon}
                                          alt="orangeViewIcon"
                                        />
                                      </div>
                                    ) : null}
                                    {eachObject?.attributes?.status == "accepted" ? (
                                      <div className={classes.statusContainer}>
                                        <p className={classes.accepted}>Accepted</p>
                                        <Button
                                          variant="contained"
                                          className={classes.removeFromListBtn}
                                          // size="small"
                                          onClick={() => {
                                            this.handleAddContract(eachObject?.id);
                                          }}
                                        >
                                          Add Contract
                                        </Button>
                                        <Button
                                          variant="contained"
                                          className={classes.sendMessageBtn}
                                          // size="small"
                                        >
                                          Send a Message
                                        </Button>
                                        <img
                                          className={classes.visibility}
                                          src={orangeViewIcon}
                                          alt="orangeViewIcon"
                                        />
                                      </div>
                                    ) : null}
                                    {eachObject?.attributes?.status == "rejected" ? (
                                      <div className={classes.statusContainer}>
                                        <p className={classes.rejected}>Rejected</p>
                                        <Button
                                          variant="contained"
                                          className={classes.removeFromListBtn}
                                          // size="small"
                                          onClick={() => {
                                            this.removeFromTheList(eachObject?.id);
                                          }}
                                          data-testid="removeFromList"
                                        >
                                          Remove from list
                                        </Button>
                                        <img
                                          className={classes.visibility}
                                          src={orangeViewIcon}
                                          alt="orangeViewIcon"
                                        />
                                      </div>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    }
                  </div>
                </div>
              )}
              {this.state.subjectSubmissionListLoading && (
                <div className={classes.circularLoader} style={{ marginLeft: "2em" }}>
                  <CircularProgress />
                </div>
              )}

              {this.state.subjectSubmissionList?.message == "No Submissions." && (
                <div>
                  <p className={classes.pageDescription} style={{ textAlign: "center" }}>
                    {this.state.subjectSubmissionList.message}
                  </p>
                </div>
              )}
            </div>
          </div>
          <BottomNavigation
            navigation={this.props.navigation}
            history={this.props.history}
            location={this.props.location}
          />
        </div>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(ViewSubmissionList);

// Customizable Area End
