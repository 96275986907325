import React, { RefObject } from "react";
import TeachersSettingsController, { Props } from "./TeachersSettingsController.web";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import DashSidebar1 from './Components/DashSidebar1.web';
import DashSidebar3 from './Components/DashSidebar3.web';
import DashSidebar2 from './Components/DashSidebar2.web';
import Typography from "@material-ui/core/Typography";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import { Divider } from "react-native-elements";

import Modal from "@material-ui/core/Modal";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";

export const styles: any = (theme: Theme) =>
({
  root: {},
  teachersettingsMainContainer: {
    paddingTop: "3vw",
    display: "flex",
    paddingLeft: "13vw",
    paddingRight: "13vw",
  },
  teachersettingsleftContainer: {
    flex: 2,
  },
  teachersettingsRightContainer: {
    width: "100%",
    marginLeft: "2vw",
    flex: 7.5,
  },
  settingsTitle: {
    color: "#14292d",
    fontFamily: "Inter, sans-serif",
    fontSize: "2em",
    fontWeight: 600,
    letterSpacing: "0.125px"

  },
  sucessPopUp: {
    position: "fixed",
    backgroundColor: "#ffffff",
    width: "29vw",
    height: "16vw",
    borderRadius: "20px",
    padding: "10px 40px 50px 40px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      width: "700px",
      height: "545px"
    },
  },

  updateBtn: {
    borderRadius: "100px",
    backgroundColor: "#ff0000",
    width: "30em",
    color: "#fff",
    marginTop: "32px",
    textTransform: "none",
    marginLeft: "25px",
    '&:hover': {
      backgroundColor: '#ff0000'
    },
    [theme.breakpoints.down("xs")]: {
      width: "1em",
    },
  },

  backgroundForupdate: {
    width: "100%",
    height: "180px",
    top: "210px",
    backgroundColor: "#F7F7F7",
    mixblendmode: "normal",
    borderRadius: "20px",
    marginTop: "2em",
    padding: "0.5em 0px",
  },
  settingsUpdatedMsg: {
    color: "#14292d",
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    marginBottom: "1em",
  },
  teacherTableContainerStyle: {
    maxHeight: 310,
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "auto"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px #dcdcdc",
      borderRadius: "10px",
      marginTop: "4vw",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(20, 41, 45, 0.3)",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(20, 41, 45, 0.3)"
    }
  },
  teacherTableHeadStyle: {
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#14292d",
      backgroundBlendMode: "normal",
    }
  },
  teacherNoHistory: {
    width: "200%",
    textAlign: "center",
    padding: "30px",
  },
  toggleSwitch: {
    "&.MuiSwitch-colorSecondary.Mui-checked": {
      color: "#ff0000",
    }
  }

});
// Customizable Area End
export class TeachersSettings extends TeachersSettingsController {
  inputRef: RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
  }

  render() {
    const { classes } = this.props;
    const { teacherpaymentDetails } = this.state
    const paymentHistory = teacherpaymentDetails.length <= 0 ?
      (<div className={classes.teacherNoHistory}><span>No Payment History</span></div>) :
      (<TableBody>
        {teacherpaymentDetails.map((eachObje: any) => {
          return (
            <TableRow key={eachObje?.id}>
              <TableCell component="th" scope="row" align="center">
                {moment(`${eachObje?.attributes.updated_at}`).format("DD-MM-YYYY")}
              </TableCell>
              <TableCell align="center" >{eachObje?.attributes?.course?.data?.attributes?.name}</TableCell>
              <TableCell align="center" >Rs.{eachObje?.attributes?.course?.data?.attributes?.course_cost}</TableCell>
              <TableCell align="center" >{eachObje?.attributes?.razorpay_order_id}</TableCell>
            </TableRow>
          )
        }
        )}
      </TableBody>)

    const content = this.state.teacherPaymentStatusLoader ?
      (<div className={classes.teacherNoHistory} style={{ marginLeft: "2em" }}><CircularProgress /></div>)
      : paymentHistory;

    return (
      <div>
        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
        <div className={classes.teachersettingsMainContainer}>
          <div className={classes.teachersettingsleftContainer}>
            <DashSidebar1 active="Settings" styles={{ marginBottom: '1.5vw' }} navigation={this.props.navigation} />
            <DashSidebar2
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              // subtitle=" Lorem Ipsum is simply dummy text of the printing"
              // onClick={() => { console.log("Next Class") }}
              styles={{ marginBottom: '1.5vw' }}
            />
            <DashSidebar3
              title="One on One classes with Students"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: '1.5vw' }}
              onClick={() => { }}
            />
          </div>
          <div className={classes.teachersettingsRightContainer}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <Typography className={classes.settingsTitle}>Settings</Typography>
              </div>
              <Divider style={{ marginTop: "2em", marginBottom: "2em" }}></Divider>
              <Typography style={{ fontSize: 24, fontWeight: 600, marginTop: 25, }}>Update Settings</Typography>

              <div className={classes.backgroundForupdate}>
                <Typography style={{ color: "#333333", marginLeft: "30px", marginTop: "30px", fontSize: 16, fontWeight: 550 }}>Notifications</Typography>
                <div style={{ display: "flex", marginLeft: "21px" }}>
                  <Switch
                    data-testId="emailCheckBox"
                    checked={this.state.teacherEmailCheckBox}
                    onChange={() => this.handleTeacherEmailCheckBox()}
                    name="teacherEmailCheckBox"
                    className={classes.toggleSwitch}
                  />
                  <Typography style={{ margin: 6 }}>Email</Typography>
                  <Switch
                    data-testId="phoneCheckBox"
                    checked={this.state.teacherphoneCheckBox}
                    onChange={() => this.handleTeacherPhoneCheckBox()}
                    name="teacherphoneCheckBox"
                    className={classes.toggleSwitch}
                  />
                  <Typography style={{ margin: 6 }}>Phone</Typography>

                </div>
                <div>
                  <Button data-testId="updateBtn" className={classes.updateBtn} variant="contained" onClick={() => this.handleTeacherUpdate()} disabled={!this.state.notificationChange}>
                    Update
                  </Button>
                </div>

              </div>
            </div>


            <div style={{ marginLeft: "20px" }}>
              <div>
                <Typography style={{ marginTop: 25, fontSize: 22, fontWeight: 550, marginBottom: 20 }}>Billing History</Typography>
              </div>
              <div>
                <TableContainer className={classes.teacherTableContainerStyle} component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow style={{ color: "white" }}>
                        <TableCell className={classes.teacherTableHeadStyle} align="center" style={{ color: "white", fontSize: 18 }}>Date</TableCell>
                        <TableCell className={classes.teacherTableHeadStyle} align="center" style={{ color: "white", fontSize: 18 }}>Details</TableCell>
                        <TableCell className={classes.teacherTableHeadStyle} align="center" style={{ color: "white", fontSize: 18 }}>Amount</TableCell>
                        <TableCell className={classes.teacherTableHeadStyle} align="center" style={{ color: "white", fontSize: 18 }}>Order ID</TableCell>
                      </TableRow>
                    </TableHead>
                    {content}
                  </Table>
                </TableContainer>
              </div>
            </div>

          </div>
        </div>

        <Modal
          open={this.state.opensucessMsgModal}
          style={{
            position: "absolute",
            height: 545,
            width: 700,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.sucessPopUp}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", marginTop: "1em" }}>
              <div>
                <p className={classes.settingsUpdatedMsg}>{this.state.sucessMsg}</p>
              </div>
              <div>
                <Button
                  onClick={() => this.handlesucessfullyUploaded(false)}
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#ffffff",
                    width: "14vw",
                    height: "3vw",
                    border: "1px solid red",
                    textTransform: "none",
                    // marginTop: "10px",
                  }}
                  variant="outlined"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>

        </Modal>



        <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(TeachersSettings);