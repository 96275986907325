import React from 'react'


const AddAttributesToArray = (coursesList: any[]) => {
    const fArray: any[] = []
    coursesList.forEach((val) => {
        fArray.push(val.attributes)
    })
    return fArray
}
export default AddAttributesToArray