import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
  history: any;
  location: any;
  classes?: any;
  bgColor?: boolean;
}

export interface S {
  // Customizable Area Start
  PCpopularCourseList: any;
  PCTopPopularData: any;
  PClistPopularData: any;
  PCcategoriesList: any;
  PCtotalPage: number;
  PCcurrentPage: number;
  PCselectCategory: any;
  PCstartDate: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PopularCoursesController extends BlockComponent<Props, S, SS> {
  apiPopularCourseListCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      PCpopularCourseList: [],
      PCTopPopularData: [],
      PClistPopularData: [],
      PCcategoriesList: [],
      PCtotalPage: 1,
      PCcurrentPage: 1,
      PCselectCategory: null,
      PCstartDate: null,
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiPopularCourseListCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson) {
              console.log("PopularCourses_Response", responseJson);
              const TopPopularData = responseJson.slice(0, 2);
              const listPopularData = responseJson.slice(2, 6);
              await this.setState({ PCTopPopularData: TopPopularData });
              await this.setState({ PClistPopularData: listPopularData });
            }
          } else {
            console.log("ERR_PopularCourses_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  PCgetPopularCoursesList = () => {
    const PCheaders = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const getPopularCourse = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiPopularCourseListCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.popularCourseListApiEndpoint
    );

    getPopularCourse.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(PCheaders));
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
  };

  PCnavigateToCoursesDetails = (id: any) => {
    this.props.navigation.navigate("CoursesDetails", { courseId: id });
    localStorage.setItem("currentCourseId", id);
  };

  PChandleNavToCoursesList = () => {
    this.props.navigation.navigate("CoursesList", { page: 1 });
  };

  componentDidMount = async () => {
    this.PCgetPopularCoursesList();
  };
}
