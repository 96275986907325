import React from "react";

// Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  SafeAreaView,

  TouchableOpacity
} from "react-native";
import { Formik } from "formik";
import Scale, { verticalScale } from "../../../components/src/Scale";
import { imgPasswordVisible, eyeClose, backArrow } from "./assets";
import * as Yup from "yup";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  renderHeaderView = () => {
    return (
      <View style={{ flex: 1 }}>
        <View style={styles.headerChatView}>
          <TouchableOpacity onPress={this.goBack}>
            <Image style={styles.backArrowImage} source={backArrow} />
          </TouchableOpacity>
          <Text style={styles.newPasswordText}>New Password</Text>
          <View style={styles.blankView} />
        </View>
        <View style={styles.PasswordView}>
          <TextInput style={styles.textInputText}
            testID="NewPasswordTestID"
            placeholder="Enter New Password"
            placeholderTextColor="#b7b7b7"
            secureTextEntry={!this.state.checked}
            onChangeText={(text) => this.setState({ newPassword: text, newPasswordMessage: "" })}
            value={this.state.newPassword}
          />
          <TouchableOpacity onPress={this.checked}>
            <Image
              style={styles.visibleImage}
              source={this.state.checked ? imgPasswordVisible : eyeClose}
            />
          </TouchableOpacity>
        </View>
        {this.state.newPasswordMessage ?
          <Text style={styles.textDanger}>{this.state.newPasswordMessage}</Text>
          : null
        }
        <View style={styles.reEnterView}>
          <TextInput style={styles.textInputText}
            testID="ConfirmPasswordTestID"
            placeholder="Re-enter new Password"
            placeholderTextColor="#b7b7b7"
            secureTextEntry={!this.state.checkbox}
            onChangeText={(text) => this.setState({ confirmPassword: text, confirmPasswordMessage: "" })}
            value={this.state.confirmPassword}
          />
          <TouchableOpacity onPress={this.checkBox}>
            <Image
              style={styles.visibleImage}
              source={this.state.checkbox ? imgPasswordVisible : eyeClose}
            />
          </TouchableOpacity>
        </View>
        {this.state.confirmPasswordMessage ?
          <Text style={styles.textDanger}>{this.state.confirmPasswordMessage}</Text>
          : null
        }
        <TouchableOpacity
          style={styles.confimView}>
          <Text style={styles.confirmText}>Confirm</Text>
        </TouchableOpacity>
      </View>
    )
  }
  isChangePassword: boolean;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          {/* Customizable Area Start */}
          <SafeAreaView style={styles.mainContainer}>
            {this.renderHeaderView()}
          </SafeAreaView>
          {/* Customizable Area End */}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  mainContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
  },

  headerChatView: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: Scale(20),
    justifyContent: "space-between"
  },

  newPasswordView: {
    width: Scale(290),
    alignItems: "center",
  },

  newPasswordText: {
    fontSize: Scale(20),
    fontWeight: "bold",
    color: "#000"
  },

  blankView: {
    width: Scale(30)
  },

  textInputText: {
    width: Scale(310),
  },

  visibleImage: {
    height: Scale(24),
    width: Scale(24),
  },

  backArrowImage: {
    height: Scale(20),
    width: Scale(10),
    marginLeft: Scale(30)
  },

  PasswordView: {
    height: Scale(50),
    marginHorizontal: Scale(25),
    borderWidth: Scale(1.5),
    borderRadius: Scale(8),
    borderColor: "#b7b7b7",
    paddingHorizontal: Scale(10),
    flexDirection: "row",
    alignItems: "center",
    marginTop: Scale(40),

  },

  reEnterView: {
    height: Scale(50),
    marginHorizontal: Scale(25),
    borderWidth: Scale(1.5),
    borderRadius: Scale(8),
    borderColor: "#b7b7b7",
    paddingHorizontal: Scale(10),
    flexDirection: "row",
    alignItems: "center",
    marginTop: Scale(20),
  },

  confimView: {
    height: Scale(50),
    width: Scale(350),
    borderRadius: Scale(25),
    backgroundColor: "#fc0b1b",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: Scale(15),
    alignSelf: "center",
  },

  confirmText: {
    fontSize: Scale(19),
    fontWeight: "bold",
    color: "#ffffff"
  },

  textDanger: {
    color: "#dc3545",
    marginLeft: Scale(20),
    marginTop: verticalScale(5),
  }
});
// Customizable Area End
