import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  history: any;
  location: any;
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  };
}

export interface S {
  // Customizable Area Start
  qualification: string;
  collageName: string;
  specialization: string;
  courseInt: string;
  experience: string;
  availability: string;
  otherLang: string;
  file1: any;
  file2: any;
  errQualification: boolean;
  errCollage: boolean;
  errSpecial: boolean;
  errCourseInt: boolean;
  errExp: boolean;
  errAvailable: boolean;
  errLang: boolean;
  errOtherLang: boolean;
  errFile1: boolean;
  errFile2: boolean;
  errLangData: boolean;

  lQualification: any[];
  lInterest: any[];

  langArray: any;
  langEng: boolean;
  langHindi: boolean;
  langOther: boolean;
  allLang: string;
  formAcademmic: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherSignUpStep2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiQualificationListCallId: any;
  apiInterestListCallId: any;
  apiAddAcademicDeatailsCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      qualification: "",
      collageName: "",
      specialization: "",
      courseInt: "",
      experience: "",
      availability: "",
      otherLang: "",
      file1: undefined,
      file2: undefined,
      errQualification: false,
      errCollage: false,
      errSpecial: false,
      errCourseInt: false,
      errExp: false,
      errAvailable: false,
      errLang: false,
      errOtherLang: false,
      errFile1: false,
      errFile2: false,
      errLangData: false,
      lQualification: [],
      lInterest: [],
      langArray: [],
      langEng: false,
      langHindi: false,
      langOther: false,
      allLang: "",
      formAcademmic: new FormData(),
    };

    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiQualificationListCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("Qualification_Response", responseJson);
            await this.setState({ lQualification: responseJson });
          } else {
            console.log("ERR_Qualification_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.apiInterestListCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("Interest_Response", responseJson);
            this.setState({ lInterest: responseJson });
          } else {
            console.log("ERR_Interest_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
        } else if (apiRequestCallId === this.apiAddAcademicDeatailsCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("Academic_Response", responseJson);
            this.props.navigation.navigate("RegSuccess");
          } else {
            console.log("ERR_Academic_Response", responseJson);
            alert(JSON.stringify(responseJson.error));
          }
        }
      }
    }
    // Customizable Area End
  }

  errStyle = {
    backgroundColor: "#fcecec",
    borderColor: "#fb7b7c",
  };

  getQualification = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiQualificationListCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qualificationAPiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getInterest = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiInterestListCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.interestAPiEndPoint
    );

    getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  componentWillMount = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    await this.getQualification();
    await this.getInterest();
  };

  async handleQualiChange(e: any) {
    await this.setState({ qualification: e.target.value });
    if (this.state.qualification.length <= 0 || this.state.qualification == "Select") {
      await this.setState({ errQualification: true });
    } else {
      await this.setState({ errQualification: false });
    }
  }
  async handleCollageChange(e: any) {
    await this.setState({ collageName: e.target.value });
    if (this.state.collageName.length <= 0) {
      await this.setState({ errCollage: true });
    } else {
      await this.setState({ errCollage: false });
    }
  }
  async handleSpecialChange(e: any) {
    await this.setState({ specialization: e.target.value });
    if (this.state.specialization.length <= 0) {
      await this.setState({ errSpecial: true });
    } else {
      await this.setState({ errSpecial: false });
    }
  }
  async handleIntCourseChange(e: any) {
    await this.setState({ courseInt: e.target.value });
    if (this.state.courseInt.length <= 0 || this.state.courseInt == "Select") {
      await this.setState({ errCourseInt: true });
    } else {
      await this.setState({ errCourseInt: false });
    }
  }
  async handleExperienceChange(e: any) {
    await this.setState({ experience: e.target.value });
    if (this.state.experience.length <= 0 || this.state.experience == "Select") {
      await this.setState({ errExp: true });
    } else {
      await this.setState({ errExp: false });
    }
  }
  async handleAvailabilityChange(e: any) {
    await this.setState({ availability: e.target.value });
    if (this.state.availability.length <= 0 || this.state.availability == "Select") {
      await this.setState({ errAvailable: true });
    } else {
      await this.setState({ errAvailable: false });
    }
  }
  async handleOtherLangChange(e: any) {
    await this.setState({ otherLang: e.target.value });
  }
  async handleFile1(e: any) {
    const files = e.target.files[0];
    this.setState({ file1: files });
    this.setState({ errFile1: false });
  }
  async handleFile2(e: any) {
    const files = e.target.files[0];
    this.setState({ file2: files });
    this.setState({ errFile2: false });
  }

  handleEngLang = async (e: any) => {
    await this.setState({ langEng: !this.state.langEng });
    if (this.state.langEng === true) {
      const arr = [...this.state.langArray, "english"];
      await this.setState({ langArray: arr });
    } else if (this.state.langEng === false) {
      await this.setState({
        langArray: this.state.langArray.filter((item: any) => {
          return item !== "english";
        }),
      });
    }
  };

  handleHindiLang = async (e: any) => {
    await this.setState({ langHindi: !this.state.langHindi });
    if (this.state.langHindi === true) {
      const arr = [...this.state.langArray, "hindi"];
      await this.setState({ langArray: arr });
    } else if (this.state.langHindi === false) {
      await this.setState({
        langArray: this.state.langArray.filter((item: any) => {
          return item !== "hindi";
        }),
      });
    }
  };

  handleOtherLang = async (e: any) => {
    await this.setState({ langOther: !this.state.langOther });
  };

  async handleSubmit() {
    if (this.state.langArray.length <= 0 && this.state.otherLang.length <= 0) {
      await this.setState({ errLangData: true });
    } else {
      await this.setState({ errLangData: false });
    }

    if (this.state.qualification.length <= 0 || this.state.qualification == "Select") {
      await this.setState({ errQualification: true });
    } else {
      await this.setState({ errQualification: false });
    }

    if (this.state.collageName.length <= 0) {
      await this.setState({ errCollage: true });
    } else {
      await this.setState({ errCollage: false });
    }
    if (this.state.specialization.length <= 0) {
      await this.setState({ errSpecial: true });
    } else {
      await this.setState({ errSpecial: false });
    }

    if (this.state.courseInt.length <= 0 || this.state.courseInt == "Select") {
      await this.setState({ errCourseInt: true });
    } else {
      await this.setState({ errCourseInt: false });
    }
    if (this.state.experience.length <= 0 || this.state.experience == "Select") {
      await this.setState({ errExp: true });
    } else {
      await this.setState({ errExp: false });
    }
    if (this.state.availability.length <= 0 || this.state.availability == "Select") {
      await this.setState({ errAvailable: true });
    } else {
      await this.setState({ errAvailable: false });
    }
    if (!this.state.file1) {
      await this.setState({ errFile1: true });
    } else {
      await this.setState({ errFile1: false });
    }
    if (!this.state.file2) {
      await this.setState({ errFile2: true });
    } else {
      await this.setState({ errFile2: false });
    }

    if (
      this.state.errQualification === false &&
      this.state.errCollage === false &&
      this.state.errSpecial === false &&
      this.state.errCourseInt === false &&
      this.state.errExp === false &&
      this.state.errAvailable === false &&
      this.state.errLang === false &&
      this.state.errOtherLang === false &&
      this.state.errFile1 === false &&
      this.state.errFile2 === false &&
      this.state.errLangData === false
    ) {
      console.log("Teacher Sign Up Step 2 Next");
      const UDetails: any = JSON.parse(localStorage.getItem("SignUpToken") || "{}");
      const token = UDetails;

      const header = {
        "Content-Type": configJSON.resetpasswordcontentType,
        token: token,
      };

      const formData = new FormData();
      await formData.append("qualification_id", this.state.qualification);
      await formData.append("college_name", this.state.collageName);
      await formData.append("specialization", this.state.specialization);
      await formData.append("interest_id", this.state.courseInt);
      await formData.append("total_experience", this.state.experience);
      await formData.append("availability", this.state.availability);
      await formData.append("known_languages", this.state.langArray);
      await formData.append("other_languages", this.state.otherLang);
      await formData.append("cv", this.state.file1);
      await formData.append("government_id", this.state.file2);

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiAddAcademicDeatailsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.academicAPiEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.signupAPiMethod);
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleLoginNav = () => {
    this.props.navigation.navigate("EmailLogin");
  };
}
