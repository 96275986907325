//@ts-nocheck
import React from 'react';

import ReactPaginate from 'react-paginate';
import { back,next } from '../../../../email-account-login/src/assets';

export default function PaginatedItems({pageCount,handlePagination}:any) {
 
    const handlePageClick = (event:any) => {
    handlePagination(event.selected)
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel={<img src={back} alt="next" className="page-img" />}
        nextLabel={<img src={next} alt="next" className="page-img" />}
        containerClassName={"pagination-C"}
        activeClassName={"active"}
      />
    </>
  );
}

