import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

import { Country, State, City } from "country-state-city";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  history: any;
  location: any;

  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  }
}

export interface S {
  signUpAs: string;
  fName: string;
  lName: string;
  date: string;
  cemail: string;
  mobile: string;
  gender: string;
  address: string;
  city: string;
  country: string;
  state: string;
  pass: string;
  cPass: string;
  mobileCode: string;
  errFName: boolean;
  errLName: boolean;
  errDate: boolean;
  errEmail: boolean;
  errPhone: boolean;
  errAddress: boolean;
  errCountry: boolean;
  errState: boolean;
  errPass: boolean;
  errCPass: boolean;
  errGender: boolean;
  errCity: boolean;
  errMaxFName: boolean;
  errMaxLName: boolean;
  errMaxPhone: boolean;
  errMaxAddress: boolean;

  lCountry: Object;

  listCountry: any[];
  listState: any[];
  listCity: any[];

  sCountry: string;
  sCountryCode: string;
  sState: string;
  sStateCode: string;
  sCity: string;
  sCityCode: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}


export default class SignUpController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start


  apiEmailWebSignUpCallId: any;
  apiCountryListCallId: any;
  errStyle: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      listCountry: [],
      listState: [],
      listCity: [],
      signUpAs: "learner",
      fName: "",
      lName: "",
      date: "",
      cemail: "",
      mobile: "",
      gender: "male",
      address: "",
      city: "",
      country: "",
      state: "",
      pass: "",
      cPass: "",
      mobileCode: "91",
      errFName: false,
      errLName: false,
      errDate: false,
      errEmail: false,
      errPhone: false,
      errAddress: false,
      errCountry: false,
      errState: false,
      errPass: false,
      errCPass: false,
      errGender: false,
      errCity: false,
      errMaxFName: false,
      errMaxLName: false,
      errMaxPhone: false,
      errMaxAddress: false,

      lCountry: {},


      sCountry: "",
      sCountryCode: "",
      sState: "",
      sStateCode: "",
      sCity: "",
      sCityCode: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.errStyle = {
      backgroundColor: "#fcecec",
      borderColor: "#fb7b7c",
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiEmailWebSignUpCallId) {
          if (!responseJson.errors && !responseJson.error) {
            if (responseJson.meta) {
              console.log("SignUp_Response", responseJson);
              responseJson.cType = this.state.signUpAs;

              await localStorage.setItem("userDetails", JSON.stringify(responseJson));
              await localStorage.setItem("SignUpToken", JSON.stringify(responseJson.meta.token));
              this.props.navigation?.navigate("OtpAuth");
            } else {
              this.parseApiErrorResponse(responseJson);
              console.log("No_Response", responseJson);
            }
          } else {
            console.log("ERR_Response", responseJson);
            if (responseJson.error) {
              alert(responseJson.error);
            }
            if (responseJson.errors[0].account) {
              if (
                responseJson.errors[0].account ==
                "Account already exists, Verification code has been sent on your phone number, Please verify your phone number number to activate your account"
              ) {
                await localStorage.setItem("SignUpToken", JSON.stringify(responseJson.data.meta.token));
                this.props.navigation?.navigate("OtpAuth");
              } else if (
                responseJson.errors[0].account == "Account already registerd with entered mobile number"
              ) {
                alert(responseJson.errors[0].account);
              }
            }
            if (responseJson.errors[0].full_phone_number) {
              alert(responseJson.errors[0].full_phone_number);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }


  /*********************** ---SIGIN UP--- *****************************/

  handleCTypeChange(e: any) {
    this.setState({ signUpAs: e.target.value });
  }

  componentWillMount = async () => {
    const country_list = Country.getAllCountries();
    await this.setState({ listCountry: country_list });
  };

  async handleFNameChange(e: any) {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      await this.setState({ fName: e.target.value });
    }
    if (this.state.fName.length > 15) {
      await this.setState({ errMaxFName: true });
    } else {
      await this.setState({ errMaxFName: false });
    }
  }

  async handleLNameChange(e: any) {
    const re = /^[A-Za-z]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      await this.setState({ lName: e.target.value });
    }
    if (this.state.lName.length > 15) {
      await this.setState({ errMaxLName: true });
    } else {
      await this.setState({ errMaxLName: false });
    }
  }

  async handleDateChange(e: any) {
    await this.setState({ date: e.target.value });
    let select_date = new Date(this.state.date);
    let today = new Date();
    let limitDate = moment().subtract(100, 'year').calendar();
    let limit = new Date(limitDate)
    today.setHours(0, 0, 0, 0);
    if (select_date > today || select_date < limit) {
      await this.setState({ errDate: true });
    } else {
      await this.setState({ errDate: false });
    }
  }

  async handleEmailChange(e: any) {
    await this.setState({ cemail: e.target.value });
    const VEmail = this.state.cemail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (this.state.cemail.length <= 0 || VEmail == null) {
      await this.setState({ errEmail: true });
    } else {
      await this.setState({ errEmail: false });
    }
  }

  async handleMobileChange(e: any) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      await this.setState({ mobile: e.target.value });
    }
    if (this.state.mobile.length > 15) {
      await this.setState({ errMaxPhone: true });
    } else {
      await this.setState({ errMaxPhone: false });
    }
  }

  async handleAddressChange(e: any) {
    await this.setState({ address: e.target.value });
    if (this.state.address.length > 30) {
      await this.setState({ errMaxAddress: true });
    } else {
      await this.setState({ errMaxAddress: false });
    }
  }

  async handleCityChange(e: any) {
    await this.setState({ sCity: e.target.value });
    if (this.state.sCity.length <= 0 || this.state.sCity == "Select City") {
      await this.setState({ errCity: true });
    } else {
      await this.setState({ errCity: false });
    }
  }

  async handlePassChange(e: any) {
    await this.setState({ pass: e.target.value });
    if (this.state.errCPass) {
      if (this.state.cPass == this.state.pass) {
        await this.setState({ errCPass: false });
      } else {
        await this.setState({ errCPass: true });
      }
    }
  }

  async handleCPassChange(e: any) {
    await this.setState({ cPass: e.target.value });
    if (this.state.cPass == this.state.pass) {
      await this.setState({ errCPass: false });
    } else {
      await this.setState({ errCPass: true });
    }
  }

  async handleGenderChange(e: any) {
    this.setState({ gender: e.target.value });
  }


  async handleCountryChange(e: any) {
    const value = e.target.value;
    if (value == "Select Country") {
      await this.setState({
        sCountry: "",
        sCountryCode: "",
      });
    } else {
      const filtered_country = this.state.listCountry[value];
      await this.setState({
        sCountry: filtered_country.name,
        sCountryCode: filtered_country.isoCode,
      });
    }

    const state_list = await State.getStatesOfCountry(this.state.sCountryCode);
    await this.setState({ listState: [] });
    await this.setState({ listState: state_list });
    await this.setState({ listCity: [] });

    if (this.state.sCountry.length <= 0 || this.state.sCountry == "Select Country") {
      await this.setState({ errCountry: true });
    } else {
      await this.setState({ errCountry: false });
    }
  }

  async handleStateChange(e: any) {
    const value = e.target.value;
    if (value == "Select State") {
      await this.setState({
        sState: "",
        sStateCode: "",
      });
    } else {
      const filtered_State = this.state.listState[value];
      await this.setState({
        sState: filtered_State.name,
        sStateCode: filtered_State.isoCode,
      });
    }
    const city_list = await City.getCitiesOfState(this.state.sCountryCode, this.state.sStateCode);
    await this.setState({ listCity: [] });
    await this.setState({ listCity: city_list });
    if (this.state.sState.length <= 0 || this.state.sState == "Select State") {
      await this.setState({ errState: true });
    } else {
      await this.setState({ errState: false });
    }
  }

  async handleMobileCodeChange(e: any) {
    await this.setState({ mobileCode: e.target.value });
  }

  async handleSubmit() {
    const VEmail = this.state.cemail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (this.state.fName.length <= 0) {
      await this.setState({ errFName: true });
    } else {
      await this.setState({ errFName: false });
    }
    if (this.state.fName.length > 15) {
      await this.setState({ errMaxFName: true });
    } else {
      await this.setState({ errMaxFName: false });
    }
    if (this.state.lName.length <= 0) {
      await this.setState({ errLName: true });
    } else {
      await this.setState({ errLName: false });
    }
    if (this.state.lName.length > 15) {
      await this.setState({ errMaxLName: true });
    } else {
      await this.setState({ errMaxLName: false });
    }
    let select_date = new Date(this.state.date);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (select_date > today) {
      await this.setState({ errDate: true });
    } else {
      await this.setState({ errDate: false });
    }
    if (this.state.cemail.length <= 0 || VEmail == null) {
      await this.setState({ errEmail: true });
    } else {
      await this.setState({ errEmail: false });
    }
    if (this.state.mobile.length <= 0) {
      await this.setState({ errPhone: true });
    } else {
      await this.setState({ errPhone: false });
    }
    if (this.state.mobile.length > 15) {
      await this.setState({ errMaxPhone: true });
    } else {
      await this.setState({ errMaxPhone: false });
    }
    if (this.state.address.length <= 0) {
      await this.setState({ errAddress: true });
    } else {
      await this.setState({ errAddress: false });
    }
    if (this.state.address.length > 30) {
      await this.setState({ errMaxAddress: true });
    } else {
      await this.setState({ errMaxAddress: false });
    }
    if (this.state.sCity.length <= 0) {
      await this.setState({ errCity: true });
    } else {
      await this.setState({ errCity: false });
    }
    if (this.state.sCountry.length <= 0 || this.state.sCountry == "Select Country") {
      await this.setState({ errCountry: true });
    } else {
      await this.setState({ errCountry: false });
    }
    if (this.state.sState.length <= 0 || this.state.sState == "Select State") {
      await this.setState({ errState: true });
    } else {
      await this.setState({ errState: false });
    }
    if (this.state.pass.length <= 0) {
      await this.setState({ errPass: true });
    } else {
      await this.setState({ errPass: false });
    }
    if (this.state.cPass.length <= 0) {
      await this.setState({ errCPass: true });
    } else {
      await this.setState({ errCPass: false });
    }
    if (this.state.cPass == this.state.pass && this.state.pass.length > 0) {
      await this.setState({ errCPass: false, errPass: false });
    } else {
      await this.setState({ errCPass: true, errPass: true });
    }

    if (
      this.state.errFName === false &&
      this.state.errLName === false &&
      this.state.errDate === false &&
      this.state.errEmail === false &&
      this.state.errPhone === false &&
      this.state.errAddress === false &&
      this.state.errCountry === false &&
      this.state.errState === false &&
      this.state.errPass === false &&
      this.state.errCPass === false &&
      this.state.errMaxFName === false &&
      this.state.errMaxLName === false &&
      this.state.errMaxPhone === false
    ) {
      /////////////////////////////////////////////////////////////////////////
      console.log("Sign Up Next");

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
      };

      const attrs = {
        first_name: this.state.fName,
        last_name: this.state.lName,
        email: this.state.cemail,
        full_phone_number: this.state.mobileCode + this.state.mobile,
        gender: this.state.gender,
        role: this.state.signUpAs,
        date_of_birth: this.state.date,
        country: this.state.sCountry,
        city: this.state.sCity,
        state: this.state.sState,
        address: this.state.address,
        password: this.state.pass,
        password_confirmation: this.state.cPass,
      };
      const userDetail = { ...attrs, phone: this.state.mobile };
      await localStorage.setItem("SignUpUserDetails", JSON.stringify(userDetail));

      const data = {
        type: "sms_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiEmailWebSignUpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.signupAPiEndPoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.signupAPiMethod);

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  handleLoginNavigate() {
    this.props.navigation?.navigate("EmailLogin");
  }

  // Customizable Area End
}

