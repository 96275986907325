import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  CSLcoursesList: any;
  CSLpopularCourseList: any;
  CSLcategoriesList: any;
  CSLtotalPage: number;
  CSLcurrentPage: number;
  CSLselectCategory: any;
  CSLselectFilterBy: any;
  CSLselectFilterByPrice: any;
  CSLstartDate: any;
  CSLendDate: any;
  CSLclearDateState: boolean;
  CSLis_loggedIn: boolean;
  CSLuserDetails: any;
  CSLfetchCourseListLoading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CoursesListController extends BlockComponent<Props, S, SS> {
  CSLapiCoursesListCallId: any;
  CSLapiPopularCoursesListCallId: any;
  CSLapiCategoriesCallId: any;
  CSLapiUserDetailsCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      CSLcoursesList: [],
      CSLpopularCourseList: [],
      CSLcategoriesList: [],
      CSLtotalPage: 1,
      CSLcurrentPage: 1,
      CSLselectCategory: null,
      CSLselectFilterBy: null,
      CSLselectFilterByPrice: null,
      CSLstartDate: null,
      CSLendDate: null,
      CSLclearDateState: false,
      CSLis_loggedIn: false,
      CSLuserDetails: [],
      CSLfetchCourseListLoading: true,
    };
    //Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const CSLapiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (CSLapiRequestCallId && responseJson) {
        if (CSLapiRequestCallId === this.CSLapiCoursesListCallId) {
          if (!responseJson.errors && !responseJson.error && !responseJson.message) {
            if (responseJson.data) {
              this.setState({ CSLfetchCourseListLoading: false })
              console.log("CoursesList_Response", responseJson);
              await this.setState({ CSLcoursesList: responseJson.data });
              await this.setState({ CSLcurrentPage: responseJson.page });
              await this.setState({ CSLtotalPage: responseJson.page_count });
              // await this.setState({ lQualification: responseJson });
              console.log("coursesList State", this.state.CSLcoursesList);
            }
          } else {
            if (responseJson.message) {
              this.setState({ CSLfetchCourseListLoading: false })
              console.log("CoursesList_no_Course_available_Response", responseJson);
              await this.setState({ CSLcoursesList: [] });
              await this.setState({ CSLcurrentPage: 1 });
              await this.setState({ CSLtotalPage: 1 });
            }
            console.log("ERR_CoursesList_Response", responseJson);
            // alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (CSLapiRequestCallId === this.CSLapiPopularCoursesListCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("PopularCourses_Response", responseJson);
            const selectPopularData = responseJson.slice(0, 4);
            await this.setState({ CSLpopularCourseList: selectPopularData });
            console.log("Popular List State", this.state.CSLpopularCourseList);
          } else {
            console.log("ERR_PopularCourses_Response", responseJson);
            alert(JSON.stringify(responseJson?.error));
          }
          this.parseApiErrorResponse(responseJson);
        } else if (CSLapiRequestCallId === this.CSLapiCategoriesCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("Categories_Response", responseJson);
            await this.setState({ CSLcategoriesList: responseJson.data });
            console.log("Categories List State", this.state.CSLcategoriesList);
          } else {
            console.log("ERR_Categories_Response", responseJson);
            alert(JSON.stringify(responseJson.errors[0][0]));
          }
          this.parseApiErrorResponse(responseJson);
        } else if (CSLapiRequestCallId === this.CSLapiUserDetailsCallId) {
          if (!responseJson.errors && !responseJson.error) {
            console.log("UserDetails_Response", responseJson);
            await this.setState({ CSLis_loggedIn: true });
            await this.setState({ CSLuserDetails: responseJson.data.attributes });
            console.log("is_loggedIn", this.state.CSLis_loggedIn);
          } else {
            console.log("ERR_UserDetails_Response", responseJson);
            // alert(JSON.stringify(responseJson.errors[0][0]));
            await this.setState({ CSLis_loggedIn: false });
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    // Customizable Area End
  }

  CSLgetUserDetails = async () => {
    const token = localStorage.getItem("loginT");
    const CSLheaders = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    const CSLrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.CSLapiUserDetailsCallId = CSLrequestMessage.messageId;

    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetailsApiEndpoint
    );

    CSLrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(CSLheaders));
    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(CSLrequestMessage.id, CSLrequestMessage);
  };

  CSLgetCousesList = (page: number, numberOfPages: number) => {
    this.setState({ CSLfetchCourseListLoading: true })
    // let token = localStorage.getItem("loginT");
    let token: any = localStorage.getItem("loginT");
    console.log("Token CourseList", token);
    token = token == null ? "" : token;
    const CSLStartDate = this.state.CSLstartDate == null ? "" : this.state.CSLstartDate;
    const CSLEndDate = this.state.CSLendDate == null ? "" : this.state.CSLendDate;
    const CSLFilterBy = this.state.CSLselectFilterBy == null ? "" : this.state.CSLselectFilterBy;
    const CSLselectFilterByPrice =
      this.state.CSLselectFilterByPrice == null ? "" : this.state.CSLselectFilterByPrice;
    const CSLbody = null;
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", this.state.CSLselectFilterBy, CSLFilterBy);
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", CSLselectFilterByPrice);
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", CSLStartDate);
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", CSLEndDate);
    const endPoint = `${configJSON.coursesListApiEndpoint}?per_page=${numberOfPages}&page=${page}&pricing=${CSLselectFilterByPrice}&filter=${CSLFilterBy}&start_date=${CSLStartDate}&end_date=${CSLEndDate}`;
    console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", endPoint);

    const CSLform = new FormData();
    CSLform.append("per_page", numberOfPages.toString());
    const CSLheader = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };
    const CSLrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.CSLapiCoursesListCallId = CSLrequestMessage.messageId;

    CSLrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(CSLheader));
    CSLrequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    CSLbody && CSLrequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), CSLbody);
    runEngine.sendMessage(CSLrequestMessage.id, CSLrequestMessage);
  };

  CSLgetPopularCoursesList = () => {
    let token: any = localStorage.getItem("loginT");
    console.log("Token CourseList", token);
    token = token == null ? "" : token;
    const CSLheaders = {
      "Content-Type": configJSON.dashboarContentType,
      token: token,
    };

    const CSLrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.CSLapiPopularCoursesListCallId = CSLrequestMessage.messageId;

    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.popularCourseListApiEndpoint
    );

    CSLrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(CSLheaders));
    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(CSLrequestMessage.id, CSLrequestMessage);
  };
  CSLgetCategories = () => {
    const CSLheaders = {
      "Content-Type": configJSON.dashboarContentType,
    };

    const CSLrequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.CSLapiCategoriesCallId = CSLrequestMessage.messageId;

    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoriesApiEndpoint
    );

    CSLrequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(CSLheaders));
    CSLrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(CSLrequestMessage.id, CSLrequestMessage);
  };

  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log("componentDidMount CoursesList");
    const pageParms = this.props.navigation.getParam("page");
    console.log("paramssss", pageParms, this.props.navigation.getParam("page"));

    let filter_by = await localStorage.getItem("filter_by");
    let filter_by_price = await localStorage.getItem("filter_by_price");
    let filter_by_start_date = await localStorage.getItem("filter_by_start_date");
    let filter_by_end_date = await localStorage.getItem("filter_filter_by_end_dateby");

    await this.setState({
      CSLselectFilterBy: filter_by == 'null' ? null : filter_by,
      CSLselectFilterByPrice: filter_by_price == 'null' ? null : filter_by_price,
      CSLstartDate: filter_by_start_date == 'null' ? null : filter_by_start_date,
      CSLendDate: filter_by_end_date == 'null' ? null : filter_by_end_date,
      CSLcurrentPage: pageParms,
    });
    await console.log(
      "filter",
      this.state.CSLselectFilterBy,
      this.state.CSLselectFilterByPrice,
      this.state.CSLstartDate,
      this.state.CSLendDate
    );

    console.log('filterType', typeof (filter_by), typeof (filter_by_price), typeof (filter_by_start_date), typeof (filter_by_end_date),)
    localStorage.removeItem('filter_by');
    localStorage.removeItem('filter_by_price');
    localStorage.removeItem('filter_by_start_date');
    localStorage.removeItem('filter_filter_by_end_dateby');

    this.CSLgetUserDetails();
    this.CSLgetCategories();
    this.CSLgetPopularCoursesList();
    this.CSLgetCousesList(this.state.CSLcurrentPage, 8);
  }

  handleFilterByChange = async (event: any) => {
    console.log(event.target.value);
    const val = event.target.value;
    if (val != "select") {
      console.log("val", val);
      await this.setState({ CSLselectFilterBy: val });
      this.CSLgetCousesList(1, 8);
      this.props.navigation.navigate("CoursesList", { page: 1 })
    } else if (val == "select") {
      await this.setState({ CSLselectFilterBy: null });
      this.CSLgetCousesList(1, 8);
      this.props.navigation.navigate("CoursesList", { page: 1 })
    }
  };
  handleFilterByPriceChange = async (event: any) => {
    console.log(event.target.value);
    const val = event.target.value;
    if (val != "select") {
      console.log("val", val);
      await this.setState({ CSLselectFilterByPrice: val });
      this.CSLgetCousesList(1, 8);
      this.props.navigation.navigate("CoursesList", { page: 1 })
    } else if (val == "select") {
      await this.setState({ CSLselectFilterByPrice: null });
      this.CSLgetCousesList(1, 8);
      this.props.navigation.navigate("CoursesList", { page: 1 })
    }
  };
  handleFilterByStartEndDateChange = async (startDate: any, endDate: any) => {
    await this.setState({ CSLstartDate: startDate });
    await this.setState({ CSLendDate: endDate });
    console.log("fstartDate", startDate);
    console.log("fendDate", endDate);
    console.log("StartDate", this.state.CSLstartDate);
    console.log("EndDate", this.state.CSLendDate);
    await this.CSLgetCousesList(1, 8);
    this.props.navigation.navigate("CoursesList", { page: 1 })
  };

  navigateToCoursesDetails = (id: any) => {
    localStorage.setItem("currentCourseId", id);
    if (this.state.CSLis_loggedIn) {
      this.props.navigation.navigate("CourseDetails", { courseId: id });
    } else {
      this.props.navigation.navigate("CoursesDetails", { courseId: id });
    }
  };
}
