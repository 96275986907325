import React, { Component } from "react";
import Banner from "../../email-account-registration/src/Components/Banner.web";
import CoursesDetailsController, { Props } from "./CoursesDetailsController.web";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
  twomembers,
  calender,
  freetag,
  girllaptop,
  book,
  boylaptop,
  boyphone,
  InstructorPic,
  syllabusIcon,
  // tickmark
} from "./assets";
import ReactPlayerTest from "./ReactPlayerTest";
import moment from "moment";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
// import VideoPlayer from "react-native-video-player";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    CD_C: {
      paddingLeft: "17%",
      paddingRight: "17%",
      backgroundColor: "#f7f7f7",
    },
    CD_title: {
      fontSize: "1.8vw",
      fontWeight: 600,
      paddingTop: "1.5em",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
    },
    CD_subtitle: {
      marginTop: "20px",
      fontSize: "0.8vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      lineHeight: 2,
    },
    CD_Instruc_c: {
      paddingTop: "4em",
      background: "linear-gradient(#f7f7f7 60%, #ffffff 0%)",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "17%",
      paddingRight: "17%",
    },
    CD_Instruc_D_1: {
      width: "100%",
      // height: "8vw",
      borderRadius: "1em",
      border: "2px solid #ff0000",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      marginRight: "1.5vw",
      padding: "1.2vw",
    },
    CD_Instruc_D_2: {
      width: "100%",
      // height: "8vw",
      borderRadius: "1em",
      border: "2px solid #ff0000",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      marginRight: "1.5vw",
      padding: "1.2vw",
    },
    CD_Instruc_D_3: {
      width: "100%",
      // height: "8vw",
      borderRadius: "1em",
      border: "2px solid #ff0000",
      background: "#ffffff",
      backgroundBlendMode: "normal",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      padding: "1.2vw",
    },
    CD_Instruct_title: {
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.5vw",
    },
    CD_Instruct_pic: {
      width: "5vw",
    },
    CD_Instruct_txt_c: {
      marginTop: "0.5vw",
      display: "flex",
      overflow: "visible",
    },
    CD_Instruct_txt_c_c: {
      marginLeft: "1.3vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    CD_Instruct_txt_name: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1vw",
    },
    CD_Instruct_txt_Quali: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.8vw",
    },
    CD_Instruct_txt_btn: {
      width: "8vw",
      height: "1.7vw",
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.65vw",
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    CD_Instruct_2_txt_c: {
      display: "flex",
      marginTop: "0.5vw",
    },
    CD_Instruct_2_txt_1: {
      lineHeight: "1.5vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "0.85vw",
    },
    CD_Instruct_2_txt_2_c: {
      marginLeft: "2.8vw",
    },
    CD_Instruct_2_txt_2: {
      lineHeight: "1.5vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85vw",
    },
    CD_Instruct_3_c: {
      width: "85%",
      maxHeight: "6em",
      marginTop: "0.5vw",
      overflow: "scroll",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "auto",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    CD_Instruct_3: {
      lineHeight: "1.5vw",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.8vw",
    },
    CD_SEC_3: {
      paddingLeft: "17%",
      paddingRight: "17%",
      marginTop: "5vw",
    },
    CD_SEC_3_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.8vw",
    },
    CD_syll_c: {
      marginTop: "2vw",
    },
    CD_syll_item_c: {
      display: "flex",
      alignItems: "center",
      marginTop: "0.6vw",
    },
    CD_syll_item_img: {
      width: "0.7vw",
    },
    CD_syll_item_bold: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "0.9vw",
      marginLeft: "0.7vw",
    },
    CD_syll_item_txt: {
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.9vw",
      marginLeft: "0.7vw",
    },
    CD_SEC_4: {
      paddingLeft: "17%",
      paddingRight: "17%",
      marginTop: "3vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "4vw",
    },
    CD_SEC_4_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.5vw",
    },
    CD_SEC_4_btn: {
      marginTop: "1.5vw",
      width: "10vw",
      height: "2.5vw",
      borderRadius: "10em",
      backgroundColor: "#ff0000",
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "0.9vw",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    CD_SEC_5: {
      paddingLeft: "17%",
      paddingRight: "17%",
      marginTop: "3vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "linear-gradient(#f7f7f7 75%, #14292d 0%)",
    },
    CD_SEC_5_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.5vw",
      marginTop: "4vw",
    },
    CD_SEC_5_card_c: {
      marginTop: "3vw",
      display: "flex",
      backgroundColor: "#ff0000",
      paddingTop: "2vw",
      paddingBottom: "2vw",
      paddingLeft: "3vw",
      paddingRight: "3vw",
      borderRadius: "1.2em",
      width: "auto",
      // height: "7vw",
      alignItems: "center",
    },
    CD_SEC_5_card_1: {
      display: "flex",
      flexDirection: "column",
    },
    CD_SEC_5_card_1_1: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.1vw",
      // lineHeight: "1vw",
    },
    CD_SEC_5_card_1_2: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.6vw",
      // lineHeight: "1vw",
    },
    CD_SEC_5_card_btn: {
      backgroundColor: "#f0d17c",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "0.9vw",
      marginLeft: "3vw",
      width: "11vw",
      height: "2.8vw",
      borderRadius: "10em",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    CD_SEC_6: {
      paddingLeft: "17%",
      paddingRight: "17%",
      padding: "3vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
    },
    CD_SEC_6_btn: {
      backgroundColor: "#ffffff",
      borderColor: "#ff4452",
      borderWidth: "2px",
      borderRadius: "10em",
      color: "#181617",
      fontSize: "0.8vw",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      textTransform: "none",
      // padding: "0.7vw",
      width: "13vw",
      height: "2.8vw",
      "&:hover": {
        backgroundColor: "#ffffff",
      },
    },
    CD_SEC_7: {
      paddingLeft: "13%",
      paddingRight: "13%",
      padding: "3vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f7f7f7",
    },
    CD_SEC_6_title: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "2.2vw",
    },
    CD_SEC_7_btn: {
      marginTop: "5vw",
      backgroundColor: "#f0d17c",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.8vw",
      fontWeight: 700,
      borderRadius: "10em",
      textTransform: "none",
      width: "15vw",
      height: "2.5vw",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    RelatedCourseCard: {
      width: 287,
      height: "100%",
    },
    CD_SEC_5_Vid: {
      width: "66vw",
      marginTop: "4vw",
      marginBottom: "5vw",
      height: "32vw",
      border: "0px",
      borderRadius: "0.5vw",
    },
  });
export class CoursesDetails extends CoursesDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    const url = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
    return (
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />

        <div className={classes.CD_C}>
          <Typography className={classes.CD_title}>{this.state.courseDetails.name}</Typography>
          <div>
            <Typography className={classes.CD_subtitle}>
              {this.state.courseDetails.course_description}
            </Typography>
          </div>
        </div>

        {/* ************************************* */}

        <div className={classes.CD_Instruc_c}>
          <div className={classes.CD_Instruc_D_1}>
            <Typography className={classes.CD_Instruct_title}>Instructor</Typography>
            <div className={classes.CD_Instruct_txt_c}>
              <img className={classes.CD_Instruct_pic} src={InstructorPic} alt="InstructorPic" />
              <div className={classes.CD_Instruct_txt_c_c}>
                <Typography className={classes.CD_Instruct_txt_name}>
                  {this.state.instructure?.first_name}&nbsp;{this.state.instructure?.last_name}
                </Typography>
                <Typography className={classes.CD_Instruct_txt_Quali}>
                  {this.state.instructure?.qualification}
                </Typography>
                <Button variant="contained" className={classes.CD_Instruct_txt_btn}>
                  Send Message
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.CD_Instruc_D_2}>
            <Typography className={classes.CD_Instruct_title}>Timings</Typography>
            <div className={classes.CD_Instruct_2_txt_c}>
              <div className={classes.CD_Instruct_2_txt_1_c}>
                <Typography className={classes.CD_Instruct_2_txt_1}>Starts on:</Typography>
                <Typography className={classes.CD_Instruct_2_txt_1}>Length:</Typography>
                <Typography className={classes.CD_Instruct_2_txt_1}>Duration:</Typography>
              </div>
              <div className={classes.CD_Instruct_2_txt_2_c}>
                <Typography className={classes.CD_Instruct_2_txt_2}>
                  {moment(this.state.courseDetails?.start_date).format("DD/MM/YYYY")}
                </Typography>
                <Typography className={classes.CD_Instruct_2_txt_2}>
                  {this.state.courseDetails.length}&nbsp;weeks
                </Typography>
                <Typography className={classes.CD_Instruct_2_txt_2}>
                  {moment(this.state.courseDetails?.course_duration, "HH:mm").format("h")}&nbsp;hrs p/w
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.CD_Instruc_D_3}>
            <Typography className={classes.CD_Instruct_title}>Features</Typography>
            <div className={classes.CD_Instruct_3_c}>
              {this.state.featuress.map((val: any, key: any) => {
                return (
                  <Typography key={key} className={classes.CD_Instruct_3}>
                    - {val}
                  </Typography>
                );
              })}
            </div>
          </div>
        </div>

        {/* ************************************** */}
        <div className={classes.CD_SEC_3}>
          <Typography className={classes.CD_SEC_3_title}>Syllabus</Typography>
          <div className={classes.CD_syll_c}>
            {this.state.courseDetails?.course_module?.length > 0 &&
              this.state.courseDetails.course_module.map((val: any, key: any) => {
                return (
                  <div className={classes.CD_syll_item_c} key={val.module_id}>
                    <img className={classes.CD_syll_item_img} src={syllabusIcon} alt="syllabusIcon" />
                    <Typography className={classes.CD_syll_item_bold}>{val.serial_number} -</Typography>
                    <Typography className={classes.CD_syll_item_txt}>{val.module}</Typography>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={classes.CD_SEC_4}>
          <Typography className={classes.CD_SEC_4_title}>Login to view more details</Typography>
          <Button
            className={classes.CD_SEC_4_btn}
            variant="contained"
            onClick={this.navigateToLogin}
            data-testId="CoursesDetailsNavigateToLogin"
          >
            Login
          </Button>
        </div>
        <div className={classes.CD_SEC_5}>
          <Typography className={classes.CD_SEC_5_title}>
            You will be able to watch the video once the payment has been done
          </Typography>
          <div className={classes.CD_SEC_5_card_c}>
            <div className={classes.CD_SEC_5_card_1}>
              <Typography className={classes.CD_SEC_5_card_1_1}>Make Payment</Typography>
              <Typography className={classes.CD_SEC_5_card_1_2}>
                Rs {this.state.courseDetails?.course_cost}/-
              </Typography>
            </div>
            <Button className={classes.CD_SEC_5_card_btn} onClick={this.navigateToLogin}>
              Pay Now
            </Button>
          </div>

          <div
            style={{
              marginTop: "4vw",
              marginBottom: "4vw",
              borderRadius: "1vw",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {this.state.previewAvailable ? (
              <ReactPlayerTest urll={this.state.previewUrl} handleEnd={false} />
            ) : null}
          </div>
        </div>
        <div className={classes.CD_SEC_6}>
          <Button
            variant="outlined"
            className={classes.CD_SEC_6_btn}
            onClick={this.navigateToCoursesList}
          >
            Back to Course Listing
          </Button>
        </div>
        {this.state.similarCourseList.length > 0 && (
          <div className={classes.CD_SEC_7}>
            <Typography className={classes.CD_SEC_6_title}>Similar Courses Available</Typography>
            <div style={{ paddingTop: "1vw" }}>
              <Grid container justifyContent="center" spacing={3} style={{ marginTop: 30 }}>
                {this.state.similarCourseList.map((val: any, key: any) => {
                  const value = val;
                  console.log("similarSimilar");
                  return (
                    <Grid item>
                      <Card className={classes.RelatedCourseCard}>
                        <CardMedia>
                          <img
                            style={{ height: "auto", width: "100%" }}
                            src={val.image_url ? val.image_url : girllaptop}
                            alt=""
                          />
                        </CardMedia>
                        <CardContent>
                          <Typography
                            style={{
                              color: "#333333",
                              fontFamily: "Inter, sans-serif",
                              fontSize: "1.3vw",
                              fontWeight: 600,
                              lineHeight: "1.7em",
                            }}
                          >
                            {value?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            component="p"
                            style={{
                              lineHeight: "2em",
                              color: "#666666",
                              fontFamily: "Inter, sans-serif",
                              fontSize: "0.8vw",
                              fontWeight: 400,
                              maxLines: 2,
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              minHeight: "4em",
                            }}
                          >
                            {value?.course_description}
                          </Typography>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "0.5vw",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "0.9vw",
                                  objectFit: "contain",
                                  marginRight: "0.2vw",
                                }}
                                src={twomembers}
                                alt=""
                              />
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: "0.75vw",
                                  color: "#333333",
                                  fontFamily: "Inter, sans-serif",
                                }}
                              >
                                &nbsp;{value?.selected_count}&nbsp;
                              </Typography>
                              <Typography
                                style={{
                                  color: "#f0d17c",
                                  marginRight: "0.4vw",
                                }}
                              >
                                |
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "0.9vw",
                                  objectFit: "contain",
                                  marginRight: "0.2vw",
                                }}
                                src={freetag}
                                alt=""
                              />
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: "0.75vw",
                                  color: "#333333",
                                  fontFamily: "Inter, sans-serif",
                                }}
                              >
                                &nbsp;{value?.course_cost == null ? "Free" : value?.course_cost}&nbsp;
                              </Typography>
                              <Typography
                                style={{
                                  color: "#f0d17c",
                                  marginRight: "0.4vw",
                                }}
                              >
                                |
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  width: "0.9vw",
                                  objectFit: "contain",
                                  marginRight: "0.2vw",
                                }}
                                src={calender}
                                alt=""
                              />
                              <Typography
                                style={{
                                  fontWeight: 600,
                                  fontSize: "0.75vw",
                                  color: "#333333",
                                  fontFamily: "Inter, sans-serif",
                                }}
                              >
                                &nbsp;{moment(`${value?.start_date}`).format("DD/MM/YYYY")}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>

                        <CardActions>
                          <div
                            style={{
                              display: "flex",
                              columnGap: 20,
                              marginBottom: 20,
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                background: "#f00",
                                color: "#fff",
                                textTransform: "none",
                                borderRadius: 100,
                                width: 115,
                              }}
                              size="small"
                              onClick={() => {
                                this.navigateToCoursesDetails(value.id);
                              }}
                              data-testId="CoursesDetailsSimilarCoursesViewCourse"
                            >
                              View Course
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                background: "#f0d17c",
                                textTransform: "none",
                                borderRadius: 100,
                                fontWeight: 550,
                                width: 115,
                              }}
                              size="small"
                            >
                              Buy Now
                            </Button>
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <Button className={classes.CD_SEC_7_btn}>View All Courses</Button>
          </div>
        )}
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CoursesDetails);
