import React from "react";

// Customizable Area Start
import moment from "moment";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";

import CourseDetailsController, { Props } from "./CourseDetailsController.web";
import {
  boygirl,
  redsidearrow,
  professor,
  notes,
  hours,
  Exercises,
  Reading,
  videos,
  tickmark,
} from "./assets";

import RazorpayCmp from "./Razorpay.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    add_contract_container: {},
    dash_left_c: {
      width: "14vw",
      marginLeft: "13vw",
      marginTop: 30,
    },
    add_contract_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
      width: "59vw",
    },
    add_contract_card_cd: {
      borderRadius: "1vw",
      padding: "2vw",
      marginTop: "2vw",
      backgroundColor: "#f7f7f7",
      marginBottom: "2vw  ",
    },
    add_contract_card_row_2_title: {
      margin: "0px",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1vw",
    },
    add_contract_card_row_4_btn2_editcontract: {
      backgroundColor: "#FF0000",
      borderRadius: "10em",
      textTransform: "none",
      paddingLeft: "1vw",
      paddingRight: "1vw",
      color: "white",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "1.2vw",
      width: 200,
      "&:hover": {
        backgroundColor: "#FF0000",
      },
    },
    banner: {
      position: "relative",
    },
    bannerTitleContainer: {
      position: "absolute",
      bottom: "45px",
      left: "24%",
      transform: "translate(-24%, -9%)",
      fontSize: 41,
      color: "#fff",
    },
    bannerTitle: {
      fontSize: 41,
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: 36,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
      },
    },
    BannerLMBtn: {
      marginTop: 25,
      borderRadius: 100,
      backgroundColor: "#f0d17c",
      width: 180,
      height: 50,
      textTransform: "none",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 20,
        height: 30,
        width: 130,
        fontSize: 14,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 15,
        height: 30,
        width: 100,
        fontSize: 12,
      },
      [theme.breakpoints.down("xs")]: {
        height: 20,
        width: 90,
        fontSize: 10,
      },
    },
    sendmessage: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      marginLeft: "5vw",
      color: "#fff",
      textTransform: "none",
      fontSize: "1em",
      "&:hover": {
        backgroundColor: "#FF0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    cardButton: {
      borderRadius: 100,
      textTransform: "none",
      fontWeight: 700,
      fontSize: 16,
      width: 230,
      height: 50,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        width: 130,
        height: 30,
      },
    },
    cancelbutton: {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "230px",
      fontWeight: 700,
      fontSize: 16,
      border: "1px solid red",
      textTransform: "none",
      height: "50px",
      marginLeft: "20px",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    Syllabus: {
      width: "63vw",
      left: "579px",
      borderRadius: "10px",
      backgroundColor: "#F7F7F7",
    },
    contents: {
      width: "58vw",
      marginLeft: "20px",
      borderRadius: "20px",
    },
    heading: {
      fontSize: 20,
      fontWeight: 600,
      marginLeft: "20px",
    },
    add_contract_card_c: {
      boxShadow: "0px 5px 30px rgba(0,0,0,0.05)",
      borderRadius: "1vw",
      padding: "2vw",
      marginTop: "2vw",
      width: "32vw",
      marginBottom: "2vw",
      display: "flex",
      flexDirection: "row",
    },
    featureSec: {
      height: "6vw",
      overflowY: "scroll",
      marginTop: "0px",
      marginRight: "1em",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "100%",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    cardStyling: {
      "&.MuiCardContent-root": {
        padding: "0px",
      },
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "0px",
      },
    },
    CD_Instruct_2_txt_c: {
      display: "flex",
      marginTop: "0.5vw",
      marginLeft: "1.5em",
    },
    CD_Instruct_2_txt_1: {
      lineHeight: "1.8em",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1.1em",
    },
    CD_Instruct_2_txt_2_c: {
      marginLeft: "2.8vw",
    },
    CD_Instruct_2_txt_2: {
      lineHeight: "1.8em",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "1.1em",
    },
    paynowstartlearning: {
      fontSize: "2vw",
      fontWeight: 550,
      paddingTop: "6vw",
    },
    paynowbutton: {
      marginTop: "3vw",
      borderRadius: 100,
      backgroundColor: "#f0d17c",
      width: "13vw",
      height: "4vw",
      textTransform: "none",
      fontWeight: 700,
      marginLeft: "3vw",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    backtocourselisting: {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "22vw",
      fontWeight: 600,
      fontSize: "1.5vw",
      border: "1px solid red",
      textTransform: "none",
      height: "4vw",
      marginTop: "3vw",
      marginBottom: "3vw",
      boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },
    PayNowpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "40vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px",
      },
    },

    PayFailurepopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "20vw",
      height: "20vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px",
      },
    },

    tickicon: {
      height: "50px",
      width: "50px",
      justifyContent: "center",
      marginTop: "8px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "80px",
      },
    },
    startexploring: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "14vw",
      height: "3vw",
      marginLeft: "10px",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    loadingPayment: {
      textAlign: "center",
      width: "100%",
      marginBottom: "5em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },

    bckListBtnContainer: {
      display: "flex",
      justifyContent: "center",
    },
  });
// Customizable Area End
export class CourseDetails extends CourseDetailsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    // const {razorpay_order_id}    = this.state.verifyCallData.attributes
    // const {course} = this.state.verifyCallData.attributes

    console.log(this.state.data, "this.state.data in course");
    //  console.log(this.state.data.attributes?.is_paid,"this.state.data in course")
    //  console.log(this.state.verifyCallData,"verifyCallDataverifyCallDataverifyCallData")

    return (
      // Customizable Area Start'

      <div>
        <RazorpayCmp handleRazorPay={this.handleRazorPay} />
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.add_contract_container}>
          <div className={classes.banner}>
            <img src={boygirl} alt="" style={{ width: "100%", height: "auto" }} />
            <div className={classes.bannerTitleContainer}>
              <Typography className={classes.bannerTitle}>
                <b>
                  Wide Range of Courses <br /> Available
                </b>
              </Typography>
              <Button className={classes.BannerLMBtn}>Learn More</Button>
            </div>
          </div>

          {/* *********** */}
          <div style={{ display: "flex" }}>
            <div className={classes.dash_left_c}>
              <DashNav
                active="Courses"
                styles={{ marginBottom: "1.5vw" }}
                navigation={this.props.navigation}
              />
              <NextClassNotification
                timings="10:00 AM - 11:00 PM"
                title="Introduction to foundation of digital marketing and e-commerce"
                subtitle=" Lorem Ipsum is simply dummy text of the printing"
                onClick={() => {}}
                styles={{ marginBottom: "1.5vw" }}
              />
              <DashAdvartiseCard
                title="One on One classes with Teachers"
                subtitle="Lorem Ipsum is simply dummy text of the printing"
                styles={{ marginBottom: "1.5vw" }}
                onClick={() => {}}
              />
            </div>

            <div style={{ marginTop: "30px", marginLeft: "2vw" }}>
              <div>
                <Typography
                  style={{
                    fontWeight: 600,
                    marginBottom: 10,
                    fontSize: "1.8vw",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Course Details
                </Typography>
              </div>
              <div className={classes.add_contract_Hr} />
              <Typography style={{ fontSize: 20, fontWeight: 600, marginTop: "40px" }}>
                {this.state.data?.attributes?.name}
              </Typography>
              <div>
                <Typography
                  style={{
                    marginTop: "20px",
                    lineHeight: 2,
                    color: "#181617",
                    width: "60vw",
                    fontSize: "0.8vw",
                  }}
                >
                  {this.state.data?.attributes?.course_description}
                </Typography>
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    width: "20vw",
                    height: "12vw",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
                    border: "1px solid red",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5vw",
                      fontWeight: 600,
                      color: "red",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    Instructor
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={professor}
                        alt=""
                        style={{
                          width: "4vw",
                          height: "4vw",
                          marginTop: "10px",
                          marginLeft: "20px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#333333",
                          fontSize: "1vw",
                          fontWeight: 550,
                        }}
                      >
                        {this.state.data?.attributes?.instructor?.data?.attributes?.first_name}{" "}
                        {this.state.data?.attributes?.instructor?.data?.attributes?.last_name}
                      </Typography>
                      <Typography style={{ fontSize: "1vw" }}>
                        {" "}
                        {this.state.data?.attributes?.instructor?.data?.attributes?.qualification}
                      </Typography>
                    </div>
                  </div>
                  <Button className={classes.sendmessage} variant="contained">
                    Send Message
                  </Button>
                </div>
                <div
                  style={{
                    width: "20vw",
                    height: "12vw",
                    borderRadius: "20px",
                    border: "1px solid red",
                    boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
                    marginLeft: "20px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5vw",
                      fontWeight: 600,
                      color: "red",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    Timings
                  </Typography>
                  <div className={classes.CD_Instruct_2_txt_c}>
                    <div className={classes.CD_Instruct_2_txt_1_c}>
                      <Typography className={classes.CD_Instruct_2_txt_1}>Starts on:</Typography>
                      <Typography className={classes.CD_Instruct_2_txt_1}>Length:</Typography>
                      <Typography className={classes.CD_Instruct_2_txt_1}>Duration:</Typography>
                    </div>
                    <div className={classes.CD_Instruct_2_txt_2_c}>
                      <Typography className={classes.CD_Instruct_2_txt_2}>
                        {moment(this.state.data?.attributes?.start_date).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography className={classes.CD_Instruct_2_txt_2}>
                        {this.state.data?.attributes?.length}&nbsp;weeks
                      </Typography>
                      <Typography className={classes.CD_Instruct_2_txt_2}>
                        {moment(this.state.data?.attributes?.course_duration, "HH:mm").format("h")}&nbsp;hrs
                        p/w
                      </Typography>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "20vw",
                    height: "12vw",
                    borderRadius: "20px",
                    border: "1px solid red",
                    boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
                    marginLeft: "20px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5vw",
                      fontWeight: 600,
                      color: "red",
                      marginLeft: "20px",
                      marginTop: "20px",
                    }}
                  >
                    Features
                  </Typography>
                  <div
                    style={{
                      marginLeft: "1.4em",
                      marginTop: "0.5em",
                      lineHeight: 6,
                    }}
                    className={classes.featureSec}
                  >
                    {this.state.data?.attributes?.features.split("\r\n").map((feature: any, index: any) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            fontSize: "1em",
                            color: "#181617",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            lineHeight: "1.8em",
                          }}
                        >
                          <div key={index}>- </div>
                          <div key={index} style={{}}>
                            {" "}
                            {feature}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* buttons */}
              {this.state.data?.attributes?.is_paid || this.state?.data?.attributes?.course_type == "free" ? (
                <div style={{ display: "flex", marginTop: "40px" }}>
                  <div>
                    <Button
                      variant="contained"
                      style={{ background: "#f0d17c" }}
                      className={classes.cardButton}
                      onClick={this.handleNavToStartLearningScreen}
                      data-testId="CourseDetailsHandleNavToStartLearningScreen"
                    >
                      Continue Learning
                    </Button>
                  </div>
                  <div>
                    <Button
                      className={classes.cancelbutton}
                      variant="outlined"
                      onClick={() => {
                        this.props.navigation.navigate("CourseList", { page: 1 });
                      }}
                    >
                      Back to Course Listing
                    </Button>
                  </div>
                </div>
              ) : null}
              {/* Syllabus */}
              <div style={{ marginBottom: 20, marginTop: 20 }}>
                <Typography style={{ fontSize: "2.2em", fontWeight: 600, marginBottom: 10 }}>
                  Syllabus
                </Typography>
                {/* course compleation  */}
                {this.state.data?.attributes?.is_paid ||
                this.state?.data?.attributes?.course_type == "free" ? (
                  <div className={classes.add_contract_card_c}>
                    <div
                      style={{
                        width: "8vw",
                        height: "7vw",
                        color: '"#212121',
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "black",
                          fontSize: "1.2em",
                          fontWeight: 550,
                        }}
                      >
                        Total Module
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <CircularProgressbarWithChildren
                            value={this.state.courseCompleationData?.completion_percent}
                            styles={buildStyles({
                              pathColor: "#f0d17d",
                            })}
                          >
                            <div
                              style={{
                                fontSize: "1em",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <strong>{this.state.courseCompleationData?.completion_percent}%</strong>
                              <Typography style={{ fontSize: "0.9em" }}>Completed</Typography>
                            </div>
                          </CircularProgressbarWithChildren>

                          <div style={{ marginTop: "30px" }}>
                            <div
                              style={{
                                height: "0.5vw",
                                width: "0.5vw",
                                background: "rgb(247 247 247)",
                                borderRadius: "50%",
                                marginLeft: "20px",
                                marginBottom: "10px",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Typography style={{ marginLeft: "15px", fontSize: "1.2em" }}>Total</Typography>
                            </div>
                            <div
                              style={{
                                height: "0.5vw",
                                width: "0.5vw",
                                background: "#f0d17d",
                                borderRadius: "50%",
                                marginLeft: "20px",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <Typography style={{ marginLeft: "15px", fontSize: "1.2em" }}>
                                Completed
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: "7vw",
                        display: "flex",
                        marginTop: 20,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography style={{ fontSize: "1.2em", textAlign: "center" }}>
                          Total Videos
                        </Typography>
                        <Typography style={{ color: "#ff0000", fontSize: "2.2em", fontWeight: 550 }}>
                          {this.state.courseCompleationData?.total_videos}
                        </Typography>
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography style={{ fontSize: "1.2em", textAlign: "center" }}>
                          Completed Videos
                        </Typography>
                        <Typography style={{ color: "#ff0000", fontSize: "2.2em", fontWeight: 550 }}>
                          {this.state.courseCompleationData?.completed_videos}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div>
                  {this.state.data?.attributes?.course_module?.length && (
                    <Card className={classes.Syllabus}>
                      {/* firstaccrodian */}
                      {this.state.data?.attributes?.course_module.map((modules: any, index: any) => {
                        return (
                          <div style={{ padding: "1em 1em 0em 1em" }} key={index}>
                            <CardContent className={classes.cardStyling}>
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "20px",
                                  marginTop: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <img src={notes} alt="" style={{ width: "18", height: "18" }} />
                                <Typography
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "16",
                                    fontWeight: 600,
                                  }}
                                >
                                  {modules.module}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "17px",
                                  marginLeft: "20px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    color: "#181617",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={hours} alt="" style={{ width: "18", height: "18" }} />
                                  <Typography> &nbsp; {modules.duration} hours</Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "16px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={videos} alt="" style={{ width: "18", height: "18" }} />
                                  <Typography>
                                    {" "}
                                    &nbsp;{modules.video_count}&nbsp;
                                    {this.videoText(modules.video_count)}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "16px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={Reading} alt="" style={{ width: "18", height: "18" }} />
                                  <Typography>
                                    {" "}
                                    &nbsp; {modules.reading_count}&nbsp;
                                    {this.readingText(modules.reading_count)}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "16px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={Exercises} alt="" style={{ width: "18", height: "18" }} />
                                  <Typography>
                                    {" "}
                                    &nbsp;{modules.assessments_count}&nbsp;
                                    {this.practiceExerciseText(modules.assessments_count)}
                                  </Typography>
                                </div>
                              </div>
                              <Typography
                                style={{
                                  font: "Inter, sans-serif",
                                  fontWeight: 400,
                                  fontSize: 17,
                                  lineHeight: 2,
                                  color: "#181617",
                                  marginLeft: "20px",
                                  marginTop: "20px",
                                }}
                              >
                                {modules.description}
                              </Typography>

                              <div className={classes.contents}>
                                <Accordion
                                  expanded={this.state.expand === modules.module_id}
                                  onChange={(event: any, isExpanded: boolean) => {
                                    this.handleExpand(isExpanded, modules.module_id);
                                  }}
                                  data-testId="CourseDetailshandleChange"
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: "#FF0000" }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography className={classes.heading}>
                                      {this.getButtonText(this.state.expand, modules.module_id)}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    {modules?.module_chapters?.video_data?.length > 0 && (
                                      <Typography
                                        style={{
                                          color: "#FF0000",
                                          fontSize: 18,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Videos
                                      </Typography>
                                    )}
                                    {modules?.module_chapters?.video_data.map((videos: any) => {
                                      return (
                                        <div key={videos.id} style={{ display: "flex", marginTop: "20px" }}>
                                          <img
                                            src={redsidearrow}
                                            alt=""
                                            style={{
                                              width: "10",
                                              height: "13",
                                              marginTop: "5px",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <Typography
                                            style={{
                                              marginLeft: "10px",
                                              fontSize: "17",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {videos.serial_number},&nbsp;{videos.title} -
                                            <span style={{ fontWeight: 550 }}>
                                              &nbsp; {videos.duration} minutes
                                            </span>{" "}
                                          </Typography>
                                        </div>
                                      );
                                    })}
                                    {/* Reading                          */}
                                    {modules.module_chapters.reading_data?.length > 0 && (
                                      <Typography
                                        style={{
                                          color: "#FF0000",
                                          fontSize: 18,
                                          fontWeight: 600,
                                          marginTop: "20px",
                                        }}
                                      >
                                        Reading
                                      </Typography>
                                    )}
                                    {modules.module_chapters.reading_data?.length > 0 &&
                                      modules.module_chapters.reading_data.map((ReadingData: any) => {
                                        return (
                                          <div
                                            key={ReadingData.id}
                                            style={{ display: "flex", marginTop: "20px" }}
                                          >
                                            <img
                                              src={redsidearrow}
                                              alt=""
                                              style={{
                                                width: "10",
                                                height: "13",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <Typography
                                              style={{
                                                marginLeft: "10px",
                                                fontSize: "17",
                                                fontWeight: 400,
                                              }}
                                            >
                                              {ReadingData.serial_number},&nbsp;{ReadingData.title} -
                                              <span style={{ fontWeight: 550 }}>
                                                &nbsp; {ReadingData.duration} minutes
                                              </span>
                                            </Typography>
                                          </div>
                                        );
                                      })}

                                    {/* Assesment*/}
                                    {modules?.assessment_data?.length > 0 && (
                                      <Typography
                                        style={{
                                          color: "#FF0000",
                                          fontSize: 18,
                                          fontWeight: 600,
                                          marginTop: "20px",
                                        }}
                                      >
                                        Practice - Exercise
                                      </Typography>
                                    )}
                                    {modules?.assessment_data?.length > 0 &&
                                      modules?.assessment_data.map((assessmentData: any) => {
                                        return (
                                          <div style={{ display: "flex", marginTop: "20px" }}>
                                            <img
                                              src={redsidearrow}
                                              alt=""
                                              style={{
                                                width: "10",
                                                height: "13",
                                                marginTop: "5px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            <Typography
                                              style={{
                                                marginLeft: "10px",
                                                fontSize: "17",
                                                fontWeight: 400,
                                              }}
                                            >
                                              {assessmentData.serial_number},&nbsp;{assessmentData.name} -
                                              <span style={{ fontWeight: 550 }}>
                                                &nbsp; {assessmentData.duration} minutes
                                              </span>
                                            </Typography>
                                          </div>
                                        );
                                      })}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                              <hr
                                style={{
                                  marginLeft: "20px",
                                  color: "#3F526D",
                                  marginTop: "30",
                                  opacity: 0.25,
                                }}
                              />
                            </CardContent>
                          </div>
                        );
                      })}
                      <CardActions />
                    </Card>
                  )}
                </div>
              </div>

              {this.state.isLoading ? (
                <div className={classes.loadingPayment} style={{ marginLeft: "2em" }}>
                  <CircularProgress />
                </div>
              ) : (
                !this.state.data?.attributes?.is_paid &&
                this.state.data?.attributes?.course_type === "paid" && (
                  <div style={{ display: "flex" }}>
                    <div>
                      <Typography className={classes.paynowstartlearning}>
                        Pay Now and Start Learning
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "33vw",
                        height: "10vw",
                        backgroundColor: "#FF0000",
                        borderRadius: "20px",
                        marginTop: "3vw",
                        marginLeft: "3vw",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          color: "#FFFFFF",
                          marginLeft: "35px",
                          marginTop: "25px",
                        }}
                      >
                        <Typography style={{ fontSize: "1.5vw" }}>Make Payment</Typography>
                        <Typography style={{ fontSize: "2vw" }}>
                          Rs {this.state.data?.attributes?.course_cost}/-
                        </Typography>
                      </div>
                      <Button
                        className={classes.paynowbutton}
                        onClick={() => this.handlePayNow()}
                        data-testId="CourseDetailsHandlePayNow"
                      >
                        Pay Now
                      </Button>
                    </div>
                  </div>
                )
              )}
              <div className={classes.bckListBtnContainer}>
                {!this.state.isLoading && !this.state.data?.attributes?.is_paid && (
                  <div>
                    <Button
                      className={classes.backtocourselisting}
                      variant="outlined"
                      onClick={() => {
                        this.props.navigation.navigate("CourseList", { page: 1 });
                      }}
                    >
                      Back to Course Listing
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* modal */}
          <Modal
            open={this.state.open}
            style={{
              position: "absolute",
              height: 545,
              width: 700,
              margin: "auto",
              borderRadius: "20px",
            }}
          >
            <div className={classes.PayNowpopup}>
              <h2 style={{ color: "#ff0000", fontSize: "2em" }}>Payment Successful</h2>
              <hr />
              <div style={{ width: "100%", textAlign: "center" }}>
                <img className={classes.tickicon} src={tickmark} alt="" />
              </div>
              <br></br>
              <Typography style={{ marginTop: "1em" }}>
                <p>
                  Your payment of Rs.
                  {this.state.verifyCallData?.attributes?.course?.data?.attributes?.course_cost} for "
                  {this.state.verifyCallData?.attributes?.course?.data?.attributes?.name}" has been
                  successfully processed.
                </p>
              </Typography>
              <Typography style={{ fontWeight: 600, marginTop: "1em" }}>
                Your Order ID: {this.state.verifyCallData?.attributes?.razorpay_order_id}
              </Typography>
              <Typography style={{ fontWeight: 600, marginTop: "1em" }}>
                We have send you an email regarding your order information to your registered email address
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2vw",
                }}
              >
                <div>
                  <Button
                    onClick={this.handleClose}
                    style={{
                      borderRadius: "100px",
                      backgroundColor: "#ffffff",
                      width: "14vw",
                      height: "3vw",
                      border: "1px solid red",
                      textTransform: "none",
                    }}
                    variant="outlined"
                  >
                    <b>Close</b>
                  </Button>
                </div>
                <div>
                  <Button
                    className={classes.startexploring}
                    variant="contained"
                    onClick={this.handleNavToStartLearningScreen}
                  >
                    Start Exploring
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          {/* paymentFailureModal */}

          <Modal
            open={this.state.paymentFailureModal}
            style={{
              position: "absolute",
              height: 545,
              width: 700,
              margin: "auto",
              borderRadius: "20px",
            }}
          >
            <div className={classes.PayFailurepopup}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2vw",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <h2 style={{ color: "#ff0000" }}>Payment Failed</h2>
                <Button
                  onClick={() => this.paymentFailure(false)}
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#ffffff",
                    width: "14vw",
                    height: "3vw",
                    border: "1px solid red",
                    textTransform: "none",
                  }}
                  variant="outlined"
                >
                  <b>Close</b>
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      // Customizable Area End
    );
  }
}
export default withStyles(styles, { withTheme: true })(CourseDetails);
