import React from "react";
// Customizable Area Start
import {
  BoyBook,
  BoywithLaptop,
  tableBook,
  womenLaptop,
  girlWithPhone,
  ManWomen,
  Girlgirl,
  student
} from "./assets";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Banner from "../../email-account-registration/src/Components/Banner.web";
import PopularCourses from "../../email-account-registration/src/PopularCourses.web";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import DashboardController, { Props } from "./DashboardController.web";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    banner: {
      position: "relative",
      justifyContent: "center"
    },
    bannerTitleContainer: {
      position: "absolute",
      bottom: "25%",
      left: "25%",
      transform: "translate(-25%, -25%)",
      fontSize: 41,
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        bottom: "10%",
        left: "20%",
        transform: "translate(-20%, -10%)",
        fontSize: 36
      },
      [theme.breakpoints.down("md")]: {
        bottom: "20%",
        left: "20%",
        transform: "translate(-20%, -20%)",
        fontSize: 24
      },
      [theme.breakpoints.down("sm")]: {
        bottom: "15%",
        left: "20%",
        transform: "translate(-15%, -20%)",
        fontSize: 18
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    bannerTitle: {
      fontSize: 41,
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: 36
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 24
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 18
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    BannerLMBtn: {
      marginTop: 25,
      borderRadius: 100,
      backgroundColor: "#f0d17c",
      width: 300,
      height: 45,
      textTransform: "none",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        marginTop: 20,
        height: 30,
        width: 130,
        fontSize: 14
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 15,
        height: 30,
        width: 100,
        fontSize: 12
      },
      [theme.breakpoints.down("xs")]: {
        height: 20,
        width: 90,
        fontSize: 10
      }
    },
    EmailpasswordContainer: {
      minHeight: 400,
      background: "#f7f7f7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: 25,
      [theme.breakpoints.down("sm")]: {
        columnGap: 0,
        flexDirection: "column",
        rowGap: 25
      },
      [theme.breakpoints.down("xs")]: {
        margin: 5
      }
    },
    EmailPassword: {
      width: 500,
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.down(1050)]: {
        width: 440
      },
      [theme.breakpoints.down("xs")]: {
        width: 300
      }
    },
    LoginbanerImg: {
      height: 350,
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 250
      }
    },
    LoginBtnContainer: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        rowGap: 10
      }
    },
    popCourseName: {
      fontSize: 38,
      fontWeight: 700,
      marginTop: -20,
      padding: 55,
      [theme.breakpoints.down("xs")]: {
        fontSize: 24,
        margin: 25
      }
    },
    popCourseContainer: {
      display: "flex",
      justifyContent: "center",
      gap: 20,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center"
      }
    },
    popCourseCard: {
      width: 600,
      height: "100%",
      [theme.breakpoints.down("md")]: {
        width: 400
      },

      [theme.breakpoints.down("sm")]: {
        width: 500
      },
      [theme.breakpoints.down("xs")]: {
        width: 350
      }
    },

    cardButton: {
      marginBottom: 25,
      borderRadius: 100,
      textTransform: "none",
      fontWeight: 700,
      fontSize: 16,
      width: 180,
      height: 50,
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        width: 130,
        height: 30
      }
    },
    forgotpasswordpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "442px",
      height: "250px",
      borderRadius: "20px",
      padding: "2px 27px 49px 27px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      }
    },
    textfieldemail: {
      width: "425px",
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.down("xs")]: {
        width: "212px"
      }
    },
    cancelbutton: {
      borderRadius: "100px",
      backgroundColor: "#ffffff",
      width: "150px",
      border: "1px solid red",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        width: "75px"
      }
    },
    submitbutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "150px",
      marginLeft: "10px",
      color: "#fff",
      textTransform: "none",
      [theme.breakpoints.down("xs")]: {
        width: "75px"
      }
    },
    tickicon: {
      height: "50px",
      width: "50px",
      justifyContent: "center",
      marginLeft: "200px",
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "90px"
      }
    },
    EmailPasswordreset: {
      width: 500,
      // border: "1px solid red",
      backgroundColor: "#ffffff",
      marginTop: "5px",
      [theme.breakpoints.down(1050)]: {
        width: 440
      },
      [theme.breakpoints.down("xs")]: {
        width: 300
      }
    },
    LoginbanerImgreset: {
      height: 350,
      // border: "1px solid red",
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: 250
      },
      [theme.breakpoints.down("xs")]: {
        height: 250
      }
    },
    LoginBtnContainerreset: {
      marginTop: 25,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        rowGap: 10
      }
    },

    tickmarkloginbutt: {
      height: "80px",
      width: "80px",
      justifyContent: "center",
      marginLeft: "200px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "77px",
        textalign: "center"
      }
    },

    RelatedCourseCard: {
      width: 287,
      height: "100%"
    },
    CourseContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "200px",
      postion: "relative"
    },
    infoGirlContainer: {
      display: "flex",
      justifyContent: "center",
      background: "#fff",
      gap: 40,
      marginLeft: "50px",
      marginBottom: "30px"
    },
    courseTemplate: {
      background: "linear-gradient( #ffffff 40%, #f00 0%)"
      // height: "150px",
      // position: "relative",
    },
    cousresTemplateConatiner: {
      // position: "absolute",
      justifyContent: "center",
      // justifyItems: "center",
      // alignItems:"center",
      display: "flex"
      // top: "-150px",
      // left: "21%",
    },
    courseTemplateText: {
      fontSize: "20px",
      color: "#fff",
      textAlign: "center"
    },
    courseTempalteImg: {
      width: "20vw",
      marginRight: "20px"
    },
    popularCourseContainer: {
      background: "#f7f7f7",
      marginTop: "-10px"
    },
    assesmentConatiner: {
      background: "#14292e",
      color: "#fff",
      height: "400px",
      display: "flex",
      justifyContent: "center"
    },

    OneOnOneContainer: {
      display: "flex",
      justifyContent: "center",
      background: "#fff",
      gap: 40,
      marginLeft: "50px",
      padding: "70px"
    },
    infoGirlDiscription: {
      marginTop: "-20px"
    },
    OneOnOneDiscription: {
      marginTop: "-20px"
    },
    TL_sec_1_c: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "17%",
      paddingRight: "17%",
      paddingBottom: "2em",
      marginTop: "4em"
    },
    TL_sec_1_txt: {
      marginRight: "8em"
    },
    TL_sec_1_title: {
      fontSize: "2.37em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#333333",
      lineHeight: "1.4em",
      margin: "0px"
    },
    TL_sec_1_subtitle: {
      fontSize: "0.8em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#181617",
      lineHeight: "2.2em",
      margin: "0px",
      marginTop: "1.5em"
    },
    TL_sec_1_img_c: {
      background: "linear-gradient(45deg, #dd8f1e 12%, transparent 12%)",
      padding: "0vw 0vw 0.6vw 0.6vw",
      display: "flex",
      flexDirection: "column",
      marginTop: "-8em"
    },
    TL_sec_1_img: {
      height: "100%",
      width: "22vw",
      objectFit: "cover",
      borderRadius: "1vw",
      zIndex: 1
    },
    TL_sec_2_c: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "17%",
      paddingRight: "17%",
      marginTop: "4em",
      background: "linear-gradient(#ffffff 43%, #ff0000 0%)",
      paddingBottom: "3em"
    },
    TL_sec_2_img: {
      width: "100%",
      borderRadius: "1.5em"
    },
    TL_sec_2_img_c: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    TL_sec_2_img_txt: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontSize: "2.1em",
      fontWeight: 600,
      marginTop: "1em"
    },
    TL_sec_4_c: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "17%",
      paddingRight: "17%",
      paddingTop: "2em",
      paddingBottom: "5em",
      backgroundColor: "#ffffff"
    },
    TL_sec_4_img_c: {
      background: "linear-gradient(-45deg, #ff0000 12%, transparent 12%)",
      padding: "0.5vw",
      display: "flex",
      flexDirection: "column",
      marginTop: "-9em"
    },
    TL_sec_4_img: {
      height: "100%",
      width: "22vw",
      objectFit: "cover",
      borderRadius: "1vw"
    },
    TL_sec_4_txt: {
      marginLeft: "3em",
      marginTop: "3em"
    },
    TL_sec_4_title: {
      fontSize: "1.95vw",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#333333",
      lineHeight: "1.4em",
      margin: "0px"
    },
    TL_sec_4_subtitle: {
      fontSize: "0.85vw",
      fontWeight: 400,
      fontFamily: "Inter, sans-serif",
      color: "#181617",
      lineHeight: "2.2em",
      margin: "0px",
      marginTop: "1.5em"
    },
    TL_sec_4_btn: {
      backgroundColor: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.9vw",
      fontWeight: 700,
      color: "#ffffff",
      borderWidth: "0px",
      width: "12vw",
      height: "2.5vw",
      borderRadius: "10em",
      marginTop: "2em",
      cursor: "pointer"
    },
    TL_sec_5_c: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "17%",
      paddingRight: "17%",
      paddingTop: "5em",
      paddingBottom: "5em",
      backgroundColor: "#14292d"
    },
    TL_sec_5_txt: {
      marginRight: "5em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    TL_sec_5_title: {
      fontSize: "2.37em",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#ffffff",
      lineHeight: "1.4em",
      margin: "0px"
    },
    TL_sec_5_subtitle: {
      fontSize: "1.07em",
      fontWeight: 400,
      fontFamily: "Inter, sans-serif",
      color: "#ffffff",
      lineHeight: "2.2em",
      margin: "0px",
      marginTop: "1.5em"
    },
    TL_sec_5_img_c: {
      background: "linear-gradient(45deg, #f0d17c 12%, transparent 12%)",
      padding: "0.6vw",
      display: "flex",
      flexDirection: "column"
    },
    TL_sec_5_img: {
      height: "100%",
      width: "22vw",
      objectFit: "cover",
      borderRadius: "1vw"
    },
    TL_sec_6_c: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "17%",
      paddingRight: "17%",
      paddingTop: "5em",
      paddingBottom: "5em",
      backgroundColor: "#ffffff"
    },
    TL_sec_6_img_c: {
      background: "linear-gradient(-45deg, #dd8f1e 12%, transparent 12%)",
      padding: "0.5vw",
      display: "flex",
      flexDirection: "column"
    },
    TL_sec_6_img: {
      height: "100%",
      width: "22vw",
      objectFit: "cover",
      borderRadius: "1vw"
    },
    TL_sec_6_txt: {
      marginLeft: "5em",
      marginTop: "3em"
    },
    TL_sec_6_title: {
      fontSize: "1.95vw",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      color: "#333333",
      lineHeight: "1.4em",
      margin: "0px"
    },
    TL_sec_6_subtitle: {
      fontSize: "0.85vw",
      fontWeight: 400,
      fontFamily: "Inter, sans-serif",
      color: "#181617",
      lineHeight: "2.2em",
      margin: "0px",
      marginTop: "1.5em"
    },
    TL_sec_6_btn: {
      backgroundColor: "#f0d17c",
      fontFamily: "Inter, sans-serif",
      fontSize: "0.9vw",
      fontWeight: 700,
      color: "#181617",
      borderWidth: "0px",
      width: "12vw",
      height: "2.5vw",
      borderRadius: "10em",
      marginTop: "2em",
      cursor: "pointer"
    },
    TL_sec_7_c: {
      position: "relative",
      width: "100%",
      height: "24vw"
    },
    TL_sec_7_txt: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    TL_sec_7_title: {
      color: "#ffffff",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "2.1vw",
      margin: "0px"
    },
    TL_sec_7_subtitle: {
      color: "#ffffff",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "1.5vw",
      margin: "0px",
      marginTop: "0.5em"
    },
    TL_sec_7_btn: {
      backgroundColor: "#f0d17c",
      color: "#181617",
      fontWeight: 700,
      fontFamily: "Inter, sans-serif",
      width: "18vw",
      height: "2.5vw",
      borderWidth: "0px",
      borderRadius: "10em",
      marginTop: "3.5em",
      cursor: "pointer",
      fontSize: "1.2em"
    },
    DashboardLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }
    },
  });
// Customizable Area End

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <div className={classes.root}>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        {this.state.isDashboardLoading ? <div className={classes.DashboardLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
        <div className={classes.TL_sec_1_c}>
          <div className={classes.TL_sec_1_txt}>
            <p className={classes.TL_sec_1_title}>
              Redefine your learning experience through its fully industry
              oriented courses
            </p>
            <p className={classes.TL_sec_1_subtitle}>
              It is also a platform for experts to connect and become an
              instructor to changelives - including their own
            </p>
          </div>
          <div className={classes.TL_sec_1_img_c}>
            <img src={BoyBook} alt="img" className={classes.TL_sec_1_img} />
          </div>
        </div>
         }
        <Grid style={{ gap: "2em" }} className={classes.TL_sec_2_c}>
          <div className={classes.TL_sec_2_img_c}>
            <img src={tableBook} alt="" className={classes.TL_sec_2_img} />
            <Typography className={classes.TL_sec_2_img_txt}>
              Courses
            </Typography>
          </div>
          <div className={classes.TL_sec_2_img_c}>
            <img src={BoywithLaptop} alt="" className={classes.TL_sec_2_img} />
            <Typography className={classes.TL_sec_2_img_txt}>
              Learn With Us
            </Typography>
          </div>
          <div className={classes.TL_sec_2_img_c}>
            <img src={womenLaptop} alt="" className={classes.TL_sec_2_img} />
            <Typography className={classes.TL_sec_2_img_txt}>
              Be a Teacher
            </Typography>
          </div>
        </Grid>
 
        <PopularCourses
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
          classes={undefined}
          bgColor={true}
        />

        {/*  */}

        <div className={classes.TL_sec_4_c}>
          <div className={classes.TL_sec_4_img_c}>
            <img
              src={girlWithPhone}
              alt="img"
              className={classes.TL_sec_4_img}
            />
          </div>
          <div className={classes.TL_sec_4_txt}>
            <p className={classes.TL_sec_4_title}>
              Create a welcoming learning environment. Make students feel
              comfortable and important.
            </p>
            <p className={classes.TL_sec_4_subtitle}>
              It is also a platform for experts to connect and become an
              instructor to changelives - including their own
            </p>
          </div>
        </div>

        {/*  */}

        <div className={classes.TL_sec_5_c}>
          <div className={classes.TL_sec_5_txt}>
            <p className={classes.TL_sec_5_title}>Assessment Evaluations</p>
            <p className={classes.TL_sec_5_subtitle}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              turpis risus, pharetra quis porttitor quis, luctus vitae leo.
              Pellentesque vel eleifend est, a fringilla augue. Donec faucibus
              venenatis urna. luctus vitae leo. Pellentesque vel eleifend est, a
              fringilla augue. Donec faucibus venenatis urna. Dolor sit amet,
              consectetur adipiscing elit. Duis turpis risus, pharetra quis
              porttitor quis, luctus vitae leo.
            </p>
          </div>
          <div className={classes.TL_sec_5_img_c}>
            <img src={ManWomen} alt="img" className={classes.TL_sec_5_img} />
          </div>
        </div>

        {/*  */}

        <div className={classes.TL_sec_6_c}>
          <div className={classes.TL_sec_6_img_c}>
            <img src={Girlgirl} alt="img" className={classes.TL_sec_4_img} />
          </div>
          <div className={classes.TL_sec_6_txt}>
            <p className={classes.TL_sec_6_title}>
              One on One Class with Teachers
            </p>
            <p className={classes.TL_sec_6_subtitle}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              turpis risus, pharetra quis porttitor quis, luctus vitae leo.
              Pellentesque vel eleifend est, a fringilla augue. Donec faucibus
              venenatis urna. luctus vitae leo. Pellentesque vel eleifend est, a
              fringilla augue. Donec faucibus venenatis urna. Dolor sit amet,
              consectetur adipiscing elit. Duis turpis risus, pharetra quis
              porttitor quis, luctus vitae leo.
            </p>
            <button className={classes.TL_sec_4_btn}>Get Started</button>
          </div>
        </div>

        {/*  */}

        <div
          className={classes.TL_sec_7_c}
          style={{
            backgroundImage: `url(${student})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className={classes.TL_sec_7_txt}>
            <p className={classes.TL_sec_7_title}>Interested in Teaching?</p>
            <p className={classes.TL_sec_7_subtitle}>
              Be an instructor on Techfin
            </p>
            <button
              className={classes.TL_sec_7_btn}
              onClick={this.handleTSignUpNav}
              data-testId='DashboardJoinNowBtnEnd'
            >
              Join Now
            </button>
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(Dashboard);

// Customizable Area End
