import React , { RefObject } from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import DashAdvartiseCard from '../../dashboard/src/components/DashAdvartiseCard.web';
import DashNav from '../../dashboard/src/components/DashNav.web';
import NextClassNotification from '../../dashboard/src/components/NextClassNotification.web';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { updatepicture, dropdown, takePhotoRed, UploadPhotoRed, crossImage } from "./assets";
import { WebcamCapture } from "../../TeachersDashboard9/src/WebCamComponent.web"
import CircularProgress from '@material-ui/core/CircularProgress';



const styles = (theme: Theme) =>
  createStyles({
    root: {},
    studentprofilemaincontainer: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      display: "flex",
    },
    studentleftcontainer: {
      flex: 2,
    },
    studentrightcontainer: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    studentProfileHr: {
      borderBottom: "0.5px solid #e4e4e5",
    },
    studentprofiletitle: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginBottom: "1vw",
      marginTop: "1vw",

    },

    studentInnerContainer: {
      marginTop: "100px",
      position: "relative",
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center'
    },

    profileUpdateBackground2: {
      position: "relative",
      height: "120px",
      background: " #F0D17C",
      opacity: "0.11",
      borderRadius: "20px",
      top: 0,
      left: 0,
      width: "100%"
    },
    profileImageSec: {
      position: "absolute",
      /* top: -41px; */
      // left: "32em",
      bottom: "31px",
    },
    updateButton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      color: "#fff",
      marginTop: "13px",
      textTransform: "none",
      width:"12em",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    UpdateInfo: {
      // position: "absolute",
      width: "100%",
      height: "auto",
      // left: "400px",
      top: "410px",
      background: "#F7F7F7",
      borderRadius: "20px",
      padding: 30,
      // marginLeft: "20px",
      marginBottom: "20px",
      boxSizing: "border-box"


      // border: "1px solid red"
    },
    updateprofilebutton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      width: "200px",
      // marginLeft: "350px",
      color: "#fff",
      marginTop: "20px",
      textTransform: "none",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },
    },

    updatePreviewButton: {
      borderRadius: "100px",
      backgroundColor: "#ff0000",
      color: "#fff",
      marginTop: "13px",
      textTransform: "none",
      width: "8em",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },

    },
    updateCancelButton: {
      marginRight: "1em",
      border: "1px solid  #ff0000",
      borderRadius: "25px",
      background: "#fff",
      width: "8em",
      marginTop: "13px",
      textTransform: "none",
      transparent: "",
      '&:hover': {
        backgroundColor: '#ff0000'
      },
      [theme.breakpoints.down("xs")]: {
        width: "75px",
      },

    },

    updatepopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "31vw",
      height: "25vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      },
    },
    updatepopupCamera: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "58vw",
      height: "35vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      },
    },
    ProfileUploadText: {
      fontFamily: "Inter, sans-serif",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      letterSpacing: "0.1px",
      color: "#ff0000",
    },
    updatePreviewpopup: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "31vw",
      height: "25vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "221px",
        height: "auto"
      },
    },


    ProfileUploadclose: {
      width: "0.8em",
      height: "0.7em",
      color: "#ff0000",
      marginTop: "1em"
    },
    buttonBg_l: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "24px",
      background: "#f7f7f7",
      width: "50%",
      height: "198px",
      marginRight: "2em"
    },
    buttonBg_r: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "24px",
      background: "#f7f7f7",
      width: "50%",
      height: "198px",
    },
    previewImage: {
      width: "100%",
      height: "60%",
    },
    successPopUp: {
      position: "fixed",
      backgroundColor: "#ffffff",
      width: "29vw",
      height: "16vw",
      borderRadius: "20px",
      padding: "10px 40px 50px 40px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      [theme.breakpoints.down("xs")]: {
        width: "700px",
        height: "545px"
      },
    },
    loadingProfile: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000"
      }

    },
    mobileNumberCode:{
      width:" 100%",
      height:"3.5rem",
      background: "white",
      color: "#a8a8a8",
      cursor: "pointer",
      border:" 1px solid #c7c7c7",
      paddingLeft: "0.5em",
      fontSize: "0.8em",
      fontFamily:" Inter, sans-serif",
      borderRadius: "0.5em",
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      backgroundImage: `url(${dropdown})`,
      backgroundSize: "2em",
      backgroundPosition: "right 10px top 50%",
      backgroundRepeat: "no-repeat",
    },
    updatedMsg:{
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      marginBottom: "1em",
    }


  });

// Customizable Area End

import ProfileController, {
  Props,
} from "./ProfileController.web"
import moment from "moment";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";



// Customizable Area End
class StudentProfile extends ProfileController {

  inputRef: RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
  }
  handleImgClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  };
  render() {
    const { classes } = this.props;
    const { attributes } = this.state?.profileData
    return (
      <div>
        <TopNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
        <div className={classes.studentprofilemaincontainer}>
          <div className={classes.studentleftcontainer}>
            <DashNav
              active="Profile"
              navigation={this.props.navigation}
              styles={{ marginBottom: "1.5vw" }}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              onClick={() => {
                console.log("Next Class");
              }}
              styles={{ marginBottom: "1.5vw" }}
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
            />
            <DashAdvartiseCard
              styles={{ marginBottom: "1.5vw" }}
              onClick={() => { }}
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
            />
          </div>

          <div className={classes.studentrightcontainer}>
            <p className={classes.studentprofiletitle}>Profile</p>
            <div className={classes.studentProfileHr} />

            {this.state.isLoading ?
              <div className={classes.loadingProfile} style={{ marginLeft: "2em" }}><CircularProgress /></div> :
              <>
                <div className={classes.studentInnerContainer} >
                  <div className={classes.profileUpdateBackground2} style={{ background: " #F0D17C", height: "120px" }}></div>
                  <div className={classes.profileImageSec} >
                    {this.state?.profileData?.attributes?.profile_image === null || undefined ?
                      <img src={updatepicture} alt="" style={{ width: "80", height: "auto", padding: 0 }} /> :
                      <img src={this.state?.profileData?.attributes?.profile_image} alt="" style={{ width: "8.5em", height: "8.5em", padding: 0, borderRadius: "50%",marginLeft:"9px"   }} />
                    }
                    <div style={{ marginLeft: "-0.9em" }}>
                      <Button className={classes.updateButton} variant="contained" onClick={() => this.handleUploadProfilePicture(true)}>Update Picture</Button>
                    </div>
                  </div>
                </div>
                <div>
                  <Typography style={{ marginTop: 65, fontSize: 22, fontWeight: 550, marginBottom: 20, marginLeft: "20px" }}>Update Profile Info</Typography>
                </div>
                <div className={classes.UpdateInfo}>
                  <div>
                    <form className="prl-inputs-container">
                      <div className="prl-input-cc">
                        <div className="prl-input-left">
                          <label className="prl-input-lable">
                            First Name<sup className="required">*</sup>
                          </label>
                          <input
                            className="prl-input"
                            type="text"
                            value={this.state.fName}
                            required
                            onChange={(e) => {
                              this.handleFNameChange(e);
                            }}
                            placeholder="Enter First Name"
                            style={this.state.errFName ? this.errStyle : {}}
                          />
                          {this.state.errFName ? (
                            <p className="prl-input-err-txt">
                              Please enter a value.{" "}
                            </p>
                          ) : (
                            <></>
                          )}
                          {this.state.errMaxFName ? (
                            <p className="prl-input-err-txt">
                              Maximum 15 characters
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="prl-input-right">
                          <label className="prl-input-lable">
                            Last Name<sup className="required">*</sup>
                          </label>
                          <input
                            className="prl-input"
                            type="text"
                            value={this.state.lName}
                            required
                            onChange={(e) => {
                              this.handleLNameChange(e);
                            }}
                            placeholder="Enter Last Name"
                            style={this.state.errLName ? this.errStyle : {}}
                          />
                          {this.state.errLName ? (
                            <p className="prl-input-err-txt">
                              Please enter a value.{" "}
                            </p>
                          ) : (
                            <></>
                          )}
                          {this.state.errMaxLName ? (
                            <p className="prl-input-err-txt">
                              Maximum 15 characters
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="prl-input-cc">
                        <div className="prl-input-left">
                          <label className="prl-input-lable">
                            Date of Birth<sup className="required">*</sup>
                          </label>
                          <input
                            className="prl-input"
                            type="date"
                            value={this.state.date}
                            required
                            onChange={(e) => {
                              this.handleDateChange(e);
                            }}
                            max={moment().format("YYYY-MM-DD")}
                            placeholder="05/03/1995"
                            style={this.state.errDate ? this.errStyle : {}}
                          />
                          {this.state.errDate ? (
                            <p className="prl-input-err-txt">
                              Please enter a valid date.
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="prl-input-right">
                          <label className="prl-input-lable">
                            Mobile no<sup className="required">*</sup>
                          </label>
                          <div className="prl-input-phone">
                            <div className="prl-input-dd-phone">
                              <select
                                className={classes.mobileNumberCode}
                                value={this.state.mobileCode}
                                onChange={(e) => {
                                  this.handleMobileCodeChange(e);
                                }}
                                style={{ width: "100%" }}
                              >
                                <option value="91">+91</option>
                                <option value="81">+81</option>
                                <option value="71">+71</option>
                              </select>

                            </div>
                            <input
                              className="prl-input-i-phone"
                              type="text"
                              pattern="[0-9]*"
                              value={this.state.mobile}
                              required
                              onChange={(e) => {
                                this.handleMobileChange(e);
                              }}
                              placeholder="0000000"
                              style={this.state.errPhone ? this.errStyle : {}}
                            />
                          </div>
                          {this.state.errPhone ? (
                            <p className="prl-input-err-txt">
                              Please enter a value.
                            </p>
                          ) : (
                            <></>
                          )}
                          {this.state.errMaxPhone ? (
                            <p className="prl-input-err-txt">
                              Maximum 15 characters
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="prl-input-cc">
                        <div className="prl-input-left">
                          <label className="prl-input-lable">
                            Email<sup className="required">*</sup>
                          </label>
                          <input
                            className="prl-input"
                            type="text"
                            value={this.state.cemail}
                            required
                            onChange={(e) => {
                              this.handleEmailChange(e);
                            }}
                            placeholder="email@gmail.com"
                            style={this.state.errEmail ? this.errStyle : {}}
                          />
                          {this.state.errEmail ? (
                            <p className="prl-input-err-txt">
                              Please enter a valid email address.
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="prl-input-right">
                          <label className="prl-input-lable">Currently Working<sup className="required">*</sup></label>
                          <div className="prl-input-dd">
                            <select
                              className="prl-input-dd-s"
                              value={this.state.currentlyWorking}
                              onChange={(e) => {
                                this.handlecurrentlyWorking(e);
                              }}
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span className="prl-input-arrow">
                              <img src={dropdown} alt="arrow" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="prl-input-cc">
                        <div className="prl-input-left">
                          <label className="prl-input-lable">Qualification<sup className="required">*</sup></label>
                          <div className="prl-input-dd">
                            <select
                              className="prl-input-dd-s"
                              value={this.state.qualification}
                              onChange={(e) => {
                                this.handleQualification(e);
                              }}
                            >
                              <option value={this.state.qualification}>{this.state.qualification}</option>
                              {this.state.qualifactionListData.map((val: any, key: any) => {
                                if (val.name === attributes?.qualification) {
                                  return
                                }
                                return (
                                  <option value={val.name} key={key}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="prl-input-arrow">
                              <img src={dropdown} alt="arrow" />
                            </span>
                          </div>
                        </div>
                        <div className="prl-input-right">
                          <label className="prl-input-lable">Area of Interest<sup className="required">*</sup></label>
                          <div className="prl-input-dd">
                            <select
                              className="prl-input-dd-s"
                              value={this.state.interstedCourse}
                              onChange={(e) => {
                                this.handleinterstedCourse(e);
                              }}
                            >
                              <option value={this.state.interstedCourse}>{this.state.interstedCourse}</option>
                              {this.state.cousesInterestListData.map((val: any, key: any) => {
                                if (val.name == attributes?.academic?.data?.attributes?.interest?.name) {
                                  return
                                }
                                return (
                                  <option value={val.name} key={key}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span className="prl-input-arrow">
                              <img src={dropdown} alt="arrow" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="prl-input-cc">
                        <div className="prl-input-left">
                          <label className="prl-input-lable">
                            Country<sup className="required">*</sup>
                          </label>
                          <div className="prl-input-dd">
                            <select
                              className="prl-input-dd-s"
                              value={this.state.sCountry}
                              onChange={(e) => {
                                this.handleCountryChange(e);
                              }}
                              style={this.state.errCountry ? this.errStyle : {}}
                            >
                              <option value={this.state.sCountry} data-key="Empty">
                                {this.state.sCountry}
                              </option>
                              {this.state.listCountry.map((val, key) => {
                                return (
                                  <option
                                    value={key}
                                    key={key}
                                    data-key={val.isoCode}
                                    a-key={val.isoCode}
                                  >
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span
                              className="prl-input-arrow"
                              style={
                                this.state.errCountry
                                  ? { backgroundColor: "#fcecec" }
                                  : {}
                              }
                            >
                              <img src={dropdown} alt="arrow" />
                            </span>
                            {this.state.errCountry ? (
                              <p className="prl-input-err-txt">
                                Please select a value.
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="prl-input-right">
                          <label className="prl-input-lable">
                            State<sup className="required">*</sup>
                          </label>
                          <div className="prl-input-dd">
                            <select
                              className="prl-input-dd-s"
                              value={this.state.sState}
                              onChange={(e) => {
                                this.handleStateChange(e);
                              }}
                              style={this.state.errState ? this.errStyle : {}}
                            >
                              <option value={this.state.sState}>{this.state.sState}</option>
                              {this.state.listState.map((val, key) => {
                                return (
                                  <option value={key} key={key}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span
                              className="prl-input-arrow"
                              style={
                                this.state.errState
                                  ? { backgroundColor: "#fcecec" }
                                  : {}
                              }
                            >
                              <img src={dropdown} alt="arrow" />
                            </span>
                            {this.state.errState ? (
                              <p className="prl-input-err-txt">
                                Please select a value.
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="prl-input-cc">
                        <div className="prl-input-left">
                          <label className="prl-input-lable">
                            City<sup className="required">*</sup>
                          </label>
                          <div className="prl-input-dd">
                            <select
                              className="prl-input-dd-s"
                              defaultValue={this.state.sCity}
                              onChange={(e) => {
                                this.handleCityChange(e);
                              }}
                              style={this.state.errCity ? this.errStyle : {}}
                            >
                              <option value={this.state.sCity}>{this.state.sCity}</option>
                              {this.state.listCity.map((val, key) => {
                                return (
                                  <option value={val.name} key={key}>
                                    {val.name}
                                  </option>
                                );
                              })}
                            </select>
                            <span
                              className="prl-input-arrow"
                              style={
                                this.state.errCity
                                  ? { backgroundColor: "#fcecec" }
                                  : {}
                              }
                            >
                              <img src={dropdown} alt="arrow" />
                            </span>
                            {this.state.errCity ? (
                              <p className="prl-input-err-txt">
                                Please select a value.
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="prl-input-right">
                          <label className="prl-input-lable">
                            Address<sup className="required">*</sup>
                          </label>
                          <input
                            className="prl-input"
                            type="text"
                            value={this.state.address}
                            required
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Enter Details"
                            style={this.state.errAddress ? this.errStyle : {}}
                            autoComplete="new-password"
                          />
                          {this.state.errAddress ? (
                            <p className="prl-input-err-txt">
                              Please enter a value.
                            </p>
                          ) : (
                            <></>
                          )}
                          {this.state.errMaxAddress ? (
                            <p className="prl-input-err-txt">
                              Maximum 30 characters
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </form>

                  </div>
                  <div>
                    <Button
                      className={classes.updateprofilebutton}
                      variant="contained"
                      onClick={this.handleUpdateData}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </>
            }


          </div>

        </div>

        {/* modal fro update */}
        <Modal
          open={this.state.updateProfilePicModalStatus}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.updatepopup}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={classes.ProfileUploadText}>Profile Upload</div>
              <div className={classes.ProfileUploadclose} onClick={() => this.handleUploadProfilePicture(false)}><img src={crossImage} alt="crossImage" style={{ height: "0.7em" }} /></div>
            </div>
            <hr />
            <div style={{ marginTop: "3em", marginBottom: "3em" }}>
              <div style={{ display: 'flex', width: "100%" }}>
                <div className={classes.buttonBg_l} >
                  <label
                    htmlFor="fileupload"
                    style={{ width: "100%", height: "198px" }}
                  >
                    <input
                      type="file"
                      id="fileupload"
                      accept=".jpeg,.png"
                      className="TSU-input-file"
                      value=""
                      ref={this.inputRef}
                      required
                      onChange={(e) => {
                        this.handleUploadProfilePictureUpdate(e);
                      }}
                      placeholder="Enter Languages"
                    /></label>
                  <img src={UploadPhotoRed} alt="upload" style={{ width: 50, height: 43, position: "absolute" }}   onClick={this.handleImgClick} />

                </div>

                <div className={classes.buttonBg_r} onClick={() => this.handletakePhotoModal(true)}>
                  <img src={takePhotoRed} alt="upload2" style={{ width: 50, height: 43 }} />
                </div>
              </div>

            </div>
          </div>
        </Modal>

        {/* modal for take photo */}
        <Modal
          open={this.state.takePhotoModalStatus}
          onClose={() => this.handletakePhotoModal(false)}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.updatepopupCamera}>
            <div>
              <WebcamCapture handletakePhotoCapture={this.handletakePhotoCapture} />
            </div>
          </div>
        </Modal>

        {/* Modal for priview */}
        <Modal
          open={this.state.previewConfirmModalStatus}
          // open={true}
          style={{
            position: "absolute",
            height: 270,
            width: 450,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.updatePreviewpopup}>
            <div>
              <div className={classes.ProfileUploadText}>Profile Upload</div>
            </div>
            <hr />
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              {this.state.uploadPicFile ? <img className={classes.previewImage} alt="preview" src={this.state.previewUploadFile} />
                : <img id="my-image" className={classes.previewImage} alt="preview" src={this.state.imageSrc} />}
            </div>

            <div style={{ display: 'flex', justifyContent: "center" }}>
              <Button className={classes.updateCancelButton} variant="contained" onClick={() => this.handleUploadProfileConfirmModal(false, true)}>Cancel</Button>
              <Button className={classes.updatePreviewButton} variant="contained" onClick={() => this.handleUploadProfilePictureConfirmUpdate()}>Upload</Button>
            </div>


          </div>
        </Modal>

        {/* sucess modal? */}
        <Modal
          // open={this.state.open}
         open={this.state.opensucessMessageModal}
          style={{
            position: "absolute",
            height: 545,
            width: 700,
            margin: "auto",
            borderRadius: "20px",
          }}
        >
          <div className={classes.successPopUp}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <div>
                <p className={classes.updatedMsg}>Profile Updated Sucessfully</p>
              </div>
              <div>
                <Button
                  onClick={() => this.handlesucessMessageModal(false)}
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "#ffffff",
                     padding:"0.5em 5em",
                    border: "1px solid red",
                    textTransform: "none",
                    // marginTop: "10px",
                  }}
                  variant="outlined"
                >
                  OK
                </Button>
              </div>
            </div>
          </div>

        </Modal>



        <BottomNavigation navigation={this.props.navigation} history={this.props.history} location={this.props.location} />
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(StudentProfile);
