import React, { Component } from "react";
import { dropdown } from "./assets";
import Banner from "./Components/Banner.web";
import BottomNavigation from "./BottomNavigation.web";
import LearnerSignUpStep2Controller, { Props } from "./LearnerSignUpStep2Controller.web";
import TopNavigation from "./TopNavigation.web";

export default class LearnerSignUpStep2 extends LearnerSignUpStep2Controller {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="LSU-container">
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className="LSU-container-c">
          <div className="LSU-title-container">
            <p className="LSU-title">Sign Up</p>
            <p className="LSU-sub-title">Step 2 of 2</p>
          </div>
          <div className="LSU-input-container">
            <div className="LSU-as-container">
              <div>
                <p className="LSU-label-1">Learner Registration</p>
              </div>
            </div>
            <div className="LSU-steps-container">
              <div className="LSU-steps-title-container">
                <p className="LSU-steps-title-1">Profile</p>
                <p className="LSU-steps-title-2">Academics</p>
              </div>
              <div className="LSU-steps-c">
                <div className="LSU-steps">
                  <div className="LSU-steps-c-1">
                    <p>1</p>
                  </div>
                </div>
                <hr className="LSU-steps-hl" />
                <div className="LSU-steps">
                  <div className="LSU-steps-c-2">
                    <p>2</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="LSU-inputs-ccc">
            <div className="LSU-input-cc">
              <div className="LSU-input-left">
                <label className="LSU-input-lable">Qualification*</label>
                <div className="LSU-input-dd">
                  <select
                    autoFocus
                    className="LSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleQualificationChange(e);
                    }}
                    style={this.state.errQualification ? this.errStyle : {}}
                    data-testId="TSS2_QualificationChange"
                  >
                    <option value="Select">Select</option>
                    {this.state.lQualification.map((value, index) => {
                      return (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className="LSU-input-arrow"
                    style={this.state.errQualification ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                </div>
              </div>
              <div className="LSU-input-right">
                <label className="LSU-input-lable">Currently Working*</label>
                <div className="LSU-input-dd">
                  <select
                    className="LSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleCurrentWorkingChange(e);
                    }}
                    style={this.state.errCurrentlyworking ? this.errStyle : {}}
                    data-testId="TSS2_CurrentWorkingChange"
                  >
                    <option value="Select">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <span
                    className="LSU-input-arrow"
                    style={this.state.errCurrentlyworking ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="LSU-input-cc">
              <div className="LSU-input-left">
                <label className="LSU-input-lable">Career Goal*</label>
                <input
                  className="LSU-input"
                  type="text"
                  value={this.state.careerGoal}
                  required
                  onChange={(e) => {
                    this.handleCareerGChange(e);
                  }}
                  placeholder="Enter Details"
                  style={this.state.errCareerGoal ? this.errStyle : {}}
                  data-testId="TSS2_CareerGChange"
                />
              </div>
              <div className="LSU-input-right">
                <label className="LSU-input-lable">Area of Interest*</label>
                <div className="LSU-input-dd">
                  <select
                    className="LSU-input-dd-s"
                    defaultValue="Select"
                    onChange={(e) => {
                      this.handleAreaOfIntChange(e);
                    }}
                    style={this.state.errAreaOfInt ? this.errStyle : {}}
                    data-testId="TSS2_AreaOfIntChange"
                  >
                    <option value="Select">Select</option>
                    {this.state.lInterest.map((value, index) => {
                      return (
                        <option key={value.id} value={value.id}>
                          {value.name}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className="LSU-input-arrow"
                    style={this.state.errAreaOfInt ? { backgroundColor: "#fcecec" } : {}}
                  >
                    <img src={dropdown} alt="arrow" />
                  </span>
                </div>
              </div>
            </div>
            <div className="LSU-submit-container">
              <div>
                <button
                  className="LSU-submit"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                  data-testId="TSS2_handleSubmit"
                >
                  Register Now
                </button>
              </div>
              <div className="LSU-already" style={{ marginTop: "1.5em" }}>
                <p>Already on Techfin? </p>
                <button
                  className="LSU-login-btn"
                  onClick={this.handleLoginNav}
                  data-testId="TSS2_handleLoginNav"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
