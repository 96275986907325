// App.js - WEB
import React, { Component } from 'react';
import firebase from 'firebase';
// import './App.scss';
import 'rsuite/dist/rsuite.min.css';
import "react-datepicker/dist/react-datepicker.css";

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';

import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import TeachersDashboard9 from '../../blocks/TeachersDashboard9/src/TeachersDashboard9';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import Videos from '../../blocks/videos/src/Videos';
import CameraAccess from '../../blocks/cameraaccess/src/CameraAccess';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Webinars from '../../blocks/Webinars/src/Webinars';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Dashboard from '../../blocks/dashboard/src/Dashboard.Web';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import ElasticSearch from '../../blocks/ElasticSearch/src/ElasticSearch';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import LiveChat2 from '../../blocks/LiveChat2/src/LiveChat2';
import VideoManagement from '../../blocks/VideoManagement/src/VideoManagement';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailLogin from '../../blocks/email-account-login/src/EmailLogin.web';
import ResetPassword from '../../blocks/email-account-login/src/ResetPassword.web';
import StartLearning from '../../blocks/email-account-login/src/StartLearning.web';
import CourseDetails from '../../blocks/email-account-login/src/CourseDetails.web';
import SignUp from '../../blocks/email-account-registration/src/SignUp.web';
import OtpAuth from '../../blocks/email-account-registration/src/OtpAuth.web';
import OtpSuccess from '../../blocks/email-account-registration/src/OtpSuccess.web';
import LearnerSignUpStep2 from '../../blocks/email-account-registration/src/LearnerSignUpStep2.web';
import RegSuccess from '../../blocks/email-account-registration/src/RegSuccess.web';
import TeacherSignUpStep2 from '../../blocks/email-account-registration/src/TeacherSignUpStep2.web';
import TeachersLandingPage from '../../blocks/landingpage/src/TeachersLandingPage.web';
import HireTeacher from '../../blocks/email-account-login/src/HireTeacher.web';
import PayProposedBudget from '../../blocks/email-account-login/src/PayProposedBudget.web';
import TeacherClasses from '../../blocks/email-account-login/src/TeacherClasses.web';
import ViewSubmissionList from '../../blocks/email-account-login/src/ViewSubmissionList.web';
import ContactUs from '../../blocks/dashboard/src/ContactUs.web';
import CoursesList from '../../blocks/dashboard/src/CoursesList.web';
import CoursesDetails from '../../blocks/dashboard/src/CoursesDetails.web';
import StudentSubjectDetials from "../../blocks/email-account-login/src/StudentSubjectDetails.web"
import StudentProfile from '../../blocks/categoriessubcategories/src/StudentProfile.web';
import Settings from '../../blocks/categoriessubcategories/src/Settings.web';
import Messages from '../../blocks/categoriessubcategories/src/Messages.web';
import Assessment from '../../blocks/AssessmentTest/src/Assessment.web';
import StudentDashboard from '../../blocks/email-account-login/src/StudentDashboard.web';
import CourseList from '../../blocks/email-account-login/src/CourseList.web';
import AddContractInfo from '../../blocks/dashboard/src/AddContractInfo.web';
import ViewSchedule from '../../blocks/dashboard/src/ViewSchedule.web';
// import WebinerList from '../../blocks/Webinars/src/WebinarList.web';
import TeacherDashboard from '../../blocks/TeachersDashboard9/src/TeacherDashboard.web';
import TeachersAssessments from '../../blocks/TeachersDashboard9/src/TeachersAssessments.web';
import ViewOngoingSubjectDetials from '../../blocks/TeachersDashboard9/src/ViewOngoingSubjectDetials.web';
import TeacherMessages from '../../blocks/TeachersDashboard9/src/TeacherMessages.web';
import JobListing from '../../blocks/TeachersDashboard9/src/JobListing.web';
// import LiveWebinar from '../../blocks/Webinars/src/LiveWebinar.web';
import JobDetails from '../../blocks/TeachersDashboard9/src/JobDetails.web';
import TeachersClasses from '../../blocks/TeachersDashboard9/src/TeachersClasses.web';
import SubjectDetails from '../../blocks/TeachersDashboard9/src/SubjectDetails.web';
import CreateAssessment from '../../blocks/AssessmentTest/src/CreateAssessment.web';
import TeachersProfile from '../../blocks/TeachersDashboard9/src/TeachersProfile.web';
import TeachersSettings from '../../blocks/TeachersDashboard9/src/TeachersSettings.web';
import AssessResult from '../../blocks/AssessmentTest/src/AssessResult.web';
import CourseAssesmentTestPass from '../../blocks/AssessmentTest/src/CourseAssesmentTestPass.web';
import Search from '../../blocks/ElasticSearch/src/Search.web';
import ViewAppliedJobs from '../../blocks/TeachersDashboard9/src/ViewAppliedJobs.web';
import AppliedJobs from '../../blocks/TeachersDashboard9/src/AppliedJobs.web';
import ViewContract from '../../blocks/TeachersDashboard9/src/ViewContract.web';
import TeachersDashboardAssignment from '../../blocks/TeachersDashboard9/src/TeachersDashboardAssignment.web';
import ViewAllSubjects from '../../blocks/TeachersDashboard9/src/ViewAllSubjects.web';
import PendingScreenLongAnswer from '../../blocks/TeachersDashboard9/src/PendingScreenLongAnswer.web';
import LongAnsweQuestionWithMarks from '../../blocks/TeachersDashboard9/src/LongAnswerQuestionWithMarks.web';
import ViewQuestion from '../../blocks/AssessmentTest/src/ViewQuestion.web';

const routeMap = {
  TeachersDashboard9: {
    component: TeachersDashboard9,
    path: '/TeachersDashboard9'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Webinars: {
    component: Webinars,
    path: '/webinars'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  Assessment: {
    component: Assessment,
    path: '/Assessment/:assessmentId'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: '/ElasticSearch'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  LiveChat2: {
    component: LiveChat2,
    path: '/LiveChat2'
  },
  VideoManagement: {
    component: VideoManagement,
    path: '/VideoManagement'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },

  Home: {
    component: Dashboard,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  EmailLogin: {
    component: EmailLogin,
    path: '/EmailLogin'
  },
  ResetPassword: {
    component: ResetPassword,
    path: '/ResetPassword'
  },
  StartLearning: {
    component: StartLearning,
    path: '/StartLearning/:courseId/:chapterId'
  },
  CourseDetails: {
    component: CourseDetails,
    path: '/CourseDetails/:courseId'
  },
  SignUp: {
    component: SignUp,
    path: '/SignUp'
  },
  OtpAuth: {
    component: OtpAuth,
    path: '/OtpAuth'
  },
  OtpSuccess: {
    component: OtpSuccess,
    path: '/OtpSuccess'
  },
  LearnerSignUpStep2: {
    component: LearnerSignUpStep2,
    path: '/LearnerSignUpStep2'
  },
  RegSuccess: {
    component: RegSuccess,
    path: '/RegSuccess'
  },
  TeacherSignUpStep2: {
    component: TeacherSignUpStep2,
    path: '/TeacherSignUpStep2'
  },
  TeachersLandingPage: {
    component: TeachersLandingPage,
    path: '/TeachersLandingPage'
  },
  ContactUs: {
    component: ContactUs,
    path: '/ContactUs'
  },
  CoursesList: {
    component: CoursesList,
    path: '/CoursesList/:page'
  },
  // LiveWebinar: {
  //   component: LiveWebinar,
  //   path: '/LiveWebinar'
  // },
  CoursesDetails: {
    component: CoursesDetails,
    path: '/CoursesDetails/:courseId'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  StudentDashboard: {
    component: StudentDashboard,
    path: '/StudentDashboard'
  },
  CourseList: {
    component: CourseList,
    path: '/CourseList/:page'
  },
  AddContractInfo: {
    component: AddContractInfo,
    path: '/AddContractInfo/:jobApplication'
  },
  ViewSchedule: {
    component: ViewSchedule,
    path: '/ViewSchedule/:ViewScheduleId'
  },
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  Messages: {
    component: Messages,
    path: '/Messages'
  },
  TeacherMessages: {
    component: TeacherMessages,
    path: '/TeacherMessages'
  },
  Profile: {
    component: StudentProfile,
    path: '/Profile'
  },
  HireTeacher: {
    component: HireTeacher,
    path: '/HireTeacher'
  },
  PayProposedBudget: {
    component: PayProposedBudget,
    path: '/PayProposedBudget/:budgetId'
  },
  TeacherClasses: {
    component: TeacherClasses,
    path: '/TeacherClasses'
  },
  StudentSubjectDetials: {
    component: StudentSubjectDetials,
    path: '/StudentSubjectDetials'
  },
  ViewSubmissionList: {
    component: ViewSubmissionList,
    path: '/ViewSubmissionList/:hiringPostId'
  },
  // WebinerList: {
  //   component: WebinerList,
  //   path: '/WebinerList'
  // },
  TeacherDashboard: {
    component: TeacherDashboard,
    path: '/TeacherDashboard'
  },
  JobListing: {
    component: JobListing,
    path: '/JobListing'
  },
  JobDetails: {
    component: JobDetails,
    path: '/JobDetails'
  },
  TeachersClasses: {
    component: TeachersClasses,
    path: '/TeachersClasses'
  },
  SubjectDetails: {
    component: SubjectDetails,
    path: '/SubjectDetails'
  },
  CreateAssessment: {
    component: CreateAssessment,
    path: '/CreateAssessment'
  },
  AssessResult: {
    component: AssessResult,
    path: '/AssessResult/:status'
  },
  TeachersProfile: {
    component: TeachersProfile,
    path: '/TeachersProfile'
  },
  CourseAssesmentTestPass: {
    component: CourseAssesmentTestPass,
    path: '/CourseAssesmentTestPass'
  },
  Search: {
    component: Search,
    path: '/Search/:searchBy?/:query?'
  },
  ViewAppliedJobs: {
    component: ViewAppliedJobs,
    path: '/ViewAppliedJobs'
  },
  ViewContract: {
    component: ViewContract,
    path: '/ViewContract'
  },
  AppliedJobs: {
    component: AppliedJobs,
    path: '/AppliedJobs'
  },
  TeachersDashboardAssignment: {
    component: TeachersDashboardAssignment,
    path: '/TeachersDashboardAssignment'
  },
  ViewAllSubjects: {
    component: ViewAllSubjects,
    path: '/ViewAllSubjects'
  },
  PendingScreenLongAnswer: {
    component: PendingScreenLongAnswer,
    path: '/PendingScreenLongAnswer'
  },
  LongAnsweQuestionWithMarks: {
    component: LongAnsweQuestionWithMarks,
    path: '/LongAnsweQuestionWithMarks'
  },
  TeachersSettings: {
    component: TeachersSettings,
    path: '/TeachersSettings'
  },
  ViewQuestion: {
    component: ViewQuestion,
    path: '/ViewQuestion'
  },
  TeachersAssessments: {
    component: TeachersAssessments,
    path: '/TeachersAssessments'
  },
  ViewOngoingSubjectDetials: {
    component: ViewOngoingSubjectDetials,
    path: '/ViewOngoingSubjectDetials'
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <div>
        {/* <TopNavigation /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        {/* <BottomNavigation /> */}
      </div>
    );
  }
}

export default App;
