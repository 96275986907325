import React from "react";

// Customizable Area Start

import Button from "@material-ui/core/Button";
import {
  Women,
  calender,
  carousal_leftArrow,
  carousal_rightArrow,
  carousal_active,
  carousal_inactive,
  defaultProfilePic,
} from "./assets";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./EmailLogin.css";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-circular-progressbar/dist/styles.css";
import Carousel from "react-elastic-carousel";
import "./style.css";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    sDashboard_container: {
      paddingLeft: "13vw",
      paddingRight: "13vw",
      paddingTop: "3vw",
      paddingBottom: "2em",
      display: "flex",
    },
    dash_left_c: {
      flex: 2,
    },
    sDashboard_cc: {
      width: "100%",
      marginLeft: "2vw",
      flex: 7.5,
    },
    sDashboard_Hr: {
      borderBottom: "0.5px solid #e4e4e5",
    },
    sDashboard_title: {
      color: "#14292d",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.55vw",
      margin: "0px",
      marginBottom: "1vw",
    },
    sDashboard_content_c: {
      display: "flex",
      width: "100%",
      marginTop: "2em",
    },
    sDashboard_content_left_c: {
      width: "100%",
      flex: 7,
      marginRight: "2em",
    },
    sDashboard_content_right_c: {
      width: "100%",
      flex: 2.8,
    },
    sDashboard_content_title: {
      color: "#333333",
      fontSize: "1.5em",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
    },
    sDashboard_main_progress: {
      borderRadius: "1em",
      "&.MuiCircularProgress-colorPrimary": {
        color: "#f0d17c",
      },
    },
    sDashboard_main_progress_sm: {
      position: "absolute",
      borderRadius: "1em",
      "&.MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },
    sDashboard_main_progress_bg: {
      borderRadius: "1em",
      "&.MuiCircularProgress-colorPrimary": {
        color: "#f7f7f7",
      },
    },
    sDashboard_content_container: {
      boxShadow: "0px 5px 30px rgba(0,0,0,0.05)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2em",
      borderRadius: "1.3em",
      marginTop: "1.5em",
      marginBottom: "3em",
    },
    circle: {
      strokeLinecap: "round",
    },
    sDashboard_course_count_c: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    sDashboard_course_count_txt: {
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.3em",
    },
    sDashboard_course_count: {
      color: "#ff0000",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.88em",
    },
    sDashboard_scroll_C: {
      background: "#ffffff",
      boxShadow: "0px 5px 20px rgba(0,0,0,0.05);",
      marginBottom: "3em",
    },
    sDashboard_scroll_header: {
      background: "#14292d",
      borderRadius: "0.25vw",
      display: "flex",
      paddingTop: "1vw",
      paddingBottom: "1vw",
      paddingLeft: "2em",
      paddingRight: "2em",
    },
    sDashboard_scroll_head_p1: {
      flex: 4,
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1em",
      color: "#ffffff",
      margin: "0px",
    },
    sDashboard_scroll_head_p2: {
      flex: 3,
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      color: "#ffffff",
      margin: "0px",
    },
    sDashboard_scroll_head_p3: {
      flex: 3,
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      color: "#ffffff",
      margin: "0px",
    },
    sDashboard_scroll_head_p4: {
      flex: 1,
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.8vw",
      color: "#ffffff",
      margin: "0px",
    },
    sDashboard_sroll_item_c: {
      display: "flex",
      paddingTop: "0.8vw",
      paddingBottom: "0.8vw",
      paddingLeft: "2em",
      paddingRight: "2em",
    },
    sDashboard_sroll_item_p1: {
      flex: 4,
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.88em",
    },
    sDashboard_sroll_item_p2: {
      flex: 3,
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.88em",
    },
    sDashboard_sroll_item_p3: {
      flex: 3,
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.88em",
    },
    sDashboard_sroll_item_p4: {
      flex: 1,
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.88em",
    },
    sDashboard_scroll_progress_c: {
      flex: 1,
      position: "relative",
      display: "inline-flex",
      height: "100%",
      maxWidth: "3.44em",
    },
    sDashboard_User_details_c: {
      background: "#f4f4f4",
      borderRadius: "1.3em",
      width: "100%",
      overflow: "hidden",
    },
    sDashboard_User_details_c1: {
      background: "#f4f4f4",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    sDashboard_User_details_img: {
      width: "6em",
      height: "6em",
      marginTop: "2em",
      borderRadius: "10em",
      objectFit: "cover",
    },
    profileImage: {
      width: "80px",
      height: "80px",
      padding: 0,
      borderRadius: "10em",
      marginTop: "2em",
    },
    sDashboard_User_details_name: {
      marginTop: "1em",
      color: "#333333",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1.25em",
    },
    sDashboard_User_details_email: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 400,
      fontSize: "0.85em",
      marginBottom: "1.5em",
    },
    sDashboard_User_details_c2: {
      background: "#ff0000",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "2em",
      paddingRight: "2em",
    },
    sDashboard_User_details_Btxt: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "1em",
      marginTop: "0.8em",
    },
    sDashboard_User_details_txt: {
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 600,
      fontSize: "0.85em",
    },
    sDashboard_User_details_Btn_c: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "1em",
      marginBottom: "2em",
    },
    sDashboard_User_details_Btn: {
      backgroundColor: "#f0d17c",
      borderRadius: "10em",
      color: "#181617",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1.05em",
      textTransform: "none",
      minWidth: "90%",
      "&:hover": {
        backgroundColor: "#f0d17c",
      },
    },
    sDashboard_AdTecher_c: {
      marginTop: "2em",
      background: "#f7f7f7",
      borderRadius: "1.3em",
      overflow: "hidden",
    },
    sDashboard_AdTecher_img: {
      width: "100%",
      height: "18em",
      objectFit: "cover",
    },
    sDashboard_AdTecher_txt_c: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
      paddingTop: "2em",
    },
    sDashboard_AdTecher_title: {
      color: "#333333",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "1.5em",
    },
    sDashboard_AdTecher_subtitle: {
      color: "#666666",
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      fontSize: "0.85em",
      marginTop: "0.8em",
    },
    sDashboard_AdTeacher_Btn_c: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "1em",
      marginBottom: "2em",
    },
    sDashboard_AdTeacher_Btn: {
      backgroundColor: "#ff0000",
      borderRadius: "10em",
      color: "#ffffff",
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "1.05em",
      textTransform: "none",
      minWidth: "90%",
      "&:hover": {
        backgroundColor: "#ff0000",
      },
    },
    sDashboard_carousal_imgs: {
      width: "2em",
    },
    sDashboard_carousal_btn_c: {
      display: "flex",
      alignItems: "center",
    },
    sDashboard_carousal_btn: {
      display: "flex",
      alignItems: "center",
      border: "0px",
      backgroundColor: "transparent",
      "&:disabled": {
        opacity: 1,
        backgroundColor: "transparent",
      },
    },
    sDashboard_carousal_dots: {
      width: "0.7em",
      marginRight: "0.6em",
    },

    tableContainerStyle: {
      background: "#ffffff",
      boxShadow: "0px 5px 20px rgba(0,0,0,0.05);",
      marginBottom: "3em",
      maxHeight: "500px",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "auto",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px #dcdcdc",
        borderRadius: "10px",
        marginTop: "4vw",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(20, 41, 45, 0.3)",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(20, 41, 45, 0.3)",
      },
    },
    tableHeadStyle: {
      "&.MuiTableCell-stickyHeader": {
        backgroundColor: "#14292d",
        backgroundBlendMode: "normal",
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        fontSize: "1em",
        color: "#ffffff",
        textAlign: "center",
      },
    },
    tableCellStyle: {
      color: "#666666",
      fontFamily: "Inter, sans-serif",
      fontWeight: 500,
      fontSize: "0.88em",
      textAlign: "center",
    },
    loadingDashboard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "2em",
      height: "100vh",
      width: "100%",
      "& .MuiCircularProgress-colorPrimary": {
        color: "#ff0000",
      },
    },
  });

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//   ];
// Customizable Area End

import StudentDashboardController, { Props } from "./StudentDashboardController.web";
import moment from "moment";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 2 },
  { width: 1200, itemsToShow: 2 },
];

class StudentDashboard extends StudentDashboardController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    console.log(this.state.userDetails, "+++++++++++++++++++++++++++=====");
    return (
      <div className={classes.root}>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />

        <div className={classes.sDashboard_container}>
          <div className={classes.dash_left_c}>
            <DashNav
              active="Dashboard"
              styles={{ marginBottom: "1.5vw" }}
              navigation={this.props.navigation}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              onClick={() => {
                console.log("Next Class");
              }}
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{}}
              onClick={() => {}}
            />
          </div>
          <div className={classes.sDashboard_cc}>
            <Typography className={classes.sDashboard_title}>Dashboard</Typography>
            <div className={classes.sDashboard_Hr} />
            {this.state.isLoading && (
              <div className={classes.loadingDashboard}>
                <CircularProgress />
              </div>
            )}
            {!this.state.isLoading && (
              <div className={classes.sDashboard_content_c}>
                <div className={classes.sDashboard_content_left_c}>
                  <Typography className={classes.sDashboard_content_title}>Current score</Typography>

                  <div className={classes.sDashboard_content_container}>
                    <div>
                      <div style={{ position: "relative", display: "inline-flex" }}>
                        <CircularProgress
                          variant="determinate"
                          value={this.state.currentScore * 10}
                          size="6.25em"
                          className={classes.sDashboard_main_progress}
                          thickness={2.5}
                          classes={{
                            circle: classes.circle,
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#14292d",
                              fontFamily: "Inter, sans-serif",
                              fontWeight: 600,
                              fontSize: "1.85em",
                            }}
                          >
                            {this.state.currentScore}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.sDashboard_course_count_c}>
                      <Typography className={classes.sDashboard_course_count_txt}>
                        Courses Completed
                      </Typography>
                      <Typography className={classes.sDashboard_course_count}>
                        {this.state.compleatedCoursesCount}
                      </Typography>
                    </div>
                    <div className={classes.sDashboard_course_count_c}>
                      <Typography className={classes.sDashboard_course_count_txt}>
                        Scheduled Courses
                      </Typography>
                      <Typography className={classes.sDashboard_course_count}>
                        {this.state.scheduledCoursesCount}
                      </Typography>
                    </div>
                  </div>

                  {/* Courses Scheduled */}
                  {this.state.scheduledCoursesCount > 0 ? (
                    <div>
                      {" "}
                      <Typography className={classes.sDashboard_title}>Courses Scheduled</Typography>
                      <TableContainer className={classes.tableContainerStyle} component={Paper}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow style={{ color: "white" }}>
                              <TableCell className={classes.tableHeadStyle}>Course</TableCell>
                              <TableCell className={classes.tableHeadStyle}>Start Date</TableCell>
                              <TableCell className={classes.tableHeadStyle}>Modules</TableCell>
                              <TableCell className={classes.tableHeadStyle}>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          {/* {this.state.paymentStatusLoader<div className={classes.noHistory} style={{marginLeft:"2em"}}><CircularProgress/></div>:paymentDetails.length<=0?<div className={classes.noHistory}><span>No Payment History</span></div>: */}
                          <TableBody>
                            {this.state.scheduledCourses.map((eachValue: any) => {
                              const eachCourse = eachValue?.attributes?.course?.data?.attributes;
                              return (
                                <TableRow key={eachCourse?.id}>
                                  <TableCell className={classes.tableCellStyle}>{eachCourse?.name}</TableCell>
                                  <TableCell className={classes.tableCellStyle}>
                                    {moment(eachCourse?.start_date).format("DD-MM-YYYY")}
                                  </TableCell>
                                  <TableCell className={classes.tableCellStyle}>
                                    {eachCourse?.module_count}
                                  </TableCell>
                                  <TableCell className={classes.tableCellStyle}>
                                    <div className={classes.sDashboard_scroll_progress_c}>
                                      <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size="3.44em"
                                        className={classes.sDashboard_main_progress_bg}
                                        thickness={5}
                                      />
                                      <CircularProgress
                                        variant="determinate"
                                        value={eachValue?.attributes?.completion_percent}
                                        size="3.44em"
                                        className={classes.sDashboard_main_progress_sm}
                                        thickness={5}
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: "#ff0000",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 600,
                                            fontSize: "0.88em",
                                          }}
                                        >
                                          {eachValue?.attributes?.completion_percent}%
                                        </Typography>
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : null}

                  {/* Courses Completed */}

                  {this.state.compleatedCoursesCount > 0 ? (
                    <div>
                      <Typography className={classes.sDashboard_title}>Courses Completed</Typography>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          background: "#ededed",
                          padding: "1em",
                          width: "100%",
                          margin: "0px",
                        }}
                        className="CarouselGrid"
                      >
                        <Carousel
                          isRTL={false}
                          breakPoints={breakPoints}
                          renderArrow={({ type, onClick, isEdge }) => {
                            const pointer =
                              type === "PREV" ? (
                                <img
                                  src={carousal_leftArrow}
                                  alt="carousal_leftArrow"
                                  className={classes.sDashboard_carousal_imgs}
                                />
                              ) : (
                                <img
                                  src={carousal_rightArrow}
                                  alt="carousal_rightArrow"
                                  className={classes.sDashboard_carousal_imgs}
                                />
                              );
                            return (
                              <div className={classes.sDashboard_carousal_btn_c}>
                                <button
                                  onClick={onClick}
                                  disabled={isEdge}
                                  className={classes.sDashboard_carousal_btn}
                                >
                                  {pointer}
                                </button>
                              </div>
                            );
                          }}
                          renderPagination={({ pages, activePage, onClick }) => {
                            return (
                              <div style={{ marginTop: "1em" }}>
                                {pages.map((page) => {
                                  const isActivePage = activePage === page;
                                  return isActivePage ? (
                                    <img
                                      src={carousal_active}
                                      alt=""
                                      className={classes.sDashboard_carousal_dots}
                                      key={page}
                                    />
                                  ) : (
                                    <img
                                      src={carousal_inactive}
                                      alt=""
                                      className={classes.sDashboard_carousal_dots}
                                      key={page}
                                    />
                                  );
                                })}
                              </div>
                            );
                          }}
                        >
                          {this.state.compleatedCourses.map((val: any) => {
                            const CCourse = val?.attributes;
                            return (
                              <Grid item key={CCourse.id} style={{ width: "12em" }}>
                                <Card
                                  style={{
                                    borderRadius: "0.7em",
                                    boxShadow: "0px 5px 20px rgba(0,0,0,0.06)",
                                  }}
                                >
                                  <CardMedia>
                                    <img
                                      style={{ height: "10em", width: "100%", objectFit: "cover" }}
                                      src={CCourse?.image_url}
                                      alt=""
                                    />
                                  </CardMedia>
                                  <CardContent>
                                    <Typography
                                      style={{
                                        fontSize: "0.9em",
                                        fontWeight: 700,
                                        fontFamily: "Inter, sans-serif",
                                        color: "#333333",
                                      }}
                                    >
                                      {CCourse?.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      style={{
                                        fontSize: "0.75em",
                                        fontWeight: 400,
                                        color: "#666666",
                                        fontFamily: "Inter, sans-serif",
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 2,
                                      }}
                                    >
                                      {CCourse?.course_description}
                                    </Typography>

                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "13px",
                                        columnGap: 5,
                                      }}
                                    >
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                          style={{ width: "1.1em", objectFit: "contain" }}
                                          src={calender}
                                          alt=""
                                        />
                                        <Typography
                                          style={{
                                            color: "#333333",
                                            fontWeight: 600,
                                            fontFamily: "Inter, sans-serif",
                                            fontSize: "0.9em",
                                          }}
                                        >
                                          &nbsp;{moment(CCourse?.start_date).format("DD-MM-YYYY")}
                                        </Typography>
                                      </div>
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          })}
                        </Carousel>
                      </Grid>
                    </div>
                  ) : null}
                </div>
                <div className={classes.sDashboard_content_right_c}>
                  <div className={classes.sDashboard_User_details_c}>
                    <div className={classes.sDashboard_User_details_c1}>
                      {this.state.userDetails.profile_image ? (
                        <img
                          src={this.state.userDetails.profile_image}
                          alt=""
                          className={classes.sDashboard_User_details_img}
                        />
                      ) : (
                        <img src={defaultProfilePic} alt="" className={classes.sDashboard_User_details_img} />
                      )}
                      <Typography className={classes.sDashboard_User_details_name}>
                        {this.state.userDetails.first_name}
                        {` `}
                        {this.state.userDetails.last_name}
                      </Typography>
                      <Typography className={classes.sDashboard_User_details_email}>
                        {this.state.userDetails.email}
                      </Typography>
                    </div>
                    <div className={classes.sDashboard_User_details_c2}>
                      <Typography className={classes.sDashboard_User_details_Btxt}>Enrolled On</Typography>
                      <Typography className={classes.sDashboard_User_details_txt}>
                        {moment(this.state.userDetails.created_at).format("DD-MM-YYYY")}
                      </Typography>
                      <Typography className={classes.sDashboard_User_details_Btxt}>
                        Courses Completed
                      </Typography>
                      <Typography className={classes.sDashboard_User_details_txt}>
                        {this.state.userDetails.completed_course_count}
                      </Typography>
                      <Typography className={classes.sDashboard_User_details_Btxt}>Qualification</Typography>
                      <Typography className={classes.sDashboard_User_details_txt}>
                        {this.state.userDetails.qualification}
                      </Typography>
                      <div className={classes.sDashboard_User_details_Btn_c}>
                        <Button
                          variant="contained"
                          onClick={this.handleNavToProfile}
                          className={classes.sDashboard_User_details_Btn}
                        >
                          Update Profile
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.sDashboard_AdTecher_c}>
                    <img src={Women} alt="" className={classes.sDashboard_AdTecher_img} />
                    <div className={classes.sDashboard_AdTecher_txt_c}>
                      <Typography className={classes.sDashboard_AdTecher_title}>
                        Can't find a Teacher?
                      </Typography>
                      <Typography className={classes.sDashboard_AdTecher_subtitle}>
                        Lorem Ipsum is simply dummy text of the printing
                      </Typography>
                      <div className={classes.sDashboard_AdTeacher_Btn_c}>
                        <Button variant="contained" className={classes.sDashboard_AdTeacher_Btn}>
                          Hire a teacher now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(StudentDashboard);

// Customizable Area End
