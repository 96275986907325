import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: any, params?: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
  }
  history: any;
  location: any;
}

export interface S {
  // Customizable Area Start
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeachersLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
  }
  async componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    console.log('componentDidMount TeachersLandingPageController.web')
  }
  handleTSignUpNav = () => {
    this.props.navigation.navigate("SignUp");
  };
}
