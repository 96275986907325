import React, { Component } from "react";
import Banner from "./Components/Banner.web";
import OtpAuthController, { Props } from "./OtpAuthController.web";
import PopularCourses from "./PopularCourses.web";
import OtpInput from "react-otp-input";
import TopNavigation from "./TopNavigation.web";
import BottomNavigation from "./BottomNavigation.web";

export default class OtpAuth extends OtpAuthController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="otp-container">
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <Banner
          navigation={this.props.navigation}
          location={this.props.location}
          history={this.props.history}
        />
        <div className="otp-container-c">
          <div className="otp-title">
            {/* <p>Please enter the 4 digit OTP sent to +91 {this.state.phone}</p> */}
            <p>To prove your human identity, type 1111 into the text box.</p>
          </div>
          <div className="otp-input-c">
            <OtpInput
              value={this.state.otp}
              onChange={this.handleOTPChange}
              numInputs={4}
              separator={<span>-</span>}
              isInputNum
              inputStyle={{
                width: "3.5em",
                height: "2.5em",
                border: "1px solid #c7c7c7",
                borderRadius: "0.3em",
                marginLeft: "1em",
                marginRight: "1em",
                color: "#181617",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
              }}
              containerStyle={{
                fontWeight: "bold",
                fontSize: "1.5em",
                fontFamily: "Inter, sans-serif",
              }}
              hasErrored={this.state.errOTP}
              errorStyle={{
                backgroundColor: "#ffe5e5",
                borderColor: "#ff0000",
              }}
            />
            {this.state.errPin && (
              <div
                className="otp-err"
                style={{
                  textAlign: "center",
                  color: "#ff0000",
                  marginTop: "1vw",
                }}
              >
                <label>Invalid Pin</label>
              </div>
            )}

            <div className="otp-validate-c">
              <button
                className="otp-validate-btn"
                onClick={this.handleSubmit}
                data-testId="OTP_AUTH_validate"
              >
                Validate
              </button>
            </div>
          </div>

          <div className="otp-resend-c">
            <button onClick={this.handleResendOTP} data-testId="OTP_AUTH_resendOtp">
              Resend OTP
            </button>
          </div>
          <div className="otp-btp-c">
            <p> Entered a wrong number? </p>
            <button onClick={this.handleBackToProfile} data-testId="OTP_AUTH_backToProfile">
              Back to Profile
            </button>
          </div>
        </div>
        <PopularCourses
          history={this.props.history}
          location={this.props.location}
          navigation={this.props.navigation}
          classes={undefined}
        />
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
    );
  }
}
