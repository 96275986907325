//@ts-nocheck
import React from "react";
// Customizable Area Start
import Button from "@material-ui/core/Button";
import { profileImg } from "./assets";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DashAdvartiseCard from "../../dashboard/src/components/DashAdvartiseCard.web";
import DashNav from "../../dashboard/src/components/DashNav.web";
import NextClassNotification from "../../dashboard/src/components/NextClassNotification.web";

import { withStyles, Theme } from "@material-ui/core/styles";

export const styles: any = (theme: Theme) => ({
  root: {
    // marginTop:"3%",
    // marginBottom:"3%"
  },
  StudentSubjetDetailsTitle:{
    color: "#14292d",
    fontFamily: "Inter, sans-serif",
    fontSize: "2em",
    fontWeight: 600,
    letterSpacing: "0.125px"
  
  },
  StudentSubjetDetailsMainSection: {
    paddingLeft: "13vw",
    paddingRight: "13vw",
    paddingTop: "3vw",
    paddingBottom: "2em",
    display: "flex",
  },
  StudentSubjetDetailsjobsleftsec: {
    flex: 2,
  },
  StudentSubjetDetailsRightSec: {
    width: "100%",
    marginLeft: "2vw",
    flex: 7.5,
  },

  add_contract_cc: {
    width: "100%",
    marginLeft: "2vw",
    flex: 7.5,
  },
  CD_Instruc_c: {
    paddingTop: "2em",
    display: "flex",
    justifyContent: "space-between",
  },
  CD_Instruc_D_A: {
    width: "100%",
    borderRadius: "1em",
    border: "2px solid #ff0000",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
    marginRight: "1.5vw",
    padding: "1.2vw",
  },
  CD_Instruc_D_B: {
    width: "100%",
    // height: "8vw",
    borderRadius: "1em",
    border: "2px solid #ff0000",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
    marginRight: "1.5vw",
    padding: "1.2vw",
  },
  CD_Instruc_D_C: {
    width: "100%",
    // height: "8vw",
    borderRadius: "1em",
    border: "2px solid #ff0000",
    background: "#ffffff",
    backgroundBlendMode: "normal",
    boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
    padding: "1.2vw",
  },
  CD_Instruct_title1: {
    color: "#ff0000",
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "1.5vw",
  },
  CD_Instruct_pics: {
    width: "5vw",
  },
  CD_Instruct_txt_cc: {
    marginTop: "0.5vw",
    display: "flex",
    overflow: "visible",
  },
  CD_Instruct_txt_cc_c: {
    marginLeft: "3.5em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  CD_Instruct_text_name: {
    color: "#333333",
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    fontSize: "1vw",
  },
  CD_Instruct_text_Quali: {
    color: "#181617",
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    fontSize: "0.8vw",
    margin:"0.3m 0em"
  },
  CD_Instruct_text_btn: {
    marginTop:"0.4em",
     padding:"1em 2em",
    color: "#ffffff",
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    backgroundColor: "#ff0000",
    borderRadius: "10em",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },
  CD_Instruct_2_text_c: {
    display: "flex",
    marginTop: "0.5vw",
  },
  CD_Instruct_2_text_1: {
    lineHeight: "1.5vw",
    color: "#181617",
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "0.85vw",
  },
  CD_Instruct_2_text_2_c: {
    marginLeft: "4em",
  },
  CD_Instruct_2_text_2: {
    lineHeight: "1.5vw",
    color: "#181617",
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    fontSize: "0.85vw",
  },
  CD_Instruct_3_cc: {
    width: "85%",
    marginTop: "0.5vw",
  },
  CD_Instruct_3: {
    lineHeight: "1.5vw",
    color: "#181617",
    fontFamily: "Inter, sans-serif",
    fontWeight: 400,
    fontSize: "0.8vw",
  },
  BtnC: {
    marginTop: "2em",
  },
  backToClassBtn1: {
    background: "#f0d17c",
    borderRadius: "10em",
    fontFamily: "Inter, sans-serif",
    fontWeight: 800,
    fontSize: "1.05em",
    textAlign: "center",
    textTransform: "none",
    color: "#181617",
    marginRight: "1.5em",
    padding:"1em 1.5em"

  },
  backToClassBtn2: {
    background: "#ff0000",
    borderRadius: "10em",
    fontFamily: "Inter, sans-serif",
    fontWeight: 800,
    fontSize: "1.05em",
    textAlign: "center",
    textTransform: "none",
    padding:"1em 1.5em",
    color: "#ffffff",
    marginRight: "1.5em",
  },
  backToClassBtn3: {
    borderRadius: "10em",
    fontFamily: "Inter, sans-serif",
    fontWeight: 800,
    fontSize: "1.05em",
    textAlign: "center",
    textTransform: "none",
    padding:"1em 1.5em",
    borderColor: "#ff4452",
    marginRight: "1.5em",
    color: "#ff0000",
  },
  
  teacherMyclassesLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    "& .MuiCircularProgress-colorPrimary": {
      color: "#ff0000"
    }
  },
});

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);utt
//   };
// Customizable Area End

import StudentSubjectDetialsController, { Props } from "./StudentSubjectDetialsController.web";
import { Formik } from "formik";
import { Divider } from "react-native-elements";
import Avatar from "@material-ui/core/Avatar";
import Pagination from "@material-ui/lab/Pagination";
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import CircularProgress from "@material-ui/core/CircularProgress";

export class StudentSubjectDetials extends StudentSubjectDetialsController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        <div className={classes.StudentSubjetDetailsMainSection}>
            <div className={classes.StudentSubjetDetailsjobsleftsec}>
            <DashNav
              active="Classes&Teachers"
              styles={{ marginBottom: "1.5vw" }}
              navigation={this.props.navigation}
            />
            <NextClassNotification
              timings="10:00 AM - 11:00 PM"
              title="Introduction to foundation of digital marketing and e-commerce"
              subtitle=" Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
            />
            <DashAdvartiseCard
              title="One on One classes with Teachers"
              subtitle="Lorem Ipsum is simply dummy text of the printing"
              styles={{ marginBottom: "1.5vw" }}
            />
            </div>
            <div className={classes.StudentSubjetDetailsRightSec}>
            <div>
            <Typography className={classes.StudentSubjetDetailsTitle}>Subject Name</Typography>
            <div className={classes.CD_C}>
                <div>
                  <Typography className={classes.CD_subtitle}>
                  description .......................  description .......................
                  </Typography>
                </div>
              </div>
         </div>
            <Divider style={{marginTop:"2em",marginBottom:"2em"}}></Divider>
          <div>
          {  false ?
                (<div className={classes.teacherMyclassesLoading} style={{ marginLeft: "2em" }}><CircularProgress /></div>):
            <>
             <div className={classes.CD_Instruc_c}>
                <div className={classes.CD_Instruc_D_A}>
                  <Typography className={classes.CD_Instruct_title1}>Hired Teacher</Typography>
                  <div className={classes.CD_Instruct_txt_cc}>
                    <img className={classes.CD_Instruct_pics} src={profileImg} alt="InstructorPic" />
                    <div className={classes.CD_Instruct_txt_cc_c}>
                      <Typography className={classes.CD_Instruct_text_name}>
                        prudhvi rufus
                      </Typography>
                      <Typography className={classes.CD_Instruct_text_Quali}>M.Tech</Typography>
                      <Button variant="contained" className={classes.CD_Instruct_text_btn}>
                        Send Message
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={classes.CD_Instruc_D_B}>
                  <Typography className={classes.CD_Instruct_title1}>Timings</Typography>
                  <div className={classes.CD_Instruct_2_text_c}>
                    <div className={classes.CD_Instruct_2_txt_1_c}>
                      <Typography className={classes.CD_Instruct_2_text_1}>Started on:</Typography>
                      <Typography className={classes.CD_Instruct_2_text_1}>Schedule:</Typography>
                      <Typography className={classes.CD_Instruct_2_text_1}>Duration:</Typography>
                    </div>
                    <div className={classes.CD_Instruct_2_text_2_c}>
                      <Typography className={classes.CD_Instruct_2_text_2}>
                        11-03-2023
                      </Typography>
                      <Typography className={classes.CD_Instruct_2_text_2}>2 days a week</Typography>
                      <Typography className={classes.CD_Instruct_2_text_2}>
                          3 months
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.BtnC}>
                <Button variant="contained" className={classes.backToClassBtn1} onClick={()=>{this.props.navigation.navigate("TeacherClasses")}}>Back to My Classes
                </Button>
                <Button variant="contained" className={classes.backToClassBtn2}>
                Join Class Now
                </Button>
                <Button variant="outlined" className={classes.backToClassBtn3}>
                 Take Assessment
                </Button>
              </div>
              </>

          }
          </div>

          </div>
        </div>
        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(StudentSubjectDetials);

// Customizable Area End
