import React from "react";

// Customizable Area Start
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Divider } from "react-native-elements";
import Typography from "@material-ui/core/Typography";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridGap: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(1),
    },
    table: {
      height: "50px",
    },
    
  });

// const handleChange = (event:any) => {
//     setFilterBy(event.target.value);
//   };
// Customizable Area End
import TopNavigation from "../../email-account-registration/src/TopNavigation.web";
import BottomNavigation from "../../email-account-registration/src/BottomNavigation.web";
import LongAnsweQuestionWithMarksController, { Props } from "./PendingScreenLongAnswerController.web";

export class LongAnsweQuestionWithMarks extends LongAnsweQuestionWithMarksController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
   
    return (
      // Customizable Area Start
      <>
      {/*@ts-ignore*/}
      <div>
        <TopNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
        {/* Banner */}
        
        <div>
        <Grid container spacing={3} style={{ marginTop: "3%", marginBottom: "3%",height:"90vh" }}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <div>
                <p
                  style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: "20px",
                    fontWeight: 500,
                    margin: "10px",
                  }}
                >
                 Subject - Assessments
                </p>
                <Divider  style={{marginBottom:"1em"}}/>
              </div>
                <div style={{backgroundColor: "#f7f7f7",borderRadius: "1vw",}}>
                <div style={{paddingTop: "2vw",paddingBottom: "1.3vw",paddingLeft: "1.3vw",paddingRight: "1.3vw"}}>
                    <Typography style={{fontWeight: "bold"}}>Q2.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
                    <div style={{border:"2px solid lightgray",background:"white",borderRadius:"5px",marginTop:"15px",marginBottom:"15px"}}>
                        <Typography style={{padding:"15px"}}>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, itaque provident voluptatum, aperiam officia suscipit recusandae magnam blanditiis pariatur optio quisquam saepe cupiditate magni. Accusantium, provident molestias consequuntur exercitationem nihil mollitia atque quos nostrum ullam repudiandae rem,provident molestias consequuntur exercitationem nihil mollitia atque quos nostrum ullam repudiandae rem.
                        </Typography>
                        <Typography style={{padding:"0px 15px 15px 15px"}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt odio illo praesentium.</Typography>
                    </div>
                <div>
                <div style={{display:"flex"}}>
                    <Typography style={{padding:"8px",border:"1px solid lightgray",background:"white",borderRadius:"5px",fontSize:"12px"}}>
                        Enter Marks
                    </Typography>
                    <Typography>&nbsp;/100&nbsp;</Typography>
                    <Button
                        variant="contained"
                        style={{
                        background: "#f00",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: 100,
                        width: "120px",
                        height: "35px",
                        marginLeft:"20px"
                        }}
                        size="medium"
                        >
                        Submit
                    </Button>
                </div>
            </div>
        </div>
                </div>
                <div style={{display:"flex",marginTop:"20px"}}>
                    <Typography>Question 2 of 4</Typography>
                <div style={{}}>
                    <Button
                        variant="contained"
                        style={{
                        background: "#f0d17c",
                        textTransform: "none",
                        borderRadius: 100,
                        width: "120px",
                        height: "35px",
                        marginLeft:"20px"
                        }}
                        size="medium"
                        >
                        Prevoius
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                        background: "#f00",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: 100,
                        width: "120px",
                        height: "35px",
                        marginLeft:"20px"
                        }}
                        size="medium"
                        >
                        Next
                    </Button>
                </div>
                    
                </div>
            </Grid>
            <Grid item xs={2} />
        </Grid>
        </div>
        


        <BottomNavigation
          navigation={this.props.navigation}
          history={this.props.history}
          location={this.props.location}
        />
      </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles, { withTheme: true })(LongAnsweQuestionWithMarks);

// Customizable Area End
