// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  subjectDetails:any
  isSubjectDetailsLoading:boolean
  // Customizable Area End
}

export interface SS {
 
  id: any;

}
export default class SubjectDetailsController extends BlockComponent<Props, S, SS> {
  subjectDetailsCallId: string = "";
  constructor(props: any) {

    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    this.state = {
      // Customizable Area Start
      subjectDetails:{},
      isSubjectDetailsLoading:false
      // Customizable Area End
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {

    // Customizable Area Start

    this.getSubjectDetails()
    // Customizable Area End
  }




  async receive(from: string, message: Message) {
    // Customizable Area Start
  
    if (this.subjectDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.errors) {
        alert(apiResponse?.errors)
      } else {
        this.setState({
          subjectDetails:apiResponse.data.attributes.hire_teacher_request,
          isSubjectDetailsLoading:false
        })
      }
    }   

    // Customizable Area End
  }

  getSubjectDetails = () => {
    this.setState({
      isSubjectDetailsLoading:true
    })
    const token = localStorage.getItem("loginT");
    const subjectId = localStorage.getItem("SubjectDetailsId");
    const endPoint = `${configJSON.viewSubjectDetails}?id=${subjectId}`;
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const getSubjectDetailsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.subjectDetailsCallId = getSubjectDetailsRequestMsg.messageId;

    getSubjectDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getSubjectDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getHttpMethodType
    );

    runEngine.sendMessage(getSubjectDetailsRequestMsg.id, getSubjectDetailsRequestMsg);

  }


 
}


  // Customizable Area End